import { ElementType, FunctionComponent, memo, useMemo } from 'react';
import {
  StoryCard,
  StoryCardArticleContent,
  StoryCardPostContent,
  StoryCardTeaserImageStyle,
  StoryCardType,
} from '../../../../services';
import { FileStatus } from '../../../models';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { FeedCardContentProps } from '../FeedCardContentCommon';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import classes from './FeedCardTeaser.module.scss';

interface FeedCardTeaserProps {
  card: StoryCard;
  ContentComponent: ElementType<FeedCardContentProps>;
}

export const FeedCardTeaser: FunctionComponent<FeedCardTeaserProps> = memo(
  ({ card, ContentComponent }) => {
    const { type, content, isPinnedToTopOfNewsFeed } = card;

    const { title, abstract, sourceName, gallery, styles } = content as
      | StoryCardArticleContent
      | StoryCardPostContent;

    const iconId = useMemo(
      () => (type === StoryCardType.ARTICLE ? 'arrow-rounded-right-up' : 'arrow-rounded-right'),
      [type]
    );

    if (Boolean(gallery.find(({ image: { status } }) => status === FileStatus.ERROR))) {
      return (
        <FeedCardMargin top right bottom left>
          <FeedCardFileError />
        </FeedCardMargin>
      );
    }

    if (Boolean(gallery.find(({ image: { status } }) => status === FileStatus.PROCESSING))) {
      return (
        <FeedCardMargin top right bottom left>
          <Skeleton height={'5rem'} />
        </FeedCardMargin>
      );
    }

    const image = gallery[0]?.image;

    if (!image) {
      return (
        <ContentComponent
          type={type}
          pinned={isPinnedToTopOfNewsFeed}
          source={sourceName}
          title={title}
          text={abstract}
        />
      );
    }

    const imageGalleryItems: ImageGalleryItem[] = gallery.map(({ image }) => {
      return { image };
    });

    switch (styles.teaserImageStyle) {
      case StoryCardTeaserImageStyle.SMALL_WITH_TEXT:
        return (
          <ContentComponent
            type={type}
            pinned={isPinnedToTopOfNewsFeed}
            source={sourceName}
            title={title}
            text={abstract}
            teaser={image}
          />
        );
      case StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT:
        return (
          <ContentComponent
            type={type}
            pinned={isPinnedToTopOfNewsFeed}
            source={sourceName}
            title={title}
            teaser={image}
          />
        );
      case StoryCardTeaserImageStyle.STANDARD:
        return (
          <>
            <ImageGallery items={imageGalleryItems} />
            <ContentComponent
              type={type}
              pinned={isPinnedToTopOfNewsFeed}
              source={sourceName}
              title={title}
              text={abstract}
            />
          </>
        );
      case StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT:
        return (
          <div className={classes['large-teaser']}>
            <ImageGallery items={imageGalleryItems} singleImageFullSize />
            <div
              className={classNames(classes['large-teaser__content'], {
                [classes['large-teaser__content--with-copy']]: image?.rightholder,
              })}
            >
              {(sourceName || isPinnedToTopOfNewsFeed) && (
                <div className={classes['large-teaser__content-source-wrapper']}>
                  {isPinnedToTopOfNewsFeed && (
                    <IconLabel
                      iconId={'pin'}
                      {...(sourceName && { label: '•' })}
                      color={getCssVar('--card-teaser-text-color')}
                      singleColor
                      nonClickable
                    />
                  )}
                  {sourceName && (
                    <div className={classes['large-teaser__content-source']}>{sourceName}</div>
                  )}
                </div>
              )}
              {title && (
                <div className={classes['large-teaser__content-title']}>
                  {title}
                  <span className={classes['large-teaser__content-title-icon']}>
                    <IconLabel
                      iconId={iconId}
                      iconSize={24}
                      color={getCssVar('--card-teaser-icon-color')}
                      singleColor
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  }
);

import { API, SanitizerConfig } from '@editorjs/editorjs';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';

export class Subscript implements InlineTool {
  private readonly api: API;
  private readonly button: HTMLButtonElement;
  private static readonly commandName = 'subscript';
  public static title = 'Subscript';

  constructor(options: { api: API }) {
    this.api = options.api;
    this.button = this.createToolButton();
  }

  static get isInline() {
    return true;
  }

  public static get sanitize(): SanitizerConfig {
    return { sub: {} };
  }

  private createToolButton(): HTMLButtonElement {
    const button = document.createElement('button');

    button.type = 'button';

    button.classList.add(this.api.styles.inlineToolButton);

    button.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_5699_15037" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_5699_15037)">
            <path d="M19.1925 19C18.9637 19 18.7718 18.9226 18.617 18.7678C18.4622 18.613 18.3848 18.4212 18.3848 18.1923V17.4231C18.3848 17.1942 18.4622 17.0024 18.617 16.8476C18.7718 16.6928 18.9637 16.6154 19.1925 16.6154H21.0002V15.7692H18.7694C18.6669 15.7692 18.5771 15.7308 18.5002 15.6538C18.4233 15.5769 18.3848 15.4872 18.3848 15.3846C18.3848 15.2821 18.4233 15.1923 18.5002 15.1154C18.5771 15.0385 18.6669 15 18.7694 15H20.9617C21.1906 15 21.3824 15.0774 21.5372 15.2322C21.692 15.387 21.7695 15.5789 21.7695 15.8077V16.5769C21.7695 16.8058 21.692 16.9976 21.5372 17.1524C21.3824 17.3072 21.1906 17.3846 20.9617 17.3846H19.154V18.2308H21.3848C21.4874 18.2308 21.5771 18.2692 21.654 18.3462C21.731 18.4231 21.7695 18.5128 21.7695 18.6154C21.7695 18.7179 21.731 18.8077 21.654 18.8846C21.5771 18.9615 21.4874 19 21.3848 19H19.1925ZM8.00853 17.3846C7.78929 17.3846 7.62893 17.2923 7.52745 17.1077C7.42595 16.9231 7.43417 16.7353 7.55212 16.5442L11.1925 10.9173L7.89635 5.83463C7.7784 5.64744 7.77018 5.46154 7.87168 5.27693C7.97318 5.09231 8.12947 5 8.34057 5C8.43802 5 8.5257 5.02233 8.6036 5.06698C8.68152 5.11164 8.74384 5.17458 8.79057 5.25578L11.9694 10.1923H11.9925L15.2233 5.23652C15.2691 5.16144 15.3322 5.10325 15.4125 5.06195C15.4928 5.02065 15.5779 5 15.668 5C15.8891 5 16.0488 5.09231 16.1471 5.27693C16.2453 5.46154 16.2355 5.64936 16.1175 5.84037L12.7637 10.9173L16.4483 16.5442C16.5662 16.7353 16.573 16.9231 16.4685 17.1077C16.364 17.2923 16.2073 17.3846 15.9983 17.3846C15.9035 17.3846 15.8167 17.3623 15.7378 17.3176C15.6588 17.273 15.5956 17.21 15.5483 17.1288L11.9925 11.6712H11.9694L8.45212 17.1288C8.40626 17.21 8.34319 17.273 8.26293 17.3176C8.18264 17.3623 8.09784 17.3846 8.00853 17.3846Z" fill="#1C1B1F"/>
        </g>
    </svg>`;

    return button;
  }

  public render(): HTMLElement {
    return this.button;
  }

  public surround(): void {
    document.execCommand(Subscript.commandName);
  }

  public checkState(): boolean {
    const isActive = document.queryCommandState(Subscript.commandName);

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}

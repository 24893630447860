import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { FilterGroup, FilterValue } from '../../components';
import { UserContext } from '../../../context';
import { LanguageByCode, optionalArrayItem } from '../../utils';
import { useFilter } from './useFilter';

export const CARD = 'card';
export const CARD_LOCALE = 'localeId';

export enum CardProps {
  allowComments = 'allowComments',
  allowCardReactions = 'allowCardReactions',
  allowSharing = 'allowSharing',
  shouldUpdatePostedTimeByComments = 'shouldUpdatePostedTimeByComments',
  showAllAuthors = 'showAllAuthors',
}

interface CardFilterProps {
  filter: FilterValue[];
  showDisplayUser?: boolean;
  showEnableComments?: boolean;
}

export const useCardFilter = ({
  filter,
  showDisplayUser = true,
  showEnableComments = true,
}: CardFilterProps) => {
  const { t } = useTranslation();

  const { getGroupValue } = useFilter();

  const { localeId } = useContext(UserContext).userInfo.userData;

  const locales = [
    { id: 1, code: 'en-US' },
    { id: 2, code: 'de-DE' },
    { id: 3, code: 'es-ES' },
  ];

  const localeValue = useMemo(
    () => getGroupValue({ filter, groupId: CARD_LOCALE }) ?? localeId,
    [filter, getGroupValue, localeId]
  );

  return {
    groups: [
      {
        id: CARD,
        multiCheck: true,
        items: [
          ...optionalArrayItem(showEnableComments, {
            id: CardProps.allowComments,
            label: t('cardFilter.enable-comments'),
            value: CardProps.allowComments,
          }),
          {
            id: CardProps.allowCardReactions,
            label: t('cardFilter.enable-reactions'),
            value: CardProps.allowCardReactions,
          },
          {
            id: CardProps.allowSharing,
            label: t('cardFilter.enable-sharing'),
            value: CardProps.allowSharing,
          },
          {
            id: CardProps.shouldUpdatePostedTimeByComments,
            label: t('cardFilter.update-time-by-comments'),
            value: CardProps.shouldUpdatePostedTimeByComments,
          },
          ...optionalArrayItem(showDisplayUser, {
            id: CardProps.showAllAuthors,
            label: t('cardFilter.display-user'),
            value: CardProps.showAllAuthors,
          }),
        ],
      },
      {
        id: CARD_LOCALE,
        title: t('cardFilter.default-language'),
        value: localeValue,
        dropdown: {
          options: locales.map(({ id, code }) => {
            return { id, title: LanguageByCode[code as keyof typeof LanguageByCode] };
          }),
        },
      },
    ] as FilterGroup[],
  };
};

import { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedCardAdmin } from '../../../components';
import {
  COLLAPSE_MODE_STORAGE_KEY,
  COPY_CUT_COOKIE_KEY,
  CollapseMode,
  CopyCutAction,
  FeedCardCollapsedLoader,
  FeedCardDrag,
  FeedCardLoader,
  STORY_TAB_ITEMS_ADMIN_COOKIE_KEY,
  useAppDispatch,
  useAppSelector,
  useLastDate,
} from '../../../shared';
import { getStoryFeed, getStoryFeedLoadMore, updateStoryFeedCardPosition } from '../../../slices';
import { NoContent } from '../../Error';

import classes from './StoryFeedAdmin.module.scss';

interface StoryFeedAdminProps {
  storyId: number;
}

export const StoryFeedAdmin: FunctionComponent<StoryFeedAdminProps> = memo(({ storyId }) => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const [cookies] = useCookies([COPY_CUT_COOKIE_KEY, STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]);

  const { cardId: copyCutCardId = 0, action: copyCutAction = '' } = {
    ...cookies[COPY_CUT_COOKIE_KEY],
  };

  const { requestFilter = { status: [] } } = {
    ...cookies[STORY_TAB_ITEMS_ADMIN_COOKIE_KEY],
  };

  const { dateRange, ...requestFilterNoDateRange } = requestFilter; //TODO

  const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
  const from = date?.from ?? null;
  const to = date?.to ?? null;

  const filter = useMemo(() => {
    return {
      ...requestFilterNoDateRange,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [from, requestFilterNoDateRange, to]);

  const [collapseMode, setCollapseMode] = useState(
    localStorage.getItem(COLLAPSE_MODE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  const isCollapsed = useMemo(() => collapseMode === CollapseMode.COLLAPSED, [collapseMode]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setCollapseMode(localStorage.getItem(COLLAPSE_MODE_STORAGE_KEY) || CollapseMode.STANDARD);
    });
  }, []);

  const [dragId, setDragId] = useState<number | null>(null);

  const [dropProcessing, setDropProcessing] = useState<boolean>(false);

  const {
    storyFeed: cards,
    page,
    hasNextPage,
    isFetching,
  } = useAppSelector(({ storyFeed }) => storyFeed);

  useEffect(() => {
    dispatch(getStoryFeed({ storyId, filter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storyId]);

  const loadMore = useCallback(() => {
    dispatch(getStoryFeedLoadMore({ storyId, page: page + 1, filter }));
  }, [dispatch, filter, page, storyId]);

  const hasCards = useMemo(() => Boolean(cards.length), [cards.length]);

  const loader = useMemo(
    () => (isCollapsed ? <FeedCardCollapsedLoader /> : <FeedCardLoader />),
    [isCollapsed]
  );

  const content = useMemo(() => {
    if (!hasCards) {
      return <NoContent />;
    }
    return (
      <InfiniteScroll
        next={loadMore}
        hasMore={hasNextPage}
        loader={loader}
        dataLength={cards.length}
        className={classes['story-feed']}
      >
        <div className={classes['story-feed__cards']}>
          {cards.map((card) => {
            const { storyId, id: storyCardId, position, permissions } = card;
            const { allowToUpdatePosition } = permissions;

            const isCutCard = copyCutAction === CopyCutAction.CUT && storyCardId === copyCutCardId;

            if (isCutCard || !allowToUpdatePosition) {
              return <FeedCardAdmin key={storyCardId} card={card} />;
            }

            return (
              <FeedCardDrag
                key={storyCardId}
                storyId={storyId}
                storyCardId={storyCardId}
                dragId={dragId}
                setDragId={setDragId}
                dropProcessing={dropProcessing}
                setDropProcessing={setDropProcessing}
                position={position}
                updatePosition={updateStoryFeedCardPosition}
              >
                <FeedCardAdmin card={card} />
              </FeedCardDrag>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  }, [
    cards,
    copyCutAction,
    copyCutCardId,
    dragId,
    dropProcessing,
    hasCards,
    hasNextPage,
    loadMore,
    loader,
  ]);

  if (isFetching) {
    return loader;
  }

  return content;
});

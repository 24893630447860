import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Switch, isPushNotificationsAllowed, useAppDispatch, useAppSelector } from '../../shared';
import { channelNotificationPreferenceUpdate } from '../../slices';

import classes from './PushNotifications.module.scss';

export const PushNotifications: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { channels } = useAppSelector(({ channels }) => channels);

  const [notificationsEnabled, setNotificationsEnabled] = useState(
    Notification.permission === 'granted'
  );

  useEffect(() => {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then((status) => {
        status.onchange = () => {
          setNotificationsEnabled(status.state === 'granted');
        };
      });
    }
  }, []);

  const onPermissionChange = useCallback(async () => {
    if (Notification.permission !== 'default') {
      toast(t('pushNotifications.browser-settings'));
      return;
    }

    Promise.resolve(Notification.requestPermission());
  }, [t]);

  const onChannelPermissionChange = useCallback(
    async ({
      channelId,
      allowToReceiveChannelNotification,
    }: {
      channelId: number;
      allowToReceiveChannelNotification: boolean;
    }) => {
      try {
        const { updated } = await dispatch(
          channelNotificationPreferenceUpdate({ channelId, allowToReceiveChannelNotification })
        ).unwrap();

        if (!updated) {
          toast.error(t('pushNotifications.channel-preference-update-error'));
        }
      } catch (_) {
        toast.error(t('pushNotifications.channel-preference-update-error'));
      }
    },
    [dispatch, t]
  );

  const renderChannelList = useMemo(() => {
    if (!notificationsEnabled) {
      return null;
    }

    return (
      <div className={classes['push-notifications__channel-list']}>
        <div className={classes['push-notifications__channel-list-title']}>
          {t('common.channel-list')}
        </div>
        <div className={classes['push-notifications__channel-list-items']}>
          {channels.map(
            ({
              id: channelId,
              name,
              userNotificationPreference: { allowToReceiveChannelNotification },
            }) => {
              return (
                <Switch
                  key={channelId}
                  label={name}
                  inputId={name}
                  checked={allowToReceiveChannelNotification}
                  onChange={() =>
                    onChannelPermissionChange({
                      channelId,
                      allowToReceiveChannelNotification: !allowToReceiveChannelNotification,
                    })
                  }
                  className={classes['push-notifications__channel-list-items-switch']}
                />
              );
            }
          )}
        </div>
      </div>
    );
  }, [channels, notificationsEnabled, onChannelPermissionChange, t]);

  if (!isPushNotificationsAllowed) {
    navigate('/notFound');
    return null;
  }

  return (
    <div className={classes['push-notifications']}>
      <div>
        <div className={classes['push-notifications__title']}>
          {t('settings.push-notifications')}
        </div>
        <div className={classes['push-notifications__subTitle']}>
          {t('pushNotifications.sub-title')}
        </div>
      </div>

      <div className={classes['push-notifications__main']}>
        <Switch
          label={t('common.notification')}
          className={classes['push-notifications__main-switch']}
          inputId={'notification'}
          checked={notificationsEnabled}
          onChange={onPermissionChange}
        />
      </div>
      {renderChannelList}
    </div>
  );
};

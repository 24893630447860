import {
  Fragment,
  MouseEvent,
  RefObject,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import { Comment, CommentAttachment } from '../../../services';
import {
  addComment,
  deleteComment,
  editComment,
  hideComment,
  highlightComment,
  loadMoreComments,
  reactOnComment,
} from '../../../slices';
import { COMMENTS_SIZE } from '../../constants';
import { useAppDispatch, useFreeLoginPopup } from '../../hooks';
import { getCssVar, isInViewport } from '../../utils';
import { IconLabel } from '../IconLabel';
import { CommentBox } from './CommentBox';
import { CommentItem } from './CommentItem';

import classes from './Comments.module.scss';

interface CommentsProps {
  storyId: number;
  cardId: number;
  comments: Comment[];
  commentsRootCount: number;
  loadMoreCustomAction?: () => void;
  wrapper?: HTMLElement;
}

export interface CommentPostProps {
  content: string;
  attachments: CommentAttachment[];
}

interface CommentEditProps extends CommentPostProps {
  id: number;
}

export const Comments = memo(
  forwardRef<HTMLTextAreaElement, CommentsProps>(
    (
      { storyId, cardId, comments, commentsRootCount, loadMoreCustomAction, wrapper },
      commentBoxRef
    ) => {
      const { t } = useTranslation();

      const dispatch = useAppDispatch();

      const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup();

      const { isAnonymous } = useContext(UserContext).userProfile;

      const replyCommentBoxRef = useRef<HTMLTextAreaElement>(null);

      const editCommentBoxRef = useRef<HTMLTextAreaElement>(null);

      const [commentIdToReply, setCommentIdToReply] = useState<number>(0);

      const [commentIdToEdit, setCommentIdToEdit] = useState<number>(0);

      const [loading, setLoading] = useState(false);

      const resetCommentIdToReply = useCallback(() => {
        setCommentIdToReply(0);
      }, []);

      const resetCommentIdToEdit = useCallback(() => {
        setCommentIdToEdit(0);
      }, []);

      const loadMoreClickHandler = useCallback(
        async (event: MouseEvent<HTMLDivElement>, parentId?: number) => {
          try {
            setLoading(true);

            if (typeof loadMoreCustomAction === 'function') {
              loadMoreCustomAction();
              return;
            }

            const { top } = event.currentTarget.getBoundingClientRect();

            const loadedItems = parentId
              ? comments.find(({ id }) => id === parentId)?.replies ?? []
              : comments;

            await dispatch(
              loadMoreComments({
                parentId,
                storyId,
                itemId: cardId,
                size: COMMENTS_SIZE,
                page: Math.floor(loadedItems.length / COMMENTS_SIZE) + 1,
              })
            );

            (wrapper ?? window).scrollBy({ top: top - 80, behavior: 'smooth' });
          } catch (_) {
            toast.error(t('comments.load-more-error'));
          } finally {
            setLoading(false);
          }
        },
        [cardId, comments, dispatch, loadMoreCustomAction, storyId, t, wrapper]
      );

      const renderCommentItem = useCallback(
        ({
          comment,
          parentId,
          replyId,
        }: {
          comment: Comment;
          parentId?: number;
          replyId?: number;
        }) => {
          const onLike = (commentId: number) => {
            if (isAnonymous) {
              freeLoginHandler();
              return;
            }
            dispatch(reactOnComment({ storyId, cardId, commentId }));
          };

          const onReply = () => {
            setCommentIdToReply(parentId ?? comment.id);
            resetCommentIdToEdit();
          };

          const onEdit = () => {
            setCommentIdToEdit(replyId ?? comment.id);
            resetCommentIdToReply();
          };

          const onHide = (commentId: number) => {
            dispatch(hideComment({ storyId, commentId }));
          };

          const onHighlight = (commentId: number) => {
            dispatch(highlightComment({ storyId, commentId }));
          };

          const onDelete = (commentId: number) => {
            dispatch(deleteComment({ storyId, cardId, commentId, parentId }));
          };

          return (
            <CommentItem
              comment={comment}
              onLike={onLike}
              onReply={onReply}
              onEdit={onEdit}
              onDelete={onDelete}
              onHide={onHide}
              onHighlight={onHighlight}
              isParentHidden={comments.find(({ id }) => id === parentId)?.isHidden ?? false}
            />
          );
        },
        [
          cardId,
          comments,
          dispatch,
          freeLoginHandler,
          isAnonymous,
          resetCommentIdToEdit,
          resetCommentIdToReply,
          storyId,
        ]
      );

      const viewMoreComments = useMemo(
        () => commentsRootCount > comments.length,
        [comments.length, commentsRootCount]
      );

      const viewMoreCommentsCount = useMemo(
        () => Math.min(COMMENTS_SIZE, commentsRootCount - comments.length),
        [comments.length, commentsRootCount]
      );

      const renderViewMoreComments = useMemo(() => {
        if (!viewMoreComments) {
          return null;
        }

        return (
          <IconLabel
            className={classes['comments__view-more-comments']}
            label={t('comments.view-more', { count: viewMoreCommentsCount })}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={loadMoreClickHandler}
            disabled={loading}
          />
        );
      }, [loadMoreClickHandler, loading, t, viewMoreComments, viewMoreCommentsCount]);

      const renderViewMoreReplies = useCallback(
        ({
          id,
          viewMoreReplies,
          viewMoreRepliesCount,
        }: {
          id: number;
          viewMoreReplies: boolean;
          viewMoreRepliesCount: number;
        }) => {
          if (!viewMoreReplies) {
            return null;
          }

          return (
            <IconLabel
              className={classes['comments__view-more-replies']}
              label={t('comments.view-more-replies', { count: viewMoreRepliesCount })}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
              onClick={(event) => loadMoreClickHandler(event, id)}
              disabled={loading}
            />
          );
        },
        [loadMoreClickHandler, loading, t]
      );

      const postCommentHandler = useCallback(
        (parentId?: number) => {
          return async ({ content, attachments }: CommentPostProps) => {
            await dispatch(addComment({ storyId, itemId: cardId, content, attachments, parentId }));
            resetCommentIdToReply();
          };
        },
        [cardId, dispatch, resetCommentIdToReply, storyId]
      );

      const editCommentHandler = useCallback(
        (commentId: number) => {
          return async ({ content, attachments }: CommentPostProps) => {
            await dispatch(editComment({ storyId, commentId, content, attachments }));
            resetCommentIdToEdit();
          };
        },
        [dispatch, resetCommentIdToEdit, storyId]
      );

      const renderCommentEdit = useCallback(
        ({ id, content, attachments }: CommentEditProps) => {
          return (
            <CommentBox
              ref={editCommentBoxRef}
              content={content}
              attachments={attachments}
              onSubmit={() => editCommentHandler(id)}
              onClose={resetCommentIdToEdit}
            />
          );
        },
        [editCommentHandler, resetCommentIdToEdit]
      );

      const setInputFocus = (inputRef: RefObject<HTMLTextAreaElement>, id: number) => {
        if (!inputRef.current || !id) {
          return;
        }

        const input = inputRef.current;

        if (input && !isInViewport(input)) {
          input.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

        input.focus({ preventScroll: true });
        input.setSelectionRange(input.value.length, input.value.length);
      };

      useEffect(() => {
        setInputFocus(replyCommentBoxRef, commentIdToReply);
        setInputFocus(editCommentBoxRef, commentIdToEdit);
      }, [commentIdToEdit, commentIdToReply]);

      return (
        <>
          <div className={classes['comments']}>
            <CommentBox ref={commentBoxRef} onSubmit={postCommentHandler} />

            {Boolean(commentsRootCount > 0) && (
              <div className={classes['comments__list']}>
                {comments.map((comment) => {
                  const { id, content, replies, repliesCount, attachments } = comment;

                  return (
                    <div key={id} className={classes['comments__list-comment']}>
                      {renderCommentItem({ comment })}
                      {id === commentIdToEdit && renderCommentEdit({ id, content, attachments })}

                      {Boolean(replies.length) && (
                        <div className={classes['comments__list-reply']}>
                          {replies.map((reply) => {
                            const {
                              id: replyId,
                              content: replyContent,
                              attachments: replyAttachments,
                            } = reply;

                            return (
                              <Fragment key={replyId}>
                                {renderCommentItem({ comment: reply, parentId: id, replyId })}
                                {replyId === commentIdToEdit &&
                                  renderCommentEdit({
                                    id: replyId,
                                    content: replyContent,
                                    attachments: replyAttachments,
                                  })}
                              </Fragment>
                            );
                          })}
                        </div>
                      )}
                      {id === commentIdToReply && (
                        <div className={classes['comments__list-reply-active']}>
                          <CommentBox
                            ref={replyCommentBoxRef}
                            onSubmit={() => postCommentHandler(id)}
                            onClose={resetCommentIdToReply}
                          />
                        </div>
                      )}
                      {renderViewMoreReplies({
                        id,
                        viewMoreReplies: repliesCount > replies.length,
                        viewMoreRepliesCount: Math.min(
                          COMMENTS_SIZE,
                          repliesCount - replies.length
                        ),
                      })}
                    </div>
                  );
                })}
                {renderViewMoreComments}
              </div>
            )}
          </div>

          {freeLoginModal}
        </>
      );
    }
  )
);

import {
  StoryCard,
  StoryCardContentArticlePost,
  StoryCardContentText,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../services';

export const getStoryCardSource = (card: StoryCard) => {
  const { type, content } = card;

  switch (type) {
    case StoryCardType.EDITORIAL:
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF:
      return (content as StoryCardContentText).sourceName;
    case StoryCardType.ARTICLE:
    case StoryCardType.POST:
      return (content as StoryCardContentArticlePost).sourceName;
    case StoryCardType.QUOTE: {
      const { quotePersonHandle, quoteSource } = content as StoryCardQuoteContent;
      return `${quotePersonHandle} via ${quoteSource?.toLowerCase()}`;
    }
    default:
      return '';
  }
};

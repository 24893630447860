/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmbedLink } from '../../../../../components';
import { Story } from '../../../../../services';
import { StoryBlockData } from '../../../../models';
import { getDeepLinkUrl } from '../../../../utils';
import { DeeplinkType } from '../../../Deeplink';
import { BaseBlockTool } from '../baseBlockTool';
import { ResetBlockTool } from '../helpers';
import { StorySelect } from './StorySelect';

interface State {
  story: Story | null;
}

type Config = {
  dispatch: any;
  channelId: number;
};

export class StoryTool extends BaseBlockTool<State, StoryBlockData, Config> {
  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {
      title: 'Mix',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M7.33203 8.16663H20.6654" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round"/>
        <path d="M7.33203 19.8333H20.6654" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round"/>
        <rect x="7.3" y="11.4666" width="13.4" height="5.06667" rx="1.2" stroke="#1E1E1E" stroke-width="1.6" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.story) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ story: null })} />;
  }

  getJSXTool(): JSX.Element {
    const { story } = this.state;

    const { dispatch, channelId = 0 } = this.config;

    if (story) {
      const { id: storyId, title, subtitle: text, image } = story;

      const url = getDeepLinkUrl({ type: DeeplinkType.STORY, channelId, storyId });

      return <EmbedLink link={{ url, title, text, image }} hideUrl />;
    }

    return (
      <StorySelect
        dispatch={dispatch}
        channelId={channelId}
        story={story}
        setStory={(story) => this.setState({ story })}
        placeholder={this.api.i18n.t('search')}
      />
    );
  }

  getDefaultState(data?: StoryBlockData): State {
    return { story: data?.story ?? null };
  }

  validate({ story }: StoryBlockData): boolean {
    return Boolean(story);
  }

  save(): StoryBlockData {
    return { story: this.state.story };
  }
}

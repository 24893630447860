import { FunctionComponent, memo } from 'react';
import { linkify } from '../../../../shared';

import classNames from 'classnames';
import classes from './Paragraph.module.scss';

interface ParagraphProps {
  text: string;
  useLinkify?: boolean;
  source?: boolean;
}

export const Paragraph: FunctionComponent<ParagraphProps> = memo(({ text, useLinkify, source }) => {
  const content = text.replace(/href/g, "target='_blank' href");

  return (
    <p
      className={classNames(classes['paragraph'], {
        [classes['paragraph--source']]: source,
      })}
      dangerouslySetInnerHTML={{ __html: useLinkify ? linkify(content) : content }}
    ></p>
  );
});

import { FunctionComponent, memo } from 'react';
import { StoryCardImageContent } from '../../../services';
import { FeedCardAdminContent, FeedCardImageCommon, FeedCardProps } from '../../../shared';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';

export const FeedCardAdminImage: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardImageContent;

  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardAdminContent
        storyId={storyId}
        cardId={id}
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={headline}
        media={<FeedCardImageCommon card={card} />}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      />
    </FeedCardAdminWrapper>
  );
});

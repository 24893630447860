import { CalloutBlockData } from '../../../../models';
import { isEmptyString } from '../../../../utils';
import { BaseBlockTool } from '../baseBlockTool';

import classes from './Callout.module.scss';

type Config = {
  placeholder?: string;
};

export class Callout extends BaseBlockTool<CalloutBlockData, CalloutBlockData, Config> {
  static get toolbox() {
    return {
      title: 'Callout',
      icon: `
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
          <rect width="14" height="14" x="5" y="5" stroke="currentColor" stroke-width="2" rx="4"/>
          <line x1="12" x2="12" y1="9" y2="12" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M12 15.02V15.01"/>
        </svg>`,
    };
  }

  getJSXTool(): JSX.Element {
    return (
      <div className={classes['callout']}>
        <div
          contentEditable
          className={classes['callout__input']}
          dangerouslySetInnerHTML={{ __html: this.state.text }}
          onBlur={({ target }) => (this.state.text = target.innerHTML)}
        />
      </div>
    );
  }

  save(): CalloutBlockData {
    const { text, icon } = this.state;
    return { text, icon };
  }

  validate({ text }: CalloutBlockData): boolean {
    return !isEmptyString(text);
  }

  getDefaultState(data?: CalloutBlockData): CalloutBlockData {
    return {
      text: data?.text ?? '',
      icon: data?.icon ?? 'warning',
    };
  }

  static get sanitize() {
    return {
      text: { br: true },
      icon: false,
    };
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComputedBarDatum } from '@nivo/bar';

export const getHorizontalBarsLayer = ({ bars }: { bars: readonly ComputedBarDatum<any>[] }) => {
  return (
    <g>
      {bars.map(({ y, width, height, data }, index) => {
        return (
          <text
            key={`bar-${index}`}
            fontSize={14}
            transform={`translate(${width + 5}, ${y + height / 2})`}
            dominantBaseline={'middle'}
          >
            {data.value}
          </text>
        );
      })}
    </g>
  );
};

import { forwardRef, memo, useCallback } from 'react';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

interface IconLabelWithActiveProps {
  active: boolean;
  setActive: (active: boolean) => void;
  label?: string;
  iconId?: string;
  iconSize?: number;
  className?: string;
  onActive?: () => void;
  disabled?: boolean;
}

export const IconLabelWithActive = memo(
  forwardRef<HTMLDivElement, IconLabelWithActiveProps>(
    ({ active, setActive, label, iconId, iconSize, className, onActive, disabled }, ref) => {
      const prefix = '--icon-label-with-active';

      const onClick = useCallback(() => {
        if (!active) {
          onActive?.();
        }
        setActive(!active);
      }, [active, onActive, setActive]);

      return (
        <IconLabel
          ref={ref}
          iconId={iconId}
          iconSize={iconSize}
          label={label}
          color={getCssVar(active ? `${prefix}-text-active-color` : `${prefix}-text-color`)}
          hoverColor={getCssVar(`${prefix}-text-active-color`)}
          className={className}
          disabled={disabled}
          withPadding
          onClick={onClick}
          {...(active && { backgroundColor: getCssVar(`${prefix}-background-active-color`) })}
        />
      );
    }
  )
);

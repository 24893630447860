/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Badge, DropdownMenu, getCssVar, IconLabel, Popup } from '../../../shared';
import { useDebounce, useOnClickOutside } from '../../hooks';
import { InputField } from '../InputField';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import sprite from '../../../assets/icons/sprite.svg';

import classNames from 'classnames';
import dropdownMenuClasses from '../../components/DropdownMenu/DropdownMenu.module.scss';
import classes from './AutoComplete.module.scss';

interface AutoCompleteProps {
  value: any[];
  propToIdentify: string;
  propToDisplay: string;
  fetchQuery: (query: string, page?: number) => any;
  itemTemplate: (item: any) => JSX.Element;
  onChange: (value: any[]) => void;
  excludeLabel?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  isStatic?: boolean;
  autoFocus?: boolean;
}

export const AutoComplete: FunctionComponent<AutoCompleteProps> = memo(
  ({
    value,
    propToIdentify,
    propToDisplay,
    itemTemplate,
    fetchQuery,
    onChange,
    excludeLabel,
    placeholder,
    className,
    disabled,
    isStatic,
    autoFocus,
  }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [showList, setShowList] = useState<boolean>(false);

    const [list, setList] = useState<any[]>([]);

    const [staticList, setStaticList] = useState<any[]>([]);

    const [pageInfo, setPageInfo] = useState({ page: 1, hasNextPage: false });

    const [input, setInput] = useState<string>('');

    const debounced = useDebounce<string>(input);

    const containerRef = useRef<HTMLDivElement>(null);

    const excludeActive = useMemo(
      () => Boolean(value.find((val) => val[propToIdentify] === null)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [value]
    );

    const isDisabled = useMemo(() => Boolean(disabled || excludeActive), [disabled, excludeActive]);

    //EditorJS fix to hide autocomplete list on settings click
    useEffect(() => {
      const editorSettingsClick = () => setShowList(false);

      document
        .querySelector('.ce-toolbar__settings-btn')
        ?.addEventListener('click', editorSettingsClick);

      return () => {
        document
          .querySelector('.ce-toolbar__settings-btn')
          ?.removeEventListener('click', editorSettingsClick);
      };
    }, []);

    useOnClickOutside(containerRef, () => {
      setShowList(false);
    });

    const fetchItems = useCallback(async () => {
      setLoading(true);

      const response = await fetchQuery(debounced).unwrap();

      if (isStatic) {
        setList(response);
        setStaticList(response);
        setLoading(false);
        return;
      }

      const { items = null, pageInfo = null } = { ...response };

      items && setList(items);

      pageInfo && setPageInfo(pageInfo);

      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced, fetchQuery]);

    //call on debounced change
    useEffect(() => {
      if (isStatic) {
        setList(
          staticList.filter((item) =>
            item[propToDisplay].toLowerCase().startsWith(debounced.toLowerCase())
          )
        );
        return;
      }

      fetchItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    //call once for static list case
    useEffect(() => {
      if (!isStatic) {
        return;
      }

      fetchItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const itemDelete = useCallback(
      (item: any) => {
        onChange(value.filter((valItem) => valItem[propToIdentify] !== item[propToIdentify]));
      },
      [onChange, propToIdentify, value]
    );

    const itemClick = useCallback(
      (item: any) => {
        value.find((valItem) => valItem[propToIdentify] === item[propToIdentify])
          ? itemDelete(item)
          : onChange([...value, item]);
      },
      [itemDelete, onChange, propToIdentify, value]
    );

    const loadMore = useCallback(() => {
      fetchQuery(debounced, pageInfo.page + 1).then(({ data }: any) => {
        setList([...list, ...data.items]);
        setPageInfo(data.pageInfo);
      });
    }, [debounced, fetchQuery, list, pageInfo.page]);

    const renderListItems = useMemo(() => {
      if (loading) {
        return <div className={classes['autocomplete__loading']}>{t('common.loading')}</div>;
      }

      if (!list.length) {
        return <div className={classes['autocomplete__empty']}>{t('common.nothing-found')}</div>;
      }

      return (
        <InfiniteScroll
          className={classes['autocomplete__infinite-scroll']}
          dataLength={list.length}
          hasMore={pageInfo.hasNextPage}
          next={loadMore}
          loader={<Skeleton height={'3rem'} />}
          height={'13.5rem'}
          hasChildren
        >
          {list.map((item) => {
            const isSelected = value.find(
              (valItem) => valItem[propToIdentify] === item[propToIdentify]
            );

            return (
              <div
                key={item[propToIdentify]}
                onClick={() => itemClick(item)}
                className={classNames(
                  classes['autocomplete__list-item'],
                  dropdownMenuClasses['dropdown-menu__item']
                )}
              >
                {itemTemplate(item)}
                {isSelected && (
                  <svg className={classes['autocomplete__list-item-check']}>
                    <use href={`${sprite}#checkmark`} />
                  </svg>
                )}
              </div>
            );
          })}
        </InfiniteScroll>
      );
    }, [
      itemTemplate,
      itemClick,
      list,
      loadMore,
      pageInfo.hasNextPage,
      propToIdentify,
      t,
      value,
      loading,
    ]);

    const onExcludeClick = useCallback(() => {
      onChange([{ [propToIdentify]: null, [propToDisplay]: excludeLabel }]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <div ref={containerRef} className={classNames(classes['autocomplete'], className)}>
        {Boolean(value.length) && (
          <div className={classes['autocomplete__value']}>
            {value.map((item) => (
              <Badge
                key={item[propToIdentify]}
                value={item[propToDisplay]}
                onRemove={() => itemDelete(item)}
                disabled={disabled}
              />
            ))}
          </div>
        )}
        <InputField
          value={input}
          onChange={({ target }) => setInput(target.value)}
          onFocus={() => setShowList(true)}
          placeholder={placeholder}
          disabled={isDisabled}
          autoFocus={autoFocus}
        />
        <Popup
          isOpen={showList}
          bodyTop={'0.5rem'}
          bodyLeft={'0.5rem'}
          bodyWidth={'calc(100% - 1rem)'}
          body={<DropdownMenu content={renderListItems} />}
        />
        {excludeLabel && (
          <IconLabel
            label={excludeLabel}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={onExcludeClick}
            disabled={excludeActive}
          />
        )}
      </div>
    );
  }
);

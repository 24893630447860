import { ResponsiveFunnel } from '@nivo/funnel';
import { differenceInDays, subDays } from 'date-fns';
import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserFunnelV2Query } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, StatsItem } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserFunnelV2: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { t } = useTranslation();

  const { data: userFunnelV2, isFetching: isFetchingUserFunnelV2 } = useUserFunnelV2Query({
    from,
    to,
    platform,
  });

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: userFunnelV2Prev, isFetching: isFetchingUserFunnelV2Prev } = useUserFunnelV2Query({
    from: subDays(new Date(from), daysDiff).toISOString(),
    to: subDays(new Date(to), daysDiff).toISOString(),
    platform,
  });

  const { items = [] } = { ...userFunnelV2 };

  const { items: itemsPrev = [] } = { ...userFunnelV2Prev };

  const renderStats = useMemo(() => {
    if (isFetchingUserFunnelV2 || isFetchingUserFunnelV2Prev) {
      return <Loader stats />;
    }

    if (!userFunnelV2 || !userFunnelV2Prev) {
      return null;
    }

    return (
      <div className={classes['analytics__chart-stats']}>
        {items.map(({ label, value }) => {
          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`userFunnelLabels.${label}`}
              hintLocaliseKey={`userFunnelHints.${label}`}
              value={value}
              valuePrev={itemsPrev.find((dataset) => dataset.label === label)?.value ?? 0}
              isInline
            />
          );
        })}
      </div>
    );
  }, [
    isFetchingUserFunnelV2,
    isFetchingUserFunnelV2Prev,
    items,
    itemsPrev,
    userFunnelV2,
    userFunnelV2Prev,
  ]);

  const renderChart = useMemo(() => {
    if (isFetchingUserFunnelV2) {
      return <Loader />;
    }

    if (!userFunnelV2) {
      return null;
    }

    if (!items.length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const data = items.map(({ label, value }) => {
      const localizedLabel = t(`userFunnelLabels.${label}`);

      return {
        id: localizedLabel,
        label: localizedLabel,
        value: Math.round((100 * value) / items[0]?.value),
      };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsiveFunnel
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          data={data}
          colors={COLORS}
          direction={'horizontal'}
          labelColor={{ from: 'color', modifiers: [['darker', 3]] }}
          beforeSeparatorLength={100}
          afterSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          valueFormat={(value) => `${value}%`}
          theme={{ labels: { text: { fontSize: 14 } } }}
        />
      </div>
    );
  }, [isFetchingUserFunnelV2, items, t, userFunnelV2]);

  return (
    <ChartWrapper typeLocaliseKey={'chartTypes.user-funnel'} infoLocaliseKey={'userFunnel.info'}>
      {renderStats}
      {renderChart}
    </ChartWrapper>
  );
});

import { FunctionComponent, memo, useCallback } from 'react';
import { ListBlockData, ListBlockElement, ListBlockStyle } from '../../../../shared';

import classNames from 'classnames';
import classes from './List.module.scss';

export const List: FunctionComponent<ListBlockData> = memo(({ style, items }) => {
  const ListTag = style === ListBlockStyle.unordered ? 'ul' : 'ol';

  const buildList = useCallback(
    (items: ListBlockElement[], counter: string) => {
      if (!items.length) {
        return;
      }

      return (
        <ListTag className={classNames(classes['list'], classes[ListTag])}>
          {items.map(({ content, items }, index) => {
            const dataCounter = counter ? `${counter}.${index + 1}` : `${index + 1}`;
            const useDataCounter = ListTag === 'ol';

            return (
              <li
                className={classes['list__item']}
                data-counter={useDataCounter ? `${dataCounter}. ` : undefined}
                key={index}
              >
                {content && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: content.replace(/href/g, "target='_blank' href"),
                    }}
                  ></span>
                )}
                {buildList(items, dataCounter)}
              </li>
            );
          })}
        </ListTag>
      );
    },
    [ListTag]
  );

  return <>{buildList(items, '')}</>;
});

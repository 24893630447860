import InfiniteScroll from 'react-infinite-scroll-component';
import { ElementType, FunctionComponent, memo, useMemo } from 'react';
import { useAppSelector, useNewsFeed } from '../../hooks';
import { HorizontalStoryFeedData, NewsFeedType, StoryCardItemData } from '../../../services';
import { PostedIn } from '../../../components';
import { HorizontalStoryFeedProps } from '../HorizontalFeed';
import { FeedCardProps } from '../FeedCard';
import { isAdminLayout } from '../../utils';

import classes from './NewsFeedCommon.module.scss';

interface NewsFeedCommonProps {
  loader: JSX.Element;
  loadMore: () => void;
  HorizontalStoryFeedComponent: ElementType<HorizontalStoryFeedProps>;
  CardComponent: ElementType<FeedCardProps>;
}

export const NewsFeedCommon: FunctionComponent<NewsFeedCommonProps> = memo(
  ({ loader, loadMore, HorizontalStoryFeedComponent, CardComponent }) => {
    const { newsFeed, hasNextPage } = useAppSelector(({ newsFeed }) => newsFeed);

    const { pinnedStorySection } = useNewsFeed();

    const hidePostedIn = useMemo(() => isAdminLayout() && pinnedStorySection, [pinnedStorySection]);

    return (
      <InfiniteScroll
        next={loadMore}
        hasMore={hasNextPage}
        loader={loader}
        dataLength={newsFeed.length}
        className={classes['feed']}
        style={{ overflowX: 'hidden' }} //needed because of horizontal feed card actions
      >
        <div className={classes['feed__cards']}>
          {newsFeed.map((newsFeedItem, index, newsFeedArray) => {
            switch (newsFeedItem.type) {
              case NewsFeedType.HORIZONTAL_STORY_FEED: {
                const data = newsFeedItem.data as HorizontalStoryFeedData;
                const { items, pageInfo, storyId } = data;

                if (!items.length) {
                  return null;
                }

                return (
                  <HorizontalStoryFeedComponent
                    key={`horizontal-story-feed-${storyId}`}
                    story={items[0].story}
                    cards={items}
                    pageInfo={pageInfo}
                    zIndex={newsFeed.length - index} //needed for card actions
                  />
                );
              }
              case NewsFeedType.STORY_CARD_ITEM: {
                const card = (newsFeedItem.data as StoryCardItemData).item;
                const prevItem = newsFeedArray[index - 1];

                return (
                  <div key={`story-card-item-${card.id}`}>
                    {!hidePostedIn &&
                      (prevItem?.type !== NewsFeedType.STORY_CARD_ITEM ||
                        (prevItem?.type === NewsFeedType.STORY_CARD_ITEM &&
                          (prevItem.data as StoryCardItemData).item.story.title !==
                            card.story.title)) && <PostedIn story={card.story} />}
                    <CardComponent card={card} />
                  </div>
                );
              }
              default:
                return null;
            }
          })}
        </div>
      </InfiniteScroll>
    );
  }
);

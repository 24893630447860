import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { organisationApi, OrganisationTag, OrganisationTagType } from '../../../services';
import { BadgeList, Modal, ORG_TAGS_NAME_LENGTH_LIMIT, ORG_TAGS_SIZE_LIMIT } from '../../../shared';
import { TagEdit } from './TagEdit';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './General.module.scss';

export const General: FunctionComponent = () => {
  const { t } = useTranslation();

  const [organisationTags] = organisationApi.endpoints.organisationTags.useLazyQuery();

  const [organisationTagCreate] = organisationApi.endpoints.organisationTagCreate.useLazyQuery();

  const [organisationTagUpdate] = organisationApi.endpoints.organisationTagUpdate.useLazyQuery();

  const [organisationTagDelete] = organisationApi.endpoints.organisationTagDelete.useLazyQuery();

  const [tags, setTags] = useState<OrganisationTag[]>();

  const [tagToEdit, setTagToEdit] = useState<OrganisationTag | null>(null);

  const [loading, setLoading] = useState(false);

  const getTags = useCallback(async () => {
    setLoading(true);

    const { items: tags } = await organisationTags({
      size: ORG_TAGS_SIZE_LIMIT,
      filters: {
        types: [
          OrganisationTagType.INTERNAL,
          OrganisationTagType.EXTERNAL,
          OrganisationTagType.USER,
        ],
      },
    }).unwrap();

    setTags(tags);

    setLoading(false);
  }, [organisationTags]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const onCreate = useCallback(
    async ({ name, type }: { name: string; type: OrganisationTagType }) => {
      const { error } = await organisationTagCreate({ name, type }).unwrap();

      if (error) {
        switch (error.__typename) {
          case 'OrganisationTagConflictNameError':
            return toast.error(t('organisationTags.tag-exists'));
          case 'OrganisationTagsReachedLimitError':
            return toast.error(t('organisationTags.tag-limit'));
        }
        return;
      }

      getTags();
    },
    [getTags, organisationTagCreate, t]
  );

  const onEdit = useCallback(
    async ({ id, name, type }: OrganisationTag) => {
      try {
        const { payload } = await organisationTagUpdate({ tagId: id, name, type }).unwrap();

        if (!payload) {
          toast.error(t('organisationTags.tag-exists'));
          return;
        }

        setTagToEdit(null);
        getTags();
      } catch (_) {
        toast.error(t('organisationTags.tag-empty'));
      }
    },
    [getTags, organisationTagUpdate, t]
  );

  const onRemove = useCallback(
    async (id: number) => {
      const payload = await organisationTagDelete({ tagId: id }).unwrap();

      if (!payload) {
        toast.error(t('organisationTags.delete-error'));
        return;
      }

      getTags();
    },
    [getTags, organisationTagDelete, t]
  );

  const renderTags = useCallback(
    (type: OrganisationTagType) => {
      const tagsByType = tags?.filter((tag) => tag.type === type) ?? [];

      return (
        <div className={classes['general__tags']}>
          <div className={classes['general__tags-title']}>
            {t(`organisationTags.title-${type}`)}
          </div>
          <div className={classes['general__tags-subTitle']}>
            {t(`organisationTags.sub-title-${type}`)}
          </div>
          {loading ? (
            <div className={classes['general__tags-loader']}>
              <Skeleton height={'2.5rem'} width={'6rem'} />
              <Skeleton height={'2.5rem'} width={'6rem'} />
              <Skeleton height={'2.5rem'} width={'6rem'} />
              <Skeleton height={'2.5rem'} width={'6rem'} />
              <Skeleton height={'2.5rem'} width={'6rem'} />
            </div>
          ) : (
            <BadgeList
              items={tagsByType}
              onRemove={(id) => onRemove(id)}
              onCreate={(name) => onCreate({ name, type })}
              placholder={t('organisationTags.add-placeholder')}
              setBadgeToEdit={({ id, name }) => setTagToEdit({ id, name, type })}
              maxLength={ORG_TAGS_NAME_LENGTH_LIMIT}
            />
          )}
        </div>
      );
    },
    [loading, onCreate, onRemove, t, tags]
  );

  return (
    <div className={classes['general']}>
      {renderTags(OrganisationTagType.INTERNAL)}
      {renderTags(OrganisationTagType.EXTERNAL)}
      {renderTags(OrganisationTagType.USER)}

      {tagToEdit &&
        ReactDOM.createPortal(
          <Modal
            isOpen={Boolean(tagToEdit)}
            contentStyle={{ width: '23rem' }}
            body={<TagEdit tag={tagToEdit} onEdit={onEdit} />}
            onClose={() => setTagToEdit(null)}
          />,
          document.getElementById('modal-root') as HTMLElement
        )}
    </div>
  );
};

import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../../context';
import { OrganisationTag, OrganisationTagType } from '../../../services';
import { getCssVar, IconLabel, layoutPath } from '../../../shared';

import classes from './ExternalTagsList.module.scss';

const COLLAPSED_TAGS_LIST_SIZE = 3;

export const ExternalTagsList: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const externalTags = useContext(OrganisationContext).organisation.tags.filter(
    ({ type }) => type === OrganisationTagType.EXTERNAL
  );

  const [collapsed, setCollapsed] = useState(true);

  const renderCollapsible = useMemo(
    () => Boolean(externalTags.length > COLLAPSED_TAGS_LIST_SIZE),
    [externalTags.length]
  );

  const [tags, setTags] = useState<OrganisationTag[]>([]);

  useEffect(() => {
    setTags(collapsed ? externalTags.slice(0, COLLAPSED_TAGS_LIST_SIZE) : externalTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  if (!Boolean(externalTags.length)) {
    return null;
  }

  return (
    <div className={classes['external-tags-list']}>
      <div className={classes['external-tags-list__title']}>{t('externalTagsList.title')}</div>
      {tags.map(({ id, name }) => (
        <IconLabel
          key={id}
          label={`#${name}`}
          className={classes['external-tags-list__item']}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={() => navigate(layoutPath(`/tags/${id}`))}
          disabled={pathname === layoutPath(`/tags/${id}`)}
        />
      ))}
      {renderCollapsible && (
        <div
          className={classes['external-tags-list__collapsible']}
          onClick={() => setCollapsed(!collapsed)}
        >
          {t(`externalTagsList.show-${collapsed ? 'more' : 'less'}`)}
        </div>
      )}
    </div>
  );
};

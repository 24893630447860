import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const Love = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx={15}
        cy={15}
        r={14.5}
        fill="#F25267"
        stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
      />
      <path
        d="M8.03499 15.9837C6.43365 14.3824 6.43365 11.7861 8.03499 10.1848C9.63634 8.58341 12.2326 8.58341 13.834 10.1848L15.2837 11.6345L16.7335 10.1847C18.3348 8.5834 20.9311 8.5834 22.5325 10.1847C24.1338 11.7861 24.1338 14.3824 22.5325 15.9837L15.9908 22.5254C15.6003 22.9159 14.9671 22.9159 14.5766 22.5254L8.03499 15.9837Z"
        fill="white"
      />
    </g>
  </svg>
);

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardEditorialContent } from '../../../../services';

export const HorizontalStoryFeedAdminEditorial: FunctionComponent<HorizontalStoryFeedCardProps> =
  memo(({ card }) => {
    const { content, isPinnedToTopOfNewsFeed } = card;
    const { sourceName, headline, subHeadline, text, textHTML } =
      content as StoryCardEditorialContent;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          source={sourceName}
          title={headline}
          subTitle={subHeadline}
          text={text}
          htmlText={textHTML}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedAdminCardWrapper>
    );
  });

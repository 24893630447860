import { createContext } from 'react';
import { Organisation } from '../services';

export interface OrganisationContextProps {
  organisation: Organisation;
}

export const OrganisationContext = createContext<OrganisationContextProps>(
  {} as OrganisationContextProps
);

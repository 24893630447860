import { FunctionComponent, memo } from 'react';
import { FeedCardProps, FeedCardSocialCommon } from '../../../shared';
import { FeedCardWrapper } from '../FeedCardWrapper';

export const FeedCardSocial: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardWrapper card={card}>
      <FeedCardSocialCommon card={card} />
    </FeedCardWrapper>
  );
});

import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IS_PRODUCTION } from '../../../env';
import { storyApi } from '../../../services';
import { ConfirmationModal, getChannelDomain, getWebAppUrl, useAppDispatch } from '../../../shared';
import { deleteStory } from '../../../slices';

interface DeleteStoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  storyId: number;
  navigateUrl?: string;
}

export const DeleteStoryModal: FunctionComponent<DeleteStoryModalProps> = memo(
  ({ isOpen, onClose, storyId, navigateUrl }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [storyDeleteTrigger] = storyApi.endpoints.storyDelete.useLazyQuery();

    const [loading, setLoading] = useState(false);

    const [showDeleteError, setShowDeleteError] = useState(false);

    const channelDomain = useMemo(() => getChannelDomain(), []);

    const storyDeleteHandler = useCallback(async () => {
      setLoading(true);
      try {
        const deleted = await storyDeleteTrigger({ storyId }).unwrap();

        if (!deleted) {
          setShowDeleteError(true);
          return;
        }

        if (navigateUrl) {
          window.location.replace(
            IS_PRODUCTION ? `${getWebAppUrl(channelDomain)}/${navigateUrl}` : navigateUrl
          );
          return;
        }

        dispatch(deleteStory({ storyId }));
        toast(t('storyActions.story-deleted'));
      } catch (_) {
        toast(t('deleteConfirmation.error-message'));
      } finally {
        onClose();
        setLoading(false);
      }
    }, [channelDomain, dispatch, navigateUrl, onClose, storyDeleteTrigger, storyId, t]);

    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        acceptLabel={t('common.delete')}
        onAccept={storyDeleteHandler}
        title={t('deleteConfirmationStory.title')}
        subTitle={t('deleteConfirmationStory.sub-title')}
        keepOpened={loading}
        loading={loading}
        danger
        {...(showDeleteError && { error: t('deleteConfirmation.error-message') })}
      />
    );
  }
);

import { FunctionComponent, memo, useMemo, useState } from 'react';
import { DetailsModal } from '../../../../components';
import { FeedCardContentCommon, FeedCardContentProps } from '../FeedCardContentCommon';

export const FeedCardContent: FunctionComponent<FeedCardContentProps> = memo(
  ({ storyId, cardId, type, pinned, source, title, media, subTitle, text, htmlText, teaser }) => {
    const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

    const renderCardDetails = useMemo(
      () => Boolean(openCardDetailsModal && storyId && cardId),
      [cardId, openCardDetailsModal, storyId]
    );

    return (
      <>
        <FeedCardContentCommon
          storyId={storyId}
          cardId={cardId}
          type={type}
          pinned={pinned}
          source={source}
          title={title}
          media={media}
          subTitle={subTitle}
          text={text}
          htmlText={htmlText}
          teaser={teaser}
          setOpenCardDetailsModal={setOpenCardDetailsModal}
        />
        {renderCardDetails && (
          <DetailsModal
            isOpen={openCardDetailsModal}
            storyId={storyId as number}
            cardId={cardId as number}
            onClose={() => setOpenCardDetailsModal(false)}
          />
        )}
      </>
    );
  }
);

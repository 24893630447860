import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const Like = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={15}
      cy={15}
      r={14.5}
      fill="#5890FF"
      stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
    />
    <path
      d="M9.4 20.8333H10.1V11.6535H9.4C8.6272 11.6535 8 12.2862 8 13.0658V19.4211C8 20.2006 8.6272 20.8333 9.4 20.8333ZM20.6 11.6535H15.7L16.4854 9.27523C16.7878 8.36077 16.113 7.41666 15.1575 7.41666H15L11.5 11.2567V20.8333H19.2L21.9384 14.7633C21.9559 14.6836 21.9832 14.5586 22 14.4781V13.0658C22 12.2862 21.3728 11.6535 20.6 11.6535Z"
      fill="white"
    />
  </svg>
);

import { FunctionComponent, memo } from 'react';
import { StoryCardArticleContent } from '../../../../../services';
import { CardContainer } from '../CardContainer';
import { Content } from '../Content';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

export const Article: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { id, storyId, type, content, dateToDisplay } = card;
  const { gallery, title, sourceName, abstract, url } = content as StoryCardArticleContent;

  return (
    <CardContainer
      storyId={storyId}
      cardId={id}
      type={type}
      url={url}
      gallery={gallery}
      postedTime={dateToDisplay}
    >
      <Content cardType={type} source={sourceName} title={title} text={abstract} />
    </CardContainer>
  );
});

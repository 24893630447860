import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IResolveParams, LoginSocialApple, LoginSocialFacebook } from 'reactjs-social-login';
import { ConfigContext } from '../../../../context';
import { userApi, useSocialLoginConnectionsQuery } from '../../../../services';
import { getWebAppUrl, IconLabel } from '../../../../shared';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './SocialLogin.module.scss';

enum SocialLoginType {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

interface SocialLoginProps {
  onSuccessLogin: ({ accessToken, webappUrl }: { accessToken: string; webappUrl: string }) => void;
}

export const SocialLogin: FunctionComponent<SocialLoginProps> = memo(({ onSuccessLogin }) => {
  const { t } = useTranslation();

  const { data: socialConnections, isFetching: isFetchingSocialConnections } =
    useSocialLoginConnectionsQuery();

  const [triggerLoginBySocial] = userApi.endpoints.userLoginBySocial.useLazyQuery();

  const { google, facebook, apple } = useContext(ConfigContext).config.elements.login.social;

  const [socialLoginLoading, setSocialLoginLoading] = useState(false);

  const socialLoader = useMemo(() => {
    return (
      <div className={classes['social-login__loader']}>
        <Skeleton height={'2.5rem'} />
      </div>
    );
  }, []);

  const onSocialLogin = useCallback(
    async ({ type, accessToken }: { type: SocialLoginType; accessToken?: string }) => {
      if (!accessToken) {
        return;
      }

      setSocialLoginLoading(true);

      try {
        const { accessToken: loginAccessToken, channel } = await triggerLoginBySocial({
          type,
          accessToken,
        }).unwrap();

        await onSuccessLogin({
          accessToken: loginAccessToken,
          webappUrl: getWebAppUrl(channel.subdomain),
        });
      } catch (_) {
        toast.error(t('common.error-message'));
      } finally {
        setSocialLoginLoading(false);
      }
    },
    [onSuccessLogin, t, triggerLoginBySocial]
  );

  const googleClientId = useMemo(
    () => socialConnections?.find(({ type }) => type === SocialLoginType.GOOGLE)?.clientId ?? '',
    [socialConnections]
  );

  const facebookClientId = useMemo(
    () => socialConnections?.find(({ type }) => type === SocialLoginType.FACEBOOK)?.clientId ?? '',
    [socialConnections]
  );

  const appleClientId = useMemo(
    () => socialConnections?.find(({ type }) => type === SocialLoginType.APPLE)?.clientId ?? '',
    [socialConnections]
  );

  const googleContent = useMemo(() => {
    if (isFetchingSocialConnections || socialLoginLoading) {
      return socialLoader;
    }

    if (googleClientId) {
      return (
        <GoogleOAuthProvider clientId={googleClientId}>
          <GoogleLogin
            type={'icon'}
            onSuccess={(credentialResponse) =>
              onSocialLogin({
                type: SocialLoginType.GOOGLE,
                accessToken: credentialResponse?.credential,
              })
            }
          ></GoogleLogin>
        </GoogleOAuthProvider>
      );
    }

    return (
      <div className={classes['social-login__item']}>
        <IconLabel iconId={'google'} />
      </div>
    );
  }, [
    googleClientId,
    isFetchingSocialConnections,
    onSocialLogin,
    socialLoader,
    socialLoginLoading,
  ]);

  const facebookContent = useMemo(() => {
    if (isFetchingSocialConnections || socialLoginLoading) {
      return socialLoader;
    }

    if (facebookClientId) {
      return (
        <LoginSocialFacebook
          isOnlyGetToken
          appId={facebookClientId}
          className={classes['social-login__item']}
          redirect_uri={window.location.href.split('?')[0]}
          onResolve={({ data }: IResolveParams) =>
            onSocialLogin({ type: SocialLoginType.FACEBOOK, accessToken: data?.accessToken })
          }
          onReject={(err) => console.log(err)}
        >
          <IconLabel iconId={'facebook-colored'} />
        </LoginSocialFacebook>
      );
    }

    return (
      <div className={classes['social-login__item']}>
        <IconLabel iconId={'facebook-colored'} />
      </div>
    );
  }, [
    facebookClientId,
    isFetchingSocialConnections,
    onSocialLogin,
    socialLoader,
    socialLoginLoading,
  ]);

  const appleContent = useMemo(() => {
    if (isFetchingSocialConnections || socialLoginLoading) {
      return socialLoader;
    }

    if (appleClientId) {
      return (
        <LoginSocialApple
          client_id={appleClientId}
          className={classes['social-login__item']}
          redirect_uri={window.location.href.split('?')[0]}
          onResolve={({ data }: IResolveParams) =>
            onSocialLogin({
              type: SocialLoginType.APPLE,
              accessToken: data?.authorization.id_token,
            })
          }
          onReject={(err) => console.log(err)}
        >
          <IconLabel iconId={'apple'} />
        </LoginSocialApple>
      );
    }

    return (
      <div className={classes['social-login__item']}>
        <IconLabel iconId={'apple'} />
      </div>
    );
  }, [appleClientId, isFetchingSocialConnections, onSocialLogin, socialLoader, socialLoginLoading]);

  return (
    <div className={classes['social-login']}>
      {google && googleContent}
      {facebook && facebookContent}
      {apple && appleContent}
    </div>
  );
});

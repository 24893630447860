export const emojiTypes = ['like', 'love', 'haha', 'wow', 'sad', 'angry'] as const;

export type Emoji = typeof emojiTypes[number];

export enum Emojis {
  like,
  love,
  haha,
  wow,
  sad,
  angry,
}

export type Reaction = {
  [key in Emoji]: number;
};

export enum EmojiModes {
  light = 'light',
  dark = 'dark',
}

import { FunctionComponent, memo } from 'react';
import { CalloutBlockData } from '../../../../shared';

import classes from './Callout.module.scss';

export const Callout: FunctionComponent<CalloutBlockData> = memo(({ text }) => {
  return (
    <div className={classes['callout']}>
      {text && (
        <div
          dangerouslySetInnerHTML={{ __html: text.replace(/href/g, "target='_blank' href") }}
        ></div>
      )}
    </div>
  );
});

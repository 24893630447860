import { ElementType, FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { UserContext } from '../../../../context';
import { clearCardDetails, getCard } from '../../../../slices';
import { useAnalytics, useAppDispatch, useAppSelector } from '../../../hooks';
import { Modal } from '../../Modal';
import { DetailsContentProps } from '../DetailsContentCommon';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export interface DetailsModalProps {
  isOpen: boolean;
  storyId: number;
  cardId: number;
  onClose: () => void;
  scrollToTags?: boolean;
  scrollToComments?: boolean;
  activeCommentsInput?: boolean;
}

interface DetailsModalCommonProps extends DetailsModalProps {
  ContentComponent: ElementType<DetailsContentProps>;
}

export const DetailsModalCommon: FunctionComponent<DetailsModalCommonProps> = ({
  isOpen,
  storyId,
  cardId,
  onClose,
  ContentComponent,
  scrollToTags,
  scrollToComments,
  activeCommentsInput,
}) => {
  const dispatch = useAppDispatch();

  const { logClickOpenArticle } = useAnalytics();

  const { channelId } = useContext(UserContext).userInfo.userData;

  useEffect(() => {
    logClickOpenArticle({
      channel_id: channelId,
      story_id: storyId,
      item_id: cardId,
      time_stamp: new Date().toISOString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpenArticle]);

  useEffect(() => {
    dispatch(getCard({ storyId, cardId }));
  }, [cardId, dispatch, storyId]);

  const { card, isFetching } = useAppSelector(({ cardDetails }) => cardDetails);

  const onCloseHandler = useCallback(() => {
    dispatch(clearCardDetails());
    onClose();
  }, [dispatch, onClose]);

  const body = useMemo(() => {
    if (!card) {
      return <Skeleton height={200} width={'100%'} />;
    }

    return (
      <ContentComponent
        card={card}
        scrollToTags={scrollToTags}
        scrollToComments={scrollToComments}
        activeCommentsInput={activeCommentsInput}
        commentsWrapper={document.querySelector('.modal-content') as HTMLElement}
      />
    );
  }, [ContentComponent, activeCommentsInput, card, scrollToComments, scrollToTags]);

  if (!isFetching && !card) {
    return null;
  }

  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <Modal isOpen={isOpen} body={body} onClose={onCloseHandler} />,
          document.getElementById('modal-root') as HTMLElement
        )}
    </>
  );
};

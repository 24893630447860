import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Story } from '../../../services';
import { Button, ButtonType } from '../Button';
import { CreateCardModal } from './CreateCardModal';

interface CreateCardProps {
  postInStories: Story[];
  onMobilePanelClose?: () => void;
}

export const CreateCard: FunctionComponent<CreateCardProps> = memo(
  ({ postInStories, onMobilePanelClose }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      if (isOpen) {
        onMobilePanelClose?.();
      }
    }, [isOpen, onMobilePanelClose]);

    return (
      <>
        <Button
          type={ButtonType.primary}
          label={t('addContent.label')}
          onClick={() => setIsOpen(true)}
          fullWidth
        />

        {isOpen && (
          <CreateCardModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            postInStories={postInStories}
          />
        )}
      </>
    );
  }
);

import { graphqlApi } from './graphqlApi';

enum ChannelAppTabAction {
  CHATS = 'CHATS',
  NEWS_FEED = 'NEWS_FEED',
  PINNED_STORY = 'PINNED_STORY',
  PROFILE = 'PROFILE',
  STORIES = 'STORIES',
  STORIES2 = 'STORIES2',
}

export enum ChannelRole {
  ADMIN = 1,
  EDITOR = 2,
  EDITOR_LIMITED = 3,
  READER = 5,
}

interface ChannelAppTabTitle {
  language: string;
  localization: string;
}

interface ChannelAppTab {
  action: ChannelAppTabAction;
  iconUrl: string;
  id: number;
  title: ChannelAppTabTitle[];
}

export interface Channel {
  appTabs: ChannelAppTab[];
  dashboardUrl: string;
  id: number;
  locale: string;
  name: string;
  role: { id: number };
  subdomain: string;
  pinnedStoryId?: number;
  userNotificationPreference: {
    allowToReceiveChannelNotification: boolean;
  };
  permissions: {
    channelPushNotificationAllowToCreate: boolean;
  };
}

export interface ChannelsArgs {
  excludeArchived: boolean;
}

interface ChannelsResponse {
  data: {
    channels: Channel[];
  };
}

export interface ChannelNotificationPreferenceUpdateArgs {
  channelId: number;
  allowToReceiveChannelNotification: boolean;
}

interface ChannelNotificationPreferenceUpdateResponse {
  data: {
    channelUserNotificationPreferenceUpdate: {
      payload: boolean;
    };
  };
}

export const channelsNewApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    channels: builder.query<Channel[], ChannelsArgs>({
      query: ({ excludeArchived = false }) => ({
        url: '/graphql/webapp?channels',
        method: 'POST',
        body: {
          query: `
          {
            channels(filters: { excludeArchived: ${excludeArchived}}) {
              id
              name
              subdomain
              pinnedStoryId
              userNotificationPreference {
                allowToReceiveChannelNotification
              }
              permissions {
                channelPushNotificationAllowToCreate
              }
            }
          }`,
        },
      }),
      transformResponse: (response: ChannelsResponse) => response.data.channels,
    }),
    channelNotificationPreferenceUpdate: builder.query<
      boolean,
      ChannelNotificationPreferenceUpdateArgs
    >({
      query: ({ channelId, allowToReceiveChannelNotification }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation {
            channelUserNotificationPreferenceUpdate(input: { 
              channelId: ${channelId},
              fields: {
                allowToReceiveChannelNotification: ${allowToReceiveChannelNotification}
              }
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChannelNotificationPreferenceUpdateResponse) =>
        response.data.channelUserNotificationPreferenceUpdate.payload,
    }),
  }),
});

export const { useChannelsQuery, useChannelNotificationPreferenceUpdateQuery } = channelsNewApi;

import { API } from '@editorjs/editorjs';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';

export class InlineCode implements InlineTool {
  private readonly api: API;
  private readonly tag = 'CODE';
  private readonly button: HTMLButtonElement;

  constructor(config: { api: API }) {
    this.api = config.api;
    this.button = this.createToolButton();
  }

  static get isInline() {
    return true;
  }

  static get sanitize() {
    return { code: {} };
  }

  private createToolButton() {
    const button = document.createElement('button');

    button.type = 'button';

    button.classList.add(this.api.styles.inlineToolButton);

    button.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M9.5 8L6.11524 11.8683C6.04926 11.9437 6.04926 12.0563 6.11524 12.1317L9.5 16"></path>
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M15 8L18.3848 11.8683C18.4507 11.9437 18.4507 12.0563 18.3848 12.1317L15 16"></path>
    </svg>`;

    return button;
  }

  render() {
    return this.button;
  }

  surround(range: Range) {
    if (!range) {
      return;
    }

    const termWrapper = this.api.selection.findParentTag(this.tag);

    termWrapper ? this.unwrap(termWrapper) : this.wrap(range);
  }

  private wrap(range: Range) {
    const marker = document.createElement(this.tag);

    marker.appendChild(range.extractContents());

    range.insertNode(marker);

    this.api.selection.expandToTag(marker);
  }

  private unwrap(termWrapper: HTMLElement) {
    this.api.selection.expandToTag(termWrapper);

    const selection = window.getSelection();

    if (!selection) {
      return;
    }

    const range = selection.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    termWrapper.parentNode?.removeChild(termWrapper);

    range.insertNode(unwrappedContent);

    selection.removeAllRanges();

    selection.addRange(range);
  }

  checkState() {
    const tag = this.api.selection.findParentTag(this.tag);

    this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, Boolean(tag));

    return Boolean(tag);
  }
}

import { format, parseISO } from 'date-fns';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context';
import {
  AnalyticContentListOrderBy,
  StoryCardType,
  useContentListQuery,
} from '../../../../services';
import {
  ANALYTICS_ADMIN_COOKIE_KEY,
  CookieActionType,
  OrderDirection,
  Select,
  cookieOptions,
  durationFormatter,
  getWebAppUrl,
} from '../../../../shared';
import {
  ChartWrapper,
  DataRowItem,
  Loader,
  TableChart,
  TableChartHeader,
  TableChartSubHeader,
} from '../../helpers';
import { ContentProps } from '../Content';

import classes from '../../Analytics.module.scss';

export const ContentList: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform, organisationTags }) => {
    const { t } = useTranslation();

    const { channelId: selectedChannelId } = useContext(UserContext).userInfo.userData;

    const COOKIE_KEY = useMemo(
      () => `${ANALYTICS_ADMIN_COOKIE_KEY}_${selectedChannelId}_contentList`,
      [selectedChannelId]
    );

    const [cookies, setCookie] = useCookies([COOKIE_KEY]);

    const { storyCardType = Object.values(StoryCardType) } = { ...cookies[COOKIE_KEY] };

    const [orderBy, setOrderBy] = useState<AnalyticContentListOrderBy>(
      AnalyticContentListOrderBy.POSTED_TIME
    );

    const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC);

    const [page, setPage] = useState<number>(1);

    const { data: contentList, isFetching: isFetchingContentList } = useContentListQuery({
      from,
      to,
      channelId,
      platform,
      organisationTags,
      orderBy,
      orderDirection,
      storyCardType,
      page,
    });

    const storyCardTypes = useMemo(
      () =>
        Object.values(StoryCardType).map((cardType) => {
          return { id: cardType, title: t(`cardTypes.${cardType.toLowerCase()}`) };
        }),
      [t]
    );

    const storyCardTypeChange = useCallback(
      (storyCardType: StoryCardType[]) => {
        setPage(1);

        setCookie(
          COOKIE_KEY,
          { ...cookies[COOKIE_KEY], storyCardType },
          cookieOptions({ action: CookieActionType.SET })
        );
      },
      [COOKIE_KEY, cookies, setCookie]
    );

    const renderChart = useMemo(() => {
      if (!contentList) {
        return <Loader />;
      }

      const { items, pageInfo } = contentList;

      const data: DataRowItem[][] = items.map(({ label, payload }) => {
        const {
          channel,
          commentReactionsTotal,
          comments,
          notifications,
          reactions,
          reactionsTotal,
          shares,
          storyCard,
          views,
          plays,
          playDuration,
          playDurationAvg,
        } = payload;

        const { name: channelName, subdomain } = channel;
        const { id: cardId, type: cardType, postedAt: cardPostedAt, storyId } = storyCard;

        const { count: cardLikeCount = 0 } = { ...reactions.find(({ name }) => name === 'like') };
        const { count: cardLoveCount = 0 } = { ...reactions.find(({ name }) => name === 'love') };
        const { count: cardHahaCount = 0 } = { ...reactions.find(({ name }) => name === 'haha') };
        const { count: cardWowCount = 0 } = { ...reactions.find(({ name }) => name === 'wow') };
        const { count: cardSadCount = 0 } = { ...reactions.find(({ name }) => name === 'sad') };
        const { count: cardAngryCount = 0 } = { ...reactions.find(({ name }) => name === 'angry') };

        return [
          {
            value: label.replace(`(${cardId}) `, ''),
            url: `${getWebAppUrl(subdomain)}/admin/details/${storyId}/${cardId}`,
          },
          { value: t(`cardTypes.${cardType.toLowerCase()}`) },
          { value: format(parseISO(cardPostedAt), 'dd.MM.yy, HH:mm') },
          { value: views },
          { value: reactionsTotal },
          { value: cardLikeCount },
          { value: cardLoveCount },
          { value: cardHahaCount },
          { value: cardWowCount },
          { value: cardSadCount },
          { value: cardAngryCount },
          { value: comments },
          { value: commentReactionsTotal },
          { value: shares },
          { value: plays },
          { value: durationFormatter(playDuration) },
          { value: durationFormatter(playDurationAvg) },
          { value: notifications },
          { value: channelName },
        ];
      });

      const headers: TableChartHeader[] = [
        { label: t('contentListLabels.title'), rowSpan: 6 },
        { label: t('contentListLabels.type'), rowSpan: 6 },
        {
          label: t('contentListLabels.posted-time'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.POSTED_TIME,
        },
        {
          label: t('contentListLabels.views'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.VIEWS,
        },
        { label: t('contentListLabels.reactions'), colSpan: 7 },
        {
          label: t('contentListLabels.comments'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.COMMENTS,
        },
        {
          label: t('contentListLabels.likes-on-comments'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.COMMENT_REACTIONS,
        },
        {
          label: t('contentListLabels.shares'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.SHARES,
        },
        {
          label: t('contentListLabels.play-count'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.PLAYS,
        },
        {
          label: t('contentListLabels.play-total-duration'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.PLAY_DURATION,
        },
        {
          label: t('contentListLabels.play-average-duration'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.PLAY_DURATION_AVG,
        },
        {
          label: t('contentListLabels.push-sent'),
          rowSpan: 6,
          orderBy: AnalyticContentListOrderBy.NOTIFICATIONS,
        },
        { label: t('contentListLabels.channel'), rowSpan: 6 },
      ];

      const subHeaders: TableChartSubHeader[] = [
        { label: t('common.total'), orderBy: AnalyticContentListOrderBy.REACTIONS },
        { label: t('reactions.like'), orderBy: AnalyticContentListOrderBy.REACTION_LIKE },
        { label: t('reactions.love'), orderBy: AnalyticContentListOrderBy.REACTION_LOVE },
        { label: t('reactions.haha'), orderBy: AnalyticContentListOrderBy.REACTION_HAHA },
        { label: t('reactions.wow'), orderBy: AnalyticContentListOrderBy.REACTION_WOW },
        { label: t('reactions.sad'), orderBy: AnalyticContentListOrderBy.REACTION_SAD },
        { label: t('reactions.angry'), orderBy: AnalyticContentListOrderBy.REACTION_ANGRY },
      ];

      return (
        <TableChart
          sticky
          headers={headers}
          subHeaders={subHeaders}
          data={data}
          pageInfo={pageInfo}
          setPage={setPage}
          orderBy={orderBy}
          setOrderBy={setOrderBy as Dispatch<SetStateAction<string>>}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          loading={isFetchingContentList}
        />
      );
    }, [contentList, isFetchingContentList, orderBy, orderDirection, t]);

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.content-list'}
        infoLocaliseKey={'contentList.info'}
        component={
          <Select
            className={classes['analytics__chart-header-select']}
            selectedItemIds={storyCardType}
            items={storyCardTypes}
            onMultiCheckChange={storyCardTypeChange}
          />
        }
      >
        {renderChart}
      </ChartWrapper>
    );
  }
);

import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabItem, Tabs } from '../../shared';
import { General } from './General';

import classes from './OrgSettings.module.scss';

enum OrgSettingsTabType {
  GENERAL,
}

export const OrgSettings: FunctionComponent = () => {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(OrgSettingsTabType.GENERAL);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        index: OrgSettingsTabType.GENERAL,
        name: t('orgSettingsTabs.general'),
        content: <General />,
      },
    ],
    [t]
  );

  return (
    <div className={classes['settings']}>
      <div className={classes['settings__title']}>{t('orgSettings.title')}</div>
      <div className={classes['settings__subTitle']}>{t('orgSettings.sub-title')}</div>
      <Tabs
        items={tabItems}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        noBackground
      />
    </div>
  );
};

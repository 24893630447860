import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedCardWrapper } from '../HorizontalStoryFeedCardWrapper';
import { StoryCardEditorialContent } from '../../../../services';

export const HorizontalStoryFeedEditorial: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { content, isPinnedToTopOfNewsFeed } = card;
    const { sourceName, headline, subHeadline, text, textHTML } =
      content as StoryCardEditorialContent;

    return (
      <HorizontalStoryFeedCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          source={sourceName}
          title={headline}
          subTitle={subHeadline}
          text={text}
          htmlText={textHTML}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedCardWrapper>
    );
  }
);

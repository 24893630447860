import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedEditorial } from './HorizontalStoryFeedEditorial';
import { HorizontalStoryFeedMedia } from './HorizontalStoryFeedMedia';
import { HorizontalStoryFeedThread } from './HorizontalStoryFeedThread';
import { HorizontalStoryFeedArticle } from './HorizontalStoryFeedArticle';
import { HorizontalStoryFeedPost } from './HorizontalStoryFeedPost';
import { HorizontalStoryFeedSocial } from './HorizontalStoryFeedSocial';
import { StoryCardType } from '../../../services';
import { HorizontalStoryFeedCardProps } from '../../../shared';

export const HorizontalStoryFeedCard: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    switch (card.type) {
      case StoryCardType.IMAGE:
      case StoryCardType.AUDIO:
      case StoryCardType.VIDEO:
      case StoryCardType.PDF:
        return <HorizontalStoryFeedMedia card={card} />;
      case StoryCardType.THREAD:
        return <HorizontalStoryFeedThread card={card} />;
      case StoryCardType.EDITORIAL:
        return <HorizontalStoryFeedEditorial card={card} />;
      case StoryCardType.QUOTE:
        return <HorizontalStoryFeedSocial card={card} />;
      case StoryCardType.ARTICLE:
        return <HorizontalStoryFeedArticle card={card} />;
      case StoryCardType.POST:
        return <HorizontalStoryFeedPost card={card} />;
      default:
        return null;
    }
  }
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComputedBarDatum } from '@nivo/bar';

export const getBarsLayer = ({
  bars,
  transform,
}: {
  bars: readonly ComputedBarDatum<any>[];
  transform?: string;
}) => {
  return (
    <g>
      {bars.map(({ x, y, width, data }, index) => {
        return (
          <text
            key={`bar-${index}`}
            fontSize={14}
            transform={`translate(${x + width / 2}, ${y - 5}) ${transform ?? ''}`}
            textAnchor={'middle'}
          >
            {data.value}
          </text>
        );
      })}
    </g>
  );
};

import { useContext, useMemo } from 'react';
import { OrganisationContext } from '../../context';
import { OrganisationTagType } from '../../services';
import { isAdminLayout } from '../utils';

export const useTags = () => {
  const { tags: organisationTags } = useContext(OrganisationContext).organisation;

  const hasInternalTags = useMemo(
    () =>
      Boolean(
        isAdminLayout() &&
          organisationTags.filter(({ type }) => type === OrganisationTagType.INTERNAL).length
      ),
    [organisationTags]
  );

  const hasExternalTags = useMemo(
    () =>
      Boolean(organisationTags.filter(({ type }) => type === OrganisationTagType.EXTERNAL).length),
    [organisationTags]
  );

  const hasTags = useMemo(
    () => Boolean(hasInternalTags || hasExternalTags),
    [hasExternalTags, hasInternalTags]
  );

  return { hasInternalTags, hasExternalTags, hasTags };
};

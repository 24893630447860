import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { getTagsFeed, getTagsFeedLoadMore } from '../../slices';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { TAGS_FEED_ADMIN_COOKIE_KEY } from '../constants';
import { useCookies } from 'react-cookie';
import { useLastDate } from './useLastDate';
import { StoryCardStatus } from '../../services';
import { isAdminLayout } from '../utils';

export const useTagsFeed = () => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const [cookies] = useCookies([TAGS_FEED_ADMIN_COOKIE_KEY]);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { page } = useAppSelector(({ tagsFeed }) => tagsFeed);

  const organisationTagId = Number(useParams().organisationTagId);

  const {
    requestFilter = {
      organisationTags: {
        externalTagsId: [],
        internalTagsId: [],
      },
      authorId: [],
      storyCardType: [],
      status: [],
      dateRange: null,
    },
  } = {
    ...cookies[TAGS_FEED_ADMIN_COOKIE_KEY],
  };

  const { dateRange, ...filter } = requestFilter; //TODO

  const filters = useMemo(() => {
    if (!isAdminLayout()) {
      return { status: [StoryCardStatus.PUBLISHED] };
    }

    const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
    const from = date?.from ?? null;
    const to = date?.to ?? null;

    return {
      ...filter,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [dateRange, filter, getLastDate, isLastDate]);

  const getTagsFeedItems = useCallback(() => {
    dispatch(getTagsFeed({ channelId, organisationTagId, filters }));
  }, [channelId, dispatch, filters, organisationTagId]);

  const loadMoreTagsFeedItems = useCallback(() => {
    dispatch(
      getTagsFeedLoadMore({
        channelId,
        organisationTagId,
        page: page + 1,
        filters,
      })
    );
  }, [channelId, dispatch, filters, organisationTagId, page]);

  return {
    getTagsFeedItems,
    loadMoreTagsFeedItems,
  };
};

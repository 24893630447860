import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  NotificationComment,
  NotificationDefault,
  NotificationType,
  ChannelNotificationsArgs,
  notificationsApi,
} from '../services';
import { deleteCard } from './common';

interface ChannelNotificationsState {
  channelNotifications: (NotificationDefault | NotificationComment)[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: ChannelNotificationsState = {
  channelNotifications: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
};

const getChannelNotificationsHandler = async (
  args: ChannelNotificationsArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { dispatch }: any
) => {
  return dispatch(notificationsApi.endpoints.channelNotifications.initiate({ ...args })).unwrap();
};

export const getChannelNotifications = createAsyncThunk(
  'getChannelNotifications',
  getChannelNotificationsHandler
);
export const loadMoreChannelNotifications = createAsyncThunk(
  'getChannelNotifications/loadMore',
  getChannelNotificationsHandler
);

const ChannelNotificationsSlice = createSlice({
  name: 'channelNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChannelNotifications.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getChannelNotifications.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.channelNotifications = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMoreChannelNotifications.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.channelNotifications.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        const notificationsFiltered = state.channelNotifications.filter(
          (notification) =>
            (notification.type === NotificationType.DEFAULT &&
              (notification as NotificationDefault).storyCard?.id !== storyCardId) ||
            (notification.type === NotificationType.COMMENT &&
              (notification as NotificationComment).comment.storyCard.id !== storyCardId)
        );

        state.channelNotifications = notificationsFiltered;
      });
  },
});

export default ChannelNotificationsSlice.reducer;

import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { ConfigContext } from '../../context';
import { EmojiModes } from '../../shared';
import { LikeAOK, LoveAOK } from './AOK';
import { Angry } from './Angry';
import { Haha } from './Haha';
import { Like } from './Like';
import { Love } from './Love';
import { Sad } from './Sad';
import { Wow } from './Wow';

interface EmojiProps {
  reaction: string;
  size?: number;
  mode?: EmojiModes;
}

export const Emoticon: FunctionComponent<EmojiProps> = memo(
  ({ reaction, size = 20, mode = EmojiModes.light }) => {
    const { standardEmojiSet } = useContext(ConfigContext).config.elements;

    const props = useMemo(() => {
      return { size, mode };
    }, [mode, size]);

    const content = useMemo(() => {
      switch (reaction) {
        case 'like':
          return standardEmojiSet ? <Like {...props} /> : <LikeAOK {...props} />;
        case 'love':
          return standardEmojiSet ? <Love {...props} /> : <LoveAOK {...props} />;
        case 'haha':
          return <Haha {...props} />;
        case 'wow':
          return <Wow {...props} />;
        case 'sad':
          return <Sad {...props} />;
        case 'angry':
          return <Angry {...props} />;
        default:
          return null;
      }
    }, [props, reaction, standardEmojiSet]);

    return content;
  }
);

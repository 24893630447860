import { ReactionBarSelector } from '@charkour/react-reactions';
import { FunctionComponent, memo, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Emoticon } from '../../../../../assets/emojis';
import { EmojiModes, emojiTypes } from '../../../../models';

import classes from './Reactions.module.scss';

interface ReactionsProps {
  onSelect: (reaction: string) => void;
  mode?: EmojiModes;
}

export const Reactions: FunctionComponent<ReactionsProps> = memo(
  ({ onSelect, mode = EmojiModes.light }) => {
    const { t } = useTranslation();

    const iconSize = useMemo(() => (isMobileOnly ? 24 : 28), []);

    const reactions = emojiTypes.map((key) => {
      return {
        key,
        label: t(`reactions.${key}`),
        node: (
          <div className={classes['reaction']}>
            <Emoticon reaction={key} size={iconSize} mode={mode} />
          </div>
        ),
      };
    });

    return (
      <ReactionBarSelector
        iconSize={iconSize}
        onSelect={onSelect}
        reactions={reactions}
        style={{
          padding: 0,
          whiteSpace: 'nowrap',
          boxShadow: '0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2)',
          background: 'white',
        }}
      />
    );
  }
);

import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context';
import { IconLabel, getCssVar } from '../../shared';
import { NoContent } from '../Error';

import classes from './FrontDoorLinks.module.scss';

export const FrontDoorLinks: FunctionComponent = () => {
  const { t } = useTranslation();

  const { config } = useContext(ConfigContext);

  const { frontDoorLinks } = config.elements;

  const { visible, titleLocaliseKey, items } = frontDoorLinks;

  if (!visible || !items.length) {
    return <NoContent subtitle={''} />;
  }

  return (
    <div className={classes['front-door-links']}>
      {titleLocaliseKey && (
        <div className={classes['front-door-links__title']}>{t(titleLocaliseKey)}</div>
      )}
      <div className={classes['front-door-links__list']}>
        {items.map(({ titleLocaliseKey, subTitleLocaliseKey, imageSrc, linkUrl }, index) => (
          <div
            key={`front-door-links-item-${index + 1}`}
            className={classes['front-door-links__list-item']}
            onClick={() => linkUrl && window.open(linkUrl, '_blank', 'noopener, noreferrer')}
          >
            {imageSrc && (
              <div className={classes['front-door-links__list-item-image-wrapper']}>
                <img
                  className={classes['front-door-links__list-item-image']}
                  src={imageSrc}
                  alt={'preview'}
                />
              </div>
            )}

            <div className={classes['front-door-links__list-item-description']}>
              <div className={classes['front-door-links__list-item-title-wrapper']}>
                {titleLocaliseKey && (
                  <div className={classes['front-door-links__list-item-title']}>
                    {t(titleLocaliseKey)}
                  </div>
                )}
                <IconLabel
                  iconId={'arrow-rounded-right'}
                  iconSize={20}
                  color={getCssVar('--front-door-links-item-icon-color')}
                  className={classes['front-door-links__list-item-icon']}
                  singleColor
                />
              </div>
              {subTitleLocaliseKey && (
                <div className={classes['front-door-links__list-item-subTitle']}>
                  {t(subTitleLocaliseKey)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { useAnalytics, useBeforeUnload, useDuration } from '../../shared';
import { StoryFeed } from '../StoryFeedItems';

export const PinnedStoryFeedItems: FunctionComponent = () => {
  const { storyId } = useParams();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { logClickOpenStory } = useAnalytics();

  const { startTrackTime, getDuration } = useDuration();

  const logClickOpen = useCallback(
    () =>
      logClickOpenStory({
        channel_id: channelId,
        story_id: Number(storyId),
        mix_id: Number(storyId),
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, logClickOpenStory, storyId]
  );

  useEffect(() => {
    startTrackTime();
    return () => logClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpen]);

  useBeforeUnload(logClickOpen);

  return <StoryFeed storyId={Number(storyId)} />;
};

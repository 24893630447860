import { CommentsReactions, StoryCard } from '../../services';

export const reactOnCommentReducer = ({
  card,
  commentId,
  reactions,
}: {
  card: StoryCard;
  commentId: number;
  reactions: CommentsReactions[];
}) => {
  const { comments } = card;

  const commentsAndReplies = comments.flatMap((comment) => {
    return [comment, ...comment.replies];
  });

  const comment = commentsAndReplies.find(({ id }) => id === commentId);

  if (!comment) {
    return;
  }

  comment.myReaction = comment.myReaction ? '' : 'like';
  comment.reactions = reactions;
};

import { createContext } from 'react';
import { User, UserInfo } from '../services';

export interface UserContextProps {
  userProfile: User;
  setUserProfile: (user: User) => void;
  userInfo: UserInfo;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

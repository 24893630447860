import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../../../context';
import { StoryCardThreadContent } from '../../../../../services';
import { CardContainer } from '../CardContainer';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

import classes from './Thread.module.scss';

export const Thread: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { t } = useTranslation();

  const { type, id, storyId, dateToDisplay, content } = card;

  const { title } = content as StoryCardThreadContent;

  const { config } = useContext(ConfigContext);

  const { card: cardConfig } = config.elements;

  const { thread } = cardConfig;

  const threadTitle = useMemo(
    () => t(`${thread.localiseKey ?? 'common.discussion'}`),
    [t, thread.localiseKey]
  );

  return (
    <CardContainer type={type} storyId={storyId} cardId={id} postedTime={dateToDisplay}>
      <div className={classes['thread']}>
        <div className={classes['thread__discussion']}>{threadTitle}</div>
        {title && <div className={classes['thread__title']}>{title}</div>}
      </div>
    </CardContainer>
  );
});

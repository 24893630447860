/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Checklist from '@editorjs/checklist';
import Code from '@editorjs/code';
import NestedList from '@editorjs/nested-list';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';

//Custom tools
import {
  AudioTool,
  Callout,
  DelimiterTool,
  EmbedLinkTool,
  FileTool,
  Gallery,
  ParagraphTool,
  PdfTool,
  StoryCardTool,
  StoryTool,
  Table,
  UserTool,
  VideoTool,
} from './BlockTools';
import { Header, InlineCode, Marker, Subscript, Superscript, Underline } from './InlineTools';

interface BlockToolsConfigProps {
  t: TFunction<'translation', undefined, 'translation'>;
  dispatch: any;
  uploadHandler: (props: UploadHandlerProps) => Promise<{ id: number; assembly_id: string }>;
  channelId: number;
}

export const INLINE_TOOLS = {
  underline: {
    class: Underline,
    inlineToolbar: true,
  },
  marker: {
    class: Marker,
    inlineToolbar: true,
  },
  inlineCode: {
    class: InlineCode,
    inlineToolbar: true,
  },
  subscript: {
    class: Subscript,
    inlineToolbar: true,
  },
  superscript: {
    class: Superscript,
    inlineToolbar: true,
  },
};

export const SHORT_POST_INLINE_TOOLS = {
  underline: {
    class: Underline,
    inlineToolbar: true,
  },
};

export const SHORT_POST_BLOCK_TOOLS = (placeholder: string) => {
  return {
    paragraph: {
      class: ParagraphTool,
      inlineToolbar: ['bold', 'italic', 'underline', 'link'],
      config: { placeholder },
    },
  };
};

export const BLOCK_TOOLS = ({ t, dispatch, uploadHandler, channelId }: BlockToolsConfigProps) => {
  return {
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
      config: { placeholder: t('richTextEditor.paragraph-config-placeholder') },
    },
    header: {
      inlineToolbar: ['link', 'marker'],
      class: Header,
      config: {
        levels: [1, 2, 3, 4, 5, 6],
        defaultLevel: 2,
        placeholder: t('richTextEditor.header-config-placeholder'),
      },
    },
    image: {
      class: Gallery,
      inlineToolbar: false,
      config: { dispatch, uploadHandler },
    },
    video: {
      class: VideoTool,
      inlineToolbar: false,
      config: { dispatch, uploadHandler },
    },
    audio: {
      class: AudioTool,
      inlineToolbar: false,
      config: { dispatch, uploadHandler },
    },
    pdf: {
      class: PdfTool,
      inlineToolbar: false,
      config: { dispatch, uploadHandler },
    },
    file: {
      class: FileTool,
      inlineToolbar: false,
      config: { dispatch, uploadHandler },
    },
    embedlink: {
      class: EmbedLinkTool,
      inlineToolbar: true,
      config: { dispatch },
    },
    story: {
      class: StoryTool,
      inlineToolbar: false,
      config: { dispatch, channelId },
    },
    card: {
      class: StoryCardTool,
      inlineToolbar: false,
      config: { dispatch, channelId },
    },
    user: {
      class: UserTool,
      inlineToolbar: false,
      config: { dispatch, placeholder: t('richTextEditor.user-config-placeholder') },
    },
    callout: {
      class: Callout,
      inlineToolbar: true,
    },
    list: {
      class: NestedList,
      inlineToolbar: true,
      config: { defaultStyle: 'unordered' },
    },
    checklist: {
      class: Checklist,
      inlineToolbar: true,
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      config: {
        quotePlaceholder: t('richTextEditor.quote-config-text-placeholder'),
        captionPlaceholder: t('richTextEditor.quote-config-author-placeholder'),
      },
    },
    table: {
      class: Table,
      inlineToolbar: true,
      config: { rows: 2, cols: 3 },
    },
    delimiter: {
      class: DelimiterTool,
      inlineToolbar: true,
    },
    code: {
      class: Code,
      inlineToolbar: false,
      config: { placeholder: t('richTextEditor.code-config-placeholder') },
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  NotificationDefault,
  PushNotificationDeleteArgs,
  PushNotificationsArgs,
  notificationsApi,
} from '../services';

interface PushNotificationsState {
  pushNotifications: NotificationDefault[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: PushNotificationsState = {
  pushNotifications: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
};

const { pushNotifications, pushNotificationDelete } = notificationsApi.endpoints;

const getPushNotificationsHandler = async (args: PushNotificationsArgs, { dispatch }: any) => {
  return dispatch(pushNotifications.initiate({ ...args })).unwrap();
};

export const getPushNotifications = createAsyncThunk(
  'getPushNotifications',
  getPushNotificationsHandler
);

export const loadMorePushNotifications = createAsyncThunk(
  'getPushNotifications/loadMore',
  getPushNotificationsHandler
);

export const deletePushNotification = createAsyncThunk(
  'deletePushNotification',
  async (args: PushNotificationDeleteArgs, { dispatch }) => {
    const deleted = await dispatch(pushNotificationDelete.initiate(args)).unwrap();
    return { ...args, deleted };
  }
);

const PushNotificationsSlice = createSlice({
  name: 'pushNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPushNotifications.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPushNotifications.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.pushNotifications = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMorePushNotifications.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.pushNotifications.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(deletePushNotification.fulfilled, (state, action) => {
        const { deleted, notificationId } = action.payload;

        if (!deleted) {
          return;
        }

        state.pushNotifications = state.pushNotifications.filter(({ id }) => id !== notificationId);
      });
  },
});

export default PushNotificationsSlice.reducer;

import { FunctionComponent, memo } from 'react';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { LineClamp } from '../../LineClamp';

import classes from './HorizontalStoryFeedSocialCommon.module.scss';

interface HorizontalStoryFeedSocialCommonProps {
  quote?: string;
  quoteSource?: string;
  quotePersonHandle?: string;
}

export const HorizontalStoryFeedSocialCommon: FunctionComponent<HorizontalStoryFeedSocialCommonProps> =
  memo(({ quote, quoteSource, quotePersonHandle }) => {
    return (
      <div className={classes['horizontal-card-social']}>
        {quoteSource && (
          <div className={classes['horizontal-card-social__source']}>
            <IconLabel
              iconId={quoteSource.toLowerCase()}
              iconSize={14}
              color={getCssVar('--card-social-icon-color')}
              singleColor
            />
            {quotePersonHandle && (
              <span className={classes['horizontal-card-social__person']}>{quotePersonHandle}</span>
            )}
          </div>
        )}
        {quote && (
          <LineClamp
            maxLines={5}
            readMore={'...'}
            contentClassName={classes['horizontal-card-social__quote']}
            stringContent={quote}
          />
        )}
      </div>
    );
  });

import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { StoryCardType } from '../../../services';
import { IconLabel, LineClamp, LineClampProps, getCssVar } from '../../../shared';

import classes from './FeedCardPreviewContent.module.scss';

interface FeedCardPreviewContentProps {
  cardType?: StoryCardType;
  source?: string;
  title?: string;
  subTitle?: string;
  text?: string;
  htmlText?: string;
}

export const FeedCardPreviewContent: FunctionComponent<FeedCardPreviewContentProps> = memo(
  ({ cardType, source, title, subTitle, text, htmlText }) => {
    const lineClampCommonProps = useMemo(() => {
      return {
        maxLines: 3,
        readMore: '...',
        readMoreClassName: classes['preview-card-content__read-more'],
      } as LineClampProps;
    }, []);

    const getIcon = useCallback(
      (className?: string) => {
        if (![StoryCardType.ARTICLE, StoryCardType.POST].includes(cardType as StoryCardType)) {
          return;
        }

        return (
          <IconLabel
            className={className}
            iconId={
              cardType === StoryCardType.ARTICLE ? 'arrow-rounded-right-up' : 'arrow-rounded-right'
            }
            iconSize={14}
            color={getCssVar('--card-content-headline-icon-color')}
            singleColor
          />
        );
      },
      [cardType]
    );

    return (
      <div className={classes['preview-card-content']}>
        {source && <div className={classes['preview-card-content__source']}>{source}</div>}
        {title && (
          <LineClamp
            {...lineClampCommonProps}
            maxLines={2}
            stringContent={title}
            stringContentIcon={getIcon(classes['preview-card-content__title-icon'])}
            readMore={<>...{getIcon(classes['preview-card-content__read-more-icon'])}</>}
            contentClassName={classes['preview-card-content__title']}
          />
        )}
        {subTitle && (
          <LineClamp
            {...lineClampCommonProps}
            stringContent={subTitle}
            contentClassName={classes['preview-card-content__subTitle']}
          />
        )}
        {!subTitle && !htmlText && text && (
          <LineClamp
            {...lineClampCommonProps}
            stringContent={text}
            contentClassName={classes['preview-card-content__text']}
            readMoreClassName={classes['preview-card-content__read-more-text']}
          />
        )}
        {!subTitle && htmlText && (
          <LineClamp
            {...lineClampCommonProps}
            htmlStringContent={htmlText}
            contentClassName={classes['preview-card-content__text']}
            readMoreClassName={classes['preview-card-content__read-more-text']}
          />
        )}
      </div>
    );
  }
);

import { CSSProperties, FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './Modal.module.scss';

export enum ModalButtonTypes {
  danger = 'danger',
  success = 'success',
}

interface ModalProps {
  isOpen: boolean;
  title?: string;
  body: JSX.Element;
  onClose?: () => void;
  contentStyle?: CSSProperties;
  previewMode?: boolean;
  previewSrc?: string;
  alignTop?: boolean;
  alignBottom?: boolean;
  keepOpened?: boolean;
  opacityOverlay?: boolean;
  error?: string;
}

export const Modal: FunctionComponent<ModalProps> = memo(
  ({
    isOpen,
    title,
    body,
    onClose,
    contentStyle,
    previewMode,
    previewSrc,
    alignTop,
    alignBottom,
    keepOpened,
    opacityOverlay,
    error,
  }) => {
    const { t } = useTranslation();

    const onCloseHandler = useCallback(() => {
      if (keepOpened) {
        return;
      }

      onClose?.();

      // multiple modals check
      if (!document.querySelectorAll('.modal-content').length) {
        document.body.style.overflow = 'auto';
      }
    }, [keepOpened, onClose]);

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      }

      return () => {
        //if multiple modals are opened
        if (!document.querySelectorAll('.modal-content').length) {
          document.body.style.overflow = 'auto';
        }
      };
    }, [isOpen]);

    const renderHeader = useMemo(() => title || !previewMode, [previewMode, title]);

    const copySourceClick = useCallback(async () => {
      if (!navigator.clipboard) {
        return;
      }

      await navigator.clipboard.writeText(previewSrc ?? '');

      toast(t('cardActions.source-url-copied'));
    }, [previewSrc, t]);

    return (
      <div
        className={classNames(classes['modal'], {
          [classes['modal--visible']]: isOpen,
        })}
      >
        <div
          onClick={onCloseHandler}
          className={classNames(classes['modal__overlay'], {
            [classes['modal__overlay--preview-mode']]: previewMode,
            [classes['modal__overlay--opacity']]: opacityOverlay,
          })}
        ></div>
        <div
          className={classNames('modal-content', classes['modal__content'], {
            [classes['modal__content--preview-mode']]: previewMode,
            [classes['modal__content--align-top']]: alignTop,
            [classes['modal__content--align-bottom']]: alignBottom,
            [classes['modal__content--mobile']]: isMobileOnly,
          })}
          style={contentStyle}
        >
          {renderHeader && (
            <div className={classes['modal__header']}>
              {title && <span className={classes['modal__header-title']}>{title}</span>}
              {!previewMode && !keepOpened && (
                <span className={classes['modal__close']} onClick={onCloseHandler}></span>
              )}
            </div>
          )}

          <div className={classes['modal__body']}>
            {body}
            {error && <div className={classes['modal__body-error']}>{error}</div>}
          </div>
        </div>

        {previewMode && (
          <>
            {Boolean(previewSrc) && (
              <IconLabel
                iconId={'copy'}
                iconSize={18}
                color={'#fff'}
                className={classes['modal__preview-src']}
                onClick={copySourceClick}
                singleColor
              />
            )}
            <span
              className={classNames(classes['modal__close'], classes['modal__close--preview-mode'])}
              onClick={onCloseHandler}
            ></span>
          </>
        )}
      </div>
    );
  }
);

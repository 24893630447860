import { format } from 'date-fns';
import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext, OrganisationContext, UserContext } from '../../context';
import { AnalyticChartPlatform, analyticsApi, OrganisationTagType } from '../../services';
import {
  ANALYTICS_ADMIN_COOKIE_KEY,
  Button,
  ButtonType,
  Check,
  CookieActionType,
  cookieOptions,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterValue,
  IconLabelSizes,
  LastDateValue,
  Select,
  TabItem,
  Tabs,
  useAppSelector,
  useDateRangeFilter,
  useFilter,
  useLastDate,
} from '../../shared';
import { Content } from './Content';
import { Other } from './Other';
import { User } from './User';

import classes from './Analytics.module.scss';

enum AnalyticsTabType {
  CONTENT,
  USER,
  OTHER,
}

export enum ChartType {
  CONTENT_AND_ENGAGEMENT = 'CONTENT_AND_ENGAGEMENT',
  CONTENT_WITH_INTERACTIONS = 'CONTENT_WITH_INTERACTIONS',
  CONTENT_TOP_5 = 'CONTENT_TOP_5',
  COMMENT_TOP_5 = 'COMMENT_TOP_5',
  CONTENT = 'CONTENT',
  CHANNELS = 'CHANNELS',
  MIXES = 'MIXES',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  IN_APP_NOTIFICATIONS = 'IN_APP_NOTIFICATIONS',
  ACTIVE_VS_ENGAGED_USERS = 'ACTIVE_VS_ENGAGED_USERS',
  ACTIVE_VS_ENGAGED_DEMO_USERS = 'ACTIVE_VS_ENGAGED_DEMO_USERS',
  USER_FUNNEL = 'USER_FUNNEL',
  USER_FUNNEL_V2 = 'USER_FUNNEL_V2',
  USER_FUNNEL_V3 = 'USER_FUNNEL_V3',
  USER_DEMO_FUNNEL = 'USER_DEMO_FUNNEL',
  USER_INTERACTIONS = 'USER_INTERACTIONS',
  USER_ROLE_CONTENT = 'USER_ROLE_CONTENT',
  SESSIONS_DURATION = 'SESSIONS_DURATION',
  SESSIONS_PER_DAY = 'SESSIONS_PER_DAY',
  SESSIONS_BY_DAY_OF_WEEK = 'SESSIONS_BY_DAY_OF_WEEK',
  SESSIONS_BY_HOUR_OF_DAY = 'SESSIONS_BY_HOUR_OF_DAY',
  ACTIVE_USERS_PER_MONTH = 'ACTIVE_USERS_PER_MONTH',
  LOGIN_LOGOUT = 'LOGIN_LOGOUT',
  INSTALL_UNINSTALL = 'INSTALL_UNINSTALL',
}

export const COLORS = [
  '#33AAFF',
  '#F471B6',
  '#00D7F3',
  '#FFDD33',
  '#7DBC60',
  '#EC8A44',
  '#F45C5C',
  '#833DA4',
  '#0077CC',
  '#52DC8B',
];

export const Analytics: FunctionComponent = () => {
  const { t } = useTranslation();

  const { channelsExclude } = useContext(ConfigContext).config.elements.analytics;

  const { tags } = useContext(OrganisationContext).organisation;

  const [getContentCSV] = analyticsApi.endpoints.analyticGenerateContentCSVReport.useLazyQuery();

  const [getUserCSV] = analyticsApi.endpoints.analyticGenerateUserCSVReport.useLazyQuery();

  const [getOtherCSV] = analyticsApi.endpoints.analyticGenerateOtherCSVReport.useLazyQuery();

  const { isLastDate, getLastDate } = useLastDate();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { channels } = useAppSelector(({ channels }) => channels);

  const COOKIE_KEY = useMemo(() => `${ANALYTICS_ADMIN_COOKIE_KEY}_${channelId}`, [channelId]);

  const USER_ACTIVE_COOKIE_KEY = useMemo(() => `${COOKIE_KEY}_userActive`, [COOKIE_KEY]);

  const [cookies, setCookie] = useCookies([COOKIE_KEY, USER_ACTIVE_COOKIE_KEY]);

  const defaultDate = LastDateValue.LAST_7_DAYS;

  const {
    dateRange = defaultDate,
    channelsId = [channelId],
    internalTagsId = [],
    externalTagsId = [],
    excludeInternalTags = false,
    excludeExternalTags = false,
    platform = [
      AnalyticChartPlatform.ANDROID,
      AnalyticChartPlatform.IOS,
      AnalyticChartPlatform.WEB,
    ],
    filter = [
      { groupId: DATE_RANGE_GROUP_ID, groupItems: [{ id: defaultDate, value: defaultDate }] },
    ],
    selectedTabIndex = AnalyticsTabType.CONTENT,
  } = { ...cookies[COOKIE_KEY] };

  const channelsIdFiltered = useMemo(
    () => channelsId.filter((id: number) => !channelsExclude.includes(id)),
    [channelsExclude, channelsId]
  );

  const { userActiveYear = new Date().getFullYear() } = { ...cookies[USER_ACTIVE_COOKIE_KEY] };

  const channelItems = useMemo(() => {
    return channels
      .filter(({ id }) => !channelsExclude.includes(id))
      .map(({ id, name }) => ({ id, title: name }));
  }, [channels, channelsExclude]);

  const internalTagsItems = useMemo(() => {
    return tags
      .filter(({ type }) => type === OrganisationTagType.INTERNAL)
      .map(({ id, name }) => ({ id, title: name }));
  }, [tags]);

  const externalTagsItems = useMemo(() => {
    return tags
      .filter(({ type }) => type === OrganisationTagType.EXTERNAL)
      .map(({ id, name }) => ({ id, title: name }));
  }, [tags]);

  const organisationTags = useMemo(() => {
    return { internalTagsId, externalTagsId };
  }, [externalTagsId, internalTagsId]);

  const { group: dateRangeGroup } = useDateRangeFilter({ filter, allowReset: false });

  const { getDateRangeValue } = useFilter();

  const lastDate = useMemo(() => isLastDate(dateRange), [dateRange, isLastDate]);

  const { from = '', to = '' } = lastDate ? getLastDate(dateRange) : { ...dateRange };

  const dateFilterLabel = useMemo(() => {
    if (lastDate) {
      return t(`dateRangeFilter.${dateRange}`);
    }
    return `${format(new Date(from), 'dd.MM.yy')} - ${format(new Date(to), 'dd.MM.yy')}`;
  }, [dateRange, from, lastDate, t, to]);

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const { dateRange } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      setCookie(
        COOKIE_KEY,
        { ...cookies[COOKIE_KEY], dateRange, filter },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, getDateRangeValue, setCookie]
  );

  const channelChange = useCallback(
    (channelsId: number[]) => {
      setCookie(
        COOKIE_KEY,
        { ...cookies[COOKIE_KEY], channelsId },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const internalTagsChange = useCallback(
    (internalTagsId: number[]) => {
      setCookie(
        COOKIE_KEY,
        { ...cookies[COOKIE_KEY], internalTagsId },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const externalTagsChange = useCallback(
    (externalTagsId: number[]) => {
      setCookie(
        COOKIE_KEY,
        { ...cookies[COOKIE_KEY], externalTagsId },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const tabIndexChange = useCallback(
    (selectedTabIndex: number) => {
      setCookie(
        COOKIE_KEY,
        { ...cookies[COOKIE_KEY], selectedTabIndex },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const renderChannelFilter = useMemo(() => {
    if (selectedTabIndex !== AnalyticsTabType.CONTENT) {
      return null;
    }

    return (
      <Select
        label={t('common.channels_other')}
        className={classes['analytics__tab-content-filters-select']}
        selectedItemIds={channelsIdFiltered}
        items={channelItems}
        onMultiCheckChange={channelChange}
        disabled={channelItems.length === 1}
      />
    );
  }, [channelChange, channelItems, channelsIdFiltered, selectedTabIndex, t]);

  const renderTagsFilter = useMemo(() => {
    if (selectedTabIndex !== AnalyticsTabType.CONTENT) {
      return null;
    }

    return (
      <>
        {Boolean(internalTagsItems.length) && (
          <Select
            key={`excludeInternalTags:${excludeInternalTags}`}
            label={t(`organisationTags.title-INTERNAL`)}
            className={classes['analytics__tab-content-filters-select']}
            selectedItemIds={internalTagsId}
            items={internalTagsItems}
            onMultiCheckChange={internalTagsChange}
            disabled={excludeInternalTags}
          />
        )}

        {Boolean(externalTagsItems.length) && (
          <Select
            key={`excludeExternalTags:${excludeExternalTags}`}
            label={t(`organisationTags.title-EXTERNAL`)}
            className={classes['analytics__tab-content-filters-select']}
            selectedItemIds={externalTagsId}
            items={externalTagsItems}
            onMultiCheckChange={externalTagsChange}
            disabled={excludeExternalTags}
          />
        )}
      </>
    );
  }, [
    excludeExternalTags,
    excludeInternalTags,
    externalTagsChange,
    externalTagsId,
    externalTagsItems,
    internalTagsChange,
    internalTagsId,
    internalTagsItems,
    selectedTabIndex,
    t,
  ]);

  const excludeInternalTagsChange = useCallback(
    (excludeInternalTags: boolean) => {
      setCookie(
        COOKIE_KEY,
        {
          ...cookies[COOKIE_KEY],
          internalTagsId: excludeInternalTags ? null : [],
          excludeInternalTags,
        },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const excludeExternalTagsChange = useCallback(
    (excludeExternalTags: boolean) => {
      setCookie(
        COOKIE_KEY,
        {
          ...cookies[COOKIE_KEY],
          externalTagsId: excludeExternalTags ? null : [],
          excludeExternalTags,
        },
        cookieOptions({ action: CookieActionType.SET })
      );
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const renderExcludeTags = useMemo(() => {
    if (
      selectedTabIndex !== AnalyticsTabType.CONTENT ||
      !Boolean([...internalTagsItems, ...externalTagsItems].length)
    ) {
      return null;
    }

    return (
      <div className={classes['analytics__tab-content-filters-exclude-tags']}>
        {Boolean(internalTagsItems.length) && (
          <Check
            label={t(`organisationTags.exclude-${OrganisationTagType.INTERNAL}`)}
            checked={excludeInternalTags}
            onChange={excludeInternalTagsChange}
          />
        )}

        {Boolean(externalTagsItems.length) && (
          <Check
            label={t(`organisationTags.exclude-${OrganisationTagType.EXTERNAL}`)}
            checked={excludeExternalTags}
            onChange={excludeExternalTagsChange}
          />
        )}
      </div>
    );
  }, [
    excludeExternalTags,
    excludeExternalTagsChange,
    excludeInternalTags,
    excludeInternalTagsChange,
    externalTagsItems,
    internalTagsItems,
    selectedTabIndex,
    t,
  ]);

  const tabFilters = useMemo(() => {
    return (
      <>
        <Filter
          iconId={'down-arrow'}
          label={dateFilterLabel}
          labelSize={IconLabelSizes.large}
          groups={[dateRangeGroup]}
          filter={filter}
          onChange={filterChange}
        />

        {renderChannelFilter}

        {renderTagsFilter}

        {renderExcludeTags}
      </>
    );
  }, [
    dateFilterLabel,
    dateRangeGroup,
    filter,
    filterChange,
    renderChannelFilter,
    renderExcludeTags,
    renderTagsFilter,
  ]);

  const getCSVReport = useMemo(() => {
    switch (selectedTabIndex) {
      case AnalyticsTabType.CONTENT:
        return getContentCSV;
      case AnalyticsTabType.USER:
        return getUserCSV;
      default:
        return getOtherCSV;
    }
  }, [getContentCSV, getOtherCSV, getUserCSV, selectedTabIndex]);

  const exportTabCharts = useCallback(async () => {
    const sent = await getCSVReport({
      from,
      to,
      channelId: channelsIdFiltered,
      platform,
      yearUserSessionPerMonth: userActiveYear,
      organisationTags,
    }).unwrap();
    sent ? toast(t('common.report-sent')) : toast.error(t('common.error-message'));
  }, [channelsIdFiltered, from, getCSVReport, organisationTags, platform, t, to, userActiveYear]);

  const tabContent = useCallback(
    (tabChart: JSX.Element) => {
      return (
        <div className={classes['analytics__tab-content']}>
          <div className={classes['analytics__tab-content-filters']}>{tabFilters}</div>
          {tabChart}
        </div>
      );
    },
    [tabFilters]
  );

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        index: AnalyticsTabType.CONTENT,
        name: t('common.content'),
        content: tabContent(
          <Content
            from={from}
            to={to}
            channelId={channelsIdFiltered}
            platform={platform}
            organisationTags={organisationTags}
          />
        ),
      },
      {
        index: AnalyticsTabType.USER,
        name: t('common.user'),
        content: tabContent(<User from={from} to={to} platform={platform} />),
      },
      {
        index: AnalyticsTabType.OTHER,
        name: t('common.other'),
        content: tabContent(<Other from={from} to={to} />),
      },
    ],
    [channelsIdFiltered, from, organisationTags, platform, t, tabContent, to]
  );

  return (
    <div className={classes['analytics']}>
      <div className={classes['analytics__header']}>{t('adminLayoutMenu.analytics')}</div>
      <Button
        className={classes['analytics__export']}
        label={t('common.export')}
        type={ButtonType.primary}
        onClick={exportTabCharts}
      />
      <Tabs
        items={tabItems}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={tabIndexChange}
        noBackground
      />
    </div>
  );
};

import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import {
  StoryCard,
  StoryCardContentArticlePost,
  StoryCardContentArticleQuote,
  StoryCardContentGallery,
  StoryCardContentHeadline,
  StoryCardContentSourceName,
  StoryCardContentTitle,
  StoryCardTeaserImageStyle,
  StoryCardType,
} from '../../../services';
import { GalleryItem, IconLabel, getCssVar, useCardDate } from '../../../shared';
import { DetailsModal } from '../../CardDetails';

import classes from './Teaser.module.scss';

interface TeaserProps {
  card: StoryCard;
}

export const Teaser: FunctionComponent<TeaserProps> = memo(({ card }) => {
  const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

  const { storyId, id, type, content, dateToDisplay } = card;

  const date = useCardDate(dateToDisplay);

  const { title } = content as StoryCardContentTitle;
  const { sourceName } = content as StoryCardContentSourceName;
  const { headline } = content as StoryCardContentHeadline;
  const { gallery } = content as StoryCardContentGallery;
  const { styles } = content as StoryCardContentArticlePost;
  const { url } = content as StoryCardContentArticleQuote;

  const bigTeaser = useMemo(
    () => styles?.teaserImageStyle === StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT,
    [styles?.teaserImageStyle]
  );

  const teaserClickHandler = useCallback(() => {
    if (url && [StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type)) {
      window.open(url, '_blank', 'noreferrer');
    } else {
      setOpenCardDetailsModal(true);
    }
  }, [type, url]);

  const getPreviewSrc = useCallback(
    (galleryItem: GalleryItem) => {
      const imageUrl = galleryItem?.image?.url;

      switch (type) {
        case StoryCardType.IMAGE:
        case StoryCardType.QUOTE:
        case StoryCardType.POST:
        case StoryCardType.ARTICLE:
          return imageUrl;
        case StoryCardType.AUDIO:
          return imageUrl || galleryItem?.audio?.thumb;
        case StoryCardType.VIDEO:
          return imageUrl || galleryItem?.video?.thumb;
        case StoryCardType.PDF:
          return imageUrl || galleryItem?.pdf?.thumb;
        default:
          return undefined;
      }
    },
    [type]
  );

  const headlineText = useMemo(
    () =>
      [StoryCardType.ARTICLE, StoryCardType.POST, StoryCardType.THREAD].includes(type)
        ? title
        : headline,
    [headline, title, type]
  );

  const previewSrc = getPreviewSrc(gallery?.[0] as GalleryItem);

  const teaserContent = useMemo(() => {
    if (bigTeaser) {
      return (
        <div className={classes['teaser__big']}>
          {previewSrc && (
            <div
              className={classes['teaser__big-preview']}
              style={{ backgroundImage: `url(${previewSrc})` }}
            ></div>
          )}
          <div className={classes['teaser__big-content']}>
            {sourceName && (
              <div className={classes['teaser__big-content-source']}>{sourceName}</div>
            )}
            {headlineText && (
              <div className={classes['teaser__big-content-headline']}>
                {headlineText}
                <span className={classes['teaser__big-content-headline-icon']}>
                  <IconLabel
                    iconId={
                      type === StoryCardType.ARTICLE
                        ? 'arrow-rounded-right-up'
                        : 'arrow-rounded-right'
                    }
                    iconSize={16}
                    color={getCssVar('--card-teaser-icon-color')}
                    singleColor
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        {sourceName && <div className={classes['teaser__source']}>{sourceName}</div>}
        {previewSrc && (
          <img className={classes['teaser__preview']} src={previewSrc} alt={'preview'} />
        )}
        {headlineText && (
          <div className={classes['teaser__headline']}>
            <IconLabel
              iconId={'arrow-rounded-right'}
              iconSize={10}
              label={headlineText}
              color={getCssVar('--special-teaser-title-color')}
              singleColor
              labelFirst
            />
          </div>
        )}
        <div className={classes['teaser__posted-time']}>{date}</div>
      </>
    );
  }, [bigTeaser, date, headlineText, previewSrc, sourceName, type]);

  return (
    <>
      <div className={classes['teaser']} onClick={teaserClickHandler}>
        {teaserContent}
      </div>

      {openCardDetailsModal && (
        <DetailsModal
          isOpen={openCardDetailsModal}
          storyId={storyId}
          cardId={id}
          onClose={() => setOpenCardDetailsModal(false)}
        />
      )}
    </>
  );
});

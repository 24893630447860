import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TCHOP_ROOT_URL } from '../env';
import { prepareHeaders, resolveBaseUrl } from './helpers';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  tagTypes: ['userProfile'],
  baseQuery: fetchBaseQuery({
    baseUrl: resolveBaseUrl('/api', TCHOP_ROOT_URL),
    credentials: 'include',
    prepareHeaders,
  }),
  endpoints: () => ({}),
});

import { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PostedIn } from '../../../../components';
import { NoContent } from '../../../../pages';
import { StoryCard, StoryCardStatus } from '../../../../services';
import { useAppSelector, useUserFeed } from '../../../hooks';
import { FeedCardLoader } from '../../FeedCard';

import classes from './StoriesCardsUserFeedCommon.module.scss';

interface StoriesCardsUserFeedCommonProps {
  userId: number;
  cardComponent: (card: StoryCard) => JSX.Element;
}

export const StoriesCardsUserFeedCommon: FunctionComponent<StoriesCardsUserFeedCommonProps> = memo(
  ({ userId, cardComponent }) => {
    const { t } = useTranslation();

    const { getUserFeedItems, loadMoreUserFeedItems, status } = useUserFeed({ userId });

    const {
      storiesCardsUserFeed: cards,
      hasNextPage,
      isFetching,
    } = useAppSelector(({ storiesCardsUserFeed }) => storiesCardsUserFeed);

    useEffect(() => {
      getUserFeedItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasCards = useMemo(() => Boolean(cards.length), [cards.length]);

    const noContentSubTitle = useMemo(
      () => t(`userFeed.tab${Object.keys(StoryCardStatus).indexOf(status) + 1}-empty-message`),
      [status, t]
    );

    const content = useMemo(() => {
      if (!hasCards) {
        return <NoContent subtitle={noContentSubTitle} />;
      }
      return (
        <InfiniteScroll
          next={loadMoreUserFeedItems}
          hasMore={hasNextPage}
          loader={<FeedCardLoader />}
          dataLength={cards.length}
          className={classes['stories-user-feed']}
        >
          <div className={classes['stories-user-feed__cards']}>
            {cards.map((card, index, cardsArray) => (
              <div key={card.id}>
                {card.story.title !== cardsArray[index - 1]?.story.title && (
                  <PostedIn story={card.story} />
                )}
                {cardComponent(card)}
              </div>
            ))}
          </div>
        </InfiniteScroll>
      );
    }, [cardComponent, cards, hasCards, hasNextPage, loadMoreUserFeedItems, noContentSubTitle]);

    if (isFetching) {
      return <FeedCardLoader />;
    }

    return content;
  }
);

import { FunctionComponent, memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

interface PublishToggleProps {
  isPublished: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const PublishToggle: FunctionComponent<PublishToggleProps> = memo(
  ({ isPublished, onClick, disabled }) => {
    const { t } = useTranslation();

    const publishTogglePrefix = '--publish-toggle';
    const baseLinkPrefix = '--base-link';

    return (
      <IconLabel
        iconId={isPublished ? 'backwards' : 'forward'}
        {...(!isMobileOnly && { label: t(`common.${isPublished ? 'unpublish' : 'publish'}`) })}
        color={getCssVar(`${isPublished ? publishTogglePrefix : baseLinkPrefix}-text-color`)}
        hoverColor={getCssVar(
          `${isPublished ? publishTogglePrefix : baseLinkPrefix}-text-hover-color`
        )}
        onClick={onClick}
        disabled={disabled}
      />
    );
  }
);

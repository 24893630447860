import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconLabel } from '../../../../IconLabel';

import classNames from 'classnames';
import classes from './MediaUploadPreview.module.scss';

interface MediaUploadPreviewProps {
  iconId: string;
  label: string;
  className?: string;
}

export const MediaUploadPreview: FunctionComponent<MediaUploadPreviewProps> = memo(
  ({ iconId, label, className }) => {
    const { t } = useTranslation();

    return (
      <div
        className={classNames(classes['preview'], className)}
        onClick={() => toast.info(t('richTextEditor.media-block-not-ready'))}
      >
        <IconLabel iconId={iconId} label={label} nonClickable singleColor />
      </div>
    );
  }
);

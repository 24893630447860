import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const Sad = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx={15}
        cy={15}
        r={14.5}
        fill="#FFD972"
        stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
      />
      <g>
        <path
          d="M12.1889 23.3063C12.2223 23.2726 12.2953 23.205 12.403 23.1148C12.5868 22.9609 12.7963 22.8063 13.0263 22.6621C13.6718 22.2577 14.3437 22.0177 15 22.0177C15.6563 22.0177 16.3283 22.2577 16.9737 22.6621C17.2037 22.8063 17.4132 22.9609 17.5971 23.1148C17.7048 23.205 17.7778 23.2726 17.8112 23.3063C18.0201 23.5165 18.365 23.5005 18.5534 23.2717L18.592 23.2248C18.7737 23.0043 18.7587 22.682 18.5573 22.4794L18.5218 22.4436C18.316 22.2365 17.9632 21.9411 17.4943 21.6472C16.7029 21.1513 15.8621 20.8511 15 20.8511C14.138 20.8511 13.2972 21.1513 12.5058 21.6472C12.0368 21.9411 11.684 22.2365 11.4782 22.4436L11.4427 22.4794C11.2414 22.682 11.2264 23.0043 11.408 23.2248L11.4466 23.2717C11.6351 23.5005 11.9799 23.5165 12.1889 23.3063Z"
          fill="#262C39"
        />
        <g>
          <ellipse cx={9.02083} cy={16.0208} rx={1.89583} ry={1.89583} fill="#262C39" />
          <circle cx={21.2708} cy={16.0208} r={1.89583} fill="#262C39" />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70729 10.9074C10.1675 11.221 9.52288 11.9276 8.96745 11.9682C8.58314 11.9963 8.17747 12.0572 7.88625 12.1818C7.52504 12.3364 7.13773 12.6929 6.83654 13.0165C6.55203 13.3221 6.04715 13.3273 5.79043 12.9979C5.61927 12.7784 5.61349 12.4729 5.79799 12.2644C6.14665 11.8704 6.77737 11.2544 7.55064 10.9293C8.74508 10.4271 9.56485 10.8103 9.70729 10.9074Z"
            fill="#262C39"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4426 10.9074C19.9824 11.221 20.627 11.9276 21.1825 11.9682C21.5668 11.9963 21.9724 12.0572 22.2636 12.1818C22.6249 12.3364 23.0122 12.6929 23.3134 13.0165C23.5979 13.3221 24.1028 13.3273 24.3595 12.9979C24.5306 12.7784 24.5364 12.4729 24.3519 12.2644C24.0033 11.8704 23.3725 11.2544 22.5993 10.9293C21.4048 10.4271 20.5851 10.8103 20.4426 10.9074Z"
            fill="#262C39"
          />
        </g>
      </g>
      <ellipse cx={23.3125} cy={24.5476} rx={2.47917} ry={4.16066} fill="#5890FF" />
    </g>
  </svg>
);

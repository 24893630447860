import { FunctionComponent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardTeaserImageStyle } from '../../../../../services';
import { getCssVar } from '../../../../utils';
import { IconLabel } from '../../../IconLabel';

import classNames from 'classnames';
import classes from './TeaserStyle.module.scss';

interface TeaserStyleProps {
  teaserStyle: StoryCardTeaserImageStyle;
  setTeaserStyle: (teaserStyle: StoryCardTeaserImageStyle) => void;
}

export const TeaserStyle: FunctionComponent<TeaserStyleProps> = memo(
  ({ teaserStyle, setTeaserStyle }) => {
    const { t } = useTranslation();

    const getTeaserIconByType = useCallback((cardTeaserStyle: StoryCardTeaserImageStyle) => {
      switch (cardTeaserStyle) {
        case StoryCardTeaserImageStyle.STANDARD:
          return 'teaser_standard';
        case StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT:
          return 'teaser_large';
        case StoryCardTeaserImageStyle.SMALL_WITH_TEXT:
          return 'teaser_small';
        case StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT:
          return 'teaser_small_without_text';
      }
    }, []);

    const getTeaserLabelByType = useCallback(
      (cardTeaserStyle: StoryCardTeaserImageStyle) => {
        switch (cardTeaserStyle) {
          case StoryCardTeaserImageStyle.STANDARD:
            return t('teasers.standard');
          case StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT:
            return t('teasers.large');
          case StoryCardTeaserImageStyle.SMALL_WITH_TEXT:
            return t('teasers.small');
          case StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT:
            return t('teasers.small-without-text');
        }
      },
      [t]
    );

    return (
      <div className={classes['teaser-style']}>
        <div className={classes['teaser-style__title']}>{t('common.teaser-style')}</div>

        {Object.entries(StoryCardTeaserImageStyle).map(([key, value]) => {
          return (
            <div
              key={key}
              className={classNames(classes['teaser-style__item'], {
                [classes['teaser-style__item--selected']]: value === teaserStyle,
              })}
              onClick={() => setTeaserStyle(value)}
            >
              <div className={classes['teaser-style__item-radio']}></div>
              <IconLabel
                iconSize={50}
                iconId={getTeaserIconByType(value)}
                label={getTeaserLabelByType(value)}
                color={getCssVar('--create-card-teaser-item-text-color')}
                singleColor
              />
            </div>
          );
        })}
      </div>
    );
  }
);

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import sprite from '../../../assets/icons/sprite.svg';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Check, CheckLabelSizes } from '../Check';
import { IconLabel } from '../IconLabel';
import { InputField } from '../InputField';

import classNames from 'classnames';
import classes from './Select.module.scss';

export interface SelectItem<T extends string | number = string | number> {
  id: T;
  title: string;
  hint?: string;
}

interface SelectProps<T extends string | number> {
  label?: string;
  items: SelectItem<T>[];
  selectedItemId?: T;
  selectedItemIds?: T[];
  logo?: JSX.Element;
  className?: string;
  listMaxHeightRem?: number;
  disabled?: boolean;
  staticListPosition?: boolean;
  search?: boolean;
  onChange?: (id: T) => void;
  onMultiCheckChange?: (ids: T[]) => void;
}

const genericMemo: <T>(component: T) => T = memo;

export const Select = genericMemo(
  <T extends string | number>({
    label,
    items,
    selectedItemId,
    selectedItemIds,
    logo,
    className,
    listMaxHeightRem,
    disabled,
    staticListPosition,
    search,
    onChange,
    onMultiCheckChange,
  }: SelectProps<T>) => {
    const { t } = useTranslation();

    const selectRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [searchInput, setSearchInput] = useState<string>('');

    const [filteredItems, setFilteredItems] = useState<SelectItem<T>[] | undefined>(undefined);

    const [selectedItem, setSelectedItem] = useState<SelectItem<T> | undefined>(() => {
      return items.find(({ id }) => id === selectedItemId) || items[0];
    });

    const multiCheck = useMemo(() => Boolean(onMultiCheckChange), [onMultiCheckChange]);

    const [selectedItems, setSelectedItems] = useState<SelectItem<T>[] | undefined>(() => {
      if (!multiCheck) {
        return;
      }
      return items.filter(({ id }) => selectedItemIds?.includes(id)) || [];
    });

    useEffect(() => {
      if (items.length && !filteredItems) {
        setFilteredItems(items);
      }
    }, [filteredItems, items]);

    useOnClickOutside(selectRef, () => {
      setIsOpen(false);
    });

    const toggle = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    const onItemChange = useCallback(
      (item: SelectItem<T>) => {
        setSelectedItem(item);
        onChange?.(item.id);
      },
      [onChange]
    );

    const onMultiCheckItemChange = useCallback(
      (item: SelectItem<T>) => {
        if (!selectedItems) {
          return;
        }

        const selectedItemsCopy = [...selectedItems];

        const itemIndex = selectedItemsCopy.findIndex(({ id }) => id === item.id);

        itemIndex === -1 ? selectedItemsCopy.push(item) : selectedItemsCopy.splice(itemIndex, 1);

        setSelectedItems(selectedItemsCopy);

        onMultiCheckChange?.(selectedItemsCopy.map(({ id }) => id));
      },
      [onMultiCheckChange, selectedItems]
    );

    const headerContent = useMemo(() => {
      if (logo) {
        return logo;
      }

      const title = multiCheck
        ? selectedItems?.map(({ title }) => title).join(', ') || t('common.please-select')
        : selectedItem?.title;

      return (
        <>
          {label && <span className={classes['select__header-content-label']}>{label}</span>}
          <span
            className={classNames(classes['select__header-content-title'], {
              [classes['select__header-content-title--without-label']]: !label,
            })}
          >
            {title}
          </span>
        </>
      );
    }, [label, logo, multiCheck, selectedItem?.title, selectedItems, t]);

    const selectAll = useCallback(
      (isChecked: boolean) => {
        setSelectedItems(isChecked ? [] : items);
        onMultiCheckChange?.(isChecked ? [] : items.map(({ id }) => id));
      },
      [items, onMultiCheckChange]
    );

    const renderSelectAll = useMemo(() => {
      if (!multiCheck) {
        return null;
      }

      const isChecked = selectedItemIds?.length === items.length;
      const label = isChecked ? t('common.unselect-all') : t('common.select-all');

      return (
        <li
          className={classes['select__list-item']}
          onClick={(event) => {
            event.stopPropagation();
            selectAll(isChecked);
          }}
        >
          <Check label={label} labelSize={CheckLabelSizes.large} checked={isChecked} />
        </li>
      );
    }, [items.length, multiCheck, selectAll, selectedItemIds?.length, t]);

    const searchInputChange = useCallback(
      (value: string) => {
        setSearchInput(value);
        setFilteredItems(
          items.filter(({ title }) => title.toLowerCase().startsWith(value.toLowerCase()))
        );
      },
      [items]
    );

    const clearSearchInput = useCallback(() => {
      setSearchInput('');
      setFilteredItems(items);
    }, [items]);

    const renderSearch = useMemo(() => {
      if (!search) {
        return null;
      }

      return (
        <li className={classes['select__search']} onClick={(event) => event.stopPropagation()}>
          <InputField
            value={searchInput}
            onChange={({ target }) => searchInputChange(target.value)}
            placeholder={t('common.type-to-search')}
          />

          {searchInput && (
            <IconLabel
              className={classes['select__search-clear']}
              iconId={'close'}
              singleColor
              onClick={clearSearchInput}
            />
          )}
        </li>
      );
    }, [clearSearchInput, search, searchInput, searchInputChange, t]);

    const renderMultiCheckListItem = useCallback(
      (item: SelectItem<T>) => {
        const isSelected = Boolean(selectedItems?.find(({ id }) => item.id === id));

        return (
          <li
            key={item.id}
            className={classes['select__list-item']}
            onClick={(event) => {
              event.stopPropagation();
              onMultiCheckItemChange(item);
            }}
          >
            <Check label={item.title} labelSize={CheckLabelSizes.large} checked={isSelected} />
          </li>
        );
      },
      [onMultiCheckItemChange, selectedItems]
    );

    const renderListItem = useCallback(
      (item: SelectItem<T>) => {
        if (multiCheck) {
          return renderMultiCheckListItem(item);
        }

        const { id, title, hint } = item;

        const isSelected = Boolean(id === selectedItem?.id);

        return (
          <li key={id} className={classes['select__list-item']} onClick={() => onItemChange(item)}>
            <div className={classes['select__list-item-title']}>
              {title}
              {hint && <div className={classes['select__list-item-title-hint']}>{hint}</div>}
            </div>

            {isSelected && (
              <svg className={classes['select__list-item-check']}>
                <use href={`${sprite}#checkmark`} />
              </svg>
            )}
          </li>
        );
      },
      [multiCheck, onItemChange, renderMultiCheckListItem, selectedItem?.id]
    );

    return (
      <div
        ref={selectRef}
        className={classNames(
          classes['select'],
          { [classes['select--disabled']]: disabled },
          className
        )}
        onClick={toggle}
      >
        <div className={classes['select__header']}>
          <div className={classes['select__header-content']}>{headerContent}</div>

          {!disabled && (
            <svg
              className={classNames(classes['select__header-arrow'], {
                [classes['select__header-arrow--up']]: isOpen,
              })}
            >
              <use href={`${sprite}#down-arrow`} />
            </svg>
          )}
        </div>

        {isOpen && (
          <ul
            className={classNames(classes['select__list'], {
              [classes['select__list--static-position']]: staticListPosition,
            })}
            {...(listMaxHeightRem && { style: { maxHeight: `${listMaxHeightRem}rem` } })}
          >
            {renderSearch}
            {renderSelectAll}
            {filteredItems?.map((item) => renderListItem(item))}
          </ul>
        )}
      </div>
    );
  }
);

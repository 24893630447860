import { IS_PRODUCTION, TCHOP_ORGANISATION_DOMAIN } from '../../env';
import { getCookieByName } from './getCookieByName';

export const getOrganisationDomain = (): string => {
  const organisation = getCookieByName('mz-organisation-domain');

  if (organisation) {
    return organisation;
  }

  if (!IS_PRODUCTION) {
    return TCHOP_ORGANISATION_DOMAIN ?? '';
  }

  // https://mbo-cardstyleguide.tchop-staging.com/path => cardstyleguide
  return window.location.hostname.split('.')[0].split('-').slice(-1).pop() ?? '';
};

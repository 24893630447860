import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { useTranslation } from 'react-i18next';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardThreadContent } from '../../../../services';

export const HorizontalStoryFeedAdminThread: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { t } = useTranslation();

    const { content, isPinnedToTopOfNewsFeed } = card;
    const { title } = content as StoryCardThreadContent;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          source={t('common.discussion')}
          title={title}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedAdminCardWrapper>
    );
  }
);

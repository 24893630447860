import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { AnalyticChartInterval, useUserActivityQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { differenceInDays, subDays } from 'date-fns';
import { ChartWrapper, Loader, StatsItem, TimeSeriesChart } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserActivity: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { data: userActivity, isFetching: isFetchingUserActivity } = useUserActivityQuery({
    from,
    to,
    interval: AnalyticChartInterval.AUTO,
    platform,
  });

  const { data: userActivityStats, isFetching: isFetchingUserActivityStats } = useUserActivityQuery(
    {
      from,
      to,
      platform,
    }
  );

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: userActivityStatsPrev, isFetching: isFetchingUserActivityStatsPrev } =
    useUserActivityQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
      platform,
    });

  const [userActivityLabels, setUserActivityLabels] = useState<string[]>([]);

  useEffect(() => {
    if (userActivity && !userActivityLabels.length) {
      setUserActivityLabels(userActivity.labels);
    }
  }, [userActivity, userActivityLabels.length]);

  const renderStats = useMemo(() => {
    if (isFetchingUserActivityStats || isFetchingUserActivityStatsPrev) {
      return <Loader stats />;
    }

    if (!userActivityStats || !userActivityStatsPrev) {
      return null;
    }

    const { datasets } = userActivityStats;
    const { datasets: datasetsPrev } = userActivityStatsPrev;

    return (
      <div className={classes['analytics__chart-stats']}>
        {datasets.map(({ label, data }, index) => {
          const isDisabled = !userActivityLabels.includes(label);
          const isClickable = userActivityLabels.length > 1 || isDisabled;

          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`userActivityLabels.${label}`}
              hintLocaliseKey={`userActivityHints.${label}`}
              color={COLORS[index]}
              value={data[0] ?? 0}
              valuePrev={datasetsPrev.find((set) => set.label === label)?.data[0] ?? 0}
              labels={userActivityLabels}
              setLabels={setUserActivityLabels}
              isInline
              isDisabled={isDisabled}
              isClickable={isClickable}
            />
          );
        })}
      </div>
    );
  }, [
    isFetchingUserActivityStats,
    isFetchingUserActivityStatsPrev,
    userActivityLabels,
    userActivityStats,
    userActivityStatsPrev,
  ]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-activity'}
      infoLocaliseKey={'userActivity.info'}
    >
      {renderStats}
      {userActivity && (
        <TimeSeriesChart
          timeSeries={userActivity}
          labels={userActivityLabels}
          localiseKeyLabels={'userActivityLabels'}
          loading={isFetchingUserActivity}
        />
      )}
    </ChartWrapper>
  );
});

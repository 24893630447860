import { FunctionComponent, memo } from 'react';
import { StoryCardEditorialContent } from '../../../services';
import { FeedCardAdminContent, FeedCardProps } from '../../../shared';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';

export const FeedCardAdminEditorial: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;

  const { sourceName, headline, subHeadline, text, textHTML } =
    content as StoryCardEditorialContent;

  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardAdminContent
        storyId={storyId}
        cardId={id}
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={headline}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      />
    </FeedCardAdminWrapper>
  );
});

import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailsModal } from '../../../components';
import { MusicPlayerContext } from '../../../context';
import { MUSIC_PLAYER_COOKIE_KEY } from '../../constants';
import { useAnalytics, useBeforeUnload, useDuration, usePrevious } from '../../hooks';
import { CookieActionType, cookieOptions, getCssVar, layoutPath } from '../../utils';
import { CloseButton } from '../CloseButton';
import { IconLabel } from '../IconLabel';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';

import classNames from 'classnames';
import classes from './MusicPlayer.module.scss';

export const MusicPlayer: FunctionComponent = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { musicPlayerData, setMusicPlayerData } = useContext(MusicPlayerContext);

  const { channelId, storyId, cardId, cardTitle, src, isPlaying } = musicPlayerData;

  const cardIdPrev = usePrevious(cardId) ?? 0;

  const { logItemPlayed, logClickOpenArticle } = useAnalytics();

  const { startTrackTime, stopTrackTime, getDuration } = useDuration();

  const [cookies, setCookie] = useCookies([MUSIC_PLAYER_COOKIE_KEY]);

  const { playlist = {} } = { ...cookies[MUSIC_PLAYER_COOKIE_KEY] };

  const ref = useRef<AudioPlayer>(null);

  const audio = ref.current?.audio.current;

  const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

  const setCurrentTime = useCallback(() => {
    if (!audio) {
      return;
    }

    if (playlist[`${cardId}_${src}`]) {
      audio.currentTime = playlist[`${cardId}_${src}`].currentTime ?? 0;
    }
  }, [audio, cardId, playlist, src]);

  const saveCurrentTime = useCallback(() => {
    if (!audio) {
      return;
    }

    const { currentTime, duration } = audio;

    if (!duration) {
      return;
    }

    const played = duration - currentTime < 1;

    playlist[`${cardId}_${src}`] = { played, currentTime: played ? 0 : Math.round(currentTime) };

    setCookie(
      MUSIC_PLAYER_COOKIE_KEY,
      { playlist },
      cookieOptions({ action: CookieActionType.SET })
    );
  }, [audio, cardId, playlist, setCookie, src]);

  const onPlayHandling = useCallback(() => {
    setCurrentTime();

    startTrackTime();

    if (
      Boolean(
        pathname !== layoutPath(`/details/${storyId}/${cardId}`) &&
          !document.querySelectorAll('.modal-content').length
      )
    ) {
      logClickOpenArticle({
        channel_id: channelId,
        story_id: storyId,
        item_id: cardId,
        time_stamp: new Date().toISOString(),
      });
    }
  }, [cardId, channelId, logClickOpenArticle, pathname, setCurrentTime, startTrackTime, storyId]);

  const onPauseHandling = useCallback(
    (cardId: number) => {
      logItemPlayed({
        channel_id: channelId,
        story_id: storyId,
        item_id: cardId,
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      });

      stopTrackTime();
    },
    [channelId, getDuration, logItemPlayed, stopTrackTime, storyId]
  );

  useBeforeUnload(() => onPauseHandling(cardId));

  const isNewTrack = useMemo(
    () => Boolean(cardIdPrev && cardId !== cardIdPrev),
    [cardId, cardIdPrev]
  );

  useEffect(() => {
    if (!audio) {
      return;
    }

    if (src && isPlaying) {
      audio.play();

      if (!isNewTrack) {
        onPlayHandling();
      }

      return;
    }

    audio.pause();
    onPauseHandling(cardId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, audio, src]);

  const onClose = () => {
    saveCurrentTime();
    onPauseHandling(cardId);
    setMusicPlayerData({ ...musicPlayerData, src: '' });
  };

  const onPlay = useCallback(() => {
    setMusicPlayerData({ ...musicPlayerData, isPlaying: true });
  }, [musicPlayerData, setMusicPlayerData]);

  const onPause = useCallback(() => {
    setMusicPlayerData({ ...musicPlayerData, isPlaying: false });
  }, [musicPlayerData, setMusicPlayerData]);

  useEffect(() => {
    if (!cardId || !isNewTrack) {
      return;
    }

    onPauseHandling(cardIdPrev);
    onPlayHandling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);

  const getIcon = useCallback(({ iconId, iconSize }: { iconId: string; iconSize?: number }) => {
    return (
      <IconLabel
        iconId={iconId}
        iconSize={iconSize}
        color={getCssVar('--media-audio-icon-color')}
        singleColor
      />
    );
  }, []);

  const titleClickHandler = useCallback(() => {
    isMobileOnly ? navigate(`/details/${storyId}/${cardId}`) : setOpenCardDetailsModal(true);
  }, [cardId, navigate, storyId]);

  return (
    <>
      <div
        className={classNames(classes['player-wrapper'], {
          [classes['player-wrapper--hidden']]: !src,
          [classes['player-wrapper--mobile']]: isMobileOnly,
        })}
      >
        <div className={classes['player']}>
          <div className={classes['player-title']} onClick={titleClickHandler}>
            {cardTitle}
          </div>

          <AudioPlayer
            {...(src && { src: `${src}?cardId=${cardId}` })}
            ref={ref}
            onPlay={onPlay}
            onPause={onPause}
            onListen={() => saveCurrentTime()}
            onSeeked={() => saveCurrentTime()}
            customIcons={{
              play: getIcon({ iconId: 'play', iconSize: 40 }),
              pause: getIcon({ iconId: 'pause', iconSize: 40 }),
              volume: getIcon({ iconId: 'volume' }),
              volumeMute: getIcon({ iconId: 'volume-mute' }),
              forward: getIcon({ iconId: 'forward-5' }),
              rewind: getIcon({ iconId: 'rewind-5' }),
              loop: getIcon({ iconId: 'loop', iconSize: 20 }),
              loopOff: getIcon({ iconId: 'loop-off', iconSize: 20 }),
            }}
            volume={isMobileOnly ? 1 : 0.5}
            showFilledVolume
          />
        </div>

        <CloseButton onClick={onClose} className={classes['player-close']} />
      </div>

      {openCardDetailsModal && (
        <DetailsModal
          isOpen={openCardDetailsModal}
          storyId={storyId}
          cardId={cardId}
          onClose={() => setOpenCardDetailsModal(false)}
        />
      )}
    </>
  );
};

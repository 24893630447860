// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryfy = (obj: any, enumObj?: any, key?: string) => {
  if (typeof obj === 'number') {
    return obj;
  }

  //single value from enum
  if (typeof obj === 'string' && enumObj?.hasOwnProperty(key) && enumObj[key ?? ''].includes(obj)) {
    return obj;
  }

  //multiple values from enum
  if (
    Array.isArray(obj) &&
    obj.every((objItem) => enumObj?.hasOwnProperty(key) && enumObj[key ?? ''].includes(objItem))
  ) {
    const props: string = obj.map((value) => `${queryfy(value, enumObj, key)}`).join(',');
    return `[${props}]`;
  }

  if (Array.isArray(obj)) {
    const props: string = obj.map((value) => `${queryfy(value)}`).join(',');
    return `[${props}]`;
  }

  if (obj !== null && typeof obj === 'object') {
    const props: string = Object.keys(obj)
      .map((key) => `${key}:${queryfy(obj[key], enumObj, key)}`)
      .join(',');
    return `{${props}}`;
  }

  return JSON.stringify(obj);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StoryCard } from '../services';
import { addCard, deleteCard, editCard, getStoryFeedHandler } from './common';

interface LatestsStoryCardsFeedState {
  storyFeed: StoryCard[];
  storyId: number;
  isFetching: boolean;
}

const initialState: LatestsStoryCardsFeedState = {
  storyFeed: [],
  storyId: 0,
  isFetching: false,
};

export const getLatestsStoryCardsFeed = createAsyncThunk(
  'latestsStoryCardsFeed',
  getStoryFeedHandler
);

const LatestsStoryCardsFeedSlice = createSlice({
  name: 'latestsStoryCardsFeedSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLatestsStoryCardsFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getLatestsStoryCardsFeed.fulfilled, (state, action) => {
        const { items, storyId } = action.payload;

        state.storyFeed = items;
        state.storyId = storyId;
        state.isFetching = false;
      })
      .addCase(addCard, (state, action) => {
        const { storyId } = action.payload;

        if (state.storyId !== storyId) {
          return;
        }

        state.storyFeed.unshift(action.payload);
      })
      .addCase(editCard, (state, action) => {
        const { id: storyCardId } = action.payload;

        const cardIndex = state.storyFeed.findIndex(({ id }) => id === storyCardId);

        if (cardIndex === -1) {
          return;
        }

        state.storyFeed[cardIndex] = action.payload;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.storyFeed = state.storyFeed.filter(({ id }) => id !== storyCardId);
      });
  },
});

export default LatestsStoryCardsFeedSlice.reducer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  StoryCard,
  storyCardApi,
  StoryCardsOrganisationTagFeedArgs,
  StoryCardsOrganisationTagFeedFilters,
} from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editComment,
  hideComment,
  highlightComment,
  pinCard,
  reactOnCard,
  reactOnComment,
  repostCard,
  toggleCardSection,
  togglePublishOnCard,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';

const getTagsFeedHandler = async (args: StoryCardsOrganisationTagFeedArgs, { dispatch }: any) => {
  return dispatch(
    storyCardApi.endpoints.storyCardsOrganisationTagFeed.initiate({ ...args })
  ).unwrap();
};

export const getTagsFeed = createAsyncThunk('tagsFeed', getTagsFeedHandler);

export const getTagsFeedLoadMore = createAsyncThunk('tagsFeed/loadMore', getTagsFeedHandler);

interface TagsFeedState {
  tagsFeed: StoryCard[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
  filters?: StoryCardsOrganisationTagFeedFilters;
}

const initialState: TagsFeedState = {
  tagsFeed: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
  filters: undefined,
};

const tagsFeedSlice = createSlice({
  name: 'tagsFeed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTagsFeed.pending, (state) => {
        state.tagsFeed = [];
        state.isFetching = true;
      })
      .addCase(getTagsFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.tagsFeed = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
        state.filters = action.meta.arg.filters;
      })
      .addCase(getTagsFeedLoadMore.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.tagsFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const card = state.tagsFeed.find(({ id }) => id === itemId);

        if (!card) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const card = state.tagsFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;

        const card = state.tagsFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;

        const card = state.tagsFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.tagsFeed = state.tagsFeed.filter(({ id }) => id !== storyCardId);
      })
      .addCase(repostCard.fulfilled, (state, action) => {
        const storyCard = action.payload;

        if (!storyCard) {
          return;
        }

        const cardIndex = state.tagsFeed.findIndex(({ id }) => id === storyCard.id);

        if (cardIndex === -1) {
          return;
        }

        state.tagsFeed[cardIndex] = {
          ...state.tagsFeed[cardIndex],
          dateToDisplay: storyCard.dateToDisplay,
        };
      })
      .addCase(pinCard.fulfilled, (state, action) => {
        const { payload, storyCardId } = action.payload;

        if (!payload) {
          return;
        }

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        const pinnedCard = state.tagsFeed.find(
          ({ isPinnedToTopOfNewsFeed }) => isPinnedToTopOfNewsFeed
        );

        if (pinnedCard && pinnedCard.id !== storyCardId) {
          pinnedCard.isPinnedToTopOfNewsFeed = false;
        }

        card.isPinnedToTopOfNewsFeed = !card.isPinnedToTopOfNewsFeed;
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, status, postingTime } = payload;

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        if (state.filters?.status?.length && !state.filters.status.includes(status)) {
          state.tagsFeed = state.tagsFeed.filter(({ id }) => id !== storyCardId);
          return;
        }

        card.status = status;
        card.postingTime = postingTime;
      })
      .addCase(toggleCardSection.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, section } = payload;

        const card = state.tagsFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        card.section = section;
      });
  },
});

export default tagsFeedSlice.reducer;

import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { OtherProps } from '../Other';
import { ChartWrapper, Loader, StatsItem, TimeSeriesChart } from '../../helpers';
import { AnalyticChartInterval, useUserLoginLogoutQuery } from '../../../../services';
import { differenceInDays, subDays } from 'date-fns';
import { COLORS } from '../../Analytics';

import classes from '../../Analytics.module.scss';

export const LoginLogout: FunctionComponent<OtherProps> = memo(({ from, to }) => {
  const { data: userLoginLogout, isFetching: isFetchingUserLoginLogout } = useUserLoginLogoutQuery({
    from,
    to,
    interval: AnalyticChartInterval.AUTO,
  });

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: userLoginLogoutPrev, isFetching: isFetchingUserLoginLogoutPrev } =
    useUserLoginLogoutQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
    });

  const [userLoginLogoutLabels, setUserLoginLogoutLabels] = useState<string[]>([]);

  useEffect(() => {
    if (userLoginLogout && !userLoginLogoutLabels.length) {
      setUserLoginLogoutLabels(userLoginLogout.labels);
    }
  }, [userLoginLogout, userLoginLogoutLabels.length]);

  const renderStats = useMemo(() => {
    if (isFetchingUserLoginLogout || isFetchingUserLoginLogoutPrev) {
      return <Loader stats />;
    }

    if (!userLoginLogout || !userLoginLogoutPrev) {
      return null;
    }

    const { datasets } = userLoginLogout;
    const { datasets: datasetsPrev } = userLoginLogoutPrev;

    return (
      <div className={classes['analytics__chart-stats']}>
        {datasets.map(({ label, data }, index) => {
          const isDisabled = !userLoginLogoutLabels.includes(label);
          const isClickable = userLoginLogoutLabels.length > 1 || isDisabled;

          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`userLoginLogoutLabels.${label}`}
              color={COLORS[index]}
              value={data.reduce((partialSum, value) => partialSum + value, 0)}
              valuePrev={datasetsPrev.find((set) => set.label === label)?.data[0] ?? 0}
              labels={userLoginLogoutLabels}
              setLabels={setUserLoginLogoutLabels}
              isInline
              isDisabled={isDisabled}
              isClickable={isClickable}
            />
          );
        })}
      </div>
    );
  }, [
    isFetchingUserLoginLogout,
    isFetchingUserLoginLogoutPrev,
    userLoginLogout,
    userLoginLogoutLabels,
    userLoginLogoutPrev,
  ]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-login-logout'}
      infoLocaliseKey={'userLoginLogout.info'}
    >
      {renderStats}
      {userLoginLogout && (
        <TimeSeriesChart
          timeSeries={userLoginLogout}
          labels={userLoginLogoutLabels}
          localiseKeyLabels={'userLoginLogoutLabels'}
          loading={isFetchingUserLoginLogout}
        />
      )}
    </ChartWrapper>
  );
});

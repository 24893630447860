import { Fragment, FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CommentAttachment } from '../../../../services';
import { CloseButton } from '../../CloseButton';
import { IconLabel } from '../../IconLabel';
import { ImageSingle } from '../../ImageGallery';
import { VideoPlayer } from '../../VideoPlayer';

import classNames from 'classnames';
import classes from './CommentAttachments.module.scss';

interface CommentAttachmentsProps {
  attachments: CommentAttachment[];
  hasPreview?: boolean;
  setAttachments?: (attachments: CommentAttachment[]) => void;
}

export const CommentAttachments: FunctionComponent<CommentAttachmentsProps> = memo(
  ({ attachments, hasPreview, setAttachments }) => {
    const { t } = useTranslation();

    const removeAttachment = useCallback(
      (index: number) => {
        setAttachments?.(attachments.filter((_, i) => i !== index));
      },
      [attachments, setAttachments]
    );

    const renderRemoveButton = useCallback(
      (index: number) => {
        if (!setAttachments) {
          return null;
        }

        return (
          <CloseButton
            className={classes['comment-attachments__remove']}
            onClick={() => removeAttachment(index)}
          />
        );
      },
      [removeAttachment, setAttachments]
    );

    const attachmentsPreview = useMemo(() => {
      return attachments.map(({ image, video }, index) => {
        if (image) {
          return (
            <Fragment key={image.id}>
              <div className={classes['comment-attachments__image-wrapper']}>
                <ImageSingle item={{ image }} modalPreviewOnClick={hasPreview} />
              </div>
              {renderRemoveButton(index)}
            </Fragment>
          );
        }

        if (video) {
          const { url } = video;

          if (!url) {
            return (
              <div key={video.id} className={classes['comment-attachments__video-placeholder']}>
                <IconLabel
                  iconId={'video'}
                  iconSize={32}
                  className={classes['comment-attachments__video-placeholder-icon']}
                  color={'#ffffff'}
                  nonClickable={!hasPreview}
                  onClick={() => hasPreview && toast.info(t('comments.attachment-loading'))}
                  singleColor
                />
                {renderRemoveButton(index)}
              </div>
            );
          }

          return (
            <Fragment key={video.id}>
              <VideoPlayer
                url={video.url}
                className={classNames(classes['comment-attachments__video'], {
                  [classes['comment-attachments__video--edit']]: setAttachments,
                })}
              />
              {renderRemoveButton(index)}
            </Fragment>
          );
        }

        return null;
      });
    }, [attachments, hasPreview, renderRemoveButton, setAttachments, t]);

    if (!attachments.length) {
      return null;
    }

    return <div className={classes['comment-attachments']}>{attachmentsPreview}</div>;
  }
);

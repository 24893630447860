import { differenceInDays, subDays } from 'date-fns';
import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { AnalyticChartInterval, useUserDemoActivityQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, StatsItem, TimeSeriesChart } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserDemoActivity: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { data: userDemoActivity, isFetching: isFetchingUserDemoActivity } =
    useUserDemoActivityQuery({
      from,
      to,
      interval: AnalyticChartInterval.AUTO,
      platform,
    });

  const { data: userDemoActivityStats, isFetching: isFetchingUserDemoActivityStats } =
    useUserDemoActivityQuery({
      from,
      to,
      platform,
    });

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: userDemoActivityStatsPrev, isFetching: isFetchingUserDemoActivityStatsPrev } =
    useUserDemoActivityQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
      platform,
    });

  const [userDemoActivityLabels, setUserDemoActivityLabels] = useState<string[]>([]);

  useEffect(() => {
    if (userDemoActivity && !userDemoActivityLabels.length) {
      setUserDemoActivityLabels(userDemoActivity.labels);
    }
  }, [userDemoActivity, userDemoActivityLabels.length]);

  const renderStats = useMemo(() => {
    if (isFetchingUserDemoActivityStats || isFetchingUserDemoActivityStatsPrev) {
      return <Loader stats />;
    }

    if (!userDemoActivityStats || !userDemoActivityStatsPrev) {
      return null;
    }

    const { datasets } = userDemoActivityStats;
    const { datasets: datasetsPrev } = userDemoActivityStatsPrev;

    return (
      <div className={classes['analytics__chart-stats']}>
        {datasets.map(({ label, data }, index) => {
          const isDisabled = !userDemoActivityLabels.includes(label);
          const isClickable = userDemoActivityLabels.length > 1 || isDisabled;

          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`userActivityLabels.${label}`}
              hintLocaliseKey={`userActivityHints.${label}`}
              color={COLORS[index]}
              value={data[0] ?? 0}
              valuePrev={datasetsPrev.find((set) => set.label === label)?.data[0] ?? 0}
              labels={userDemoActivityLabels}
              setLabels={setUserDemoActivityLabels}
              isInline
              isDisabled={isDisabled}
              isClickable={isClickable}
            />
          );
        })}
      </div>
    );
  }, [
    isFetchingUserDemoActivityStats,
    isFetchingUserDemoActivityStatsPrev,
    userDemoActivityLabels,
    userDemoActivityStats,
    userDemoActivityStatsPrev,
  ]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-demo-activity'}
      infoLocaliseKey={'userDemoActivity.info'}
    >
      {renderStats}
      {userDemoActivity && (
        <TimeSeriesChart
          timeSeries={userDemoActivity}
          labels={userDemoActivityLabels}
          localiseKeyLabels={'userActivityLabels'}
          loading={isFetchingUserDemoActivity}
        />
      )}
    </ChartWrapper>
  );
});

import { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Story, StoryType } from '../../services';
import { Button, ButtonType, CollapseSwitcher, DropdownMenu, Popup } from '../../shared';
import { CreateStoryModal } from './CreateStoryModal';
import { StoryListAdmin } from './StoryListAdmin';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './StoryTabListAdmin.module.scss';

export const StoryTabListAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const [createStoryOptionsOpen, setCreateStoryOptionsOpen] = useState<boolean>(false);

  const [createStoryModalOpen, setCreateStoryModalOpen] = useState<boolean>(false);

  const [newStoryType, setNewStoryType] = useState<StoryType>(StoryType.STANDARD);

  const createStoryContainerRef = useRef<HTMLDivElement>(null);

  const createStoryOptionClickHandler = useCallback((storyType: StoryType) => {
    setCreateStoryOptionsOpen(false);
    setCreateStoryModalOpen(true);
    setNewStoryType(storyType);
  }, []);

  const dropdownMenuContent = useMemo(
    () => (
      <>
        <span
          className={dropdownMenuClasses['dropdown-menu__item']}
          onClick={() => createStoryOptionClickHandler(StoryType.STANDARD)}
        >
          {t('common.standard')}
        </span>
        <span
          className={dropdownMenuClasses['dropdown-menu__item']}
          onClick={() => createStoryOptionClickHandler(StoryType.READONLY)}
        >
          {t('common.readonly')}
        </span>
      </>
    ),
    [createStoryOptionClickHandler, t]
  );

  return (
    <>
      <div className={classes['story-tab-list']}>
        <div className={classes['story-tab-list__header']}>
          <div className={classes['story-tab-list__title']}>{t('adminLayoutMenu.mixes')}</div>
          <div className={classes['story-tab-list__buttons']}>
            <CollapseSwitcher />

            {!isMobileOnly && (
              <div
                ref={createStoryContainerRef}
                className={classes['story-tab-list__create-story']}
              >
                <Button
                  type={ButtonType.primary}
                  label={t('common.create-story')}
                  onClick={() => setCreateStoryOptionsOpen(true)}
                />

                <Popup
                  isOpen={createStoryOptionsOpen}
                  setIsOpen={setCreateStoryOptionsOpen}
                  bodyTop={'0.5rem'}
                  bodyRight={'0'}
                  parentClassName={classes['story-tab-list__create-story-menu']}
                  body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
                />
              </div>
            )}
          </div>
        </div>
        <StoryListAdmin />
      </div>
      {createStoryModalOpen && (
        <CreateStoryModal
          isOpen={createStoryModalOpen}
          onClose={() => setCreateStoryModalOpen(false)}
          story={{ type: newStoryType } as Story}
        />
      )}
    </>
  );
};

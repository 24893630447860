import { differenceInYears, subYears } from 'date-fns';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context';
import {
  AnalyticChartTableTotalSeries,
  useInAppMessageTotalQuery,
  usePushNotificationTotalQuery,
} from '../../../services';
import { Button, ButtonType, Delta, IconLabel, IconLabelHintDirection } from '../../../shared';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './NotificationsRightSide.module.scss';

export const NotificationsRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const date = new Date();
  date.setSeconds(0, 0);

  const to = date.toISOString();
  const from = subYears(date, 1).toISOString();

  const { data: pushNotificationTotal, isFetching: isFetchingPushNotificationTotal } =
    usePushNotificationTotalQuery({ from, to, channelId: [channelId] });

  const { data: inAppMessageTotal, isFetching: isFetchingInAppMessageTotal } =
    useInAppMessageTotalQuery({ from, to, channelId: [channelId] });

  const yearsDiff = differenceInYears(new Date(to), new Date(from)) + 1;

  const { data: pushNotificationTotalPrev, isFetching: isFetchingPushNotificationTotalPrev } =
    usePushNotificationTotalQuery({
      from: subYears(new Date(from), yearsDiff).toISOString(),
      to: subYears(new Date(to), yearsDiff).toISOString(),
      channelId: [channelId],
    });

  const { data: inAppMessageTotalPrev, isFetching: isFetchingInAppMessageTotalPrev } =
    useInAppMessageTotalQuery({
      from: subYears(new Date(from), yearsDiff).toISOString(),
      to: subYears(new Date(to), yearsDiff).toISOString(),
      channelId: [channelId],
    });

  const renderStats = useCallback(
    ({
      notificationTotal,
      isFetchingNotificationTotal,
      notificationTotalPrev,
      isFetchingNotificationTotalPrev,
    }: {
      notificationTotal?: AnalyticChartTableTotalSeries;
      isFetchingNotificationTotal: boolean;
      notificationTotalPrev?: AnalyticChartTableTotalSeries;
      isFetchingNotificationTotalPrev: boolean;
    }) => {
      if (isFetchingNotificationTotal || isFetchingNotificationTotalPrev) {
        return <Skeleton height={'5rem'} />;
      }

      if (!notificationTotal || !notificationTotalPrev) {
        return null;
      }

      const { items } = notificationTotal;

      const { items: itemsPrev } = notificationTotalPrev;

      const stats = [
        {
          localiseKey: 'notificationListLabels.total',
          value: items.find(({ label }) => label === 'total')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'total')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.total-open',
          value: items.find(({ label }) => label === 'openTotal')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'openTotal')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.average-sent',
          value: items.find(({ label }) => label === 'sentAvg')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'sentAvg')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.average-open',
          value: items.find(({ label }) => label === 'openAvg')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'openAvg')?.value ?? 0,
        },
      ];

      return (
        <>
          {stats.map(({ localiseKey, value, valuePrev }) => {
            return (
              <div key={localiseKey} className={classes['notifications-right-side__stats-item']}>
                <span className={classes['notifications-right-side__stats-item-label']}>
                  {t(localiseKey)}
                </span>
                <span className={classes['notifications-right-side__stats-item-data']}>
                  <Delta value={value} valuePrev={valuePrev} />
                  <span className={classes['notifications-right-side__stats-item-value']}>
                    {value}
                  </span>
                </span>
              </div>
            );
          })}
        </>
      );
    },
    [t]
  );

  return (
    <div className={classes['notifications-right-side']}>
      <div className={classes['notifications-right-side__info']}>
        <IconLabel
          iconId={'info'}
          color={'#b3b3b3'}
          data={t('notificationsPage.analytics-info')}
          hintDirection={IconLabelHintDirection.left}
          singleColor
        />
      </div>

      <div className={classes['notifications-right-side__stats']}>
        <div className={classes['notifications-right-side__stats-title']}>
          {t('notificationsPage.push')}
        </div>

        {renderStats({
          notificationTotal: pushNotificationTotal,
          isFetchingNotificationTotal: isFetchingPushNotificationTotal,
          notificationTotalPrev: pushNotificationTotalPrev,
          isFetchingNotificationTotalPrev: isFetchingPushNotificationTotalPrev,
        })}
      </div>
      <div className={classes['notifications-right-side__stats']}>
        <div className={classes['notifications-right-side__stats-title']}>
          {t('notificationsPage.in-app')}
        </div>
        {renderStats({
          notificationTotal: inAppMessageTotal,
          isFetchingNotificationTotal: isFetchingInAppMessageTotal,
          notificationTotalPrev: inAppMessageTotalPrev,
          isFetchingNotificationTotalPrev: isFetchingInAppMessageTotalPrev,
        })}
      </div>
      <Button
        type={ButtonType.secondary}
        label={t('notificationsPage.open-analytics')}
        onClick={() => navigate('/admin/analytics')}
      />
    </div>
  );
};

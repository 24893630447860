import { FunctionComponent, memo } from 'react';

import classes from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  content: JSX.Element;
  width?: string;
}

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = memo(({ content, width }) => {
  return (
    <div className={classes['dropdown-menu']} style={{ width }}>
      {content}
    </div>
  );
});

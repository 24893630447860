import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StoryCard } from '../../../../services';
import { deleteCard } from '../../../../slices';
import {
  AbuseReportType,
  useAbuseReportPopup,
  useAppDispatch,
  useCardEdit,
  useCardTitle,
} from '../../../hooks';
import { getCssVar, layoutPath } from '../../../utils';
import { ConfirmationModal } from '../../ConfirmationModal';
import { DropdownMenu } from '../../DropdownMenu';
import { IconLabel } from '../../IconLabel';
import { Popup } from '../../Popup';

import classNames from 'classnames';
import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';
import classes from './CardActionsCommon.module.scss';

export interface CardActionsProps {
  card: StoryCard;
  isHorizontalFeedCard?: boolean;
  isProfileFeedCard?: boolean;
}

interface CardActionsCommonProps extends CardActionsProps {
  isListOpen: boolean;
  setIsListOpen: (isOpen: boolean) => void;
  adminActions?: JSX.Element;
}
export const CardActionsCommon: FunctionComponent<CardActionsCommonProps> = memo(
  ({ card, adminActions, isListOpen, setIsListOpen, isHorizontalFeedCard, isProfileFeedCard }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { cardEditModal, cardEditHandler } = useCardEdit({ storyCard: card, isProfileFeedCard });

    const cardTitle = useCardTitle(card);

    const {
      id: cardId,
      type: cardType,
      storyId,
      permissions,
      story: { title: storyTitle },
      user,
    } = card;

    const { id: userId, screenName: userName } = { ...user };

    const { allowToDelete, allowToUpdate } = permissions;

    const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
      type: AbuseReportType.Post,
      storyId,
      storyName: storyTitle,
      postId: cardId,
      postType: cardType,
      postTitle: cardTitle,
      authorId: userId,
      authorName: userName,
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const editHandler = useCallback(async () => {
      setIsListOpen(false);
      cardEditHandler();
    }, [cardEditHandler, setIsListOpen]);

    const deleteHandler = useCallback(() => {
      setIsListOpen(false);
      setIsDeleteOpen(true);
    }, [setIsListOpen]);

    const abuseReportClick = useCallback(() => {
      setIsListOpen(false);
      abuseReportHandler();
    }, [abuseReportHandler, setIsListOpen]);

    const cardDelete = useCallback(async () => {
      setLoading(true);
      try {
        const { deleted } = await dispatch(deleteCard({ storyId, storyCardId: cardId })).unwrap();

        toast(t(deleted ? 'cardActions.card-deleted' : 'deleteConfirmation.error-message'));

        if (!deleted) {
          return;
        }

        if (pathname === layoutPath(`/details/${storyId}/${cardId}`)) {
          navigate(layoutPath('/'));
        }
      } catch (_) {
        toast.error(t('deleteConfirmation.error-message'));
      } finally {
        setLoading(false);
      }
    }, [cardId, dispatch, navigate, pathname, storyId, t]);

    const dropdownMenuContent = useMemo(() => {
      return (
        <>
          {adminActions}

          {!isProfileFeedCard && (
            <IconLabel
              iconId={'report'}
              iconSize={18}
              label={t('abuseReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={abuseReportClick}
              singleColor
            />
          )}
          {allowToUpdate && (
            <IconLabel
              iconId={'edit'}
              iconSize={18}
              label={t('common.edit')}
              onClick={editHandler}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              singleColor
            />
          )}
          {allowToDelete && (
            <IconLabel
              iconId={'delete'}
              iconSize={18}
              label={t('common.delete')}
              color={getCssVar('--color-danger')}
              onClick={deleteHandler}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              singleColor
            />
          )}
        </>
      );
    }, [
      abuseReportClick,
      adminActions,
      allowToDelete,
      allowToUpdate,
      deleteHandler,
      editHandler,
      isProfileFeedCard,
      t,
    ]);

    if (!adminActions && !allowToDelete && !allowToUpdate) {
      return null;
    }

    return (
      <>
        <Popup
          isOpen={isListOpen}
          setIsOpen={setIsListOpen}
          iconId={'dots-menu'}
          color={getCssVar('--action-pane-icon-color')}
          hoverColor={getCssVar('--action-pane-icon-hover-color')}
          bodyTop={'1.5rem'}
          bodyRight={'0.0625rem'}
          body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
          parentClassName={classNames(classes['card-actions'], 'card-actions', {
            [classes['card-actions--horizontal']]: isHorizontalFeedCard,
          })}
        />

        {cardEditModal}

        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          acceptLabel={t('common.delete')}
          onAccept={cardDelete}
          title={t('deleteConfirmation.title')}
          subTitle={t('deleteConfirmation.sub-title')}
          keepOpened={loading}
          loading={loading}
          danger
        />

        {abuseReportModal}
      </>
    );
  }
);

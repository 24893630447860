import { FunctionComponent, PropsWithChildren, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../context';

import classNames from 'classnames';
import classes from './ErrorLayout.module.scss';

interface ErrorLayoutProps {
  subtitle: string;
  isNotFound?: boolean;
}

export const ErrorLayout: FunctionComponent<PropsWithChildren<ErrorLayoutProps>> = memo(
  ({ subtitle, isNotFound, children }) => {
    const { t } = useTranslation();

    const { config } = useContext(ConfigContext);

    // * ConfigContext might be undefined if unhandled error occured
    const logo = config?.elements.organisationLogoUrl;

    const pageContent = useMemo(() => {
      if (isNotFound) {
        return <div className={classes['error-layout__body-page-404']}>404</div>;
      }
      return (
        <div className={classes['error-layout__body-page-face']}>
          <span className={classes['error-layout__body-page-eye']}></span>
          <span className={classes['error-layout__body-page-eye']}></span>
          <div className={classes['error-layout__body-page-smile']}></div>
        </div>
      );
    }, [isNotFound]);

    return (
      <div className={classes['error-layout']}>
        {logo && <img src={logo} className={classes['error-layout__logo']} alt={'logo'} />}
        <div
          className={classNames(classes['error-layout__content'], {
            [classes['error-layout__content--no-logo']]: !logo,
          })}
        >
          <div className={classes['error-layout__header']}>
            <div className={classes['error-layout__header-title']}>{t('common.we-are-sorry')}</div>
            <div className={classes['error-layout__header-subtitle']}>{subtitle}</div>
          </div>
          <div className={classes['error-layout__body']}>
            <div className={classes['error-layout__body-page']}>
              <div className={classes['error-layout__body-page-header']}>
                <span className={classes['error-layout__body-page-circle']}></span>
                <span className={classes['error-layout__body-page-circle']}></span>
                <span className={classes['error-layout__body-page-circle']}></span>
              </div>
              {pageContent}
            </div>
            <div
              className={classNames(classes['error-layout__body-page-cover'], {
                [classes['error-layout__body-page-cover--not-found']]: isNotFound,
              })}
            ></div>
          </div>

          {children}
        </div>
      </div>
    );
  }
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserProfile } from '../../../../../components';
import { organisationApi, User } from '../../../../../services';
import { UserBlockData } from '../../../../models';
import { AutoComplete } from '../../../AutoComplete';
import { Avatar } from '../../../Avatar';
import { BaseBlockTool } from '../baseBlockTool';
import { ResetBlockTool } from '../helpers';

import classes from './User.module.scss';

interface State {
  user: User | null;
}

type Config = {
  dispatch: any;
  placeholder?: string;
};

export class UserTool extends BaseBlockTool<State, UserBlockData, Config> {
  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {
      title: 'User',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M19.33 20.7463V19.3301C19.33 18.5788 19.0316 17.8584 18.5004 17.3272C17.9692 16.796 17.2487 16.4976 16.4975 16.4976H10.8325C10.0813 16.4976 9.36082 16.796 8.82962 17.3272C8.29842 17.8584 8 18.5788 8 19.3301V20.7463" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.6645 13.665C15.2289 13.665 16.497 12.3968 16.497 10.8325C16.497 9.26815 15.2289 8 13.6645 8C12.1002 8 10.832 9.26815 10.832 10.8325C10.832 12.3968 12.1002 13.665 13.6645 13.665Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.user) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ user: null })} />;
  }

  getJSXTool(): JSX.Element {
    const { user } = this.state;

    if (user) {
      return <UserProfile user={user} />;
    }

    const { dispatch, placeholder } = this.config;

    const fetchQuery = (query: string, page?: number) => {
      return dispatch(
        organisationApi.endpoints.organisationUsers.initiate({
          page,
          filter: { query, queryLocation: '', filterPositions: [], filterDepartments: [] },
        })
      );
    };

    const itemTemplate = ({ screenName, avatar }: User) => {
      return (
        <>
          <Avatar url={avatar?.url} />
          <div className={classes['item-template-name']}>{screenName}</div>
        </>
      );
    };

    return (
      <AutoComplete
        propToIdentify={'id'}
        propToDisplay={'id'}
        fetchQuery={fetchQuery}
        itemTemplate={itemTemplate}
        value={[user].filter(Boolean)}
        onChange={(users: User[]) => this.setState({ user: users.slice(-1).pop() })}
        placeholder={placeholder}
      />
    );
  }

  getDefaultState(data?: UserBlockData): State {
    return { user: data?.user ?? null };
  }

  validate({ user }: UserBlockData): boolean {
    return Boolean(user);
  }

  save(): UserBlockData {
    return { user: this.state.user };
  }
}

import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo } from 'react';
import { getCssVar } from '../../utils';
import { IconLabel, IconLabelHintDirection } from '../IconLabel';

import classNames from 'classnames';

interface ScheduleIconProps {
  postingTime: Date | string | null;
  hintDirection?: IconLabelHintDirection;
  className?: string;
}

export const ScheduleIcon: FunctionComponent<ScheduleIconProps> = memo(
  ({ postingTime, hintDirection, className }) => {
    return (
      <IconLabel
        className={classNames(className)}
        iconId={`calendar-${postingTime ? 'added' : 'add'}`}
        color={getCssVar('--schedule-icon-color')}
        hoverColor={getCssVar('--schedule-icon-hover-color')}
        {...(postingTime && {
          data: format(parseISO(new Date(postingTime).toISOString()), 'dd.MM.yy, HH:mm'),
          hintDirection,
        })}
      />
    );
  }
);

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLayout } from '../ErrorLayout';

import classes from './NotSupported.module.scss';

export const NotSupported: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout subtitle={t('notSupportedPage.title')}>
      <div className={classes['not-supported']}>
        <div className={classes['not-supported__message']}>
          {t('notSupportedPage.message')}&nbsp;
          <span className={classes['not-supported__message--highlighted']}>
            {t('common.supported-browsers')}
          </span>
        </div>
      </div>
    </ErrorLayout>
  );
};

import { IS_PRODUCTION, PUBLIC_URL, TCHOP_ROOT_URL } from '../../env';
import { CUSTOM_CLIENT_CONFIG } from './customClientConfig';
import { getOrganisationDomain } from './getOrganisationDomain';

export const getWebAppUrl = (channelDomain: string): string => {
  if (!IS_PRODUCTION) {
    return '';
  }

  if (CUSTOM_CLIENT_CONFIG) {
    const { urlStructure } = CUSTOM_CLIENT_CONFIG;

    return `${window.location.protocol}//${urlStructure.replace('{channel}', channelDomain)}`;
  }

  const { protocol, host } = new URL(TCHOP_ROOT_URL);

  return `${protocol}//${channelDomain}-${getOrganisationDomain()}.${host}${PUBLIC_URL}`;
};

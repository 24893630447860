import { isAdminLayout } from './isAdminLayout';

export const layoutPath = (path: string) => {
  if (!isAdminLayout()) {
    return path;
  }

  const admin = '/admin';

  if (path === '/') {
    return admin;
  }

  return `${admin}${path}`;
};

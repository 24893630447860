import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import { NewsFeedFilter, storyCardApi, useGetStoriesByChannelIdQuery } from '../../../services';
import {
  CookieActionType,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  SEARCH_ADMIN_COOKIE_KEY,
  SelectItem,
  cookieOptions,
  optionalArrayItem,
  useAppDispatch,
  useAuthorsFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
} from '../../../shared';
import { getNewsFeed } from '../../../slices';

import classes from './SearchFeedAdminRightSide.module.scss';

const STORIES = 'stories';

export const SearchFeedAdminRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { data: stories, isFetching: isFetchingStories } = useGetStoriesByChannelIdQuery({
    channelId,
  });

  const storyOptions = useMemo(() => {
    return (
      stories?.map(({ id, title }) => {
        return { id, title } as SelectItem;
      }) ?? []
    );
  }, [stories]);

  const query = (searchParams.get('qs') ?? '').replace(/\\|"|'/g, '');

  const [fetchChannelAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByChannelId.useLazyQuery();

  const [cookies, setCookie] = useCookies([SEARCH_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[SEARCH_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue, getDateRangeValue } = useFilter();

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter(filter, fetchChannelAuthors, { channelId });

  const AUTHORS = useMemo(() => authorsGroup.id, [authorsGroup.id]);

  const { group: cardTypeGroup } = useCardTypeFilter();

  const TYPE = useMemo(() => cardTypeGroup.id, [cardTypeGroup.id]);

  const { group: cardStatusGroup } = useCardStatusFilter();

  const STATUS = useMemo(() => cardStatusGroup.id, [cardStatusGroup.id]);

  const groups: FilterGroup[] = [
    authorsGroup,
    ...optionalArrayItem(!isFetchingStories, {
      id: STORIES,
      title: t('storiesFilter.title'),
      value:
        getGroupValue({
          filter,
          groupId: STORIES,
          multiCheck: true,
          propToMap: 'id',
        }) ?? [],
      dropdown: {
        disabled: isFetchingStories,
        options: storyOptions,
        staticListPosition: false,
        search: true,
        multiCheck: true,
      },
      allowReset: true,
    }),
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const authorId = getGroupValue({
        filter,
        groupId: AUTHORS,
        multiCheck: true,
        propToMap: 'id',
      });

      const storyId = getGroupValue({
        filter,
        groupId: STORIES,
        multiCheck: true,
        propToMap: 'id',
      });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: TYPE, multiCheck: true });

      const status = getGroupValue({ filter, groupId: STATUS, multiCheck: true });

      const requestFilterToSave: NewsFeedFilter = {
        ...(authorId?.length && { authorId }),
        ...(storyId?.length && { storyId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const requestFilter: NewsFeedFilter = {
        ...requestFilterToSave,
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        SEARCH_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions({ action: CookieActionType.SET })
      );

      dispatch(getNewsFeed({ channelId, filter: { ...requestFilter, query } }));

      setFilter(filter);
    },
    [AUTHORS, STATUS, TYPE, channelId, dispatch, getDateRangeValue, getGroupValue, query, setCookie]
  );

  return (
    <div className={classes['search-feed-admin-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};

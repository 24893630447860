import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IS_PRODUCTION, TCHOP_ROOT_URL } from '../env';
import { getChannelDomain } from '../shared/utils/getChannelDomain';
import { getChannelNameFromCookie } from '../shared/utils/getChannelNameFromCookie';
import { getOrganisationDomain } from '../shared/utils/getOrganisationDomain';
import { prepareHeaders } from './helpers';

const getChannelUrl = (): string => {
  const organisation = getOrganisationDomain();

  if (!IS_PRODUCTION) {
    return 'http://127.0.0.1:3001/channel/api/';
  }

  const { host, protocol } = new URL(TCHOP_ROOT_URL);

  const channelCustomDomain = getChannelNameFromCookie();

  if (channelCustomDomain) {
    return `${protocol}//${channelCustomDomain}-${organisation}.${host}/api`;
  }

  const channel = getChannelDomain();

  if (!channel) {
    // it seems like the current domain doesn't have a channel reference
    return '/';
  }
  return `${protocol}//${channel}-${organisation}.${host}/api`;
};

export const channelApi = createApi({
  reducerPath: 'channelApi',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: getChannelUrl(),
    credentials: 'include',
    prepareHeaders,
  }),
  endpoints: () => ({}),
});

import { FunctionComponent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../../context';
import { IS_PRODUCTION } from '../../../env';
import { userApi } from '../../../services';
import {
  Button,
  ButtonType,
  CUSTOM_CLIENT_CONFIG,
  CookieActionType,
  IconLabel,
  InputField,
  MZ_ACCOUNT_COOKIE_KEY,
  cookieOptions,
  emailValidationError,
  getCssVar,
  getWebAppUrl,
} from '../../../shared';
import { Form } from '../Form';

import classNames from 'classnames';
import formClasses from '../Form/Form.module.scss';
import classes from './Signup.module.scss';

export const Signup: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [triggerLoginByEmail] = userApi.endpoints.userLoginByEmail.useLazyQuery();

  const [triggerRegister] = userApi.endpoints.userRegistrationByEmail.useLazyQuery();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies([MZ_ACCOUNT_COOKIE_KEY]);

  const { config } = useContext(ConfigContext);

  const { emailPlaceholder, supportEmail } = config.elements;

  const [email, setEmail] = useState<string>('');

  const [name, setName] = useState<string>('');

  const [password, setPassword] = useState<string>('');

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const [isNameValid, setIsNameValid] = useState<boolean>(true);

  const [signedUp, setSignedUp] = useState<boolean>(false);

  const [peeping, setPeeping] = useState<boolean>(false);

  const [credentialError, setCredentialError] = useState<boolean>(false);

  const [signUpError, setSignUpError] = useState<boolean>(false);

  const signUpButton = useRef<HTMLButtonElement>(null);

  const signupActive = useMemo(() => email.length && name.length, [email.length, name.length]);

  const loginActive = useMemo(() => password.length, [password.length]);

  const loginButton = useRef<HTMLButtonElement>(null);

  const passwordFieldType = useMemo(() => (peeping ? 'text' : 'password'), [peeping]);

  const emailChangeHandler = useCallback((value: string) => {
    setSignUpError(false);
    setIsEmailValid(true);
    setEmail(value);
  }, []);

  const nameChangeHandler = useCallback((value: string) => {
    setIsNameValid(true);
    setName(value);
  }, []);

  const nameValidationError = useCallback(() => {
    const screenName = name.trim();
    return screenName.length < 2 || screenName.length > 100;
  }, [name]);

  const passwordChangeHandler = useCallback((value: string) => {
    setCredentialError(false);
    setPassword(value);
  }, []);

  const signupClickHandler = useCallback(async () => {
    if (emailValidationError(email)) {
      setIsEmailValid(false);
      return;
    }

    if (nameValidationError()) {
      setIsNameValid(false);
      return;
    }

    signUpButton.current?.setAttribute('disabled', 'true');

    const { payload } = await triggerRegister({ email, screenName: name }).unwrap();

    payload ? setSignedUp(true) : setSignUpError(true);

    signUpButton.current?.removeAttribute('disabled');
  }, [email, name, nameValidationError, triggerRegister]);

  const loginClickHandler = useCallback(async () => {
    setCredentialError(false);

    loginButton.current?.setAttribute('disabled', 'true');

    try {
      const { payload } = await triggerLoginByEmail({ email, password }).unwrap();

      const { accessToken, channel } = payload;

      if (CUSTOM_CLIENT_CONFIG?.baseHost) {
        setCookie(
          MZ_ACCOUNT_COOKIE_KEY,
          accessToken,
          cookieOptions({
            action: CookieActionType.SET,
            domain: `.${CUSTOM_CLIENT_CONFIG.baseHost}`,
          })
        );
      }

      IS_PRODUCTION ? window.location.replace(getWebAppUrl(channel.subdomain)) : navigate('/');
    } catch (e) {
      setCredentialError(true);
    } finally {
      loginButton.current?.removeAttribute('disabled');
    }
  }, [email, navigate, password, setCookie, triggerLoginByEmail]);

  const content = useMemo(() => {
    if (signedUp) {
      return (
        <>
          <div className={formClasses['form__content-header']}>
            <span className={formClasses['form__content-header-title']}>
              {t('auth.sign-up-enter-password-title')}
            </span>
            <div className={formClasses['form__content-header-subtitle']}>
              <Trans i18nKey={'auth.sign-up-enter-password-intro'} tOptions={{ email }}>
                We sent the password to
                <span
                  className={classNames(formClasses['form__content-link'], classes['link'])}
                  onClick={() => setSignedUp(false)}
                >
                  {email}
                </span>
                . Please check spam folder in case you haven't received the email yet
              </Trans>
            </div>
          </div>

          <InputField
            label={t('common.password')}
            value={password}
            type={passwordFieldType}
            placeholder={t('common.password-placeholder')}
            icon={
              <IconLabel
                iconId={peeping ? 'eye-crossed' : 'eye'}
                iconSize={18}
                onClick={() => setPeeping(!peeping)}
                color={getCssVar('--input-icon-color')}
                singleColor
              />
            }
            onChange={({ target }) => passwordChangeHandler(target.value)}
          />

          {credentialError && (
            <div className={formClasses['form__content-error']}>{t('auth.login-fail')}</div>
          )}

          <Button
            ref={loginButton}
            type={ButtonType.primary}
            label={t('common.login')}
            disabled={!loginActive}
            onClick={loginClickHandler}
            fullWidth
          />

          <span className={classes['text']}>
            {t('checkEmail.not-received')}&nbsp;
            <a
              href={`mailto:${supportEmail ?? 'support@tchop.io'}`}
              className={classNames(formClasses['form__content-link'], classes['link'])}
            >
              {t('common.contact-us')}
            </a>
          </span>
        </>
      );
    }

    return (
      <>
        <div className={formClasses['form__content-header']}>
          <span className={formClasses['form__content-header-title']}>
            {t('auth.sign-up-title')}
          </span>
          <div className={formClasses['form__content-header-subtitle']}>
            {t('auth.sign-up-intro')}
          </div>
        </div>

        <InputField
          label={t('common.name')}
          value={name}
          placeholder={t('common.name-placeholder')}
          onChange={({ target }) => nameChangeHandler(target.value)}
          error={!isNameValid}
          {...(!isNameValid && { errorMessage: t('auth.name-invalid') })}
        />

        <InputField
          label={t('common.email')}
          value={email}
          placeholder={emailPlaceholder ?? t('common.email-placeholder')}
          onChange={({ target }) => emailChangeHandler(target.value)}
          error={!isEmailValid}
          {...(!isEmailValid && { errorMessage: t('auth.email-invalid') })}
        />

        {signUpError && (
          <div className={formClasses['form__content-error']}>{t('auth.sign-up-fail')}</div>
        )}

        <Button
          ref={signUpButton}
          type={ButtonType.primary}
          label={t('auth.sign-up')}
          disabled={!signupActive}
          onClick={signupClickHandler}
          fullWidth
        />

        <Link to={'/auth/login?loginEmail=true'} className={formClasses['form__content-back']}>
          {t('common.back-to-login')}
        </Link>
      </>
    );
  }, [
    credentialError,
    email,
    emailChangeHandler,
    emailPlaceholder,
    isEmailValid,
    isNameValid,
    loginActive,
    loginClickHandler,
    name,
    nameChangeHandler,
    password,
    passwordChangeHandler,
    passwordFieldType,
    peeping,
    signUpError,
    signedUp,
    signupActive,
    signupClickHandler,
    supportEmail,
    t,
  ]);

  return <Form>{content}</Form>;
};

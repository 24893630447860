export const getBestValues = ({
  values,
  intervals,
  formatter,
}: {
  values: number[];
  intervals: string[];
  formatter?: (value: string) => void;
}) => {
  const largest = Math.max(...values);

  if (!largest) {
    return;
  }

  return values
    .reduce(
      (labels: string[], value, index) =>
        value === largest ? [...labels, intervals[index]] : labels,
      []
    )
    .map((value) => (formatter ? formatter(value) : value))
    .join(', ');
};

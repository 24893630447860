/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, memo } from 'react';
import { Story, storyApi, StoryStatus } from '../../../../../../services';
import { AutoComplete } from '../../../../AutoComplete';

import classes from './StorySelect.module.scss';

interface StorySelectProps {
  dispatch: any;
  channelId: number;
  story: Story | null;
  setStory: (story: Story) => void;
  placeholder?: string;
}

export const StorySelect: FunctionComponent<StorySelectProps> = memo(
  ({ dispatch, channelId, story, setStory, placeholder }) => {
    const fetchQuery = () => {
      return dispatch(
        storyApi.endpoints.getStoriesByChannelId.initiate({
          channelId,
          filter: { onlyPostIn: false, status: [StoryStatus.PUBLISHED] },
        })
      );
    };

    const itemTemplate = ({ title, subtitle, image }: Story) => {
      return (
        <div className={classes['story']}>
          <div className={classes['story__fields']}>
            <div className={classes['story__fields-title']}>{title}</div>
            {subtitle && <div className={classes['story__fields-subTitle']}>{subtitle}</div>}
          </div>
          {image?.url && (
            <div
              className={classes['story__teaser']}
              style={{ backgroundImage: `url(${image.url})` }}
            ></div>
          )}
        </div>
      );
    };

    return (
      <AutoComplete
        propToIdentify={'id'}
        propToDisplay={'title'}
        fetchQuery={fetchQuery}
        itemTemplate={itemTemplate}
        value={[story].filter(Boolean)}
        onChange={(stories: Story[]) => setStory(stories.slice(-1).pop() as Story)}
        placeholder={placeholder}
        isStatic
      />
    );
  }
);

import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { User, organisationApi, storyCardApi } from '../../../../services';
import { useOnClickOutside, useRefreshFeeds } from '../../../hooks';
import { getCssVar } from '../../../utils';
import { AutoComplete } from '../../AutoComplete';
import { Avatar } from '../../Avatar';
import { Button, ButtonType } from '../../Button';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FeedCardChangeAuthor.module.scss';

interface FeedCardChangeAuthorProps {
  storyId: number;
  cardId: number;
  user: User;
  originalUserId: number;
  externalName?: string;
}

export const FeedCardChangeAuthor: FunctionComponent<FeedCardChangeAuthorProps> = memo(
  ({ storyId, cardId, user, originalUserId, externalName }) => {
    const { t } = useTranslation();

    const [cardUpdate] = storyCardApi.endpoints.storyCardUpdate.useLazyQuery();

    const [fetchOrgUsers] = organisationApi.endpoints.organisationUsers.useLazyQuery();

    const { refreshFeeds } = useRefreshFeeds({ scrollTop: false });

    const [value, setValue] = useState<User[]>([]);

    const [isOpen, setIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const { id, screenName } = user;

    useEffect(() => {
      if (isOpen && !externalName) {
        setValue([user]);
      }
    }, [externalName, isOpen, user]);

    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
      setIsOpen(false);
    });

    const saveDisabled = useMemo(
      () => Boolean(isLoading || !value.length || id === value[0]?.id),
      [id, isLoading, value]
    );

    const itemTemplate = useCallback(
      ({ id, screenName, avatar }: User) => {
        const isDefault = id === originalUserId;

        return (
          <>
            <Avatar url={avatar?.url} />

            <div
              className={classNames(classes['change-author__name'], {
                [classes['change-author__name--default']]: isDefault,
              })}
            >
              {screenName}
            </div>

            {isDefault && (
              <div className={classes['change-author__name-default']}>
                ({t('common.default').toLowerCase()})
              </div>
            )}
          </>
        );
      },
      [originalUserId, t]
    );

    const fetchQuery = useCallback(
      (query: string, page?: number) => {
        return fetchOrgUsers({
          page,
          filter: { query, queryLocation: '', filterPositions: [], filterDepartments: [] },
        });
      },
      [fetchOrgUsers]
    );

    const changeAuthor = useCallback(async () => {
      setIsLoading(true);
      setIsOpen(false);

      try {
        const { payload } = await toast.promise(
          cardUpdate({
            storyId,
            storyCardId: cardId,
            fields: { assignedUserId: value[0].id },
          }).unwrap(),
          {
            pending: t('changeAuthor.pending'),
            success: t('changeAuthor.success'),
            error: t('changeAuthor.error'),
          }
        );

        if (!payload) {
          return;
        }

        refreshFeeds();
      } catch (_) {
        toast.error(t('changeAuthor.error'));
      } finally {
        setIsLoading(false);
      }
    }, [cardId, cardUpdate, refreshFeeds, storyId, t, value]);

    return (
      <div ref={containerRef} className={classes['change-author']}>
        <IconLabel
          label={externalName ?? screenName}
          iconId={'edit'}
          iconSize={18}
          labelFirst
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={() => setIsOpen(!isOpen)}
          labelClassName={classes['change-author__label']}
          disabled={isLoading}
        />

        {isOpen && (
          <div className={classes['change-author__popup']}>
            <AutoComplete
              value={value}
              fetchQuery={fetchQuery}
              itemTemplate={itemTemplate}
              propToDisplay={'screenName'}
              propToIdentify={'id'}
              placeholder={t('common.type-to-search')}
              onChange={(value) => setValue(value.length ? [value.pop()] : [])}
            />
            <Button
              className={classes['change-author__popup-button']}
              type={ButtonType.primary}
              label={t('common.save')}
              disabled={saveDisabled}
              onClick={changeAuthor}
            />
          </div>
        )}
      </div>
    );
  }
);

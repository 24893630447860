import { createRef, FunctionComponent, memo, useCallback, useState } from 'react';
import { Badge } from '../Badge';

import classNames from 'classnames';
import classes from './BadgeList.module.scss';

export interface BadgeListItem {
  id: number;
  name: string;
}

interface BadgeListProops {
  items: BadgeListItem[];
  onCreate?: (name: string) => void;
  setBadgeToEdit?: ({ id, name }: BadgeListItem) => void;
  onRemove?: (id: number) => void;
  placholder?: string;
  maxLength?: number;
}

export const BadgeList: FunctionComponent<BadgeListProops> = memo(
  ({ items, placholder, maxLength, onCreate, setBadgeToEdit, onRemove }) => {
    const [input, setInput] = useState('');

    const inputRef = createRef<HTMLInputElement>();

    const createHanlder = useCallback(() => {
      if (!input.trim().length) {
        return;
      }

      onCreate?.(input);
      setInput('');
    }, [input, onCreate]);

    const onKeyDown = useCallback(
      ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter' && input) {
          createHanlder();
        }
      },
      [createHanlder, input]
    );

    return (
      <div
        className={classNames(classes['badge-list'], {
          [classes['badge-list--with-create']]: Boolean(onCreate),
        })}
      >
        {Boolean(items.length) && (
          <div className={classes['badge-list__items']}>
            {items.map(({ id, name }) => (
              <Badge
                key={id}
                value={name}
                onEdit={() => setBadgeToEdit?.({ id, name })}
                onRemove={() => onRemove?.(id)}
                disabled={!setBadgeToEdit && !onRemove}
                removeConfirmion
              />
            ))}
          </div>
        )}
        {Boolean(onCreate) && (
          <input
            ref={inputRef}
            placeholder={placholder}
            className={classes['badge-list__input']}
            value={input}
            onChange={({ target }) => setInput(target.value)}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            onBlur={createHanlder}
          />
        )}
      </div>
    );
  }
);

import { useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../context';
import { NewsFeedScope, StoryCardStatus } from '../../services';
import { getNewsFeed, getPinnedFeed, loadMoreNewsFeed, loadMorePinnedFeed } from '../../slices';
import { NEWS_FEED_ADMIN_COOKIE_KEY } from '../constants';
import { isAdminLayout } from '../utils';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useLastDate } from './useLastDate';

export const useNewsFeed = () => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const [cookies] = useCookies([NEWS_FEED_ADMIN_COOKIE_KEY]);

  const { page } = useAppSelector(({ newsFeed }) => newsFeed);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { requestFilter = { scope: NewsFeedScope.WEB_FEED } } = {
    ...cookies[NEWS_FEED_ADMIN_COOKIE_KEY],
  };

  const { dateRange, pinnedStorySection, ...requestFilterNoDateRange } = requestFilter; //TODO

  const { status } = requestFilterNoDateRange;

  const filter = useMemo(() => {
    if (!isAdminLayout()) {
      return { status: [StoryCardStatus.PUBLISHED] };
    }

    const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
    const from = date?.from ?? null;
    const to = date?.to ?? null;

    return {
      ...requestFilterNoDateRange,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [dateRange, getLastDate, isLastDate, requestFilterNoDateRange]);

  const getNewsFeedItems = useCallback(() => {
    dispatch(getNewsFeed({ channelId, filter }));
  }, [channelId, dispatch, filter]);

  const loadMoreNewsFeedItems = useCallback(() => {
    dispatch(loadMoreNewsFeed({ channelId, page: page + 1, filter }));
  }, [channelId, dispatch, filter, page]);

  const getPinnedFeedItems = useCallback(
    (pinnedStoryId: number) => {
      dispatch(getPinnedFeed({ storyId: pinnedStoryId, filter: { ...filter, status } }));
    },
    [dispatch, filter, status]
  );

  const loadMorePinnedFeedItems = useCallback(
    (pinnedStoryId: number) => {
      dispatch(
        loadMorePinnedFeed({
          storyId: pinnedStoryId,
          page: page + 1,
          filter: { ...filter, status },
        })
      );
    },
    [dispatch, filter, page, status]
  );

  return {
    getNewsFeedItems,
    loadMoreNewsFeedItems,
    getPinnedFeedItems,
    loadMorePinnedFeedItems,
    pinnedStorySection,
  };
};

import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfigContext } from '../../../context';
import { useForgotMutation } from '../../../services';
import {
  Button,
  ButtonType,
  CookieActionType,
  CookieExpirationType,
  FORGOT_PASSWORD_COOKIE_KEY,
  InputField,
  cookieOptions,
  emailValidationError,
  getOrganisationDomain,
} from '../../../shared';
import { Form } from '../Form';

import formClasses from '../Form/Form.module.scss';

export const Forgot: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([FORGOT_PASSWORD_COOKIE_KEY]);

  const location = useLocation();

  const type = new URLSearchParams(location.search).get('type') ?? '';

  const [isMagic, setIsMagic] = useState(['', 'mobile', 'magic', 'magicMobile'].includes(type));

  const isMobile = useMemo(() => ['mobile', 'setMobile', 'magicMobile'].includes(type), [type]);

  const isSetAndMagic = useMemo(() => ['', 'mobile'].includes(type), [type]);

  const [email, setEmail] = useState<string>('');

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const [forgot] = useForgotMutation();

  const resetButton = useRef<HTMLButtonElement | null>(null);

  const { config } = useContext(ConfigContext);

  const { emailPlaceholder } = config.elements;

  const changeType = useCallback(() => {
    setIsMagic(!isMagic);
  }, [isMagic]);

  const changeTypeLabel = useMemo(
    () => t(`forgotPassword.${isMagic ? 'set-own' : 'ask-magic'}`),
    [isMagic, t]
  );

  const subTitle = useMemo(
    () => t(`forgotPassword.${isMagic ? 'send-magic' : 'send-instructions'}`),
    [isMagic, t]
  );

  const resetButtonLabel = useMemo(
    () => t(`forgotPassword.${isMagic ? 'get-magic' : 'reset-password'}`),
    [isMagic, t]
  );

  const resetClickHandler = useCallback(async () => {
    if (emailValidationError(email)) {
      setIsEmailValid(false);
      return;
    }

    if (cookies[FORGOT_PASSWORD_COOKIE_KEY]?.requested) {
      toast.error(t('forgotPassword.request-delay-error'));
      return;
    }

    resetButton.current?.setAttribute('disabled', 'true');

    try {
      await forgot({
        email,
        organisation: getOrganisationDomain(),
        generateMagicLink: Boolean(isMagic),
      });

      setCookie(
        FORGOT_PASSWORD_COOKIE_KEY,
        { requested: true },
        cookieOptions({
          action: CookieActionType.SET,
          expiration: CookieExpirationType.TWO_MINUTES,
        })
      );

      navigate(
        `/auth/checkEmail?email=${email}&magic=${isMagic}&mobile=${isMobile}${
          type ? `&type=${type}` : ''
        }`
      );
    } catch (e) {
      toast.error(t('common.error-title'));
    } finally {
      resetButton.current?.removeAttribute('disabled');
    }
  }, [cookies, email, forgot, isMagic, isMobile, navigate, setCookie, t, type]);

  const emailChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsEmailValid(true);
    setEmail(target.value);
  }, []);

  return (
    <Form>
      <div className={formClasses['form__content-header']}>
        <span className={formClasses['form__content-header-title']}>
          {t('auth.forgot-password')}
        </span>
        <div className={formClasses['form__content-header-subtitle']}>{subTitle}</div>
      </div>

      <InputField
        label={t('common.email')}
        value={email}
        placeholder={emailPlaceholder ?? t('common.email-placeholder')}
        onChange={emailChangeHandler}
        error={!isEmailValid}
        {...(!isEmailValid && { errorMessage: t('auth.email-invalid') })}
      />

      <Button
        ref={resetButton}
        type={ButtonType.primary}
        label={resetButtonLabel}
        onClick={resetClickHandler}
        fullWidth
      />

      {isSetAndMagic && (
        <span className={formClasses['form__content-link']} onClick={changeType}>
          {changeTypeLabel}
        </span>
      )}

      {!isMobile && (
        <Link to={'/auth/login'} className={formClasses['form__content-back']}>
          {t('common.back-to-login')}
        </Link>
      )}
    </Form>
  );
};

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedCardWrapper } from '../HorizontalStoryFeedCardWrapper';
import { StoryCardArticleContent } from '../../../../services';

export const HorizontalStoryFeedArticle: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { type, content, isPinnedToTopOfNewsFeed } = card;
    const { title, sourceName, abstract } = content as StoryCardArticleContent;

    return (
      <HorizontalStoryFeedCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          cardType={type}
          source={sourceName}
          title={title}
          text={abstract}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedCardWrapper>
    );
  }
);

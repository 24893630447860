import { SVGProps } from 'react';
import { EmojiModes } from '../../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const LoveAOK = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx={15}
        cy={15}
        r={14.5}
        fill="#91F54A"
        stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7985 9.29469L15.0001 10.4278L16.2017 9.29469C18.0322 7.56844 21.008 7.56844 22.8385 9.29469L22.9605 9.40977C24.791 11.136 24.791 13.9423 22.9605 15.6685L15.0095 23.1667H15.0001H14.9907L7.03964 15.6774C5.20912 13.9511 5.20912 11.1449 7.03964 9.41862L7.16168 9.30354C8.9922 7.57729 11.968 7.57729 13.7985 9.30354V9.29469ZM19.5248 9.77273C18.7738 9.77273 18.0603 10.056 17.5347 10.5518L15.0001 12.9331L12.4749 10.5518L12.3341 10.4278C11.8178 10.0029 11.1607 9.78158 10.4754 9.79043C9.67748 9.81699 8.9171 10.1357 8.36325 10.6757C7.26494 11.7114 7.26494 13.3934 8.36325 14.4292L15.0095 20.6791L21.6369 14.4292L21.7683 14.2964C22.2189 13.8095 22.463 13.1898 22.4442 12.5436C22.4161 11.7911 22.0781 11.0741 21.5055 10.5518C20.9798 10.0472 20.2664 9.77273 19.5154 9.77273H19.5248Z"
        fill="#293033"
      />
    </g>
  </svg>
);

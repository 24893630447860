import { API } from '@editorjs/editorjs';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';

export class Superscript implements InlineTool {
  private readonly api: API;
  private readonly button: HTMLButtonElement;
  private static readonly commandName = 'superscript';
  public static title = 'Superscript';

  constructor(options: { api: API }) {
    this.api = options.api;
    this.button = this.createToolButton();
  }

  static get isInline() {
    return true;
  }

  public static get sanitize() {
    return { sup: {} };
  }

  private createToolButton(): HTMLButtonElement {
    const button = document.createElement('button');

    button.type = 'button';

    button.classList.add(this.api.styles.inlineToolButton);

    button.innerHTML = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_5699_15075" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_5699_15075)">
            <path d="M19.1925 9C18.9637 9 18.7718 8.9226 18.617 8.7678C18.4622 8.61298 18.3848 8.42115 18.3848 8.1923V7.42307C18.3848 7.19422 18.4622 7.0024 18.617 6.8476C18.7718 6.69278 18.9637 6.61537 19.1925 6.61537H21.0002V5.76922H18.7694C18.6669 5.76922 18.5771 5.73077 18.5002 5.65385C18.4233 5.57692 18.3848 5.48717 18.3848 5.3846C18.3848 5.28205 18.4233 5.19231 18.5002 5.11537C18.5771 5.03846 18.6669 5 18.7694 5H20.9617C21.1906 5 21.3824 5.0774 21.5372 5.2322C21.692 5.38702 21.7695 5.57884 21.7695 5.80768V6.57693C21.7695 6.80578 21.692 6.9976 21.5372 7.1524C21.3824 7.30722 21.1906 7.38463 20.9617 7.38463H19.154V8.23078H21.3848C21.4874 8.23078 21.5771 8.26923 21.654 8.34615C21.731 8.42307 21.7695 8.51281 21.7695 8.61537C21.7695 8.71794 21.731 8.80769 21.654 8.88463C21.5771 8.96154 21.4874 9 21.3848 9H19.1925ZM8.00853 19C7.78929 19 7.62893 18.9077 7.52745 18.7231C7.42595 18.5385 7.43417 18.3506 7.55212 18.1596L11.1925 12.5327L7.89635 7.45C7.7784 7.26282 7.77018 7.07692 7.87168 6.8923C7.97318 6.70768 8.12947 6.61537 8.34057 6.61537C8.43802 6.61537 8.5257 6.63771 8.6036 6.68237C8.68152 6.72702 8.74384 6.78995 8.79057 6.87115L11.9694 11.8077H11.9925L15.2233 6.85193C15.2691 6.77683 15.3322 6.71863 15.4125 6.67733C15.4928 6.63603 15.5779 6.61537 15.668 6.61537C15.8891 6.61537 16.0488 6.70768 16.1471 6.8923C16.2453 7.07692 16.2355 7.26474 16.1175 7.45578L12.7637 12.5327L16.4483 18.1596C16.5662 18.3506 16.573 18.5385 16.4685 18.7231C16.364 18.9077 16.2073 19 15.9983 19C15.9035 19 15.8167 18.9777 15.7378 18.933C15.6588 18.8884 15.5956 18.8254 15.5483 18.7442L11.9925 13.2866H11.9694L8.45212 18.7442C8.40626 18.8254 8.34319 18.8884 8.26293 18.933C8.18264 18.9777 8.09784 19 8.00853 19Z" fill="#1C1B1F"/>
        </g>
    </svg>`;

    return button;
  }

  public render(): HTMLElement {
    return this.button;
  }

  public surround(): void {
    document.execCommand(Superscript.commandName);
  }

  public checkState(): boolean {
    const isActive = document.queryCommandState(Superscript.commandName);

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}

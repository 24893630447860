import { FunctionComponent, memo, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext } from '../../../../context';
import { userApi } from '../../../../services';
import { Button, ButtonType, getWebAppUrl } from '../../../../shared';

interface FreeLoginProps {
  onSuccessLogin: ({ accessToken, webappUrl }: { accessToken: string; webappUrl: string }) => void;
  label?: string;
}

export const FreeLogin: FunctionComponent<FreeLoginProps> = memo(({ onSuccessLogin, label }) => {
  const { t } = useTranslation();

  const { freeLogin } = useContext(ConfigContext).config.elements;

  const [triggerLoginByEmail] = userApi.endpoints.userLoginByEmail.useLazyQuery();

  const freeLoginButton = useRef<HTMLButtonElement>(null);

  const freeLoginClickHandler = useCallback(async () => {
    const { login: email = '', password = '' } = { ...freeLogin };

    freeLoginButton.current?.setAttribute('disabled', 'true');

    try {
      const { payload } = await triggerLoginByEmail({ email, password }).unwrap();

      const { accessToken, channel } = payload;

      await onSuccessLogin({ accessToken, webappUrl: getWebAppUrl(channel.subdomain) });
    } catch (_) {
      toast.error(t('common.error-message'));
    } finally {
      freeLoginButton.current?.removeAttribute('disabled');
    }
  }, [freeLogin, onSuccessLogin, t, triggerLoginByEmail]);

  if (!freeLogin) {
    return null;
  }

  return (
    <Button
      ref={freeLoginButton}
      type={ButtonType.secondary}
      label={label ?? t('login.free')}
      onClick={freeLoginClickHandler}
      fullWidth
    />
  );
});

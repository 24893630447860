import { FunctionComponent, memo } from 'react';
import { FeedCardAdminThread } from './FeedCardAdminThread';
import { FeedCardAdminImage } from './FeedCardAdminImage';
import { FeedCardAdminEditorial } from './FeedCardAdminEditorial';
import { FeedCardAdminAudio } from './FeedCardAdminAudio';
import { FeedCardAdminPost } from './FeedCardAdminPost';
import { FeedCardAdminArticle } from './FeedCardAdminArticle';
import { FeedCardAdminPdf } from './FeedCardAdminPdf';
import { FeedCardAdminSocial } from './FeedCardAdminSocial';
import { FeedCardAdminVideo } from './FeedCardAdminVideo';
import { StoryCardType } from '../../services';
import { FeedCardProps } from '../../shared';

export const FeedCardAdmin: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  switch (card.type) {
    case StoryCardType.THREAD:
      return <FeedCardAdminThread card={card} />;
    case StoryCardType.IMAGE:
      return <FeedCardAdminImage card={card} />;
    case StoryCardType.EDITORIAL:
      return <FeedCardAdminEditorial card={card} />;
    case StoryCardType.AUDIO:
      return <FeedCardAdminAudio card={card} />;
    case StoryCardType.VIDEO:
      return <FeedCardAdminVideo card={card} />;
    case StoryCardType.PDF:
      return <FeedCardAdminPdf card={card} />;
    case StoryCardType.QUOTE:
      return <FeedCardAdminSocial card={card} />;
    case StoryCardType.ARTICLE:
      return <FeedCardAdminArticle card={card} />;
    case StoryCardType.POST:
      return <FeedCardAdminPost card={card} />;
    default:
      return null;
  }
});

import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { organisationApi, OrganisationTag, OrganisationTagType } from '../../../services';
import { AutoComplete } from '../AutoComplete';

import classes from './TagsPicker.module.scss';

interface TagsPickerProps {
  tags: OrganisationTag[];
  setTags: (tags: OrganisationTag[]) => void;
  type: OrganisationTagType;
  title?: string;
  hideTitle?: boolean;
  subTitle?: string;
  hideSubTitle?: boolean;
  disabled?: boolean;
}

export const TagsPicker: FunctionComponent<TagsPickerProps> = memo(
  ({ tags, setTags, type, title, hideTitle, subTitle, hideSubTitle, disabled }) => {
    const { t } = useTranslation();

    const [organisationTags] = organisationApi.endpoints.organisationTags.useLazyQuery();

    const fetchQuery = useCallback(
      (query: string, page?: number) => {
        return organisationTags({ page, filters: { query, types: [type] } });
      },
      [organisationTags, type]
    );

    const itemTemplate = useCallback(({ name }: OrganisationTag) => {
      return <div className={classes['tags-picker__tag-name']}>{name}</div>;
    }, []);

    const description = useMemo(() => {
      return (
        <>
          {!hideTitle && (
            <div className={classes['tags-picker__title']}>
              {title ?? t(`organisationTags.title-${type}`)}
            </div>
          )}
          {!hideSubTitle && (
            <div className={classes['tags-picker__subTitle']}>
              {subTitle ?? t(`organisationTags.sub-title-${type}`)}
            </div>
          )}
        </>
      );
    }, [hideSubTitle, hideTitle, subTitle, t, title, type]);

    return (
      <div className={classes['tags-picker']}>
        {description}
        <AutoComplete
          propToIdentify={'id'}
          propToDisplay={'name'}
          value={tags}
          fetchQuery={fetchQuery}
          itemTemplate={itemTemplate}
          placeholder={t('common.type-to-search')}
          onChange={(value) => setTags(value)}
          disabled={disabled}
        />
      </div>
    );
  }
);

import { SVGProps } from 'react';
import { EmojiModes } from '../../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const LikeAOK = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={13}
      cy={13}
      r={12.5}
      fill="#FFF133"
      stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
    />
    <path
      d="M16.4286 20.0886H8.71436V10.2057H10.6515C10.9344 9.30571 11.5086 7.63428 11.7315 6.71714C11.8562 6.20209 12.1689 5.75221 12.6081 5.4557C13.0473 5.15918 13.5814 5.03742 14.1058 5.11428C14.6153 5.18139 15.0842 5.42797 15.4283 5.80973C15.7723 6.19148 15.969 6.68341 15.9829 7.19714V10.2057H18.3658C18.719 10.1775 19.0738 10.2355 19.3996 10.3749C19.7254 10.5143 20.0125 10.7308 20.236 11.0058C20.4595 11.2808 20.6128 11.606 20.6827 11.9534C20.7526 12.3008 20.737 12.66 20.6372 13L20.4829 13.5229C20.1658 14.5686 19.4801 16.8743 19.0601 18.1943C18.9056 18.7641 18.5597 19.2635 18.0805 19.6084C17.6014 19.9534 17.0181 20.1229 16.4286 20.0886ZM10.4286 18.3743H16.4286C16.6506 18.3987 16.8738 18.3406 17.0556 18.2109C17.2374 18.0813 17.3651 17.8892 17.4144 17.6714C17.8344 16.36 18.5201 14.0714 18.8286 13.0343L18.9915 12.4943C19.0053 12.3533 18.9722 12.2116 18.8972 12.0914C18.7349 11.969 18.5344 11.9082 18.3315 11.92C17.1572 11.92 15.1258 11.92 15.1258 11.92H14.2686V7.19714C14.2686 6.97428 14.0286 6.83714 13.8572 6.81142C13.6858 6.78571 13.4972 6.81142 13.3772 7.20571C13.0001 8.47428 12.1429 11.3286 12.1429 11.3286L11.9544 11.92H10.4286V18.3743Z"
      fill="#293033"
    />
    <path d="M6.99993 10.1971H5.28564V20.0543H6.99993V10.1971Z" fill="#293033" />
  </svg>
);

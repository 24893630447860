import { differenceInDays, format, parseISO, subDays } from 'date-fns';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  InAppActiveMessage,
  NotificationListOrderBy,
  useInAppMessageListQuery,
  useInAppMessageTotalQuery,
} from '../../../../services';
import { OrderDirection } from '../../../../shared';
import {
  ChartWrapper,
  DataRowItem,
  Loader,
  TableChart,
  TableChartHeader,
  TableStats,
} from '../../helpers';
import { ContentProps } from '../Content';

export const InAppNotificationList: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform }) => {
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState<NotificationListOrderBy>(
      NotificationListOrderBy.DELIVERY_TIME
    );

    const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC);

    const [page, setPage] = useState<number>(1);

    const { data: inAppMessageList, isFetching: isFetchingInAppMessageList } =
      useInAppMessageListQuery({
        from,
        to,
        channelId,
        platform,
        orderBy,
        orderDirection,
        page,
      });

    const { data: inAppMessageTotal, isFetching: isFetchingInAppMessageTotal } =
      useInAppMessageTotalQuery({
        from,
        to,
        channelId,
        platform,
      });

    const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

    const { data: inAppMessageTotalPrev, isFetching: isFetchingInAppMessageTotalPrev } =
      useInAppMessageTotalQuery({
        from: subDays(new Date(from), daysDiff).toISOString(),
        to: subDays(new Date(to), daysDiff).toISOString(),
        channelId,
        platform,
      });

    const renderStats = useMemo(() => {
      if (!inAppMessageTotal || !inAppMessageTotalPrev) {
        return null;
      }

      const { items } = inAppMessageTotal;

      const { items: itemsPrev } = inAppMessageTotalPrev;

      const stats = [
        {
          localiseKey: 'notificationListLabels.total',
          hintLocaliseKey: 'inAppMessageListHints.total',
          value: items.find(({ label }) => label === 'total')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'total')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.total-open',
          hintLocaliseKey: 'inAppMessageListHints.total-open',
          value: items.find(({ label }) => label === 'openTotal')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'openTotal')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.average-sent',
          hintLocaliseKey: 'inAppMessageListHints.average-sent',
          value: items.find(({ label }) => label === 'sentAvg')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'sentAvg')?.value ?? 0,
        },
        {
          localiseKey: 'notificationListLabels.average-open',
          hintLocaliseKey: 'inAppMessageListHints.average-open',
          value: items.find(({ label }) => label === 'openAvg')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'openAvg')?.value ?? 0,
        },
      ];

      return (
        <TableStats
          stats={stats}
          loading={Boolean(isFetchingInAppMessageTotal || isFetchingInAppMessageTotalPrev)}
        />
      );
    }, [
      inAppMessageTotal,
      inAppMessageTotalPrev,
      isFetchingInAppMessageTotal,
      isFetchingInAppMessageTotalPrev,
    ]);

    const getInAppLink = useCallback(
      ({ inAppMessage }: { inAppMessage: InAppActiveMessage }): DataRowItem => {
        const { primary } = inAppMessage;

        const { action } = primary;

        const urlString = action.split('?url=')[1];

        const link = urlString ? decodeURIComponent(urlString) : '';

        return { value: link, url: link };
      },
      []
    );

    const renderChart = useMemo(() => {
      if (!inAppMessageList) {
        return <Loader />;
      }

      const { items, pageInfo } = inAppMessageList;

      const data: DataRowItem[][] = items.map(({ label, payload }) => {
        const { sent, open, channel } = payload;

        const { name: channelName = '' } = { ...channel };

        const { inAppMessage } = payload;

        const link = getInAppLink({ inAppMessage });

        return [
          { value: label },
          { value: sent },
          { value: open },
          { value: format(parseISO(inAppMessage.delivery), 'dd.MM.yy, HH:mm') },
          { ...link },
          { value: channelName },
        ];
      });

      const headers: TableChartHeader[] = [
        { label: t('notificationListLabels.title') },
        { label: t('notificationListLabels.sent'), orderBy: NotificationListOrderBy.SENT },
        { label: t('notificationListLabels.open'), orderBy: NotificationListOrderBy.OPEN },
        {
          label: t('notificationListLabels.delivery-time'),
          orderBy: NotificationListOrderBy.DELIVERY_TIME,
        },
        { label: t('notificationListLabels.link') },
        { label: t('notificationListLabels.channel') },
      ];

      return (
        <TableChart
          sticky
          headers={headers}
          data={data}
          pageInfo={pageInfo}
          setPage={setPage}
          orderBy={orderBy}
          setOrderBy={setOrderBy as Dispatch<SetStateAction<string>>}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          loading={isFetchingInAppMessageList}
        />
      );
    }, [getInAppLink, inAppMessageList, isFetchingInAppMessageList, orderBy, orderDirection, t]);

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.in-app-message-list'}
        infoLocaliseKey={'inAppMessageList.info'}
      >
        {renderStats}
        {renderChart}
      </ChartWrapper>
    );
  }
);

import {
  ElementType,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DetailsBody } from '../../../../components';
import { UserContext } from '../../../../context';
import { StoryCard } from '../../../../services';
import { useCardDate } from '../../../hooks';
import { layoutPath } from '../../../utils';
import { ActionPane, CardActionsProps } from '../../ActionPane';
import { AuthorInfo } from '../../AuthorInfo';
import { Comments } from '../../Comments';
import { FeedCardTags } from '../../FeedCard';
import { ReactionsCounter } from '../../ReactionsCounter';

import classNames from 'classnames';
import classes from './DetailsContentCommon.module.scss';

export interface DetailsContentProps {
  card: StoryCard;
  scrollToTags?: boolean;
  scrollToComments?: boolean;
  activeCommentsInput?: boolean;
  commentsWrapper?: HTMLElement;
}

interface DetailsContentCommonProps extends DetailsContentProps {
  CardActionsComponent: ElementType<CardActionsProps>;
}

export const DetailsContentCommon: FunctionComponent<DetailsContentCommonProps> = ({
  card,
  scrollToTags,
  scrollToComments,
  activeCommentsInput,
  commentsWrapper,
  CardActionsComponent,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userProfile } = useContext(UserContext);

  const {
    id: cardId,
    story,
    storyId,
    options,
    comments,
    commentsRootCount,
    commentsCount,
    reactions,
    author,
    dateToDisplay,
    myReaction,
    externalTags,
  } = card;

  const date = useCardDate(dateToDisplay);

  const { allowDisplayAuthor, allowCardReaction, allowComment } = options;

  const { id: authorId, avatar, screenName: authorName } = { ...author };

  const { title: storyTitle } = story;

  const isAuthor = useMemo(() => Boolean(authorId === userProfile.id), [authorId, userProfile.id]);

  const showPostedTime = useMemo(() => date && !allowDisplayAuthor, [allowDisplayAuthor, date]);

  const commentBoxRef = useRef<HTMLTextAreaElement>(null);

  const reactionsCounterRef = useRef<HTMLDivElement>(null);

  const reactionCommentPaneCounterRef = useRef<HTMLDivElement>(null);

  const tagsRef = useRef<HTMLDivElement>(null);

  const headerTitle = useMemo(() => authorName, [authorName]);

  const headerSubTitle = useMemo(
    () => `${t('feed.posted-in')} "${storyTitle}"${t('common.separator')} ${date}`,
    [date, storyTitle, t]
  );

  const cardHeaderClickHandler = useCallback(() => {
    navigate(`${layoutPath('/profile')}${!isAuthor ? `/${authorId}` : ''}`);
    window.scrollTo(0, 0);
  }, [authorId, isAuthor, navigate]);

  const scrollToCommentsAction = (behavior: ScrollBehavior = 'smooth') => {
    if (reactionsCounterRef.current) {
      reactionsCounterRef.current.scrollIntoView({ behavior, block: 'start' });
      return;
    }
    reactionCommentPaneCounterRef.current?.scrollIntoView({ behavior, block: 'start' });
  };

  useEffect(() => {
    if (scrollToComments) {
      setTimeout(() => {
        scrollToCommentsAction('auto');
      }, 0);
    }
  }, [scrollToComments]);

  useEffect(() => {
    if (scrollToTags) {
      setTimeout(() => {
        tagsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [scrollToTags]);

  useEffect(() => {
    if (activeCommentsInput && commentBoxRef?.current) {
      commentBoxRef.current?.focus();
    }
  }, [activeCommentsInput]);

  const commentsCounterClick = useCallback(() => {
    commentBoxRef.current?.focus();
    scrollToCommentsAction();
  }, []);

  const totalReactionsCount = useMemo(
    () =>
      Object.values(reactions)
        .filter(({ count }) => Boolean(count))
        .reduce((res, { count }) => res + count, 0) as number,
    [reactions]
  );

  const onlyMyReaction = useMemo(
    () => Boolean(myReaction && totalReactionsCount === 1),
    [myReaction, totalReactionsCount]
  );

  return (
    <div className={classNames(`card-${cardId}`, classes['details-content'])}>
      {allowDisplayAuthor && (
        <div className={classes['details-content__author-info']}>
          <AuthorInfo
            avatarUrl={avatar?.url}
            title={headerTitle}
            subTitle={headerSubTitle}
            onClick={cardHeaderClickHandler}
          />
        </div>
      )}

      <DetailsBody card={card} />

      {Boolean(externalTags.length) && (
        <div ref={tagsRef} className={classes['details-content__tags-wrapper']}>
          <FeedCardTags className={classes['details-content__tags']} tags={externalTags} />
        </div>
      )}

      {showPostedTime && <div className={classes['details-content__posted-time']}>{date}</div>}

      <div className={classes['details-content__reaction-comment-wrapper']}>
        {allowCardReaction && totalReactionsCount > 0 && !onlyMyReaction && (
          <div ref={reactionsCounterRef} className={classes['details-content__reactions-counter']}>
            <ReactionsCounter
              totalCount={totalReactionsCount}
              reactions={reactions}
              myReaction={myReaction}
            />
          </div>
        )}
        <div
          ref={reactionCommentPaneCounterRef}
          className={classNames(classes['details-content__action-pane'], {
            [classes['details-content__action-pane--mobile']]: isMobileOnly,
          })}
        >
          <ActionPane
            card={card}
            myReaction={myReaction}
            commentsCount={commentsCount}
            commentsCounterClick={commentsCounterClick}
          />

          <CardActionsComponent card={card} />
        </div>

        {allowComment && (
          <Comments
            ref={commentBoxRef}
            storyId={storyId}
            cardId={cardId}
            comments={comments}
            commentsRootCount={commentsRootCount}
            wrapper={commentsWrapper}
          />
        )}
      </div>
    </div>
  );
};

import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconLabel } from '../../../IconLabel';

interface ResetBlockToolProps {
  onClick: () => void;
}

export const ResetBlockTool: FunctionComponent<ResetBlockToolProps> = memo(({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={'ce-popover__items'}>
      <div className={'ce-popover-item'} onClick={onClick}>
        <div className={'ce-popover-item__icon ce-popover-item__icon--tool'}>
          <IconLabel iconId={'reset'} />
        </div>
        <div className={'ce-popover-item__title'}>{t('common.reset')}</div>
      </div>
    </div>
  );
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StoriesByChannelIdArgs, StoriesByChannelIdFilter, Story, storyApi } from '../services';
import { togglePublishOnStory, updateStory } from './common';

interface StoryListState {
  stories: Story[];
  isFetching: boolean;
  filter?: StoriesByChannelIdFilter;
}

const initialState: StoryListState = {
  stories: [],
  isFetching: false,
  filter: undefined,
};

const getStoriesHandler = async (args: StoriesByChannelIdArgs, { dispatch }: any) => {
  return dispatch(storyApi.endpoints.getStoriesByChannelId.initiate({ ...args })).unwrap();
};

export const getStories = createAsyncThunk('getStories', getStoriesHandler);

const StoriesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    updateStoriesPositionOnDrop: (
      state,
      action: PayloadAction<{ draggingStoryId: number; targetStoryId: number }>
    ) => {
      const { draggingStoryId, targetStoryId } = action.payload;

      const draggingStory = state.stories.find(({ id }) => id === draggingStoryId);
      const targetStory = state.stories.find(({ id }) => id === targetStoryId);

      if (!draggingStory || !targetStory) {
        return;
      }

      const { position: draggingPosition } = draggingStory;
      const { position: targetPosition } = targetStory;

      if (draggingPosition < targetPosition) {
        state.stories
          .filter(({ position }) => position > draggingPosition && position < targetPosition)
          .forEach((story) => (story.position -= 1));

        draggingStory.position = targetPosition;

        targetStory.position -= 1;
      } else {
        state.stories
          .filter(({ position }) => position < draggingPosition && position > targetPosition)
          .forEach((story) => (story.position += 1));

        draggingStory.position = targetPosition;

        targetStory.position += 1;
      }

      state.stories.sort(({ position: posA }, { position: posB }) => posA - posB);
    },
    deleteStory: (state, action: PayloadAction<{ storyId: number }>) => {
      const { storyId } = action.payload;
      state.stories = state.stories.filter(({ id }) => id !== storyId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getStories.fulfilled, (state, action) => {
        state.stories = action.payload;
        state.isFetching = false;
        state.filter = action.meta.arg.filter;
      })
      .addCase(updateStory.fulfilled, (state, action) => {
        const { id, ...storyProps } = action.payload;

        const storyToUpdateIndex = state.stories.findIndex((story) => story.id === id);

        if (storyToUpdateIndex === -1) {
          return;
        }

        state.stories[storyToUpdateIndex] = {
          ...state.stories[storyToUpdateIndex],
          ...storyProps,
        };
      })
      .addCase(togglePublishOnStory.fulfilled, (state, action) => {
        const { id: storyId, status } = action.payload;

        const story = state.stories.find(({ id }) => id === storyId);

        if (!story) {
          return;
        }

        if (state.filter?.status?.length && !state.filter.status.includes(status)) {
          state.stories = state.stories.filter(({ id }) => id !== storyId);
          return;
        }

        story.status = status;
      });
  },
});

export const { updateStoriesPositionOnDrop, deleteStory } = StoriesSlice.actions;
export default StoriesSlice.reducer;

import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../context';
import {
  IconLabel,
  USER_DIRECTORY_READER_COOKIE_KEY,
  useAppDispatch,
  useAppSelector,
} from '../../shared';
import { getOrganisationUsers, loadMoreOrganisationUsers } from '../../slices';
import { UserDirectoryItem } from './UserDirectoryItem';

import InfiniteScroll from 'react-infinite-scroll-component';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './UserDirectory.module.scss';

export const UserDirectory: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { config } = useContext(ConfigContext);

  const { appTabsSecondaryOrders } = config.elements;

  if (!appTabsSecondaryOrders.includes('userDirectory')) {
    navigate('/');
  }

  const [cookies] = useCookies([USER_DIRECTORY_READER_COOKIE_KEY]);

  const {
    requestFilter = {
      filterDepartments: [],
      filterPositions: [],
      query: '',
      queryLocation: '',
    },
  } = {
    ...cookies[USER_DIRECTORY_READER_COOKIE_KEY],
  };

  const { users, page, hasNextPage, isFetching } = useAppSelector(
    ({ organisationUsers }) => organisationUsers
  );

  useEffect(() => {
    dispatch(getOrganisationUsers({ filter: requestFilter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loadMore = useCallback(() => {
    dispatch(loadMoreOrganisationUsers({ page: page + 1, filter: requestFilter }));
  }, [dispatch, page, requestFilter]);

  const loader = useMemo(() => {
    return (
      <div className={classes['user-directory__loader']}>
        <Skeleton height={48} width={'100%'} />
        <Skeleton height={48} width={'100%'} />
        <Skeleton height={48} width={'100%'} />
      </div>
    );
  }, []);

  const hasUsers = useMemo(() => Boolean(users.length), [users.length]);

  const content = useMemo(() => {
    if (isFetching) {
      return loader;
    }

    if (!hasUsers) {
      return (
        <div className={classes['user-directory__empty']}>
          <IconLabel iconId={'search-off'} iconSize={48} nonClickable />
          <div className={classes['user-directory__empty-message']}>
            {t('userDirectory.empty-message')}
          </div>
        </div>
      );
    }
    return (
      <InfiniteScroll
        next={loadMore}
        hasMore={hasNextPage}
        loader={loader}
        dataLength={users.length}
        className={classes['user-directory__feed']}
      >
        {users.map((user) => (
          <UserDirectoryItem key={user.id} user={user} />
        ))}
      </InfiniteScroll>
    );
  }, [hasNextPage, hasUsers, isFetching, loadMore, loader, t, users]);

  return (
    <div className={classes['user-directory']}>
      <div className={classes['user-directory__title']}>{t('secondaryTabs.user-directory')}</div>
      {content}
    </div>
  );
};

import { StoryCard } from '../../services';

interface DeleteCommentProps {
  card: StoryCard;
  commentId: number;
  parentId?: number;
}

export const deleteCommentReducer = ({ card, commentId, parentId }: DeleteCommentProps) => {
  const { comments } = card;

  if (parentId) {
    const parentComment = comments.find(({ id }) => id === parentId);

    if (!parentComment) {
      return;
    }

    const { replies } = parentComment;

    replies.splice(
      replies.findIndex(({ id }) => id === commentId),
      1
    );

    parentComment.repliesCount--;
    card.commentsCount--;
  } else {
    const comment = comments.find(({ id }) => id === commentId);

    if (!comment) {
      return;
    }

    comments.splice(
      comments.findIndex(({ id }) => id === commentId),
      1
    );

    card.commentsRootCount--;
    card.commentsCount -= comment.repliesCount + 1;
  }
};

export const htmlToMarkdown = (html: string) => {
  return html
    .replace(/<b> {1,}/g, ' **')
    .replace(/ {1,}<\/b>/g, '** ')
    .replace(/<\/?b>/g, '**')

    .replace(/<i> {1,}/g, ' _')
    .replace(/ {1,}<\/i>/g, '_ ')
    .replace(/<\/?i>/g, '_')

    .replace(/<u> {1,}/g, ' ++')
    .replace(/ {1,}<\/u>/g, '++ ')
    .replace(/<\/?u>/g, '++')

    .replace(/<a\s+href="([^"]+)">([^<]+)<\/a>/g, '[$2]($1)');
};

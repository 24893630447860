import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardPostContent } from '../../../../services';

export const HorizontalStoryFeedAdminPost: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { type, content, isPinnedToTopOfNewsFeed } = card;
    const { title, sourceName, abstract } = content as StoryCardPostContent;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          cardType={type}
          source={sourceName}
          title={title}
          text={abstract}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedAdminCardWrapper>
    );
  }
);

import { rootApi } from './rootApi';

export interface TransloaditAuthResult {
  signature: string;
  params: {
    auth: {
      key: string;
      expires: string;
    };
    template_id: string;
    notify_url: string;
  };
}

export interface TransloaditAssembliesResult {
  assembly_id: string;
}

export enum TransloaditAssemblyStatus {
  completed = 'ASSEMBLY_COMPLETED',
  executing = 'ASSEMBLY_EXECUTING',
}

export interface TransloaditAssembly {
  ok: TransloaditAssemblyStatus;
  error: string;
}

export interface TransloaditAuthArgs {
  template: string;
  type: string;
  url?: string;
}

export const transloaditApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    transloaditAuth: builder.mutation<TransloaditAuthResult, TransloaditAuthArgs>({
      query: ({ template, type, url }) => ({
        url: '/transloadit-signature-authentication',
        method: 'POST',
        body: {
          template,
          type,
          url,
        },
      }),
    }),
  }),
});

export const { useTransloaditAuthMutation } = transloaditApi;

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { StoryCard, StoryCardsFeedFilter } from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editComment,
  getStoryFeedHandler,
  hideComment,
  highlightComment,
  pinCard,
  reactOnCard,
  reactOnComment,
  repostCard,
  toggleCardSection,
  togglePublishOnCard,
  updateStory,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';
import { updateStoryCardPosition } from './reducers';

export const getStoryFeed = createAsyncThunk('storyFeed', getStoryFeedHandler);
export const getStoryFeedLoadMore = createAsyncThunk('storyFeed/loadMore', getStoryFeedHandler);

interface StoryFeedState {
  storyFeed: StoryCard[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
  filter?: StoryCardsFeedFilter;
}

const initialState: StoryFeedState = {
  storyFeed: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
  filter: undefined,
};

const storyFeedSlice = createSlice({
  name: 'storyFeed',
  initialState,
  reducers: {
    updateStoryFeedCardPosition: (
      state,
      action: PayloadAction<{ dragId: number; targetId: number }>
    ) => {
      const { dragId, targetId } = action.payload;

      updateStoryCardPosition({ dragId, targetId, feed: state.storyFeed });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoryFeed.pending, (state) => {
        state.storyFeed = [];
        state.isFetching = true;
      })
      .addCase(getStoryFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.storyFeed = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
        state.filter = action.meta.arg.filter;
      })
      .addCase(getStoryFeedLoadMore.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.storyFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(updateStory.fulfilled, (state, action) => {
        const { id, ...storyProps } = action.payload;

        state.storyFeed.forEach((storyFeedItem) => {
          storyFeedItem.story = {
            ...storyFeedItem.story,
            ...storyProps,
          };
        });
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const card = state.storyFeed.find(({ id }) => id === itemId);

        if (!card) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const card = state.storyFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;

        const card = state.storyFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;

        const card = state.storyFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.storyFeed = state.storyFeed.filter(({ id }) => id !== storyCardId);
      })
      .addCase(repostCard.fulfilled, (state, action) => {
        const storyCard = action.payload;

        if (!storyCard) {
          return;
        }

        const cardIndex = state.storyFeed.findIndex(({ id }) => id === storyCard.id);

        if (cardIndex === -1) {
          return;
        }

        state.storyFeed[cardIndex] = {
          ...state.storyFeed[cardIndex],
          dateToDisplay: storyCard.dateToDisplay,
        };
      })
      .addCase(pinCard.fulfilled, (state, action) => {
        const { payload, storyCardId } = action.payload;

        if (!payload) {
          return;
        }

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        const pinnedCard = state.storyFeed.find(
          ({ isPinnedToTopOfNewsFeed }) => isPinnedToTopOfNewsFeed
        );

        if (pinnedCard && pinnedCard.id !== storyCardId) {
          pinnedCard.isPinnedToTopOfNewsFeed = false;
        }

        card.isPinnedToTopOfNewsFeed = !card.isPinnedToTopOfNewsFeed;
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, status, postingTime } = payload;

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        if (state.filter?.status?.length && !state.filter.status.includes(status)) {
          state.storyFeed = state.storyFeed.filter(({ id }) => id !== storyCardId);
          return;
        }

        card.status = status;
        card.postingTime = postingTime;
      })
      .addCase(toggleCardSection.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, section } = payload;

        const card = state.storyFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        card.section = section;
      });
  },
});

export const { updateStoryFeedCardPosition } = storyFeedSlice.actions;
export default storyFeedSlice.reducer;

export const getRandomInteger = (range: number): number => {
  const max_range = 256; // Highest possible number in Uint8

  // Create byte array and fill with 1 random number
  const byteArray = new Uint8Array(1);
  window.crypto.getRandomValues(byteArray); // This is the new, and safer API than Math.Random()

  // If the generated number is out of range, try again
  if (byteArray[0] >= Math.floor(max_range / range) * range) {
    return getRandomInteger(range);
  }
  return byteArray[0] % range;
};

import { FunctionComponent, memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  FileAcceptType,
  FilePreview,
  FileUpload,
  GalleryItem,
  getCssVar,
  getWebAppUrl,
  IconLabel,
  IconLabelSizes,
  isCardMediaCompleted,
  Modal,
  PublishToggle,
  ScheduleInput,
  toggleStoryCardDisabled,
  useAppDispatch,
} from '../../../../../shared';

import { isMobileOnly } from 'react-device-detect';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../../context';
import {
  StoryCard,
  storyCardApi,
  StoryCardContentImageVideo,
  StoryCardFieldsPostInStoryInput,
  StoryCardStatus,
  StoryCardType,
} from '../../../../../services';
import { createProfileFeedStoryCard, editCard, getCard } from '../../../../../slices';

import classes from './ProfileFeedCreateCardModal.module.scss';

interface ProfileFeedCreateCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  card?: StoryCard;
}

const FETCH_CARD_ATTEMPTS_LIMIT = 2;

const FETCH_CARD_MS_TIMEOUT = 5000;

export const ProfileFeedCreateCardModal: FunctionComponent<ProfileFeedCreateCardModalProps> = memo(
  ({ isOpen, onClose, card }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [storyCardPostIn] = storyCardApi.endpoints.storyCardPostInUserProfileStory.useLazyQuery();

    const [storyCardUpdate] = storyCardApi.endpoints.storyCardUpdate.useLazyQuery();

    const { channelName } = useContext(UserContext).userInfo.body;

    const editMode = useMemo(() => Boolean(card), [card]);

    const [loading, setLoading] = useState(false);

    const fetchCardAttemptsCount = useRef<number>(0);

    const {
      storyId = 0,
      id: storyCardId = 0,
      content: cardContent,
      status: cardStatus,
      postingTime: cardPostingTime,
    } = { ...card };

    const [editCardStatus, setEditCardStatus] = useState<StoryCardStatus | undefined>(cardStatus);

    const isEditCardPublished = useMemo(
      () => editCardStatus === StoryCardStatus.PUBLISHED,
      [editCardStatus]
    );

    const [postingTime, setPostingTime] = useState<Date | null>(
      cardPostingTime ? new Date(cardPostingTime) : null
    );

    const [gallery, setGallery] = useState<GalleryItem[]>(
      ((cardContent as StoryCardContentImageVideo)?.gallery ?? []) as GalleryItem[]
    );

    const hasGallery = useMemo(() => Boolean(gallery.length), [gallery.length]);

    const galleryMap = useCallback((galleryItems: GalleryItem[]) => {
      return galleryItems.map((galleryItem) => {
        const { image, video } = galleryItem;

        const { id } = { ...image };

        const imageMapped = id && { image: { id } };

        switch (true) {
          case Boolean(video):
            return { video: { id: video?.id, useDefaultThumb: true }, ...imageMapped };
          case Boolean(image): {
            return { ...imageMapped };
          }
          default:
            return null;
        }
      });
    }, []);

    const postInCardType = useMemo(() => {
      switch (true) {
        case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.video))): {
          return StoryCardType.VIDEO;
        }
        case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.image))): {
          return StoryCardType.IMAGE;
        }
      }
    }, [gallery]);

    const postCard = useCallback(
      (status: StoryCardStatus) => {
        return new Promise<void>(async (resolve, reject) => {
          fetchCardAttemptsCount.current = 0;
          toggleStoryCardDisabled({ storyCardId, disabled: true });
          onClose();

          const mediaFields = { gallery: galleryMap(gallery) };

          try {
            const fields = {
              status,
              postingTime: postingTime?.toISOString(),
              ...(postInCardType === StoryCardType.IMAGE && { imageFields: mediaFields }),
              ...(postInCardType === StoryCardType.VIDEO && { videoFields: mediaFields }),
            } as StoryCardFieldsPostInStoryInput;

            const { payload, error } = await (editMode
              ? storyCardUpdate({ ...{ fields }, storyId, storyCardId })
              : storyCardPostIn({ fields })
            ).unwrap();

            const payloadAction = editMode ? editCard : createProfileFeedStoryCard;

            if (payload) {
              if (isCardMediaCompleted(payload)) {
                toggleStoryCardDisabled({ storyCardId: storyCardId, disabled: false });
                dispatch(payloadAction(payload));
                resolve();
                return;
              }

              const { storyId: postedStoryId, id: postedCardId } = payload;

              const postedCardInterval = setInterval(async () => {
                fetchCardAttemptsCount.current = fetchCardAttemptsCount.current + 1;

                const postedCard = await dispatch(
                  getCard({ storyId: postedStoryId, cardId: postedCardId })
                ).unwrap();

                if (!isCardMediaCompleted(postedCard)) {
                  if (fetchCardAttemptsCount.current === FETCH_CARD_ATTEMPTS_LIMIT) {
                    toast.dismiss('postCard');
                    toast(t('addContent.long-processing'), { delay: 1000 });
                  }
                  return;
                }

                if (fetchCardAttemptsCount.current < FETCH_CARD_ATTEMPTS_LIMIT) {
                  toggleStoryCardDisabled({ storyCardId: storyCardId, disabled: false });
                  dispatch(payloadAction(postedCard));
                  resolve();
                  clearInterval(postedCardInterval);
                  return;
                }

                const detailsUrl = `${getWebAppUrl(
                  channelName
                )}/details/${postedStoryId}/${postedCardId}`;

                toast(
                  <IconLabel
                    label={t('addContent.card-processed')}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={() => window.open(detailsUrl, '_blank', 'noopener, noreferrer')}
                  />,
                  { autoClose: false }
                );

                clearInterval(postedCardInterval);
              }, FETCH_CARD_MS_TIMEOUT);

              return;
            }

            if (error) {
              toast.error(t('addContent.error-message'));
              reject();
            }
          } catch (_) {
            reject();
          }
        });
      },
      [
        channelName,
        dispatch,
        editMode,
        gallery,
        galleryMap,
        onClose,
        postInCardType,
        postingTime,
        storyCardId,
        storyCardPostIn,
        storyCardUpdate,
        storyId,
        t,
      ]
    );

    const postCardHandler = useCallback(
      async (status: StoryCardStatus) => {
        if (!Boolean(gallery.length)) {
          return toast.error(t('addContent.profile-feed-validation-error'));
        }

        const action = editMode ? 'edit' : 'create';

        await toast.promise(
          postCard(status),
          {
            pending: t(`addContent.${action}-pending`),
            success: t(`addContent.${action}-success`),
            error: t(`addContent.${action}-error`),
          },
          { toastId: 'postCard' }
        );
      },
      [editMode, gallery.length, postCard, t]
    );

    const scheduleButton = useMemo(() => {
      if (editMode && editCardStatus === StoryCardStatus.PUBLISHED) {
        return null;
      }

      return (
        <ScheduleInput
          postingTime={postingTime}
          setPostingTime={setPostingTime}
          title={t('addContent.schedule-post')}
          subTitle={t('addContent.schedule-post-description')}
        />
      );
    }, [editCardStatus, editMode, postingTime, t]);

    const saveButton = useMemo(() => {
      if (editMode) {
        return null;
      }

      if (isMobileOnly) {
        return (
          <IconLabel
            iconId={'save'}
            iconSize={20}
            onClick={() => postCardHandler(StoryCardStatus.UNPUBLISHED)}
            disabled={Boolean(loading || postingTime)}
          />
        );
      }

      return (
        <Button
          type={ButtonType.secondary}
          label={t('common.save-draft')}
          onClick={() => postCardHandler(StoryCardStatus.UNPUBLISHED)}
          disabled={Boolean(loading || postingTime)}
        />
      );
    }, [editMode, loading, postCardHandler, postingTime, t]);

    const publishToggleButton = useMemo(() => {
      if (!editMode) {
        return;
      }

      return (
        <PublishToggle
          isPublished={isEditCardPublished}
          onClick={() =>
            setEditCardStatus(
              isEditCardPublished ? StoryCardStatus.UNPUBLISHED : StoryCardStatus.PUBLISHED
            )
          }
        />
      );
    }, [editMode, isEditCardPublished]);

    const publishButtonLabel = useMemo(() => {
      switch (true) {
        case editMode:
          return t('common.save');
        case Boolean(postingTime):
          return t('common.schedule');
        default:
          return t('common.publish');
      }
    }, [editMode, postingTime, t]);

    const publishButtonClickHandler = useCallback(() => {
      if (!editMode) {
        postCardHandler(postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.PUBLISHED);
        return;
      }

      if (isEditCardPublished) {
        postCardHandler(StoryCardStatus.PUBLISHED);
        return;
      }

      postCardHandler(postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.UNPUBLISHED);
    }, [editMode, isEditCardPublished, postCardHandler, postingTime]);

    const publishButton = useMemo(() => {
      return (
        <Button
          type={ButtonType.primary}
          label={publishButtonLabel}
          disabled={loading}
          onClick={publishButtonClickHandler}
        />
      );
    }, [loading, publishButtonClickHandler, publishButtonLabel]);

    const createCardButtons = useMemo(() => {
      return (
        <div className={classes['create-card__buttons']}>
          {scheduleButton}
          {saveButton}
          {publishToggleButton}
          {publishButton}
        </div>
      );
    }, [publishButton, publishToggleButton, saveButton, scheduleButton]);

    const multiUpload = useMemo(
      () => Boolean(hasGallery && gallery.find(({ image }) => image)),
      [gallery, hasGallery]
    );

    const acceptTypes = useMemo(() => {
      return hasGallery ? [FileAcceptType.IMAGE] : [FileAcceptType.IMAGE, FileAcceptType.VIDEO];
    }, [hasGallery]);

    const uploadLimit = useMemo(() => {
      const { image, video } = { ...gallery[0] };

      switch (true) {
        case Boolean(video):
          return 1;
        case Boolean(image):
          return 20;
      }
    }, [gallery]);

    const body = useMemo(() => {
      return (
        <div className={classes['create-card']}>
          <div className={classes['create-card__post-in']}>
            <span className={classes['create-card__post-in-label']}>
              {t(`common.${editMode ? 'edit' : 'create'}-post-in`)}
            </span>
            <IconLabel
              label={t('profileFeed.title')}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
              labelSize={IconLabelSizes.large}
              nonClickable
            />
          </div>

          <FileUpload
            galleryItems={gallery}
            setGalleryItems={setGallery}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={acceptTypes}
            multiUpload={multiUpload}
            uploadLimit={uploadLimit}
            placeholder={t('profileFeed.input-file-placeholder')}
          />

          <FilePreview galleryItems={gallery} setGalleryItems={setGallery} loading={loading} />

          {createCardButtons}
        </div>
      );
    }, [acceptTypes, createCardButtons, editMode, gallery, loading, multiUpload, t, uploadLimit]);

    return (
      <>
        {isOpen &&
          ReactDOM.createPortal(
            <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />,
            document.getElementById('modal-root') as HTMLElement
          )}
      </>
    );
  }
);

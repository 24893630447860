import { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './BreadCrumbs.module.scss';

export interface BreadCrumbsItem {
  title: string;
  link?: string;
}

interface BreadCrumbsProps {
  items: BreadCrumbsItem[];
  className?: string;
}

export const BreadCrumbs: FunctionComponent<BreadCrumbsProps> = memo(({ items, className }) => {
  return (
    <div className={classNames(classes['bread-crumbs'], className)}>
      {items.map(({ link, title }, index) => {
        return (
          <div className={classes['bread-crumbs__wrapper']} key={`${title}${index}`}>
            {index > 0 && (
              <span className={classes['bread-crumbs__separator']}>
                <IconLabel
                  iconId={'right-arrow'}
                  iconSize={16}
                  color={'#6d767c'}
                  singleColor
                  nonClickable
                />
              </span>
            )}

            {link ? (
              <Link className={classes['bread-crumbs__item']} to={link}>
                {title}
              </Link>
            ) : (
              <span
                className={classNames(classes['bread-crumbs__item'], {
                  [classes['bread-crumbs__item--active']]: index === items.length - 1,
                })}
              >
                {title}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
});

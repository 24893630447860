import { ImageFile } from '../shared';
import { channelApi } from './channelApi';

export enum ChatAccess {
  admin = 'admin',
}

export enum ChatAccessTypes {
  private = 'private',
  publicWrite = 'publicWrite',
  publicRead = 'publicRead',
}

enum ChatLevels {
  channel = 'channel',
}

export enum ChatTypes {
  all = 'all', //atrificial field
  p2p = 'p2p',
  group = 'group',
}

export enum MessageTypes {
  chatRemoved = 'chatRemoved',
}

export enum MessageSubTypes {
  create = 'create',
  join = 'join',
  leave = 'leave',
  change = 'change',
}

export interface Chat {
  access: ChatAccess;
  accessType: ChatAccessTypes;
  channelId: number;
  chatDescriptor: string;
  chatId: number;
  created: string;
  image?: ImageFile;
  imageId: number | null;
  // isDefault  :   0
  isHiddenFromPublic: boolean;
  // itemId  :   null
  level: ChatLevels;
  name: string;
  organisationId: number;
  payload: string;
  publicToJoin: boolean;
  receivePushes: boolean;
  // recipient  :   null
  // recipientId  :   null
  // storyId  :   null
  // storyIdPinned  :   59533
  // storyPinnedEmpty  :   true
  // thumbnails  :   [{initials: "BT"}]
  type: ChatTypes;
  updated: string;
  usersCount: number;
}

interface ChannelChatsResponse {
  chats: Chat[];
  publishKey: string;
  subscribeKey: string;
  token: string;
  tokenV3: string;
}

interface DeleteChatArgs {
  chatId: number;
}

export const chatsApi = channelApi.injectEndpoints({
  endpoints: (builder) => ({
    getChannelChats: builder.query<ChannelChatsResponse, void>({
      query: () => ({
        url: '/chats',
      }),
      forceRefetch: () => true,
    }),
    deleteChat: builder.mutation<void, DeleteChatArgs>({
      query: ({ chatId }) => ({
        url: `/chats/${chatId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetChannelChatsQuery, useDeleteChatMutation } = chatsApi;

import { FunctionComponent, memo } from 'react';
import { Avatar } from '../Avatar';

import classNames from 'classnames';
import classes from './AuthorInfo.module.scss';

interface AuthorInfoProps {
  avatarUrl?: string;
  title?: string;
  subTitle?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  className?: string;
}

export const AuthorInfo: FunctionComponent<AuthorInfoProps> = memo(
  ({ avatarUrl, title, subTitle, icon, onClick, className }) => {
    return (
      <div className={classNames(classes['author-info'], className)} onClick={onClick}>
        <Avatar url={avatarUrl} size={48} />
        {(title || subTitle) && (
          <div className={classes['author-info__fields']}>
            {title && <div className={classes['author-info__fields-title']}>{title}</div>}
            {subTitle && <div className={classes['author-info__fields-subTitle']}>{subTitle}</div>}
          </div>
        )}
        {icon}
      </div>
    );
  }
);

import { FunctionComponent, PropsWithChildren, memo, useContext } from 'react';
import { ConfigContext } from '../../../context';

import classNames from 'classnames';
import classes from './Form.module.scss';

export enum AlignContentOptions {
  left = 'left',
  center = 'center',
  right = 'right',
}

interface FormProps {
  alignContent?: AlignContentOptions;
}

export const Form: FunctionComponent<PropsWithChildren<FormProps>> = memo(
  ({ alignContent, children }) => {
    const { config } = useContext(ConfigContext);

    const { organisationLogoUrl } = config.elements;

    return (
      <div className={classes['form']}>
        <img src={organisationLogoUrl} className={classes['form__logo']} alt="logo" />
        <form
          className={classNames(
            classes['form__content'],
            classes[`form__content--${alignContent}`]
          )}
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          {children}
        </form>
      </div>
    );
  }
);

import { FunctionComponent, memo, useMemo } from 'react';
import { StoryCardContentMedia } from '../../../../../services';
import { GalleryItem } from '../../../../../shared';
import { CardContainer } from '../CardContainer';
import { Content } from '../Content';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

export const Media: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { type, id, storyId, content, dateToDisplay } = card;

  const { gallery, headline, subHeadline, sourceName, text, textHTML } =
    content as StoryCardContentMedia;

  const hasContent = useMemo(() => {
    return [sourceName, headline, subHeadline, text, textHTML].some((item) => item?.length);
  }, [sourceName, headline, subHeadline, text, textHTML]);

  return (
    <CardContainer
      type={type}
      storyId={storyId}
      cardId={id}
      gallery={gallery as GalleryItem[]}
      postedTime={dateToDisplay}
    >
      {hasContent && (
        <Content
          source={sourceName}
          title={headline}
          subTitle={subHeadline}
          text={text}
          htmlText={textHTML}
        />
      )}
    </CardContainer>
  );
});

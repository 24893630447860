import { ChangeEvent, FunctionComponent, memo, useCallback } from 'react';
import { isValidDigit } from '../../../../../shared';

import classes from './TwoFactorAuthCode.module.scss';

export const TWO_FA_CODE_DIGITS_QTY = 6;

interface TwoFactorAuthCodeProps {
  digits: string[];
  onChange: (digits: string[]) => void;
}

export const TwoFactorAuthCode: FunctionComponent<TwoFactorAuthCodeProps> = memo(
  ({ digits, onChange }) => {
    const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, target } = event;
      const { value, previousSibling } = target as HTMLInputElement;

      if (key === 'Backspace' && !value && previousSibling) {
        (previousSibling as HTMLElement).focus();
      }
    }, []);

    const pasteHandler = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        const { clipboardData } = event;

        const clipboardText = clipboardData.getData('Text');

        const clipboardDigits = clipboardText.split('');

        if (
          clipboardDigits.length === TWO_FA_CODE_DIGITS_QTY &&
          clipboardDigits.every((digit) => isValidDigit(digit))
        ) {
          onChange(clipboardDigits);
        }
      },
      [onChange]
    );

    const onDigitChange = useCallback(
      ({ target }: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value, nextElementSibling } = target;

        if (value && !isValidDigit(value)) {
          return;
        }

        const newItems = [...digits];

        newItems[index] = value;

        onChange(newItems);

        if (isValidDigit(value) && nextElementSibling) {
          (nextElementSibling as HTMLElement).focus();
        }
      },
      [digits, onChange]
    );

    return (
      <div className={classes['code']}>
        {[...Array(TWO_FA_CODE_DIGITS_QTY)].map((_, index) => (
          <input
            key={`digit-${index}`}
            type={'text'}
            value={digits[index] ?? ''}
            maxLength={1}
            className={classes['code__digit']}
            onChange={(event) => onDigitChange(event, index)}
            onKeyDown={onKeyDown}
            onPaste={pasteHandler}
          />
        ))}
      </div>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import BaseHeader from '@editorjs/header';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';
import { SanitizerConfig } from '@editorjs/editorjs/types/configs/sanitizer-config';
import { getSanitizeConfig } from '../../RichTextEditorSanitizeConfig';

import './Header.module.scss';

export class Header extends BaseHeader implements InlineTool {
  static get sanitize(): SanitizerConfig {
    return getSanitizeConfig();
  }

  render(): HTMLElement {
    return super.render();
  }
}

import { FunctionComponent, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  ButtonType,
  IconLabel,
  IconLabelSizes,
  InputField,
  getCssVar,
  isValidDigit,
} from '../../../../../shared';
import { TWO_FA_CODE_DIGITS_QTY, TwoFactorAuthCode } from '../TwoFactorAuthCode';

import formClasses from '../../../Form/Form.module.scss';
import classes from './TwoFactorAuthSetup.module.scss';

export interface TwoFactorAuthSetupProps {
  otpAuthSecret: string;
  otpAuthUrlQRCode: string;
  onVerify: ({ otp }: { otp: string }) => void;
  onBack?: () => void;
}

export const TwoFactorAuthSetup: FunctionComponent<TwoFactorAuthSetupProps> = memo(
  ({ otpAuthSecret, otpAuthUrlQRCode, onVerify, onBack }) => {
    const { t } = useTranslation();

    const [digits, setDigits] = useState<string[]>([]);

    const [useQR, setUseQR] = useState(true);

    const verifyButton = useRef<HTMLButtonElement | null>(null);

    const copyCodeClick = useCallback(async () => {
      if (!navigator.clipboard) {
        return;
      }

      await navigator.clipboard.writeText(otpAuthSecret);

      toast(t('auth.2fa-otp-secret-copied'));
    }, [otpAuthSecret, t]);

    const step2Component = useMemo(() => {
      if (useQR) {
        return <img src={otpAuthUrlQRCode} alt={'qr'} />;
      }

      return (
        <InputField
          value={otpAuthSecret}
          disabled
          icon={
            <IconLabel
              iconId={'copy'}
              iconSize={18}
              onClick={copyCodeClick}
              color={getCssVar('--input-icon-color')}
              singleColor
            />
          }
        />
      );
    }, [copyCodeClick, otpAuthSecret, otpAuthUrlQRCode, useQR]);

    const verifyDisabled = useMemo(
      () =>
        digits.length !== TWO_FA_CODE_DIGITS_QTY || digits.some((digit) => !isValidDigit(digit)),
      [digits]
    );

    const verifyClickHandler = useCallback(async () => {
      verifyButton.current?.setAttribute('disabled', 'true');

      await onVerify({ otp: digits.join('') });

      verifyButton.current?.removeAttribute('disabled');
    }, [digits, onVerify]);

    return (
      <>
        <div className={formClasses['form__content-header']}>
          <span className={formClasses['form__content-header-title']}>
            {t('auth.2fa-setup-title')}
          </span>
          <div className={formClasses['form__content-header-subtitle']}>
            {t('auth.2fa-setup-subtitle')}
          </div>
        </div>
        <div className={classes['description']}>
          <div className={classes['description__step']}>
            <div className={classes['description__step-title']}>
              {t('auth.2fa-setup-step1-title')}
            </div>
          </div>
          <div className={classes['description__step']}>
            <div className={classes['description__step-title']}>
              {t(`auth.2fa-setup-step2-title-${useQR ? 'qr' : 'code'}`)}
            </div>
            <div className={classes['description__step-subTitle']}>
              {t(`auth.2fa-setup-step2-subtitle-${useQR ? 'qr' : 'code'}`)}
            </div>
            <div className={classes['description__step-component']}>
              {step2Component}
              <IconLabel
                label={t(`auth.2fa-setup-step2-${useQR ? 'add-code-manually' : 'scan-qr'}`)}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={() => setUseQR(!useQR)}
              />
            </div>
          </div>
          <div className={classes['description__step']}>
            <div className={classes['description__step-title']}>
              {t('auth.2fa-setup-step3-title')}
            </div>
            <div className={classes['description__step-subTitle']}>
              {t('auth.2fa-setup-step3-subtitle')}
            </div>
            <div className={classes['description__step-code']}>
              <TwoFactorAuthCode digits={digits} onChange={setDigits} />
            </div>
          </div>
          <Button
            ref={verifyButton}
            type={ButtonType.primary}
            label={t('common.verify-and-save')}
            onClick={verifyClickHandler}
            disabled={verifyDisabled}
            fullWidth
          />
          {onBack && (
            <IconLabel
              label={t('common.back-to-login')}
              labelSize={IconLabelSizes.large}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
              className={classes['link']}
              onClick={onBack}
            />
          )}
        </div>
      </>
    );
  }
);

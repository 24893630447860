import { FunctionComponent, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FeedCardProfile } from '../../../components';
import { ConfigContext } from '../../../context';
import { StoryCardStatus } from '../../../services';
import { getCssVar, IconLabel, layoutPath, useAppDispatch, useAppSelector } from '../../../shared';
import { getStoryCardsUserProfileFeed, loadMoreStoryCardsUserProfileFeed } from '../../../slices';
import { ProfileFeedCreateCard, ProfileFeedCreateCardMode } from './ProfileFeedCreateCard';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './ProfileFeed.module.scss';

interface ProfileFeedProps {
  userId: number;
  me?: boolean;
}

export const ProfileFeed: FunctionComponent<ProfileFeedProps> = memo(({ userId, me = true }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    storyCardsUserProfileFeed: cards,
    totalItems,
    perPage,
    isFetching,
  } = useAppSelector(({ storyCardsUserProfileFeed }) => storyCardsUserProfileFeed);

  const { config } = useContext(ConfigContext);

  const { profile, horizontalFeed } = config.elements;

  const { enabled = false } = { ...profile.feed };

  const { cardsPerView = 3, spaceBetweenCards = 12 } = { ...horizontalFeed };

  const slidesPerView = useMemo(() => (isMobileOnly ? 2 : cardsPerView), [cardsPerView]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    dispatch(
      getStoryCardsUserProfileFeed({
        userId,
        ...(!me && { filters: { status: [StoryCardStatus.PUBLISHED] } }),
      })
    );
  }, [dispatch, enabled, me, userId]);

  const cardsLength = useMemo(() => cards.length, [cards.length]);

  const onSlideChangeHandler = useCallback(
    (activeIndex: number) => {
      const index = activeIndex + slidesPerView;

      if (index < totalItems && index >= cardsLength) {
        dispatch(
          loadMoreStoryCardsUserProfileFeed({
            userId,
            page: Math.floor(index / perPage) + 1,
            size: perPage,
            ...(!me && { filters: { status: [StoryCardStatus.PUBLISHED] } }),
          })
        );
      }
    },
    [cardsLength, dispatch, me, perPage, slidesPerView, totalItems, userId]
  );

  const title = useMemo(
    () => <div className={classes['profile-feed__title']}>{t('profileFeed.title')}</div>,
    [t]
  );

  const content = useMemo(() => {
    if (isFetching) {
      return <Skeleton height={'5rem'} />;
    }

    if (Boolean(me && !cards.length)) {
      return (
        <>
          {title}
          <ProfileFeedCreateCard />
        </>
      );
    }

    if (Boolean(cards.length)) {
      return (
        <div className={classes['profile-feed__horizontal']}>
          <div className={classes['profile-feed__horizontal-header']}>
            {title}

            {me && <ProfileFeedCreateCard mode={ProfileFeedCreateCardMode.LABEL} />}

            <IconLabel
              className={classes['horizontal-feed__header-view']}
              iconId={'right-arrow'}
              iconSize={18}
              label={t('common.view-all')}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
              onClick={() => navigate(layoutPath(`/profile${me ? '/' : `/${userId}/`}items`))}
              labelFirst
            />
          </div>

          <Swiper
            navigation
            keyboard={{ enabled: true, onlyInViewport: true }}
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetweenCards}
            modules={[Navigation, Keyboard]}
            onSlideChange={({ activeIndex }) => onSlideChangeHandler(activeIndex)}
            style={{ overflow: 'visible' }}
            touchStartPreventDefault={false}
          >
            {cards.map((card) => (
              <SwiperSlide key={card.id}>
                <FeedCardProfile card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      );
    }

    return null;
  }, [
    cards,
    isFetching,
    me,
    navigate,
    onSlideChangeHandler,
    slidesPerView,
    spaceBetweenCards,
    t,
    title,
    userId,
  ]);

  if (Boolean(!enabled || (!me && !isFetching && !cards.length))) {
    return null;
  }

  return <div className={classes['profile-feed']}>{content}</div>;
});

import { FunctionComponent, useContext } from 'react';
import { ConfigContext } from '../../../context';

import classes from './FooterCopy.module.scss';

export const FooterCopy: FunctionComponent = () => {
  const { copyText } = useContext(ConfigContext).config.elements.footer;

  return (
    <div className={classes['copy']}>
      &#169; {new Date().getFullYear()} {copyText}
    </div>
  );
};

import { FunctionComponent, memo } from 'react';
import { IconLabel, QuoteBlockData, getCssVar } from '../../../../shared';

import classes from './Quote.module.scss';

export const Quote: FunctionComponent<QuoteBlockData> = memo(({ text, caption, alignment }) => {
  return (
    <div className={classes['quote']} style={{ textAlign: alignment }}>
      <IconLabel
        className={classes['quote__image']}
        iconId={'quote'}
        color={getCssVar('--details-block-quote-icon-color')}
        singleColor
        nonClickable
      />
      {text && (
        <div
          className={classes['quote__text']}
          dangerouslySetInnerHTML={{ __html: text.replace(/href/g, "target='_blank' href") }}
        ></div>
      )}
      {caption && (
        <div
          className={classes['quote__caption']}
          dangerouslySetInnerHTML={{ __html: caption.replace(/href/g, "target='_blank' href") }}
        ></div>
      )}
    </div>
  );
});

import { Comment, StoryCard } from '../../services';

export const editCommentReducer = ({ card, comment }: { card: StoryCard; comment: Comment }) => {
  const { comments } = card;
  const { id, content, attachments, parentId, contentUpdatedAt } = comment;

  const commentsToUpdate = parentId
    ? comments.find(({ id }) => id === parentId)?.replies
    : comments;

  if (!commentsToUpdate) {
    return;
  }

  const commentToUpdate = commentsToUpdate.find((comment) => comment.id === id);

  if (!commentToUpdate) {
    return;
  }

  commentToUpdate.content = content;
  commentToUpdate.attachments = attachments;
  commentToUpdate.contentUpdatedAt = contentUpdatedAt;
};

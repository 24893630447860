import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../../../../context';
import { StoryCard, StoryCardPdfContent } from '../../../../services';
import { useAnalytics } from '../../../hooks';
import { FileStatus } from '../../../models';
import { FileLink } from '../../File';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import classes from './FeedCardPdfCommon.module.scss';

interface FeedCardPdfCommonProps {
  card: StoryCard;
}

export const FeedCardPdfCommon: FunctionComponent<FeedCardPdfCommonProps> = memo(({ card }) => {
  const { logClickOpenArticle } = useAnalytics();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { id: cardId, storyId, content } = card;

  const { gallery } = content as StoryCardPdfContent;

  const galleryItem = useMemo(() => gallery[0], [gallery]);

  const { pdf, title } = { ...galleryItem };

  const pdfLoaded = useMemo(() => pdf.status === FileStatus.OK, [pdf.status]);

  const pdfClickHandler = useCallback(() => {
    if (!pdfLoaded) {
      return;
    }

    logClickOpenArticle({
      channel_id: channelId,
      story_id: storyId,
      item_id: cardId,
      time_stamp: new Date().toISOString(),
    });

    window.open(pdf?.url, '_blank', 'noopener, noreferrer');
  }, [cardId, channelId, logClickOpenArticle, pdf?.url, pdfLoaded, storyId]);

  const imageContent = useMemo(() => {
    switch (true) {
      case Boolean(gallery.find(({ image }) => image?.status === FileStatus.ERROR)):
        return (
          <FeedCardMargin top right bottom left>
            <FeedCardFileError />
          </FeedCardMargin>
        );
      case Boolean(gallery.find(({ image }) => image?.status === FileStatus.PROCESSING)):
        return (
          <FeedCardMargin top right bottom left>
            <Skeleton height={'5rem'} />
          </FeedCardMargin>
        );
      default: {
        const imageGalleryItems: ImageGalleryItem[] = gallery.map(({ image }) => {
          return {
            image: {
              url: image?.url,
              rightholder: image?.rightholder,
              averageColor: image?.averageColor,
            },
          };
        });

        return <ImageGallery items={imageGalleryItems} />;
      }
    }
  }, [gallery]);

  const pdfContent = useMemo(() => {
    switch (pdf.status) {
      case FileStatus.ERROR:
        return (
          <FeedCardMargin top right bottom left>
            <FeedCardFileError />
          </FeedCardMargin>
        );
      case FileStatus.PROCESSING:
        return (
          <FeedCardMargin top right bottom left>
            <Skeleton height={'5rem'} />
          </FeedCardMargin>
        );
      default:
        return (
          <FeedCardMargin top right left>
            <FileLink file={pdf} caption={title} />
          </FeedCardMargin>
        );
    }
  }, [pdf, title]);

  return (
    <div className={classNames({ [classes['wrapper']]: pdfLoaded })} onClick={pdfClickHandler}>
      {imageContent}
      {pdfContent}
    </div>
  );
});

import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { File, FileAcceptType } from '../../../../shared';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FileLink.module.scss';

interface FileLinkProps {
  file: File;
  caption?: string;
  onClick?: () => void;
}

export const FileLink: FunctionComponent<FileLinkProps> = memo(({ file, caption, onClick }) => {
  const { t } = useTranslation();

  const ext = useMemo(() => file.originalFilename?.split('.').pop() ?? '', [file.originalFilename]);

  const isFile = FileAcceptType.FILE.split(',').includes(`.${ext}`);

  return (
    <div className={classes['file-link']}>
      <div
        className={classNames(classes['file-link__description'], {
          [classes['file-link__description--pointer']]: onClick,
        })}
        onClick={onClick}
      >
        <span className={classes['file-link__description-type']}>{ext || t('common.file')}</span>
        <span className={classes['file-link__description-action']}>
          {t(`common.${isFile ? 'download' : 'open'}`)}
        </span>
        <IconLabel
          iconId={isFile ? 'donwload' : 'arrow-rounded-right-up'}
          iconSize={20}
          color={getCssVar('--media-file-icon-color')}
          singleColor
        />
      </div>
      {caption && <div className={classes['file-link__caption']}>{caption}</div>}
    </div>
  );
});

import { FunctionComponent, memo } from 'react';
import { UserFeedCommon } from '../../../shared';
import { StoriesCardsUserFeedAdmin } from './StoriesCardsUserFeedAdmin';

interface UserFeedProps {
  me?: boolean;
  channelId: number;
  userId: number;
}

export const UserFeedAdmin: FunctionComponent<UserFeedProps> = memo(({ me = true, userId }) => {
  return (
    <UserFeedCommon
      me={me}
      userId={userId}
      userFeedComponent={<StoriesCardsUserFeedAdmin userId={userId} />}
    />
  );
});

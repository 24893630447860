import { FunctionComponent, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { ConfigContext } from '../../context';
import { LATESTS_STORY_CARDS_COOKIE_KEY } from '../../shared';
import { ExternalTagsList } from './ExternalTagsList';
import { LatestsStoryCards } from './LatestsStoryCards';

import classes from './RightSide.module.scss';

export const RightSide: FunctionComponent = () => {
  const [cookies] = useCookies([LATESTS_STORY_CARDS_COOKIE_KEY]);

  const { latestsStoryCardsEnabled = true } = { ...cookies[LATESTS_STORY_CARDS_COOKIE_KEY] };

  const { latestsStoryCards, externalTagsList } = { ...useContext(ConfigContext).config.elements };

  const { visible: externalTagsListVisible = false } = { ...externalTagsList };

  const { visible: latestsStoryCardsVisible = false } = { ...latestsStoryCards };

  const renderLatestStoryCards = useMemo(
    () => Boolean(latestsStoryCardsVisible && latestsStoryCardsEnabled),
    [latestsStoryCardsEnabled, latestsStoryCardsVisible]
  );

  if (!externalTagsListVisible && !renderLatestStoryCards) {
    return null;
  }

  return (
    <div className={classes['right-side']}>
      {externalTagsListVisible && <ExternalTagsList />}
      {renderLatestStoryCards && <LatestsStoryCards />}
    </div>
  );
};

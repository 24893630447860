import { FunctionComponent, PropsWithChildren, memo } from 'react';
import { CardActions, FeedCardProps, FeedCardWrapperCommon } from '../../../shared';
import { DetailsModal } from '../../CardDetails';

export const FeedCardWrapper: FunctionComponent<PropsWithChildren<FeedCardProps>> = memo(
  ({ children, ...props }) => {
    return (
      <FeedCardWrapperCommon
        {...props}
        CardActionsComponent={CardActions}
        DetailsModalComponent={DetailsModal}
      >
        {children}
      </FeedCardWrapperCommon>
    );
  }
);

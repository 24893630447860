import { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardTeaserImageStyle } from '../../../../services';
import { GalleryItem } from '../../../models';
import { TextAreaField } from '../../TextAreaField';
import { TeaserStyle } from './TeaserStyle';

import classes from './FileSettings.module.scss';

interface FileSettingsProps {
  galleryItems?: GalleryItem[];
  setGalleryItems?: (galleryItems: GalleryItem[]) => void;
  activeGalleryItemIndex?: number;
  teaserStyle?: StoryCardTeaserImageStyle;
  setTeaserStyle?: (teaserStyle: StoryCardTeaserImageStyle) => void;
  hideCaption?: boolean;
  loading?: boolean;
}

export const FileSettings: FunctionComponent<FileSettingsProps> = memo(
  ({
    galleryItems = [],
    setGalleryItems,
    activeGalleryItemIndex = 0,
    teaserStyle,
    setTeaserStyle,
    hideCaption,
    loading,
  }) => {
    const { t } = useTranslation();

    const galleryItem = useMemo(
      () => galleryItems[activeGalleryItemIndex],
      [activeGalleryItemIndex, galleryItems]
    );

    const { image, title } = { ...galleryItem };

    const [rightholder, setRightholder] = useState<string>(image?.rightholder ?? '');

    const [caption, setCaption] = useState<string>(title ?? '');

    useEffect(() => {
      setRightholder(image?.rightholder ?? '');
      setCaption(title ?? '');
    }, [activeGalleryItemIndex, image?.rightholder, title]);

    const saveRightholder = useCallback(() => {
      if (!image) {
        return;
      }

      const galleryItemsCopy = [...galleryItems];

      galleryItemsCopy[activeGalleryItemIndex] = {
        ...galleryItem,
        image: { ...image, rightholder },
      };

      setGalleryItems?.(galleryItemsCopy);
    }, [activeGalleryItemIndex, galleryItem, galleryItems, image, rightholder, setGalleryItems]);

    const saveCaption = useCallback(() => {
      const galleryItemsCopy = [...galleryItems];

      galleryItemsCopy[activeGalleryItemIndex] = { ...galleryItem, title: caption };

      setGalleryItems?.(galleryItemsCopy);
    }, [activeGalleryItemIndex, caption, galleryItem, galleryItems, setGalleryItems]);

    const renderTeaserStyle = useMemo(() => {
      if (!teaserStyle || !setTeaserStyle) {
        return null;
      }

      return <TeaserStyle teaserStyle={teaserStyle} setTeaserStyle={setTeaserStyle} />;
    }, [setTeaserStyle, teaserStyle]);

    const renderCaption = useMemo(() => {
      if (hideCaption) {
        return null;
      }

      return (
        <TextAreaField
          value={caption}
          label={t('common.caption')}
          placeholder={t('common.caption')}
          inputClassName={classes['file-settings__input']}
          maxLength={160}
          onBlur={saveCaption}
          onChange={({ target }) => setCaption(target.value)}
          disableLineBreak
          disabled={loading}
        />
      );
    }, [caption, hideCaption, loading, saveCaption, t]);

    const renderRightHolder = useMemo(() => {
      if (!image) {
        return null;
      }

      return (
        <TextAreaField
          value={rightholder}
          label={t('common.copyright')}
          placeholder={t('common.copyright')}
          inputClassName={classes['file-settings__input']}
          maxLength={160}
          onBlur={saveRightholder}
          onChange={({ target }) => setRightholder(target.value)}
          disableLineBreak
          disabled={loading}
        />
      );
    }, [image, loading, rightholder, saveRightholder, t]);

    if (!galleryItems.length) {
      return null;
    }

    return (
      <div className={classes['file-settings']}>
        {renderCaption}
        {renderRightHolder}
        {renderTeaserStyle}
      </div>
    );
  }
);

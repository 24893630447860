import { FunctionComponent, memo, useState } from 'react';
import { CardActionsCommon, CardActionsProps } from '../CardActionsCommon';

export const CardActions: FunctionComponent<CardActionsProps> = memo(
  ({ card, isHorizontalFeedCard, isProfileFeedCard }) => {
    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    return (
      <CardActionsCommon
        card={card}
        isHorizontalFeedCard={isHorizontalFeedCard}
        isProfileFeedCard={isProfileFeedCard}
        isListOpen={isListOpen}
        setIsListOpen={setIsListOpen}
      />
    );
  }
);

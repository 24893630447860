import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FileAcceptType, useTags } from '../../../../hooks';
import { GalleryItem } from '../../../../models';
import { getCssVar, isAdminLayout } from '../../../../utils';
import { AuthorInfo } from '../../../AuthorInfo';
import { FilePreview, FileUpload } from '../../../File';
import { IconLabel } from '../../../IconLabel';
import { TextAreaField } from '../../../TextAreaField';
import { CreateCardSettingsTabType, LinkPostQuoteFields } from '../../CreateCardModal';

import classNames from 'classnames';
import classes from './LinkPostQuote.module.scss';

interface LinkPostQuoteProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  url: string;
  quoteData: LinkPostQuoteFields;
  setQuote: (quote: LinkPostQuoteFields) => void;
  activeLinkPostGalleryItemIndex: number;
  setActiveLinkPostGalleryItemIndex: (index: number) => void;
  settingsOpen: boolean;
  setSettingsOpen: (isOpen: boolean) => void;
  activeLinkPostSettingsMediaTab: boolean;
  setActiveLinkPostSettingsTabIndex: (index: number) => void;
  setLinkPostQuoteGalleryItems: (galleryItems: GalleryItem[]) => void;
}

export const LinkPostQuote: FunctionComponent<LinkPostQuoteProps> = memo(
  ({
    loading,
    setLoading,
    url,
    quoteData,
    setQuote,
    activeLinkPostGalleryItemIndex,
    setActiveLinkPostGalleryItemIndex,
    settingsOpen,
    setSettingsOpen,
    activeLinkPostSettingsMediaTab,
    setActiveLinkPostSettingsTabIndex,
    setLinkPostQuoteGalleryItems,
  }) => {
    const { t } = useTranslation();

    const { hasTags } = useTags();

    const headlineRef = useRef<HTMLTextAreaElement>(null);

    const {
      headline,
      quote,
      quotePerson,
      quotePersonHandle,
      quotePersonImage,
      quoteSource,
      gallery: linkPostQuoteGalleryItems,
    } = quoteData;

    const hasGallery = useMemo(
      () => Boolean(linkPostQuoteGalleryItems.length),
      [linkPostQuoteGalleryItems.length]
    );

    useEffect(() => {
      if (headlineRef.current) {
        headlineRef.current.focus();
        headlineRef.current?.setSelectionRange(
          headlineRef.current?.value.length,
          headlineRef.current?.value.length
        );
      }
    }, []);

    const fieldChangeHandler = useCallback(
      (name: keyof LinkPostQuoteFields, value: string) => {
        setQuote({ ...quoteData, [name]: value });
      },
      [quoteData, setQuote]
    );

    const headerSubTitle = useMemo(
      () => `${quotePersonHandle} via ${quoteSource.toLowerCase()}`,
      [quotePersonHandle, quoteSource]
    );

    const tagsClickHandler = useCallback(() => {
      setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.TAGS);
      setSettingsOpen(true);
    }, [setActiveLinkPostSettingsTabIndex, setSettingsOpen]);

    const content = useMemo(() => {
      return (
        <>
          <div className={classes['link-post-quote__url']}>
            <span className={classes['link-post-quote__url-label']}>{t('common.link')}:</span>
            <a
              href={url}
              target={'_blank'}
              rel={'noreferrer'}
              className={classes['link-post-quote__url-value']}
            >
              {url}
            </a>
          </div>

          <TextAreaField
            ref={headlineRef}
            inputClassName={classes['link-post-quote__headline']}
            value={headline}
            placeholder={t('addContent.link-post-quote-headline')}
            maxLength={360}
            onChange={({ target }) => fieldChangeHandler('headline', target.value)}
          />

          <AuthorInfo
            avatarUrl={quotePersonImage?.url}
            title={quotePerson}
            subTitle={headerSubTitle}
            className={classes['link-post-quote__author-info']}
          />

          <FileUpload
            galleryItems={linkPostQuoteGalleryItems}
            setGalleryItems={setLinkPostQuoteGalleryItems}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={[FileAcceptType.IMAGE]}
            uploadLimit={20}
            multiUpload
          />

          <FilePreview
            galleryItems={linkPostQuoteGalleryItems}
            setGalleryItems={setLinkPostQuoteGalleryItems}
            activeGalleryItemIndex={activeLinkPostGalleryItemIndex}
            setActiveGalleryItemIndex={setActiveLinkPostGalleryItemIndex}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            activeMediaTab={activeLinkPostSettingsMediaTab}
            setActiveMediaTab={(isActive) => {
              if (!isActive) {
                if (isAdminLayout()) {
                  setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.CARD);
                }
                return;
              }
              setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.MEDIA);
            }}
          />

          <TextAreaField
            inputClassName={classes['link-post-quote__content']}
            value={quote}
            placeholder={`${t('addContent.link-post-quote-content')} ${!hasGallery ? '*' : ''}`}
            maxLength={700}
            onChange={({ target }) => fieldChangeHandler('quote', target.value)}
          />
        </>
      );
    }, [
      activeLinkPostGalleryItemIndex,
      activeLinkPostSettingsMediaTab,
      fieldChangeHandler,
      hasGallery,
      headerSubTitle,
      headline,
      linkPostQuoteGalleryItems,
      loading,
      quote,
      quotePerson,
      quotePersonImage?.url,
      setActiveLinkPostGalleryItemIndex,
      setActiveLinkPostSettingsTabIndex,
      setLinkPostQuoteGalleryItems,
      setLoading,
      setSettingsOpen,
      settingsOpen,
      t,
      url,
    ]);

    return (
      <div className={classes['link-post-quote']}>
        <div
          className={classNames(classes['link-post-quote__scrollable'], {
            [classes['link-post-quote__scrollable--mobile']]: isMobileOnly,
            [classes['link-post-quote__scrollable--has-tags']]: hasTags,
          })}
        >
          {content}
        </div>
        {hasTags && (
          <IconLabel
            iconId={'tags'}
            iconSize={20}
            label={t('addContent.short-post-tags')}
            color={getCssVar('--create-card-link-color')}
            hoverColor={getCssVar('--create-card-link-hover-color')}
            className={classes['link-post-quote__tags']}
            onClick={tagsClickHandler}
          />
        )}
      </div>
    );
  }
);

import { FunctionComponent, memo, useMemo } from 'react';
import { StoryCard, StoryCardPostContent, StoryCardType } from '../../../services';
import { DetailsBlock } from '../DetailsBlock';
import { DetailsCardToBlock } from '../DetailsCardToBlock';

interface DetailsBodyProps {
  card: StoryCard;
}

export const DetailsBody: FunctionComponent<DetailsBodyProps> = memo(({ card }) => {
  const { id: cardId, storyId, type, content } = card;

  const blocks = type === StoryCardType.POST && (content as StoryCardPostContent).contentBlocks;

  const details = useMemo(() => {
    if (blocks) {
      return blocks.map((block, index) => (
        <DetailsBlock
          key={`${block.type}${index}`}
          block={block}
          storyId={storyId}
          cardId={cardId}
        />
      ));
    }

    return <DetailsCardToBlock card={card} />;
  }, [blocks, card, cardId, storyId]);

  return <>{details}</>;
});

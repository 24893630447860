import { getCookieByName } from './getCookieByName';

export interface CustomClientConfig {
  // {channel}-webapp.tchop.live
  urlStructure: string;

  // webapp.tchop.live
  client: string;

  // tchop.live
  baseHost: string;
}

const getCustomClientConfig = (): CustomClientConfig | null => {
  const encodedCookie = getCookieByName('mz-client-config');

  if (!encodedCookie) {
    return null;
  }

  try {
    return JSON.parse(decodeURIComponent(encodedCookie));
  } catch (e) {
    console.error(`getCustomClientConfig. ${e}`);
    return null;
  }
};

export const CUSTOM_CLIENT_CONFIG = getCustomClientConfig();

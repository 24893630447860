import libphonenumber from 'google-libphonenumber';
import { isNumber } from './isNumber';

export const phoneValidationError = (phone: string) => {
  if (!phone.length) {
    return true;
  }

  let isValidNumber = true;

  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const parsedPhoneNumber = phoneUtil.parse(phone);
    isValidNumber = phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch (_) {
    isValidNumber = false;
  } finally {
    if (!isValidNumber) {
      return !(isNumber(phone) && phone.length >= 7 && phone.length <= 15);
    }
    return false;
  }
};

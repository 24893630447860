import { OrderDirection, isAdminLayout } from '../../../shared';

export const commentQuery = (size = 2, orderDirection = OrderDirection.DESC) => `
  attachments {
    imageId
    image {
      id
      originalFilename
      url
      averageColor
      rightholder
    }
    videoId
    video {
      id
      originalFilename
      thumb
      url
      status
    }
  }
  author {
    avatar {
      averageColor
      externalId
      externalType
      id
      originalFilename
      rightholder
      status
      statusCopyrightId
      thumb
      url
    }
    email
    id
    screenName
  }
  authorId
  content
  contentUpdatedAt
  createdAt
  id
  isHidden
  isHighlighted
  myReaction
  parentId
  reactions {
    count
    name
  }
  permissions {
    allowToEditContent
    allowToDelete
    allowToHide
    allowToHighlight
  }
  replies(size: ${size}, orderDirection: ${orderDirection}, filters: {showHidden: ${isAdminLayout()}}) {
    attachments {
      imageId
      image {
        id
        originalFilename
        url
        averageColor
        rightholder
      }
      videoId
      video {
        id
        originalFilename
        thumb
        url
        status
      }
    }
    author {
      avatar {
        averageColor
        externalId
        externalType
        id
        originalFilename
        rightholder
        status
        statusCopyrightId
        thumb
        url
      }
      email
      id
      screenName
    }
    authorId
    content
    permissions {
      allowToEditContent
      allowToDelete
      allowToHide
      allowToHighlight
    }
    contentUpdatedAt
    createdAt
    id
    isHidden
    myReaction
    parentId
    reactions {
      count
      name
    }
    repliesCount(filters: {showHidden: ${isAdminLayout()}})
    story {
      channelId
      id
      includeInMixTab
      includeInMixTab2
      includeInNewsFeed
      status
      subtitle
      title
      settings {
        includeInNewsFeed
      }
    }
    storyCardId
    storyId
    story {
      title
    }
    storyCard {
      type
      content {
        ... on StoryCardThreadContent {
          title
        }
        ... on StoryCardEditorialContent {
          headline
        }
        ... on StoryCardImageContent {
          headline
        }
        ... on StoryCardAudioContent {
          headline 
        }
        ... on StoryCardVideoContent {
          headline
        }
        ... on StoryCardPdfContent {
          headline
        }
        ... on StoryCardArticleContent {
          title
        }
        ... on StoryCardQuoteContent {
          quote
        }
        ... on StoryCardPostContent {
          title
        }
      }
    }
  }
  repliesCount(filters: {showHidden: ${isAdminLayout()}})
  storyCardId
  storyId
  story {
    title
  }
  storyCard {
    type
    content {
      ... on StoryCardThreadContent {
        title
      }
      ... on StoryCardEditorialContent {
        headline
      }
      ... on StoryCardImageContent {
        headline
      }
      ... on StoryCardAudioContent {
        headline 
      }
      ... on StoryCardVideoContent {
        headline
      }
      ... on StoryCardPdfContent {
        headline
      }
      ... on StoryCardArticleContent {
        title
      }
      ... on StoryCardQuoteContent {
        quote
      }
      ... on StoryCardPostContent {
        title
      }
    }
  }`;

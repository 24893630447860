import { FunctionComponent, memo } from 'react';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';
import { FeedCardProps, FeedCardThreadCommon } from '../../../shared';

export const FeedCardAdminThread: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardThreadCommon card={card} />
    </FeedCardAdminWrapper>
  );
});

import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FooterCopy, IconLabel, IconLabelSizes, getCssVar } from '../../shared';

import classes from './FooterAdmin.module.scss';

export const FooterAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const renderLink = useCallback(
    (localiseKey: string, url: string) => {
      return (
        <IconLabel
          label={t(`adminLayoutFooter.${localiseKey}`)}
          color={'#b3b3b3'}
          labelSize={IconLabelSizes.small}
          labelClassName={classes['footer__links-item-label']}
          onClick={() => window.open(url, '_blank', 'noopener, noreferrer')}
          singleColor
        />
      );
    },
    [t]
  );

  return (
    <div className={classes['footer']}>
      <IconLabel
        iconId={'switch'}
        label={t('common.reader-view')}
        color={getCssVar('--base-link-text-color')}
        hoverColor={getCssVar('--base-link-text-hover-color')}
        onClick={() => navigate('/')}
      />

      <div className={classes['footer__links']}>
        {renderLink(
          'plugin',
          'https://chrome.google.com/webstore/detail/tchop-curator-extension/acffclopdpmfepllledpjjppenpbcmlk'
        )}
        {renderLink('faq', 'https://help.tchop.io')}
        {renderLink('privacy', 'https://tchop.io/privacy')}
        {renderLink('terms', 'https://tchop.io/terms-of-service')}
      </div>
      <FooterCopy />
    </div>
  );
};

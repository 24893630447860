import { FunctionComponent, memo, useMemo } from 'react';
import { TableBlockData } from '../../../../shared';

import classes from './Table.module.scss';

export const Table: FunctionComponent<TableBlockData> = memo(({ withHeadings, content }) => {
  const renderHeader = useMemo(() => {
    if (!withHeadings) {
      return null;
    }

    return (
      <thead>
        <tr>
          {content[0].map((header, index) => (
            <th
              key={`th${index}`}
              dangerouslySetInnerHTML={{ __html: header.replace(/href/g, "target='_blank' href") }}
            ></th>
          ))}
        </tr>
      </thead>
    );
  }, [content, withHeadings]);

  const renderBody = useMemo(() => {
    return (
      <tbody>
        {content.map((row, index) => {
          if (withHeadings && !index) {
            return false;
          }

          return (
            <tr key={`tr${index}`}>
              {row.map((cell, index) => {
                return (
                  <td
                    key={`td${index}`}
                    dangerouslySetInnerHTML={{
                      __html: cell.replace(/href/g, "target='_blank' href"),
                    }}
                  ></td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }, [content, withHeadings]);

  return (
    <div className={classes['wrapper']}>
      <table className={classes['table']}>
        {renderHeader}
        {renderBody}
      </table>
    </div>
  );
});

import {
  FunctionComponent,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { UserContext } from '../../../../../context';
import { StoryCardType } from '../../../../../services';
import {
  GalleryItem,
  getCssVar,
  getStoryCardPreviewUrl,
  IconLabel,
  useAnalytics,
  useCardDate,
} from '../../../../../shared';
import { DetailsModal } from '../../../../CardDetails';

import classNames from 'classnames';
import classes from './CardContainer.module.scss';

interface CardContainerProps {
  storyId: number;
  cardId: number;
  type: StoryCardType;
  postedTime: string;
  gallery?: GalleryItem[];
  url?: string;
}

export const CardContainer: FunctionComponent<PropsWithChildren<CardContainerProps>> = memo(
  ({ type, postedTime, gallery, url, storyId, cardId, children }) => {
    const { channelId } = useContext(UserContext).userInfo.userData;

    const date = useCardDate(postedTime);

    const { logClickOpenArticle } = useAnalytics();

    const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

    const previewUrl = getStoryCardPreviewUrl({ type, galleryItem: gallery?.[0] });

    const isImageGallery = useMemo(
      () =>
        [StoryCardType.IMAGE, StoryCardType.QUOTE].includes(type) && gallery && gallery?.length > 1,
      [gallery, type]
    );

    const shouldRenderOverlay = useMemo(
      () =>
        isImageGallery ||
        [StoryCardType.VIDEO, StoryCardType.AUDIO, StoryCardType.PDF].includes(type),
      [isImageGallery, type]
    );

    const iconColor = useMemo(
      () => (!previewUrl ? getCssVar('--card-overlay-icon-color') : '#ffffff'),
      [previewUrl]
    );

    const teaserOverlay = useMemo(() => {
      if (!shouldRenderOverlay) {
        return null;
      }

      return (
        <div
          className={classNames(classes['card__teaser-overlay'], {
            [classes['card__teaser-overlay--no-image-loaded']]: !previewUrl,
            [classes['card__teaser-overlay--wide']]: !children,
          })}
        >
          <IconLabel
            iconId={isImageGallery ? 'gallery' : type.toLowerCase()}
            iconSize={32}
            color={iconColor}
            singleColor
          />
        </div>
      );
    }, [children, iconColor, isImageGallery, previewUrl, shouldRenderOverlay, type]);

    const wideTeaser = useMemo(() => {
      return (
        <div className={classes['card__teaser']}>
          {previewUrl && (
            <>
              <div
                className={classes['card__teaser-background']}
                style={{ backgroundImage: `url(${previewUrl})` }}
              ></div>
              <img className={classes['card__teaser-image']} src={previewUrl} alt={'teaser'} />
            </>
          )}
          {teaserOverlay}
        </div>
      );
    }, [previewUrl, teaserOverlay]);

    const sideTeaser = useMemo(() => {
      return (
        <div className={classes['card__side-teaser']}>
          {previewUrl && (
            <div
              className={classes['card__side-teaser-image']}
              style={{ backgroundImage: `url(${previewUrl})` }}
            ></div>
          )}
          {teaserOverlay}
        </div>
      );
    }, [previewUrl, teaserOverlay]);

    const postedDate = useMemo(() => {
      return <div className={classes['card__posted-time']}>{date}</div>;
    }, [date]);

    const shouldRenderTeaser = useMemo(() => {
      return previewUrl || shouldRenderOverlay;
    }, [previewUrl, shouldRenderOverlay]);

    const shouldRenderSideTeaser = useMemo(
      () => Boolean(shouldRenderTeaser && children),
      [children, shouldRenderTeaser]
    );

    const shouldRenderWideTeaser = useMemo(
      () => Boolean(shouldRenderTeaser && !children),
      [children, shouldRenderTeaser]
    );

    const cardClickHandler = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
          event.preventDefault();
        }

        if (url && [StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type)) {
          logClickOpenArticle({
            channel_id: channelId,
            story_id: storyId,
            item_id: cardId,
            time_stamp: new Date().toISOString(),
          });

          window.open(url, '_blank', 'noreferrer');
        } else {
          setOpenCardDetailsModal(true);
        }
      },
      [cardId, channelId, logClickOpenArticle, storyId, type, url]
    );

    return (
      <>
        <div
          className={classNames(`card-${cardId}`, classes['card'])}
          onClick={(event) => cardClickHandler(event)}
        >
          <div
            className={classNames(classes['card__content'], {
              [classes['card__content--with-place-for-side-teaser']]: shouldRenderSideTeaser,
            })}
          >
            {shouldRenderWideTeaser && wideTeaser}
            {children}
            {date && postedDate}
          </div>

          {shouldRenderSideTeaser && sideTeaser}
        </div>

        {Boolean(openCardDetailsModal && cardId) && (
          <DetailsModal
            isOpen={openCardDetailsModal}
            storyId={storyId}
            cardId={cardId}
            onClose={() => setOpenCardDetailsModal(false)}
          />
        )}
      </>
    );
  }
);

import { ResponsiveBar } from '@nivo/bar';
import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context';
import {
  useChannelListCommentsQuery,
  useChannelListPostsQuery,
  useChannelListReactionsQuery,
  useChannelListSharesQuery,
  useChannelListViewsQuery,
} from '../../../../services';
import {
  ANALYTICS_ADMIN_COOKIE_KEY,
  CookieActionType,
  Select,
  cookieOptions,
} from '../../../../shared';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, getHorizontalBarsLayer } from '../../helpers';
import { ContentProps } from '../Content';

import classes from '../../Analytics.module.scss';

const BAR_HEIGHT = 32;
const MARGIN_TOP = 20;
const MARGIN_BOTTOM = 40;
const PADDING = BAR_HEIGHT / 2;

enum ChannelListType {
  POSTS = 'POSTS',
  VIEWS = 'VIEWS',
  REACTIONS = 'REACTIONS',
  COMMENTS = 'COMMENTS',
  SHARES = 'SHARES',
}

export const ChannelList: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform }) => {
    const { t } = useTranslation();

    const { channelId: selectedChannelId } = useContext(UserContext).userInfo.userData;

    const COOKIE_KEY = useMemo(
      () => `${ANALYTICS_ADMIN_COOKIE_KEY}_${selectedChannelId}_channelList`,
      [selectedChannelId]
    );

    const [cookies, setCookie] = useCookies([COOKIE_KEY]);

    const { channelListType = ChannelListType.POSTS } = { ...cookies[COOKIE_KEY] };

    const dropdownItems = useMemo(() => {
      return Object.values(ChannelListType).map((value) => {
        return { id: value, title: t(`channelListTypes.${value}`) };
      });
    }, [t]);

    const channelListTypeChange = useCallback(
      (channelListType: ChannelListType) => {
        setCookie(
          COOKIE_KEY,
          { ...cookies[COOKIE_KEY], channelListType },
          cookieOptions({ action: CookieActionType.SET })
        );
      },
      [COOKIE_KEY, cookies, setCookie]
    );

    const channelListQuery = useMemo(() => {
      switch (channelListType) {
        case ChannelListType.VIEWS:
          return useChannelListViewsQuery;
        case ChannelListType.REACTIONS:
          return useChannelListReactionsQuery;
        case ChannelListType.COMMENTS:
          return useChannelListCommentsQuery;
        case ChannelListType.SHARES:
          return useChannelListSharesQuery;
        default:
          return useChannelListPostsQuery;
      }
    }, [channelListType]);

    const { data: channelList, isFetching: isFetchingChannelList } = channelListQuery({
      from,
      to,
      channelId,
      platform,
    });

    const renderChart = useMemo(() => {
      if (isFetchingChannelList) {
        return <Loader />;
      }

      if (!channelList) {
        return null;
      }

      const { items } = channelList;

      const itemsFiltered = items.filter(({ value }) => value);

      if (!itemsFiltered.length) {
        return (
          <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>
        );
      }

      const keys: string[] = [];

      const data = itemsFiltered.map(({ label, value }) => {
        keys.push(label);
        return { channel: label, [label]: value };
      });

      const height = `calc(${data.length * BAR_HEIGHT}px + ${MARGIN_TOP}px + ${MARGIN_BOTTOM}px + ${
        (data.length + 1) * PADDING
      }px)`;

      return (
        <div style={{ height }}>
          <ResponsiveBar
            layout={'horizontal'}
            enableGridY={false}
            margin={{ top: MARGIN_TOP, right: 50, bottom: MARGIN_BOTTOM, left: 20 }}
            padding={0.5}
            borderRadius={4}
            tooltip={() => <></>}
            axisLeft={{
              renderTick: ({ value, y }) => (
                <text y={y - PADDING} fontSize={14} fill={'#1E1E1E'}>
                  {value}
                </text>
              ),
            }}
            axisBottom={{ format: (value) => (value === Math.floor(value) ? value : '') }}
            data={data.reverse()}
            keys={keys}
            indexBy={'channel'}
            colors={[COLORS[0]]}
            label={''}
            layers={['axes', 'bars', ({ bars }) => getHorizontalBarsLayer({ bars })]}
          />
        </div>
      );
    }, [channelList, isFetchingChannelList, t]);

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.channel-list'}
        infoLocaliseKey={'channelList.info'}
        component={
          <Select
            className={classes['analytics__chart-header-select']}
            selectedItemId={channelListType}
            items={dropdownItems}
            onChange={channelListTypeChange}
          />
        }
      >
        {renderChart}
      </ChartWrapper>
    );
  }
);

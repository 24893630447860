import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toggleLabel } from '../../utils';
import { Delta } from '../../../../../shared';

import classNames from 'classnames';
import classes from '../../../Analytics.module.scss';

interface StatsItemProps {
  localiseKey: string;
  value: number;
  valuePrev: number;
  label?: string;
  hintLocaliseKey?: string;
  valueSuffix?: string;
  valueFormatter?: (value: number) => string;
  color?: string;
  labels?: string[];
  setLabels?: (labels: string[]) => void;
  hasTotal?: boolean;
  isTotal?: boolean;
  isInline?: boolean;
  isClickable?: boolean;
  isDisabled?: boolean;
  columnDisplay?: boolean;
}

export const StatsItem: FunctionComponent<StatsItemProps> = memo(
  ({
    localiseKey,
    value,
    valuePrev,
    label,
    hintLocaliseKey,
    valueSuffix,
    valueFormatter,
    color,
    labels,
    setLabels,
    hasTotal,
    isTotal,
    isInline,
    isClickable,
    isDisabled,
    columnDisplay,
  }) => {
    const { t } = useTranslation();

    return (
      <div
        className={classNames(classes['analytics__chart-stats-item'], {
          [classes['analytics__chart-stats-item--has-total']]: hasTotal,
          [classes['analytics__chart-stats-item--full-width']]: isTotal,
          [classes['analytics__chart-stats-item--inline']]: isInline,
          [classes['analytics__chart-stats-item--pointer']]: isClickable,
          [classes['analytics__chart-stats-item--disabled']]: isDisabled,
          [classes['analytics__chart-stats-item--column']]: columnDisplay,
          [classes['analytics__chart-stats-item--hint']]: hintLocaliseKey,
        })}
        {...(hintLocaliseKey && { 'data-hint': t(hintLocaliseKey) })}
        onClick={() => labels && setLabels && label && toggleLabel({ labels, setLabels, label })}
      >
        <div className={classes['analytics__chart-stats-item-label']}>
          {color && (
            <div
              className={classes['analytics__chart-stats-item-color']}
              style={{ backgroundColor: color }}
            ></div>
          )}
          {t(localiseKey)}
        </div>

        <div className={classes['analytics__chart-stats-item-right-side']}>
          <Delta value={value} valuePrev={valuePrev} />
          <span className={classes['analytics__chart-stats-item-value']}>
            {valueFormatter ? valueFormatter(value) : value}
            {valueSuffix}
          </span>
        </div>
      </div>
    );
  }
);

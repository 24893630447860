import EditorJS from '@editorjs/editorjs';
import {
  FunctionComponent,
  MutableRefObject,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { StoryCardPostContentBlockFields } from '../../../services';
import { useAppDispatch, useUpload } from '../../hooks';
import { BlockTypes } from '../../models';
import {
  BLOCK_TOOLS,
  INLINE_TOOLS,
  SHORT_POST_BLOCK_TOOLS,
  SHORT_POST_INLINE_TOOLS,
} from './RichTextEditorToolsConfig';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DragDrop from 'editorjs-drag-drop';

import classNames from 'classnames';
import classes from './RichTextEditor.module.scss';

interface RichTextEditorProps {
  editorInstance: MutableRefObject<EditorJS | null>;
  contentBlocks: StoryCardPostContentBlockFields[];
  isTextField?: boolean;
  onChange?: () => void;
}

export const RichTextEditor: FunctionComponent<RichTextEditorProps> = memo(
  ({ editorInstance, contentBlocks, isTextField, onChange }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { uploadHandler } = useUpload();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const isRichTextEditorReady = useRef(false); // * used to fix editor's dual rendering in strict mode

    const autofocus = useMemo(() => {
      if (!contentBlocks.length) {
        return true;
      }

      return [
        BlockTypes.paragraph,
        BlockTypes.header,
        BlockTypes.quote,
        BlockTypes.checklist,
        BlockTypes.list,
      ].includes(contentBlocks[0].type as BlockTypes);
    }, [contentBlocks]);

    const tools = useMemo(() => {
      if (isTextField) {
        return {
          ...SHORT_POST_INLINE_TOOLS,
          ...SHORT_POST_BLOCK_TOOLS(t('addContent.short-post-text')),
        };
      }
      return {
        ...INLINE_TOOLS,
        ...BLOCK_TOOLS({ t, dispatch, uploadHandler, channelId }),
      };
    }, [channelId, dispatch, isTextField, t, uploadHandler]);

    useEffect(() => {
      if (!isRichTextEditorReady.current) {
        editorInstance.current = new EditorJS({
          holder: 'editor',
          data: { blocks: contentBlocks },
          tools,
          autofocus,
          minHeight: 0,
          onReady: () => new DragDrop(editorInstance.current),
          onChange: () => onChange?.(),
          i18n: {
            messages: {
              ui: {
                inlineToolbar: {
                  converter: { 'Convert to': t('richTextEditor.inline-toolbar-convert-to') },
                },
              },
              blockTunes: {
                delete: {
                  Delete: t('richTextEditor.block-tunes-delete'),
                  'Click to delete': t('richTextEditor.block-tunes-click-to-delete'),
                },
                moveUp: { 'Move up': t('richTextEditor.block-tunes-move-up') },
                moveDown: { 'Move down': t('richTextEditor.block-tunes-move-down') },
              },
              toolNames: {
                Text: t('richTextEditor.tool-names-text'),
                Heading: t('richTextEditor.tool-names-heading'),
                ImageGallery: t('richTextEditor.tool-names-image-gallery'),
                Video: t('richTextEditor.tool-names-video'),
                Audio: t('richTextEditor.tool-names-audio'),
                PDF: t('richTextEditor.tool-names-pdf'),
                File: t('richTextEditor.tool-names-file'),
                EmbedLink: t('richTextEditor.tool-names-embed-link'),
                Mix: t('richTextEditor.tool-names-story'),
                Card: t('richTextEditor.tool-names-story-card'),
                User: t('richTextEditor.tool-names-user'),
                Callout: t('richTextEditor.tool-names-callout'),
                List: t('richTextEditor.tool-names-list'),
                Checklist: t('richTextEditor.tool-names-check-list'),
                Quote: t('richTextEditor.tool-names-quote'),
                Table: t('richTextEditor.tool-names-table'),
                Delimiter: t('richTextEditor.tool-names-delimiter'),
                Code: t('richTextEditor.tool-names-code'),
                Link: t('richTextEditor.tool-names-link'),
                Underline: t('richTextEditor.tool-names-underline'),
                Subscript: t('richTextEditor.tool-names-subscript'),
                Superscript: t('richTextEditor.tool-names-superscript'),
                Marker: t('richTextEditor.tool-names-marker'),
                Bold: t('richTextEditor.tool-names-bold'),
                Italic: t('richTextEditor.tool-names-italic'),
                InlineCode: t('richTextEditor.tool-names-inline-code'),
              },
              tools: {
                header: {
                  'Heading 1': t('richTextEditor.tools-header-heading-level', { level: 1 }),
                  'Heading 2': t('richTextEditor.tools-header-heading-level', { level: 2 }),
                  'Heading 3': t('richTextEditor.tools-header-heading-level', { level: 3 }),
                  'Heading 4': t('richTextEditor.tools-header-heading-level', { level: 4 }),
                  'Heading 5': t('richTextEditor.tools-header-heading-level', { level: 5 }),
                  'Heading 6': t('richTextEditor.tools-header-heading-level', { level: 6 }),
                },
                image: { caption: t('richTextEditor.tools-image-caption') },
                video: { label: t('richTextEditor.video-placeholder') },
                audio: { label: t('richTextEditor.audio-placeholder') },
                pdf: { label: t('richTextEditor.pdf-placeholder') },
                file: { label: t('richTextEditor.file-placeholder') },
                link: { 'Add a link': t('richTextEditor.tools-link-add') },
                story: { search: t('richTextEditor.search-story-placeholder') },
                card: {
                  changeStory: t('richTextEditor.change-story-label'),
                  searchStory: t('richTextEditor.search-story-placeholder'),
                  searchCard: t('richTextEditor.search-card-placeholder'),
                  editorial: t('cardTypes.editorial'),
                  image: t('cardTypes.image'),
                  audio: t('cardTypes.audio'),
                  video: t('cardTypes.video'),
                  pdf: t('cardTypes.pdf'),
                  quote: t('cardTypes.quote'),
                  article: t('cardTypes.article'),
                  post: t('cardTypes.post'),
                  thread: t('cardTypes.thread'),
                },
                table: {
                  'Without headings': t('richTextEditor.tools-table-without-headings'),
                  'With headings': t('richTextEditor.tools-table-with-headings'),
                  'Add column to left': t('richTextEditor.tools-table-add-columns-left'),
                  'Add column to right': t('richTextEditor.tools-table-add-columns-right'),
                  'Add row above': t('richTextEditor.tools-table-add-row-above'),
                  'Add row below': t('richTextEditor.tools-table-add-row-below'),
                  'Delete row': t('richTextEditor.tools-table-delete-row'),
                  'Delete column': t('richTextEditor.tools-table-delete-column'),
                },
                list: {
                  Unordered: t('richTextEditor.tools-list-unordered'),
                  Ordered: t('richTextEditor.tools-list-ordered'),
                },
                quote: {
                  'Align Left': t('richTextEditor.tools-quote-align-left'),
                  'Align Center': t('richTextEditor.tools-quote-align-center'),
                },
              },
            },
          },
        } as EditorJS.EditorConfig);

        isRichTextEditorReady.current = true;
      }
    }, [autofocus, contentBlocks, editorInstance, onChange, t, tools]);

    return (
      <div
        id={'editor'}
        className={classNames(classes['rich-text-editor'], {
          [classes['rich-text-editor--short-post-text-field']]: isTextField,
        })}
      />
    );
  }
);

import { Dispatch, FunctionComponent, SetStateAction, memo } from 'react';

import classNames from 'classnames';
import classes from './Radio.module.scss';

export enum RadioLabelSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface RadioProps {
  label: string;
  checked: boolean;
  labelSize?: RadioLabelSizes;
  onChange?: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const Radio: FunctionComponent<RadioProps> = memo(
  ({ label, checked, labelSize = RadioLabelSizes.medium, onChange, className }) => {
    return (
      <div
        className={classNames(classes['radio'], classes[`radio--${labelSize}`], className, {
          [classes['radio--checked']]: checked,
        })}
        onClick={() => onChange?.(!checked)}
      >
        {label}
      </div>
    );
  }
);

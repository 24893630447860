import { FunctionComponent, memo } from 'react';
import sprite from '../../../../assets/icons/sprite.svg';
import { ChecklistBlockData } from '../../../../shared';

import classNames from 'classnames';
import classes from './CheckList.module.scss';

export const CheckList: FunctionComponent<ChecklistBlockData> = memo(({ items }) => {
  return (
    <ul className={classes['check-list']}>
      {items.map(({ text, checked }, index) => {
        return (
          <li key={index} className={classes['check-list__item']}>
            <span
              className={classNames(classes['check-list__item-check'], {
                [classes['check-list__item-check--checked']]: checked,
              })}
            >
              {checked && (
                <svg className={classes['check-list__item-check-icon']}>
                  <use href={`${sprite}#checkmark`} />
                </svg>
              )}
            </span>

            {text && (
              <span
                className={classes['check-list__item-content']}
                dangerouslySetInnerHTML={{ __html: text.replace(/href/g, "target='_blank' href") }}
              ></span>
            )}
          </li>
        );
      })}
    </ul>
  );
});

import { FunctionComponent, memo } from 'react';

import classNames from 'classnames';
import classes from './CircularLoader.module.scss';

interface CircularLoaderProps {
  text?: string;
  sizeRem?: number;
  className?: string;
}

export const CircularLoader: FunctionComponent<CircularLoaderProps> = memo(
  ({ text, sizeRem = 1.75, className }) => {
    return (
      <div className={classNames(classes['loader'], className)}>
        {text && <span className={classes['loader__text']}>{text}</span>}
        <div
          className={classes['loader__body']}
          style={{
            width: `${sizeRem}rem`,
            height: `${sizeRem}rem`,
          }}
        ></div>
      </div>
    );
  }
);

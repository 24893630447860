import { FunctionComponent } from 'react';
import { DetailsModalCommon, DetailsModalProps } from '../../../shared';
import { DetailsContentAdmin } from '../DetailsContentAdmin';

export const DetailsModalAdmin: FunctionComponent<DetailsModalProps> = ({
  isOpen,
  storyId,
  cardId,
  onClose,
  scrollToTags,
  scrollToComments,
  activeCommentsInput,
}) => {
  return (
    <DetailsModalCommon
      isOpen={isOpen}
      storyId={storyId}
      cardId={cardId}
      onClose={onClose}
      ContentComponent={DetailsContentAdmin}
      scrollToTags={scrollToTags}
      scrollToComments={scrollToComments}
      activeCommentsInput={activeCommentsInput}
    />
  );
};

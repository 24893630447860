export enum CopyCutAction {
  COPY = 'COPY',
  CUT = 'CUT',
}

export const MIN_PASSWORD_LENGTH = 5;

export const COMMENTS_SIZE = 25;

export const TRANSLOADIT_URL = 'https://api2.transloadit.com/assemblies';

export const CRISP_SITE_ID = '39655829-166c-4a82-8f93-fdff2326ecad';

export const ORG_TAGS_SIZE_LIMIT = 200;

export const ORG_TAGS_NAME_LENGTH_LIMIT = 100;

//STORAGE
export const COLLAPSE_MODE_STORAGE_KEY = 'collapseMode';

export const FREE_LOGIN_USER_ID_STORAGE_KEY = 'freeLoginUserId';

export const LOGIN_REDIRECT_STORAGE_KEY = 'loginRedirect';

//COOKIE
export const MZ_ACCOUNT_COOKIE_KEY = 'mz-account';

export const FREE_LOGIN_COOKIE_KEY = 'freeLogin';

export const COPY_CUT_COOKIE_KEY = 'copyCut';

export const LATESTS_STORY_CARDS_COOKIE_KEY = 'latestsStoryCards';

export const USER_DIRECTORY_READER_COOKIE_KEY = 'userDirectoryReader';

export const STORY_TAB_ADMIN_COOKIE_KEY = 'storyTabAdmin';

export const STORY_TAB_ITEMS_ADMIN_COOKIE_KEY = 'storyTabItemsAdmin';

export const NEWS_FEED_ADMIN_COOKIE_KEY = 'newsFeedAdmin';

export const TAGS_FEED_ADMIN_COOKIE_KEY = 'tagsFeedAdmin';

export const SEARCH_ADMIN_COOKIE_KEY = 'searchAdmin';

export const ANALYTICS_ADMIN_COOKIE_KEY = 'analyticsAdmin';

export const USER_FEED_COOKIE_KEY = 'userFeed';

export const USER_DEVICE_COOKIE_KEY = 'userDevice';

export const NOTIFICATIONS_PERMISSION_COOKIE_KEY = 'notificationsPermission';

export const NOTIFICATIONS_ADMIN_COOKIE_KEY = 'notificationsAdmin';

export const ABUSE_REPORT_COOKIE_KEY = 'abuseReport';

export const FORGOT_PASSWORD_COOKIE_KEY = 'forgotPassword';

export const MUSIC_PLAYER_COOKIE_KEY = 'musicPlayer';

export const PUBNUB_COOKIE_KEY = 'pubNub';

import { emailValidationError } from './emailValidationError';
import { isHtmlString } from './isHtmlString';
import { isUrl } from './isUrl';
import { phoneValidationError } from './phoneValidationError';
import { unescape } from './unescape';

interface LinkStringProps {
  href: string;
  isEmail?: boolean;
  isPhone?: boolean;
}

const getLinkString = ({ href, isEmail, isPhone }: LinkStringProps) => {
  const getHref = () => {
    switch (true) {
      case isEmail:
        return `mailto:${href}`;
      case isPhone:
        return `tel:${href}`;
      default:
        return href;
    }
  };

  return `<a href=${getHref()} target='_blank' rel='noreferrer'>${href}</a>`;
};

const mapper = (text: string) => {
  return text
    .split('\n')
    .map((phrase) => {
      const words = phrase.split(' ');

      return words
        .map((word) => {
          if (isUrl(word)) {
            return getLinkString({ href: word });
          }

          if (!emailValidationError(word)) {
            return getLinkString({ href: word, isEmail: true });
          }

          if (!phoneValidationError(word)) {
            return getLinkString({ href: word, isPhone: true });
          }

          return word;
        })
        .join(' ');
    })
    .join('\n');
};

export const linkify = (content: string) => {
  if (!isHtmlString(content)) {
    return mapper(content);
  }

  const template = document.createElement('template');

  template.innerHTML = content;

  template.content.childNodes.forEach((node) => {
    node.childNodes.forEach((childNode) => {
      const { nodeType, nodeValue } = childNode;

      if (nodeType === Node.TEXT_NODE && nodeValue) {
        childNode.textContent = mapper(nodeValue);
      }
    });
  });

  return unescape(template.innerHTML);
};

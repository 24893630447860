import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Keyboard, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StoryCardContentImageVideo, StoryCardStatus, StoryCardType } from '../../services';
import {
  CardActions,
  FeedCardProps,
  GalleryItem,
  getCssVar,
  getStoryCardPreviewUrl,
  IconLabel,
  isCardMediaCompleted,
  Modal,
  useCardDate,
  VideoPlayer,
} from '../../shared';

import classNames from 'classnames';
import classes from './FeedCardProfile.module.scss';

export const FeedCardProfile: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { t } = useTranslation();

  const { id: cardId, type, content, dateToDisplay, status } = card;

  const date = useCardDate(dateToDisplay);

  const { gallery } = content as StoryCardContentImageVideo;

  const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

  const previewUrl = getStoryCardPreviewUrl({ type, galleryItem });

  const isImageGallery = useMemo(
    () => [StoryCardType.IMAGE].includes(type) && gallery && gallery.length > 1,
    [gallery, type]
  );

  const shouldRenderOverlay = useMemo(
    () => isImageGallery || (previewUrl && type === StoryCardType.VIDEO),
    [isImageGallery, previewUrl, type]
  );

  const iconId = useMemo(() => {
    if (isImageGallery) {
      return 'gallery';
    }

    return type.toLowerCase();
  }, [isImageGallery, type]);

  const iconColor = useMemo(
    () => (!previewUrl ? getCssVar('--card-overlay-icon-color') : '#ffffff'),
    [previewUrl]
  );

  const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

  const cardDetailsModalBody = useMemo(() => {
    switch (type) {
      case StoryCardType.VIDEO:
        return (
          <VideoPlayer
            autoplay
            url={galleryItem?.video?.url}
            thumb={previewUrl}
            className={'card__modal-video'}
          />
        );
      default:
        return (
          <Swiper
            navigation
            pagination
            keyboard={{ enabled: true, onlyInViewport: true }}
            modules={[Navigation, Pagination, Keyboard]}
          >
            {gallery.map(({ image }, index) => {
              if (!image) {
                return null;
              }

              const { url } = image;

              return (
                <SwiperSlide
                  key={`${url}-${index}`}
                  className={classes['card__modal-swiper-slide']}
                >
                  <img className={classes['card__modal-preview']} src={url} alt={'modal-preview'} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        );
    }
  }, [gallery, galleryItem?.video?.url, previewUrl, type]);

  const cardDetailsClickHandler = useCallback(() => {
    if (!isCardMediaCompleted(card)) {
      toast.info(t('profileFeed.file-not-ready'));
      return;
    }

    setOpenCardDetailsModal(true);
  }, [card, t]);

  if (![StoryCardType.IMAGE, StoryCardType.VIDEO].includes(type)) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(`card-${cardId}`, classes['card'], {
          [classes['card--not-published']]: status !== StoryCardStatus.PUBLISHED,
        })}
        onClick={cardDetailsClickHandler}
      >
        <div
          className={classNames(classes['card__teaser'], {
            [classes['card__teaser--overlay']]: shouldRenderOverlay,
          })}
          style={{ backgroundImage: `url(${previewUrl})` }}
        >
          {shouldRenderOverlay && (
            <IconLabel
              className={classes['card__teaser-icon']}
              iconId={iconId}
              iconSize={32}
              color={iconColor}
              singleColor
            />
          )}
        </div>

        {date && <div className={classes['card__posted-time']}>{date}</div>}
      </div>

      <CardActions card={card} isHorizontalFeedCard isProfileFeedCard />

      {openCardDetailsModal &&
        ReactDOM.createPortal(
          <Modal
            isOpen={openCardDetailsModal}
            body={cardDetailsModalBody}
            onClose={() => setOpenCardDetailsModal(false)}
            previewMode
          />,
          document.getElementById('modal-root') as HTMLElement
        )}
    </>
  );
});

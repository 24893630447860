import { MediaOutlet, MediaPlayButton, MediaPlayer } from '@vidstack/react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useBeforeUnload, useDuration } from '../../hooks';
import { Copyright } from '../Copyright';

import 'vidstack/styles/defaults.css';

import classNames from 'classnames';
import classes from './VideoPlayer.module.scss';

interface VideoPlayerProps {
  url?: string;
  thumb?: string;
  caption?: string;
  captionClassName?: string;
  copyright?: string;
  autoplay?: boolean;
  onLogItemPlayed?: (duration: number) => void;
  onLogClickOpenArticle?: () => void;
  className?: string;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  url,
  thumb,
  caption,
  captionClassName,
  copyright,
  autoplay,
  onLogItemPlayed,
  onLogClickOpenArticle,
  className,
}) => {
  const { startTrackTime, stopTrackTime, getDuration } = useDuration();

  const [activated, setActivated] = useState(false);

  const logItemPlayed = useCallback(
    () => onLogItemPlayed?.(getDuration()),
    [getDuration, onLogItemPlayed]
  );

  useBeforeUnload(logItemPlayed);

  useEffect(() => {
    return () => logItemPlayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPlay = useCallback(
    (target: EventTarget) => {
      startTrackTime();

      onLogClickOpenArticle?.();

      document.querySelectorAll('video').forEach((video) => {
        if (video !== (target as HTMLElement).querySelector('video')) {
          video.pause();
        }
      });
    },
    [onLogClickOpenArticle, startTrackTime]
  );

  const onPause = useCallback(() => {
    logItemPlayed();
    stopTrackTime();
  }, [logItemPlayed, stopTrackTime]);

  useEffect(() => {
    if (autoplay) {
      setActivated(true);
    }
  }, [autoplay]);

  if (!url) {
    return null;
  }

  return (
    <>
      <MediaPlayer
        className={classNames(classes['player'], className)}
        src={url}
        poster={thumb}
        autoplay={autoplay}
        controls={activated}
        onPlay={({ target }) => onPlay(target)}
        onPause={onPause}
        load={'eager'}
      >
        {!activated && (
          <div className={classes['player__play-button']} onClick={() => setActivated(true)}>
            <MediaPlayButton />
          </div>
        )}
        <MediaOutlet />
        {copyright && !activated && <Copyright copyright={copyright} />}
      </MediaPlayer>
      {caption && <div className={classNames(captionClassName, classes['caption'])}>{caption}</div>}
    </>
  );
};

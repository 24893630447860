interface ToggleStoryCardDisabledProps {
  storyCardId: number;
  disabled: boolean;
}

export const toggleStoryCardDisabled = ({
  storyCardId,
  disabled,
}: ToggleStoryCardDisabledProps) => {
  document.querySelectorAll(`.card-${storyCardId}`).forEach(({ classList }) => {
    disabled ? classList.add('card-disabled') : classList.remove('card-disabled');
  });
};

import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { OrganisationContext, UserContext } from '../../context';
import { TCHOP_ROOT_URL } from '../../env';
import { OrganisationRole } from '../../services';
import { CUSTOM_CLIENT_CONFIG, getChannelDomain } from '../../shared';

import sprite from '../../assets/icons/sprite.svg';

import classNames from 'classnames';
import classes from './MenuAdmin.module.scss';

interface MenuAdminItem {
  localiseKey: string;
  iconId: string;
  to: string;
  href?: string;
}

export const MenuAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const { isStaff } = useContext(UserContext).userProfile;

  const { organisation } = useContext(OrganisationContext);

  const channelDomain = useMemo(() => getChannelDomain(), []);

  const roleId = useMemo(() => organisation.role?.id, [organisation.role?.id]);

  const renderOrgSettings = useMemo(
    () =>
      Boolean(
        isStaff || (roleId && [OrganisationRole.OWNER, OrganisationRole.ADMIN].includes(roleId))
      ),
    [isStaff, roleId]
  );

  const origin = useMemo(() => {
    if (CUSTOM_CLIENT_CONFIG) {
      const { protocol, host } = new URL(TCHOP_ROOT_URL);

      return `${protocol}//${channelDomain}-${organisation.subdomain}.${host}`;
    }

    return window.location.origin;
  }, [channelDomain, organisation.subdomain]);

  const renderMenuItem = useCallback(
    ({ localiseKey, iconId, to, href }: MenuAdminItem) => {
      return (
        <li>
          {to && (
            <NavLink
              end
              to={to}
              className={({ isActive }) =>
                classNames(classes['menu__item'], {
                  [classes['menu__item--active']]: isActive,
                })
              }
            >
              <svg className={classes['menu__item-icon']}>
                <use href={`${sprite}#${iconId}`} />
              </svg>

              <span className={classes['menu__item-label']}>
                {t(`adminLayoutMenu.${localiseKey}`)}
              </span>
            </NavLink>
          )}
          {href && (
            <a className={classes['menu__item']} href={href} target={'_blank'} rel={'noreferrer'}>
              <svg className={classes['menu__item-icon']}>
                <use href={`${sprite}#${iconId}`} />
              </svg>

              <span className={classes['menu__item-label']}>
                {t(`adminLayoutMenu.${localiseKey}`)}
              </span>
            </a>
          )}
        </li>
      );
    },
    [t]
  );

  return (
    <ul className={classes['menu']}>
      {renderMenuItem({ localiseKey: 'content', iconId: 'home', to: '/admin' })}
      {renderMenuItem({ localiseKey: 'mixes', iconId: 'mixes', to: '/admin/storyTab' })}
      {/* {renderMenuItem({ localiseKey: 'chats', iconId: 'chats', to: '/admin/chats' })} */}

      <li className={classes['menu__section']}>{t('common.tools')}</li>

      {renderMenuItem({
        localiseKey: 'integration',
        iconId: 'integration',
        to: '',
        href: `${origin}/integrations`,
      })}
      {!isMobileOnly &&
        renderMenuItem({
          localiseKey: 'notification',
          iconId: 'bell',
          to: '/admin/notifications',
        })}
      {renderMenuItem({
        localiseKey: 'user-management',
        iconId: 'profile',
        to: '',
        href: `${origin}/curators`,
      })}
      {!isMobileOnly &&
        renderMenuItem({
          localiseKey: 'analytics',
          iconId: 'analytics',
          to: '/admin/analytics',
        })}
      {renderMenuItem({
        localiseKey: 'developers',
        iconId: 'developers',
        to: '',
        href: `${origin}/developers`,
      })}
      {renderMenuItem({
        localiseKey: 'settings',
        iconId: 'settings',
        to: '',
        href: `${origin}/settings`,
      })}
      {renderOrgSettings &&
        renderMenuItem({
          localiseKey: 'orgSettings',
          iconId: 'settings',
          to: '/admin/orgSettings',
        })}
    </ul>
  );
};

import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../context';
import { Story, StoryStatus } from '../../services';
import { getStoryTabItemsLink } from '../../shared';

import classNames from 'classnames';
import classes from './PostedIn.module.scss';

interface PostedInProps {
  story: Story;
}

export const PostedIn: FunctionComponent<PostedInProps> = memo(({ story }) => {
  const { t } = useTranslation();

  const { visible } = useContext(ConfigContext).config.elements.postedIn;

  const navigate = useNavigate();

  const { title, status } = story;

  const clickable = useMemo(() => status === StoryStatus.PUBLISHED, [status]);

  if (!visible) {
    return null;
  }

  return (
    <div className={classes['posted-in']}>
      <div className={classes['posted-in__label']}>{t('feed.posted-in')}:&nbsp;</div>
      <div
        className={classNames(classes['posted-in__value'], {
          [classes['posted-in__value--clickable']]: clickable,
        })}
        onClick={() => clickable && navigate(getStoryTabItemsLink({ story }))}
      >
        {title}
      </div>
    </div>
  );
});

import { FunctionComponent, memo, useContext } from 'react';
import { UserContext } from '../../../../context';
import { DeeplinkType, getDeepLinkUrl, StoryBlockData } from '../../../../shared';
import { EmbedLink } from '../EmbedLink';

export const Story: FunctionComponent<StoryBlockData> = memo(({ story }) => {
  const { channelId } = useContext(UserContext).userInfo.userData;

  if (!story) {
    return null;
  }

  const { id: storyId, title, subtitle: text, image } = story;

  const url = getDeepLinkUrl({ type: DeeplinkType.STORY, channelId, storyId });

  return <EmbedLink link={{ url, title, text, image }} hideUrl />;
});

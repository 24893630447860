/* eslint-disable react-hooks/exhaustive-deps */
import { Crisp } from 'crisp-sdk-web';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { DeepLinkModal, InAppMessageManager, Navbar, Sidebar, TermsModal } from '../../components';
import {
  ChannelBySubdomainContext,
  ConfigContext,
  MusicPlayerContext,
  MusicPlayerData,
  OrganisationContext,
  UserContext,
} from '../../context';
import { useGetConfigQuery, useOrganisationQuery, User } from '../../services';
import {
  getOrganisationDomain,
  MusicPlayer,
  ScrollToTop,
  useAnalytics,
  useAppSelector,
  useBeforeUnload,
  useConfig,
  useDuration,
  useFreeLoginPopup,
  useNotificationsPermissionPopup,
  useUserDevice,
} from '../../shared';
import { useLayoutData } from '../useLayoutData';
import { useFreeLogin } from './useFreeLogin';

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classNames from 'classnames';
import classes from './ReaderLayout.module.scss';

interface ReaderLayoutProps {
  rightSideModule?: JSX.Element;
  fullWidthContent?: boolean;
}

export const ReaderLayout: FunctionComponent<ReaderLayoutProps> = memo(
  ({ rightSideModule, fullWidthContent }) => {
    const { data: layoutData } = useLayoutData();

    const { userProfile, userInfo, channelBySubdomain } = { ...layoutData };

    const { channels } = useAppSelector(({ channels }) => channels);

    const { startTrackTime, getDuration } = useDuration();

    const { data: organisation } = useOrganisationQuery();

    const { data: config } = useGetConfigQuery({ organisationDomain: getOrganisationDomain() });

    useConfig(config);

    useUserDevice(config);

    useNotificationsPermissionPopup(config);

    const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup(config);

    useFreeLogin({ freeLoginHandler, userProfile, config });

    const { logSessionEnd } = useAnalytics(config);

    const { appTabs, allAppTabs, dashboardUrl } = channelBySubdomain ?? {
      appTabs: [],
      allAppTabs: [],
      dashboardUrl: null,
    };

    useEffect(() => {
      startTrackTime();

      if (Crisp?.chat && Crisp.chat.isVisible()) {
        Crisp.chat.hide();
      }
    }, []);

    useBeforeUnload(() =>
      logSessionEnd({
        channel_id: userInfo?.userData.channelId ?? 0,
        duration: getDuration(),
        time_stamp: new Date().toISOString(),
      })
    );

    const [userProfileData, setUserProfile] = useState<User | undefined>();

    useEffect(() => {
      if (!userProfile) {
        return;
      }

      setUserProfile(userProfile);
    }, [userProfile]);

    const [musicPlayerData, setMusicPlayerData] = useState<MusicPlayerData>({
      channelId: 0,
      storyId: 0,
      cardId: 0,
      cardTitle: '',
      src: '',
      isPlaying: false,
    });

    if (
      !userProfileData ||
      !userInfo ||
      !channels.length ||
      !config ||
      !channelBySubdomain ||
      !organisation
    ) {
      return null;
    }

    const { title, faviconUrl } = config.elements.meta;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <link rel={'icon'} href={faviconUrl} />
        </Helmet>

        <ScrollToTop />

        <UserContext.Provider value={{ userProfile: userProfileData, setUserProfile, userInfo }}>
          <ConfigContext.Provider value={{ config }}>
            <OrganisationContext.Provider value={{ organisation }}>
              <ChannelBySubdomainContext.Provider value={{ appTabs, allAppTabs, dashboardUrl }}>
                <MusicPlayerContext.Provider value={{ musicPlayerData, setMusicPlayerData }}>
                  <div className={classes['layout']}>
                    <Navbar />

                    <div className={classes['layout__page']}>
                      {!isMobileOnly && <Sidebar />}

                      <div
                        className={classNames(classes['layout__content'], {
                          [classes['layout__content--full-width']]: fullWidthContent,
                          [classes['layout__content--mobile']]: isMobileOnly,
                        })}
                      >
                        <Outlet />
                      </div>

                      {!isMobileOnly && rightSideModule}
                    </div>
                  </div>

                  <InAppMessageManager />

                  <TermsModal />

                  <DeepLinkModal />

                  {freeLoginModal}

                  <MusicPlayer />
                </MusicPlayerContext.Provider>
              </ChannelBySubdomainContext.Provider>
            </OrganisationContext.Provider>
          </ConfigContext.Provider>
        </UserContext.Provider>

        <ToastContainer
          autoClose={2000}
          position={'bottom-center'}
          transition={Slide}
          newestOnTop
          hideProgressBar={true}
          pauseOnHover={false}
          draggable={false}
          style={{ zIndex: 999999 }}
        />
      </>
    );
  }
);

export const toggleLabel = ({
  labels,
  setLabels,
  label,
}: {
  labels: string[];
  setLabels: (labels: string[]) => void;
  label: string;
}) => {
  const labelsCopy = [...labels];

  const index = labelsCopy.findIndex((labelCopy) => labelCopy === label);

  index === -1 ? labelsCopy.push(label) : labelsCopy.length > 1 && labelsCopy.splice(index, 1);

  setLabels(labelsCopy);
};

import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './NoContent.module.scss';

interface NoContentProps {
  subtitle?: string;
}

export const NoContent: FunctionComponent<NoContentProps> = memo(({ subtitle }) => {
  const { t } = useTranslation();

  return (
    <div className={classes['no-content']}>
      <div className={classes['no-content__page']}>
        <div className={classes['no-content__page-face']}>
          <span className={classes['no-content__page-eye']}></span>
          <span className={classes['no-content__page-eye']}></span>
          <div className={classes['no-content__page-smile']}></div>
        </div>
        <div className={classes['no-content__page-footer']}>
          <div className={classes['no-content__page-footer-stripe']}></div>
          <div className={classes['no-content__page-footer-stripe']}></div>
        </div>
      </div>
      <div className={classes['no-content__message']}>
        <div className={classes['no-content__message-title']}>{t('noContent.title')}</div>
        <div className={classes['no-content__message-subtitle']}>
          {subtitle ?? t('noContent.subtitle')}
        </div>
      </div>
    </div>
  );
});

import { FunctionComponent, memo } from 'react';
import { HeaderBlockData } from '../../../../shared';

import classNames from 'classnames';
import classes from './Header.module.scss';

export const Header: FunctionComponent<HeaderBlockData> = memo(({ text, level }) => {
  const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
    <HeaderTag
      className={classNames(classes['header'], classes[HeaderTag])}
      dangerouslySetInnerHTML={{ __html: text.replace(/href/g, "target='_blank' href") }}
    ></HeaderTag>
  );
});

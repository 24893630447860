import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AudioBlockData,
  FileStatus,
  ImageGallery,
  ImageGalleryItem,
  MediaUploadPreview,
  MusicPlayerTrigger,
} from '../../../../shared';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './Audio.module.scss';

interface AudioProps extends AudioBlockData {
  storyId?: number;
  cardId?: number;
  cardTitle?: string;
}

export const Audio: FunctionComponent<AudioProps> = memo(
  ({ items, storyId, cardId, cardTitle }) => {
    const { t } = useTranslation();

    if (!items.length) {
      return null;
    }

    const { audio, previewUrl, caption, copyright } = items[0];

    if (!audio) {
      return null;
    }

    const { url, status } = audio;

    if (status === FileStatus.PROCESSING) {
      return <Skeleton height={'3rem'} />;
    }

    if (!url) {
      return <MediaUploadPreview iconId={'audio'} label={t('richTextEditor.audio-placeholder')} />;
    }

    const imageGalleryItems: ImageGalleryItem[] = [
      { image: { url: previewUrl ?? '', rightholder: copyright ?? '' } },
    ];

    return (
      <div className={classes['audio']}>
        <ImageGallery items={imageGalleryItems} singleImageFullSize modalPreviewOnClick />
        <MusicPlayerTrigger
          storyId={storyId ?? 0}
          cardId={cardId ?? 0}
          cardTitle={cardTitle ?? ''}
          src={audio.url}
        />
        {caption && <div className={classes['audio__caption']}>{caption}</div>}
      </div>
    );
  }
);

import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../../../../context';
import { StoryCardImageContent } from '../../../../services';
import { useAnalytics } from '../../../hooks';
import { FileStatus } from '../../../models';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';
import { FeedCardProps } from '../model';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './FeedCardImageCommon.module.scss';

export const FeedCardImageCommon: FunctionComponent<FeedCardProps> = memo(
  ({ card, isProfileFeedCard }) => {
    const { channelId } = useContext(UserContext).userInfo.userData;

    const { logClickOpenArticle } = useAnalytics();

    const { id, storyId, content } = card;

    const { gallery } = content as StoryCardImageContent;

    const onLogClickOpenArticle = useCallback(() => {
      if (isProfileFeedCard) {
        return;
      }

      logClickOpenArticle({
        channel_id: channelId,
        story_id: storyId,
        item_id: id,
        time_stamp: new Date().toISOString(),
      });
    }, [channelId, id, isProfileFeedCard, logClickOpenArticle, storyId]);

    const imageContent = useMemo(() => {
      switch (true) {
        case Boolean(gallery.find(({ image: { status } }) => status === FileStatus.ERROR)):
          return (
            <FeedCardMargin top right bottom left>
              <FeedCardFileError />
            </FeedCardMargin>
          );
        case Boolean(gallery.find(({ image: { status } }) => status === FileStatus.PROCESSING)):
          return (
            <FeedCardMargin top right bottom left>
              <Skeleton height={'5rem'} />
            </FeedCardMargin>
          );
        default: {
          const imageGalleryItems: ImageGalleryItem[] = gallery.map(({ title, image }) => {
            return { caption: title, image };
          });

          return (
            <ImageGallery
              items={imageGalleryItems}
              captionClass={classes['caption']}
              modalPreviewOnClick
              onLogClickOpenArticle={onLogClickOpenArticle}
            />
          );
        }
      }
    }, [gallery, onLogClickOpenArticle]);

    return imageContent;
  }
);

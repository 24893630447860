import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { IS_PRODUCTION, TCHOP_ROOT_URL } from './env';
import { getI18nLocale } from './shared/utils';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: !IS_PRODUCTION,
    react: { useSuspense: false },
    supportedLngs: ['en-US', 'de-DE'],
    fallbackLng: 'en-US',
    load: 'currentOnly',
    backend: { loadPath: `${TCHOP_ROOT_URL}/api/translation?locale={{lng}}&project=webapp` },
    returnNull: false,
  });

i18n.changeLanguage(getI18nLocale());

export default i18n;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FileAcceptType,
  TransloaditAuthTemplate,
  UploadHandlerProps,
  UploadType,
} from '../../../../hooks';
import { VideoBlockData } from '../../../../models';
import { BaseBlockTool } from '../baseBlockTool';
import { MediaUpload, MediaUploadPreview, ResetBlockTool } from '../helpers';

interface State extends VideoBlockData {
  loading: boolean;
  pasteFile: File | null;
}

type Config = {
  dispatch: any;
  uploadHandler: (props: UploadHandlerProps) => Promise<{ id: number; assembly_id: string }>;
};

export class VideoTool extends BaseBlockTool<State, VideoBlockData, Config> {
  static get toolbox() {
    return {
      title: 'Video',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M21.03 10.5762C20.9526 10.2671 20.7951 9.98388 20.5732 9.75516C20.3513 9.52643 20.0731 9.36031 19.7664 9.27356C18.6462 9 14.165 9 14.165 9C14.165 9 9.68385 9 8.56356 9.29961C8.25695 9.38636 7.97865 9.55248 7.75679 9.78121C7.53493 10.0099 7.37735 10.2932 7.29998 10.6023C7.09495 11.7392 6.99466 12.8925 7.00037 14.0478C6.99306 15.2118 7.09336 16.3739 7.29998 17.5194C7.38528 17.8189 7.54639 18.0914 7.76773 18.3104C7.98908 18.5295 8.26318 18.6878 8.56356 18.7699C9.68385 19.0696 14.165 19.0696 14.165 19.0696C14.165 19.0696 18.6462 19.0696 19.7664 18.7699C20.0731 18.6832 20.3513 18.5171 20.5732 18.2883C20.7951 18.0596 20.9526 17.7764 21.03 17.4673C21.2335 16.3389 21.3337 15.1944 21.3296 14.0478C21.3369 12.8839 21.2366 11.7217 21.03 10.5762Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6973 16.1777L16.4424 14.0478L12.6973 11.918V16.1777Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  static get pasteConfig() {
    return { files: { mimeTypes: [FileAcceptType.VIDEO] } };
  }

  onPaste(event: any) {
    if (event.type !== 'file') {
      return;
    }

    this.setState({ pasteFile: event.detail.file });
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.items.length) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ items: [] })} />;
  }

  getJSXTool(): JSX.Element {
    const { loading, items, pasteFile } = this.state;

    const { video = null } = { ...items[0] };

    if (video) {
      return <MediaUploadPreview iconId={'video'} label={this.api.i18n.t('label')} />;
    }

    return (
      <MediaUpload
        {...(this.config as Config)}
        loading={loading}
        setLoading={(loading) => this.setState({ loading })}
        items={items}
        setItems={(items) => this.setState({ items } as VideoBlockData)}
        pasteFile={pasteFile}
        template={TransloaditAuthTemplate.FILE}
        uploadType={UploadType.VIDEO}
        fileAcceptType={FileAcceptType.VIDEO}
      />
    );
  }

  getDefaultState(data?: VideoBlockData): State {
    return { items: data?.items ?? [], loading: false, pasteFile: null };
  }

  validate({ items }: VideoBlockData): boolean {
    return Boolean(items.length);
  }

  save(): VideoBlockData {
    return { items: this.state.items };
  }
}

import {
  StoryCardAudioGalleryItem,
  StoryCardImageGalleryItem,
  StoryCardParseUrlPayload,
  StoryCardPdfGalleryItem,
  StoryCardType,
  StoryCardVideoGalleryItem,
} from '../../services';

export const getStoryCardParsedUrlFile = (parsedData: StoryCardParseUrlPayload) => {
  const { type, gallery } = parsedData;

  switch (type) {
    case StoryCardType.IMAGE:
      return (gallery[0] as StoryCardImageGalleryItem).image;
    case StoryCardType.AUDIO:
      return (gallery[0] as StoryCardAudioGalleryItem).audio;
    case StoryCardType.VIDEO:
      return (gallery[0] as StoryCardVideoGalleryItem).video;
    case StoryCardType.PDF:
      return (gallery[0] as StoryCardPdfGalleryItem).pdf;
  }
};

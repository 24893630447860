import { FunctionComponent, memo } from 'react';
import { StoryCardType } from '../../services';
import { FeedCardProps } from '../../shared';
import { FeedCardPreviewArticle } from './FeedCardPreviewArticle';
import { FeedCardPreviewEditorial } from './FeedCardPreviewEditorial';
import { FeedCardPreviewMedia } from './FeedCardPreviewMedia';
import { FeedCardPreviewPost } from './FeedCardPreviewPost';
import { FeedCardPreviewSocial } from './FeedCardPreviewSocial';
import { FeedCardPreviewThread } from './FeedCardPreviewThread';

export const FeedCardPreview: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  switch (card.type) {
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF:
      return <FeedCardPreviewMedia card={card} />;
    case StoryCardType.THREAD:
      return <FeedCardPreviewThread card={card} />;
    case StoryCardType.EDITORIAL:
      return <FeedCardPreviewEditorial card={card} />;
    case StoryCardType.QUOTE:
      return <FeedCardPreviewSocial card={card} />;
    case StoryCardType.ARTICLE:
      return <FeedCardPreviewArticle card={card} />;
    case StoryCardType.POST:
      return <FeedCardPreviewPost card={card} />;
    default:
      return null;
  }
});

import { Comment, StoryCard } from '../../services';

export const highlightCommentReducer = ({
  card,
  comment,
}: {
  card: StoryCard;
  comment: Comment;
}) => {
  const { comments } = card;
  const { id, isHighlighted } = comment;

  const commentToReset = comments.find(({ isHighlighted }) => isHighlighted);

  if (commentToReset) {
    commentToReset.isHighlighted = false;
  }

  const commentToUpdate = comments.find((comment) => comment.id === id);

  if (!commentToUpdate) {
    return;
  }

  commentToUpdate.isHighlighted = isHighlighted;
};

import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const Wow = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <circle
        cx={15}
        cy={15}
        r={14.5}
        fill="#FFD972"
        stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
      />
      <g>
        <ellipse cx={15.1458} cy={20.396} rx={4.22917} ry={5.6875} fill="#262C39" />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.30029 14.4167C9.34733 14.4167 10.3574 13.1761 10.5564 11.6458C10.7554 10.1155 10.0679 8.875 9.02088 8.875C7.97384 8.875 6.96374 10.1155 6.76475 11.6458C6.56577 13.1761 7.25325 14.4167 8.30029 14.4167Z"
            fill="#262C39"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8543 14.4167C20.8072 14.4167 19.7971 13.1761 19.5981 11.6458C19.3991 10.1155 20.0866 8.875 21.1337 8.875C22.1807 8.875 23.1908 10.1155 23.3898 11.6458C23.5888 13.1761 22.9013 14.4167 21.8543 14.4167Z"
            fill="#262C39"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.021 4.40478C11.3844 4.82681 10.5788 5.34256 10.0318 5.23801C9.65334 5.16567 9.24574 5.11952 8.93219 5.16452C8.54327 5.22034 8.07689 5.46447 7.70222 5.69904C7.3483 5.92063 6.85929 5.79492 6.69656 5.41037C6.58806 5.15397 6.66152 4.85746 6.8937 4.70382C7.33245 4.41349 8.10111 3.98172 8.93219 3.8678C10.2159 3.69184 10.9086 4.27416 11.021 4.40478Z"
            fill="#262C39"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2707 4.40478C18.9074 4.82681 19.7129 5.34256 20.2599 5.23801C20.6384 5.16567 21.046 5.11952 21.3596 5.16452C21.7485 5.22034 22.2149 5.46446 22.5895 5.69904C22.9434 5.92063 23.4325 5.79492 23.5952 5.41037C23.7037 5.15397 23.6302 4.85746 23.398 4.70382C22.9593 4.41349 22.1906 3.98172 21.3596 3.8678C20.0758 3.69184 19.3832 4.27416 19.2707 4.40478Z"
            fill="#262C39"
          />
        </g>
      </g>
    </g>
  </svg>
);

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import React, {
  Dispatch,
  forwardRef,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EmojiSelectProps } from '..';
import { CommentAttachment } from '../../../../../services';
import {
  FileAcceptType,
  ImageUploadType,
  TransloaditAuthTemplate,
  useLoaderText,
  useOnClickOutside,
  useUpload,
} from '../../../../hooks';
import { getAcceptTypeByFileType, getCssVar, getEmojiPickerLocale } from '../../../../utils';
import { CircularLoader } from '../../../CircularLoader';
import { IconLabel } from '../../../IconLabel';
import { TextAreaField } from '../../../TextAreaField';
import { CommentAttachments } from '../../CommentAttachments';

import classes from './CommentInput.module.scss';

interface CommentInputProps {
  text: string;
  setText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  attachments: CommentAttachment[];
  setAttachments: (attachments: CommentAttachment[]) => void;
  onEmojiSelect: (emoji: EmojiSelectProps) => void;
  onSubmit: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

export const CommentInput = memo(
  forwardRef<HTMLTextAreaElement, CommentInputProps>(
    (
      {
        text,
        setText,
        attachments,
        setAttachments,
        onEmojiSelect,
        onSubmit,
        loading,
        setLoading,
        onClose,
      },
      ref
    ) => {
      const { t } = useTranslation();

      const { uploadHandler, getUploadType } = useUpload();

      const { loaderText } = useLoaderText(loading);

      const [showEmojiPicker, setShowEmojiPicker] = useState(false);

      const emojiPickerRef = useRef(null);

      const fileRef = useRef<HTMLInputElement | null>(null);

      useOnClickOutside(emojiPickerRef, () => {
        setShowEmojiPicker(false);
      });

      const onKeyDownHandler = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          const { key, shiftKey, target } = event;

          if (key === 'Enter' && !shiftKey) {
            event.preventDefault();
            onSubmit();

            (target as HTMLTextAreaElement).blur();
          }

          if (key === 'Escape') {
            onClose?.();
          }
        },
        [onClose, onSubmit]
      );

      const getAttachment = useCallback(
        ({ id, file, acceptType }: { id: number; file: File; acceptType: FileAcceptType }) => {
          const originalFilename = file.name;

          switch (acceptType) {
            case FileAcceptType.IMAGE:
              return {
                imageId: id,
                image: { id, originalFilename, url: URL.createObjectURL(file) },
              };
            case FileAcceptType.VIDEO:
              return {
                videoId: id,
                video: { id, originalFilename },
              };
          }
        },
        []
      );

      const onFileChange = useCallback(
        async (files: FileList | null) => {
          if (!files?.length) {
            return;
          }

          setLoading(true);

          const uploadedFiles = [];

          for (const file of files) {
            const { type } = file;

            const fileType = type.includes('application/') ? type : type.split('/')[0];

            const acceptType = getAcceptTypeByFileType(fileType);

            if (!acceptType) {
              toast.error(t('fileUpload.file-type-error'));
              continue;
            }

            const { id } = await uploadHandler({
              file,
              template: TransloaditAuthTemplate.COMMENT_FILE,
              type: getUploadType({ acceptType }),
              ...(acceptType === FileAcceptType.IMAGE && {
                imageUploadType: ImageUploadType.COMMENT,
              }),
            });

            uploadedFiles.push({ ...getAttachment({ id, file, acceptType }) });

            setAttachments([...attachments, ...uploadedFiles] as CommentAttachment[]);
          }

          setLoading(false);
        },
        [attachments, getAttachment, getUploadType, setAttachments, setLoading, t, uploadHandler]
      );

      const sendComponent = useMemo(() => {
        if (loading) {
          return (
            <CircularLoader
              sizeRem={1.25}
              className={classes['comment-input__icons-loader']}
              text={loaderText}
            />
          );
        }

        if (!text.trim().length && !attachments.length) {
          return null;
        }

        return (
          <IconLabel
            iconId={'send'}
            iconSize={20}
            className={classes['comment-input__icons-send']}
            color={getCssVar('--comments-input-icon-color')}
            hoverColor={getCssVar('--comments-input-icon-hover-color')}
            onClick={onSubmit}
          />
        );
      }, [attachments.length, loaderText, loading, onSubmit, text]);

      return (
        <div className={classes['comment-input']}>
          <input
            type={'file'}
            ref={fileRef}
            disabled={loading}
            accept={`${FileAcceptType.IMAGE}, ${FileAcceptType.VIDEO}`}
            className={classes['comment-input__file']}
            onChange={({ target }) => onFileChange(target.files)}
            onClick={({ target }) => ((target as HTMLInputElement).value = '')}
          />

          <TextAreaField
            ref={ref}
            inputClassName={classes['comment-input__field']}
            value={text}
            onChange={setText}
            onKeyDown={onKeyDownHandler}
            placeholder={t('comments.placeholder')}
            disabled={loading}
          />

          <CommentAttachments attachments={attachments} setAttachments={setAttachments} />

          <div className={classes['comment-input__icons']}>
            <IconLabel
              iconId={'mood'}
              iconSize={20}
              color={getCssVar('--comments-input-icon-color')}
              hoverColor={getCssVar('--comments-input-icon-hover-color')}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              disabled={loading}
            />

            {Boolean(!attachments.length) && (
              <IconLabel
                iconId={'image'}
                iconSize={20}
                color={getCssVar('--comments-input-icon-color')}
                hoverColor={getCssVar('--comments-input-icon-hover-color')}
                onClick={() => fileRef.current?.click()}
                disabled={loading}
              />
            )}

            {onClose && (
              <IconLabel
                iconId={'close'}
                iconSize={20}
                color={getCssVar('--comments-input-icon-color')}
                hoverColor={getCssVar('--comments-input-icon-hover-color')}
                onClick={onClose}
                disabled={loading}
              />
            )}

            {sendComponent}
          </div>

          {showEmojiPicker && (
            <div ref={emojiPickerRef} className={classes['comment-input__emoji-picker']}>
              <Picker
                data={data}
                locale={getEmojiPickerLocale()}
                onEmojiSelect={onEmojiSelect}
                maxFrequentRows={3}
                perLine={isMobileOnly ? 6 : 10}
                emojiSize={18}
                previewPosition={'none'}
                searchPosition={'static'}
                navPosition={'bottom'}
              />
            </div>
          )}
        </div>
      );
    }
  )
);

import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import sha1 from 'sha1';
import { UserContext } from '../../../../context';
import { useAnalytics } from '../../../hooks';
import { getCssVar } from '../../../utils';
import { Deeplink, DeeplinkType } from '../../Deeplink';
import { DropdownMenu } from '../../DropdownMenu';
import { IconLabel } from '../../IconLabel';
import { Popup } from '../../Popup';

import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';

interface ShareProps {
  storyId: number;
  cardId: number;
  articleQuoteUrl?: string;
  pdfUrl?: string;
}

export const Share: FunctionComponent<ShareProps> = memo(
  ({ storyId, cardId, articleQuoteUrl, pdfUrl }) => {
    const { t } = useTranslation();

    const { id: userId, channelId } = useContext(UserContext).userInfo.userData;

    const { logItemShared } = useAnalytics();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const url = useMemo(() => articleQuoteUrl ?? pdfUrl, [articleQuoteUrl, pdfUrl]);

    const logItemShare = useCallback(() => {
      logItemShared({
        user_Id: sha1(`${userId}`),
        channel_id: channelId,
        story_id: storyId,
        item_id: cardId,
        time_stamp: new Date().toISOString(),
      });
    }, [cardId, channelId, logItemShared, storyId, userId]);

    const deeplinkClick = useCallback(() => {
      setIsMenuOpen(false);
      logItemShare();
    }, [logItemShare]);

    const copySourceClick = useCallback(async () => {
      setIsMenuOpen(false);

      if (!navigator.clipboard || !url) {
        return;
      }

      await navigator.clipboard.writeText(url);

      toast(t(`cardActions.${pdfUrl ? 'pdf' : 'source'}-url-copied`));

      logItemShare();
    }, [logItemShare, pdfUrl, t, url]);

    return (
      <Popup
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        iconSize={20}
        iconId={'share'}
        color={getCssVar('--action-pane-icon-color')}
        hoverColor={getCssVar('--action-pane-icon-hover-color')}
        bodyTop={'1.5rem'}
        bodyLeft={'0'}
        body={
          <>
            <DropdownMenu
              width={'13rem'}
              content={
                <Deeplink
                  type={DeeplinkType.CARD}
                  storyId={storyId}
                  cardId={cardId}
                  className={dropdownMenuClasses['dropdown-menu__item--small']}
                  label={t('cardActions.deeplink')}
                  onClick={deeplinkClick}
                />
              }
            />
            {url && (
              <IconLabel
                iconId={'copy'}
                iconSize={18}
                label={t(`cardActions.copy-${pdfUrl ? 'pdf' : 'source'}-url`)}
                className={dropdownMenuClasses['dropdown-menu__item--small']}
                onClick={copySourceClick}
                singleColor
              />
            )}
          </>
        }
        {...(!isMobileOnly && { label: t('cardActions.share') })}
      />
    );
  }
);

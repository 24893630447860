import { useTranslation } from 'react-i18next';
import {
  StoryCard,
  StoryCardContentText,
  StoryCardContentTitle,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../services';
import { stripHtmlString } from '../utils';

export const useCardTitle = (card: StoryCard) => {
  const { t } = useTranslation();

  const { type, content } = card;

  const typeLocalized = t(`cardTypes.${type.toLowerCase()}`);

  switch (type) {
    case StoryCardType.EDITORIAL:
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF: {
      const { headline, textHTML } = content as StoryCardContentText;
      return headline || stripHtmlString(textHTML) || typeLocalized;
    }
    case StoryCardType.ARTICLE:
    case StoryCardType.POST:
    case StoryCardType.THREAD: {
      const { title } = content as StoryCardContentTitle;
      return title || typeLocalized;
    }
    case StoryCardType.QUOTE: {
      const { quote } = content as StoryCardQuoteContent;
      return quote || typeLocalized;
    }
    default:
      return typeLocalized;
  }
};

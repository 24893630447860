import { ResponsiveBar } from '@nivo/bar';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticChartInterval, useUserSessionQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, getBarsLayer, getBestValues } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserSessionByHour: FunctionComponent<UserProps> = memo((userProps) => {
  const { t } = useTranslation();

  const { data: userSessionByHour, isFetching: isFetchingUserSessionByHour } = useUserSessionQuery({
    ...userProps,
    interval: AnalyticChartInterval.HOUR_OF_DAY,
  });

  const formatter = useCallback((value: string) => format(parseISO(value), 'HH:00'), []);

  const renderChart = useMemo(() => {
    if (isFetchingUserSessionByHour) {
      return <Loader />;
    }

    if (!userSessionByHour) {
      return null;
    }

    const { datasets, intervals } = userSessionByHour;

    if (!datasets.length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const values = datasets[0].data;

    const data = values.map((value, index) => {
      return { interval: intervals[index], value };
    });

    const bestValues = getBestValues({ values, intervals, formatter });

    return (
      <>
        {bestValues && (
          <div className={classes['analytics__chart-best']}>
            <div className={classes['analytics__chart-best-label']}>
              {t('userSessionByHourOfDay.best-label')}
            </div>
            <div className={classes['analytics__chart-best-value']}>{bestValues}</div>
          </div>
        )}
        <div className={classes['analytics__chart-height']}>
          <ResponsiveBar
            margin={{ top: 40, right: 40, bottom: 50, left: 40 }}
            padding={0.5}
            borderRadius={4}
            tooltip={() => <></>}
            data={data}
            keys={['value']}
            indexBy={'interval'}
            colors={[COLORS[0]]}
            axisLeft={{ format: (value) => (value === Math.floor(value) ? value : '') }}
            axisBottom={{ format: (value) => formatter(value), tickRotation: -45 }}
            label={''}
            layers={['grid', 'axes', 'bars', ({ bars }) => getBarsLayer({ bars })]}
          />
        </div>
      </>
    );
  }, [formatter, isFetchingUserSessionByHour, t, userSessionByHour]);
  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-session-by-hour-of-day'}
      infoLocaliseKey={'userSessionByHourOfDay.info'}
    >
      {renderChart}
    </ChartWrapper>
  );
});

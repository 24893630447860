import { FunctionComponent, memo } from 'react';
import { StoryCardImageContent } from '../../../services';
import { FeedCardContent, FeedCardImageCommon, FeedCardProps } from '../../../shared';
import { FeedCardWrapper } from '../FeedCardWrapper';

export const FeedCardImage: FunctionComponent<FeedCardProps> = memo((props) => {
  const { card } = props;
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardImageContent;

  return (
    <FeedCardWrapper {...props}>
      <FeedCardContent
        storyId={storyId}
        cardId={id}
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={headline}
        media={<FeedCardImageCommon {...props} />}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      />
    </FeedCardWrapper>
  );
});

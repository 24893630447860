import { FunctionComponent, memo } from 'react';
import { StoryCardPdfContent } from '../../../services';
import { FeedCardContent, FeedCardPdfCommon, FeedCardProps } from '../../../shared';
import { FeedCardWrapper } from '../FeedCardWrapper';

export const FeedCardPdf: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardPdfContent;

  return (
    <FeedCardWrapper card={card}>
      <FeedCardContent
        storyId={storyId}
        cardId={id}
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={headline}
        media={<FeedCardPdfCommon card={card} />}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      />
    </FeedCardWrapper>
  );
});

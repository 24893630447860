import { FunctionComponent, memo } from 'react';
import { AnalyticChartPlatform } from '../../../services';
import { ChartType } from '../Analytics';
import { useChartExclude } from '../helpers';
import { ChannelList } from './ChannelList';
import { CommentTop } from './CommentTop';
import { ContentActivity } from './ContentActivity';
import { ContentInteraction } from './ContentInteraction';
import { ContentList } from './ContentList';
import { ContentTop } from './ContentTop';
import { InAppNotificationList } from './InAppNotificationList';
import { PushNotificationList } from './PushNotificationList';
import { StoryList } from './StoryList';

export interface ContentProps {
  from: string;
  to: string;
  channelId?: number[];
  platform?: AnalyticChartPlatform[];
  organisationTags?: {
    externalTagsId: number[] | null;
    internalTagsId: number[] | null;
  };
}

export const Content: FunctionComponent<ContentProps> = memo((contentProps) => {
  const excludeContentActivity = useChartExclude(ChartType.CONTENT_AND_ENGAGEMENT);
  const excludeContentInteraction = useChartExclude(ChartType.CONTENT_WITH_INTERACTIONS);
  const excludeContentTop = useChartExclude(ChartType.CONTENT_TOP_5);
  const excludeCommentTop = useChartExclude(ChartType.COMMENT_TOP_5);
  const excludeContentList = useChartExclude(ChartType.CONTENT);
  const excludeChannelList = useChartExclude(ChartType.CHANNELS);
  const excludeStorylList = useChartExclude(ChartType.MIXES);
  const excludePushNotificationsList = useChartExclude(ChartType.PUSH_NOTIFICATIONS);
  const excludeInAppNotificationsList = useChartExclude(ChartType.IN_APP_NOTIFICATIONS);

  return (
    <>
      {!excludeContentActivity && <ContentActivity {...contentProps} />}
      {!excludeContentInteraction && <ContentInteraction {...contentProps} />}
      {!excludeContentTop && <ContentTop {...contentProps} />}
      {!excludeCommentTop && <CommentTop {...contentProps} />}
      {!excludeContentList && <ContentList {...contentProps} />}
      {!excludeChannelList && <ChannelList {...contentProps} />}
      {!excludeStorylList && <StoryList {...contentProps} />}
      {!excludePushNotificationsList && <PushNotificationList {...contentProps} />}
      {!excludeInAppNotificationsList && <InAppNotificationList {...contentProps} />}
    </>
  );
});

import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from '../../context';
import { Config } from '../../services';

enum AnalyticsEventName {
  item_shared = 'item_shared',
  item_played = 'item_played',
  click_open_article = 'click_open_article',
  click_open_mix = 'click_open_mix',
  push_open = 'push_open',
  session_end = 'session_end',
  iam = 'iam',
}

interface ItemSharedPayload {
  user_Id: string;
  channel_id: number;
  story_id: number;
  item_id: number;
  time_stamp: string;
}

interface ItemPlayedPayload {
  channel_id: number;
  story_id: number;
  item_id: number;
  duration: number;
  time_stamp: string;
}

interface ClickOpenArticlePayload {
  channel_id: number;
  story_id: number;
  item_id: number;
  time_stamp: string;
}

interface ClickOpenStoryPayload {
  channel_id: number;
  story_id: number;
  mix_id: number;
  duration: number;
  time_stamp: string;
}

interface PushOpenPayload {
  channel_id: number;
  notification_id: number;
  time_stamp: string;
}

interface SessionEndPayload {
  channel_id: number;
  duration: number;
  time_stamp: string;
}

interface InAppMessageViewPayload {
  iam_id: number;
  iam_action: 'primary' | 'secondary';
  time_stamp: string;
}

export const useAnalytics = (queriedConfig?: Config) => {
  const { config: contextConfig } = useContext(ConfigContext);

  const config = useMemo(() => queriedConfig ?? contextConfig, [contextConfig, queriedConfig]);

  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    if (!config) {
      return;
    }

    const { firebaseAnalytics } = config.elements;

    if (!firebaseAnalytics) {
      return;
    }

    setAnalytics(getAnalytics(initializeApp(firebaseAnalytics)));
  }, [config]);

  const logItemShared = useCallback(
    (payload: ItemSharedPayload) => {
      if (!analytics) {
        return;
      }
      console.log('logItemShared');
      logEvent(analytics, AnalyticsEventName.item_shared, payload);
    },
    [analytics]
  );

  const logItemPlayed = useCallback(
    (payload: ItemPlayedPayload) => {
      if (!analytics || !payload.duration) {
        return;
      }
      console.log('logItemPlayed', payload.duration + ' seconds');
      logEvent(analytics, AnalyticsEventName.item_played, payload);
    },
    [analytics]
  );

  const logClickOpenArticle = useCallback(
    (payload: ClickOpenArticlePayload) => {
      if (!analytics) {
        return;
      }
      console.log('logClickOpenArticle');
      logEvent(analytics, AnalyticsEventName.click_open_article, payload);
    },
    [analytics]
  );

  const logClickOpenStory = useCallback(
    (payload: ClickOpenStoryPayload) => {
      if (!analytics || !payload.duration) {
        return;
      }
      console.log('logClickOpenStory', payload.duration + ' seconds');
      logEvent(analytics, AnalyticsEventName.click_open_mix, payload);
    },
    [analytics]
  );

  const logPushOpen = useCallback(
    (payload: PushOpenPayload) => {
      if (!analytics) {
        return;
      }
      console.log('logPushOpen');
      logEvent(analytics, AnalyticsEventName.push_open, payload);
    },
    [analytics]
  );

  const logSessionEnd = useCallback(
    (payload: SessionEndPayload) => {
      if (!analytics || !payload.duration) {
        return;
      }
      console.log('logSessionEnd', payload.duration + ' seconds');
      logEvent(analytics, AnalyticsEventName.session_end, payload);
    },
    [analytics]
  );

  const logInAppMessageView = useCallback(
    (payload: InAppMessageViewPayload) => {
      if (!analytics) {
        return;
      }
      console.log('logInAppMessageView');
      logEvent(analytics, AnalyticsEventName.iam, payload);
    },
    [analytics]
  );

  return {
    logItemShared,
    logItemPlayed,
    logClickOpenArticle,
    logClickOpenStory,
    logPushOpen,
    logSessionEnd,
    logInAppMessageView,
  };
};

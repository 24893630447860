import { FunctionComponent, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import { User, useUserByIdQuery } from '../../../services';
import { PersonalInfo } from '../PersonalInfo';
import { PostStats } from '../PostStats';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import classes from './ProfileRightSide.module.scss';

export const ProfileRightSide: FunctionComponent = () => {
  const { userProfile, userInfo } = useContext(UserContext);

  const { isAnonymous } = userProfile;

  const { id } = useParams();

  const isProfileOther = useMemo(() => Boolean(id), [id]);

  const { data: profileOther, isFetching: isFetchingCollaboratorProfile } = useUserByIdQuery(
    { userId: Number(id) },
    { skip: !isProfileOther }
  );

  if (isFetchingCollaboratorProfile) {
    return (
      <div className={classes['profile-right-side__loader']}>
        <Skeleton width={'100%'} height={'10rem'} />
      </div>
    );
  }

  if (isAnonymous || (isProfileOther && !profileOther)) {
    return null;
  }

  const {
    id: userId,
    email,
    phone,
    position,
    department,
    location,
    subscription,
    organisationTags,
  } = (profileOther ?? userProfile) as User;

  return (
    <div
      className={classNames(classes['profile-right-side'], {
        [classes['profile-right-side--mobile']]: isMobileOnly,
      })}
    >
      <PersonalInfo
        email={email}
        phone={phone}
        position={position}
        department={department}
        subscription={subscription}
        location={location.text}
        organisationTags={organisationTags}
      />
      {!profileOther && <PostStats channelId={userInfo.userData.channelId} userId={userId} />}
    </div>
  );
};

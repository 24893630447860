/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { EmbedLink } from '../../../../../components';
import {
  StoryCardArticleParsedUrl,
  StoryCardParseUrlPayload,
  StoryCardQuoteParsedUrl,
  StoryCardType,
  storyCardApi,
} from '../../../../../services';
import { EmbedLinkBlockData, LinkData } from '../../../../models';
import { isUrl } from '../../../../utils';
import { InputField } from '../../../InputField';
import { BaseBlockTool } from '../baseBlockTool';
import { ResetBlockTool } from '../helpers';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface State {
  url: string;
  link: LinkData | null;
  loading: boolean;
  invalidUrl: boolean;
}

interface ProcessParsedDataProps {
  url: string;
  parsedData: StoryCardParseUrlPayload;
}

type Config = {
  dispatch: any;
};

export class EmbedLinkTool extends BaseBlockTool<State, EmbedLinkBlockData, Config> {
  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {
      title: 'EmbedLink',
      icon: `
        <svg height="24" viewBox="0 0 24 24" width="24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M19 15v4H5v-4h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7 18.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM19 5v4H5V5h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 8.5c-.82 0-1.5-.67-1.5-1.5S6.18 5.5 7 5.5s1.5.68 1.5 1.5S7.83 8.5 7 8.5z"/>
        </svg>`,
    };
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.url) {
      return null;
    }

    return (
      <ResetBlockTool
        onClick={() =>
          this.setState({
            url: '',
            link: null,
            loading: false,
            invalidUrl: false,
          })
        }
      />
    );
  }

  private processParsedData = ({ url, parsedData }: ProcessParsedDataProps) => {
    const { type } = parsedData;

    if (![StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type)) {
      this.setState({ invalidUrl: true });
      return;
    }

    const link: LinkData = { url } as LinkData;

    const { gallery } = parsedData;

    if (gallery[0]?.image) {
      link.image = gallery[0].image;
    }

    switch (type) {
      case StoryCardType.ARTICLE: {
        const { title, abstract, sourceName } = parsedData as StoryCardArticleParsedUrl;

        link.title = title;
        link.text = abstract;
        link.sourceName = sourceName;
        break;
      }
      case StoryCardType.QUOTE: {
        const { quote, quotePerson, quotePersonHandle, quoteSource } =
          parsedData as StoryCardQuoteParsedUrl;

        link.title = quotePerson;
        link.text = quote;
        link.sourceName = quotePersonHandle;
        link.quoteSource = quoteSource;
        break;
      }
    }

    this.setState({ link });
  };

  private inputChangeHandler = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    this.setState({ url: value, invalidUrl: false });

    if (!value) {
      return;
    }

    if (!isUrl(value)) {
      this.setState({ invalidUrl: true });
      return;
    }

    this.setState({ loading: true });

    const parsedData = await this.config
      .dispatch(storyCardApi.endpoints.storyCardParseUrl.initiate({ url: value }))
      .unwrap();

    !parsedData
      ? this.setState({ invalidUrl: true })
      : this.processParsedData({ url: value, parsedData });

    this.setState({ loading: false });
  };

  getJSXTool(): JSX.Element {
    const { url, link, loading, invalidUrl } = this.state;

    if (loading) {
      return <Skeleton height={'3rem'} style={{ zIndex: '-1' }} />;
    }

    if (link) {
      return <EmbedLink link={link} />;
    }

    return (
      <InputField
        value={url}
        placeholder={'https://'}
        onChange={this.inputChangeHandler}
        error={invalidUrl}
        errorMessage={'Invalid Url'}
        disabled={loading}
      />
    );
  }

  getDefaultState(data?: EmbedLinkBlockData): State {
    return {
      url: '',
      link: data?.link ?? null,
      loading: false,
      invalidUrl: false,
    };
  }

  validate({ link }: EmbedLinkBlockData): boolean {
    return Boolean(link);
  }

  save(): EmbedLinkBlockData {
    return { link: this.state.link, caption: '' };
  }
}

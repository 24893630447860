import { FunctionComponent } from 'react';
import { IconLabel } from '../../IconLabel';
import { useTranslation } from 'react-i18next';

import classes from './FeedCardFileError.module.scss';

export const FeedCardFileError: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={classes['file-error']}>
      <IconLabel iconId={'file-error'} iconSize={48} nonClickable />
      <div className={classes['file-error__message']}>{t('fileUpload.error')}</div>
    </div>
  );
};

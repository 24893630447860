import InfiniteScroll from 'react-infinite-scroll-component';
import { FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react';
import { NoContent } from '../../Error';
import { FeedCard } from '../../../components';
import { FeedCardLoader, useAppDispatch, useAppSelector } from '../../../shared';
import { getStoryFeed, getStoryFeedLoadMore } from '../../../slices';
import { StoryCardStatus } from '../../../services';

import classes from './StoryFeed.module.scss';

interface StoryFeedProps {
  storyId: number;
}

export const StoryFeed: FunctionComponent<StoryFeedProps> = memo(({ storyId }) => {
  const dispatch = useAppDispatch();

  const {
    storyFeed: cards,
    page,
    hasNextPage,
    isFetching,
  } = useAppSelector(({ storyFeed }) => storyFeed);

  const filter = useMemo(() => {
    return { status: [StoryCardStatus.PUBLISHED] };
  }, []);

  useEffect(() => {
    dispatch(getStoryFeed({ storyId, filter }));
  }, [dispatch, filter, storyId]);

  const loadMore = useCallback(() => {
    dispatch(getStoryFeedLoadMore({ storyId, page: page + 1, filter }));
  }, [dispatch, filter, page, storyId]);

  const hasCards = useMemo(() => Boolean(cards.length), [cards.length]);

  const content = useMemo(() => {
    if (!hasCards) {
      return <NoContent />;
    }

    return (
      <InfiniteScroll
        next={loadMore}
        hasMore={hasNextPage}
        loader={<FeedCardLoader />}
        dataLength={cards.length}
        className={classes['story-feed']}
      >
        <div className={classes['story-feed__cards']}>
          {cards.map((card) => (
            <FeedCard key={card.id} card={card} />
          ))}
        </div>
      </InfiniteScroll>
    );
  }, [cards, hasCards, hasNextPage, loadMore]);

  if (isFetching) {
    return <FeedCardLoader />;
  }

  return content;
});

import { FunctionComponent, PropsWithChildren, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';
import classes from './MobileSlidePanel.module.scss';

interface MobileSlidePanelProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MobileSlidePanel: FunctionComponent<PropsWithChildren<MobileSlidePanelProps>> = memo(
  ({ isOpen, onClose, children }) => {
    const location = useLocation();

    useEffect(() => {
      if (isOpen) {
        onClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      }

      return () => {
        if (!document.querySelectorAll('.modal-content').length) {
          document.body.style.overflow = 'auto';
        }
      };
    }, [isOpen]);

    return (
      <div
        className={classNames(classes['panel'], {
          [classes['panel--open']]: isOpen,
        })}
      >
        <div className={classes['panel__overlay']} onClick={onClose}></div>
        <div className={classes['panel__content']}>{children}</div>
      </div>
    );
  }
);

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedCardWrapper } from '../HorizontalStoryFeedCardWrapper';
import {
  StoryCardImageContent,
  StoryCardAudioContent,
  StoryCardVideoContent,
  StoryCardPdfContent,
} from '../../../../services';

export const HorizontalStoryFeedMedia: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { content, isPinnedToTopOfNewsFeed } = card;
    const { headline, subHeadline, sourceName, text, textHTML } = content as
      | StoryCardImageContent
      | StoryCardAudioContent
      | StoryCardVideoContent
      | StoryCardPdfContent;

    return (
      <HorizontalStoryFeedCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          source={sourceName}
          title={headline}
          subTitle={subHeadline}
          text={text}
          htmlText={textHTML}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedCardWrapper>
    );
  }
);

import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { OtherProps } from '../Other';
import { ChartWrapper, Loader, StatsItem, TimeSeriesChart } from '../../helpers';
import { AnalyticChartInterval, useInstallUninstallAppQuery } from '../../../../services';
import { differenceInDays, subDays } from 'date-fns';
import { COLORS } from '../../Analytics';

import classes from '../../Analytics.module.scss';

export const InstallUninstall: FunctionComponent<OtherProps> = memo(({ from, to }) => {
  const { data: installUninstallApp, isFetching: isFetchingInstallUninstallApp } =
    useInstallUninstallAppQuery({
      from,
      to,
      interval: AnalyticChartInterval.AUTO,
    });

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: installUninstallAppPrev, isFetching: isFetchingInstallUninstallAppPrev } =
    useInstallUninstallAppQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
    });

  const [installUninstallAppLabels, setInstallUninstallAppLabels] = useState<string[]>([]);

  useEffect(() => {
    if (installUninstallApp && !installUninstallAppLabels.length) {
      setInstallUninstallAppLabels(installUninstallApp.labels);
    }
  }, [installUninstallApp, installUninstallAppLabels.length]);

  const renderStats = useMemo(() => {
    if (isFetchingInstallUninstallApp || isFetchingInstallUninstallAppPrev) {
      return <Loader stats />;
    }

    if (!installUninstallApp || !installUninstallAppPrev) {
      return null;
    }

    const { datasets } = installUninstallApp;
    const { datasets: datasetsPrev } = installUninstallAppPrev;

    return (
      <div className={classes['analytics__chart-stats']}>
        {datasets.map(({ label, data }, index) => {
          const isDisabled = !installUninstallAppLabels.includes(label);
          const isClickable = installUninstallAppLabels.length > 1 || isDisabled;

          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`installUninstallAppLabels.${label}`}
              color={COLORS[index]}
              value={data.reduce((partialSum, value) => partialSum + value, 0)}
              valuePrev={datasetsPrev.find((set) => set.label === label)?.data[0] ?? 0}
              labels={installUninstallAppLabels}
              setLabels={setInstallUninstallAppLabels}
              isDisabled={isDisabled}
              isClickable={isClickable}
            />
          );
        })}
      </div>
    );
  }, [
    installUninstallApp,
    installUninstallAppLabels,
    installUninstallAppPrev,
    isFetchingInstallUninstallApp,
    isFetchingInstallUninstallAppPrev,
  ]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.install-uninstall-app'}
      infoLocaliseKey={'installUninstallApp.info'}
    >
      {renderStats}
      {installUninstallApp && (
        <TimeSeriesChart
          timeSeries={installUninstallApp}
          labels={installUninstallAppLabels}
          localiseKeyLabels={'installUninstallAppLabels'}
          loading={isFetchingInstallUninstallApp}
        />
      )}
    </ChartWrapper>
  );
});

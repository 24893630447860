import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedSocialCommon } from '../../../../shared';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardQuoteContent } from '../../../../services';

export const HorizontalStoryFeedAdminSocial: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { content } = card;
    const { quote, quoteSource, quotePersonHandle } = content as StoryCardQuoteContent;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedSocialCommon
          quote={quote}
          quoteSource={quoteSource}
          quotePersonHandle={quotePersonHandle}
        />
      </HorizontalStoryFeedAdminCardWrapper>
    );
  }
);

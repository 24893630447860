import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  mode?: EmojiModes;
  size?: number | string;
}

export const Angry = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={15}
      cy={15}
      r={14.5}
      fill="url(#prefix__paint0_linear_2702_8686)"
      stroke={mode === 'light' ? 'white' : '#0A0904'}
    />
    <ellipse cx={15} cy={23.452} rx={4.375} ry={0.881} fill="#262C39" />
    <circle cx={8.875} cy={18.792} fill="#262C39" r={1.458} />
    <circle r={1.458} transform="matrix(-1 0 0 1 21.125 18.792)" fill="#262C39" />
    <path
      d="M4.76 16.644c.217.186.602.447 1.135.704a6.843 6.843 0 002.137.629c.439.054.95.105 1.519.154a90.123 90.123 0 003.103.211.583.583 0 00.058-1.165 98.742 98.742 0 01-3.06-.208 41.76 41.76 0 01-1.478-.15 5.683 5.683 0 01-2.445-.907 2.73 2.73 0 01-.212-.155.583.583 0 10-.758.887zM25.24 16.644c-.217.186-.602.447-1.135.704a6.844 6.844 0 01-2.137.629c-.439.054-.95.105-1.519.154a90.128 90.128 0 01-3.103.211.583.583 0 01-.058-1.165l.262-.014a98.742 98.742 0 002.799-.194 41.745 41.745 0 001.477-.15 5.682 5.682 0 002.445-.907c.11-.075.182-.13.212-.155a.583.583 0 01.758.887z"
      fill="#262C39"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear_2702_8686"
        x1={1}
        y1={1}
        x2={1}
        y2={29}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F25469" />
        <stop offset={1} stopColor="#FFD972" />
      </linearGradient>
    </defs>
  </svg>
);

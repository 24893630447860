import { API } from '@editorjs/editorjs';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';

export class Underline implements InlineTool {
  private readonly api: API;
  private readonly button: HTMLButtonElement;
  private static readonly commandName = 'underline';

  constructor(config: { api: API }) {
    this.api = config.api;
    this.button = this.createToolButton();
  }

  static get isInline() {
    return true;
  }

  static get shortcut() {
    return 'CMD+U';
  }

  static get sanitize() {
    return { u: {} };
  }

  private createToolButton() {
    const button = document.createElement('button');

    button.type = 'button';

    button.classList.add(this.api.styles.inlineToolButton);

    button.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7.5V11.5C9 12.2956 9.31607 13.0587 9.87868 13.6213C10.4413 14.1839 11.2044 14.5 12 14.5C12.7956 14.5 13.5587 14.1839 14.1213 13.6213C14.6839 13.0587 15 12.2956 15 11.5V7.5"></path>
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7.71429 18H16.2857"></path>
    </svg>`;

    return button;
  }

  render() {
    return this.button;
  }

  surround() {
    document.execCommand(Underline.commandName);
  }

  public checkState(): boolean {
    const isActive = document.queryCommandState(Underline.commandName);

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}

import { FunctionComponent, memo } from 'react';
import { SliceTooltipProps } from '@nivo/line';

import classNames from 'classnames';
import classes from '../../../Analytics.module.scss';

interface SliceTooltipArgs extends SliceTooltipProps {
  valueFormatter?: (value: number) => string;
}

export const SliceTooltip: FunctionComponent<SliceTooltipArgs> = memo(
  ({ slice, valueFormatter }) => {
    return (
      <div
        className={classNames(
          classes['analytics__chart-tooltip'],
          classes['analytics__chart-tooltip--reverse']
        )}
      >
        {slice.points.map(({ color, serieId, data: { y, yFormatted } }) => {
          return (
            <div key={serieId} className={classes['analytics__chart-tooltip-item']}>
              <span
                className={classes['analytics__chart-tooltip-item-color']}
                style={{ backgroundColor: color }}
              ></span>
              <span className={classes['analytics__chart-tooltip-item-label']}>{serieId}</span>
              <span className={classes['analytics__chart-tooltip-item-value']}>
                {valueFormatter ? valueFormatter(y as number) : yFormatted}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
);

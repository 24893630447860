// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SanitizerConfig } from '@editorjs/editorjs/types/configs/sanitizer-config';
import { BlockTool } from '@editorjs/editorjs/types/tools/block-tool';
import { BlockToolData } from '@editorjs/editorjs/types/tools/block-tool-data';
import BaseTable from '@editorjs/table';
import { getSanitizeConfig } from '../../RichTextEditorSanitizeConfig';

import './Table.module.scss';

export class Table extends BaseTable implements BlockTool {
  static get sanitize(): SanitizerConfig {
    return getSanitizeConfig();
  }

  render(): HTMLElement {
    return super.render();
  }

  save(block: HTMLElement): BlockToolData {
    return super.save(block);
  }
}

import { FunctionComponent, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrganisationTag } from '../../../../services';
import { getCssVar, layoutPath } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FeedCardTags.module.scss';

interface FeedCardTagsProps {
  tags: OrganisationTag[];
  className?: string;
}

export const FeedCardTags: FunctionComponent<FeedCardTagsProps> = memo(({ tags, className }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  return (
    <div className={classNames(classes['tags'], className)}>
      {tags.map(({ id, name }) => (
        <IconLabel
          key={id}
          label={`#${name}`}
          className={classes['tags__item']}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={() => navigate(layoutPath(`/tags/${id}`))}
          disabled={pathname === layoutPath(`/tags/${id}`)}
        />
      ))}
    </div>
  );
});

import { useCallback, useMemo, useState } from 'react';
import { ProfileFeedCreateCardModal } from '../../pages';
import { StoryCard, storyCardApi } from '../../services';
import { CreateCardModal } from '../components';
import { getPostFieldsFromCard } from '../utils';

interface CardEditProps {
  storyCard: StoryCard;
  isProfileFeedCard?: boolean;
}

export const useCardEdit = ({ storyCard, isProfileFeedCard }: CardEditProps) => {
  //lazy query used because of card details
  //if dispatch(getCard) used --> overrides details modal
  const [getCard] = storyCardApi.endpoints.storyCardById.useLazyQuery();

  const [card, setCard] = useState<StoryCard>(storyCard);

  const { id: cardId, storyId, story: postInStory } = card;

  const postFilledFields = useMemo(() => getPostFieldsFromCard(card), [card]);

  const [createCardModalOpen, setCreateCardModalOpen] = useState(false);

  const cardEditHandler = useCallback(async () => {
    setCard(await getCard({ storyId, cardId }).unwrap());
    setCreateCardModalOpen(true);
  }, [cardId, getCard, storyId]);

  const cardEditModal = useMemo(() => {
    if (!createCardModalOpen) {
      return null;
    }

    if (isProfileFeedCard) {
      return (
        <ProfileFeedCreateCardModal
          isOpen={createCardModalOpen}
          onClose={() => setCreateCardModalOpen(false)}
          card={card}
        />
      );
    }

    return (
      <CreateCardModal
        {...postFilledFields}
        isOpen={createCardModalOpen}
        onClose={() => setCreateCardModalOpen(false)}
        postInStories={[postInStory]}
        card={card}
      />
    );
  }, [card, createCardModalOpen, isProfileFeedCard, postFilledFields, postInStory]);

  return { cardEditModal, cardEditHandler };
};

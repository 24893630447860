/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../services';
import { Avatar, FilterGroup, FilterValue } from '../../../components';
import { useFilter } from '../useFilter';

import classes from './useAuthorsFilter.module.scss';

const GROUP_ID = 'authors';

export const useAuthorsFilter = (
  filter: FilterValue[],
  fetchItemsQuery: any,
  fetchItemsQueryArgs: any
) => {
  const { t } = useTranslation();

  const { getGroupById } = useFilter();

  const itemTemplate = useCallback(({ screenName, avatar }: User) => {
    return (
      <>
        <Avatar url={avatar?.url} />
        <div className={classes['name']}>{screenName}</div>
      </>
    );
  }, []);

  return {
    group: {
      id: GROUP_ID,
      title: t('authorsFilter.title'),
      autoComplete: {
        propToIdentify: 'id',
        propToDisplay: 'screenName',
        fetchItemsQuery,
        fetchItemsQueryArgs,
        itemTemplate,
        placeholder: t('authorsFilter.placeholder'),
      },
      value: getGroupById({ filter, groupId: GROUP_ID })?.groupItems ?? [],
      allowReset: true,
    } as FilterGroup,
  };
};

import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { rootApi, channelApi, graphqlApi } from '../services';
import { IS_PRODUCTION } from '../env';
import { sentryReduxEnhancer } from '../shared';

export const store = configureStore({
  enhancers: [sentryReduxEnhancer],
  devTools: !IS_PRODUCTION,
  reducer: rootReducer,
  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware().concat([
      rootApi.middleware,
      channelApi.middleware,
      graphqlApi.middleware,
    ]),
});

export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

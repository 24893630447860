import { MouseEventHandler, forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import sprite from '../../../assets/icons/sprite.svg';

import classNames from 'classnames';
import classes from './IconLabel.module.scss';

export enum IconLabelSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum IconLabelHintDirection {
  top = 'top',
  right = 'right', //default
  bottom = 'bottom',
  left = 'left',
}

interface IconLabelProps {
  icon?: JSX.Element; // icon element
  iconUrl?: string; // icon href
  iconId?: string; // icon id from svg sprite
  iconSize?: number;
  label?: string;
  labelSize?: IconLabelSizes;
  color?: string;
  hoverColor?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  labelClassName?: string;
  labelFirst?: boolean;
  singleColor?: boolean;
  nonClickable?: boolean;
  disabled?: boolean;
  withPadding?: boolean;
  backgroundColor?: string;
  data?: string;
  hintDirection?: IconLabelHintDirection;
}

const DEFAULT_COLORS = {
  main: '#43445B',
  hover: '#787878',
  disabled: '#B3B3B3',
};

export const IconLabel = memo(
  forwardRef<HTMLDivElement, IconLabelProps>(
    (
      {
        icon,
        iconUrl,
        iconId,
        iconSize = 24,
        label,
        labelSize = IconLabelSizes.medium,
        color,
        hoverColor,
        onClick,
        className,
        labelClassName,
        labelFirst,
        singleColor,
        nonClickable,
        disabled,
        withPadding,
        backgroundColor,
        data,
        hintDirection,
      },
      ref
    ) => {
      const textColor = useMemo(
        () => (disabled ? DEFAULT_COLORS.disabled : color ?? DEFAULT_COLORS.main),
        [color, disabled]
      );

      const textHoverColor = useMemo(() => {
        if (singleColor || disabled) {
          return textColor;
        }
        return hoverColor ?? DEFAULT_COLORS.hover;
      }, [disabled, hoverColor, singleColor, textColor]);

      const iconUrlElementRef = useRef<HTMLDivElement>(null);

      const renderLabel = useMemo(() => {
        if (!label) {
          return null;
        }
        return (
          <span
            className={classNames(
              classes['icon-label__label'],
              classes[`icon-label__label--${labelSize}`],
              labelClassName
            )}
          >
            {label}
          </span>
        );
      }, [label, labelClassName, labelSize]);

      const renderIconUrlElement = useMemo(() => {
        if (!iconUrl) {
          return null;
        }
        return (
          <div
            ref={iconUrlElementRef}
            style={{
              width: iconSize,
              height: iconSize,
              backgroundColor: textColor,
              flexShrink: 0,
              mask: `url('${iconUrl}') no-repeat center`,
              WebkitMask: `url('${iconUrl}') no-repeat center`,
            }}
          ></div>
        );
      }, [iconSize, iconUrl, textColor]);

      const changeColor = useCallback(
        (currentTarget: EventTarget & HTMLDivElement, color: string) => {
          currentTarget.style.color = color;

          if (iconUrlElementRef?.current) {
            iconUrlElementRef.current.style.backgroundColor = color;
          }
        },
        []
      );

      if ([icon, iconUrl, iconId, label].every((value) => !Boolean(value))) {
        return null;
      }

      return (
        <div
          ref={ref}
          className={classNames(className, classes['icon-label'], {
            [classes['icon-label--non-clickable']]: nonClickable,
            [classes['icon-label--disabled']]: disabled,
            [classes['icon-label--with-padding']]: withPadding,
            [classes['icon-label--data']]: data,
            [classes[`icon-label--data-hint-${hintDirection}`]]: data && hintDirection,
          })}
          style={{ color: textColor, backgroundColor }}
          onMouseLeave={({ currentTarget }) => changeColor(currentTarget, textColor)}
          onMouseOver={({ currentTarget }) => changeColor(currentTarget, textHoverColor)}
          onClick={onClick}
          {...{ data }}
        >
          {labelFirst && renderLabel}
          {icon}
          {renderIconUrlElement}
          {iconId && (
            <svg width={iconSize} height={iconSize} className={classes['icon-label__icon']}>
              <use href={`${sprite}#${iconId}`} />
            </svg>
          )}
          {!labelFirst && renderLabel}
        </div>
      );
    }
  )
);

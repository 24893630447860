import { FunctionComponent, memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

interface CommentsCounterProps {
  commentsCount: number;
  onClick: () => void;
}

export const CommentsCounter: FunctionComponent<CommentsCounterProps> = memo(
  ({ commentsCount, onClick }) => {
    const { t } = useTranslation();

    return (
      <IconLabel
        iconId={'comments'}
        iconSize={20}
        label={`${!isMobileOnly ? t('comments.title') : ''} (${commentsCount})`}
        color={getCssVar('--action-pane-icon-color')}
        hoverColor={getCssVar('--action-pane-icon-hover-color')}
        onClick={onClick}
      />
    );
  }
);

import { forwardRef, memo } from 'react';

import classNames from 'classnames';
import classes from './Button.module.scss';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
}

interface ButtonProps {
  type: ButtonType;
  label: string;
  className?: string;
  disabled?: boolean;
  nonClickable?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  danger?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        type,
        label,
        onClick,
        disabled = false,
        nonClickable = false,
        fullWidth = false,
        loading = false,
        danger = false,
        className,
      },
      ref
    ) => {
      return (
        <button
          ref={ref}
          className={classNames(className, classes['button'], classes[`button--${type}`], {
            [classes['button--non-clickable']]: nonClickable,
            [classes['button--full-width']]: fullWidth,
            [classes['button--loading']]: loading,
            [classes['button--danger']]: danger,
          })}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </button>
      );
    }
  )
);

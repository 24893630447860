import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context';
import {
  CookieActionType,
  LATESTS_STORY_CARDS_COOKIE_KEY,
  NavbarCommon,
  Switch,
  cookieOptions,
} from '../../shared';
import { Notifications } from '../Notifications';
import { SettingsMenu } from '../SettingsMenu';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';

export const Navbar: FunctionComponent = () => {
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies([LATESTS_STORY_CARDS_COOKIE_KEY]);

  const { latestsStoryCardsEnabled = true } = { ...cookies[LATESTS_STORY_CARDS_COOKIE_KEY] };

  const { visible: latestsStoryCardsVisible = false } = {
    ...useContext(ConfigContext).config.elements.latestsStoryCards,
  };

  const toggleRightModule = useCallback(() => {
    setCookie(
      LATESTS_STORY_CARDS_COOKIE_KEY,
      { latestsStoryCardsEnabled: !latestsStoryCardsEnabled },
      cookieOptions({ action: CookieActionType.SET })
    );
  }, [latestsStoryCardsEnabled, setCookie]);

  const renderLatestStoryCardsSwitch = useMemo(
    () => Boolean(!isMobileOnly && latestsStoryCardsVisible),
    [latestsStoryCardsVisible]
  );

  return (
    <NavbarCommon>
      <SettingsMenu
        {...(renderLatestStoryCardsSwitch && {
          component: (
            <Switch
              inputId={'latests-story-cards'}
              label={t('latestsStoryCards.switch-label')}
              checked={latestsStoryCardsEnabled}
              onChange={toggleRightModule}
              className={dropdownMenuClasses['dropdown-menu__item']}
            />
          ),
        })}
      />
      <Notifications />
    </NavbarCommon>
  );
};

import { ImageFile } from '../shared';
import { Channel } from './channelsNew';
import { graphqlApi } from './graphqlApi';
import { userQuery } from './helpers';
import { OrganisationTag } from './organisation';

export enum UserGender {
  NOT_SELECTED = 'NOT_SELECTED', //artificial field for display only
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERSE = 'DIVERSE',
}

export enum OrganisationUserRole {
  CHANNEL_ADMIN = 'CHANNEL_ADMIN',
  CHANNEL_EDITOR = 'CHANNEL_EDITOR',
  CHANNEL_EDITOR_LIMITED = 'CHANNEL_EDITOR_LIMITED',
  CHANNEL_READER = 'CHANNEL_READER',
  ORGANISATION_ADMIN = 'ORGANISATION_ADMIN',
  ORGANISATION_OWNER = 'ORGANISATION_OWNER',
  ORGANISATION_TEAM_MEMBER = 'ORGANISATION_TEAM_MEMBER',
}

interface UserLocation {
  text: string;
}

export interface UserSocialLinks {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;
  youtube?: string;
}

export interface User {
  avatar?: ImageFile;
  bio: string;
  department: string;
  email?: string;
  gender: UserGender | null;
  id: number;
  isAnonymous: boolean;
  isStaff: boolean;
  links: UserSocialLinks;
  location: UserLocation;
  organisationTags: OrganisationTag[];
  phone?: string;
  position: string;
  roleId?: OrganisationUserRole;
  screenName: string;
  subscription: string;
  url: string;
}

interface UserProfileResponse {
  data: {
    me: User;
  };
}

interface UserByIdArgs {
  userId: number;
}

interface UserByIdResponse {
  data: {
    userById: {
      payload: User;
    };
  };
}

interface UserProfileUpdateArgs {
  fields: {
    bio?: string;
    department?: string;
    gender?: UserGender | null;
    imageId: number | null;
    links: UserSocialLinks;
    location?: { text: string };
    organisationTagsId?: number[];
    phone?: string;
    position?: string;
    screenName?: string;
    subscription?: string;
    url?: string;
  };
}

interface UserProfileUpdateResponse {
  data: {
    userProfileUpdate: {
      payload: User;
    };
  };
}

interface UserRegistrationByEmailArgs {
  email: string;
  screenName: string;
}

interface UserRegistrationByEmailResponseData {
  payload: boolean;
  error: [{ message: string }];
}

interface UserRegistrationByEmailResponse {
  data: {
    userRegistrationByEmail: UserRegistrationByEmailResponseData;
  };
}

interface UserLoginBySocialPayload {
  accessToken: string;
  channel: Channel;
}

interface UserLoginBySocialData {
  payload: UserLoginBySocialPayload;
  error: [{ message: string }];
}

interface UserLoginBySocialResponse {
  data: {
    userLoginBySocial: UserLoginBySocialData;
  };
}

interface UserLoginBySocialArgs {
  accessToken: string;
  type: string;
  screenName?: string;
}

interface UserDeviceRegistrationArgs {
  fcmToken: string;
}

interface UserDeviceRegistrationResponse {
  data: {
    userDeviceRegistration: {
      payload: boolean;
    };
  };
}

interface UserLogoutArgs {
  fcmToken: string;
}

interface UserLogoutResponse {
  data: {
    userLogout: {
      payload: boolean;
    };
  };
}

interface UserLoginByEmailArgs {
  email: string;
  password: string;
  otp?: string;
  otpBackup?: string;
}

interface UserLoginByEmailPayload {
  accessToken: string;
  channel: Channel;
}

interface UserLoginByEmailData {
  payload: UserLoginByEmailPayload;
  error: {
    __typename: string;
    message: string;
    otpAuthSecret?: string;
    otpAuthUrlQRCode?: string;
  };
}

interface UserLoginByEmailResponse {
  data: {
    userLoginByEmail: UserLoginByEmailData;
  };
}

interface UserRequestNew2FASetupPayload {
  otpAuthSecret: string;
  otpAuthUrlQRCode: string;
}

interface UserRequestNew2FASetupResponse {
  data: {
    userRequestNew2FASetup: {
      payload: UserRequestNew2FASetupPayload;
    };
  };
}

interface UserConfirmNew2FASetupArgs {
  otp: string;
}

interface UserConfirmNew2FASetupResponse {
  data: {
    userConfirmNew2FASetup: {
      payload: boolean;
    };
  };
}

export const userApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    profile: builder.query<User, void>({
      query: () => ({
        url: '/graphql/webapp?profile',
        method: 'POST',
        body: {
          query: `{
            me {
              ${userQuery}
            }
          }`,
        },
      }),
      transformResponse: (response: UserProfileResponse) => response.data.me,
    }),
    userById: builder.query<User, UserByIdArgs>({
      query: ({ userId }) => ({
        url: '/graphql/webapp?userById',
        method: 'POST',
        body: {
          query: `query UserById($userId: Int!) {
            userById(userId: $userId) {
              payload {
                ${userQuery}
              }
            }
          }`,
          variables: { userId },
        },
      }),
      transformResponse: (response: UserByIdResponse) => response.data.userById.payload,
    }),
    userProfileUpdate: builder.query<User, UserProfileUpdateArgs>({
      query: ({ fields }) => ({
        url: '/graphql/webapp?userProfileUpdate',
        method: 'POST',
        body: {
          query: `mutation UserProfileUpdate($input: UserProfileUpdateInput!) {
            userProfileUpdate(input: $input) {
              payload {
                ${userQuery}
              }
            }
          }`,
          variables: {
            input: {
              fields,
            },
          },
        },
      }),
      transformResponse: (response: UserProfileUpdateResponse) =>
        response.data.userProfileUpdate.payload,
    }),
    userRegistrationByEmail: builder.query<
      UserRegistrationByEmailResponseData,
      UserRegistrationByEmailArgs
    >({
      query: ({ email, screenName }) => ({
        url: '/graphql/webapp?userRegistrationByEmail',
        method: 'POST',
        body: {
          query: `mutation {
            userRegistrationByEmail(input: { 
              email: "${email}", 
              screenName: "${screenName}"
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
                ... on UserIsAlreadyRegisteredError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: UserRegistrationByEmailResponse) =>
        response.data.userRegistrationByEmail,
    }),
    userLoginByEmail: builder.query<UserLoginByEmailData, UserLoginByEmailArgs>({
      query: ({ email, password, otp, otpBackup }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation UserLoginByEmail($input: UserLoginByEmailInputType!) {
            userLoginByEmail(input: $input) {
              payload {
                accessToken
                channel {
                  subdomain
                }
              }
              error {
                __typename
                ... on UnknownError {
                  message
                }
                ... on User2FAOtpSetupIsRequiredError {
                  message
                  otpAuthSecret
                  otpAuthUrlQRCode
                }
                ... on User2FAOtpIsRequiredError {
                  message
                }
                ... on User2FAOtpIsInvalidError {
                  message
                }
                ... on User2FAOtpBackupIsUsedError {
                  message
                }
                ... on User2FAOtpBackupIsInvalidError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: {
              email,
              password,
              ...(Boolean(otp) && { otp }),
              ...(Boolean(otpBackup) && { otpBackup }),
            },
          },
        },
      }),
      transformResponse: (response: UserLoginByEmailResponse) => response.data.userLoginByEmail,
    }),
    userLoginBySocial: builder.query<UserLoginBySocialPayload, UserLoginBySocialArgs>({
      query: ({ accessToken, type, screenName = '' }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation {
            userLoginBySocial(input: { 
              accessToken: "${accessToken}", 
              type: "${type}",
              screenName: "${screenName}",
            }) {
              payload {
                accessToken
                channel {
                  subdomain
                }
              }
              error {
                ... on SocialLoginFetchingProfileError {
                  message
                }
                ... on SocialLoginInvalidConnectionError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: UserLoginBySocialResponse) =>
        response.data.userLoginBySocial.payload,
    }),
    userDeviceRegistration: builder.query<boolean, UserDeviceRegistrationArgs>({
      query: ({ fcmToken }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation {
            userDeviceRegistration(input: { 
              fcmToken: "${fcmToken}"
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: UserDeviceRegistrationResponse) =>
        response.data.userDeviceRegistration.payload,
    }),
    userLogout: builder.query<boolean, UserLogoutArgs>({
      query: ({ fcmToken }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation {
            userLogout(input: { 
              fcmToken: "${fcmToken}"
            }) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: UserLogoutResponse) => response.data.userLogout.payload,
    }),
    userRequestNew2FASetup: builder.query<UserRequestNew2FASetupPayload, void>({
      query: () => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation UserRequestNew2FASetup {
            userRequestNew2FASetup {
              payload {
                otpAuthSecret
                otpAuthUrlQRCode
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: UserRequestNew2FASetupResponse) =>
        response.data.userRequestNew2FASetup.payload,
    }),
    userConfirmNew2FASetup: builder.query<boolean, UserConfirmNew2FASetupArgs>({
      query: ({ otp }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `mutation UserConfirmNew2FASetup($input: UserConfirmNew2faSetupInput!) {
            userConfirmNew2FASetup(input: $input) {
              payload
              error {
                ... on UnknownError {
                  message
                }
                ... on User2FAOtpIsInvalidError {
                  message
                }
                ... on User2FATemporarySecretIsEmptyError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { otp },
          },
        },
      }),
      transformResponse: (response: UserConfirmNew2FASetupResponse) =>
        response.data.userConfirmNew2FASetup.payload,
    }),
  }),
});

export const {
  useProfileQuery,
  useUserProfileUpdateQuery,
  useUserByIdQuery,
  useUserLogoutQuery,
  useUserRegistrationByEmailQuery,
  useUserLoginBySocialQuery,
  useUserDeviceRegistrationQuery,
} = userApi;

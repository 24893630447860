import { FunctionComponent, memo } from 'react';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './CloseButton.module.scss';

interface CloseButtonProps {
  onClick: () => void;
  backgroundColor?: string;
  iconBackgroundColor?: string;
  className?: string;
}

export const CloseButton: FunctionComponent<CloseButtonProps> = memo(
  ({ onClick, backgroundColor, iconBackgroundColor, className }) => {
    return (
      <div
        className={classNames(classes['close'], className)}
        style={{ backgroundColor }}
        onClick={onClick}
      >
        <IconLabel
          className={classes['close__icon']}
          iconId={'close'}
          iconSize={20}
          color={iconBackgroundColor ?? '#fff'}
          singleColor
        />
      </div>
    );
  }
);

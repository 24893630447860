import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AlignContentOptions, Form } from '../Form';

import formClasses from '../Form/Form.module.scss';

export const CheckEmail: FunctionComponent = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const email = new URLSearchParams(location.search).get('email');

  const isMagic = new URLSearchParams(location.search).get('magic') === 'true';

  const isMobile = new URLSearchParams(location.search).get('mobile') === 'true';

  const type = new URLSearchParams(location.search).get('type') ?? '';

  const resendLink = useMemo(() => `/auth/forgot${type ? `?type=${type}` : ''}`, [type]);

  return (
    <Form alignContent={AlignContentOptions.center}>
      <div className={formClasses['form__content-header']}>
        <span className={formClasses['form__content-header-title']}>{t('checkEmail.title')}</span>
        <div className={formClasses['form__content-header-subtitle']}>
          <div>
            {isMagic ? t('checkEmail.magic-sent') : t('checkEmail.reset-sent')}&nbsp;
            <b>{email}</b>.
          </div>
          {t('checkEmail.spam-check')}
        </div>
      </div>

      <Link to={resendLink} className={formClasses['form__content-link']}>
        <span>{t('checkEmail.not-received')}</span>&nbsp;
        <span className={formClasses['form__content-link--highlighted']}>
          {t('common.click-to-resend')}
        </span>
      </Link>

      {!isMobile && (
        <Link to={'/auth/login'} className={formClasses['form__content-back']}>
          {t('common.back-to-login')}
        </Link>
      )}
    </Form>
  );
};

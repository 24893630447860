import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../context';
import { OrganisationUsersFilter, useOrganisationUsersFiltersQuery } from '../../../services';
import {
  CookieActionType,
  Filter,
  FilterGroup,
  FilterValue,
  USER_DIRECTORY_READER_COOKIE_KEY,
  cookieOptions,
  useAppDispatch,
  useFilter,
} from '../../../shared';
import { getOrganisationUsers } from '../../../slices';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './UserDirectoryRightSide.module.scss';

const NAME_ID = 'name';
const DEPARTMENT_ID = 'department';
const POSITION_ID = 'position';
const LOCATION_ID = 'location';

export const UserDirectoryRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [cookies, setCookie] = useCookies([USER_DIRECTORY_READER_COOKIE_KEY]);

  const { config } = useContext(ConfigContext);

  const { fields } = config.elements.profile;

  const { data: filterGroups, isFetching } = useOrganisationUsersFiltersQuery();

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[USER_DIRECTORY_READER_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue } = useFilter();

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const filterDepartments = getGroupValue({ filter, groupId: DEPARTMENT_ID, multiCheck: true });
      const filterPositions = getGroupValue({ filter, groupId: POSITION_ID, multiCheck: true });
      const query = getGroupValue({ filter, groupId: NAME_ID });
      const queryLocation = getGroupValue({ filter, groupId: LOCATION_ID });

      const requestFilter: OrganisationUsersFilter = {
        ...(filterDepartments?.length && { filterDepartments }),
        ...(filterPositions?.length && { filterPositions }),
        ...(query && { query }),
        ...(queryLocation && { queryLocation }),
      };

      setCookie(
        USER_DIRECTORY_READER_COOKIE_KEY,
        { requestFilter, filter },
        cookieOptions({ action: CookieActionType.SET })
      );

      dispatch(getOrganisationUsers({ filter: requestFilter }));

      setFilter(filter);
    },
    [dispatch, getGroupValue, setCookie]
  );

  if (isFetching) {
    return (
      <div className={classes['user-directory-right-side__loader']}>
        <Skeleton width={'100%'} height={'10rem'} />
      </div>
    );
  }

  if (!filterGroups) {
    return null;
  }

  const groups: FilterGroup[] = filterGroups
    .filter(({ values }) => values.length)
    .map(({ name, values }) => {
      return {
        id: name,
        title: t(`${fields[name as keyof typeof fields].localiseKey ?? `common.${name}`}`),
        items: values.map(({ value }) => {
          return { id: value, label: value, value };
        }),
        multiCheck: true,
        allowReset: true,
      };
    });

  const nameTitle = t(`${fields['screenName'].localiseKey ?? `common.${NAME_ID}`}`);

  groups.unshift({
    id: NAME_ID,
    title: nameTitle,
    search: { placeholder: nameTitle },
    value: getGroupValue({ filter, groupId: NAME_ID }) ?? '',
    allowReset: true,
  });

  const locationTitle = t(`${fields['location'].localiseKey ?? `common.${LOCATION_ID}`}`);

  groups.push({
    id: LOCATION_ID,
    title: locationTitle,
    search: { placeholder: locationTitle },
    value: getGroupValue({ filter, groupId: LOCATION_ID }) ?? '',
    allowReset: true,
  });

  return (
    <div className={classes['user-directory-right-side']}>
      <Filter
        groups={groups}
        groupItemsLimit={5}
        filter={filter}
        onChange={filterChange}
        resetPageScrollOnChange
      />
    </div>
  );
};

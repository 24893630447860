// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jsmediatags from 'jsmediatags/dist/jsmediatags.min';
import { TagType } from 'jsmediatags/types';

export const generateAudioTeaserUrl = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    jsmediatags.read(file, {
      onSuccess: ({ tags: { picture } }: TagType) => {
        if (!picture) {
          resolve('');
          return;
        }

        let base64String = '';
        for (let i = 0; i < picture.data.length; i++) {
          base64String += String.fromCharCode(picture.data[i]);
        }
        resolve(`data:${picture.format};base64,${btoa(base64String)}`);
      },
      onError: () => {
        resolve('');
      },
    });
  });
};

import { FunctionComponent, memo } from 'react';
import { StoryCardType } from '../../../../../../services';
import { IconLabel } from '../../../../IconLabel';

import classes from './EmbedLinkMediaPlaceholder.module.scss';

interface EmbedLinkMediaPlaceholderProps {
  url: string;
  type: StoryCardType;
}

export const EmbedLinkMediaPlaceholder: FunctionComponent<EmbedLinkMediaPlaceholderProps> = memo(
  ({ url, type }) => {
    return (
      <a
        href={url}
        className={classes['embed-link-placeholder']}
        target={'_blank'}
        rel="noreferrer"
      >
        <IconLabel
          iconId={type.toLowerCase()}
          className={classes['embed-link-placeholder__icon']}
          iconSize={32}
          color={'#fff'}
          singleColor
        />
      </a>
    );
  }
);

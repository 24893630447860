import { FunctionComponent, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import { getDeepLinkUrl } from '../../utils';
import { IconLabel } from '../IconLabel';

export enum DeeplinkType {
  CHANNEL = 'CHANNEL',
  STORY = 'STORY',
  USER = 'USER',
  CARD = 'CARD',
  CHAT = 'CHAT',
}

interface DeeplinkProps {
  type: DeeplinkType;
  userId?: number;
  storyId?: number;
  cardId?: number;
  chatId?: number;
  label?: string;
  className?: string;
  onClick?: () => void;
}

export const Deeplink: FunctionComponent<DeeplinkProps> = memo(
  ({ type, userId, storyId, cardId, chatId, label, className, onClick }) => {
    const { t } = useTranslation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const copyDeeplinkHandler = useCallback(async () => {
      onClick?.();

      if (!navigator.clipboard) {
        return;
      }

      await navigator.clipboard.writeText(
        getDeepLinkUrl({ type, channelId, userId, storyId, cardId, chatId })
      );

      setTimeout(() => {
        toast(t('deepLink.copied'));
      }, 0);
    }, [cardId, channelId, chatId, onClick, storyId, t, type, userId]);

    return (
      <IconLabel
        iconId={'add-link'}
        iconSize={18}
        label={label ?? t('deepLink.label')}
        className={className}
        onClick={copyDeeplinkHandler}
        singleColor
      />
    );
  }
);

import { API } from '@editorjs/editorjs';
import { InlineTool } from '@editorjs/editorjs/types/tools/inline-tool';

export class Marker implements InlineTool {
  private readonly api: API;
  private readonly tag = 'MARK';
  private readonly button: HTMLButtonElement;

  constructor(config: { api: API }) {
    this.api = config.api;
    this.button = this.createToolButton();
  }

  static get isInline() {
    return true;
  }

  static get sanitize() {
    return { mark: {} };
  }

  private createToolButton(): HTMLButtonElement {
    const button = document.createElement('button');

    button.type = 'button';

    button.classList.add(this.api.styles.inlineToolButton);

    button.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-width="2" d="M11.3535 9.31802L12.7678 7.90381C13.5488 7.12276 14.8151 7.12276 15.5962 7.90381C16.3772 8.68486 16.3772 9.95119 15.5962 10.7322L14.182 12.1464M11.3535 9.31802L7.96729 12.7043C7.40889 13.2627 7.02826 13.9739 6.87339 14.7482L6.69798 15.6253C6.55803 16.325 7.17495 16.942 7.87467 16.802L8.75175 16.6266C9.52612 16.4717 10.2373 16.0911 10.7957 15.5327L14.182 12.1464M11.3535 9.31802L14.182 12.1464"/>
        <line x1="15" x2="19" y1="17" y2="17" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
    </svg>`;

    return button;
  }

  render() {
    return this.button;
  }

  surround(range: Range) {
    if (!range) {
      return;
    }

    const termWrapper = this.api.selection.findParentTag(this.tag);

    termWrapper ? this.unwrap(termWrapper) : this.wrap(range);
  }

  private wrap(range: Range) {
    const marker = document.createElement(this.tag);

    marker.appendChild(range.extractContents());

    range.insertNode(marker);

    this.api.selection.expandToTag(marker);
  }

  private unwrap(termWrapper: HTMLElement) {
    this.api.selection.expandToTag(termWrapper);

    const selection = window.getSelection();

    if (!selection) {
      return;
    }

    const range = selection.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    termWrapper.parentNode?.removeChild(termWrapper);

    range.insertNode(unwrappedContent);

    selection.removeAllRanges();

    selection.addRange(range);
  }

  checkState() {
    const tag = this.api.selection.findParentTag(this.tag);

    this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, Boolean(tag));

    return Boolean(tag);
  }
}

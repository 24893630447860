import { differenceInDays, subDays } from 'date-fns';
import { Dispatch, FunctionComponent, SetStateAction, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalyticChartStoryListOrderBy,
  useStoryListQuery,
  useStoryTotalQuery,
} from '../../../../services';
import { OrderDirection, durationFormatter } from '../../../../shared';
import {
  ChartWrapper,
  DataRowItem,
  Loader,
  TableChart,
  TableChartHeader,
  TableStats,
} from '../../helpers';
import { ContentProps } from '../Content';

export const StoryList: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform }) => {
    const { t } = useTranslation();

    const [orderBy, setOrderBy] = useState<AnalyticChartStoryListOrderBy>(
      AnalyticChartStoryListOrderBy.VIEWS
    );

    const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC);

    const [page, setPage] = useState<number>(1);

    const { data: storyList, isFetching: isFetchingStoryList } = useStoryListQuery({
      from,
      to,
      channelId,
      platform,
      orderBy,
      orderDirection,
      page,
    });

    const { data: storyTotal, isFetching: isFetchingStoryTotal } = useStoryTotalQuery({
      from,
      to,
      channelId,
      platform,
    });

    const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

    const { data: storyTotalPrev, isFetching: isFetchingStoryTotalPrev } = useStoryTotalQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
      channelId,
      platform,
    });

    const renderStats = useMemo(() => {
      if (!storyTotal || !storyTotalPrev) {
        return null;
      }

      const { items } = storyTotal;

      const { items: itemsPrev } = storyTotalPrev;

      const stats = [
        {
          localiseKey: 'storyListLabels.total-views',
          hintLocaliseKey: 'storyListHints.total-views',
          value: items.find(({ label }) => label === 'viewTotal')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'viewTotal')?.value ?? 0,
        },
        {
          localiseKey: 'storyListLabels.total-duration',
          hintLocaliseKey: 'storyListHints.total-duration',
          value: items.find(({ label }) => label === 'durationTotal')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'durationTotal')?.value ?? 0,
          valueFormatter: durationFormatter,
        },
        {
          localiseKey: 'storyListLabels.total-average-duration',
          hintLocaliseKey: 'storyListHints.total-average-duration',
          value: items.find(({ label }) => label === 'durationAvgTotal')?.value ?? 0,
          valuePrev: itemsPrev.find(({ label }) => label === 'durationAvgTotal')?.value ?? 0,
          valueFormatter: durationFormatter,
        },
      ];

      return (
        <TableStats
          stats={stats}
          loading={Boolean(isFetchingStoryTotal || isFetchingStoryTotalPrev)}
          columnDisplay
        />
      );
    }, [isFetchingStoryTotal, isFetchingStoryTotalPrev, storyTotal, storyTotalPrev]);

    const renderChart = useMemo(() => {
      if (!storyList) {
        return <Loader />;
      }

      const { items, pageInfo } = storyList;

      const data: DataRowItem[][] = items.map(({ label, payload }) => {
        const { views, duration, durationAvg } = payload;

        return [
          { value: label },
          { value: views },
          { value: durationFormatter(duration) },
          { value: durationFormatter(durationAvg) },
        ];
      });

      const headers: TableChartHeader[] = [
        { label: t('storyListLabels.title') },
        { label: t('storyListLabels.views'), orderBy: AnalyticChartStoryListOrderBy.VIEWS },
        { label: t('storyListLabels.duration'), orderBy: AnalyticChartStoryListOrderBy.DURATION },
        {
          label: t('storyListLabels.average-duration'),
          orderBy: AnalyticChartStoryListOrderBy.DURATION_AVG,
        },
      ];

      return (
        <TableChart
          sticky
          headers={headers}
          data={data}
          pageInfo={pageInfo}
          setPage={setPage}
          orderBy={orderBy}
          setOrderBy={setOrderBy as Dispatch<SetStateAction<string>>}
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
          loading={isFetchingStoryList}
        />
      );
    }, [isFetchingStoryList, orderBy, orderDirection, storyList, t]);

    return (
      <ChartWrapper typeLocaliseKey={'chartTypes.story-list'} infoLocaliseKey={'storyList.info'}>
        {renderStats}
        {renderChart}
      </ChartWrapper>
    );
  }
);

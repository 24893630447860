import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';
import {
  InAppActiveMessage,
  useGetInAppMessageQuery,
  useInAppMessageReceivedMutation,
} from '../../services';
import { useDeepLink } from '../../shared';
import { InAppMessage } from './InAppMessage';

export const InAppMessageManager: FunctionComponent = memo(() => {
  const { isDeepLink } = useDeepLink();

  const [showInAppMessage, setShowInAppMessage] = useState(false);

  const { data: inAppMessage } = useGetInAppMessageQuery();

  const [messageReceived] = useInAppMessageReceivedMutation();

  useEffect(() => {
    if (inAppMessage?.activeMessage) {
      setShowInAppMessage(true);
    }
  }, [inAppMessage]);

  const closeInAppMessage = useCallback(() => {
    messageReceived({ messageId: (inAppMessage?.activeMessage as InAppActiveMessage).id });
    setShowInAppMessage(false);
  }, [inAppMessage?.activeMessage, messageReceived]);

  if (isDeepLink) {
    return null;
  }

  return (
    <>
      {inAppMessage?.activeMessage && (
        <InAppMessage
          isOpen={showInAppMessage}
          message={inAppMessage.activeMessage}
          onClose={closeInAppMessage}
        />
      )}
    </>
  );
});

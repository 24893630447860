import { FunctionComponent, memo } from 'react';
import { FeedCardWrapper } from '../FeedCardWrapper';
import { FeedCardProps, FeedCardThreadCommon } from '../../../shared';

export const FeedCardThread: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardWrapper card={card}>
      <FeedCardThreadCommon card={card} />
    </FeedCardWrapper>
  );
});

import { useEffect } from 'react';
import { Config } from '../../services';
import { setConfigCSSVariables } from '../utils';

export const useConfig = (config?: Config) => {
  useEffect(() => {
    if (!config) {
      return;
    }

    setConfigCSSVariables(config);

    config.styles.fonts.forEach((font) => {
      const { src, style, weight } = font;

      const urls: string[] = [];

      src.forEach(({ url, format }) => {
        urls.push(`url("${url}") format("${format}")`);
      });

      const configFont = new FontFace('Config Font', urls.join(','), { style, weight });

      document.fonts.add(configFont);
    });
  }, [config]);
};

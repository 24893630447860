export const userQuery = `
    avatar {
        averageColor
        externalId
        externalType
        id
        originalFilename
        rightholder
        status
        statusCopyrightId
        thumb
        url
    }
    bio
    department
    email
    gender
    id
    isAnonymous
    isStaff
    links {
        facebook
        instagram
        linkedin
        snapchat
        tiktok
        twitter
        youtube
    }
    location {
        text
    }
    organisationTags {
        id
        name
        type
    }
    phone
    position
    roleId
    screenName
    subscription
    url
`;

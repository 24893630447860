import { ChannelAppTabAction } from '../../services';

export const getAppTabHrefByActionType = (action: ChannelAppTabAction, storyId?: number) => {
  switch (action) {
    case ChannelAppTabAction.STORIES:
      return '/storyTab1';
    case ChannelAppTabAction.STORIES2:
      return '/storyTab2';
    case ChannelAppTabAction.PROFILE:
      return '/profile';
    case ChannelAppTabAction.PINNED_STORY:
      return `/pinnedStoryTab/${storyId}/items`;
    case ChannelAppTabAction.CHATS:
      return '/chats';
    default:
      return '/';
  }
};

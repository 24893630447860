import { FunctionComponent, memo, useMemo } from 'react';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classes from './Delta.module.scss';

interface DeltaProps {
  value: number;
  valuePrev: number;
}

export const Delta: FunctionComponent<DeltaProps> = memo(({ value, valuePrev }) => {
  const delta = Math.round(((value - valuePrev) / valuePrev) * 100);

  const isTrendUp = useMemo(() => Boolean(delta > 0), [delta]);

  if (!value || !valuePrev || value === valuePrev) {
    return null;
  }

  return (
    <IconLabel
      className={classes['delta']}
      iconSize={18}
      iconId={isTrendUp ? 'trend-up' : 'trend-down'}
      label={`${Math.abs(delta)}%`}
      color={isTrendUp ? '#0DA068' : getCssVar('--color-danger')}
      nonClickable
    />
  );
});

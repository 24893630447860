import { Story, StoryCard, StoryCardQuoteSource, User } from '../../services';
import { File, ImageFile } from './File';

export enum BlockTypes {
  paragraph = 'paragraph',
  list = 'list',
  checklist = 'checklist',
  delimiter = 'delimiter',
  header = 'header',
  code = 'code',
  quote = 'quote',
  callout = 'callout',
  embedlink = 'embedlink',
  table = 'table',
  image = 'image',
  audio = 'audio',
  video = 'video',
  pdf = 'pdf',
  file = 'file',
  user = 'user',
  story = 'story',
  card = 'card',
}

export enum ListBlockStyle {
  unordered = 'unordered',
  ordered = 'ordered',
}

export interface ListBlockElement {
  content: string;
  items: ListBlockElement[];
}

interface ChecklistBlockElement {
  text: string;
  checked: boolean;
}

export interface LinkData {
  url: string;
  title: string;
  text: string;
  image: ImageFile | null;
  sourceName?: string;
  quoteSource?: StoryCardQuoteSource;
}

export interface ImageBlockDataItem {
  image: { id: number; url: string };
  caption: string;
  copyright?: string;
}

export interface ImageBlockData {
  items: ImageBlockDataItem[];
  withBorder: boolean;
  withBackground: boolean;
  stretched: boolean;
}

export interface ParagraphBlockData {
  text: string;
  source?: boolean; //artificial field
}

export interface ListBlockData {
  style: ListBlockStyle;
  items: ListBlockElement[];
}

export interface ChecklistBlockData {
  items: ChecklistBlockElement[];
}

export interface DelimiterBlockData {
  style: 'stars';
}

export interface HeaderBlockData {
  text: string;
  level: number;
}

export interface CodeBlockData {
  code: string;
}

export interface QuoteBlockData {
  text: string;
  caption: string;
  alignment: 'center' | 'left';
}

export interface CalloutBlockData {
  text: string;
  icon: string;
}

export interface EmbedLinkBlockData {
  link: LinkData | null;
  caption?: string;
  hideUrl?: boolean; //artificial field
}

export interface TableBlockData {
  withHeadings: boolean;
  content: string[][];
}

export interface AudioBlockDataItem {
  audio: File | null;
  caption?: string;
  previewUrl?: string;
  copyright?: string;
}

export interface AudioBlockData {
  items: AudioBlockDataItem[];
}

export interface VideoBlockDataItem {
  video: File | null;
  caption?: string;
  previewUrl?: string;
  copyright?: string;
}

export interface VideoBlockData {
  items: VideoBlockDataItem[];
}

export interface PdfBlockDataItem {
  pdf: File | null;
  caption?: string;
  previewUrl?: string;
  copyright?: string;
}

export interface PdfBlockData {
  items: PdfBlockDataItem[];
}

export interface FileBlockDataItem {
  file: File | null;
  caption?: string;
  previewUrl?: string;
  copyright?: string;
}

export interface FileBlockData {
  items: FileBlockDataItem[];
  isPdf?: boolean; //artificial field
}

export interface UserBlockData {
  user: User | null;
}

export interface StoryBlockData {
  story: Story | null;
}

export interface StoryCardBlockData {
  storyCard: StoryCard | null;
}

interface ContentBlock {
  id: string;
  type: string;
  data: unknown;
}

export interface ParagraphBlock extends ContentBlock {
  type: BlockTypes.paragraph;
  data: ParagraphBlockData;
}

export interface ListBlock extends ContentBlock {
  type: BlockTypes.list;
  data: ListBlockData;
}

export interface ChecklistBlock extends ContentBlock {
  type: BlockTypes.checklist;
  data: ChecklistBlockData;
}

export interface DelimiterBlock extends ContentBlock {
  type: BlockTypes.delimiter;
  data: DelimiterBlockData;
}

export interface HeaderBlock extends ContentBlock {
  type: BlockTypes.header;
  data: HeaderBlockData;
}

export interface CodeBlock extends ContentBlock {
  type: BlockTypes.code;
  data: CodeBlockData;
}

export interface QuoteBlock extends ContentBlock {
  type: BlockTypes.quote;
  data: QuoteBlockData;
}

export interface CalloutBlock extends ContentBlock {
  type: BlockTypes.callout;
  data: CalloutBlockData;
}

export interface EmbedLinkBlock extends ContentBlock {
  type: BlockTypes.embedlink;
  data: EmbedLinkBlockData;
}

export interface TableBlock extends ContentBlock {
  type: BlockTypes.table;
  data: TableBlockData;
}

export interface ImageBlock extends ContentBlock {
  type: BlockTypes.image;
  data: ImageBlockData;
}

export interface AudioBlock extends ContentBlock {
  type: BlockTypes.audio;
  data: AudioBlockData;
}

export interface VideoBlock extends ContentBlock {
  type: BlockTypes.video;
  data: VideoBlockData;
}

export interface PdfBlock extends ContentBlock {
  type: BlockTypes.pdf;
  data: PdfBlockData;
}

export interface FileBlock extends ContentBlock {
  type: BlockTypes.file;
  data: FileBlockData;
}

export interface UserBlock extends ContentBlock {
  type: BlockTypes.user;
  data: UserBlockData;
}

export interface StoryBlock extends ContentBlock {
  type: BlockTypes.story;
  data: StoryBlockData;
}

export interface StoryCardBlock extends ContentBlock {
  type: BlockTypes.card;
  data: StoryCardBlockData;
}

export type BlockUnion =
  | ParagraphBlock
  | ListBlock
  | ChecklistBlock
  | DelimiterBlock
  | HeaderBlock
  | CodeBlock
  | QuoteBlock
  | CalloutBlock
  | EmbedLinkBlock
  | TableBlock
  | ImageBlock
  | AudioBlock
  | VideoBlock
  | PdfBlock
  | FileBlock
  | UserBlock
  | StoryBlock
  | StoryCardBlock;

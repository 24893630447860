import { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForgotSetMutation, useForgotTokenCheckQuery } from '../../../services';
import {
  Button,
  ButtonType,
  IconLabel,
  InputField,
  MIN_PASSWORD_LENGTH,
  getCssVar,
} from '../../../shared';
import { Form } from '../Form';
import { TokenError } from './TokenError';

import formClasses from '../Form/Form.module.scss';
import classes from './ForgotSet.module.scss';

export const ForgotSet: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const token = new URLSearchParams(location.search).get('token') ?? '';

  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [peeping, setPeeping] = useState<boolean>(false);
  const [peepingConfirm, setPeepingConfirm] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const resetButton = useRef<HTMLButtonElement | null>(null);

  const resetDisabled = useMemo(
    () => password.length < MIN_PASSWORD_LENGTH || password !== passwordConfirm,
    [password, passwordConfirm]
  );

  const passwordFieldType = useMemo(() => (peeping ? 'text' : 'password'), [peeping]);

  const passwordConfirmFieldType = useMemo(
    () => (peepingConfirm ? 'text' : 'password'),
    [peepingConfirm]
  );

  const title = useMemo(
    () => t(`setPassword.title${requestSent ? '-sent' : ''}`),
    [requestSent, t]
  );

  const subTitle = useMemo(
    () => t(`setPassword.sub-title${requestSent ? '-sent' : ''}`),
    [requestSent, t]
  );

  const [forgotSet] = useForgotSetMutation();

  const resetClickHandler = useCallback(async () => {
    resetButton.current?.setAttribute('disabled', 'true');

    try {
      await forgotSet({ pwd: password, token }).unwrap();
      setRequestSent(true);
    } catch (e) {
      toast.error(t('common.error-title'));
    } finally {
      resetButton.current?.removeAttribute('disabled');
    }
  }, [forgotSet, password, t, token]);

  const { data: isTokenValid, isFetching } = useForgotTokenCheckQuery({ token });

  if (isFetching) {
    return null;
  }

  if (!isTokenValid) {
    return <TokenError />;
  }

  return (
    <Form>
      <div className={formClasses['form__content-header']}>
        <div className={formClasses['form__content-header-title']}>{title}</div>
        <div className={formClasses['form__content-header-subtitle']}>{subTitle}</div>
      </div>

      {!requestSent && (
        <>
          <InputField
            label={t('common.password')}
            value={password}
            type={passwordFieldType}
            placeholder={t('common.password-placeholder')}
            icon={
              <IconLabel
                iconId={peeping ? 'eye-crossed' : 'eye'}
                iconSize={18}
                onClick={() => setPeeping(!peeping)}
                color={getCssVar('--input-icon-color')}
                singleColor
              />
            }
            onChange={({ target }) => setPassword(target.value)}
          />

          <span className={classes['password-hint']}>{t('setPassword.password-hint')}</span>

          <InputField
            label={t('setPassword.password-confirm')}
            value={passwordConfirm}
            type={passwordConfirmFieldType}
            placeholder={t('setPassword.password-confirm-placeholder')}
            icon={
              <IconLabel
                iconId={peepingConfirm ? 'eye-crossed' : 'eye'}
                iconSize={18}
                onClick={() => setPeepingConfirm(!peepingConfirm)}
                color={getCssVar('--input-icon-color')}
                singleColor
              />
            }
            onChange={({ target }) => setPasswordConfirm(target.value)}
          />

          <Button
            ref={resetButton}
            type={ButtonType.primary}
            label={'Reset password'}
            onClick={resetClickHandler}
            disabled={resetDisabled}
            fullWidth
          />

          <Link to={'/auth/login'} className={formClasses['form__content-back']}>
            {t('common.back-to-login')}
          </Link>
        </>
      )}

      {requestSent && (
        <Button
          ref={resetButton}
          type={ButtonType.primary}
          label={t('common.continue')}
          onClick={() => navigate('/auth/login')}
          fullWidth
        />
      )}
    </Form>
  );
};

import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { File, ImageFile } from '../../../../shared';
import { GalleryItem } from '../../../models';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FilePreview.module.scss';

interface FilePreviewProps {
  galleryItems: GalleryItem[];
  setGalleryItems?: (uploads: GalleryItem[]) => void;
  activeGalleryItemIndex?: number;
  setActiveGalleryItemIndex?: (index: number) => void;
  settingsOpen?: boolean;
  setSettingsOpen?: (isOpen: boolean) => void;
  activeMediaTab?: boolean;
  setActiveMediaTab?: (isActive: boolean) => void;
  loading?: boolean;
}

export const FilePreview: FunctionComponent<FilePreviewProps> = memo(
  ({
    galleryItems,
    setGalleryItems,
    activeGalleryItemIndex,
    setActiveGalleryItemIndex,
    settingsOpen,
    setSettingsOpen,
    activeMediaTab,
    setActiveMediaTab,
    loading,
  }) => {
    const [draggingImageId, setDraggingImageId] = useState<number | null>(null);

    const isImageDraggable = useMemo(
      () =>
        !loading &&
        galleryItems.filter(({ image, audio, video, pdf }) =>
          Boolean(!audio && !video && !pdf && image)
        )?.length > 1,
      [galleryItems, loading]
    );

    const onImageDragStart = useCallback((id: number) => {
      setDraggingImageId(id);
    }, []);

    const onImageDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      (event.target as HTMLDivElement).classList.add(classes['file-preview__teaser--ghost']);
    }, []);

    const onImageDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
      (event.target as HTMLDivElement).classList.remove(classes['file-preview__teaser--ghost']);
    }, []);

    const onImageDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
      (event.target as HTMLDivElement).classList.remove(classes['file-preview__teaser--ghost']);
      setDraggingImageId(null);
    }, []);

    const onImageDrop = useCallback(
      (event: React.DragEvent<HTMLDivElement>, id: number) => {
        event.preventDefault();

        (event.target as HTMLDivElement).classList.remove(classes['file-preview__teaser--ghost']);

        const galleryItemsCopy = [...galleryItems];

        const draggingIndex = galleryItemsCopy.findIndex(
          ({ image }) => image?.id === draggingImageId
        );

        if (draggingIndex === -1) {
          setDraggingImageId(null);
          return;
        }

        const currentIndex = galleryItemsCopy.findIndex(({ image }) => image?.id === id);

        [galleryItemsCopy[draggingIndex], galleryItemsCopy[currentIndex]] = [
          galleryItemsCopy[currentIndex],
          galleryItemsCopy[draggingIndex],
        ];

        setGalleryItems?.(galleryItemsCopy);
        setDraggingImageId(null);
      },
      [draggingImageId, galleryItems, setGalleryItems]
    );

    const removePreview = useCallback(
      (index: number) => {
        const uploadsCopy = [...galleryItems];
        uploadsCopy.splice(index, 1);
        setGalleryItems?.(uploadsCopy);

        if (index === activeGalleryItemIndex || uploadsCopy.length === 1) {
          setActiveGalleryItemIndex?.(0);
        }

        if (!uploadsCopy.length) {
          setActiveMediaTab?.(false);
        }
      },
      [
        activeGalleryItemIndex,
        galleryItems,
        setActiveGalleryItemIndex,
        setActiveMediaTab,
        setGalleryItems,
      ]
    );

    const removeTeaser = useCallback(
      (index: number) => {
        const uploadsCopy = [...galleryItems];
        uploadsCopy[index] = { ...uploadsCopy[index], image: undefined };
        setGalleryItems?.(uploadsCopy);
      },
      [galleryItems, setGalleryItems]
    );

    const activeGalleryItemChange = useCallback(
      (index: number) => {
        setActiveGalleryItemIndex?.(index);
        setSettingsOpen?.(true);
        setActiveMediaTab?.(true);
      },
      [setActiveGalleryItemIndex, setActiveMediaTab, setSettingsOpen]
    );

    const isGalleryItemActive = useCallback(
      (index: number) => {
        return Boolean(
          !loading && activeMediaTab && settingsOpen && index === activeGalleryItemIndex
        );
      },
      [activeGalleryItemIndex, activeMediaTab, loading, settingsOpen]
    );

    const previewActions = useCallback(
      ({ index, removeAction }: { index: number; removeAction: (index: number) => void }) => {
        if (!setGalleryItems) {
          return null;
        }

        return (
          <div className={classes['file-preview__actions']}>
            {setActiveGalleryItemIndex && (
              <IconLabel
                className={classNames(classes['file-preview__actions-settings'], {
                  [classes['file-preview__actions-settings--active']]: isGalleryItemActive(index),
                })}
                iconId={'dots-menu'}
                iconSize={30}
                onClick={() => activeGalleryItemChange(index)}
              />
            )}
            <span
              className={classes['file-preview__actions-remove']}
              onClick={() => removeAction(index)}
            ></span>
          </div>
        );
      },
      [activeGalleryItemChange, isGalleryItemActive, setActiveGalleryItemIndex, setGalleryItems]
    );

    const preview = useCallback(
      ({
        file,
        image,
        fileType,
        index,
      }: {
        file?: File;
        image?: ImageFile;
        fileType?: string;
        index: number;
      }) => {
        const { id: fileId } = { ...file };
        const { id: imageId, url: imageUrl } = { ...image };

        const id = fileId ?? imageId;

        if (!id) {
          return;
        }

        const removeAction = file && image ? removeTeaser : removePreview;

        return (
          <div
            key={id}
            {...(isImageDraggable && {
              draggable: true,
              onDragStart: () => onImageDragStart(id),
              onDrop: (event) => onImageDrop(event, id),
              onDragEnd: onImageDragEnd,
              onDragLeave: onImageDragLeave,
              onDragOver: onImageDragOver,
            })}
            className={classNames(classes['file-preview__teaser'], {
              [classes['file-preview__teaser--loading']]: loading,
              [classes['file-preview__teaser--move']]: isImageDraggable,
              [classes['file-preview__teaser--dragging']]: Boolean(draggingImageId),
            })}
          >
            <div
              className={classNames(classes['file-preview__teaser-url'], {
                [classes['file-preview__teaser-url--overlay']]: Boolean(fileType),
              })}
              {...(imageUrl && { style: { backgroundImage: `url('${imageUrl}')` } })}
            ></div>
            {fileType && (
              <IconLabel
                iconId={fileType}
                iconSize={32}
                className={classes['file-preview__teaser-icon']}
                color={'#ffffff'}
                singleColor
                nonClickable
              />
            )}
            {previewActions({ index, removeAction })}
          </div>
        );
      },
      [
        draggingImageId,
        isImageDraggable,
        loading,
        onImageDragEnd,
        onImageDragLeave,
        onImageDragOver,
        onImageDragStart,
        onImageDrop,
        previewActions,
        removePreview,
        removeTeaser,
      ]
    );

    const getPreview = useCallback(
      (item: GalleryItem, index: number) => {
        const { image, audio, video, pdf } = item;

        switch (true) {
          case Boolean(audio):
            return audio && preview({ file: audio, image, fileType: 'audio', index });
          case Boolean(video):
            return video && preview({ file: video, image, fileType: 'video', index });
          case Boolean(pdf):
            return pdf && preview({ file: pdf, image, fileType: 'pdf', index });
          case Boolean(image):
            return image && preview({ image, index });
        }
      },
      [preview]
    );

    if (!galleryItems.length) {
      return null;
    }

    return (
      <div className={classes['file-preview']}>
        {galleryItems.map((item, index) => getPreview(item, index))}
      </div>
    );
  }
);

import { ChangeEvent, forwardRef, memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../context';
import { CommentAttachment } from '../../../../services';
import { useFreeLoginPopup } from '../../../hooks';
import { layoutPath } from '../../../utils';
import { Avatar } from '../../Avatar';
import { CommentPostProps } from '../Comments';
import { CommentInput } from './CommentInput';

import classes from './CommentBox.module.scss';

interface CommentBoxProps {
  onSubmit: () => ({ content, attachments }: CommentPostProps) => void;
  content?: string;
  attachments?: CommentAttachment[];
  onClose?: () => void;
}

export interface EmojiSelectProps {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
}

export const CommentBox = memo(
  forwardRef<HTMLTextAreaElement, CommentBoxProps>(
    ({ onSubmit, content, attachments, onClose }, ref) => {
      const { t } = useTranslation();

      const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup();

      const { avatar, isAnonymous } = useContext(UserContext).userProfile;

      const [commentText, setCommentText] = useState<string>(content ?? '');

      const [commentAttachments, setCommentAttachments] = useState<CommentAttachment[]>(
        attachments ?? []
      );

      const [loading, setLoading] = useState<boolean>(false);

      const handleTextChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
        setCommentText(target.value);
      };

      const handleEmojiSelect = useCallback(({ native }: EmojiSelectProps) => {
        setCommentText((text) => `${text}${native}`);
      }, []);

      const submitHandler = useCallback(async () => {
        if (isAnonymous) {
          setCommentText('');
          freeLoginHandler();
          return;
        }

        try {
          setLoading(true);
          await onSubmit()({
            content: commentText.replace(/<|>/g, '').trim(),
            attachments: commentAttachments.map(({ imageId, videoId }) => {
              return { imageId, videoId };
            }),
          });
        } catch (_) {
          toast.error(t('comments.post-error'));
        } finally {
          setCommentText('');
          setCommentAttachments([]);
          setLoading(false);
        }
      }, [commentAttachments, commentText, freeLoginHandler, isAnonymous, onSubmit, t]);

      return (
        <>
          <div className={classes['comment-box']}>
            <Link onClick={() => window.scrollTo(0, 0)} to={layoutPath('/profile')}>
              <Avatar url={avatar?.url} />
            </Link>
            <div className={classes['comment-box__input-wrapper']}>
              <CommentInput
                ref={ref}
                text={commentText}
                setText={handleTextChange}
                attachments={commentAttachments}
                setAttachments={setCommentAttachments}
                onEmojiSelect={handleEmojiSelect}
                onSubmit={submitHandler}
                onClose={onClose}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>

          {freeLoginModal}
        </>
      );
    }
  )
);

/* eslint-disable @typescript-eslint/no-explicit-any */
interface OptionalProps {
  name: string;
  condition: boolean;
  value: any;
}

export const optional = ({ name, condition, value }: OptionalProps) => {
  return condition ? `${name}: ${value}` : '';
};

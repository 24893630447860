import { FunctionComponent, memo, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { COPY_CUT_COOKIE_KEY } from '../../../constants';
import { CookieActionType, cookieOptions, getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FeedCardCut.module.scss';

interface FeedCardCutProps {
  isCollapsed?: boolean;
  isHorizontalFeedCard?: boolean;
}

export const FeedCardCut: FunctionComponent<FeedCardCutProps> = memo(
  ({ isCollapsed, isHorizontalFeedCard }) => {
    const { t } = useTranslation();

    const [, , removeCookie] = useCookies([COPY_CUT_COOKIE_KEY]);

    const onClickHandler = useCallback(() => {
      removeCookie(COPY_CUT_COOKIE_KEY, cookieOptions({ action: CookieActionType.REMOVE }));
    }, [removeCookie]);

    return (
      <div
        {...(!isHorizontalFeedCard && { id: 'cut-card' })}
        className={classNames(classes['cut-card'], {
          [classes['cut-card--collapsed']]: isCollapsed,
          [classes['cut-card--horizontal']]: isHorizontalFeedCard,
        })}
      >
        {t('common.cut-card')}
        <IconLabel
          label={t('common.undo')}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={onClickHandler}
        />
      </div>
    );
  }
);

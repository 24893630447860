import { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { generateRandomString } from '../../utils';

import classNames from 'classnames';
import classes from './Tabs.module.scss';

export interface TabItem {
  index: number;
  name: string;
  content: JSX.Element;
}

interface TabsProps {
  items: TabItem[];
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
  noBackground?: boolean;
  disabled?: boolean;
  tabNamesClassName?: string;
}

export const Tabs: FunctionComponent<TabsProps> = memo(
  ({ items, selectedTabIndex, setSelectedTabIndex, noBackground, disabled, tabNamesClassName }) => {
    useEffect(() => {
      if (items.length && !items.find(({ index }) => index === selectedTabIndex)) {
        setSelectedTabIndex(items[0].index);
      }
    }, [items, selectedTabIndex, setSelectedTabIndex]);

    //needed in case of multiple identical tab components visible at the same time on screen
    //like MediaUpload component for image, audio, video, pdf, file with same tab names
    const namePostfix = useMemo(() => generateRandomString(8), []);

    return (
      <div className={classes['tabs']}>
        <div
          className={classNames(
            classes['tabs__tab-names'],
            { [classes['tabs__tab-names--no-background']]: noBackground },
            tabNamesClassName
          )}
        >
          {items.map(({ index, name }) => {
            const key = `${name}${namePostfix}`;

            return (
              <div
                key={key}
                className={classNames(classes['tabs__tab-names-item'], {
                  [classes['tabs__tab-names-item--disabled']]: disabled,
                })}
              >
                <input
                  id={key}
                  type={'radio'}
                  className={classes['tabs__tab-names-item-check']}
                  checked={index === selectedTabIndex}
                  onChange={() => setSelectedTabIndex(index)}
                />
                <label htmlFor={key} className={classes['tabs__tab-names-item-label']}>
                  {name}
                </label>
              </div>
            );
          })}
        </div>
        <div className={classes['tabs__tab-content']}>
          {items.find(({ index }) => index === selectedTabIndex)?.content}
        </div>
      </div>
    );
  }
);

import { enUS, de } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

registerLocale('de', de);

export enum LanguageByCode {
  'en-US' = 'EN',
  'de-DE' = 'DE',
  'es-ES' = 'ES',
}

export const getEmojiPickerLocale = () => {
  switch (navigator.languages?.[0]) {
    case 'de':
    case 'de-DE':
      return 'de';
    default:
      return 'en';
  }
};

export const getDateFnsLocale = () => {
  switch (navigator.languages?.[0]) {
    case 'de':
    case 'de-DE':
      return de;
    default:
      return enUS;
  }
};

export const getI18nLocale = () => {
  switch (navigator.languages?.[0]) {
    case 'de':
    case 'de-DE':
      return 'de-DE';
    default:
      return 'en-US';
  }
};

export const getLocale = () => {
  switch (navigator.languages?.[0]) {
    case 'de':
    case 'de-DE':
      return 'de-DE';
    case 'es':
    case 'es-ES':
      return 'es-ES';
    default:
      return 'en-US';
  }
};

import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { MusicPlayerContext, UserContext } from '../../../../context';
import { MUSIC_PLAYER_COOKIE_KEY } from '../../../constants';
import { CookieActionType, cookieOptions, durationFormatter, getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classes from './MusicPlayerTrigger.module.scss';

interface MusicPlayerTriggerProps {
  storyId: number;
  cardId: number;
  cardTitle: string;
  src?: string;
  caption?: string;
}

export const MusicPlayerTrigger: FunctionComponent<MusicPlayerTriggerProps> = memo(
  ({ storyId, cardId, cardTitle, src, caption }) => {
    const { t } = useTranslation();

    const { musicPlayerData, setMusicPlayerData } = useContext(MusicPlayerContext);

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { cardId: audioCardId, isPlaying: isAudioPlaying, src: audioSrc } = musicPlayerData;

    const [cookies, setCookie] = useCookies([MUSIC_PLAYER_COOKIE_KEY]);

    const { playlist = {} } = { ...cookies[MUSIC_PLAYER_COOKIE_KEY] };

    const track = useMemo(() => playlist[`${cardId}_${src}`], [cardId, playlist, src]);

    const { currentTime: trackCurrentTime = 0, played: trackPlayed = false } = { ...track };

    const onPlay = useCallback(() => {
      if (!src) {
        return;
      }

      setMusicPlayerData({
        ...musicPlayerData,
        channelId,
        storyId,
        cardId,
        cardTitle,
        src,
        isPlaying: true,
      });
    }, [cardId, cardTitle, channelId, musicPlayerData, setMusicPlayerData, src, storyId]);

    const onPause = useCallback(() => {
      setMusicPlayerData({ ...musicPlayerData, isPlaying: false });
    }, [musicPlayerData, setMusicPlayerData]);

    const isPlaying = useMemo(
      () => isAudioPlaying && audioCardId === cardId && audioSrc === src,
      [audioCardId, audioSrc, cardId, isAudioPlaying, src]
    );

    const reset = useMemo(
      () => Boolean(!isPlaying && trackCurrentTime && !trackPlayed),
      [isPlaying, trackCurrentTime, trackPlayed]
    );

    const onClickHander = useCallback(() => {
      isPlaying ? onPause() : onPlay();
    }, [isPlaying, onPause, onPlay]);

    const resetHandler = useCallback(() => {
      playlist[`${cardId}_${src}`] = { played: false, currentTime: 0 };

      setCookie(
        MUSIC_PLAYER_COOKIE_KEY,
        { playlist },
        cookieOptions({ action: CookieActionType.SET })
      );

      onPlay();
    }, [cardId, onPlay, playlist, setCookie, src]);

    const label = useMemo(() => {
      if (isPlaying) {
        return t('musicPlayer.playing');
      }

      switch (true) {
        case Boolean(trackPlayed):
          return t('musicPlayer.played');
        case Boolean(trackCurrentTime):
          return `${t('musicPlayer.continue-at')} ${durationFormatter(trackCurrentTime)}`;
        default:
          return t('musicPlayer.play');
      }
    }, [isPlaying, t, trackCurrentTime, trackPlayed]);

    if (!src) {
      return null;
    }

    return (
      <>
        <div className={classes['trigger']}>
          <IconLabel
            iconId={`${isPlaying ? 'pause' : 'play'}`}
            iconSize={40}
            color={getCssVar('--media-audio-icon-color')}
            onClick={onClickHander}
            label={label}
            singleColor
          />

          {reset && (
            <IconLabel
              iconId={'reset'}
              className={classes['trigger__reset']}
              color={getCssVar('--media-audio-icon-color')}
              onClick={resetHandler}
              singleColor
            />
          )}
        </div>
        {caption && <div className={classes['caption']}>{caption}</div>}
      </>
    );
  }
);

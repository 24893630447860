import { StoryTabType } from '../../pages';
import { Story } from '../../services';
import { isAdminLayout } from './isAdminLayout';

interface StoryTabItemsLinkProps {
  story: Story;
}

export const getStoryTabItemsLink = ({ story }: StoryTabItemsLinkProps) => {
  const { id, settings } = story;

  const { includeInMixTab, includeInMixTab2 } = settings;

  const getStoryTab = () => {
    switch (true) {
      case includeInMixTab:
        return StoryTabType.tab1;
      case includeInMixTab2:
        return StoryTabType.tab2;
    }
  };

  return `/${isAdminLayout() ? 'admin/storyTab' : getStoryTab()}/${id}/items`;
};

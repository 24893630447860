import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { FeedCard } from '../../../../components';
import { ConfigContext, UserContext } from '../../../../context';
import { StoryCardStatus } from '../../../../services';
import {
  FeedCardDrag,
  FeedCardLoader,
  IconLabel,
  useAppDispatch,
  useAppSelector,
} from '../../../../shared';
import {
  getStoryCardsUserProfileFeed,
  loadMoreStoryCardsUserProfileFeed,
  updateProfileFeedCardPosition,
} from '../../../../slices';
import { NoContent } from '../../../Error';
import { ProfileFeedCreateCard, ProfileFeedCreateCardMode } from '../ProfileFeedCreateCard';

import classes from './ProfileFeedItems.module.scss';

export const ProfileFeedItems: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { enabled } = useContext(ConfigContext).config.elements.profile.feed;

  const { userProfile } = useContext(UserContext);

  const { id } = useParams();

  const isProfileOther = useMemo(() => Boolean(id), [id]);

  const userId = Number(id ?? userProfile.id);

  const historyState = useMemo(() => window.history.state, []);

  const [dragId, setDragId] = useState<number | null>(null);

  const [dropProcessing, setDropProcessing] = useState<boolean>(false);

  const {
    storyCardsUserProfileFeed: cards,
    page,
    hasNextPage,
    isFetching,
  } = useAppSelector(({ storyCardsUserProfileFeed }) => storyCardsUserProfileFeed);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    dispatch(
      getStoryCardsUserProfileFeed({
        userId,
        ...(isProfileOther && { filters: { status: [StoryCardStatus.PUBLISHED] } }),
      })
    );
  }, [dispatch, enabled, isProfileOther, userId]);

  const loadMoreStoryCardsUserProfileFeedItems = useCallback(() => {
    dispatch(
      loadMoreStoryCardsUserProfileFeed({
        userId,
        page: page + 1,
        ...(isProfileOther && { filters: { status: [StoryCardStatus.PUBLISHED] } }),
      })
    );
  }, [dispatch, isProfileOther, page, userId]);

  const content = useMemo(() => {
    if (!Boolean(cards.length)) {
      return <NoContent subtitle={t('profileFeed.no-content')} />;
    }

    return (
      <InfiniteScroll
        next={loadMoreStoryCardsUserProfileFeedItems}
        hasMore={hasNextPage}
        loader={<FeedCardLoader />}
        dataLength={cards.length}
        className={classes['profile-feed__content']}
      >
        <div className={classes['profile-feed__content-cards']}>
          {cards.map((card) => {
            const { storyId, id: storyCardId, position, permissions } = card;
            const { allowToUpdatePosition } = permissions;

            if (!allowToUpdatePosition) {
              return <FeedCard key={id} card={card} isProfileFeedCard />;
            }

            return (
              <FeedCardDrag
                key={storyCardId}
                storyId={storyId}
                storyCardId={storyCardId}
                dragId={dragId}
                setDragId={setDragId}
                dropProcessing={dropProcessing}
                setDropProcessing={setDropProcessing}
                position={position}
                updatePosition={updateProfileFeedCardPosition}
              >
                <FeedCard card={card} isProfileFeedCard />
              </FeedCardDrag>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  }, [cards, dragId, dropProcessing, hasNextPage, id, loadMoreStoryCardsUserProfileFeedItems, t]);

  if (!enabled) {
    return null;
  }

  if (isFetching) {
    return <FeedCardLoader />;
  }

  return (
    <div className={classes['profile-feed']}>
      <div className={classes['profile-feed__header']}>
        <IconLabel
          {...(historyState && { iconId: 'arrow-rounded-left' })}
          label={t('profileFeed.title')}
          labelClassName={classes['profile-feed__header-title']}
          onClick={() => historyState && navigate(-1)}
          color={'#43445b'}
          singleColor
        />
        {!isProfileOther && <ProfileFeedCreateCard mode={ProfileFeedCreateCardMode.BUTTON} />}
      </div>
      {content}
    </div>
  );
};

import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../context';
import { NotificationsTabType } from '../../../../pages';
import {
  ChannelAppTabAction,
  StoryCardSection,
  StoryCardStatus,
  StoryStatus,
} from '../../../../services';
import { pinCard, repostCard, toggleCardSection } from '../../../../slices';
import {
  COPY_CUT_COOKIE_KEY,
  CopyCutAction,
  NOTIFICATIONS_ADMIN_COOKIE_KEY,
} from '../../../constants';
import {
  useAppDispatch,
  useAppTabsLabel,
  useNewsFeed,
  useSearchFeed,
  useUserFeed,
} from '../../../hooks';
import {
  CookieActionType,
  CookieExpirationType,
  cookieOptions,
  getChannelDomain,
  getOrganisationDomain,
  getWebAppUrl,
} from '../../../utils';
import { Deeplink, DeeplinkType } from '../../Deeplink';
import { IconLabel } from '../../IconLabel';
import { CardActionsCommon, CardActionsProps } from '../CardActionsCommon';

import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';

export const CardActionsAdmin: FunctionComponent<CardActionsProps> = memo(
  ({ card, isHorizontalFeedCard = false }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { getAppTabsLabel } = useAppTabsLabel();

    const { id } = useContext(UserContext).userInfo.userData;

    const { pathname } = useLocation();

    const { id: collaboratorId } = useParams();

    const { getNewsFeedItems, pinnedStorySection } = useNewsFeed();

    const { getSearchFeedItems } = useSearchFeed();

    const userId = useMemo(() => Number(collaboratorId ?? id), [collaboratorId, id]);

    const { getUserFeedItems } = useUserFeed({ userId });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setCookie] = useCookies([COPY_CUT_COOKIE_KEY, NOTIFICATIONS_ADMIN_COOKIE_KEY]);

    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    const isNewsFeedAdmin = useMemo(() => pathname === '/admin', [pathname]);

    const isSearchFeedAdmin = useMemo(() => pathname === '/admin/search', [pathname]);

    const isUserFeedAdmin = useMemo(
      () => [`/admin/profile${collaboratorId ? `/${collaboratorId}` : ''}`].includes(pathname),
      [collaboratorId, pathname]
    );

    const channelDomain = useMemo(() => getChannelDomain(), []);

    const {
      id: cardId,
      permissions: cardPermissions,
      isReadOnly: cardReadOnly,
      section: cardSection,
      isPinnedToTopOfNewsFeed: cardPinned,
      status: cardStatus,
      story,
    } = card;

    const { allowToDelete: cardAllowToDelete } = cardPermissions;

    const {
      id: storyId,
      isReadOnly: storyReadOnly,
      status: storyStatus,
      settings: storySettings,
    } = story;

    const cardPublished = useMemo(() => cardStatus === StoryCardStatus.PUBLISHED, [cardStatus]);

    const storyPublished = useMemo(() => storyStatus === StoryStatus.PUBLISHED, [storyStatus]);

    const copyCutHandler = useCallback(
      (action: CopyCutAction) => {
        setCookie(
          COPY_CUT_COOKIE_KEY,
          {
            organisation: getOrganisationDomain(),
            storyId,
            cardId,
            cardReadOnly,
            action,
          },
          cookieOptions({ action: CookieActionType.SET, expiration: CookieExpirationType.DAY })
        );
        setIsListOpen(false);
      },
      [cardId, cardReadOnly, setCookie, storyId]
    );

    const copyHandler = useCallback(() => {
      copyCutHandler(CopyCutAction.COPY);
      toast(t('cardActions.copied'));
    }, [copyCutHandler, t]);

    const cutHandler = useCallback(() => {
      copyCutHandler(CopyCutAction.CUT);

      if (!isHorizontalFeedCard) {
        setTimeout(() => {
          document
            .querySelector('#cut-card')
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
      }
    }, [copyCutHandler, isHorizontalFeedCard]);

    const cardInWebFeed = useMemo(
      () => cardSection.includes(StoryCardSection.WEB_FEED),
      [cardSection]
    );

    const cardInAppFeed = useMemo(
      () => cardSection.includes(StoryCardSection.MOBILE_FEED),
      [cardSection]
    );

    const storyInWebFeed = useMemo(
      () => storySettings.includeInWebappNewsFeed,
      [storySettings.includeInWebappNewsFeed]
    );

    const storyInAppFeed = useMemo(
      () => storySettings.includeInNewsFeed,
      [storySettings.includeInNewsFeed]
    );

    const toggleInWebFeedLabel = `${cardInWebFeed ? 'X' : '✓'} ${getAppTabsLabel(
      ChannelAppTabAction.NEWS_FEED
    )}`;

    const toggleInAppFeedLabel = `${cardInAppFeed ? 'X' : '✓'} ${getAppTabsLabel(
      ChannelAppTabAction.NEWS_FEED
    )}`;

    const pinAtTopLabel = cardPinned ? t('cardActions.unpin') : t('cardActions.pin');

    const refreshNewsFeed = useCallback(() => {
      getNewsFeedItems();
      window.scrollTo(0, 0);
    }, [getNewsFeedItems]);

    const refreshUserFeed = useCallback(() => {
      getUserFeedItems();
      window.scrollTo(0, 0);
    }, [getUserFeedItems]);

    const refreshSearchFeed = useCallback(() => {
      getSearchFeedItems();
      window.scrollTo(0, 0);
    }, [getSearchFeedItems]);

    const refreshFeeds = useCallback(() => {
      switch (true) {
        case isNewsFeedAdmin: {
          return !pinnedStorySection && refreshNewsFeed();
        }
        case isSearchFeedAdmin: {
          return refreshSearchFeed();
        }
        case isUserFeedAdmin: {
          return refreshUserFeed();
        }
      }
    }, [
      isNewsFeedAdmin,
      isSearchFeedAdmin,
      isUserFeedAdmin,
      pinnedStorySection,
      refreshNewsFeed,
      refreshSearchFeed,
      refreshUserFeed,
    ]);

    const toggleInWebFeed = useCallback(async () => {
      setIsListOpen(false);

      try {
        const payload = await toast.promise(
          dispatch(
            toggleCardSection({
              storyId,
              storyCardId: cardId,
              fields: { includeInWebappNewsFeed: !cardInWebFeed },
            })
          ).unwrap(),
          {
            pending: t(`cardActions.${cardInWebFeed ? 'hiding' : 'showing'}-in-web-feed`),
            success: t(`cardActions.${cardInWebFeed ? 'hided' : 'showed'}-in-web-feed`),
            error: t(`cardActions.${cardInWebFeed ? 'hide' : 'show'}-in-web-feed-error`),
          }
        );

        if (!payload) {
          return;
        }

        refreshFeeds();
      } catch (_) {}
    }, [cardId, cardInWebFeed, dispatch, refreshFeeds, storyId, t]);

    const toggleInAppFeed = useCallback(async () => {
      setIsListOpen(false);

      try {
        const payload = await toast.promise(
          dispatch(
            toggleCardSection({
              storyId,
              storyCardId: cardId,
              fields: { includeInNewsFeed: !cardInAppFeed },
            })
          ).unwrap(),
          {
            pending: t(`cardActions.${cardInAppFeed ? 'hiding' : 'showing'}-in-app-feed`),
            success: t(`cardActions.${cardInAppFeed ? 'hided' : 'showed'}-in-app-feed`),
            error: t(`cardActions.${cardInAppFeed ? 'hide' : 'show'}-in-app-feed-error`),
          }
        );

        if (!payload) {
          return;
        }

        refreshFeeds();
      } catch (_) {}
    }, [cardId, cardInAppFeed, dispatch, refreshFeeds, storyId, t]);

    const togglePinHandler = useCallback(async () => {
      setIsListOpen(false);

      try {
        const payload = await toast.promise(
          dispatch(pinCard({ storyId, storyCardId: cardId })).unwrap(),
          {
            pending: t(`cardActions.${cardPinned ? 'unpinning' : 'pinning'}`),
            success: t(`cardActions.${cardPinned ? 'unpinned' : 'pinned'}`),
            error: t(`cardActions.${cardPinned ? 'unpin' : 'pin'}-error`),
          }
        );

        if (!payload) {
          return;
        }

        refreshFeeds();
      } catch (_) {}
    }, [cardId, cardPinned, dispatch, refreshFeeds, storyId, t]);

    const repostHandler = useCallback(async () => {
      setIsListOpen(false);

      try {
        const storyCard = await toast.promise(
          dispatch(repostCard({ storyId, storyCardId: cardId })).unwrap(),
          {
            pending: t('cardActions.reposting'),
            success: t('cardActions.reposted'),
            error: t('cardActions.repost-error'),
          }
        );

        if (!storyCard) {
          return;
        }

        refreshFeeds();
      } catch (_) {}
    }, [cardId, dispatch, refreshFeeds, storyId, t]);

    const pushHandler = useCallback(() => {
      setCookie(
        NOTIFICATIONS_ADMIN_COOKIE_KEY,
        { selectedTabIndex: NotificationsTabType.PUSH },
        cookieOptions({ action: CookieActionType.SET })
      );

      navigate(`/admin/notifications?storyId=${storyId}&cardId=${cardId}`);
    }, [cardId, navigate, setCookie, storyId]);

    const previewlinkHandler = useCallback(async () => {
      setIsListOpen(false);

      if (!navigator.clipboard) {
        return;
      }

      await navigator.clipboard.writeText(
        `${getWebAppUrl(channelDomain)}/details/${storyId}/${cardId}`
      );

      toast(t('cardActions.preview-link-copied'));
    }, [cardId, channelDomain, storyId, t]);

    return (
      <CardActionsCommon
        card={card}
        isHorizontalFeedCard={isHorizontalFeedCard}
        isListOpen={isListOpen}
        setIsListOpen={setIsListOpen}
        adminActions={
          <>
            <IconLabel
              iconId={'copy'}
              iconSize={18}
              label={t('cardActions.copy')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={copyHandler}
              singleColor
            />

            {!storyReadOnly && cardAllowToDelete && (
              <IconLabel
                iconId={'cut'}
                iconSize={18}
                label={t('cardActions.cut')}
                className={dropdownMenuClasses['dropdown-menu__item--small']}
                onClick={cutHandler}
                singleColor
              />
            )}

            {!cardReadOnly && (
              <IconLabel
                iconId={'repost'}
                iconSize={18}
                label={t('cardActions.repost')}
                className={dropdownMenuClasses['dropdown-menu__item--small']}
                onClick={repostHandler}
                singleColor
              />
            )}

            {!isMobileOnly && (
              <IconLabel
                iconId={'bell'}
                iconSize={18}
                label={t('cardActions.push')}
                className={dropdownMenuClasses['dropdown-menu__item--small']}
                onClick={pushHandler}
                singleColor
              />
            )}

            <IconLabel
              iconId={'preview-link'}
              iconSize={18}
              label={t('cardActions.preview-link')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={previewlinkHandler}
              singleColor
            />

            <Deeplink
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              type={DeeplinkType.CARD}
              storyId={storyId}
              cardId={cardId}
              onClick={() => setIsListOpen(false)}
            />

            {storyPublished && !storyReadOnly && cardPublished && !cardReadOnly && (
              <>
                {(storyInWebFeed || storyInAppFeed) && (
                  <IconLabel
                    iconId={'pin'}
                    iconSize={18}
                    label={pinAtTopLabel}
                    className={dropdownMenuClasses['dropdown-menu__item--small']}
                    onClick={togglePinHandler}
                    singleColor
                  />
                )}
                {!storyInWebFeed && (
                  <IconLabel
                    iconId={'web'}
                    iconSize={18}
                    label={toggleInWebFeedLabel}
                    className={dropdownMenuClasses['dropdown-menu__item--small']}
                    onClick={toggleInWebFeed}
                    singleColor
                  />
                )}
                {!storyInAppFeed && (
                  <IconLabel
                    iconId={'mobile'}
                    iconSize={18}
                    label={toggleInAppFeedLabel}
                    className={dropdownMenuClasses['dropdown-menu__item--small']}
                    onClick={toggleInAppFeed}
                    singleColor
                  />
                )}
              </>
            )}
          </>
        }
      />
    );
  }
);

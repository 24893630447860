import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FileAcceptType, useTags } from '../../../../hooks';
import { GalleryItem } from '../../../../models';
import { getCssVar, isAdminLayout, isEmptyString } from '../../../../utils';
import { FilePreview, FileUpload } from '../../../File';
import { IconLabel } from '../../../IconLabel';
import { TextAreaField } from '../../../TextAreaField';
import { CreateCardSettingsTabType, LinkPostArticleFields } from '../../CreateCardModal';
import { PostToggleFields, PostToggleFieldsItem } from '../../PostToggleFields';

import classNames from 'classnames';
import classes from './LinkPostArticle.module.scss';

interface LinkPostArticleProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  url: string;
  articleData: LinkPostArticleFields;
  setArticle: (article: LinkPostArticleFields) => void;
  activeLinkPostGalleryItemIndex: number;
  setActiveLinkPostGalleryItemIndex: (index: number) => void;
  settingsOpen: boolean;
  setSettingsOpen: (isOpen: boolean) => void;
  activeLinkPostSettingsMediaTab: boolean;
  setActiveLinkPostSettingsTabIndex: (index: number) => void;
  setLinkPostArticleGalleryItems: (galleryItems: GalleryItem[]) => void;
}

export type LinkPostArticleToggleFields = {
  [key in keyof LinkPostArticleFields]?: PostToggleFieldsItem;
};

export const LinkPostArticle: FunctionComponent<LinkPostArticleProps> = memo(
  ({
    loading,
    setLoading,
    url,
    articleData,
    setArticle,
    activeLinkPostGalleryItemIndex,
    setActiveLinkPostGalleryItemIndex,
    settingsOpen,
    setSettingsOpen,
    activeLinkPostSettingsMediaTab,
    setActiveLinkPostSettingsTabIndex,
    setLinkPostArticleGalleryItems,
  }) => {
    const { t } = useTranslation();

    const { hasTags } = useTags();

    const headlineRef = useRef<HTMLTextAreaElement>(null);
    const abstractRef = useRef<HTMLTextAreaElement>(null);
    const authorRef = useRef<HTMLTextAreaElement>(null);

    const {
      sourceName,
      title,
      headline,
      abstract,
      contentAuthor,
      gallery: linkPostArticleGalleryItems,
    } = articleData;

    const [postFieldsToggling, setPostFieldsToggling] = useState<LinkPostArticleToggleFields>({
      abstract: {
        ref: abstractRef,
        title: t('addContent.link-post-article-abstract'),
        iconId: '',
        hidden: false,
      },
      contentAuthor: {
        ref: authorRef,
        title: t('addContent.link-post-article-author'),
        iconId: '',
        hidden: Boolean(isEmptyString(contentAuthor)),
      },
    });

    useEffect(() => {
      if (headlineRef.current) {
        headlineRef.current.focus();
        headlineRef.current?.setSelectionRange(
          headlineRef.current?.value.length,
          headlineRef.current?.value.length
        );
      }
    }, []);

    const keyDownHandler = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>, field: keyof LinkPostArticleFields) => {
        const { key, target } = event;

        if (key === 'Backspace' && !(target as HTMLTextAreaElement).value) {
          setPostFieldsToggling({
            ...postFieldsToggling,
            [field]: { ...postFieldsToggling[field], hidden: true },
          });
        }
      },
      [postFieldsToggling]
    );

    const fieldChangeHandler = useCallback(
      (name: keyof LinkPostArticleFields, value: string) => {
        setArticle({ ...articleData, [name]: value });
      },
      [articleData, setArticle]
    );

    const tagsClickHandler = useCallback(() => {
      setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.TAGS);
      setSettingsOpen(true);
    }, [setActiveLinkPostSettingsTabIndex, setSettingsOpen]);

    const content = useMemo(() => {
      return (
        <>
          <div className={classes['link-post-article__url']}>
            <span className={classes['link-post-article__url-label']}>{t('common.link')}:</span>
            <a
              href={url}
              target={'_blank'}
              rel={'noreferrer'}
              className={classes['link-post-article__url-value']}
            >
              {url}
            </a>
          </div>

          <TextAreaField
            ref={headlineRef}
            inputClassName={classes['link-post-article__headline']}
            value={headline}
            placeholder={t('addContent.link-post-article-headline')}
            maxLength={360}
            onChange={({ target }) => fieldChangeHandler('headline', target.value)}
          />

          <FileUpload
            galleryItems={linkPostArticleGalleryItems}
            setGalleryItems={setLinkPostArticleGalleryItems}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={[FileAcceptType.IMAGE]}
            uploadLimit={1}
          />

          <FilePreview
            galleryItems={linkPostArticleGalleryItems}
            setGalleryItems={setLinkPostArticleGalleryItems}
            activeGalleryItemIndex={activeLinkPostGalleryItemIndex}
            setActiveGalleryItemIndex={setActiveLinkPostGalleryItemIndex}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            activeMediaTab={activeLinkPostSettingsMediaTab}
            setActiveMediaTab={(isActive) => {
              if (!isActive) {
                if (isAdminLayout()) {
                  setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.CARD);
                }
                return;
              }
              setActiveLinkPostSettingsTabIndex(CreateCardSettingsTabType.MEDIA);
            }}
          />

          <div className={classes['link-post-article__fields']}>
            <TextAreaField
              inputClassName={classes['link-post-article__sourceName']}
              value={sourceName}
              placeholder={t('addContent.link-post-article-sourceName')}
              maxLength={160}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('sourceName', target.value)}
              disableLineBreak
            />

            <TextAreaField
              inputClassName={classes['link-post-article__title']}
              value={title}
              placeholder={t('addContent.link-post-article-title')}
              maxLength={160}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('title', target.value)}
              disableLineBreak
            />

            <TextAreaField
              ref={abstractRef}
              inputClassName={classes['link-post-article__abstract']}
              value={abstract}
              placeholder={t('addContent.link-post-article-abstract')}
              maxLength={700}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('abstract', target.value)}
              onKeyDown={(event) => keyDownHandler(event, 'abstract')}
              hidden={postFieldsToggling?.abstract?.hidden}
            />

            <TextAreaField
              ref={authorRef}
              inputClassName={classes['link-post-article__author']}
              value={contentAuthor}
              placeholder={t('addContent.link-post-article-author')}
              maxLength={60}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('contentAuthor', target.value)}
              onKeyDown={(event) => keyDownHandler(event, 'contentAuthor')}
              hidden={postFieldsToggling?.contentAuthor?.hidden}
              disableLineBreak
            />
          </div>
        </>
      );
    }, [
      abstract,
      activeLinkPostGalleryItemIndex,
      activeLinkPostSettingsMediaTab,
      contentAuthor,
      fieldChangeHandler,
      headline,
      keyDownHandler,
      linkPostArticleGalleryItems,
      loading,
      postFieldsToggling?.abstract?.hidden,
      postFieldsToggling?.contentAuthor?.hidden,
      setActiveLinkPostGalleryItemIndex,
      setActiveLinkPostSettingsTabIndex,
      setLinkPostArticleGalleryItems,
      setLoading,
      setSettingsOpen,
      settingsOpen,
      sourceName,
      t,
      title,
      url,
    ]);

    return (
      <div className={classes['link-post-article']}>
        <div
          className={classNames(classes['link-post-article__scrollable'], {
            [classes['link-post-article__scrollable--mobile']]: isMobileOnly,
          })}
        >
          {content}
        </div>
        <div className={classes['link-post-article__pane']}>
          <div className={classes['link-post-article__pane-fields']}>
            {hasTags && (
              <IconLabel
                iconId={'tags'}
                iconSize={20}
                label={t('addContent.short-post-tags')}
                color={getCssVar('--create-card-link-color')}
                hoverColor={getCssVar('--create-card-link-hover-color')}
                onClick={tagsClickHandler}
              />
            )}
            <PostToggleFields
              loading={loading}
              fields={postFieldsToggling}
              setFields={setPostFieldsToggling}
            />
          </div>
        </div>
      </div>
    );
  }
);

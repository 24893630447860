import { createContext } from 'react';
import { AppTab } from '../services';

export interface ChannelBySubdomainContextProps {
  appTabs: AppTab[];
  allAppTabs: AppTab[];
  dashboardUrl: string | null;
}

export const ChannelBySubdomainContext = createContext<ChannelBySubdomainContextProps>(
  {} as ChannelBySubdomainContextProps
);

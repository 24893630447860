import { StoryCard } from '../../services';

interface UpdateStoryCardPositionProps {
  dragId: number;
  targetId: number;
  feed: StoryCard[];
}

export const updateStoryCardPosition = ({
  dragId,
  targetId,
  feed,
}: UpdateStoryCardPositionProps) => {
  const drag = feed.find(({ id }) => id === dragId);
  const target = feed.find(({ id }) => id === targetId);

  if (!drag || !target) {
    return;
  }

  const { position: dragPosition } = drag;
  const { position: targetPosition } = target;

  if (dragPosition < targetPosition) {
    feed
      .filter(({ position }) => position > dragPosition && position < targetPosition)
      .forEach((story) => (story.position -= 1));

    drag.position = targetPosition;

    target.position -= 1;
  } else {
    feed
      .filter(({ position }) => position < dragPosition && position > targetPosition)
      .forEach((story) => (story.position += 1));

    drag.position = targetPosition;

    target.position += 1;
  }

  feed.sort(({ position: posA }, { position: posB }) => posB - posA);
};

import { FunctionComponent, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationTagType } from '../../../../services';
import {
  FileSettings,
  Filter,
  TabItem,
  Tabs,
  TagsPicker,
  optionalArrayItem,
  useTags,
} from '../../../../shared';
import { StorySettings } from '../CreateStoryModal';
import { AutoNotification } from './AutoNotification';
import {
  useCreateStoryAccessFilter,
  useCreateStoryCardFilter,
  useCreateStoryDisplayFilter,
  useCreateStoryOtherFilter,
} from './Filters';

import classes from './CreateStorySettings.module.scss';

export enum CreateStorySettingsTabType {
  DISPLAY,
  CARD,
  ACCESS,
  TAGS,
  OTHER,
  MEDIA,
  AUTO_NOTIFICATION,
}

interface CreateStorySettingsProps {
  settings: StorySettings;
  onChange: (settings: StorySettings) => void;
  isStandard: boolean;
  activeSettingsTabIndex: number;
  setActiveSettingsTabIndex: (index: number) => void;
}

export const CreateStorySettings: FunctionComponent<CreateStorySettingsProps> = memo(
  ({ settings, onChange, isStandard, activeSettingsTabIndex, setActiveSettingsTabIndex }) => {
    const { t } = useTranslation();

    const { hasInternalTags, hasExternalTags, hasTags } = useTags();

    const { display, card, tags, media, access, other, autoNotification } = settings;

    const { external, setExternal, internal, setInternal } = tags;

    const { groups: displayGroups } = useCreateStoryDisplayFilter(display);

    const { groups: cardGroups } = useCreateStoryCardFilter(card);

    const { groups: accessGroups } = useCreateStoryAccessFilter();

    const { groups: otherGroups } = useCreateStoryOtherFilter(other);

    const hasMedia = useMemo(() => Boolean(media.galleryItems.length), [media.galleryItems.length]);

    const showTags = useMemo(() => Boolean(isStandard && hasTags), [hasTags, isStandard]);

    useEffect(() => {
      if (activeSettingsTabIndex === CreateStorySettingsTabType.MEDIA && !hasMedia) {
        setActiveSettingsTabIndex(CreateStorySettingsTabType.DISPLAY);
      }
    }, [activeSettingsTabIndex, hasMedia, isStandard, setActiveSettingsTabIndex]);

    const tabItems: TabItem[] = useMemo(
      () => [
        {
          index: CreateStorySettingsTabType.DISPLAY,
          name: t('createStorySettingsTabs.display'),
          content: (
            <Filter
              filter={display}
              groups={displayGroups}
              onChange={(display) => onChange({ ...settings, display })}
            />
          ),
        },
        ...optionalArrayItem(isStandard, {
          index: CreateStorySettingsTabType.CARD,
          name: t('createStorySettingsTabs.card'),
          content: (
            <Filter
              filter={card}
              groups={cardGroups}
              onChange={(card) => onChange({ ...settings, card })}
            />
          ),
        }),
        ...optionalArrayItem(showTags, {
          index: CreateStorySettingsTabType.TAGS,
          name: t('createStorySettingsTabs.tags'),
          content: (
            <div className={classes['create-story-settings__tags']}>
              {hasInternalTags && (
                <TagsPicker
                  tags={internal}
                  setTags={setInternal}
                  type={OrganisationTagType.INTERNAL}
                />
              )}
              {hasExternalTags && (
                <TagsPicker
                  tags={external}
                  setTags={setExternal}
                  type={OrganisationTagType.EXTERNAL}
                />
              )}
            </div>
          ),
        }),
        ...optionalArrayItem(hasMedia, {
          index: CreateStorySettingsTabType.MEDIA,
          name: t('createStorySettingsTabs.media'),
          content: <FileSettings {...media} hideCaption />,
        }),
        ...optionalArrayItem(isStandard, {
          index: CreateStorySettingsTabType.ACCESS,
          name: t('createStorySettingsTabs.access'),
          content: (
            <Filter
              filter={access}
              groups={accessGroups}
              onChange={(access) => onChange({ ...settings, access })}
            />
          ),
        }),
        ...optionalArrayItem(isStandard, {
          index: CreateStorySettingsTabType.OTHER,
          name: t('createStorySettingsTabs.other'),
          content: (
            <Filter
              filter={other}
              groups={otherGroups}
              onChange={(other) => onChange({ ...settings, other })}
            />
          ),
        }),
        ...optionalArrayItem(isStandard, {
          index: CreateStorySettingsTabType.AUTO_NOTIFICATION,
          name: t('createStorySettingsTabs.auto-notification'),
          content: <AutoNotification {...autoNotification} />,
        }),
      ],
      [
        access,
        accessGroups,
        autoNotification,
        card,
        cardGroups,
        display,
        displayGroups,
        external,
        hasExternalTags,
        hasInternalTags,
        hasMedia,
        internal,
        isStandard,
        media,
        onChange,
        other,
        otherGroups,
        setExternal,
        setInternal,
        settings,
        showTags,
        t,
      ]
    );

    return (
      <div className={classes['create-story-settings']}>
        <Tabs
          items={tabItems}
          selectedTabIndex={activeSettingsTabIndex}
          setSelectedTabIndex={setActiveSettingsTabIndex}
          noBackground
        />
      </div>
    );
  }
);

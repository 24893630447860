import { useRef } from 'react';

export const useDuration = () => {
  const startTime = useRef(0);

  const startTrackTime = () => {
    startTime.current = Date.now();
  };

  const stopTrackTime = () => {
    startTime.current = 0;
  };

  const getDuration = () => {
    if (!startTime.current) {
      return 0;
    }
    return Math.floor((Date.now() - startTime.current) / 1000);
  };

  return {
    startTrackTime,
    stopTrackTime,
    getDuration,
  };
};

import { FunctionComponent, memo } from 'react';
import classes from './Copyright.module.scss';

interface CopyrightProps {
  copyright?: string;
}

export const Copyright: FunctionComponent<CopyrightProps> = memo(({ copyright }) => {
  return <small className={classes['copyright']}>&#169; {copyright}</small>;
});

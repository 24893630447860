/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmbedLink } from '../../../../../components';
import {
  Story,
  StoryCard,
  storyCardApi,
  StoryCardContentGallery,
  StoryCardStatus,
} from '../../../../../services';
import { GalleryItem, ImageFile, StoryCardBlockData } from '../../../../models';
import {
  getDeepLinkUrl,
  getStoryCardIntro,
  getStoryCardPreviewUrl,
  getStoryCardSource,
  getStoryCardTitle,
  stripHtmlString,
} from '../../../../utils';
import { AutoComplete } from '../../../AutoComplete';
import { Button, ButtonType } from '../../../Button';
import { DeeplinkType } from '../../../Deeplink';
import { BaseBlockTool } from '../baseBlockTool';
import { EmbedLinkMediaPlaceholder, ResetBlockTool } from '../helpers';
import { StorySelect } from '../Story';

import classes from './StoryCard.module.scss';

interface State {
  story: Story | null;
  storyCard: StoryCard | null;
}

type Config = {
  dispatch: any;
  channelId: number;
};

export class StoryCardTool extends BaseBlockTool<State, StoryCardBlockData, Config> {
  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {
      title: 'Card',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M8 18H20" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round"/>
        <path d="M8 21H16" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round"/>
        <rect x="7.8" y="7.8" width="12.4" height="7.4" rx="1.2" stroke="#1E1E1E" stroke-width="1.6" stroke-linejoin="round"/>
        <path d="M12.5 15L15.0733 12.3397C15.2711 12.1222 15.5393 12 15.819 12C16.0987 12 16.3669 12.1222 16.5647 12.3397L18.9833 15" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 10H18.0074" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.story) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ story: null, storyCard: null })} />;
  }

  getJSXTool(): JSX.Element {
    const { story, storyCard } = this.state;

    const { dispatch, channelId = 0 } = this.config;

    if (!story) {
      return (
        <StorySelect
          dispatch={dispatch}
          channelId={channelId}
          story={story}
          setStory={(story) => this.setState({ story })}
          placeholder={this.api.i18n.t('searchStory')}
        />
      );
    }

    if (storyCard) {
      const { storyId, id: cardId, type, content } = storyCard;

      const sourceName = getStoryCardSource(storyCard);

      const title = getStoryCardTitle(storyCard);

      const text = getStoryCardIntro(storyCard);

      const { gallery } = content as StoryCardContentGallery;

      const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

      const imageUrl = getStoryCardPreviewUrl({ type, galleryItem });

      const url = getDeepLinkUrl({ type: DeeplinkType.CARD, channelId, storyId, cardId });

      if ([sourceName, title, text, imageUrl].every((value) => !Boolean(value))) {
        return <EmbedLinkMediaPlaceholder url={url} type={type} />;
      }

      return (
        <EmbedLink
          link={{ url, sourceName, title, text, image: { url: imageUrl } as ImageFile }}
          hideUrl
        />
      );
    }

    const fetchQuery = (query: string, page?: number) => {
      return dispatch(
        storyCardApi.endpoints.storyCardsFeed.initiate({
          page,
          storyId: story.id,
          filter: { query, onlyCompletedMedia: true, status: [StoryCardStatus.PUBLISHED] },
        })
      );
    };

    const itemTemplate = (storyCard: StoryCard) => {
      const title = getStoryCardTitle(storyCard);

      const text = getStoryCardIntro(storyCard);

      const { type, content } = storyCard;

      const { gallery } = content as StoryCardContentGallery;

      const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

      const previewUrl = getStoryCardPreviewUrl({ type, galleryItem });

      return (
        <div className={classes['card']}>
          <div className={classes['card__fields']}>
            <div className={classes['card__fields-header']}>
              <div className={classes['card__fields-header-type']}>
                {this.api.i18n.t(type.toLowerCase())}
              </div>
              {title && (
                <div className={classes['card__fields-header-title']}>&nbsp;•&nbsp;{title}</div>
              )}
            </div>
            {text && <div className={classes['card__fields-text']}>{stripHtmlString(text)}</div>}
          </div>
          {previewUrl && (
            <div
              className={classes['card__teaser']}
              style={{ backgroundImage: `url(${previewUrl})` }}
            ></div>
          )}
        </div>
      );
    };

    return (
      <div className={classes['card-select']}>
        <AutoComplete
          propToIdentify={'id'}
          propToDisplay={'id'}
          fetchQuery={fetchQuery}
          itemTemplate={itemTemplate}
          value={[storyCard].filter(Boolean)}
          onChange={(cards: StoryCard[]) => this.setState({ storyCard: cards.slice(-1).pop() })}
          placeholder={this.api.i18n.t('searchCard')}
          autoFocus
        />

        <Button
          type={ButtonType.primary}
          label={this.api.i18n.t('changeStory')}
          className={classes['card-select__change-story']}
          onClick={() => this.setState({ story: null })}
        />
      </div>
    );
  }

  getDefaultState(data?: StoryCardBlockData): State {
    return { story: data?.storyCard?.story ?? null, storyCard: data?.storyCard ?? null };
  }

  validate({ storyCard }: StoryCardBlockData): boolean {
    return Boolean(storyCard);
  }

  save(): StoryCardBlockData {
    return { storyCard: this.state.storyCard };
  }
}

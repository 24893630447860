import { useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../context';
import { StoryCardStatus } from '../../services';
import { getStoriesCardsUserFeed, loadMoreStoriesCardsUserFeed } from '../../slices';
import { USER_FEED_COOKIE_KEY } from '../constants';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useLastDate } from './useLastDate';

interface UserFeedProps {
  userId: number;
}

export const useUserFeed = ({ userId }: UserFeedProps) => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const { page } = useAppSelector(({ storiesCardsUserFeed }) => storiesCardsUserFeed);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [cookies] = useCookies([USER_FEED_COOKIE_KEY]);

  const { requestFilter = { dateRange: null, status: StoryCardStatus.PUBLISHED } } = {
    ...cookies[USER_FEED_COOKIE_KEY],
  };

  const { dateRange, status } = requestFilter;

  const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
  const from = date?.from ?? null;
  const to = date?.to ?? null;

  const filter = useMemo(() => {
    return {
      ...requestFilter,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [from, requestFilter, to]);

  const getUserFeedItems = useCallback(() => {
    dispatch(getStoriesCardsUserFeed({ channelId, userId, filter }));
  }, [channelId, dispatch, filter, userId]);

  const loadMoreUserFeedItems = useCallback(() => {
    dispatch(loadMoreStoriesCardsUserFeed({ channelId, userId, filter, page: page + 1 }));
  }, [channelId, dispatch, filter, page, userId]);

  return {
    getUserFeedItems,
    loadMoreUserFeedItems,
    status,
  };
};

export const generateVideoTeaserUrl = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      if (!fileReader.result) {
        resolve('');
        return;
      }

      const blob = new Blob([fileReader.result], { type: file.type });
      const video = document.createElement('video');

      video.onloadedmetadata = function () {
        video.currentTime = 3;
      };

      video.onseeked = function () {
        const canvas = document.createElement('canvas');
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        resolve(canvas.toDataURL());
      };

      video.src = URL.createObjectURL(blob);
    };

    fileReader.readAsArrayBuffer(file);
  });
};

import {
  FREE_LOGIN_USER_ID_STORAGE_KEY,
  getCookieByName,
  getOrganisationDomain,
} from '../../shared';

export const prepareHeaders = (headers: Headers): Headers => {
  headers.set('x-tchop-webapp-organisation', getOrganisationDomain());

  const authToken = getCookieByName('mz-account');

  if (authToken) {
    headers.set('x-tchop-auth-token', authToken);
  }

  const freeLoginUserId = localStorage.getItem(FREE_LOGIN_USER_ID_STORAGE_KEY);

  if (freeLoginUserId) {
    headers.set('x-tchop-firebase-instance-id', freeLoginUserId);
  }

  return headers;
};

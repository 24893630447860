export enum FileStatus {
  ERROR = 'ERROR',
  OK = 'OK',
  PROCESSING = 'PROCESSING',
}

export enum StatusCopyright {
  CC = 'CC',
  LICENSED = 'LICENSED',
  SUBLICENSED = 'SUBLICENSED',
  UNKNOWN = 'UNKNOWN',
}

export enum FileExternalType {
  BRIGHTCOVE = 'BRIGHTCOVE',
  INTERNAL = 'INTERNAL',
  TRANSLOADIT = 'TRANSLOADIT',
}

export interface ImageFile {
  id: number;
  externalId: string;
  externalType: FileExternalType;
  averageColor: string;
  originalFilename: string;
  rightholder: string;
  status: FileStatus;
  statusCopyrightId: StatusCopyright;
  thumb: string;
  url: string;
}

export interface File {
  id: number;
  externalId: string;
  externalType: FileExternalType;
  originalFilename: string;
  status: FileStatus;
  url: string;
  thumb?: string;
}

import { FunctionComponent, memo } from 'react';
import { StoryCardQuoteContent } from '../../../../../services';
import { IconLabel, LineClamp, getCssVar } from '../../../../../shared';
import { CardContainer } from '../CardContainer';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

import classes from './Social.module.scss';

export const Social: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { id, storyId, type, content, dateToDisplay } = card;
  const { gallery, quote, quoteSource, quotePersonHandle, url } = content as StoryCardQuoteContent;

  return (
    <CardContainer
      storyId={storyId}
      cardId={id}
      type={type}
      url={url}
      gallery={gallery}
      postedTime={dateToDisplay}
    >
      {quoteSource && (
        <div className={classes['quote-source']}>
          <IconLabel
            iconId={quoteSource.toLowerCase()}
            iconSize={14}
            color={getCssVar('--card-social-icon-color')}
            singleColor
          />
          {quotePersonHandle && (
            <span className={classes['quote-source__person']}>{quotePersonHandle}</span>
          )}
        </div>
      )}
      {quote && (
        <LineClamp
          maxLines={3}
          readMore={'...'}
          contentClassName={classes['quote']}
          stringContent={quote}
        />
      )}
    </CardContainer>
  );
});

import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { StoryCard } from '../../../services';
import {
  ConfirmationModal,
  IconLabel,
  ImageFile,
  ImageSingle,
  getChannelDomain,
  getCssVar,
  getWebAppUrl,
  useAppDispatch,
} from '../../../shared';
import { deleteInAppMessage, deletePushNotification } from '../../../slices';

import classes from './Notification.module.scss';

interface NotificationProps {
  isPush: boolean;
  id: number;
  title: string;
  message: string;
  created: string;
  delivered: string;
  expiration: string;
  allowToDelete: boolean;
  open: number;
  sent: number;
  storyCard: StoryCard | null;
  url?: string;
  channelId?: number;
  image?: ImageFile;
}

export const Notification: FunctionComponent<NotificationProps> = memo(
  ({
    isPush,
    id,
    title,
    message,
    created,
    delivered,
    expiration,
    allowToDelete,
    open,
    sent,
    url,
    channelId,
    storyCard,
    image,
  }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const { storyId, id: cardId } = { ...storyCard };

    const channelDomain = useMemo(() => getChannelDomain(), []);

    const detailsUrl =
      storyId && cardId ? `${getWebAppUrl(channelDomain)}/details/${storyId}/${cardId}` : '';

    const notificationDelete = useCallback(async () => {
      setLoading(true);

      try {
        const { deleted } = await (isPush
          ? dispatch(deletePushNotification({ notificationId: id }))
          : dispatch(deleteInAppMessage({ inAppMessageId: id }))
        ).unwrap();

        toast(t(deleted ? 'deleteNotification.success' : 'deleteNotification.error'));
      } catch (_) {
        toast.error(t('deleteNotification.error'));
      } finally {
        setLoading(false);
      }
    }, [dispatch, id, isPush, t]);

    return (
      <div className={classes['notification']}>
        <div className={classes['notification__top']}>
          <div className={classes['notification__top-text']}>
            <div className={classes['notification__title']}>{title}</div>
            <div className={classes['notification__message']}>{message}</div>
          </div>
          {image && (
            <div className={classes['notification__top-image-wrapper']}>
              <ImageSingle item={{ image }} modalPreviewOnClick />
            </div>
          )}
        </div>
        {Boolean(url) && (
          <IconLabel
            labelClassName={classes['notification__url']}
            iconId={'arrow-rounded-right-up-box'}
            iconSize={18}
            label={url}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={() => window.open(url, '_blank', 'noopener, noreferrer')}
          />
        )}
        {Boolean(detailsUrl) && (
          <IconLabel
            labelClassName={classes['notification__url']}
            iconId={'attachment'}
            iconSize={18}
            label={t('common.attachment')}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={() => window.open(detailsUrl, '_blank', 'noopener, noreferrer')}
          />
        )}
        <div className={classes['notification__info']}>
          <div className={classes['notification__info-block']}>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.opened')}
              </span>
              <span className={classes['notification__info-block-item-value']}>{open}</span>
            </div>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.delivered')}
              </span>
              <span className={classes['notification__info-block-item-value']}>{sent}</span>
            </div>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.sent-to')}
              </span>
              <span className={classes['notification__info-block-item-value']}>
                {t(`common.${channelId ? 'channel' : 'organisation'}`)}
              </span>
            </div>
          </div>
          <div className={classes['notification__info-block']}>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.created-date')}
              </span>
              <span className={classes['notification__info-block-item-value']}>
                {format(parseISO(created), 'dd.MM.yyyy, HH:mm')}
              </span>
            </div>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.delivery-date')}
              </span>
              <span className={classes['notification__info-block-item-value']}>
                {format(parseISO(delivered), 'dd.MM.yyyy, HH:mm')}
              </span>
            </div>
            <div className={classes['notification__info-block-item']}>
              <span className={classes['notification__info-block-item-label']}>
                {t('notificationsPage.expiration-date')}
              </span>
              <span className={classes['notification__info-block-item-value']}>
                {format(parseISO(expiration), 'dd.MM.yyyy, HH:mm')}
              </span>
            </div>
          </div>
        </div>

        {allowToDelete && (
          <IconLabel
            iconId={'delete'}
            iconSize={18}
            label={t('common.delete')}
            color={getCssVar('--color-danger')}
            onClick={() => setIsDeleteOpen(true)}
            singleColor
          />
        )}

        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          acceptLabel={t('common.delete')}
          onAccept={notificationDelete}
          title={t('deleteNotification.title')}
          subTitle={t('deleteNotification.subTitle')}
          keepOpened={loading}
          loading={loading}
          danger
        />
      </div>
    );
  }
);

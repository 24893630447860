import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';
import { linkify } from '../../utils';

import classNames from 'classnames';
import classes from './LineClamp.module.scss';

export interface LineClampProps {
  maxLines: number;
  stringContent?: string;
  stringContentIcon?: JSX.Element;
  htmlStringContent?: string;
  contentClassName?: string;
  allowExpand?: boolean;
  expandCharsLimit?: number;
  expandMaxHeight?: number;
  readMore?: string | JSX.Element;
  readMoreClassName?: string;
  onClick?: () => void;
  onTextReveal?: () => void;
  useLinkify?: boolean;
  fallBackLineHeight?: number;
}

export const LineClamp: FunctionComponent<LineClampProps> = memo(
  ({
    maxLines,
    stringContent,
    stringContentIcon,
    htmlStringContent,
    contentClassName,
    readMore,
    readMoreClassName,
    onClick,
    onTextReveal,
    allowExpand,
    expandCharsLimit = 650,
    expandMaxHeight = 500,
    useLinkify,
    fallBackLineHeight = 24,
  }) => {
    const [maxHeight, setMaxHeight] = useState<string>('unset');
    const [isOverflown, setIsOverflown] = useState(false);
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

    const refCallback = useCallback(
      (node: HTMLDivElement | null) => {
        if (node !== null) {
          setMaxHeight(
            `${
              maxLines * (parseInt(window.getComputedStyle(node).lineHeight) || fallBackLineHeight)
            }px`
          );
          setContentRef(node);
        }
      },
      [fallBackLineHeight, maxLines]
    );

    useEffect(() => {
      if (maxHeight && contentRef) {
        const { scrollHeight, clientHeight } = contentRef;

        //needed in case of bold text that takes a little bit more space than regular
        const delta = 0.5 * parseInt(window.getComputedStyle(contentRef).lineHeight);

        setIsOverflown(scrollHeight - delta > clientHeight);
      }
      //stringContent, htmlStringContent dependencies added for 'edit card' case
    }, [contentRef, maxHeight, stringContent, htmlStringContent]);

    const onClickHandler = useCallback(
      ({ target }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (
          allowExpand &&
          contentRef &&
          contentRef.innerText.length <= expandCharsLimit &&
          contentRef.scrollHeight <= expandMaxHeight
        ) {
          setMaxHeight('unset');
          setIsOverflown(false);

          onTextReveal?.();
        } else {
          if ((target as HTMLElement).tagName.toLowerCase() === 'a') {
            return;
          }
          onClick?.();
        }
      },
      [allowExpand, contentRef, expandCharsLimit, expandMaxHeight, onClick, onTextReveal]
    );

    return (
      <div
        className={classNames(classes['line-clamp'], {
          [classes['line-clamp--clickable']]: isOverflown,
        })}
        onClick={(event) => isOverflown && onClickHandler(event)}
      >
        <div className={classes['line-clamp__content']}>
          {htmlStringContent && (
            <div
              ref={refCallback}
              style={{ maxHeight }}
              className={classNames(contentClassName)}
              dangerouslySetInnerHTML={{
                __html: useLinkify ? linkify(htmlStringContent) : htmlStringContent,
              }}
            ></div>
          )}
          {stringContent && (
            <div ref={refCallback} style={{ maxHeight }} className={classNames(contentClassName)}>
              {stringContent} {stringContentIcon}
            </div>
          )}
        </div>
        {isOverflown && (
          <div className={classNames(classes['line-clamp__read-more'], readMoreClassName)}>
            {readMore}
          </div>
        )}
      </div>
    );
  }
);

import { FunctionComponent, memo } from 'react';
import { StoryCardPostContent } from '../../../../../services';
import { CardContainer } from '../CardContainer';
import { Content } from '../Content';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

export const Post: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { type, id, storyId, content, dateToDisplay } = card;
  const { gallery, title, sourceName, abstract } = content as StoryCardPostContent;

  return (
    <CardContainer
      type={type}
      storyId={storyId}
      cardId={id}
      gallery={gallery}
      postedTime={dateToDisplay}
    >
      <Content cardType={type} source={sourceName} title={title} text={abstract} />
    </CardContainer>
  );
});

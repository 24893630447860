import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { ConfigContext } from '../../context';
import { useGetConfigQuery } from '../../services';
import { ScrollToTop, getOrganisationDomain, useConfig } from '../../shared';

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FormLayout: FunctionComponent = () => {
  const { data: config } = useGetConfigQuery({ organisationDomain: getOrganisationDomain() });

  useConfig(config);

  if (!config) {
    return null;
  }

  const { title, faviconUrl } = config.elements.meta;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel={'icon'} href={faviconUrl} />
      </Helmet>

      <ScrollToTop />

      <ConfigContext.Provider value={{ config }}>
        <Outlet />
      </ConfigContext.Provider>

      <ToastContainer
        autoClose={2000}
        position={'bottom-center'}
        transition={Slide}
        newestOnTop
        hideProgressBar={true}
        pauseOnHover={false}
        draggable={false}
        style={{ zIndex: 999999 }}
      />
    </>
  );
};

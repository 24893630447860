import { FunctionComponent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, getCssVar, IconLabel } from '../../../../shared';
import { ProfileFeedCreateCardModal } from './ProfileFeedCreateCardModal';

import classes from './ProfileFeedCreateCard.module.scss';

export enum ProfileFeedCreateCardMode {
  LABEL = 'LABEL',
  BUTTON = 'BUTTON',
  BLOCK = 'BLOCK',
}

interface ProfileFeedCreateCardProps {
  mode?: ProfileFeedCreateCardMode;
}

export const ProfileFeedCreateCard: FunctionComponent<ProfileFeedCreateCardProps> = memo(
  ({ mode = ProfileFeedCreateCardMode.BLOCK }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const content = useMemo(() => {
      switch (mode) {
        case ProfileFeedCreateCardMode.LABEL:
          return (
            <IconLabel
              iconId={'add-plus'}
              iconSize={16}
              label={t('profileFeed.add-media')}
              color={getCssVar('--profile-feed-create-card-label-color')}
              hoverColor={getCssVar('--profile-feed-create-card-label-hover-color')}
              onClick={() => setIsOpen(true)}
              labelFirst
            />
          );
        case ProfileFeedCreateCardMode.BUTTON:
          return (
            <Button
              type={ButtonType.secondary}
              label={t('profileFeed.add-media')}
              className={classes['create-card__button']}
              onClick={() => setIsOpen(true)}
            />
          );
        default:
          return (
            <div className={classes['create-card']}>
              <IconLabel
                iconId={'gallery'}
                color={getCssVar('--profile-feed-create-card-icon-color')}
                className={classes['create-card__icon']}
                nonClickable
              />
              <div className={classes['create-card__label']}>
                {t('profileFeed.add-media-label')}
              </div>
              <Button
                type={ButtonType.secondary}
                label={t('profileFeed.add-media')}
                className={classes['create-card__button']}
                onClick={() => setIsOpen(true)}
              />
            </div>
          );
      }
    }, [mode, t]);

    return (
      <>
        {content}
        {isOpen && <ProfileFeedCreateCardModal isOpen={isOpen} onClose={() => setIsOpen(false)} />}
      </>
    );
  }
);

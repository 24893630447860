import { FunctionComponent, memo, useMemo } from 'react';
import { StoryCardType } from '../../../../../services';
import { IconLabel, LineClamp, LineClampProps, getCssVar } from '../../../../../shared';

import classes from './Content.module.scss';

interface ContentProps {
  cardType?: StoryCardType;
  source?: string;
  title?: string;
  subTitle?: string;
  text?: string;
  htmlText?: string;
}

export const Content: FunctionComponent<ContentProps> = memo(
  ({ cardType, source, title, subTitle, text, htmlText }) => {
    const lineClampCommonProps = useMemo(() => {
      return {
        maxLines: 3,
        readMore: '...',
        contentClassName: classes['text'],
        readMoreClassName: classes['read-more'],
      } as LineClampProps;
    }, []);

    const titleIcon = useMemo(
      () =>
        [StoryCardType.ARTICLE, StoryCardType.POST].includes(cardType as StoryCardType) && (
          <IconLabel
            className={classes['title-icon']}
            iconId={
              cardType === StoryCardType.ARTICLE ? 'arrow-rounded-right-up' : 'arrow-rounded-right'
            }
            iconSize={14}
            color={getCssVar('--card-content-headline-icon-color')}
            singleColor
          />
        ),
      [cardType]
    );

    return (
      <>
        {source && <div className={classes['source']}>{source}</div>}
        {title && (
          <div className={classes['title']}>
            {title} {titleIcon}
          </div>
        )}
        {subTitle && <div className={classes['subTitle']}>{subTitle}</div>}
        {!subTitle && !htmlText && text && (
          <LineClamp {...lineClampCommonProps} stringContent={text} />
        )}
        {!subTitle && htmlText && (
          <LineClamp {...lineClampCommonProps} htmlStringContent={htmlText} />
        )}
      </>
    );
  }
);

import { Comment, StoryCard } from '../../services';

export const hideCommentReducer = ({ card, comment }: { card: StoryCard; comment: Comment }) => {
  const { comments } = card;
  const { id, parentId, isHidden } = comment;

  const commentsToUpdate = parentId
    ? comments.find(({ id }) => id === parentId)?.replies
    : comments;

  if (!commentsToUpdate) {
    return;
  }

  const commentToUpdate = commentsToUpdate.find((comment) => comment.id === id);

  if (!commentToUpdate) {
    return;
  }

  commentToUpdate.isHidden = isHidden;
};

import { StoryCardType } from '../../services';
import { FileAcceptType } from '../hooks';

export const getAcceptTypeByCardType = (cardType: StoryCardType) => {
  switch (cardType) {
    case StoryCardType.IMAGE:
      return FileAcceptType.IMAGE;
    case StoryCardType.AUDIO:
      return FileAcceptType.AUDIO;
    case StoryCardType.VIDEO:
      return FileAcceptType.VIDEO;
    case StoryCardType.PDF:
      return FileAcceptType.PDF;
    default:
      return undefined;
  }
};

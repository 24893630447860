import { FunctionComponent, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Story } from '../../../services';
import { StoryTabType } from '../StoryTabList';

import classes from './StoryList.module.scss';

interface StoryListProps {
  stories: Story[];
  tab: StoryTabType;
}

export const StoryList: FunctionComponent<StoryListProps> = memo(({ stories, tab }) => {
  const navigate = useNavigate();

  const navigateToStoryFeedItems = useCallback(
    (id: number) => {
      navigate(`/${tab}/${id}/items`);
    },
    [navigate, tab]
  );

  return (
    <div className={classes['story-list']}>
      {stories.map(({ id, title, subtitle, image }) => {
        const { url: imageUrl } = { ...image };

        return (
          <div
            key={id}
            className={classes['story-list__item']}
            onClick={() => navigateToStoryFeedItems(id)}
          >
            <div className={classes['story-list__item-description']}>
              <div className={classes['story-list__item-title']}>{title}</div>
              {subtitle && <div className={classes['story-list__item-subTitle']}>{subtitle}</div>}
            </div>
            {imageUrl && (
              <div className={classes['story-list__item-image-wrapper']}>
                <img className={classes['story-list__item-image']} src={imageUrl} alt={'preview'} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});

import { FunctionComponent, memo, useContext, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Emoticon } from '../../../assets/emojis';
import { ConfigContext } from '../../../context';
import { StoryCardReactions } from '../../../services';
import { Emoji, Emojis } from '../../models';

import classNames from 'classnames';
import classes from './ReactionsCounter.module.scss';

interface ReactionsCounterProps {
  totalCount: number;
  reactions: StoryCardReactions[];
  myReaction: string;
}

export const ReactionsCounter: FunctionComponent<ReactionsCounterProps> = memo(
  ({ totalCount, reactions, myReaction }) => {
    const { t } = useTranslation();

    const { individualReaction } = useContext(ConfigContext).config.elements.card;

    const [showIndividual, setShowIndividual] = useState(individualReaction);

    const iconSize = useMemo(() => (isMobileOnly ? 24 : 30), []);

    const getReactions = useMemo(() => {
      return Object.values(reactions)
        .filter(({ count }) => Boolean(count))
        .sort((a, b) => {
          if (b.count === a.count) {
            return Emojis[a.name as Emoji] - Emojis[b.name as Emoji];
          }
          return b.count - a.count;
        });
    }, [reactions]);

    return (
      <div className={classes['counter']} onClick={() => setShowIndividual(!showIndividual)}>
        <div className={classes['counter__reactions']}>
          {getReactions.map(({ name, count }) => (
            <div
              key={name}
              className={classNames(classes['counter__reactions-icon'], {
                [classes['counter__reactions-icon--individual']]: showIndividual,
              })}
            >
              <Emoticon reaction={name as Emoji} size={iconSize} />
              {showIndividual && (
                <span className={classes['counter__reactions-icon-count']}>{count}</span>
              )}
            </div>
          ))}
        </div>
        {!showIndividual && (
          <div className={classes['counter__label']}>
            {myReaction
              ? t('reactions.counter-personal', { count: totalCount - 1 })
              : t('reactions.counter', { count: totalCount })}
          </div>
        )}
      </div>
    );
  }
);

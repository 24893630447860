import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ChannelBySubdomainContext, ConfigContext, UserContext } from '../../context';
import { useGetStoriesByChannelIdQuery } from '../../services';
import {
  CreateCard,
  Select,
  getAppTabHrefByActionType,
  getWebAppUrl,
  useAppSelector,
} from '../../shared';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { SpecialTeaser } from '../SpecialTeaser';

import classNames from 'classnames';
import classes from './Sidebar.module.scss';

interface SidebarProps {
  onMobilePanelClose?: () => void;
}

export const Sidebar: FunctionComponent<SidebarProps> = memo(({ onMobilePanelClose }) => {
  const { channelId, id: userId } = useContext(UserContext).userInfo.userData;

  const { channelLogos } = useContext(ConfigContext).config.elements;

  const { appTabs } = useContext(ChannelBySubdomainContext);

  const { channels } = useAppSelector(({ channels }) => channels);

  const postInStories =
    useGetStoriesByChannelIdQuery({
      channelId,
      filter: { onlyPostIn: true },
    }).data ?? [];

  const channelItems = useMemo(() => {
    return channels.map(({ id, name }) => ({ id, title: name })) ?? [];
  }, [channels]);

  const showLogo: boolean = useMemo(() => {
    const currentChannel = channels.find(({ id }) => id === channelId);

    if (!currentChannel) {
      return false;
    }

    return Boolean(channelLogos.find(({ channelId }) => channelId === currentChannel.id));
  }, [channelId, channelLogos, channels]);

  const hasAppTabs = useMemo(() => Boolean(appTabs.length), [appTabs.length]);

  const onChannelChange = useCallback(
    (channelId: number) => {
      if (!hasAppTabs) {
        return;
      }

      const selectedChannel = channels
        .find(({ id }) => id === channelId)
        ?.subdomain.replace(/\s/g, '');

      if (!selectedChannel) {
        return;
      }

      const appTabHref = getAppTabHrefByActionType(appTabs[0].action.type).split('/')[1];

      window.location.replace(
        `${getWebAppUrl(selectedChannel)}${appTabHref ? `/${appTabHref}` : ''}`
      );
    },
    [appTabs, channels, hasAppTabs]
  );

  const logo = useMemo(() => {
    if (!showLogo) {
      return;
    }

    const logo = channelLogos.find((channelLogo) => channelLogo.channelId === channelId)?.logoUrl;

    if (!logo) {
      return;
    }

    return <img className={classes['logo']} src={logo} alt={'logo'} />;
  }, [channelId, channelLogos, showLogo]);

  const renderChannelSwitcher = useMemo(
    () => Boolean(hasAppTabs && channels.length),
    [channels.length, hasAppTabs]
  );

  const createCardEnabled = useMemo(() => Boolean(postInStories.length), [postInStories.length]);

  const appTabsRelatedContent = useMemo(() => {
    return (
      <>
        {renderChannelSwitcher && (
          <Select
            selectedItemId={channelId}
            items={channelItems}
            logo={logo}
            onChange={(channelId) => onChannelChange(channelId as number)}
            disabled={channels.length === 1}
          />
        )}
        {hasAppTabs && (
          <Menu
            appTabs={appTabs}
            createCardEnabled={createCardEnabled}
            channelId={channelId}
            userId={userId}
          />
        )}
      </>
    );
  }, [
    appTabs,
    channelId,
    channelItems,
    channels.length,
    createCardEnabled,
    hasAppTabs,
    logo,
    onChannelChange,
    renderChannelSwitcher,
    userId,
  ]);

  return (
    <div
      className={classNames(classes['sidebar'], {
        [classes['sidebar--mobile']]: isMobileOnly,
      })}
    >
      {appTabsRelatedContent}
      {createCardEnabled && (
        <CreateCard postInStories={postInStories} onMobilePanelClose={onMobilePanelClose} />
      )}
      <SpecialTeaser />
      <Footer />
    </div>
  );
});

import { FunctionComponent, memo } from 'react';
import { UserFeedCommon } from '../../../shared';
import { StoriesCardsUserFeed } from './StoriesCardsUserFeed';

interface UserFeedProps {
  userId: number;
  me?: boolean;
}

export const UserFeed: FunctionComponent<UserFeedProps> = memo(({ userId, me = true }) => {
  return (
    <UserFeedCommon
      me={me}
      userId={userId}
      userFeedComponent={<StoriesCardsUserFeed userId={userId} />}
    />
  );
});

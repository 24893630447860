import { FunctionComponent } from 'react';
import { DetailsModalCommon, DetailsModalProps } from '../../../shared';
import { DetailsContent } from '../DetailsContent';

export const DetailsModal: FunctionComponent<DetailsModalProps> = ({
  isOpen,
  storyId,
  cardId,
  onClose,
  scrollToTags,
  scrollToComments,
  activeCommentsInput,
}) => {
  return (
    <DetailsModalCommon
      isOpen={isOpen}
      storyId={storyId}
      cardId={cardId}
      onClose={onClose}
      scrollToTags={scrollToTags}
      scrollToComments={scrollToComments}
      activeCommentsInput={activeCommentsInput}
      ContentComponent={DetailsContent}
    />
  );
};

import { format, parseISO } from 'date-fns';
import { useContext } from 'react';
import { ConfigContext } from '../../context';
import { timeAgo } from '../utils';

export const useCardDate = (dateToDisplay: string) => {
  const { relativeTime } = useContext(ConfigContext).config.elements.card;

  return relativeTime
    ? timeAgo(dateToDisplay)
    : format(parseISO(dateToDisplay), 'dd.MM.yyyy, HH:mm');
};

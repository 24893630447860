import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrganisationUsersArgs, User, organisationApi } from '../services';

interface OrganisationUsersState {
  users: User[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: OrganisationUsersState = {
  users: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getOrganisationUsersHandler = async (args: OrganisationUsersArgs, { dispatch }: any) => {
  return dispatch(organisationApi.endpoints.organisationUsers.initiate({ ...args })).unwrap();
};

export const getOrganisationUsers = createAsyncThunk(
  'getOrganisationUsers',
  getOrganisationUsersHandler
);

export const loadMoreOrganisationUsers = createAsyncThunk(
  'getOrganisationUsers/loadMore',
  getOrganisationUsersHandler
);

const OrganisationUsersSlice = createSlice({
  name: 'organisationUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisationUsers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getOrganisationUsers.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.users = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMoreOrganisationUsers.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.users.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      });
  },
});

export default OrganisationUsersSlice.reducer;

import {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext, OrganisationContext, UserContext } from '../../../context';
import {
  ConfigProfileFields,
  OrganisationTagType,
  TransloaditAssembly,
  TransloaditAssemblyStatus,
  userApi,
  UserGender,
  UserSocialLinks,
} from '../../../services';
import {
  Avatar,
  Button,
  ButtonType,
  getCssVar,
  IconLabel,
  ImageUploadType,
  InputField,
  isUrl,
  Modal,
  phoneValidationError,
  Select,
  TagsPicker,
  TRANSLOADIT_URL,
  TransloaditAuthTemplate,
  UploadType,
  useUpload,
} from '../../../shared';

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './EditProfile.module.scss';

interface RenderProfileFieldProps {
  fieldName: keyof ConfigProfileFields;
  value: string;
  setter?: (value: SetStateAction<string>) => void;
  changeCallBack?: () => void;
  error?: boolean;
  errorMessage?: string;
  maxLength?: number;
}

const CROP_IMAGE_MAX_HEIGHT = `calc(${window.innerHeight}px - 15rem)`;

export const EditProfile: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { userProfile, setUserProfile } = useContext(UserContext);

  const { tags } = useContext(OrganisationContext).organisation;

  const hasTags = useMemo(
    () => Boolean(tags.filter(({ type }) => type === OrganisationTagType.USER).length),
    [tags]
  );

  const {
    email: userEmail,
    screenName: userScreenName,
    gender: userGender,
    location: userLocation,
    bio: userBio,
    phone: userPhone,
    position: userPosition,
    department: userDepartment,
    subscription: userSubscription,
    avatar: userAvatar,
    url: userUrl,
    links: userLinks,
    organisationTags: userOrganisationTags,
  } = userProfile;

  const [updateProfile] = userApi.endpoints.userProfileUpdate.useLazyQuery();

  const { uploadHandler } = useUpload();

  const { config } = useContext(ConfigContext);

  const { avatar, fields, socialFields } = config.elements.profile;

  const { size: avatarSize } = avatar;

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [validationError, setValidationError] = useState({
    screenName: false,
    phone: false,
    facebook: false,
    instagram: false,
    twitter: false,
    youtube: false,
    snapchat: false,
    tiktok: false,
    linkedin: false,
    url: false,
  });

  const [email] = useState<string>(userEmail ?? '');
  const [screenName, setScreenName] = useState<string>(userScreenName);
  const [gender, setGender] = useState<UserGender | null>(userGender);
  const [locationText, setLocationText] = useState<string>(userLocation.text);
  const [bio, setBio] = useState<string>(userBio);
  const [phone, setPhone] = useState<string>(userPhone ?? '');
  const [position, setPosition] = useState<string>(userPosition);
  const [department, setDepartment] = useState<string>(userDepartment);
  const [subscription, setSubscription] = useState<string>(userSubscription);
  const [url, setUrl] = useState<string>(userUrl);
  const [imageId, setImageId] = useState<number | null>(userAvatar?.id ?? null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(userAvatar?.url);
  const [links, setLinks] = useState<UserSocialLinks>(userLinks);
  const [userTags, setUserTags] = useState(userOrganisationTags);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [imageCrop, setImageCrop] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const goToProfile = useCallback(() => {
    navigate(pathname.replace('/edit', ''));
  }, [navigate, pathname]);

  const screenNameValidityCheckPassed = useCallback(() => {
    if (Boolean(screenName.trim().length < 2)) {
      setValidationError({ ...validationError, screenName: true });
      return false;
    }
    return true;
  }, [screenName, validationError]);

  const socialLinksValidityCheckPassed = useCallback(() => {
    for (const [key, value] of Object.entries(links)) {
      if (Boolean(value?.length && !isUrl(value))) {
        setValidationError({ ...validationError, [key]: true });
        return false;
      }
    }

    return true;
  }, [links, validationError]);

  const phoneValidityCheckPassed = useCallback(() => {
    if (!phone || !fields['phone'].editable) {
      return true;
    }

    if (phoneValidationError(phone)) {
      setValidationError({ ...validationError, phone: true });
      return false;
    }

    return true;
  }, [fields, phone, validationError]);

  const urlValidityCheckPassed = useCallback(() => {
    if (Boolean(url.length && !isUrl(url))) {
      setValidationError({ ...validationError, url: true });
      return false;
    }

    return true;
  }, [url, validationError]);

  const validationPassed = useCallback(() => {
    return !Boolean(
      [
        screenNameValidityCheckPassed(),
        phoneValidityCheckPassed(),
        socialLinksValidityCheckPassed(),
        urlValidityCheckPassed(),
      ].some((validityCheckPassed) => !Boolean(validityCheckPassed))
    );
  }, [
    phoneValidityCheckPassed,
    screenNameValidityCheckPassed,
    socialLinksValidityCheckPassed,
    urlValidityCheckPassed,
  ]);

  const saveHandler = useCallback(async () => {
    const { id, email, avatar, roleId, isAnonymous, isStaff, organisationTags, ...infoToSave } =
      userProfile;

    if (!validationPassed()) {
      return;
    }

    setUserProfile(
      await updateProfile({
        fields: {
          ...infoToSave,
          screenName,
          gender,
          bio,
          phone,
          position,
          department,
          location: { text: locationText },
          subscription,
          organisationTagsId: userTags.map(({ id }) => id),
          url,
          links,
          imageId,
        },
      }).unwrap()
    );

    goToProfile();
  }, [
    bio,
    department,
    gender,
    goToProfile,
    imageId,
    links,
    locationText,
    phone,
    position,
    screenName,
    setUserProfile,
    subscription,
    updateProfile,
    url,
    userProfile,
    userTags,
    validationPassed,
  ]);

  const getCroppedImage = useCallback(() => {
    const image = imageRef.current;

    if (!image || !completedCrop) {
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const pixelRatio = window.devicePixelRatio;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(completedCrop.width * pixelRatio * scaleX);
    canvas.height = Math.floor(completedCrop.height * pixelRatio * scaleY);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            return;
          }
          resolve(blob);
        },
        'image/png',
        1
      );
    });
  }, [completedCrop]);

  const upload = useCallback(
    async (file: File) => {
      try {
        const { id, assembly_id } = await uploadHandler({
          file,
          template: TransloaditAuthTemplate.PROFILE_FILE,
          type: UploadType.IMAGE,
          imageUploadType: ImageUploadType.PROFILE,
        });

        const getAssemblyInterval = setInterval(async () => {
          const assemblyResult: TransloaditAssembly = await fetch(
            `${TRANSLOADIT_URL}/${assembly_id}`
          ).then((result) => result.json());

          if (assemblyResult.ok === TransloaditAssemblyStatus.completed) {
            setIsUploading(false);
            setImagePreview(URL.createObjectURL(file));
            setImageId(id);
            clearInterval(getAssemblyInterval);
          }
        }, 1000);
      } catch (e) {}
    },
    [uploadHandler]
  );

  const onFileChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files?.[0];

    if (file) {
      setIsModalOpen(true);
      setImageCrop(URL.createObjectURL(file));
    }
  };

  const deleteImageHandler = () => {
    setImagePreview('');
    setImageId(null);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setCrop(undefined);
    setCompletedCrop(undefined);
  };

  const imageLoadHandler = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop({ unit: '%', width: 50 }, 1, width, height),
      width,
      height
    );

    setCrop(crop);
  };

  const modalActionHandler = useCallback(async () => {
    setIsModalOpen(false);
    setIsUploading(true);
    upload((await getCroppedImage()) as File);
  }, [getCroppedImage, upload]);

  const addOrDelete = useMemo(() => {
    const hasImage = Boolean(imageId);

    return (
      <button
        disabled={isUploading}
        className={classes['edit-profile__add-or-delete-avatar']}
        onClick={hasImage ? deleteImageHandler : () => inputFile.current?.click()}
      >
        {t(`profile.${hasImage ? 'delete' : 'add'}-picture`)}
      </button>
    );
  }, [imageId, isUploading, t]);

  //Needed to reset input file
  //to have a possibility to load same image again
  const fileInputKey = inputFile?.current?.value ? inputFile?.current?.value : +new Date();

  const renderProfileInputField = useCallback(
    ({
      fieldName,
      value,
      setter,
      changeCallBack,
      error,
      errorMessage,
      maxLength,
    }: RenderProfileFieldProps) => {
      const { visible = false, editable = false, localiseKey } = { ...fields[fieldName] };

      if (!visible) {
        return null;
      }

      return (
        <InputField
          label={t(localiseKey ?? `common.${fieldName}`)}
          value={value}
          disabled={!editable}
          error={error}
          errorMessage={errorMessage}
          maxLength={maxLength}
          {...(setter && {
            onChange: ({ target }) => {
              setter(target.value);
              changeCallBack?.();
            },
          })}
        />
      );
    },
    [fields, t]
  );

  const renderGenderField = useMemo(() => {
    const { visible = false, editable = false, localiseKey } = { ...fields['gender'] };

    if (!visible) {
      return null;
    }

    const items = Object.values(UserGender).map(
      (value) => {
        return { id: value, title: t(`genderTypes.${value}`) };
      },
      [t]
    );

    const onChange = (gender: UserGender) => {
      setGender(gender === UserGender.NOT_SELECTED ? null : gender);
    };

    return (
      <Select
        label={t(localiseKey ?? 'common.gender')}
        selectedItemId={gender ?? UserGender.NOT_SELECTED}
        items={items}
        onChange={onChange}
        disabled={!editable}
      />
    );
  }, [fields, gender, t]);

  const renderUserTags = useMemo(() => {
    const { visible = false, editable = false, localiseKey } = { ...fields['organisationTags'] };

    if (!visible || !hasTags) {
      return null;
    }

    return (
      <TagsPicker
        tags={userTags}
        setTags={setUserTags}
        type={OrganisationTagType.USER}
        title={t(localiseKey ?? 'organisationTags.title-USER')}
        disabled={!editable}
        hideSubTitle
      />
    );
  }, [fields, hasTags, t, userTags]);

  const profileSocialLinkChange = useCallback(
    (linkType: keyof UserSocialLinks, value: string) => {
      setLinks({ ...links, [linkType]: value });
    },
    [links]
  );

  const renderProfileSocialLinks = useMemo(() => {
    const sortedLinkTypes: (keyof UserSocialLinks)[] = [
      'facebook',
      'instagram',
      'twitter',
      'youtube',
      'snapchat',
      'tiktok',
      'linkedin',
    ];

    return sortedLinkTypes.map((linkType: keyof UserSocialLinks) => {
      return (
        <Fragment key={linkType}>
          {socialFields.includes(linkType) && (
            <InputField
              label={t(`common.${linkType}`)}
              value={links[linkType] ?? ''}
              onChange={({ target }) => {
                profileSocialLinkChange(linkType, target.value);
                setValidationError({ ...validationError, [linkType]: false });
              }}
              error={validationError[linkType]}
              errorMessage={t('profile.social-link-invalid')}
              icon={
                <>
                  {!validationError[linkType] && (
                    <IconLabel
                      iconId={linkType}
                      iconSize={18}
                      color={getCssVar('--profile-social-color')}
                      singleColor
                      nonClickable
                    />
                  )}
                </>
              }
            />
          )}
        </Fragment>
      );
    });
  }, [links, profileSocialLinkChange, socialFields, t, validationError]);

  const avatarContent = useMemo(() => {
    if (isUploading) {
      return <Skeleton circle height={'100%'} />;
    }
    return (
      <>
        <Avatar
          url={imagePreview ?? userAvatar?.url}
          size={avatarSize}
          className={classes['edit-profile__avatar']}
        />
        <div className={classes['edit-profile__avatar-upload']}></div>
      </>
    );
  }, [avatarSize, imagePreview, isUploading, userAvatar?.url]);

  return (
    <div className={classes['edit-profile']}>
      <label className={classes['edit-profile__avatar-wrapper']} htmlFor={'profile_image'}>
        {avatarContent}

        <IconLabel
          iconId={'photo-upload'}
          className={classes['edit-profile__avatar-upload-icon']}
          iconSize={20}
          color={getCssVar('--profile-upload-icon-color')}
          singleColor
        />
      </label>

      <input
        key={fileInputKey}
        ref={inputFile}
        id={'profile_image'}
        type={'file'}
        accept={'image/*'}
        className={classes['edit-profile__file']}
        onChange={onFileChange}
        disabled={isUploading}
      />

      {addOrDelete}

      <div className={classes['edit-profile__fields-wrapper']}>
        {renderProfileInputField({
          fieldName: 'screenName',
          value: screenName,
          setter: setScreenName,
          changeCallBack: () => setValidationError({ ...validationError, screenName: false }),
          maxLength: 100,
          error: validationError.screenName,
          errorMessage: t('profile.name-invalid'),
        })}

        {renderProfileInputField({ fieldName: 'email', value: email })}

        {renderGenderField}

        {renderUserTags}

        {renderProfileInputField({
          fieldName: 'phone',
          value: phone,
          setter: setPhone,
          changeCallBack: () => setValidationError({ ...validationError, phone: false }),
          error: validationError.phone,
          errorMessage: t('profile.phone-invalid'),
        })}

        {renderProfileInputField({ fieldName: 'bio', value: bio, setter: setBio, maxLength: 300 })}

        {renderProfileInputField({
          fieldName: 'position',
          value: position,
          setter: setPosition,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'department',
          value: department,
          setter: setDepartment,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'location',
          value: locationText,
          setter: setLocationText,
        })}

        {renderProfileInputField({
          fieldName: 'subscription',
          value: subscription,
          setter: setSubscription,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'url',
          value: url,
          setter: setUrl,
          changeCallBack: () => setValidationError({ ...validationError, url: false }),
          error: validationError.url,
          errorMessage: t('profile.social-link-invalid'),
        })}

        {renderProfileSocialLinks}
      </div>

      <div className={classes['edit-profile__buttons-wrapper']}>
        <Button
          type={ButtonType.secondary}
          label={t('common.cancel')}
          disabled={isUploading}
          onClick={goToProfile}
        />

        <Button
          type={ButtonType.primary}
          label={t('profile.save-profile')}
          disabled={isUploading}
          onClick={saveHandler}
        />
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          title={t('profile.change-picture')}
          contentStyle={{ textAlign: 'center' }}
          onClose={onModalClose}
          body={
            <>
              {imageCrop && (
                <ReactCrop
                  className={classes['edit-profile__crop']}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  minWidth={100}
                  minHeight={100}
                  maxWidth={500}
                  maxHeight={500}
                  keepSelection
                  ruleOfThirds
                  circularCrop
                >
                  <img
                    onLoad={imageLoadHandler}
                    style={{ maxHeight: CROP_IMAGE_MAX_HEIGHT }}
                    ref={imageRef}
                    src={imageCrop}
                    alt={'crop profile'}
                  />
                </ReactCrop>
              )}

              <div className={classes['edit-profile__crop-buttons']}>
                <Button
                  label={t('common.cancel')}
                  type={ButtonType.secondary}
                  onClick={onModalClose}
                />
                <Button
                  label={t('profile.crop-and-save')}
                  type={ButtonType.primary}
                  onClick={modalActionHandler}
                  disabled={!completedCrop}
                />
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

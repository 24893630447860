import { FunctionComponent, memo } from 'react';
import { FeedCard } from '../../../../components';
import { StoriesCardsUserFeedCommon } from '../../../../shared';

interface StoriesCardsUserFeedProps {
  userId: number;
}

export const StoriesCardsUserFeed: FunctionComponent<StoriesCardsUserFeedProps> = memo(
  ({ userId }) => {
    return (
      <StoriesCardsUserFeedCommon
        userId={userId}
        cardComponent={(card) => <FeedCard card={card} />}
      />
    );
  }
);

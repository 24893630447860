/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationTag, OrganisationTagsArgs, OrganisationTagType } from '../../../../services';
import { FilterGroup, FilterGroupAutoComplete, FilterValue } from '../../../components';
import { useFilter } from '../useFilter';

import classes from './useTagsFilter.module.scss';

export const useTagsFilter = (
  filter: FilterValue[],
  fetchItemsQuery: any,
  fetchItemsQueryArgs: OrganisationTagsArgs
) => {
  const { t } = useTranslation();

  const { getGroupById } = useFilter();

  const type = fetchItemsQueryArgs?.filters?.types?.[0] ?? OrganisationTagType.EXTERNAL;

  const groupId = `tags-${type}`;

  const itemTemplate = useCallback(
    ({ name }: OrganisationTag) => <div className={classes['name']}>{name}</div>,
    []
  );

  const autoComplete: FilterGroupAutoComplete = {
    propToIdentify: 'id',
    propToDisplay: 'name',
    fetchItemsQuery,
    fetchItemsQueryArgs,
    itemTemplate,
    placeholder: t('common.type-to-search'),
    excludeLabel: t(`organisationTags.exclude-${type}`),
  };

  const group: FilterGroup = {
    id: groupId,
    title: t(`organisationTags.title-${type}`),
    value: getGroupById({ filter, groupId })?.groupItems ?? [],
    allowReset: true,
    autoComplete,
  };

  return { group };
};

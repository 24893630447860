import { ClipboardEvent, KeyboardEvent } from 'react';
import { ParagraphBlockData } from '../../../../models';
import { BaseBlockTool } from '../baseBlockTool';

import classes from './Paragraph.module.scss';

interface State {
  text: string;
}

type Config = {
  placeholder?: string;
};

export class ParagraphTool extends BaseBlockTool<State, ParagraphBlockData, Config> {
  static get enableLineBreaks() {
    return false;
  }

  static get toolbox() {
    return { title: 'Paragraph' };
  }

  static get sanitize() {
    return { text: {} };
  }

  static get pasteConfig() {
    return {
      tags: ['b', 'i', 'u'],
    };
  }

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter' && event.shiftKey) {
      event.preventDefault();
      return false;
    }
  };

  private onInput = ({ target }: KeyboardEvent<HTMLDivElement>) => {
    this.state.text = (target as HTMLDivElement).innerHTML ?? '';
  };

  private onPaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    event.stopPropagation();

    const { clipboardData } = event;

    const clipboardText = clipboardData.getData('Text');

    document.execCommand('insertHTML', false, clipboardText);

    const selection = window.getSelection();

    if (!selection?.rangeCount) {
      return;
    }

    const range = selection.getRangeAt(0);

    if (range.commonAncestorContainer === document) {
      return;
    }

    const br = document.createElement('br');

    range.insertNode(br);

    br.scrollIntoView({ block: 'end' });

    br.remove();
  };

  getJSXTool(): JSX.Element {
    return (
      <div
        contentEditable
        className={classes['paragraph']}
        data-textholder={this.config.placeholder}
        dangerouslySetInnerHTML={{ __html: this.state.text }}
        onKeyDown={this.onKeyDown}
        onInput={this.onInput}
        onPaste={this.onPaste}
      />
    );
  }

  save(): ParagraphBlockData {
    return { text: this.state.text ?? '' };
  }

  getDefaultState(data?: ParagraphBlockData): State {
    return { text: data?.text ?? '' };
  }
}

import { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ConfigContext, UserContext } from '../../context';
import { getWebAppUrl, Select, useAppSelector } from '../../shared';
import { FooterAdmin } from '../FooterAdmin';
import { MenuAdmin } from '../MenuAdmin';

import classNames from 'classnames';
import classes from './SidebarAdmin.module.scss';

export const SidebarAdmin: FunctionComponent = () => {
  const { userInfo } = useContext(UserContext);

  const { channels } = useAppSelector(({ channels }) => channels);

  const { channelLogos } = useContext(ConfigContext).config.elements;

  const { channelId } = userInfo.userData;

  const channelItems = useMemo(() => {
    return channels.map(({ id, name }) => ({ id, title: name }));
  }, [channels]);

  const showLogo: boolean = useMemo(() => {
    const currentChannel = channels.find(({ id }) => id === channelId);

    if (!currentChannel) {
      return false;
    }

    return Boolean(channelLogos.find(({ channelId }) => channelId === currentChannel.id));
  }, [channelId, channelLogos, channels]);

  const onChannelChange = useCallback(
    (channelId: number) => {
      const selectedChannel = channels
        .find(({ id }) => id === channelId)
        ?.subdomain.replace(/\s/g, '');

      if (!selectedChannel) {
        return;
      }

      window.location.replace(`${getWebAppUrl(selectedChannel)}/admin`);
    },
    [channels]
  );

  const logo = useMemo(() => {
    if (!showLogo) {
      return;
    }

    const logo = channelLogos.find((channelLogo) => channelLogo.channelId === channelId)?.logoUrl;

    if (!logo) {
      return;
    }

    return <img className={classes['logo']} src={logo} alt={'logo'} />;
  }, [channelId, channelLogos, showLogo]);

  const renderChannelSwitcher = useMemo(() => Boolean(channels.length), [channels]);

  return (
    <div
      className={classNames(classes['sidebar'], {
        [classes['sidebar--mobile']]: isMobileOnly,
      })}
    >
      {renderChannelSwitcher && (
        <Select
          selectedItemId={channelId}
          items={channelItems}
          logo={logo}
          onChange={(channelId) => onChannelChange(channelId as number)}
          disabled={channels.length === 1}
        />
      )}
      <MenuAdmin />
      <FooterAdmin />
    </div>
  );
};

import { FunctionComponent, memo } from 'react';
import { PdfBlockData } from '../../../../shared';

import { File } from '../File';

export const Pdf: FunctionComponent<PdfBlockData> = memo(({ items }) => {
  if (!items.length) {
    return null;
  }

  const { pdf, ...props } = items[0];

  return <File items={[{ file: pdf, ...props }]} isPdf />;
});

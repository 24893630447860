import { CSSProperties, FunctionComponent, PropsWithChildren, memo } from 'react';

interface FeedCardMarginProps {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
}

export const FeedCardMargin: FunctionComponent<PropsWithChildren<FeedCardMarginProps>> = memo(
  ({ children, top, right, bottom, left }) => {
    const spacing = parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue('--card-spacing')
    );

    const style: CSSProperties = {
      width: '100%',
      maxWidth: `calc(100% - ${left ? spacing : 0}rem - ${right ? spacing : 0}rem)`,
      ...(top && { marginTop: `${spacing}rem` }),
      ...(right && { marginRight: `${spacing}rem` }),
      ...(bottom && { marginBottom: `${spacing}rem` }),
      ...(left && { marginLeft: `${spacing}rem` }),
    };

    return <div style={style}>{children}</div>;
  }
);

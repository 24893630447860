/* eslint-disable @typescript-eslint/ban-types */
import { API, BlockAPI } from '@editorjs/editorjs';
import { BlockTool } from '@editorjs/editorjs/types/tools/block-tool';
import { BlockToolData } from '@editorjs/editorjs/types/tools/block-tool-data';
import { ToolConfig } from '@editorjs/editorjs/types/tools/tool-config';
import ReactDOM from 'react-dom';

interface Nodes {
  toolHolder: HTMLElement;
  settingsHolder: HTMLElement;
}

interface BlockToolConstructorOptions<TData extends {}, TConfig extends {}> {
  api: API;
  data?: BlockToolData<TData>;
  config?: ToolConfig<TConfig>;
  block?: BlockAPI;
}

export abstract class BaseBlockTool<TState, TData extends {}, TConfig extends {}>
  implements BlockTool
{
  static get isReadOnlySupported() {
    return true;
  }

  protected readonly api: API;
  protected readonly nodes: Nodes;
  protected readonly block: BlockAPI;
  protected readonly config: Partial<TConfig>;
  protected isMounted = false;
  protected state: TState;

  abstract getJSXTool(): JSX.Element;
  abstract getDefaultState(d?: TData): TState;
  abstract save(): TData;

  onMount?(): void;
  onUpdated?(): void;
  getJSXSettings?(): JSX.Element | null;

  constructor(options: BlockToolConstructorOptions<TData, TConfig>) {
    this.api = options.api;
    this.config = options.config || {};
    this.block = options.block as BlockAPI;

    this.state = this.getDefaultState(options.data);

    this.nodes = {
      toolHolder: this.createToolHolder(),
      settingsHolder: this.createSettingsHolder(),
    };
  }

  renderSettings() {
    this.renderReactSettingsInOwnHolder();
    return this.nodes.settingsHolder;
  }

  render() {
    this.renderReactToolInOwnHolder();
    this.triggerOnMount();

    return this.nodes.toolHolder;
  }

  setState(s: Partial<TState>) {
    this.state = { ...this.state, ...s };
    this.forceUpdate();
  }

  forceUpdate() {
    this.onUpdated?.();
    this.renderReactSettingsInOwnHolder();
    this.renderReactToolInOwnHolder();
  }

  private renderReactToolInOwnHolder() {
    if (!this.nodes.toolHolder) {
      return;
    }

    ReactDOM.render(this.getJSXTool(), this.nodes.toolHolder);
  }

  private renderReactSettingsInOwnHolder() {
    if (!this.nodes.settingsHolder) {
      return;
    }

    const component = this.getJSXSettings?.();

    if (component) {
      ReactDOM.render(component, this.nodes.settingsHolder);
    }
  }

  private createToolHolder(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.api.styles.block);
    rootNode.dataset.node = 'root';
    return rootNode;
  }

  private createSettingsHolder(): HTMLElement {
    const settingsHolder = document.createElement('div');
    settingsHolder.dataset.node = 'settings';
    return settingsHolder;
  }

  private triggerOnMount(): void {
    if (this.isMounted) {
      return;
    }

    setTimeout(() => {
      this.onMount?.();
    }, 0);

    this.isMounted = true;
  }
}

import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { SearchInput, Sidebar, SidebarAdmin } from '../../../components';
import { ConfigContext, UserContext } from '../../../context';
import { isAdminLayout, layoutPath } from '../../utils';
import { Avatar } from '../Avatar';
import { ConfigurationLink } from '../ConfigurationLink';
import { IconLabel } from '../IconLabel';
import { MobileSlidePanel } from '../MobileSlidePanel';

import classNames from 'classnames';
import classes from './NavbarCommon.module.scss';

export const NavbarCommon: FunctionComponent<PropsWithChildren> = memo(({ children }) => {
  const { userProfile } = useContext(UserContext);

  const {
    organisationLogoUrl,
    headerLinks,
    mobile: { menuIconColor },
  } = useContext(ConfigContext).config.elements;

  const renderHeaderLinks = useMemo(
    () => headerLinks.map((link) => <ConfigurationLink key={link.title} link={link} />),
    [headerLinks]
  );

  const [mobilePanelOpen, setMobilePanelOpen] = useState(false);

  const mobilePanelClose = useCallback(() => {
    setMobilePanelOpen(false);
  }, []);

  const mobilePanelComponent = useMemo(
    () => (isAdminLayout() ? <SidebarAdmin /> : <Sidebar onMobilePanelClose={mobilePanelClose} />),
    [mobilePanelClose]
  );

  return (
    <div className={classes['navbar']}>
      <div
        className={classNames(classes['navbar__container'], {
          [classes['navbar__container--mobile']]: isMobileOnly,
        })}
      >
        {isMobileOnly && (
          <>
            <IconLabel
              iconId={mobilePanelOpen ? 'close' : 'mobile-menu'}
              onClick={() => setMobilePanelOpen(!mobilePanelOpen)}
              color={menuIconColor}
              singleColor
            />
            <MobileSlidePanel isOpen={mobilePanelOpen} onClose={mobilePanelClose}>
              {mobilePanelComponent}
            </MobileSlidePanel>
          </>
        )}

        <div className={classes['navbar__logo-wrapper']}>
          <Link className={classes['navbar__logo']} to={layoutPath('/')}>
            <img
              src={organisationLogoUrl}
              className={classNames(classes['navbar__logo-icon'], {
                [classes['navbar__logo-icon--mobile']]: isMobileOnly,
              })}
              alt={'logo'}
            />
          </Link>
        </div>

        {!isMobileOnly && (
          <div className={classes['navbar__search']}>
            <SearchInput />
          </div>
        )}

        <div className={classes['navbar__links']}>
          {!isMobileOnly && renderHeaderLinks}

          {children}

          <Link
            className={classes['navbar__avatar']}
            onClick={() => window.scrollTo(0, 0)}
            to={layoutPath('/profile')}
          >
            <Avatar url={userProfile.avatar?.url} />
          </Link>
        </div>
      </div>
    </div>
  );
});

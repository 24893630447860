import { channelApi } from './channelApi';

export interface UploadResult {
  id: number;
}

export interface UploadArgs {
  assemblyId: string;
  originalFilename: string;
}

export interface ImageUploadArgs extends UploadArgs {
  type: string;
}

export const uploadApi = channelApi.injectEndpoints({
  endpoints: (builder) => ({
    imageUpload: builder.mutation<UploadResult, ImageUploadArgs>({
      query: ({ assemblyId, originalFilename, type }) => ({
        url: '/images',
        method: 'POST',
        body: {
          assemblyId,
          originalFilename,
          type,
        },
      }),
    }),
    audioUpload: builder.mutation<UploadResult, UploadArgs>({
      query: ({ assemblyId, originalFilename }) => ({
        url: '/audios',
        method: 'POST',
        body: {
          assemblyId,
          originalFilename,
        },
      }),
    }),
    videoUpload: builder.mutation<UploadResult, UploadArgs>({
      query: ({ assemblyId, originalFilename }) => ({
        url: '/videos',
        method: 'POST',
        body: {
          assemblyId,
          originalFilename,
        },
      }),
    }),
    pdfUpload: builder.mutation<UploadResult, UploadArgs>({
      query: ({ assemblyId, originalFilename }) => ({
        url: '/pdf',
        method: 'POST',
        body: {
          assemblyId,
          originalFilename,
        },
      }),
    }),
    fileUpload: builder.mutation<UploadResult, UploadArgs>({
      query: ({ assemblyId, originalFilename }) => ({
        url: '/files',
        method: 'POST',
        body: {
          assemblyId,
          originalFilename,
        },
      }),
    }),
  }),
});

export const {
  useImageUploadMutation,
  useAudioUploadMutation,
  useVideoUploadMutation,
  usePdfUploadMutation,
  useFileUploadMutation,
} = uploadApi;

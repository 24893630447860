import { isAfter, isThisMonth, isThisWeek } from 'date-fns';
import {
  ElementType,
  Fragment,
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../../../context';
import {
  NotificationComment,
  NotificationDefault,
  NotificationType,
  StoryCardArticleContent,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../../services';
import { getChannelNotifications, loadMoreChannelNotifications } from '../../../../slices';
import { useAnalytics, useAppDispatch, useAppSelector } from '../../../hooks';
import { getCssVar, layoutPath } from '../../../utils';
import { DetailsModalProps } from '../../CardDetails';
import { Popup } from '../../Popup';
import { TabItem, Tabs } from '../../Tabs';
import { NotificationCommentContent } from '../NotificationCommentContent';
import { NotificationDefaultContent } from '../NotificationDefaultContent';
import { NotificationWrapper } from '../NotificationWrapper';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import classes from './NotificationsCommon.module.scss';

interface NotificationsCommonProps {
  DetailsModalComponent: ElementType<DetailsModalProps>;
}

interface CardDetails {
  storyId: number;
  cardId: number;
  cardType: StoryCardType;
  scrollToComments: boolean;
}

export enum NotificationsTabType {
  VIEW_ALL,
  FOLLOWING,
  GENERAL,
}

export const NotificationsCommon: FunctionComponent<NotificationsCommonProps> = memo(
  ({ DetailsModalComponent }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const { logPushOpen } = useAnalytics();

    const {
      userData: { channelId },
      body: { channelName },
    } = useContext(UserContext).userInfo;

    const { channelNotifications, page, hasNextPage, isFetching } = useAppSelector(
      ({ channelNotifications }) => channelNotifications
    );

    const { config } = useContext(ConfigContext);

    const { defaultLogoUrl } = config.elements.notifications;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [newTopNotificationTime, setNewTopNotificationTime] = useState<string | undefined>(
      undefined
    );

    const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);

    const storageKey = useMemo(
      () => `Top notification time for channel:${channelName}`,
      [channelName]
    );

    const location = useLocation();

    useEffect(() => {
      setIsOpen(false);
      setCardDetails(null);
    }, [location]);

    const notificationType = useMemo(() => {
      switch (selectedTabIndex) {
        case NotificationsTabType.FOLLOWING:
          return [NotificationType.COMMENT];
        case NotificationsTabType.GENERAL:
          return [NotificationType.DEFAULT];
        default:
          return [NotificationType.DEFAULT, NotificationType.COMMENT];
      }
    }, [selectedTabIndex]);

    const getFirstPageNotifications = useCallback(async () => {
      const { items } = await dispatch(
        getChannelNotifications({ channelId, type: notificationType })
      ).unwrap();

      const topNotification = items[0];

      if (
        topNotification &&
        typeof newTopNotificationTime === 'undefined' &&
        selectedTabIndex === 0
      ) {
        const { deliveredTime: topNotificationTime } = topNotification;
        const lastTopNotificationTime = localStorage.getItem(storageKey);

        if (
          !lastTopNotificationTime ||
          isAfter(new Date(topNotificationTime), new Date(lastTopNotificationTime))
        ) {
          setNewTopNotificationTime(topNotificationTime);
        }
      }
    }, [
      channelId,
      dispatch,
      newTopNotificationTime,
      notificationType,
      selectedTabIndex,
      storageKey,
    ]);

    const loadMore = useCallback(() => {
      dispatch(loadMoreChannelNotifications({ channelId, type: notificationType, page: page + 1 }));
    }, [channelId, dispatch, notificationType, page]);

    const iconClickHandler = useCallback(() => {
      setIsOpen(!isOpen);

      if (newTopNotificationTime) {
        localStorage.setItem(storageKey, newTopNotificationTime);
        setNewTopNotificationTime('');
      }
    }, [isOpen, newTopNotificationTime, storageKey]);

    useEffect(() => {
      getFirstPageNotifications();
    }, [getFirstPageNotifications, selectedTabIndex]);

    const logOpen = useCallback(
      (notification_id: number) =>
        logPushOpen({
          channel_id: channelId,
          notification_id,
          time_stamp: new Date().toISOString(),
        }),
      [channelId, logPushOpen]
    );

    const renderNotificationDefault = useCallback(
      (notification: NotificationDefault) => {
        const { id, title, url, story, storyCard, message, deliveredTime } = notification;

        const hasLink = Boolean(url || (story && storyCard));

        const onClickHandler = () => {
          if (!hasLink) {
            return;
          }

          if (url) {
            window.open(url, '_blank', 'noreferrer');
          } else if (story && storyCard) {
            const { id: storyId, channelId: notificationChannelId } = story;
            const { id: cardId, type: cardType, content } = storyCard;

            if (notificationChannelId === channelId) {
              if (isMobileOnly) {
                navigate(layoutPath(`/details/${storyId}/${cardId}`));
              } else {
                setCardDetails({ cardId, cardType, storyId, scrollToComments: false });
                setIsOpen(false);
              }
              return;
            }

            const { url } = content as StoryCardArticleContent | StoryCardQuoteContent;

            window.open(url, '_blank', 'noreferrer');
          }

          logOpen(id);
          setIsOpen(false);
        };

        return (
          <NotificationWrapper
            key={id}
            hasLink={hasLink}
            avatarUrl={defaultLogoUrl}
            deliveredTime={deliveredTime}
            onClick={onClickHandler}
          >
            <NotificationDefaultContent title={title} message={message} hasLink={hasLink} />
          </NotificationWrapper>
        );
      },
      [channelId, defaultLogoUrl, logOpen, navigate]
    );

    const renderNotificationComment = useCallback(
      (notification: NotificationComment) => {
        const { id, deliveredTime, action, comment, parentComment, author } = notification;

        const { story, storyCard } = comment;

        const onClickHandler = () => {
          if (story && storyCard) {
            const { id: storyId } = story;
            const { id: cardId, type: cardType } = storyCard;

            isMobileOnly
              ? navigate(layoutPath(`/details/${storyId}/${cardId}?scrollToComments=true`))
              : setCardDetails({ cardId, cardType, storyId, scrollToComments: true });

            logOpen(id);
          }

          setIsOpen(false);
        };

        const { id: userId, avatar } = author;

        return (
          <NotificationWrapper
            key={id}
            userId={userId}
            avatarUrl={avatar?.url}
            deliveredTime={deliveredTime}
            onClick={onClickHandler}
            hasLink
          >
            <NotificationCommentContent
              commentAction={action}
              comment={comment}
              parentComment={parentComment}
              author={author}
            />
          </NotificationWrapper>
        );
      },
      [logOpen, navigate]
    );

    const getGroupLabel = useCallback(
      (time: string) => {
        switch (true) {
          case isThisWeek(new Date(time), { weekStartsOn: 1 }):
            return t('channelNotifications.this-week');
          case isThisMonth(new Date(time)):
            return t('channelNotifications.this-month');
          default:
            return t('channelNotifications.earlier');
        }
      },
      [t]
    );

    const renderNotifications = useMemo(() => {
      return (
        <>
          {channelNotifications.map((notification, index, notificationsArray) => {
            const { id, type, deliveredTime } = notification;

            return (
              <Fragment key={id}>
                {getGroupLabel(notificationsArray[index - 1]?.deliveredTime) !==
                  getGroupLabel(deliveredTime) && (
                  <div className={classes['notifications__group-label']}>
                    {getGroupLabel(deliveredTime)}
                  </div>
                )}
                {type === NotificationType.DEFAULT
                  ? renderNotificationDefault(notification as NotificationDefault)
                  : renderNotificationComment(notification as NotificationComment)}
              </Fragment>
            );
          })}
        </>
      );
    }, [channelNotifications, getGroupLabel, renderNotificationComment, renderNotificationDefault]);

    const loader = useMemo(() => <Skeleton className={classes['notifications__loader']} />, []);

    const tabContent = useMemo(() => {
      if (isFetching) {
        return loader;
      }

      if (!isFetching && !channelNotifications.length) {
        return (
          <div className={classes['notifications__no-content']}>
            {t('channelNotifications.no-content')}
          </div>
        );
      }

      return (
        <InfiniteScroll
          className={classes['notifications__infinite-scroll']}
          height={'30rem'} //needed for infinite scroll inside modal
          next={loadMore}
          hasMore={hasNextPage}
          loader={loader}
          dataLength={channelNotifications.length}
        >
          {renderNotifications}
        </InfiniteScroll>
      );
    }, [
      channelNotifications.length,
      hasNextPage,
      isFetching,
      loadMore,
      loader,
      renderNotifications,
      t,
    ]);

    const renderCardDetails = useMemo(() => {
      if (!cardDetails) {
        return null;
      }

      const { cardId, storyId, scrollToComments } = cardDetails;

      return (
        <DetailsModalComponent
          isOpen={Boolean(cardDetails)}
          storyId={storyId}
          cardId={cardId}
          scrollToComments={scrollToComments}
          onClose={() => setCardDetails(null)}
        />
      );
    }, [DetailsModalComponent, cardDetails]);

    const tabItems: TabItem[] = useMemo(() => {
      return Object.values(NotificationsTabType)
        .filter((values) => typeof values === 'string')
        .map((value) => {
          return {
            index: NotificationsTabType[value as keyof typeof NotificationsTabType],
            name: t(`channelNotificationsTabs.${value}`),
            content: tabContent,
          };
        });
    }, [t, tabContent]);

    const popupBody = useMemo(() => {
      return (
        <div
          className={classNames(classes['notifications'], {
            [classes['notifications--mobile']]: isMobileOnly,
          })}
        >
          <div className={classes['notifications__header']}>
            <span className={classes['notifications__header-title']}>
              {t('channelNotifications.title')}
            </span>
          </div>
          <Tabs
            items={tabItems}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            noBackground
          />
        </div>
      );
    }, [selectedTabIndex, t, tabItems]);

    return (
      <>
        <Popup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconId={'bell'}
          color={getCssVar('--notifications-icon-color')}
          hoverColor={getCssVar('--notifications-icon-hover-color')}
          onClick={iconClickHandler}
          bodyTop={'2rem'}
          bodyRight={'0'}
          body={popupBody}
          className={classNames({
            [classes['notifications__icon--has-new']]: Boolean(newTopNotificationTime),
          })}
        />
        {renderCardDetails}
      </>
    );
  }
);

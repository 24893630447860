/**
 * @example
 *  resolveBaseUrl('/api', '/'); // 'http://localhost:3000/api'
 *  resolveBaseUrl('/api', 'https://tchop-staging.com'); // 'https://tchop-staging.com/api'
 *  resolveBaseUrl('/api', '/channel'); // 'http://localhost:3000/channel/api'
 */
export const resolveBaseUrl = (url: string, basePath = '/'): string => {
  if (basePath === '/') {
    return new URL(url, window.location.origin).toString();
  }

  if (basePath.startsWith('/')) {
    return new URL(basePath + url, window.location.origin).toString();
  }

  return new URL(url, basePath).toString();
};

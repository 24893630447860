import { FunctionComponent, memo } from 'react';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './Avatar.module.scss';

interface AvatarProps {
  url?: string;
  size?: number;
  className?: string;
  nonClickable?: boolean;
  onClick?: () => void;
}

export const Avatar: FunctionComponent<AvatarProps> = memo(
  ({ url, size = 40, className, nonClickable, onClick }) => {
    if (!url) {
      return (
        <div className={classNames(className, classes['avatar'])}>
          <IconLabel
            iconId={'profile'}
            iconSize={size}
            color={getCssVar('--avatar-background-color')}
            nonClickable={nonClickable}
            singleColor
          />
        </div>
      );
    }
    return (
      <div
        className={classNames(className, classes['avatar'], {
          [classes['avatar--clickable']]: !nonClickable,
        })}
        style={{
          backgroundImage: `url(${url})`,
          minWidth: `${size}px`,
          width: `${size}px`,
          height: `${size}px`,
        }}
        onClick={onClick}
      ></div>
    );
  }
);

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLayout } from '../ErrorLayout';

import classes from './UnhandledError.module.scss';

export const UnhandledError: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout subtitle={t('unhandledError.title')}>
      <div className={classes['unhandled-error']}>
        <div className={classes['unhandled-error__message']}>{t('unhandledError.message')}</div>
      </div>
    </ErrorLayout>
  );
};

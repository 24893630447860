import { useTranslation } from 'react-i18next';
import {
  FilterGroup,
  FilterGroupItemsAlignment,
  FilterValue,
  useCardFilter,
} from '../../../../../shared';
import { CARD_COMMENTS, CARD_TEASER } from '../../CreateStoryModal';
import { StoryCardTeaserImageStyle, StoryHighlightingCommentsMode } from '../../../../../services';

export const useCreateStoryCardFilter = (filter: FilterValue[]) => {
  const { t } = useTranslation();

  const { groups: cardGroups } = useCardFilter({ filter });

  return {
    groups: [
      ...cardGroups,
      {
        id: CARD_COMMENTS,
        title: t('createStorySettings.featured-comments'),
        itemsAlignment: FilterGroupItemsAlignment.ROW,
        items: [
          {
            id: StoryHighlightingCommentsMode.MOST_LIKEABLE,
            label: t('createStorySettings.featured-comments-most-liked'),
            value: StoryHighlightingCommentsMode.MOST_LIKEABLE,
          },
          {
            id: StoryHighlightingCommentsMode.MANUAL,
            label: t('createStorySettings.featured-comments-manual'),
            value: StoryHighlightingCommentsMode.MANUAL,
          },
          {
            id: StoryHighlightingCommentsMode.FIRST,
            label: t('createStorySettings.featured-comments-first'),
            value: StoryHighlightingCommentsMode.FIRST,
          },
        ],
      },
      {
        id: CARD_TEASER,
        title: t('common.teaser-style'),
        itemsAlignment: FilterGroupItemsAlignment.ROW,
        items: [
          {
            id: StoryCardTeaserImageStyle.STANDARD,
            label: t('teasers.standard'),
            value: StoryCardTeaserImageStyle.STANDARD,
          },
          {
            id: StoryCardTeaserImageStyle.SMALL_WITH_TEXT,
            label: t('teasers.small'),
            value: StoryCardTeaserImageStyle.SMALL_WITH_TEXT,
          },
          {
            id: StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT,
            label: t('teasers.small-without-text'),
            value: StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT,
          },
          {
            id: StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT,
            label: t('teasers.large'),
            value: StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT,
          },
        ],
      },
    ] as FilterGroup[],
  };
};

import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Story, StoryCardCopySyncMoveData, storyCardApi } from '../../../../services';
import { getStoryFeed } from '../../../../slices';
import { COPY_CUT_COOKIE_KEY, CopyCutAction } from '../../../constants';
import { useAppDispatch, useDebounce, useOnClickOutside } from '../../../hooks';
import {
  CookieActionType,
  cookieOptions,
  getCssVar,
  getOrganisationDomain,
  isUrl,
} from '../../../utils';
import { Button, ButtonType } from '../../Button';
import { ConfirmationModal } from '../../ConfirmationModal';
import { DropdownMenu } from '../../DropdownMenu';
import { InputField } from '../../InputField';
import { Popup } from '../../Popup';
import { CreateCardModal, LinkPostFields, ShortPostFields } from '../CreateCardModal';

import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';
import classes from './CreateCardAdmin.module.scss';

interface CreateCardAdminProps {
  postInStories: Story[];
}

interface PostFilledFields {
  shortPostFilledFields?: ShortPostFields;
  linkPostFilledFields?: LinkPostFields;
}

interface PostFilesToUpload {
  files?: FileList;
  fileUrl?: string;
}

export const CreateCardAdmin: FunctionComponent<CreateCardAdminProps> = memo(
  ({ postInStories }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, _, removeCookie] = useCookies([COPY_CUT_COOKIE_KEY]);

    const {
      organisation: copyCutOrganisation = '',
      storyId: copyCutStoryId = 0,
      cardId: copyCutCardId = 0,
      cardReadOnly: copyCutIsReadOnly = false,
      action: copyCutAction = '',
    } = { ...cookies[COPY_CUT_COOKIE_KEY] };

    const { storyId: targetStoryId } = useParams();

    const [moveToOtherStoryTrigger] =
      storyCardApi.endpoints.storyCardMoveToOtherStory.useLazyQuery();

    const [postInStoryAsCopyTrigger] =
      storyCardApi.endpoints.storyCardPostInStoryAsCopy.useLazyQuery();

    const [postInStoryAsSyncTrigger] =
      storyCardApi.endpoints.storyCardPostInStoryAsSync.useLazyQuery();

    const [isOpen, setIsOpen] = useState(false);

    const [input, setInput] = useState('');

    const [postFilledFields, setPostFilledFields] = useState<PostFilledFields>({
      linkPostFilledFields: undefined,
      shortPostFilledFields: undefined,
    });

    const [postFilesToUpload, setPostFilesToUpload] = useState<PostFilesToUpload>({
      files: undefined,
      fileUrl: undefined,
    });

    const [errorMessage, setErrorMessage] = useState('');

    const [isCopySyncCutListOpen, setIsCopySyncCutListOpen] = useState(false);

    const isStoryFeed = useMemo(() => Boolean(targetStoryId), [targetStoryId]);

    const renderCopyPaste = useMemo(() => copyCutAction === CopyCutAction.COPY, [copyCutAction]);

    const renderCopySync = useMemo(
      () =>
        Boolean(!copyCutIsReadOnly && renderCopyPaste && copyCutStoryId !== Number(targetStoryId)),
      [copyCutIsReadOnly, copyCutStoryId, renderCopyPaste, targetStoryId]
    );

    const renderCutPaste = useMemo(
      () =>
        Boolean(copyCutAction === CopyCutAction.CUT && copyCutStoryId !== Number(targetStoryId)),
      [copyCutAction, copyCutStoryId, targetStoryId]
    );

    const renderCopySyncCutActions = useMemo(
      () =>
        Boolean(
          copyCutOrganisation === getOrganisationDomain() &&
            isStoryFeed &&
            (renderCopyPaste || renderCutPaste)
        ),
      [copyCutOrganisation, isStoryFeed, renderCopyPaste, renderCutPaste]
    );

    const debouncedValue = useDebounce<string>(input);

    const copySyncCutContainer = useRef<HTMLDivElement>(null);

    useOnClickOutside(copySyncCutContainer, () => {
      setIsCopySyncCutListOpen(false);
    });

    useEffect(() => {
      if (debouncedValue) {
        setPostFilledFields(
          isUrl(debouncedValue)
            ? { linkPostFilledFields: { url: debouncedValue } }
            : {
                shortPostFilledFields: {
                  sourceName: '',
                  headline: '',
                  subHeadline: '',
                  text: debouncedValue,
                  gallery: [],
                },
              }
        );

        setInput('');
        setIsOpen(true);
      }
    }, [debouncedValue]);

    const modalClose = useCallback(() => {
      setIsOpen(false);

      setPostFilledFields({
        linkPostFilledFields: undefined,
        shortPostFilledFields: undefined,
      });

      setPostFilesToUpload({
        files: undefined,
        fileUrl: undefined,
      });
    }, []);

    const copySyncMoveActionHandler = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (action: any) => {
        setIsCopySyncCutListOpen(false);

        try {
          const { payload, error }: StoryCardCopySyncMoveData = await toast.promise(
            action({
              sourceStoryId: copyCutStoryId,
              sourceStoryCardId: copyCutCardId,
              targetStoryId: Number(targetStoryId),
            }).unwrap(),
            {
              pending: t('cardActions.pasting'),
              success: t('cardActions.pasted'),
              error: t('cardActions.paste-error'),
            }
          );

          if (payload) {
            removeCookie(COPY_CUT_COOKIE_KEY, cookieOptions({ action: CookieActionType.REMOVE }));

            dispatch(getStoryFeed({ storyId: Number(targetStoryId) }));
          } else {
            toast.error(
              error.__typename === 'DuplicatedStoryItemIdError'
                ? t('cardActions.duplicate-error')
                : t('common.error-message')
            );
          }
        } catch (_) {}
      },
      [copyCutCardId, copyCutStoryId, dispatch, removeCookie, t, targetStoryId]
    );

    const pasteHandler = useCallback(async (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const { clipboardData } = event;

      const { files } = clipboardData;

      const clipboardText = clipboardData.getData('Text');

      //image jpg|jpeg|png|webp|avif|gif
      //auido mp3|wav|aiff
      //video mp4|webm|mov

      const isMedia = /\.(jpg|jpeg|png|webp|avif|gif|mp3|wav|aiff|mp4|webm|mov|pdf)$/.test(
        clipboardText
      );

      switch (true) {
        case Boolean(files.length): {
          setPostFilesToUpload({ files });
          break;
        }
        case isMedia: {
          setPostFilesToUpload({ fileUrl: clipboardText });
          break;
        }
        case !isMedia: {
          setPostFilledFields(
            isUrl(clipboardText)
              ? { linkPostFilledFields: { url: clipboardText } }
              : {
                  shortPostFilledFields: {
                    sourceName: '',
                    headline: '',
                    subHeadline: '',
                    text: clipboardText,
                    gallery: [],
                  },
                }
          );
          break;
        }
      }

      setIsOpen(true);
    }, []);

    const dropdownMenuContent = useMemo(() => {
      return (
        <>
          {renderCopyPaste && (
            <span
              className={dropdownMenuClasses['dropdown-menu__item']}
              onClick={() => copySyncMoveActionHandler(postInStoryAsCopyTrigger)}
            >
              {t('common.paste-card')}
            </span>
          )}
          {renderCopySync && (
            <span
              className={dropdownMenuClasses['dropdown-menu__item']}
              onClick={() => copySyncMoveActionHandler(postInStoryAsSyncTrigger)}
            >
              {t('common.sync-card')}
            </span>
          )}
          {renderCutPaste && (
            <span
              className={dropdownMenuClasses['dropdown-menu__item']}
              onClick={() => copySyncMoveActionHandler(moveToOtherStoryTrigger)}
            >
              {t('common.paste-card')}
            </span>
          )}
        </>
      );
    }, [
      copySyncMoveActionHandler,
      moveToOtherStoryTrigger,
      postInStoryAsCopyTrigger,
      postInStoryAsSyncTrigger,
      renderCopyPaste,
      renderCopySync,
      renderCutPaste,
      t,
    ]);

    return (
      <div className={classes['create-card']}>
        <InputField
          value={input}
          onChange={({ target }) => setInput(target.value)}
          inputClassName={classes['create-card__input']}
          placeholder={t(`addContent.input-placeholder${isMobileOnly ? '-mobile' : ''}`)}
          onPaste={pasteHandler}
          borderless
        />

        <Button
          className={classes['create-card__button']}
          type={ButtonType.primary}
          label={t('addContent.label')}
          onClick={() => setIsOpen(true)}
          fullWidth
        />

        {renderCopySyncCutActions && (
          <Popup
            isOpen={isCopySyncCutListOpen}
            setIsOpen={setIsCopySyncCutListOpen}
            iconId={'copy'}
            color={getCssVar('--create-card-link-color')}
            hoverColor={getCssVar('--create-card-link-hover-color')}
            bodyTop={'2.5rem'}
            bodyRight={'0'}
            body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
          />
        )}

        {isOpen && (
          <CreateCardModal
            isOpen={isOpen}
            onClose={modalClose}
            postInStories={postInStories}
            createWithFilledFields
            {...postFilledFields}
            {...postFilesToUpload}
          />
        )}

        {errorMessage && (
          <ConfirmationModal
            isOpen={Boolean(errorMessage)}
            onClose={() => setErrorMessage('')}
            onAccept={() => setErrorMessage('')}
            acceptLabel={t('common.accept')}
            title={t('cardSyncError.duplicate-story-error-title')}
            subTitle={errorMessage}
            hideCancel
          />
        )}
      </div>
    );
  }
);

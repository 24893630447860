import { useTranslation } from 'react-i18next';
import { FilterGroup } from '../../../../../shared';
import { ACCESS } from '../../CreateStoryModal';
import { StoryAccess } from '../../../../../services';

export const useCreateStoryAccessFilter = () => {
  const { t } = useTranslation();

  return {
    groups: [
      {
        id: ACCESS,
        multiCheck: true,
        items: [
          {
            id: StoryAccess.EDITOR_LIMITED,
            label: t('createStorySettings.access-editor-limited'),
            value: StoryAccess.EDITOR_LIMITED,
          },
          {
            id: StoryAccess.READER,
            label: t('createStorySettings.access-reader'),
            value: StoryAccess.READER,
          },
        ],
      },
    ] as FilterGroup[],
  };
};

import {
  StoryCard,
  StoryCardArticleContent,
  StoryCardEditorialContent,
  StoryCardPostContent,
  StoryCardQuoteContent,
  StoryCardThreadContent,
  StoryCardType,
} from '../../services';
import { LinkPostFields, ShortPostFields } from '../components';

export const getPostFieldsFromCard = (card?: StoryCard) => {
  if (!card) {
    return;
  }

  const { type, content } = card;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { textHTML = '', ...postContent } = { ...content };

  switch (type) {
    case StoryCardType.THREAD:
      return {
        threadPostFilledFields: postContent as StoryCardThreadContent,
      };
    case StoryCardType.POST: {
      return {
        fullPostFilledFields: postContent as StoryCardPostContent,
      };
    }
    case StoryCardType.EDITORIAL:
      return {
        shortPostFilledFields: { ...(postContent as StoryCardEditorialContent), gallery: [] },
      };
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF:
      return {
        shortPostFilledFields: postContent as ShortPostFields,
      };
    case StoryCardType.ARTICLE: {
      const { url, ...articleFields } = postContent as StoryCardArticleContent;

      return {
        linkPostFilledFields: {
          url,
          type,
          article: { ...articleFields, type },
        } as LinkPostFields,
      };
    }
    case StoryCardType.QUOTE: {
      const { url, ...quoteFields } = postContent as StoryCardQuoteContent;

      return {
        linkPostFilledFields: {
          url,
          type,
          quote: { ...quoteFields, type },
        } as LinkPostFields,
      };
    }
  }
};

import { FunctionComponent, memo } from 'react';
import { FeedCardAdmin } from '../../../../components';
import { StoriesCardsUserFeedCommon } from '../../../../shared';

interface StoriesCardsUserFeedAdminProps {
  userId: number;
}

export const StoriesCardsUserFeedAdmin: FunctionComponent<StoriesCardsUserFeedAdminProps> = memo(
  ({ userId }) => {
    return (
      <StoriesCardsUserFeedCommon
        userId={userId}
        cardComponent={(card) => <FeedCardAdmin card={card} />}
      />
    );
  }
);

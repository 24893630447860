import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TCHOP_ROOT_URL } from '../env';
import { resolveBaseUrl } from './helpers';
import { prepareHeaders } from './helpers/prepareHeaders';

export const graphqlApi = createApi({
  reducerPath: 'graphqlApi',
  baseQuery: fetchBaseQuery({
    baseUrl: resolveBaseUrl('/api', TCHOP_ROOT_URL),
    credentials: 'include',
    prepareHeaders,
  }),
  endpoints: () => ({}),
});

import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { useOrganisationQuery, useStoryCardByIdQuery } from '../../services';
import {
  Button,
  ButtonType,
  CookieActionType,
  IconLabel,
  InputField,
  NOTIFICATIONS_ADMIN_COOKIE_KEY,
  OrderDirection,
  Select,
  TabItem,
  Tabs,
  cookieOptions,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from '../../shared';
import {
  getInAppMessages,
  getPushNotifications,
  loadMoreInAppMessages,
  loadMorePushNotifications,
} from '../../slices';
import { NoContent } from '../Error';
import { CreateNotificationModal } from './CreateNotificationModal';
import { Notification } from './Notification';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classes from './Notifications.module.scss';

export enum NotificationsTabType {
  PUSH,
  INAPP,
}

export enum NotificationsType {
  PUSH = 'push',
  INAPP = 'in-app',
}

export const Notifications: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const storyId = Number(searchParams.get('storyId'));

  const cardId = Number(searchParams.get('cardId'));

  const { data: card } = useStoryCardByIdQuery({ storyId, cardId }, { skip: !storyId || !cardId });

  const { channels } = useAppSelector(({ channels }) => channels);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const channel = channels.find(({ id }) => id === channelId);

  const { data: organisation } = useOrganisationQuery();

  const {
    pushNotifications,
    page: pushNotificationsPage,
    hasNextPage: pushNotificationsHasNextPage,
    isFetching: isFetchingPushNotifications,
  } = useAppSelector(({ pushNotifications }) => pushNotifications);

  const {
    inAppMessages,
    page: inAppMessagesPage,
    hasNextPage: inAppMessagesHasNextPage,
    isFetching: isFetchingInAppMessages,
  } = useAppSelector(({ inAppMessages }) => inAppMessages);

  const [cookies, setCookie] = useCookies([NOTIFICATIONS_ADMIN_COOKIE_KEY]);

  const { selectedTabIndex = NotificationsTabType.PUSH } = {
    ...cookies[NOTIFICATIONS_ADMIN_COOKIE_KEY],
  };

  const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState<boolean>(false);

  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce<string>(query);

  const [orderDirection, setOrderDirection] = useState(OrderDirection.DESC);

  const type = useMemo(
    () =>
      selectedTabIndex === NotificationsTabType.PUSH
        ? NotificationsType.PUSH
        : NotificationsType.INAPP,
    [selectedTabIndex]
  );

  const isPush = useMemo(() => type === NotificationsType.PUSH, [type]);

  const openModalLabel = useMemo(
    () => `${t('common.new')} ${t(`notificationsPage.${type}`)}`,
    [t, type]
  );

  const channelPushNotificationAllowToCreate = useMemo(
    () => Boolean(channel?.permissions.channelPushNotificationAllowToCreate),
    [channel?.permissions.channelPushNotificationAllowToCreate]
  );

  const organisationPushNotificationAllowToCreate = useMemo(
    () => Boolean(organisation?.permissions.organisationPushNotificationAllowToCreate),
    [organisation?.permissions.organisationPushNotificationAllowToCreate]
  );

  const openModalDisabled = useMemo(
    () => !channelPushNotificationAllowToCreate && !organisationPushNotificationAllowToCreate,
    [channelPushNotificationAllowToCreate, organisationPushNotificationAllowToCreate]
  );

  const intro = useMemo(() => t(`notificationsPage.intro-${type}`), [t, type]);

  const noContentSubTitle = useMemo(() => t(`notificationsPage.no-content-${type}`), [t, type]);

  const noPushNotifications = useMemo(
    () => Boolean(isPush && !isFetchingPushNotifications && !pushNotifications.length),
    [isFetchingPushNotifications, isPush, pushNotifications.length]
  );

  const noInAppMessages = useMemo(
    () => Boolean(!isPush && !isFetchingInAppMessages && !inAppMessages.length),
    [inAppMessages.length, isFetchingInAppMessages, isPush]
  );

  const loader = useMemo(() => {
    return <Skeleton className={classes['notifications__loader']} height={'5rem'} count={3} />;
  }, []);

  useEffect(() => {
    if (card) {
      setCreateNotificationModalOpen(true);
    }
  }, [card]);

  useEffect(() => {
    if (isPush) {
      dispatch(getPushNotifications({ channelId, orderDirection, query }));
      return;
    }

    dispatch(getInAppMessages({ channelId, orderDirection, query }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, orderDirection]);

  const loadMore = useCallback(() => {
    if (isPush) {
      dispatch(
        loadMorePushNotifications({
          channelId,
          page: pushNotificationsPage + 1,
          orderDirection,
          query,
        })
      );
      return;
    }

    dispatch(
      loadMoreInAppMessages({
        channelId,
        page: inAppMessagesPage + 1,
        orderDirection,
        query,
      })
    );
  }, [
    channelId,
    dispatch,
    inAppMessagesPage,
    isPush,
    orderDirection,
    pushNotificationsPage,
    query,
  ]);

  const renderPushNotifications = useMemo(() => {
    if (isFetchingPushNotifications) {
      return loader;
    }

    if (noPushNotifications) {
      return <NoContent subtitle={noContentSubTitle} />;
    }

    return pushNotifications.map(
      ({
        id,
        title,
        message,
        createdTime,
        deliveredTime,
        expirationTime,
        permissions: { allowToDelete },
        statistic: { open, sent },
        channelId,
        url,
        storyCard,
      }) => (
        <Notification
          key={id}
          id={id}
          isPush={true}
          title={title}
          message={message}
          created={createdTime}
          delivered={deliveredTime}
          expiration={expirationTime}
          allowToDelete={allowToDelete}
          open={open}
          sent={sent}
          channelId={channelId}
          url={url}
          storyCard={storyCard}
        />
      )
    );
  }, [
    isFetchingPushNotifications,
    loader,
    noContentSubTitle,
    noPushNotifications,
    pushNotifications,
  ]);

  const renderInAppMessages = useMemo(() => {
    if (isFetchingInAppMessages) {
      return loader;
    }

    if (noInAppMessages) {
      return <NoContent subtitle={noContentSubTitle} />;
    }

    return inAppMessages.map(
      ({
        id,
        title,
        intro,
        created,
        delivery,
        expire,
        permissions: { allowToDelete },
        statistic: { open, sent },
        primary: { action },
        channelId,
        image,
      }) => (
        <Notification
          key={id}
          id={id}
          isPush={false}
          title={title}
          message={intro}
          created={created}
          delivered={delivery}
          expiration={expire}
          allowToDelete={allowToDelete}
          open={open}
          sent={sent}
          channelId={channelId}
          url={decodeURIComponent(action.split('?url=')[1] ?? '')}
          image={image}
          storyCard={null}
        />
      )
    );
  }, [inAppMessages, isFetchingInAppMessages, loader, noContentSubTitle, noInAppMessages]);

  const tabContent = useCallback(
    (notifications: JSX.Element | JSX.Element[]) => {
      return (
        <div className={classes['notifications__content']}>
          <div className={classes['notifications__content-intro']}>{intro}</div>
          <div className={classes['notifications__content-filters']}>
            <div className={classes['notifications__content-filters-search']}>
              <InputField
                value={query}
                placeholder={t('common.type-to-search')}
                onChange={({ target }) => setQuery(target.value)}
              />
              {query && (
                <IconLabel
                  className={classes['notifications__content-filters-search-clear']}
                  iconId={'close'}
                  onClick={() => setQuery('')}
                  singleColor
                />
              )}
            </div>
            <Select
              selectedItemId={orderDirection}
              items={[
                { id: OrderDirection.ASC, title: t('notificationsPage.order-direction-asc') },
                { id: OrderDirection.DESC, title: t('notificationsPage.order-direction-desc') },
              ]}
              onChange={(value) => setOrderDirection(value)}
            />
          </div>
          <InfiniteScroll
            className={classes['notifications__content-list']}
            next={loadMore}
            hasMore={isPush ? pushNotificationsHasNextPage : inAppMessagesHasNextPage}
            dataLength={isPush ? pushNotifications.length : inAppMessages.length}
            loader={loader}
          >
            {notifications}
          </InfiniteScroll>
        </div>
      );
    },
    [
      inAppMessages.length,
      inAppMessagesHasNextPage,
      intro,
      isPush,
      loadMore,
      loader,
      orderDirection,
      pushNotifications.length,
      pushNotificationsHasNextPage,
      query,
      t,
    ]
  );

  const onTabChange = useCallback(
    (index: number) => {
      setCookie(
        NOTIFICATIONS_ADMIN_COOKIE_KEY,
        { selectedTabIndex: index },
        cookieOptions({ action: CookieActionType.SET })
      );

      switch (index) {
        case NotificationsTabType.PUSH:
          return dispatch(getPushNotifications({ channelId, orderDirection, query }));
        case NotificationsTabType.INAPP:
          return dispatch(getInAppMessages({ channelId, orderDirection, query }));
      }
    },
    [channelId, dispatch, orderDirection, query, setCookie]
  );

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        index: NotificationsTabType.PUSH,
        name: t('notificationsPage.push'),
        content: tabContent(renderPushNotifications),
      },
      {
        index: NotificationsTabType.INAPP,
        name: t('notificationsPage.in-app'),
        content: tabContent(renderInAppMessages),
      },
    ],
    [renderInAppMessages, renderPushNotifications, t, tabContent]
  );

  return (
    <div className={classes['notifications']}>
      <div className={classes['notifications__header']}>
        <div className={classes['notifications__header-title']}>{t('notificationsPage.title')}</div>
        <Button
          type={ButtonType.primary}
          label={openModalLabel}
          disabled={openModalDisabled}
          className={classes['notifications__header-button']}
          onClick={() => setCreateNotificationModalOpen(true)}
        />
      </div>

      <Tabs
        items={tabItems}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={onTabChange}
        noBackground
      />

      {createNotificationModalOpen && (
        <CreateNotificationModal
          isOpen={createNotificationModalOpen}
          type={type}
          query={query}
          orderDirection={orderDirection}
          organisationPushNotificationAllowToCreate={organisationPushNotificationAllowToCreate}
          card={card}
          onClose={() => setCreateNotificationModalOpen(false)}
        />
      )}
    </div>
  );
};

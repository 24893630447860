import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../utils';
import { CloseButton } from '../CloseButton';
import { ConfirmationModal } from '../ConfirmationModal';
import { IconLabel } from '../IconLabel';

import classes from './Badge.module.scss';

interface BadgeProps {
  value: string;
  onRemove: () => void;
  onEdit?: () => void;
  removeConfirmion?: boolean;
  disabled?: boolean;
}

export const Badge: FunctionComponent<BadgeProps> = memo(
  ({ value, onRemove, onEdit, removeConfirmion, disabled }) => {
    const { t } = useTranslation();

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const removeHandler = useCallback(() => {
      if (removeConfirmion) {
        setIsDeleteOpen(true);
        return;
      }

      onRemove();
    }, [onRemove, removeConfirmion]);

    const badgeContent = useMemo(() => {
      if (disabled) {
        return value;
      }

      return (
        <>
          {Boolean(onEdit) && (
            <IconLabel
              iconId={'edit'}
              iconSize={18}
              color={getCssVar('--filter-badge-text-color')}
              onClick={onEdit}
              singleColor
            />
          )}

          {value}

          <CloseButton
            backgroundColor={getCssVar('--filter-badge-text-color')}
            iconBackgroundColor={getCssVar('--filter-badge-background-color')}
            onClick={removeHandler}
          />
        </>
      );
    }, [disabled, onEdit, removeHandler, value]);

    return (
      <>
        <div className={classes['badge']}>{badgeContent}</div>

        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          acceptLabel={t('common.delete')}
          onAccept={onRemove}
          title={t('common.delete-confirmation-title')}
          subTitle={t('common.delete-confirmation-sub-title')}
          danger
        />
      </>
    );
  }
);

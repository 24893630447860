import { FunctionComponent } from 'react';
import { FeedCardPlaceholder } from '../FeedCardPlaceholder';

import classes from './FeedCardLoader.module.scss';

export const FeedCardLoader: FunctionComponent = () => {
  return (
    <div className={classes['loader']}>
      <FeedCardPlaceholder />
      <FeedCardPlaceholder />
    </div>
  );
};

export const contentBlocksQuery = `
id
type
data {
  ... on ContentBlockAudioData {
    items {
      audio {
        id
        url
        status
      }
      caption
    }
  }
  ... on ContentBlockCalloutData {
    icon
    text
  }
  ... on ContentBlockChecklistData {
    items {
      checked
      text
    }
  }
  ... on ContentBlockCodeData {
    code
  }
  ... on ContentBlockDelimiterData {
    style
  }
  ... on ContentBlockEmbedlinkData {
    caption
    link {
      image {
        id
        originalFilename
        url
        averageColor
        rightholder
      }
      quoteSource
      sourceName
      text
      title
      url
    }
  }
  ... on ContentBlockFileData {
    items {
      caption
      file {
        id
        url
        status
        originalFilename
      }
    }
  }
  ... on ContentBlockHeaderData {
    level
    text
  }
  ... on ContentBlockImageData {
    items {
      caption
      image {
        id
        originalFilename
        url
        averageColor
        rightholder
      }
    }
  }
  ... on ContentBlockListData {
    style
    items {
      content
      items
    }
  }
  ... on ContentBlockParagraphData {
    text
  }
  ... on ContentBlockPdfData {
    items {
      caption
      pdf {
        id
        url
        status
        originalFilename
        thumb
      }
    }
  }
  ... on ContentBlockQuoteData {
    alignment
    caption
    text
  }
  ... on ContentBlockTableData {
    content
    withHeadings
  }
  ... on ContentBlockUserData {
    user {
      id
      screenName
      position
      department
      avatar {
        averageColor
        externalId
        externalType
        id
        originalFilename
        rightholder
        status
        statusCopyrightId
        thumb
        url
      }
    }
  }
  ... on ContentBlockStoryCardData {
    storyCard {
      id
      type
      storyId
      story {
        id
      }
      content {
        ... on StoryCardThreadContent {
          title
        }
        ... on StoryCardEditorialContent {
          headline
          sourceName
          subHeadline
          text
          textHTML
        }
        ... on StoryCardImageContent {
          headline
          sourceName
          subHeadline
          text
          textHTML
          gallery {
            id
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
            title
          }
        }
        ... on StoryCardAudioContent {
          headline
          sourceName
          subHeadline
          text
          textHTML
          gallery {
            id
            audio {
              id
              originalFilename
              thumb
              url
              status
            }
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
            title
          }
        }
        ... on StoryCardVideoContent {
          headline
          sourceName
          subHeadline
          text
          textHTML
          gallery {
            id
            video {
              id
              originalFilename
              thumb
              url
              status
            }
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
            title
          }
        }
        ... on StoryCardPdfContent {
          headline
          sourceName
          subHeadline
          text
          textHTML
          gallery {
            id
            pdf {
              id
              originalFilename
              thumb
              url
              status
            }
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
            title
          }
        }
        ... on StoryCardArticleContent {
          abstract
          contentAuthor
          contentReadTime
          gallery {
            id
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
          }
          headline
          sourceName
          styles {
            teaserImageStyle
          }
          title
          url
        }
        ... on StoryCardQuoteContent {
          gallery {
            id
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
          }            
          headline
          quote
          quoteCreated
          quotePerson
          quotePersonHandle
          quotePersonImage {
            id
            url
          }
          quoteSource
          url
        }
        ... on StoryCardPostContent {
          abstract
          contentAuthor
          contentReadTime
          gallery {
            id
            image {
              id
              originalFilename
              url
              averageColor
              rightholder
              status
            }
          }
          headline
          sourceName
          styles {
            teaserImageStyle
          }
          title
        }
      }
    }
  }
  ... on ContentBlockStoryData {
    story {
      id
      title
      subtitle
      image {
        averageColor
        id
        originalFilename
        rightholder
        status
        statusCopyrightId
        thumb
        url
      }
    }
  }
  ... on ContentBlockVideoData {
    items {
      caption
      video {
        id
        url
        status
        thumb
      }
    }
  }
}`;

import { FunctionComponent, memo } from 'react';
import { StoryCardEditorialContent } from '../../../services';
import { FeedCardProps } from '../../../shared';
import { FeedCardPreviewContent } from '../FeedCardPreviewContent';
import { FeedCardPreviewWrapper } from '../FeedCardPreviewWrapper';

export const FeedCardPreviewEditorial: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { sourceName, headline, subHeadline, text, textHTML } =
    card.content as StoryCardEditorialContent;

  return (
    <FeedCardPreviewWrapper card={card}>
      <FeedCardPreviewContent
        source={sourceName}
        title={headline}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      ></FeedCardPreviewContent>
    </FeedCardPreviewWrapper>
  );
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import EditorJS from '@editorjs/editorjs';
import ReactDOM from 'react-dom';

import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { DetailsBlock } from '../../../../components';
import { StoryCardPostContentBlockFields } from '../../../../services';
import { FileAcceptType, useTags } from '../../../hooks';
import { BlockUnion, GalleryItem } from '../../../models';
import { getCssVar, isAdminLayout, isEmptyString, stripHtmlString } from '../../../utils';
import { Button, ButtonType } from '../../Button';
import { FilePreview, FileUpload } from '../../File';
import { IconLabel } from '../../IconLabel';
import { Modal } from '../../Modal';
import { RichTextEditor } from '../../RichTextEditor';
import { TextAreaField } from '../../TextAreaField';
import { CreateCardSettingsTabType, FullPostFields } from '../CreateCardModal';
import { PostToggleFields, PostToggleFieldsItem } from '../PostToggleFields';

import classNames from 'classnames';
import classes from './FullPost.module.scss';

interface FullPostProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fullPostFields: FullPostFields;
  setFullPostFields: Dispatch<SetStateAction<FullPostFields>>;
  setFullPostGalleryItems: (galleryItems: GalleryItem[]) => void;
  fullPostTextEditorOpen: boolean;
  setFullPostTextEditorOpen: Dispatch<SetStateAction<boolean>>;
  fullPostTextEditorUsePrefill: boolean;
  setFullPostTextEditorUsePrefill: Dispatch<SetStateAction<boolean>>;
  activeFullPostGalleryItemIndex: number;
  setActiveFullPostGalleryItemIndex: (index: number) => void;
  settingsOpen: boolean;
  setSettingsOpen: (isOpen: boolean) => void;
  activeFullPostSettingsMediaTab: boolean;
  setActiveFullPostSettingsTabIndex: (index: number) => void;
}

export type FullPostToggleFields = {
  [key in keyof FullPostFields]?: PostToggleFieldsItem;
};

// ! Trying not to rerender EditorJS because of strict mode workaround

export const FullPost: FunctionComponent<FullPostProps> = memo(
  ({
    loading,
    setLoading,
    fullPostFields,
    setFullPostFields,
    setFullPostGalleryItems,
    fullPostTextEditorOpen,
    setFullPostTextEditorOpen,
    fullPostTextEditorUsePrefill,
    setFullPostTextEditorUsePrefill,
    activeFullPostGalleryItemIndex,
    setActiveFullPostGalleryItemIndex,
    settingsOpen,
    setSettingsOpen,
    activeFullPostSettingsMediaTab,
    setActiveFullPostSettingsTabIndex,
  }) => {
    const { t } = useTranslation();

    const { hasTags } = useTags();

    const sourceNameRef = useRef<HTMLTextAreaElement>(null);
    const abstractRef = useRef<HTMLTextAreaElement>(null);
    const authorRef = useRef<HTMLTextAreaElement>(null);
    const editorRef = useRef<EditorJS | null>(null);

    const { sourceName, headline, title, abstract, contentAuthor, gallery, contentBlocks } =
      fullPostFields;

    const [postFieldsToggling, setPostFieldsToggling] = useState<FullPostToggleFields>({
      sourceName: {
        ref: sourceNameRef,
        title: t('addContent.full-post-sourceName'),
        iconId: '',
        hidden: Boolean(isEmptyString(sourceName)),
      },
      abstract: {
        ref: abstractRef,
        title: t('addContent.full-post-abstract'),
        iconId: '',
        hidden: false,
      },
      contentAuthor: {
        ref: authorRef,
        title: t('addContent.full-post-author'),
        iconId: '',
        hidden: Boolean(isEmptyString(contentAuthor)),
      },
    });

    const [contentBlocksDraft, setContentBlocksDraft] = useState(contentBlocks);

    const [previewMode, setPreviewMode] = useState(false);

    const fieldChangeHandler = useCallback(
      (name: keyof FullPostFields, value: string) => {
        setFullPostFields({ ...fullPostFields, [name]: value });
      },
      [fullPostFields, setFullPostFields]
    );

    const keyDownHandler = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>, field: keyof FullPostFields) => {
        const { key, target } = event;

        if (key === 'Backspace' && !(target as HTMLTextAreaElement).value) {
          setPostFieldsToggling({
            ...postFieldsToggling,
            [field]: { ...postFieldsToggling[field], hidden: true },
          });
        }
      },
      [postFieldsToggling]
    );

    const onTextEditorSave = useCallback(async () => {
      setFullPostTextEditorOpen(false);

      if (!editorRef.current) {
        return;
      }

      const contentBlocks = (await editorRef.current.save())
        .blocks as StoryCardPostContentBlockFields[];

      const header =
        (
          fullPostTextEditorUsePrefill &&
          (contentBlocks.find(({ type }) => type === 'header')?.data as any)
        )?.text ?? '';

      const paragraph =
        (
          fullPostTextEditorUsePrefill &&
          (contentBlocks.find(({ type }) => type === 'paragraph')?.data as any)
        )?.text ?? '';

      const image = (
        fullPostTextEditorUsePrefill &&
        (contentBlocks.find(({ type }) => type === 'image')?.data as any)
      )?.items?.[0]?.image;

      const abstractValue = abstract || stripHtmlString(paragraph);

      setFullPostFields({
        ...fullPostFields,
        title: title || stripHtmlString(header).replace(/(\r\n|\n|\r)/gm, ' '), //remove line breaks for title when prefill from editor
        abstract: abstractValue,
        gallery: !gallery.length && image ? [{ image }] : gallery,
        contentBlocks,
      });

      setPostFieldsToggling({
        ...postFieldsToggling,
        abstract: {
          ...postFieldsToggling?.abstract,
          hidden: Boolean(isEmptyString(abstractValue)),
        },
      } as FullPostToggleFields);

      setFullPostTextEditorUsePrefill(false);
    }, [
      abstract,
      fullPostFields,
      fullPostTextEditorUsePrefill,
      gallery,
      postFieldsToggling,
      setFullPostFields,
      setFullPostTextEditorOpen,
      setFullPostTextEditorUsePrefill,
      title,
    ]);

    const previewBtnLabel = useMemo(
      () => (previewMode ? t('addContent.full-post-open-text-editor') : t('common.preview')),
      [previewMode, t]
    );

    const previewHandler = useCallback(async () => {
      if (!previewMode && editorRef.current) {
        setContentBlocksDraft(
          (await editorRef.current.save()).blocks as StoryCardPostContentBlockFields[]
        );
      }
      setPreviewMode(!previewMode);
    }, [previewMode]);

    const textEditorBody = useMemo(() => {
      return (
        <div className={classes['full-post__editor']}>
          <div
            className={classNames(classes['full-post__editor-header'], {
              [classes['full-post__editor-header--mobile']]: isMobileOnly,
            })}
          >
            <Button
              type={ButtonType.primary}
              label={previewBtnLabel}
              className={classes['full-post__editor-header-preview']}
              onClick={previewHandler}
            />
            {!previewMode && (
              <span
                className={classes['full-post__editor-header-close']}
                onClick={() => setFullPostTextEditorOpen(false)}
              ></span>
            )}
          </div>

          <div
            className={classNames(classes['full-post__editor-content'], {
              [classes['full-post__editor-content--hidden']]: previewMode,
              [classes['full-post__editor-content--mobile']]: isMobileOnly,
            })}
          >
            <RichTextEditor editorInstance={editorRef} contentBlocks={contentBlocks} />
          </div>

          {previewMode && (
            <div
              className={classNames(
                classes['full-post__editor-content'],
                classes[`full-post__editor-content--${isMobileOnly ? 'preview-mobile' : 'preview'}`]
              )}
            >
              {contentBlocksDraft.map((block, index) => (
                <DetailsBlock key={`${block.type}${index}`} block={block as BlockUnion} />
              ))}
            </div>
          )}

          {!previewMode && (
            <div
              className={classNames(classes['full-post__editor-footer'], {
                [classes['full-post__editor-footer--mobile']]: isMobileOnly,
              })}
            >
              <Button
                type={ButtonType.secondary}
                label={t('common.cancel')}
                onClick={() => setFullPostTextEditorOpen(false)}
                className={classes['full-post__editor-footer-cancel']}
              />
              <Button
                type={ButtonType.primary}
                label={t('common.save')}
                onClick={onTextEditorSave}
              />
            </div>
          )}
        </div>
      );
    }, [
      previewBtnLabel,
      previewHandler,
      previewMode,
      contentBlocks,
      contentBlocksDraft,
      t,
      onTextEditorSave,
      setFullPostTextEditorOpen,
    ]);

    const tagsClickHandler = useCallback(() => {
      setActiveFullPostSettingsTabIndex(CreateCardSettingsTabType.TAGS);
      setSettingsOpen(true);
    }, [setActiveFullPostSettingsTabIndex, setSettingsOpen]);

    const content = useMemo(() => {
      return (
        <>
          <TextAreaField
            inputClassName={classes['full-post__headline']}
            value={headline}
            placeholder={t('addContent.full-post-headline')}
            maxLength={360}
            disabled={loading}
            onChange={({ target }) => fieldChangeHandler('headline', target.value)}
          />

          <FilePreview
            galleryItems={gallery}
            setGalleryItems={setFullPostGalleryItems}
            activeGalleryItemIndex={activeFullPostGalleryItemIndex}
            setActiveGalleryItemIndex={setActiveFullPostGalleryItemIndex}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            activeMediaTab={activeFullPostSettingsMediaTab}
            setActiveMediaTab={(isActive) => {
              if (!isActive) {
                if (isAdminLayout()) {
                  setActiveFullPostSettingsTabIndex(CreateCardSettingsTabType.CARD);
                }
                return;
              }
              setActiveFullPostSettingsTabIndex(CreateCardSettingsTabType.MEDIA);
            }}
          />

          <FileUpload
            galleryItems={gallery}
            setGalleryItems={setFullPostGalleryItems}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={[FileAcceptType.IMAGE]}
            uploadLimit={1}
          />

          <div className={classes['full-post__fields']}>
            <TextAreaField
              ref={sourceNameRef}
              inputClassName={classes['full-post__sourceName']}
              value={sourceName}
              placeholder={t('addContent.full-post-sourceName')}
              maxLength={160}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('sourceName', target.value)}
              onKeyDown={(event) => keyDownHandler(event, 'sourceName')}
              hidden={postFieldsToggling?.sourceName?.hidden}
              disableLineBreak
            />

            <TextAreaField
              inputClassName={classes['full-post__title']}
              value={title}
              placeholder={t('addContent.full-post-title')}
              maxLength={160}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('title', target.value)}
              disableLineBreak
            />

            <TextAreaField
              ref={abstractRef}
              inputClassName={classes['full-post__abstract']}
              value={abstract}
              placeholder={t('addContent.full-post-abstract')}
              maxLength={700}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('abstract', target.value)}
              onKeyDown={(event) => keyDownHandler(event, 'abstract')}
              hidden={postFieldsToggling?.abstract?.hidden}
            />

            <TextAreaField
              ref={authorRef}
              inputClassName={classes['full-post__author']}
              value={contentAuthor}
              placeholder={t('addContent.full-post-author')}
              maxLength={60}
              disabled={loading}
              onChange={({ target }) => fieldChangeHandler('contentAuthor', target.value)}
              onKeyDown={(event) => keyDownHandler(event, 'contentAuthor')}
              hidden={postFieldsToggling?.contentAuthor?.hidden}
              disableLineBreak
            />
          </div>
        </>
      );
    }, [
      abstract,
      activeFullPostGalleryItemIndex,
      activeFullPostSettingsMediaTab,
      contentAuthor,
      fieldChangeHandler,
      gallery,
      headline,
      keyDownHandler,
      loading,
      postFieldsToggling?.abstract?.hidden,
      postFieldsToggling?.contentAuthor?.hidden,
      postFieldsToggling?.sourceName?.hidden,
      setActiveFullPostGalleryItemIndex,
      setActiveFullPostSettingsTabIndex,
      setFullPostGalleryItems,
      setLoading,
      setSettingsOpen,
      settingsOpen,
      sourceName,
      t,
      title,
    ]);

    return (
      <>
        <div className={classes['full-post']}>
          <div
            className={classNames(classes['full-post__scrollable'], {
              [classes['full-post__scrollable--mobile']]: isMobileOnly,
            })}
          >
            {content}
          </div>
          <div className={classes['full-post__pane']}>
            <div className={classes['full-post__pane-fields']}>
              {hasTags && (
                <IconLabel
                  iconId={'tags'}
                  iconSize={20}
                  label={t('addContent.short-post-tags')}
                  color={getCssVar('--create-card-link-color')}
                  hoverColor={getCssVar('--create-card-link-hover-color')}
                  onClick={tagsClickHandler}
                />
              )}
              <PostToggleFields
                loading={loading}
                fields={postFieldsToggling}
                setFields={setPostFieldsToggling}
              />
            </div>
          </div>
        </div>
        {fullPostTextEditorOpen &&
          ReactDOM.createPortal(
            <Modal
              isOpen={fullPostTextEditorOpen}
              body={textEditorBody}
              contentStyle={{
                padding: '0',
                top: '0',
                width: '100%',
                height: '100vh',
                maxHeight: '100vh',
                overflowX: 'hidden',
              }}
              alignTop
              onClose={() => setFullPostTextEditorOpen(false)}
            />,
            document.getElementById('modal-editor-root') as HTMLElement
          )}
      </>
    );
  }
);

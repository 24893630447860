import { FunctionComponent, memo } from 'react';
import { FeedCardProps, FeedCardSocialCommon } from '../../../shared';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';

export const FeedCardAdminSocial: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardSocialCommon card={card} />
    </FeedCardAdminWrapper>
  );
});

import { FunctionComponent, PropsWithChildren, memo } from 'react';
import { IconLabel } from '../../../../../shared';
import { useTranslation } from 'react-i18next';

import classes from '../../../Analytics.module.scss';

interface ChartWrapperProps {
  typeLocaliseKey: string;
  infoLocaliseKey: string;
  component?: JSX.Element;
}

export const ChartWrapper: FunctionComponent<PropsWithChildren<ChartWrapperProps>> = memo(
  ({ typeLocaliseKey, infoLocaliseKey, component, children }) => {
    const { t } = useTranslation();

    return (
      <div className={classes['analytics__chart']}>
        <div className={classes['analytics__chart-header']}>
          <span className={classes['analytics__chart-header-label']}>{t(typeLocaliseKey)}</span>
          <IconLabel iconId={'info'} iconSize={20} color={'#B3B3B3'} data={t(infoLocaliseKey)} />
          {component}
        </div>
        {children}
      </div>
    );
  }
);

import {
  StoryCard,
  StoryCardAudioContent,
  StoryCardImageContent,
  StoryCardPdfContent,
  StoryCardType,
  StoryCardVideoContent,
} from '../../services';
import { FileStatus } from '../models';

export const isCardMediaCompleted = (card: StoryCard) => {
  const { type, content } = card;

  switch (type) {
    case StoryCardType.IMAGE:
      return (content as StoryCardImageContent).gallery.every(
        ({ image }) => image.status === FileStatus.OK
      );
    case StoryCardType.AUDIO:
      return (content as StoryCardAudioContent).gallery[0].audio.status === FileStatus.OK;
    case StoryCardType.VIDEO:
      return (content as StoryCardVideoContent).gallery[0].video.status === FileStatus.OK;
    case StoryCardType.PDF:
      return (content as StoryCardPdfContent).gallery[0].pdf.status === FileStatus.OK;
    default:
      return true;
  }
};

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCommon, PaginationInfo, getStoryTabItemsLink } from '../../shared';
import { HorizontalStoryFeedAdminCard } from './HorizontalStoryFeedAdminCard';
import { useNavigate } from 'react-router-dom';
import { Story, StoryCard } from '../../services';

interface HorizontalStoryFeedAdminProps {
  story: Story;
  cards: StoryCard[];
  pageInfo: PaginationInfo;
  zIndex: number;
}

export const HorizontalStoryFeedAdmin: FunctionComponent<HorizontalStoryFeedAdminProps> = memo(
  ({ story, cards, pageInfo, zIndex }) => {
    const navigate = useNavigate();

    return (
      <HorizontalStoryFeedCommon
        story={story}
        cards={cards}
        pageInfo={pageInfo}
        zIndex={zIndex}
        CardComponent={HorizontalStoryFeedAdminCard}
        viewAllClick={() => navigate(getStoryTabItemsLink({ story }))}
      />
    );
  }
);

import { useLocation } from 'react-router-dom';
import { DeeplinkType } from '../components';

export interface DeepLinkUrlProps {
  type: DeeplinkType;
  channelId: number;
  userId?: number;
  storyId?: number;
  cardId?: number;
  chatId?: number;
}

export const useDeepLink = () => {
  const location = useLocation();

  return {
    isDeepLink: Boolean(new URLSearchParams(location.search).get('r')),
  };
};

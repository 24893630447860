import {
  ElementType,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context';
import {
  StoryCardContentArticlePost,
  StoryCardContentArticleQuote,
  StoryCardContentHeadline,
  StoryCardType,
} from '../../../../services';
import {
  ActionPane,
  AuthorInfo,
  CardActionsProps,
  Comments,
  FeedCardProps,
  FeedCardTags,
  layoutPath,
  ReactionsCounter,
  useAnalytics,
  useCardDate,
} from '../../../../shared';
import { DetailsModalProps } from '../../CardDetails';
import { FeedCardMargin } from '../FeedCardMargin';

import classNames from 'classnames';
import classes from './FeedCardWrapperCommon.module.scss';

interface FeedCardWrapperCommonProps extends FeedCardProps {
  CardActionsComponent: ElementType<CardActionsProps>;
  DetailsModalComponent: ElementType<DetailsModalProps>;
  authorTimeSyncComponent?: JSX.Element;
}

export const FeedCardWrapperCommon: FunctionComponent<
  PropsWithChildren<FeedCardWrapperCommonProps>
> = memo(
  ({
    card,
    children,
    CardActionsComponent,
    DetailsModalComponent,
    authorTimeSyncComponent,
    isProfileFeedCard,
  }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { logClickOpenArticle } = useAnalytics();

    const {
      userInfo: {
        userData: { channelId },
      },
      userProfile: { id: userProfileId },
    } = useContext(UserContext);

    const [showCardDetails, setShowCardDetails] = useState(false);

    const [cardDetailsScrollToComments, setCardDetailsScrollToComments] = useState(false);

    const [cardDetailsActiveCommentsInput, setCardDetailsActiveCommentsInput] = useState(false);

    const {
      id: cardId,
      type,
      story: { title: storyTitle },
      storyId,
      reactions,
      comments,
      options: { allowDisplayAuthor, allowCardReaction, allowComment },
      commentsRootCount,
      commentsCount,
      author,
      dateToDisplay,
      myReaction,
      content,
      externalTags,
    } = card;

    const date = useCardDate(dateToDisplay);

    const { id: authorId, avatar, screenName: authorName } = { ...author };

    const { headline } = content as StoryCardContentHeadline;

    const { url } = content as StoryCardContentArticleQuote;

    const { contentAuthor } = content as StoryCardContentArticlePost;

    const isAuthor = useMemo(() => Boolean(authorId === userProfileId), [authorId, userProfileId]);

    const isArticleOrPost = useMemo(
      () => [StoryCardType.ARTICLE, StoryCardType.POST].includes(type),
      [type]
    );

    const showPostedTime = useMemo(() => date && !allowDisplayAuthor, [allowDisplayAuthor, date]);

    const totalReactionsCount = useMemo(
      () =>
        Object.values(reactions)
          .filter(({ count }) => Boolean(count))
          .reduce((res, { count }) => res + count, 0) as number,
      [reactions]
    );

    const clickableChild = useMemo(
      () => [StoryCardType.THREAD, StoryCardType.ARTICLE, StoryCardType.POST].includes(type),
      [type]
    );

    const onlyMyReaction = useMemo(
      () => Boolean(myReaction && totalReactionsCount === 1),
      [myReaction, totalReactionsCount]
    );

    const headerSubTitle = useMemo(
      () => `${t('feed.posted-in')} "${storyTitle}"${t('common.separator')} ${date}`,
      [date, storyTitle, t]
    );

    const showCardComment = useMemo(
      () => headline && ((type === StoryCardType.QUOTE && allowDisplayAuthor) || isArticleOrPost),
      [allowDisplayAuthor, headline, isArticleOrPost, type]
    );

    const renderReactionsCounter = useMemo(
      () => Boolean(allowCardReaction && totalReactionsCount > 0 && !onlyMyReaction),
      [allowCardReaction, onlyMyReaction, totalReactionsCount]
    );

    const authorOrPosted = useMemo(
      () => Boolean(contentAuthor || showPostedTime),
      [contentAuthor, showPostedTime]
    );

    const showCardDetailsModal = useCallback(({ scrollToComments = false }) => {
      setShowCardDetails(true);
      setCardDetailsScrollToComments(scrollToComments);
    }, []);

    const closeCardDetailsModal = useCallback(() => {
      setShowCardDetails(false);
      setCardDetailsActiveCommentsInput(false);
    }, []);

    const commentsCounterClick = useCallback(() => {
      if (isMobileOnly) {
        navigate(layoutPath(`/details/${storyId}/${cardId}?scrollToComments=true`));
        return;
      }

      showCardDetailsModal({ scrollToComments: true });
      setCardDetailsActiveCommentsInput(true);
    }, [cardId, navigate, showCardDetailsModal, storyId]);

    const childClickHandler = useCallback(() => {
      if ([StoryCardType.QUOTE, StoryCardType.ARTICLE].includes(type)) {
        logClickOpenArticle({
          channel_id: channelId,
          story_id: storyId,
          item_id: cardId,
          time_stamp: new Date().toISOString(),
        });

        window.open(url, '_blank', 'noreferrer');
        return;
      }

      isMobileOnly
        ? navigate(layoutPath(`/details/${storyId}/${cardId}`))
        : showCardDetailsModal({ scrollToComments: false });
    }, [
      cardId,
      channelId,
      logClickOpenArticle,
      navigate,
      showCardDetailsModal,
      storyId,
      type,
      url,
    ]);

    const cardHeaderClickHandler = useCallback(() => {
      navigate(layoutPath(`/profile${!isAuthor ? `/${authorId}` : ''}`));
      window.scrollTo(0, 0);
    }, [authorId, isAuthor, navigate]);

    const commentsLoadMoreCustomAction = useCallback(() => {
      isMobileOnly
        ? navigate(layoutPath(`/details/${storyId}/${cardId}?scrollToComments=true`))
        : showCardDetailsModal({ scrollToComments: true });
    }, [cardId, navigate, showCardDetailsModal, storyId]);

    return (
      <>
        <div className={classNames(`card-${cardId}`, classes['card-wrapper'])}>
          {allowDisplayAuthor && (
            <FeedCardMargin top right bottom left>
              <AuthorInfo
                avatarUrl={avatar?.url}
                title={authorName}
                subTitle={headerSubTitle}
                onClick={cardHeaderClickHandler}
              />
            </FeedCardMargin>
          )}

          {showCardComment && (
            <FeedCardMargin top right bottom left>
              <div className={classes['card-wrapper__headline']}>{headline}</div>
            </FeedCardMargin>
          )}

          <div className={classes['card-wrapper__content']}>
            <div
              className={classNames(classes['card-wrapper__child'], {
                [classes['card-wrapper__child--clickable']]: clickableChild,
              })}
              onClick={() => clickableChild && childClickHandler()}
            >
              {children}
            </div>

            {Boolean(externalTags.length) && (
              <FeedCardMargin right left>
                <FeedCardTags tags={externalTags} />
              </FeedCardMargin>
            )}

            {authorOrPosted && (
              <FeedCardMargin right left>
                <div className={classes['card-wrapper__author-time']}>
                  {contentAuthor && (
                    <div className={classes['card-wrapper__content-author']}>
                      {`${t('common.by')} ${contentAuthor}`}
                    </div>
                  )}
                  {contentAuthor && showPostedTime && <span>•</span>}
                  {showPostedTime && (
                    <div className={classes['card-wrapper__posted-time']}>{date}</div>
                  )}
                </div>
              </FeedCardMargin>
            )}

            {authorTimeSyncComponent}

            {renderReactionsCounter && (
              <FeedCardMargin right left>
                <div className={classes['card-wrapper__reactions-counter']}>
                  <ReactionsCounter
                    totalCount={totalReactionsCount}
                    reactions={reactions}
                    myReaction={myReaction}
                  />
                </div>
              </FeedCardMargin>
            )}

            <FeedCardMargin right left>
              <div
                className={classNames(classes['card-wrapper__action-pane'], {
                  [classes['card-wrapper__action-pane--mobile']]: isMobileOnly,
                })}
              >
                <ActionPane
                  card={card}
                  myReaction={myReaction}
                  commentsCount={commentsCount}
                  commentsCounterClick={commentsCounterClick}
                  isProfileFeedCard={isProfileFeedCard}
                />

                <CardActionsComponent card={card} isProfileFeedCard={isProfileFeedCard} />
              </div>
            </FeedCardMargin>

            {allowComment && (
              <FeedCardMargin right left>
                <Comments
                  storyId={storyId}
                  cardId={cardId}
                  comments={comments}
                  commentsRootCount={commentsRootCount}
                  loadMoreCustomAction={commentsLoadMoreCustomAction}
                />
              </FeedCardMargin>
            )}
          </div>
        </div>

        {showCardDetails && (
          <DetailsModalComponent
            isOpen={showCardDetails}
            storyId={storyId}
            cardId={cardId}
            activeCommentsInput={cardDetailsActiveCommentsInput}
            scrollToComments={cardDetailsScrollToComments}
            onClose={closeCardDetailsModal}
          />
        )}
      </>
    );
  }
);

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorLayout } from '../ErrorLayout';

import classes from './NotFound.module.scss';

export const NotFound: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout isNotFound subtitle={t('notFoundPage.title')}>
      <div className={classes['not-found']}>
        <div className={classes['not-found__message']}>{t('notFoundPage.message')}</div>
      </div>
    </ErrorLayout>
  );
};

import { ChangeEvent, FunctionComponent, memo, useCallback } from 'react';

import classNames from 'classnames';
import classes from './Switch.module.scss';

interface SwitchProps {
  inputId: string;
  checked: boolean;
  label: string;
  className?: string;
  onChange: (checked: boolean) => void;
}

export const Switch: FunctionComponent<SwitchProps> = memo(
  ({ inputId, checked, label, className, onChange }) => {
    const onChangeHandler = useCallback(
      ({ target }: ChangeEvent<HTMLInputElement>) => {
        onChange(target.checked);
      },
      [onChange]
    );

    return (
      <label className={classNames(classes['switch'], className)} htmlFor={inputId}>
        {label}
        <div className={classes['switch__container']}>
          <input
            type={'checkbox'}
            id={inputId}
            className={classes['switch__input']}
            checked={checked}
            onChange={onChangeHandler}
          />
          <div className={classes['switch__slide']}></div>
        </div>
      </label>
    );
  }
);

import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { COLLAPSE_MODE_STORAGE_KEY } from '../../constants';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './CollapseSwitcher.module.scss';

export enum CollapseMode {
  STANDARD = 'STANDARD',
  COLLAPSED = 'COLLAPSED',
}

interface CollapseSwitcherProps {
  className?: string;
}

export const CollapseSwitcher: FunctionComponent<CollapseSwitcherProps> = memo(({ className }) => {
  const [mode, setMode] = useState(
    localStorage.getItem(COLLAPSE_MODE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  const isStandardMode = useMemo(() => mode === CollapseMode.STANDARD, [mode]);

  const onChange = useCallback((mode: CollapseMode) => {
    setMode(mode);
    localStorage.setItem(COLLAPSE_MODE_STORAGE_KEY, mode);
    window.dispatchEvent(new Event('storage'));
  }, []);

  const prefix = '--collapse-switcher';

  return (
    <div className={classNames(classes['switcher'], className)}>
      <IconLabel
        iconId={'collapse-mode-standard'}
        className={classNames(classes['switcher__mode'], {
          [classes['switcher__mode--active']]: isStandardMode,
        })}
        color={isStandardMode ? getCssVar(`${prefix}-active-color`) : '#b3b3b3'}
        hoverColor={getCssVar(`${prefix}-active-color`)}
        onClick={() => onChange(CollapseMode.STANDARD)}
      />
      <IconLabel
        iconId={'collapse-mode-collapsed'}
        className={classNames(classes['switcher__mode'], {
          [classes['switcher__mode--active']]: !isStandardMode,
        })}
        color={!isStandardMode ? getCssVar(`${prefix}-active-color`) : '#b3b3b3'}
        hoverColor={getCssVar(`${prefix}-active-color`)}
        onClick={() => onChange(CollapseMode.COLLAPSED)}
      />
    </div>
  );
});

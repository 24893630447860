import { FunctionComponent, memo } from 'react';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';
import { FeedCardAdminContent, FeedCardProps, FeedCardTeaser } from '../../../shared';

export const FeedCardAdminPost: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardTeaser card={card} ContentComponent={FeedCardAdminContent} />
    </FeedCardAdminWrapper>
  );
});

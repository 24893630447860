import InfiniteScroll from 'react-infinite-scroll-component';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FeedCard, PostedIn } from '../../components';
import { FeedCardLoader, useAppSelector, useSearchFeed } from '../../shared';
import { NewsFeedType, StoryCardItemData } from '../../services';
import sprite from '../../assets/icons/sprite.svg';

import classes from './SearchFeed.module.scss';

export const SearchFeed: FunctionComponent = () => {
  const { t } = useTranslation();

  const { getSearchFeedItems, loadMoreSearchFeedItems } = useSearchFeed();

  const [searchParams] = useSearchParams();

  const query = searchParams.get('qs') ?? '';

  const { newsFeed, totalItems, hasNextPage, isFetching } = useAppSelector(
    ({ newsFeed }) => newsFeed
  );

  const hasCards = useMemo(() => Boolean(newsFeed.length), [newsFeed.length]);

  useEffect(() => {
    getSearchFeedItems();
  }, [getSearchFeedItems]);

  const content = useMemo(() => {
    if (!hasCards) {
      return (
        <div className={classes['search__no-result']}>
          <svg className={classes['search__no-result-icon']}>
            <use href={`${sprite}#search-off`} />
          </svg>
          <div className={classes['search__no-result-text']}>
            <Trans i18nKey={'search.no-results'}>
              No results match that query
              <Link className={classes['search__no-result-link']} to={'/'}>
                Home
              </Link>
            </Trans>
          </div>
        </div>
      );
    }

    return (
      <InfiniteScroll
        className={classes['search__feed']}
        next={loadMoreSearchFeedItems}
        hasMore={hasNextPage}
        loader={<FeedCardLoader />}
        dataLength={newsFeed.length}
      >
        <div className={classes['search__feed-cards']}>
          {newsFeed.map((newsFeedItem, index, newsFeedArray) => {
            if (newsFeedItem.type !== NewsFeedType.STORY_CARD_ITEM) {
              return null;
            }

            const card = (newsFeedItem.data as StoryCardItemData).item;
            const prevItem = newsFeedArray[index - 1];

            return (
              <div key={`story-card-item-${card.id}`}>
                {(prevItem?.type !== NewsFeedType.STORY_CARD_ITEM ||
                  (prevItem?.type === NewsFeedType.STORY_CARD_ITEM &&
                    (prevItem.data as StoryCardItemData).item.story.title !==
                      card.story.title)) && <PostedIn story={card.story} />}
                <FeedCard card={card} />
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  }, [hasCards, hasNextPage, loadMoreSearchFeedItems, newsFeed]);

  if (isFetching) {
    return <FeedCardLoader />;
  }

  return (
    <>
      <div className={classes['search__title']}>
        {t('search.results-for')}&nbsp;
        <span className={classes['search__title-query']}>{query}</span>
      </div>
      <div className={classes['search__count']}>
        {t('search.results-found', { count: totalItems })}
      </div>
      {content}
    </>
  );
};

import { PUBLIC_URL } from '../../../../../env';

export const generatePdfTeaserUrl = async (file: File): Promise<string> => {
  const pdfjs = await import('pdfjs-dist/legacy/build/pdf');

  pdfjs.GlobalWorkerOptions.workerSrc = `${PUBLIC_URL}/pdf.worker.js`.replace(/\/\//g, '/');

  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      if (!fileReader.result) {
        resolve('');
        return;
      }

      const loadingTask = pdfjs.getDocument({
        data: new Uint8Array(fileReader.result as ArrayBuffer),
      });

      loadingTask.promise
        .then(async (pdf) => {
          try {
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1.5 });

            const canvas = document.createElement('canvas');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderTask = page.render({
              canvasContext: canvas.getContext('2d', {
                willReadFrequently: true,
              }) as CanvasRenderingContext2D,
              viewport: viewport,
            });

            await renderTask.promise;

            return canvas.toDataURL();
          } catch (e) {}

          return '';
        })
        .then((url) => resolve(url))
        .catch(() => resolve(''));
    };

    fileReader.readAsArrayBuffer(file);
  });
};

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ENV_NAME, IS_TEST, RELEASE_VERSION, SENTRY_DNS } from '../../env';

Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: ENV_NAME,
  release: RELEASE_VERSION,
  enabled: !IS_TEST,
  debug: false,
  normalizeDepth: 15,
});

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

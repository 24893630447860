import { useTranslation } from 'react-i18next';
import { StoryCardStatus } from '../../../services';

const GROUP_ID = 'status';

export const useCardStatusFilter = () => {
  const { t } = useTranslation();

  return {
    group: {
      id: GROUP_ID,
      title: t(`common.${GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryCardStatus.PUBLISHED,
          label: t('common.published'),
          value: StoryCardStatus.PUBLISHED,
        },
        {
          id: StoryCardStatus.UNPUBLISHED,
          label: t('common.unpublished'),
          value: StoryCardStatus.UNPUBLISHED,
        },
        {
          id: StoryCardStatus.SCHEDULED,
          label: t('common.scheduled'),
          value: StoryCardStatus.SCHEDULED,
        },
      ],
    },
  };
};

import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { StoryCardStatus } from '../../services';
import { getStoryFeed, getStoryFeedLoadMore } from '../../slices';
import { STORY_TAB_ITEMS_ADMIN_COOKIE_KEY } from '../constants';
import { isAdminLayout } from '../utils';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useLastDate } from './useLastDate';

interface StoryFeedProps {
  storyId: number;
}

export const useStoryFeed = ({ storyId }: StoryFeedProps) => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const [cookies] = useCookies([STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]);

  const { page } = useAppSelector(({ storyFeed }) => storyFeed);

  const { requestFilter = { status: [], dateRange: null } } = {
    ...cookies[STORY_TAB_ITEMS_ADMIN_COOKIE_KEY],
  };

  const { dateRange, ...requestFilterNoDateRange } = requestFilter; //TODO

  const filter = useMemo(() => {
    if (!isAdminLayout()) {
      return { status: [StoryCardStatus.PUBLISHED] };
    }

    const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
    const from = date?.from ?? null;
    const to = date?.to ?? null;

    return {
      ...requestFilterNoDateRange,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [dateRange, getLastDate, isLastDate, requestFilterNoDateRange]);

  const getStoryFeedItems = useCallback(() => {
    dispatch(getStoryFeed({ storyId, filter }));
  }, [dispatch, filter, storyId]);

  const storyFeedItemsLoadMore = useCallback(() => {
    dispatch(getStoryFeedLoadMore({ storyId, page: page + 1, filter }));
  }, [dispatch, filter, page, storyId]);

  return { getStoryFeedItems, storyFeedItemsLoadMore };
};

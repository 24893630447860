import { FunctionComponent, memo } from 'react';
import { StoryCardType } from '../../services';
import { FeedCardProps } from '../../shared';
import { FeedCardArticle } from './FeedCardArticle';
import { FeedCardAudio } from './FeedCardAudio';
import { FeedCardEditorial } from './FeedCardEditorial';
import { FeedCardImage } from './FeedCardImage';
import { FeedCardPdf } from './FeedCardPdf';
import { FeedCardPost } from './FeedCardPost';
import { FeedCardSocial } from './FeedCardSocial';
import { FeedCardThread } from './FeedCardThread';
import { FeedCardVideo } from './FeedCardVideo';

export const FeedCard: FunctionComponent<FeedCardProps> = memo((props) => {
  const { card } = props;

  switch (card.type) {
    case StoryCardType.THREAD:
      return <FeedCardThread card={card} />;
    case StoryCardType.IMAGE:
      return <FeedCardImage {...props} />;
    case StoryCardType.EDITORIAL:
      return <FeedCardEditorial card={card} />;
    case StoryCardType.AUDIO:
      return <FeedCardAudio card={card} />;
    case StoryCardType.VIDEO:
      return <FeedCardVideo {...props} />;
    case StoryCardType.PDF:
      return <FeedCardPdf card={card} />;
    case StoryCardType.QUOTE:
      return <FeedCardSocial card={card} />;
    case StoryCardType.ARTICLE:
      return <FeedCardArticle card={card} />;
    case StoryCardType.POST:
      return <FeedCardPost card={card} />;
    default:
      return null;
  }
});

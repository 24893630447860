import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../context';
import { userApi } from '../../../../services';
import {
  Button,
  ButtonType,
  IconLabel,
  InputField,
  emailValidationError,
  getCssVar,
  getWebAppUrl,
} from '../../../../shared';
import { FreeLogin } from '../FreeLogin';
import { LoginContentType } from '../Login';
import { SocialLogin } from '../SocialLogin/SocialLogin';
import { TwoFactorAuthSetupProps } from '../TwoFactor';

import classNames from 'classnames';
import formClasses from '../../Form/Form.module.scss';
import classes from './LoginWithEmail.module.scss';

interface LoginWithEmailProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  setTwoFactorAuthSetup: Dispatch<SetStateAction<TwoFactorAuthSetupProps>>;
  twoFactorBackHandler: () => void;
  otpProcessing: ({ otp, otpBackup }: { otp?: string; otpBackup?: string }) => void;
  onSuccessLogin: ({ accessToken, webappUrl }: { accessToken: string; webappUrl: string }) => void;
  setLoginContentType: Dispatch<SetStateAction<LoginContentType | null>>;
  isFreeLogin?: boolean;
}

export const LoginWithEmail: FunctionComponent<LoginWithEmailProps> = memo(
  ({
    email,
    setEmail,
    password,
    setPassword,
    setTwoFactorAuthSetup,
    twoFactorBackHandler,
    otpProcessing,
    onSuccessLogin,
    setLoginContentType,
    isFreeLogin,
  }) => {
    const { t } = useTranslation();

    const {
      emailPlaceholder,
      login: {
        forgotUrl,
        isRegular,
        social: { google, facebook, apple },
      },
    } = useContext(ConfigContext).config.elements;

    const [triggerLoginByEmail] = userApi.endpoints.userLoginByEmail.useLazyQuery();

    const [peeping, setPeeping] = useState<boolean>(false);

    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

    const [credentialError, setCredentialError] = useState<boolean>(false);

    const passwordFieldType = useMemo(() => (peeping ? 'text' : 'password'), [peeping]);

    const forgotPassUrl = useMemo(() => forgotUrl ?? '/auth/forgot?type=magic', [forgotUrl]);

    const loginButton = useRef<HTMLButtonElement>(null);

    const loginActive = useMemo(
      () => email.length && password.length,
      [email.length, password.length]
    );

    const hasSocial = useMemo(
      () => [google, facebook, apple].some((social) => social),
      [apple, facebook, google]
    );

    const renderSocial = useMemo(() => {
      if (!hasSocial || isFreeLogin) {
        return null;
      }

      return (
        <>
          <div className={classes['divider']}>{t('auth.or-login-with')}</div>
          <SocialLogin onSuccessLogin={onSuccessLogin} />
        </>
      );
    }, [hasSocial, isFreeLogin, onSuccessLogin, t]);

    const emailChangeHandler = useCallback(
      (value: string) => {
        setIsEmailValid(true);
        setCredentialError(false);
        setEmail(value);
      },
      [setEmail]
    );

    const passwordChangeHandler = useCallback(
      (value: string) => {
        setCredentialError(false);
        setPassword(value);
      },
      [setPassword]
    );

    const loginClickHandler = useCallback(async () => {
      setCredentialError(false);

      if (emailValidationError(email)) {
        setIsEmailValid(false);
        return;
      }

      loginButton.current?.setAttribute('disabled', 'true');

      const { payload, error } = await triggerLoginByEmail({ email, password }).unwrap();

      if (payload) {
        const { accessToken, channel } = payload;

        await onSuccessLogin({ accessToken, webappUrl: getWebAppUrl(channel.subdomain) });

        return;
      }

      switch (error.__typename) {
        case 'User2FAOtpSetupIsRequiredError': {
          const { otpAuthSecret = '', otpAuthUrlQRCode = '' } = { ...error };
          setTwoFactorAuthSetup({
            otpAuthSecret,
            otpAuthUrlQRCode,
            onVerify: otpProcessing,
            onBack: twoFactorBackHandler,
          });
          setLoginContentType(LoginContentType.SETUP_2FA);
          break;
        }
        case 'User2FAOtpIsRequiredError': {
          setLoginContentType(LoginContentType.CODE_2FA);
          break;
        }
        default: {
          setCredentialError(true);
          loginButton.current?.removeAttribute('disabled');
        }
      }
    }, [
      email,
      onSuccessLogin,
      otpProcessing,
      password,
      setLoginContentType,
      setTwoFactorAuthSetup,
      triggerLoginByEmail,
      twoFactorBackHandler,
    ]);

    return (
      <>
        <InputField
          label={t('common.email')}
          value={email}
          placeholder={emailPlaceholder ?? t('common.email-placeholder')}
          onChange={({ target }) => emailChangeHandler(target.value)}
          error={!isEmailValid}
          {...(!isEmailValid && { errorMessage: t('auth.email-invalid') })}
        />

        <InputField
          label={t('common.password')}
          value={password}
          type={passwordFieldType}
          placeholder={t('common.password-placeholder')}
          icon={
            <IconLabel
              iconId={peeping ? 'eye-crossed' : 'eye'}
              iconSize={18}
              onClick={() => setPeeping(!peeping)}
              color={getCssVar('--input-icon-color')}
              singleColor
            />
          }
          onChange={({ target }) => passwordChangeHandler(target.value)}
        />

        {credentialError && (
          <div className={formClasses['form__content-error']}>{t('auth.login-fail')}</div>
        )}

        <Link
          to={forgotPassUrl}
          className={classNames(formClasses['form__content-link'], classes['forgot'])}
        >
          {t('auth.forgot-password')}
        </Link>

        <Button
          ref={loginButton}
          type={ButtonType.primary}
          label={t('common.login')}
          disabled={!loginActive}
          onClick={loginClickHandler}
          fullWidth
        />

        {!isFreeLogin && <FreeLogin onSuccessLogin={onSuccessLogin} />}

        {isFreeLogin && hasSocial && (
          <Link to={'/auth/login'} className={classNames(formClasses['form__content-link'])}>
            {t('auth.more-login-options')}
          </Link>
        )}

        {isRegular && (
          <>
            {renderSocial}

            <div className={classes['sign-up']}>
              <span className={classes['sign-up__label']}>{t('auth.sign-up-label')}</span>
              <Link
                to={'/auth/signup'}
                className={classNames(formClasses['form__content-link'], classes['sign-up__link'])}
              >
                {t('auth.sign-up')}
              </Link>
            </div>
          </>
        )}
      </>
    );
  }
);

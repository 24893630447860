import { createRef, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationTag, OrganisationTagType } from '../../../../services';
import {
  Button,
  ButtonType,
  InputField,
  ORG_TAGS_NAME_LENGTH_LIMIT,
  Select,
} from '../../../../shared';

import classes from './TagEdit.module.scss';

interface TagEditProps {
  tag: OrganisationTag;
  onEdit: (tag: OrganisationTag) => void;
}

export const TagEdit: FunctionComponent<TagEditProps> = ({ tag, onEdit }) => {
  const { t } = useTranslation();

  const { id, name, type } = tag;

  const [tagName, setTagName] = useState(name);

  const [tagType, setTagType] = useState(type);

  const inputRef = createRef<HTMLInputElement>();

  const dropdownItems = useMemo(() => {
    return Object.values(OrganisationTagType).map((value) => {
      return {
        id: value,
        title: t(`organisationTagType.${value}`),
        hint: t(`organisationTags.sub-title-${value}`),
      };
    });
  }, [t]);

  const saveDisabled = useMemo(
    () => Boolean(!tagName.length || (name === tagName && type === tagType)),
    [name, tagName, tagType, type]
  );

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.focus();

    inputRef.current.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length
    );
  }, [inputRef]);

  return (
    <div className={classes['tag-edit']}>
      <InputField
        ref={inputRef}
        value={tagName}
        placeholder={t('organisationTags.add-placeholder')}
        onChange={({ target }) => setTagName(target.value)}
        maxLength={ORG_TAGS_NAME_LENGTH_LIMIT}
      />

      <Select
        selectedItemId={tagType}
        items={dropdownItems}
        onChange={(type) => setTagType(type)}
        staticListPosition
      />

      <Button
        type={ButtonType.primary}
        label={t('common.save')}
        onClick={() => onEdit({ id, name: tagName, type: tagType })}
        disabled={saveDisabled}
      />
    </div>
  );
};

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardContentMedia } from '../../../../services';

export const HorizontalStoryFeedAdminMedia: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    const { content, isPinnedToTopOfNewsFeed } = card;
    const { headline, subHeadline, sourceName, text, textHTML } = content as StoryCardContentMedia;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          source={sourceName}
          title={headline}
          subTitle={subHeadline}
          text={text}
          htmlText={textHTML}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedAdminCardWrapper>
    );
  }
);

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { FunctionComponent, PropsWithChildren, memo, useCallback } from 'react';
import { storyCardApi } from '../../../../services';
import { useAppDispatch } from '../../../hooks';
import { IconLabel } from '../../IconLabel';

import classNames from 'classnames';
import classes from './FeedCardDrag.module.scss';

interface FeedCardDragProps {
  storyId: number;
  storyCardId: number;
  dragId: number | null;
  setDragId: (dragId: number | null) => void;
  dropProcessing: boolean;
  setDropProcessing: (dropProcessing: boolean) => void;
  position: number;
  updatePosition: ActionCreatorWithPayload<{ dragId: number; targetId: number }>;
}

export const FeedCardDrag: FunctionComponent<PropsWithChildren<FeedCardDragProps>> = memo(
  ({
    children,
    storyId,
    storyCardId,
    dragId,
    setDragId,
    dropProcessing,
    setDropProcessing,
    position,
    updatePosition,
  }) => {
    const dispatch = useAppDispatch();

    const [storyCardPositionUpdate] = storyCardApi.endpoints.storyCardPositionUpdate.useLazyQuery();

    const onDrop = useCallback(
      (event: React.DragEvent<HTMLDivElement>, cardId: number, position: number) => {
        if (dragId === null) {
          return;
        }

        event.preventDefault();

        (event.target as HTMLDivElement).classList.remove(classes['card--ghost']);

        if (cardId === dragId) {
          return;
        }

        setDropProcessing(true);

        dispatch(updatePosition({ dragId, targetId: cardId }));

        storyCardPositionUpdate({ storyId, storyCardId: dragId, position });

        setDragId(null);

        setDropProcessing(false);
      },
      [
        dispatch,
        dragId,
        setDragId,
        setDropProcessing,
        storyCardPositionUpdate,
        storyId,
        updatePosition,
      ]
    );

    const onDragEnd = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        if (dragId === null) {
          return;
        }

        (event.target as HTMLDivElement).classList.remove(classes['card--ghost']);

        if (!dropProcessing) {
          setDragId(null);
        }
      },
      [dragId, dropProcessing, setDragId]
    );

    const onDragLeave = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        if (dragId === null) {
          return;
        }

        (event.target as HTMLDivElement).classList.remove(classes['card--ghost']);
      },
      [dragId]
    );

    const onDragOver = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        if (dragId === null) {
          return;
        }

        event.preventDefault();

        (event.target as HTMLDivElement).classList.add(classes['card--ghost']);
      },
      [dragId]
    );

    return (
      <div
        draggable
        className={classNames(classes['card'], { [classes['card--dragging']]: Boolean(dragId) })}
        onDragStart={() => setDragId(storyCardId)}
        onDrop={(event) => onDrop(event, storyCardId, position)}
        onDragEnd={onDragEnd}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <IconLabel
          iconId={'drag'}
          iconSize={14}
          className={classes['card__drag-icon']}
          singleColor
        />
        <div draggable>{children}</div>
      </div>
    );
  }
);

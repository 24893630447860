import { FunctionComponent, useContext } from 'react';
import { UserContext } from '../../context';
import { Profile } from './Profile';
import { ProfileFeed } from './ProfileFeed';
import { UserFeedAdmin } from './UserFeedAdmin/UserFeedAdmin';

import classes from './Profile.module.scss';

export const ProfileMeAdmin: FunctionComponent = () => {
  const { userProfile, userInfo } = useContext(UserContext);

  const { id: userId, avatar, screenName, gender, bio, links, roleId, url } = userProfile;

  const { channelId } = userInfo.userData;

  return (
    <>
      <Profile
        userId={userId}
        roleId={roleId}
        avatar={avatar}
        screenName={screenName}
        gender={gender}
        bio={bio}
        links={links}
        url={url}
      />

      <div className={classes['profile__feeds-wrapper']}>
        <ProfileFeed userId={userId} />
        <UserFeedAdmin channelId={channelId} userId={userId} />
      </div>
    </>
  );
};

import { FunctionComponent, memo } from 'react';
import { StoryCardVideoContent } from '../../../services';
import { FeedCardAdminContent, FeedCardProps, FeedCardVideoCommon } from '../../../shared';
import { FeedCardAdminWrapper } from '../FeedCardAdminWrapper';

export const FeedCardAdminVideo: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardVideoContent;

  return (
    <FeedCardAdminWrapper card={card}>
      <FeedCardAdminContent
        storyId={storyId}
        cardId={id}
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={headline}
        media={<FeedCardVideoCommon card={card} />}
        subTitle={subHeadline}
        text={text}
        htmlText={textHTML}
      />
    </FeedCardAdminWrapper>
  );
});

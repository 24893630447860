import { useTranslation } from 'react-i18next';
import {
  FilterGroup,
  FilterValue,
  optionalArrayItem,
  useAppTabsLabel,
  useFilter,
} from '../../../../../shared';
import { useContext, useMemo } from 'react';
import { DISPLAY, DisplayProps } from '../../CreateStoryModal';
import { ChannelAppTabAction, StoryPinned } from '../../../../../services';
import { UserContext } from '../../../../../context';

export const useCreateStoryDisplayFilter = (filter: FilterValue[]) => {
  const { t } = useTranslation();

  const { getAppTabsLabel } = useAppTabsLabel();

  const { getGroupItemValue } = useFilter();

  const { userPermissions } = useContext(UserContext).userInfo.userData;

  const { allowToPinStoryToOrganisation } = userPermissions;

  const pinnedValue = useMemo(
    () =>
      getGroupItemValue({
        filter,
        groupId: DISPLAY,
        itemId: DisplayProps.pinned,
      }) ?? StoryPinned.CHANNEL,
    [filter, getGroupItemValue]
  );

  const horizontalPosition = useMemo(
    () =>
      getGroupItemValue({
        filter,
        groupId: DISPLAY,
        itemId: DisplayProps.horizontalPosition,
      }) ?? 1,
    [filter, getGroupItemValue]
  );

  return {
    groups: [
      {
        id: DISPLAY,
        multiCheck: true,
        items: [
          {
            id: DisplayProps.includeInWebappNewsFeed,
            label: `${t('common.display-in')} ${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t(
              'common.web'
            )}`,
            value: DisplayProps.includeInWebappNewsFeed,
          },
          {
            id: DisplayProps.includeInNewsFeed,
            label: `${t('common.display-in')} ${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t(
              'common.mobile'
            )}`,
            value: DisplayProps.includeInNewsFeed,
          },
          {
            id: DisplayProps.includeInMixTab,
            label: `${t('common.display-in')} ${getAppTabsLabel(ChannelAppTabAction.STORIES)}`,
            value: DisplayProps.includeInMixTab,
          },
          {
            id: DisplayProps.includeInMixTab2,
            label: `${t('common.display-in')} ${getAppTabsLabel(ChannelAppTabAction.STORIES2)}`,
            value: DisplayProps.includeInMixTab2,
          },
          {
            id: DisplayProps.pinned,
            label: `${t('common.display-in')} ${getAppTabsLabel(ChannelAppTabAction.PINNED_STORY)}`,
            value: pinnedValue,
            dropdown: {
              options: [
                {
                  id: StoryPinned.CHANNEL,
                  title: t('createStorySettings.display-in-pinned-mix-channel'),
                },
                ...optionalArrayItem(allowToPinStoryToOrganisation, {
                  id: StoryPinned.ORGANISATION,
                  title: t('createStorySettings.display-in-pinned-mix-organisation'),
                }),
              ],
            },
          },
          {
            id: DisplayProps.horizontalPosition,
            label: t('createStorySettings.display-in-horizontal-mix'),
            value: horizontalPosition,
            inputNumber: {
              label: `${t('common.position-in')} ${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)}`,
              min: 1,
              max: 100,
            },
          },
        ],
      },
    ] as FilterGroup[],
  };
};

import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../context';
import {
  Avatar,
  getChannelDomain,
  getWebAppUrl,
  layoutPath,
  UserBlockData,
} from '../../../../shared';

import classes from './UserProfile.module.scss';

export const UserProfile: FunctionComponent<UserBlockData> = memo(({ user }) => {
  const { t } = useTranslation();

  const userId = useContext(UserContext).userProfile?.id;

  const channelDomain = useMemo(() => getChannelDomain(), []);

  const clickHandler = useCallback(
    (url: string) => {
      if (!userId) {
        toast.info(t('richTextEditor.user-block-not-ready'));
        return;
      }

      window.open(url, '_blank', 'noopener, noreferrer');
    },
    [t, userId]
  );

  if (!user) {
    return null;
  }

  const { id, screenName, position, department, avatar } = user;

  const url = `${getWebAppUrl(channelDomain)}${layoutPath(
    `${id === userId ? '/profile' : `/profile/${id}`}`
  )}`;

  return (
    <div className={classes['user']} onClick={() => clickHandler(url)}>
      <Avatar url={avatar?.url} size={48} nonClickable />
      <div className={classes['user__fields']}>
        {screenName && <div className={classes['user__fields-name']}>{screenName}</div>}
        {Boolean(position || department) && (
          <div className={classes['user__fields-position-department']}>
            {position} {position && department && '/'} {department}
          </div>
        )}
      </div>
    </div>
  );
});

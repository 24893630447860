/* eslint-disable @typescript-eslint/no-explicit-any */
import EditorJS from '@editorjs/editorjs';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FileAcceptType, useTags } from '../../../hooks';
import { GalleryItem } from '../../../models';
import {
  getCssVar,
  htmlToMarkdown,
  isAdminLayout,
  isEmptyString,
  isUrl,
  markdownToHtml,
} from '../../../utils';
import { FilePreview, FileUpload } from '../../File';
import { IconLabel } from '../../IconLabel';
import { RichTextEditor } from '../../RichTextEditor';
import { TextAreaField } from '../../TextAreaField';
import { CreateCardSettingsTabType, ShortPostFields } from '../CreateCardModal';
import { PostToggleFields, PostToggleFieldsItem } from '../PostToggleFields';

import classNames from 'classnames';
import classes from './ShortPost.module.scss';

interface ShortPostProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  shortPostFields: ShortPostFields;
  setShortPostFields: Dispatch<SetStateAction<ShortPostFields>>;
  setShortPostGalleryItems: (galleryItems: GalleryItem[]) => void;
  activeShortPostGalleryItemIndex: number;
  setActiveShortPostGalleryItemIndex: (index: number) => void;
  convertToLinkPost: (url: string, headline: string) => void;
  setSettingsOpen: (isOpen: boolean) => void;
  settingsOpen: boolean;
  activeShortPostSettingsMediaTab: boolean;
  setActiveShortPostSettingsTabIndex: (index: number) => void;
  files?: FileList;
  fileUrl?: string;
}

export type ShortPostToggleFields = {
  [key in keyof ShortPostFields]?: PostToggleFieldsItem;
};

interface ContentBlockFields {
  data: unknown;
  id: string;
  type: string;
}

export const ShortPost: FunctionComponent<ShortPostProps> = memo(
  ({
    loading,
    setLoading,
    shortPostFields,
    setShortPostFields,
    setShortPostGalleryItems,
    activeShortPostGalleryItemIndex,
    setActiveShortPostGalleryItemIndex,
    convertToLinkPost,
    settingsOpen,
    setSettingsOpen,
    activeShortPostSettingsMediaTab,
    setActiveShortPostSettingsTabIndex,
    files,
    fileUrl,
  }) => {
    const { t } = useTranslation();

    const { hasTags } = useTags();

    const { sourceName, headline, subHeadline, text, gallery } = shortPostFields;

    const hasGallery = useMemo(() => Boolean(gallery.length), [gallery.length]);

    const sourceNameRef = useRef<HTMLTextAreaElement>(null);
    const headlineRef = useRef<HTMLTextAreaElement>(null);
    const subHeadlineRef = useRef<HTMLTextAreaElement>(null);
    const textRef = useRef<HTMLTextAreaElement>(null);
    const editorRef = useRef<EditorJS | null>(null);

    // ! need to handle shortPostFields.text separately, because of editorJS rerender issue
    const [shortPostText, setShortPostText] = useState(text);

    const [contentBlocks, setContentBlocks] = useState<ContentBlockFields[]>(
      shortPostText.split('\n').map((text, index) => ({
        id: `paragraph-${index}`,
        type: 'paragraph',
        data: { text: markdownToHtml(text) },
      })) || []
    );

    useEffect(() => {
      setShortPostFields({ ...shortPostFields, text: shortPostText });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortPostText]);

    const [postFieldsToggling, setPostFieldsToggling] = useState<ShortPostToggleFields>({
      sourceName: {
        ref: sourceNameRef,
        title: t('addContent.short-post-sourceName'),
        iconId: '',
        hidden: Boolean(isEmptyString(sourceName)),
      },
      headline: {
        ref: headlineRef,
        title: t('addContent.short-post-headline'),
        iconId: '',
        hidden: Boolean(isEmptyString(headline)),
      },
      subHeadline: {
        ref: subHeadlineRef,
        title: t('addContent.short-post-subHeadline'),
        iconId: '',
        hidden: Boolean(isEmptyString(subHeadline)),
      },
    });

    useEffect(() => {
      if (textRef.current) {
        textRef.current.focus();
        textRef.current?.setSelectionRange(
          textRef.current?.value.length,
          textRef.current?.value.length
        );
      }
    }, []);

    const keyDownHandler = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>, field: keyof ShortPostFields) => {
        const { key, target } = event;

        if (key === 'Backspace' && !(target as HTMLTextAreaElement).value) {
          setPostFieldsToggling({
            ...postFieldsToggling,
            [field]: { ...postFieldsToggling[field], hidden: true },
          });
        }
      },
      [postFieldsToggling]
    );

    const fieldChangeHandler = useCallback(
      (name: keyof ShortPostFields, value: string) => {
        setShortPostFields({ ...shortPostFields, [name]: value });
      },
      [setShortPostFields, shortPostFields]
    );

    const contentBlocksChange = useCallback(async () => {
      if (!editorRef.current) {
        return;
      }

      const blocks = (await editorRef.current.save()).blocks as ContentBlockFields[];

      setShortPostText(blocks.map(({ data: { text } }: any) => htmlToMarkdown(text)).join('\n'));

      setContentBlocks(blocks);

      if (
        blocks.length !== 1 ||
        [sourceName.length, headline.length, subHeadline.length, hasGallery].some((value) =>
          Boolean(value)
        )
      ) {
        return;
      }

      const text: string = (blocks[0].data as any).text;

      const url =
        text
          .replace(/(\r\n|\n|\r)/gm, ' ')
          .split(' ')
          .find((word) => isUrl(word)) ?? '';

      const textWithoutUrl = text.replace(url, '');

      if (textWithoutUrl.length > 360) {
        return;
      }

      if (url) {
        convertToLinkPost(url, textWithoutUrl);
      }
    }, [convertToLinkPost, hasGallery, headline.length, sourceName.length, subHeadline.length]);

    const uploadLimit = useMemo(() => {
      const { image, audio, video, pdf } = { ...gallery[0] };

      switch (true) {
        case Boolean(audio):
        case Boolean(video):
        case Boolean(pdf):
          return 1;
        case Boolean(image):
          return 20;
      }
    }, [gallery]);

    const acceptTypes = useMemo(() => {
      return hasGallery ? [FileAcceptType.IMAGE] : undefined;
    }, [hasGallery]);

    const multiUpload = useMemo(
      () => Boolean(hasGallery && gallery.find(({ image }) => image)),
      [gallery, hasGallery]
    );

    const tagsClickHandler = useCallback(() => {
      setActiveShortPostSettingsTabIndex(CreateCardSettingsTabType.TAGS);
      setSettingsOpen(true);
    }, [setActiveShortPostSettingsTabIndex, setSettingsOpen]);

    return (
      <div className={classes['short-post']}>
        <div
          className={classNames(classes['short-post__scrollable'], {
            [classes['short-post__scrollable--mobile']]: isMobileOnly,
          })}
        >
          <TextAreaField
            ref={sourceNameRef}
            inputClassName={classes['short-post__sourceName']}
            value={sourceName}
            placeholder={t('addContent.short-post-sourceName')}
            maxLength={160}
            disabled={loading}
            onChange={({ target }) => fieldChangeHandler('sourceName', target.value)}
            onKeyDown={(event) => keyDownHandler(event, 'sourceName')}
            hidden={postFieldsToggling?.sourceName?.hidden}
            disableLineBreak
          />

          <TextAreaField
            ref={headlineRef}
            inputClassName={classes['short-post__headline']}
            value={headline}
            placeholder={t('addContent.short-post-headline')}
            maxLength={360}
            disabled={loading}
            onChange={({ target }) => fieldChangeHandler('headline', target.value)}
            onKeyDown={(event) => keyDownHandler(event, 'headline')}
            hidden={postFieldsToggling?.headline?.hidden}
          />

          <FileUpload
            galleryItems={gallery}
            setGalleryItems={setShortPostGalleryItems}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={acceptTypes}
            uploadLimit={uploadLimit}
            multiUpload={multiUpload}
            files={files}
            fileUrl={fileUrl}
          />

          <FilePreview
            galleryItems={gallery}
            setGalleryItems={setShortPostGalleryItems}
            activeGalleryItemIndex={activeShortPostGalleryItemIndex}
            setActiveGalleryItemIndex={setActiveShortPostGalleryItemIndex}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            activeMediaTab={activeShortPostSettingsMediaTab}
            setActiveMediaTab={(isActive) => {
              if (!isActive) {
                if (isAdminLayout()) {
                  setActiveShortPostSettingsTabIndex(CreateCardSettingsTabType.CARD);
                }
                return;
              }
              setActiveShortPostSettingsTabIndex(CreateCardSettingsTabType.MEDIA);
            }}
            loading={loading}
          />

          <TextAreaField
            ref={subHeadlineRef}
            inputClassName={classes['short-post__subHeadline']}
            value={subHeadline}
            placeholder={t('addContent.short-post-subHeadline')}
            maxLength={360}
            disabled={loading}
            onChange={({ target }) => fieldChangeHandler('subHeadline', target.value)}
            onKeyDown={(event) => keyDownHandler(event, 'subHeadline')}
            hidden={postFieldsToggling?.subHeadline?.hidden}
          />

          <RichTextEditor
            editorInstance={editorRef}
            contentBlocks={contentBlocks}
            onChange={contentBlocksChange}
            isTextField
          />
        </div>

        <div className={classes['short-post__pane']}>
          <div className={classes['short-post__pane-fields']}>
            {hasTags && (
              <IconLabel
                iconId={'tags'}
                iconSize={20}
                label={t('addContent.short-post-tags')}
                color={getCssVar('--create-card-link-color')}
                hoverColor={getCssVar('--create-card-link-hover-color')}
                onClick={tagsClickHandler}
              />
            )}
            <PostToggleFields
              loading={loading}
              fields={postFieldsToggling}
              setFields={setPostFieldsToggling}
            />
          </div>
        </div>
      </div>
    );
  }
);

import { useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { StoryCardStatus } from '../../services';
import { getNewsFeed, loadMoreNewsFeed } from '../../slices';
import { SEARCH_ADMIN_COOKIE_KEY } from '../constants';
import { isAdminLayout } from '../utils';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useLastDate } from './useLastDate';

export const useSearchFeed = () => {
  const dispatch = useAppDispatch();

  const { isLastDate, getLastDate } = useLastDate();

  const [cookies] = useCookies([SEARCH_ADMIN_COOKIE_KEY]);

  const [searchParams] = useSearchParams();

  const { page } = useAppSelector(({ newsFeed }) => newsFeed);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const {
    requestFilter = {
      authorId: [],
      storyId: [],
      dateRange: null,
      storyCardType: [],
      status: [],
    },
  } = {
    ...cookies[SEARCH_ADMIN_COOKIE_KEY],
  };

  const filter = useMemo(() => {
    if (!isAdminLayout()) {
      return { status: [StoryCardStatus.PUBLISHED] };
    }

    const { dateRange, ...requestFilterNoDateRange } = requestFilter; //TODO

    const date = isLastDate(dateRange) ? getLastDate(dateRange) : { ...dateRange };
    const from = date?.from ?? null;
    const to = date?.to ?? null;

    return {
      ...requestFilterNoDateRange,
      ...(from && { from }),
      ...(to && { to }),
    };
  }, [getLastDate, isLastDate, requestFilter]);

  const query = (searchParams.get('qs') ?? '').replace(/\\|"|'/g, '');

  const getSearchFeedItems = useCallback(() => {
    dispatch(getNewsFeed({ channelId, filter: { ...filter, query } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, dispatch, query]);

  const loadMoreSearchFeedItems = useCallback(() => {
    dispatch(loadMoreNewsFeed({ channelId, page: page + 1, filter: { ...filter, query } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, dispatch, page, query]);

  return {
    getSearchFeedItems,
    loadMoreSearchFeedItems,
  };
};

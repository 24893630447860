import { FunctionComponent, memo } from 'react';
import { StoryCardPostContent } from '../../../services';
import { FeedCardProps } from '../../../shared';
import { FeedCardPreviewContent } from '../FeedCardPreviewContent';
import { FeedCardPreviewWrapper } from '../FeedCardPreviewWrapper';

export const FeedCardPreviewPost: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { type, content } = card;
  const { title, sourceName, abstract } = content as StoryCardPostContent;

  return (
    <FeedCardPreviewWrapper card={card}>
      <FeedCardPreviewContent
        cardType={type}
        source={sourceName}
        title={title}
        text={abstract}
      ></FeedCardPreviewContent>
    </FeedCardPreviewWrapper>
  );
});

import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useEffect } from 'react';
import { getDateFnsLocale } from '../../utils';
import { Badge } from '../Badge';
import { IconLabelHintDirection } from '../IconLabel';
import { ScheduleIcon } from '../ScheduleIcon';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import classNames from 'classnames';
import classes from './ScheduleInput.module.scss';

interface ScheduleInputProps {
  postingTime: Date | null;
  setPostingTime: (value: Date | null) => void;
  title?: string;
  subTitle?: string;
}

export const ScheduleInput: FunctionComponent<ScheduleInputProps> = memo(
  ({ postingTime, setPostingTime, title, subTitle }) => {
    const onChangeHandler = useCallback(
      (date: Date | null) => {
        if (date && !postingTime) {
          date.setHours(new Date().getHours(), new Date().getMinutes() + 1);
          setPostingTime(date);
          return;
        }

        if (date && new Date().getTime() > new Date(date).getTime()) {
          date.setTime(new Date().getTime() + 60 * 1000);
          setPostingTime(date);
          return;
        }

        setPostingTime(date);
      },
      [postingTime, setPostingTime]
    );

    const filterTimeHandler = useCallback(
      (date: Date) => {
        return Boolean(postingTime && new Date().getTime() < new Date(date).getTime());
      },
      [postingTime]
    );

    useEffect(() => {
      if (postingTime) {
        setTimeout(() => {
          document
            .querySelector('#schedule-input .react-datepicker__time-list-item--selected')
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
      }
    }, [postingTime]);

    return (
      <DatePicker
        minDate={new Date()}
        selected={postingTime}
        onChange={onChangeHandler}
        filterTime={filterTimeHandler}
        locale={getDateFnsLocale()}
        showTimeSelect
        timeIntervals={1}
        withPortal
        portalId={'modal-datepicker-root'}
        calendarContainer={({ className, children }) => (
          <div id={'schedule-input'} className={classNames(className, classes['schedule-input'])}>
            {title && <div className={classes['schedule-input__title']}>{title}</div>}

            {subTitle && <div className={classes['schedule-input__subTitle']}>{subTitle}</div>}

            <div className={classes['schedule-input__picker']}>{children}</div>

            {postingTime && (
              <Badge
                value={format(parseISO(postingTime.toISOString()), 'dd.MM.yy, HH:mm')}
                onRemove={() => setPostingTime(null)}
              />
            )}
          </div>
        )}
        customInput={
          <div className={classes['schedule-input__icon']}>
            <ScheduleIcon postingTime={postingTime} hintDirection={IconLabelHintDirection.top} />
          </div>
        }
      />
    );
  }
);

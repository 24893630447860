import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedAdminEditorial } from './HorizontalStoryFeedAdminEditorial';
import { HorizontalStoryFeedAdminMedia } from './HorizontalStoryFeedAdminMedia';
import { HorizontalStoryFeedAdminThread } from './HorizontalStoryFeedAdminThread';
import { HorizontalStoryFeedAdminArticle } from './HorizontalStoryFeedAdminArticle';
import { HorizontalStoryFeedAdminPost } from './HorizontalStoryFeedAdminPost';
import { HorizontalStoryFeedAdminSocial } from './HorizontalStoryFeedAdminSocial';
import { StoryCardType } from '../../../services';
import { HorizontalStoryFeedCardProps } from '../../../shared';

export const HorizontalStoryFeedAdminCard: FunctionComponent<HorizontalStoryFeedCardProps> = memo(
  ({ card }) => {
    switch (card.type) {
      case StoryCardType.IMAGE:
      case StoryCardType.AUDIO:
      case StoryCardType.VIDEO:
      case StoryCardType.PDF:
        return <HorizontalStoryFeedAdminMedia card={card} />;
      case StoryCardType.THREAD:
        return <HorizontalStoryFeedAdminThread card={card} />;
      case StoryCardType.EDITORIAL:
        return <HorizontalStoryFeedAdminEditorial card={card} />;
      case StoryCardType.QUOTE:
        return <HorizontalStoryFeedAdminSocial card={card} />;
      case StoryCardType.ARTICLE:
        return <HorizontalStoryFeedAdminArticle card={card} />;
      case StoryCardType.POST:
        return <HorizontalStoryFeedAdminPost card={card} />;
      default:
        return null;
    }
  }
);

import { FunctionComponent, memo } from 'react';
import { AnalyticChartPlatform } from '../../../services';
import { ChartType } from '../Analytics';
import { useChartExclude } from '../helpers';
import { UserActive } from './UserActive';
import { UserActivity } from './UserActivity';
import { UserDemoActivity } from './UserDemoActivity';
import { UserDemoFunnel } from './UserDemoFunnel';
import { UserEngagement } from './UserEngagement';
import { UserFunnel } from './UserFunnel';
import { UserFunnelV2 } from './UserFunnelV2';
import { UserFunnelV3 } from './UserFunnelV3';
import { UserInteraction } from './UserInteraction';
import { UserRoleContent } from './UserRoleContent';
import { UserSessionByDay } from './UserSessionByDay';
import { UserSessionByHour } from './UserSessionByHour';
import { UserSessionPerDay } from './UserSessionPerDay';

export interface UserProps {
  from: string;
  to: string;
  platform?: AnalyticChartPlatform[];
}

export const User: FunctionComponent<UserProps> = memo((userProps) => {
  const excludeUserActivity = useChartExclude(ChartType.ACTIVE_VS_ENGAGED_USERS);
  const excludeUserDemoActivity = useChartExclude(ChartType.ACTIVE_VS_ENGAGED_DEMO_USERS);
  const excludeUserFunnel = useChartExclude(ChartType.USER_FUNNEL);
  const excludeUserFunnelV2 = useChartExclude(ChartType.USER_FUNNEL_V2);
  const excludeUserFunnelV3 = useChartExclude(ChartType.USER_FUNNEL_V3);
  const excludeUserDemoFunnel = useChartExclude(ChartType.USER_DEMO_FUNNEL);
  const excludeUserInteraction = useChartExclude(ChartType.USER_INTERACTIONS);
  const excludeUserRoleContent = useChartExclude(ChartType.USER_ROLE_CONTENT);
  const excludeUserEngagement = useChartExclude(ChartType.SESSIONS_DURATION);
  const excludeUserSessionPerDay = useChartExclude(ChartType.SESSIONS_PER_DAY);
  const excludeUserSessionByDay = useChartExclude(ChartType.SESSIONS_BY_DAY_OF_WEEK);
  const excludeUserSessionByHour = useChartExclude(ChartType.SESSIONS_BY_HOUR_OF_DAY);
  const excludeUserActive = useChartExclude(ChartType.ACTIVE_USERS_PER_MONTH);

  return (
    <>
      {!excludeUserActivity && <UserActivity {...userProps} />}
      {!excludeUserDemoActivity && <UserDemoActivity {...userProps} />}
      {!excludeUserFunnel && <UserFunnel {...userProps} />}
      {!excludeUserFunnelV2 && <UserFunnelV2 {...userProps} />}
      {!excludeUserFunnelV3 && <UserFunnelV3 {...userProps} />}
      {!excludeUserDemoFunnel && <UserDemoFunnel {...userProps} />}
      {!excludeUserInteraction && <UserInteraction {...userProps} />}
      {!excludeUserRoleContent && <UserRoleContent {...userProps} />}
      {!excludeUserEngagement && <UserEngagement {...userProps} />}
      {!excludeUserSessionPerDay && <UserSessionPerDay {...userProps} />}
      {!excludeUserSessionByDay && <UserSessionByDay {...userProps} />}
      {!excludeUserSessionByHour && <UserSessionByHour {...userProps} />}
      {!excludeUserActive && <UserActive />}
    </>
  );
});

import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  StoryCard,
  StoryCardContentGallery,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../../services';
import {
  COLLAPSE_MODE_STORAGE_KEY,
  COPY_CUT_COOKIE_KEY,
  CardActionsAdmin,
  CollapseMode,
  CopyCutAction,
  FeedCardCut,
  GalleryItem,
  IconLabel,
  getCssVar,
  getStoryCardPreviewUrl,
  useCardDate,
} from '../../../../shared';
import { DetailsModalAdmin } from '../../../CardDetails';

import classNames from 'classnames';
import classes from './HorizontalStoryFeedAdminCardWrapper.module.scss';

interface HorizontalStoryAdminFeedCardWrapperProps {
  card: StoryCard;
}

export const HorizontalStoryFeedAdminCardWrapper: FunctionComponent<
  PropsWithChildren<HorizontalStoryAdminFeedCardWrapperProps>
> = memo(({ card, children }) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _, removeCookie] = useCookies([COPY_CUT_COOKIE_KEY]);

  const { cardId: copyCutCardId = 0, action: copyCutAction = '' } = {
    ...cookies[COPY_CUT_COOKIE_KEY],
  };

  const [collapseMode, setCollapseMode] = useState(
    localStorage.getItem(COLLAPSE_MODE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  useEffect(() => {
    window.addEventListener('storage', () => {
      setCollapseMode(localStorage.getItem(COLLAPSE_MODE_STORAGE_KEY) || CollapseMode.STANDARD);
    });
  }, []);

  const isCollapsed = useMemo(() => collapseMode === CollapseMode.COLLAPSED, [collapseMode]);

  const { id: cardId, storyId, type, dateToDisplay, content, externalTags } = card;

  const date = useCardDate(dateToDisplay);

  const { gallery } = content as StoryCardContentGallery;

  const { quoteSource } = content as StoryCardQuoteContent;

  const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

  const [scrollToTags, setScrollToTags] = useState(false);

  const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

  const previewUrl = getStoryCardPreviewUrl({ type, galleryItem });

  const isImageGallery = useMemo(
    () =>
      [StoryCardType.IMAGE, StoryCardType.QUOTE].includes(type) && gallery && gallery.length > 1,
    [gallery, type]
  );

  const shouldRenderOverlay = useMemo(
    () =>
      isImageGallery ||
      (previewUrl && [StoryCardType.VIDEO, StoryCardType.AUDIO, StoryCardType.PDF].includes(type)),
    [isImageGallery, previewUrl, type]
  );

  const isCutCard = useMemo(
    () => copyCutAction === CopyCutAction.CUT && cardId === copyCutCardId,
    [cardId, copyCutAction, copyCutCardId]
  );

  const cardClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      isMobileOnly
        ? navigate(`/admin/details/${storyId}/${cardId}`)
        : setOpenCardDetailsModal(true);
    },
    [cardId, navigate, storyId]
  );

  useEffect(() => {
    if (!scrollToTags) {
      return;
    }

    isMobileOnly
      ? navigate(`/details/${storyId}/${cardId}?scrollToTags=true`)
      : setOpenCardDetailsModal(true);
  }, [cardId, navigate, scrollToTags, storyId]);

  const iconId = useMemo(() => {
    switch (type) {
      case StoryCardType.ARTICLE:
      case StoryCardType.POST:
        return 'article';
      case StoryCardType.IMAGE:
        return isImageGallery ? 'gallery' : type.toLowerCase();
      case StoryCardType.QUOTE:
        return isImageGallery ? 'gallery' : quoteSource?.toLowerCase();
      default:
        return type.toLowerCase();
    }
  }, [isImageGallery, quoteSource, type]);

  const iconColor = useMemo(
    () => (!previewUrl ? getCssVar('--card-overlay-icon-color') : '#ffffff'),
    [previewUrl]
  );

  if (isCutCard) {
    return <FeedCardCut isCollapsed={isCollapsed} isHorizontalFeedCard />;
  }

  return (
    <>
      <div
        className={classNames(`card-${cardId}`, classes['card'], {
          [classes['card--collapsed']]: isCollapsed,
        })}
        onClick={cardClickHandler}
      >
        <div
          className={classNames(classes['card__teaser'], {
            [classes['card__teaser--placeholder']]: !previewUrl,
            [classes['card__teaser--overlay']]: shouldRenderOverlay,
          })}
          {...(previewUrl && { style: { backgroundImage: `url(${previewUrl})` } })}
        >
          {(!previewUrl || shouldRenderOverlay) && (
            <IconLabel
              className={classes['card__teaser-icon']}
              iconId={iconId}
              iconSize={32}
              color={iconColor}
              singleColor
            />
          )}
        </div>
        <div
          className={classNames(classes['card__content'], {
            [classes['card__content--collapsed']]: isCollapsed,
          })}
        >
          {!isCollapsed && children}
          {date && <div className={classes['card__posted-time']}>{date}</div>}
        </div>
      </div>

      {Boolean(externalTags.length) && (
        <IconLabel
          className={classes['card__tags']}
          iconSize={18}
          iconId={'tags'}
          color={getCssVar('--action-pane-icon-color')}
          hoverColor={getCssVar('--action-pane-icon-hover-color')}
          onClick={() => setScrollToTags(true)}
        />
      )}

      <CardActionsAdmin card={card} isHorizontalFeedCard />

      {openCardDetailsModal && (
        <DetailsModalAdmin
          isOpen={openCardDetailsModal}
          storyId={storyId}
          cardId={cardId}
          scrollToTags={scrollToTags}
          onClose={() => {
            setOpenCardDetailsModal(false);
            setScrollToTags(false);
          }}
        />
      )}
    </>
  );
});

import { isIE, isOpera } from 'react-device-detect';
import { BrowserRouterProps, createBrowserRouter } from 'react-router-dom';
import { PUBLIC_URL } from '../env';
import {
  Analytics,
  CardDetails,
  CheckEmail,
  EditProfile,
  Forgot,
  ForgotSet,
  FrontDoorLinks,
  Login,
  NewsFeed,
  NewsFeedAdmin,
  NewsFeedAdminRightSide,
  NotFound,
  NotSupported,
  Notifications,
  NotificationsRightSide,
  OrgSettings,
  PinnedStoryFeedItems,
  ProfileFeedItems,
  ProfileMe,
  ProfileMeAdmin,
  ProfileOther,
  ProfileOtherAdmin,
  ProfileRightSide,
  PushNotifications,
  SearchFeed,
  SearchFeedAdmin,
  SearchFeedAdminRightSide,
  Signup,
  StoryFeedItems,
  StoryFeedItemsAdmin,
  StoryFeedItemsAdminRightSide,
  StoryTabList,
  StoryTabListAdmin,
  StoryTabListAdminRightSide,
  StoryTabType,
  TagsFeed,
  TagsFeedAdmin,
  TagsFeedAdminRightSide,
  UnhandledError,
  UserDirectory,
  UserDirectoryRightSide,
} from '../pages';

import { DetailsContent, DetailsContentAdmin, RightSide } from '../components';
import { AdminLayout, FormLayout, ReaderLayout } from '../layouts';
import { NoAccess } from '../pages/Error/NoAccess';

const browserProps: BrowserRouterProps = {
  basename: PUBLIC_URL,
};

const isDeprecatedBrowser = isIE || isOpera;

// * READER LAYOUT
const withRightSideReader = {
  path: '/',
  element: <ReaderLayout rightSideModule={<RightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/',
      element: <NewsFeed />,
    },
    {
      path: '/storyTab1',
      element: <StoryTabList tab={StoryTabType.tab1} />,
    },
    {
      path: '/storyTab2',
      element: <StoryTabList tab={StoryTabType.tab2} />,
    },
    {
      path: '/:storyTab/:storyId/items',
      element: <StoryFeedItems />,
    },
    {
      path: '/pinnedStoryTab/:storyId/items',
      element: <PinnedStoryFeedItems />,
    },
    {
      path: '/frontDoorLinksTab',
      element: <FrontDoorLinks />,
    },
    {
      path: '/tags/:organisationTagId',
      element: <TagsFeed />,
    },
  ],
};

const withoutRightSideReader = {
  path: '/',
  element: <ReaderLayout />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/search',
      element: <SearchFeed />,
    },
    {
      path: '/details/:storyId/:cardId',
      element: <CardDetails ContentComponent={DetailsContent} />,
    },
    {
      path: '/profile/edit',
      element: <EditProfile />,
    },
    {
      path: '/pushNotifications',
      element: <PushNotifications />,
    },
  ],
};

const fullWidthContentReader = {
  path: '/',
  element: <ReaderLayout fullWidthContent />,
  errorElement: <UnhandledError />,
  children: [
    // {
    //   path: '/chats',
    //   element: <Chats />,
    // },
  ],
};

const profileReader = {
  path: '/',
  element: <ReaderLayout rightSideModule={<ProfileRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/profile',
      element: <ProfileMe />,
    },
    {
      path: '/profile/:id',
      element: <ProfileOther />,
    },
    {
      path: '/profile/items',
      element: <ProfileFeedItems />,
    },
    {
      path: '/profile/:id/items',
      element: <ProfileFeedItems />,
    },
  ],
};

const userDirectoryReader = {
  path: '/',
  element: <ReaderLayout rightSideModule={<UserDirectoryRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/userDirectory',
      element: <UserDirectory />,
    },
  ],
};

// * ADMIN LAYOUT
const multiFeedsAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<NewsFeedAdminRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin',
      element: <NewsFeedAdmin />,
    },
  ],
};

const storyTabAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<StoryTabListAdminRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/storyTab',
      element: <StoryTabListAdmin />,
    },
  ],
};

const storyFeedItemsAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<StoryFeedItemsAdminRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/storyTab/:storyId/items',
      element: <StoryFeedItemsAdmin />,
    },
  ],
};

const tagsFeedAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<TagsFeedAdminRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/tags/:organisationTagId',
      element: <TagsFeedAdmin />,
    },
  ],
};

const profileAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<ProfileRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/profile',
      element: <ProfileMeAdmin />,
    },
    {
      path: '/admin/profile/:id',
      element: <ProfileOtherAdmin />,
    },
    {
      path: '/admin/profile/items',
      element: <ProfileFeedItems />,
    },
    {
      path: '/admin/profile/:id/items',
      element: <ProfileFeedItems />,
    },
  ],
};

const searchAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<SearchFeedAdminRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/search',
      element: <SearchFeedAdmin />,
    },
  ],
};

const notificationsAdmin = {
  path: '/',
  element: <AdminLayout rightSideModule={<NotificationsRightSide />} />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/notifications',
      element: <Notifications />,
    },
  ],
};

const withoutRightSideAdmin = {
  path: '/',
  element: <AdminLayout />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/admin/details/:storyId/:cardId',
      element: <CardDetails ContentComponent={DetailsContentAdmin} />,
    },
    {
      path: 'admin/profile/edit',
      element: <EditProfile />,
    },
    {
      path: 'admin/pushNotifications',
      element: <PushNotifications />,
    },
    {
      path: 'admin/orgSettings',
      element: <OrgSettings />,
    },
  ],
};

const fullWidthContentAdmin = {
  path: '/',
  element: <AdminLayout fullWidthContent />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: 'admin/analytics',
      element: <Analytics />,
    },
    // {
    //   path: 'admin/chats',
    //   element: <Chats />,
    // },
  ],
};

// * FORM LAYOUT
const auth = {
  path: '/',
  element: <FormLayout />,
  errorElement: <UnhandledError />,
  children: [
    {
      path: '/auth/login',
      element: <Login />,
    },
    {
      path: '/auth/forgotPasswordSet',
      element: <ForgotSet />,
    },
    {
      path: '/auth/signup',
      element: <Signup />,
    },
    {
      path: '/auth/forgot',
      element: <Forgot />,
    },
    {
      path: '/auth/checkEmail',
      element: <CheckEmail />,
    },
  ],
};

const noAccess = {
  path: '/',
  element: <FormLayout />,
  children: [
    {
      path: '/noAccess',
      element: <NoAccess />,
    },
  ],
};

const notSupported = {
  path: '*',
  element: <FormLayout />,
  children: [
    {
      path: '*',
      element: <NotSupported />,
    },
  ],
};

const notFound = {
  path: '*',
  element: <FormLayout />,
  children: [
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export const router = createBrowserRouter(
  isDeprecatedBrowser
    ? [notSupported]
    : [
        // * READER LAYOUT
        withRightSideReader,
        withoutRightSideReader,
        fullWidthContentReader,
        profileReader,
        userDirectoryReader,
        // * ADMIN LAYOUT
        multiFeedsAdmin,
        storyTabAdmin,
        storyFeedItemsAdmin,
        tagsFeedAdmin,
        // commentsAdmin,
        withoutRightSideAdmin,
        fullWidthContentAdmin,
        profileAdmin,
        searchAdmin,
        notificationsAdmin,
        // * FORM LAYOUT
        auth,
        notFound,
        noAccess,
      ],
  browserProps
);

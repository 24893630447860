import { ResponsiveBar } from '@nivo/bar';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalyticChartInterval,
  AnalyticChartIntervalResponse,
  useUserSessionQuery,
} from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, getBarsLayer } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserSessionPerDay: FunctionComponent<UserProps> = memo((userProps) => {
  const { t } = useTranslation();

  const { data: userSessionPerDay, isFetching: isFetchingUserSessionPerDay } = useUserSessionQuery({
    ...userProps,
    interval: AnalyticChartInterval.AUTO,
  });

  const formatter = useCallback(
    ({ value, interval }: { value: string; interval: AnalyticChartIntervalResponse | null }) => {
      return format(
        parseISO(value),
        interval === AnalyticChartIntervalResponse.HOUR ? 'HH:mm' : 'dd.MM'
      );
    },
    []
  );

  const renderChart = useMemo(() => {
    if (isFetchingUserSessionPerDay) {
      return <Loader />;
    }

    if (!userSessionPerDay) {
      return null;
    }

    const { datasets, intervals, interval } = userSessionPerDay;

    if (!datasets.length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const values = datasets[0].data;

    const data = values.map((value, index) => {
      return { interval: intervals[index], value };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsiveBar
          margin={{ top: 40, right: 40, bottom: 50, left: 40 }}
          padding={0.5}
          borderRadius={4}
          tooltip={() => <></>}
          data={data}
          keys={['value']}
          indexBy={'interval'}
          colors={[COLORS[0]]}
          axisLeft={{ format: (value) => (value === Math.floor(value) ? value : '') }}
          axisBottom={{ format: (value) => formatter({ value, interval }), tickRotation: -45 }}
          label={''}
          layers={['grid', 'axes', 'bars', ({ bars }) => getBarsLayer({ bars })]}
        />
      </div>
    );
  }, [formatter, isFetchingUserSessionPerDay, t, userSessionPerDay]);
  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-session-per-day'}
      infoLocaliseKey={'userSessionPerDay.info'}
    >
      {renderChart}
    </ChartWrapper>
  );
});

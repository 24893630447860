import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from '../../../shared';
import { ErrorLayout } from '../ErrorLayout';
import classes from './NoAccess.module.scss';

import { useNavigate } from 'react-router-dom';

export const NoAccess: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <ErrorLayout subtitle={t('noAccess.title')}>
      <div className={classes['no-access']}>
        <div className={classes['no-access__message']}>{t('noAccess.message')}</div>
      </div>
      <Button
        type={ButtonType.primary}
        label={t('common.back-home')}
        onClick={() => navigate('/')}
      />
    </ErrorLayout>
  );
};

import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardThreadContent } from '../../../services';
import { FeedCardProps } from '../../../shared';
import { FeedCardPreviewContent } from '../FeedCardPreviewContent';
import { FeedCardPreviewWrapper } from '../FeedCardPreviewWrapper';

export const FeedCardPreviewThread: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { t } = useTranslation();

  const { title } = card.content as StoryCardThreadContent;

  return (
    <FeedCardPreviewWrapper card={card}>
      <FeedCardPreviewContent
        source={t('common.discussion')}
        title={title}
      ></FeedCardPreviewContent>
    </FeedCardPreviewWrapper>
  );
});

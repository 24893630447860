import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { StoryCardType } from '../../../../services';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { LineClamp, LineClampProps } from '../../LineClamp';

import classes from './HorizontalStoryFeedContentCommon.module.scss';

interface HorizontalStoryFeedContentCommonProps {
  pinned: boolean;
  cardType?: StoryCardType;
  source?: string;
  title?: string;
  subTitle?: string;
  text?: string;
  htmlText?: string;
}

export const HorizontalStoryFeedContentCommon: FunctionComponent<HorizontalStoryFeedContentCommonProps> =
  memo(({ pinned, cardType, source, title, subTitle, text, htmlText }) => {
    const lineClampCommonProps = useMemo(() => {
      return {
        maxLines: 3,
        readMore: '...',
        readMoreClassName: classes['horizontal-card-content__read-more'],
      } as LineClampProps;
    }, []);

    const getIcon = useCallback(
      (className?: string) => {
        if (![StoryCardType.ARTICLE, StoryCardType.POST].includes(cardType as StoryCardType)) {
          return;
        }

        return (
          <IconLabel
            className={className}
            iconId={
              cardType === StoryCardType.ARTICLE ? 'arrow-rounded-right-up' : 'arrow-rounded-right'
            }
            iconSize={14}
            color={getCssVar('--card-content-headline-icon-color')}
            singleColor
          />
        );
      },
      [cardType]
    );

    return (
      <div className={classes['horizontal-card-content']}>
        {(source || pinned) && (
          <div className={classes['horizontal-card-content__source-wrapper']}>
            {pinned && (
              <IconLabel
                iconSize={14}
                iconId={'pin'}
                {...(source && { label: '•' })}
                color={getCssVar('--base-link-text-color')}
                singleColor
                nonClickable
              />
            )}
            {source && <div className={classes['horizontal-card-content__source']}>{source}</div>}
          </div>
        )}
        {title && (
          <LineClamp
            {...lineClampCommonProps}
            maxLines={2}
            stringContent={title}
            stringContentIcon={getIcon(classes['horizontal-card-content__title-icon'])}
            readMore={<>...{getIcon(classes['horizontal-card-content__read-more-icon'])}</>}
            contentClassName={classes['horizontal-card-content__title']}
          />
        )}
        {subTitle && (
          <LineClamp
            {...lineClampCommonProps}
            stringContent={subTitle}
            contentClassName={classes['horizontal-card-content__subTitle']}
          />
        )}
        {!subTitle && !htmlText && text && (
          <LineClamp
            {...lineClampCommonProps}
            stringContent={text}
            contentClassName={classes['horizontal-card-content__text']}
            readMoreClassName={classes['horizontal-card-content__read-more-text']}
          />
        )}
        {!subTitle && htmlText && (
          <LineClamp
            {...lineClampCommonProps}
            htmlStringContent={htmlText}
            contentClassName={classes['horizontal-card-content__text']}
            readMoreClassName={classes['horizontal-card-content__read-more-text']}
          />
        )}
      </div>
    );
  });

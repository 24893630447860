import { Margin } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticChartIntervalResponse, ChartTimeSeries } from '../../../../../services';
import { COLORS } from '../../../Analytics';
import { Loader } from '../Loader';
import { SliceTooltip } from '../SliceTooltip';

import classes from '../../../Analytics.module.scss';

interface TimeSeriesChartProps {
  timeSeries: ChartTimeSeries;
  labels: string[];
  localiseKeyLabels: string;
  loading: boolean;
  valueFormatter?: (value: number) => string;
  margin?: Partial<Margin>;
}

export const TimeSeriesChart: FunctionComponent<TimeSeriesChartProps> = memo(
  ({ timeSeries, labels, localiseKeyLabels, loading, valueFormatter, margin }) => {
    const { t } = useTranslation();

    const renderChart = useMemo(() => {
      if (loading) {
        return <Loader />;
      }

      if (!timeSeries) {
        return null;
      }

      const { datasets, intervals, interval } = timeSeries;

      const intervalFormat = interval === AnalyticChartIntervalResponse.HOUR ? 'HH:mm' : 'dd.MM';

      const data: Serie[] = datasets
        .filter(({ label }) => labels.includes(label))
        .map(({ data, label }) => {
          return {
            id: t(`${localiseKeyLabels}.${label}`),
            data: data.map((value, index) => {
              return { x: intervals[index], y: value ?? 0 };
            }),
          };
        });

      const datasetLabels = datasets.map(({ label }) => label);

      const labelDiffIndexes: number[] = [];
      for (let i = 0; i < datasetLabels.length; i++) {
        if (!labels.includes(datasetLabels[i])) {
          labelDiffIndexes.push(i);
        }
      }

      return (
        <div className={classes['analytics__chart-height']}>
          <ResponsiveLine
            margin={margin ?? { top: 20, right: 40, bottom: 50, left: 40 }}
            data={data}
            colors={COLORS.filter((_, index) => !labelDiffIndexes.includes(index))}
            enableSlices={'x'}
            axisLeft={{
              format: (value) =>
                valueFormatter ? valueFormatter(value) : value === Math.floor(value) ? value : '',
            }}
            axisBottom={{
              format: (value) => format(parseISO(value), intervalFormat),
              tickRotation: -45,
            }}
            sliceTooltip={(sliceTooltipProps) => (
              <SliceTooltip {...sliceTooltipProps} valueFormatter={valueFormatter} />
            )}
          />
        </div>
      );
    }, [labels, loading, localiseKeyLabels, margin, t, timeSeries, valueFormatter]);

    return renderChart;
  }
);

import { Comment, StoryCard } from '../../services';

interface AddCommentProps {
  card: StoryCard;
  comment: Comment;
}

export const addCommentReducer = ({ card, comment }: AddCommentProps) => {
  const { comments } = card;
  const { parentId } = comment;

  if (parentId) {
    const parentComment = comments.find(({ id }) => id === parentId);

    if (parentComment) {
      parentComment.replies.push(comment);
      parentComment.repliesCount++;
    }
  } else {
    comments.unshift(comment);
    card.commentsRootCount++;
  }

  card.commentsCount++;
};

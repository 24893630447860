import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import {
  organisationApi,
  OrganisationTagsFiltersArgs,
  OrganisationTagType,
  storyCardApi,
  StoryCardsOrganisationTagFeedFilters,
} from '../../../services';
import {
  CookieActionType,
  cookieOptions,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  TAGS_FEED_ADMIN_COOKIE_KEY,
  useAppDispatch,
  useAuthorsFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
  useTagsFilter,
} from '../../../shared';
import { getTagsFeed } from '../../../slices';

import classes from './TagsFeedAdminRightSide.module.scss';

export const TagsFeedAdminRightSide: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const organisationTagId = Number(useParams().organisationTagId);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [fetchChannelAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByChannelId.useLazyQuery();

  const [organisationTags] = organisationApi.endpoints.organisationTags.useLazyQuery();

  const [cookies, setCookie] = useCookies([TAGS_FEED_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[TAGS_FEED_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue, getDateRangeValue } = useFilter();

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter(filter, fetchChannelAuthors, { channelId });

  const AUTHORS = useMemo(() => authorsGroup.id, [authorsGroup.id]);

  const organisationTagsQuery = useCallback(
    ({
      query,
      filters,
      page,
    }: {
      query: string;
      filters: OrganisationTagsFiltersArgs;
      page?: number;
    }) => organisationTags({ page, filters: { ...filters, query } }),
    [organisationTags]
  );

  const { group: internalTagsGroup } = useTagsFilter(filter, organisationTagsQuery, {
    filters: { types: [OrganisationTagType.INTERNAL] },
  });

  const INTERNAL_TAGS = useMemo(() => internalTagsGroup.id, [internalTagsGroup.id]);

  const { group: externalTagsGroup } = useTagsFilter(filter, organisationTagsQuery, {
    filters: { types: [OrganisationTagType.EXTERNAL] },
  });

  const EXTERNAL_TAGS = useMemo(() => externalTagsGroup.id, [externalTagsGroup.id]);

  const { group: cardTypeGroup } = useCardTypeFilter();

  const TYPE = useMemo(() => cardTypeGroup.id, [cardTypeGroup.id]);

  const { group: cardStatusGroup } = useCardStatusFilter();

  const STATUS = useMemo(() => cardStatusGroup.id, [cardStatusGroup.id]);

  const groups: FilterGroup[] = [
    internalTagsGroup,
    externalTagsGroup,
    authorsGroup,
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const internalTagsId = getGroupValue({
        filter,
        groupId: INTERNAL_TAGS,
        multiCheck: true,
        propToMap: 'id',
      });

      const externalTagsId = getGroupValue({
        filter,
        groupId: EXTERNAL_TAGS,
        multiCheck: true,
        propToMap: 'id',
      });

      const authorId = getGroupValue({
        filter,
        groupId: AUTHORS,
        multiCheck: true,
        propToMap: 'id',
      });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: TYPE, multiCheck: true });

      const status = getGroupValue({ filter, groupId: STATUS, multiCheck: true });

      const requestFilterToSave: StoryCardsOrganisationTagFeedFilters = {
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(authorId?.length && { authorId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const filters: StoryCardsOrganisationTagFeedFilters = {
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(authorId?.length && { authorId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        TAGS_FEED_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions({ action: CookieActionType.SET })
      );

      setFilter(filter);

      dispatch(getTagsFeed({ channelId, organisationTagId, filters }));
    },
    [
      AUTHORS,
      EXTERNAL_TAGS,
      INTERNAL_TAGS,
      STATUS,
      TYPE,
      channelId,
      dispatch,
      getDateRangeValue,
      getGroupValue,
      organisationTagId,
      setCookie,
    ]
  );

  return (
    <div className={classes['tags-feed-admin-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};

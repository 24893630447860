import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  ChannelAppTabAction,
  OrganisationTagType,
  OrganisationTagsFiltersArgs,
  StoryCardSection,
  StoryCardsFeedFilter,
  organisationApi,
  storyCardApi,
} from '../../../services';
import {
  CookieActionType,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  STORY_TAB_ITEMS_ADMIN_COOKIE_KEY,
  cookieOptions,
  optionalArrayItem,
  useAppDispatch,
  useAppTabsLabel,
  useAuthorsFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
  useTags,
  useTagsFilter,
} from '../../../shared';
import { getStoryFeed } from '../../../slices';

import classes from './StoryFeedItemsAdminRightSide.module.scss';

const SECTIONS = 'sections';

export const StoryFeedItemsAdminRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const { storyId } = useParams();

  const dispatch = useAppDispatch();

  const { getAppTabsLabel } = useAppTabsLabel();

  const { hasInternalTags, hasExternalTags } = useTags();

  const [cookies, setCookie] = useCookies([STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]);

  const [fetchStoryAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByStoryId.useLazyQuery();

  const [organisationTags] = organisationApi.endpoints.organisationTags.useLazyQuery();

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue, getDateRangeValue } = useFilter();

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter(filter, fetchStoryAuthors, { storyId });

  const AUTHORS = useMemo(() => authorsGroup.id, [authorsGroup.id]);

  const organisationTagsQuery = useCallback(
    ({
      query,
      filters,
      page,
    }: {
      query: string;
      filters: OrganisationTagsFiltersArgs;
      page?: number;
    }) => organisationTags({ page, filters: { ...filters, query } }),
    [organisationTags]
  );

  const { group: internalTagsGroup } = useTagsFilter(filter, organisationTagsQuery, {
    filters: { types: [OrganisationTagType.INTERNAL] },
  });

  const INTERNAL_TAGS = useMemo(() => internalTagsGroup.id, [internalTagsGroup.id]);

  const { group: externalTagsGroup } = useTagsFilter(filter, organisationTagsQuery, {
    filters: { types: [OrganisationTagType.EXTERNAL] },
  });

  const EXTERNAL_TAGS = useMemo(() => externalTagsGroup.id, [externalTagsGroup.id]);

  const { group: cardTypeGroup } = useCardTypeFilter();

  const TYPE = useMemo(() => cardTypeGroup.id, [cardTypeGroup.id]);

  const { group: cardStatusGroup } = useCardStatusFilter();

  const STATUS = useMemo(() => cardStatusGroup.id, [cardStatusGroup.id]);

  const groups: FilterGroup[] = [
    {
      id: SECTIONS,
      title: t(`common.${SECTIONS}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryCardSection.WEB_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.web')}`,
          value: StoryCardSection.WEB_FEED,
        },
        {
          id: StoryCardSection.MOBILE_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.mobile')}`,
          value: StoryCardSection.MOBILE_FEED,
        },
      ],
    },
    ...optionalArrayItem(hasInternalTags, internalTagsGroup),
    ...optionalArrayItem(hasExternalTags, externalTagsGroup),
    authorsGroup,
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      if (!storyId) {
        return;
      }

      const authorId = getGroupValue({
        filter,
        groupId: AUTHORS,
        multiCheck: true,
        propToMap: 'id',
      });

      const internalTagsId = getGroupValue({
        filter,
        groupId: INTERNAL_TAGS,
        multiCheck: true,
        propToMap: 'id',
      });

      const externalTagsId = getGroupValue({
        filter,
        groupId: EXTERNAL_TAGS,
        multiCheck: true,
        propToMap: 'id',
      });

      const section = getGroupValue({ filter, groupId: SECTIONS, multiCheck: true });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: TYPE, multiCheck: true });

      const status = getGroupValue({ filter, groupId: STATUS, multiCheck: true });

      const requestFilterToSave: StoryCardsFeedFilter = {
        ...(authorId?.length && { authorId }),
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(section?.length && { section }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const requestFilter: StoryCardsFeedFilter = {
        ...(authorId?.length && { authorId }),
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(section?.length && { section }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        STORY_TAB_ITEMS_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions({ action: CookieActionType.SET })
      );

      dispatch(getStoryFeed({ storyId: Number(storyId), filter: requestFilter }));

      setFilter(filter);
    },
    [
      AUTHORS,
      EXTERNAL_TAGS,
      INTERNAL_TAGS,
      STATUS,
      TYPE,
      dispatch,
      getDateRangeValue,
      getGroupValue,
      setCookie,
      storyId,
    ]
  );

  return (
    <div className={classes['story-feed-items-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};

import { FunctionComponent, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  IconLabel,
  IconLabelSizes,
  InputField,
  getCssVar,
  isValidDigit,
} from '../../../../../shared';
import { TWO_FA_CODE_DIGITS_QTY, TwoFactorAuthCode } from '../TwoFactorAuthCode';

import formClasses from '../../../Form/Form.module.scss';
import classes from './TwoFactorAuth.module.scss';

interface TwoFactorAuthProps {
  onValidate: ({ otp, otpBackup }: { otp?: string; otpBackup?: string }) => void;
  onBack: () => void;
}

export const TwoFactorAuth: FunctionComponent<TwoFactorAuthProps> = memo(
  ({ onValidate, onBack }) => {
    const { t } = useTranslation();

    const [digits, setDigits] = useState<string[]>([]);

    const [useBackup, setUseBackup] = useState(false);

    const [otpBackup, setOtpBackup] = useState<string>('');

    const inputContent = useMemo(() => {
      if (useBackup) {
        return (
          <InputField
            value={otpBackup}
            onChange={({ target }) => setOtpBackup(target.value)}
            maxLength={6}
          />
        );
      }

      return <TwoFactorAuthCode digits={digits} onChange={setDigits} />;
    }, [digits, otpBackup, useBackup]);

    const validateButton = useRef<HTMLButtonElement | null>(null);

    const validateDisabled = useMemo(() => {
      return useBackup
        ? otpBackup.length !== TWO_FA_CODE_DIGITS_QTY
        : digits.length !== TWO_FA_CODE_DIGITS_QTY || digits.some((digit) => !isValidDigit(digit));
    }, [digits, otpBackup.length, useBackup]);

    const validateClickHandler = useCallback(async () => {
      validateButton.current?.setAttribute('disabled', 'true');

      await onValidate(useBackup ? { otpBackup } : { otp: digits.join('') });

      validateButton.current?.removeAttribute('disabled');
    }, [digits, onValidate, otpBackup, useBackup]);

    return (
      <>
        <div className={formClasses['form__content-header']}>
          <span className={formClasses['form__content-header-title']}>{t('auth.2fa-title')}</span>
          <div className={formClasses['form__content-header-subtitle']}>
            {t(`auth.2fa-subtitle${useBackup ? '-backup' : ''}`)}
          </div>
        </div>

        <div className={classes['verification']}>
          <div className={classes['verification__title']}>
            {t(`auth.2fa-enter-otp${useBackup ? '-backup' : ''}`)}
          </div>
          {inputContent}
        </div>

        <Button
          ref={validateButton}
          type={ButtonType.primary}
          label={t('common.validate')}
          onClick={validateClickHandler}
          disabled={validateDisabled}
          fullWidth
        />

        <IconLabel
          label={t(`auth.2fa-do-not-have-${useBackup ? 'backup' : 'device'}`)}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          className={classes['verification__link']}
          onClick={() => setUseBackup(!useBackup)}
        />

        {useBackup && (
          <span className={classes['verification__hint']}>{t('auth.2fa-contact-admin')}</span>
        )}

        <IconLabel
          label={t('common.back-to-login')}
          labelSize={IconLabelSizes.large}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          className={classes['verification__link']}
          onClick={onBack}
        />
      </>
    );
  }
);

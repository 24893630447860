import { FunctionComponent, memo, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationCommentAction, NotificationCommentItem, User } from '../../../../services';
import { LineClamp } from '../../LineClamp';

import classes from './NotificationCommentContent.module.scss';

interface NotificationCommentContentProps {
  author: User;
  commentAction: NotificationCommentAction;
  comment: NotificationCommentItem;
  parentComment?: NotificationCommentItem;
}

export const NotificationCommentContent: FunctionComponent<
  PropsWithChildren<NotificationCommentContentProps>
> = memo(({ commentAction, comment, parentComment, author }) => {
  const { t } = useTranslation();

  const renderComment = useMemo(() => {
    if (!comment) {
      return null;
    }

    const { screenName } = author;
    const { content } = comment;

    switch (commentAction) {
      case NotificationCommentAction.LIKE:
        return (
          <>
            <div className={classes['content__title']}>
              <span className={classes['content__title-author']}>{screenName}</span>&nbsp;
              {t('channelNotifications.liked')}
            </div>
            <div className={classes['content__comment']}>
              <LineClamp maxLines={3} readMore={'...'} stringContent={content} />
            </div>
          </>
        );
      case NotificationCommentAction.REPLY:
        return (
          <>
            <div className={classes['content__title']}>
              <span className={classes['content__title-author']}>{screenName}</span>&nbsp;
              {t('channelNotifications.replied')}
            </div>
            <div className={classes['content__answer']}>{content}</div>
            <div className={classes['content__comment']}>
              <LineClamp maxLines={3} readMore={'...'} stringContent={parentComment?.content} />
            </div>
          </>
        );
      case NotificationCommentAction.COMMENT:
        return (
          <>
            <div className={classes['content__title']}>
              <span className={classes['content__title-author']}>{screenName}</span>&nbsp;
              {t('channelNotifications.commented')}
            </div>
            <div className={classes['content__answer']}>{content}</div>
          </>
        );
      default:
        return null;
    }
  }, [author, comment, commentAction, parentComment?.content, t]);

  return <div className={classes['content']}>{renderComment}</div>;
});

import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Copyright } from '../../Copyright';
import { Modal } from '../../Modal';
import { ImageGalleryItem } from '../ImageGallery';

import classNames from 'classnames';
import classes from './ImageSingle.module.scss';

interface ImageSingleProps {
  item: ImageGalleryItem;
  captionClass?: string;
  fullSize?: boolean;
  modalPreviewOnClick?: boolean;
  onLogClickOpenArticle?: () => void;
}

const AVERAGE_COLOR_PLACEHOLDER = 'rgb(228, 228, 235)';

export const ImageSingle: FunctionComponent<ImageSingleProps> = memo(
  ({ item, captionClass, fullSize, modalPreviewOnClick, onLogClickOpenArticle }) => {
    const { image, caption } = item;
    const { url, averageColor, rightholder } = image;

    const [isModalPreviewOpened, setIsModalPreviewOpened] = useState(false);

    const modalPreviewBody = useMemo(
      () => <img className={classes['image__modal-preview']} src={url} alt={'modal-preview'} />,
      [url]
    );

    const modalPreviewOnClickHandler = useCallback(() => {
      onLogClickOpenArticle?.();
      setIsModalPreviewOpened(true);
    }, [onLogClickOpenArticle]);

    return (
      <>
        <div
          className={classNames(classes['image__wrapper'], {
            [classes['image__wrapper--full-size']]: fullSize,
            [classes['image__wrapper--clickable']]: modalPreviewOnClick,
          })}
          style={{ backgroundColor: averageColor ?? AVERAGE_COLOR_PLACEHOLDER }}
          onClick={() => modalPreviewOnClick && modalPreviewOnClickHandler()}
        >
          <img
            className={classNames(classes['image__preview'], {
              [classes['image__preview--full-size']]: fullSize,
            })}
            src={url}
            alt={'preview'}
          />
          {rightholder && <Copyright copyright={rightholder} />}
        </div>
        {caption && (
          <div
            className={classNames(classes['image__caption'], captionClass)}
            dangerouslySetInnerHTML={{ __html: caption.replace(/href/g, "target='_blank' href") }}
          ></div>
        )}

        {modalPreviewOnClick &&
          isModalPreviewOpened &&
          ReactDOM.createPortal(
            <Modal
              isOpen={isModalPreviewOpened}
              body={modalPreviewBody}
              onClose={() => setIsModalPreviewOpened(false)}
              previewMode
              previewSrc={url}
            />,
            document.getElementById('modal-preview-root') as HTMLElement
          )}
      </>
    );
  }
);

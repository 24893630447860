import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { StoryStatus, useGetStoriesByChannelIdQuery } from '../../services';
import {
  IconLabel,
  getCssVar,
  useAnalytics,
  useBeforeUnload,
  useDeepLink,
  useDuration,
} from '../../shared';
import { NoContent } from '../Error';
import { StoryFeed } from './StoryFeed';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import classes from './StoryFeedItems.module.scss';

export const StoryFeedItems: FunctionComponent = () => {
  const navigate = useNavigate();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { isDeepLink } = useDeepLink();

  const storyId = Number(useParams().storyId);

  const { data: stories, isFetching } = useGetStoriesByChannelIdQuery({ channelId });

  const { logClickOpenStory } = useAnalytics();

  const { startTrackTime, getDuration } = useDuration();

  const story = stories?.find(({ id }) => id === storyId);

  const historyState = useMemo(() => window.history.state, []);

  const logClickOpen = useCallback(
    () =>
      logClickOpenStory({
        channel_id: channelId,
        story_id: storyId,
        mix_id: storyId,
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, logClickOpenStory, storyId]
  );

  useEffect(() => {
    startTrackTime();
    return () => logClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpen]);

  useBeforeUnload(logClickOpen);

  if (isFetching) {
    return <Skeleton height={'10rem'} />;
  }

  if (!story || (isDeepLink && story.status !== StoryStatus.PUBLISHED)) {
    return <NoContent />;
  }

  const { title, subtitle, image } = story;
  const { url: imageUrl } = { ...image };

  return (
    <div className={classes['feed-items']}>
      <div
        className={classNames(classes['feed-items__header'], {
          [classes['feed-items__header--pointer']]: historyState,
        })}
        onClick={() => historyState && navigate(-1)}
      >
        {historyState && (
          <IconLabel
            iconId={'arrow-rounded-left'}
            color={getCssVar('--story-feed-back-color')}
            className={classes['feed-items__icon']}
            singleColor
          />
        )}
        <div className={classes['feed-items__heading']}>
          <div
            className={classNames(classes['feed-items__heading-title'], {
              [classes['feed-items__heading-title--one-liner']]: imageUrl,
            })}
          >
            {title}
          </div>
          {subtitle && !imageUrl && (
            <div className={classes['feed-items__heading-subTitle']}>{subtitle}</div>
          )}
        </div>
      </div>
      {imageUrl && (
        <div className={classes['feed-items__image-text-wrapper']}>
          <img className={classes['feed-items__image']} src={imageUrl} alt={'preview'} />
          <div className={classes['feed-items__text']}>
            <div className={classes['feed-items__text-title']}>{title}</div>
            {subtitle && <div className={classes['feed-items__text-subTitle']}>{subtitle}</div>}
          </div>
        </div>
      )}
      <StoryFeed storyId={storyId} />
    </div>
  );
};

import { ResponsiveBar } from '@nivo/bar';
import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommentListQuery } from '../../../../services';
import { Avatar, getWebAppUrl, LineClamp, OrderDirection } from '../../../../shared';
import { COLORS } from '../../Analytics';
import { ChartWrapper, getHorizontalBarsLayer, Loader } from '../../helpers';
import { ContentProps } from '../Content';

import analytics from '../../Analytics.module.scss';
import classes from './CommentTop.module.scss';

const BAR_HEIGHT = 32;
const MARGIN_TOP = 20;
const MARGIN_BOTTOM = 40;
const PADDING = BAR_HEIGHT / 2;

interface TooltipProps {
  cardTitle: string;
  screenName: string;
  avatarUrl: string;
  content: string;
}

export const CommentTop: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform }) => {
    const { t } = useTranslation();

    const { data: commentList, isFetching: isFetchingCommentList } = useCommentListQuery({
      from,
      to,
      channelId,
      platform,
      orderDirection: OrderDirection.DESC,
      page: 1,
      size: 5,
    });

    const tooltip = useCallback(({ cardTitle, screenName, avatarUrl, content }: TooltipProps) => {
      return (
        <div className={analytics['analytics__chart-tooltip']}>
          <div className={classes['title']}>{cardTitle}</div>
          <div className={classes['content']}>
            <div className={classes['content__user']}>
              <Avatar url={avatarUrl} size={40} />
              <span className={classes['content__user-name']}>{screenName}</span>
            </div>
            <LineClamp
              contentClassName={classes['content__comment']}
              readMoreClassName={classes['content__comment']}
              maxLines={10}
              stringContent={content}
              readMore={'...'}
            />
          </div>
        </div>
      );
    }, []);

    const renderChart = useMemo(() => {
      if (isFetchingCommentList) {
        return <Loader />;
      }

      if (!commentList) {
        return null;
      }

      const { items } = commentList;

      const itemsFiltered = items.filter(({ value }) => value);

      if (!itemsFiltered.length) {
        return (
          <div className={analytics['analytics__chart-no-records']}>{t('common.no-records')}</div>
        );
      }

      const keys: string[] = [];

      const data = itemsFiltered.map(({ label, value, payload }) => {
        const {
          channel: { subdomain },
          commentReactionsTotal,
          storyCardTitle,
          comment: {
            id: commentId,
            content,
            author: { screenName, avatar },
            storyCard,
          },
        } = payload;

        const { id: cardId, storyId } = storyCard;

        const labelWithId = `(${commentId}) ${label}`;

        keys.push(labelWithId);

        return {
          [labelWithId]: value,
          label: labelWithId,
          content: content || t('common.attachment'),
          screenName,
          avatarUrl: avatar?.url ?? '',
          commentReactionsTotal,
          cardTitle: storyCardTitle,
          detailsPath: `${getWebAppUrl(subdomain)}/admin/details/${storyId}/${cardId}`,
        };
      });

      const height = `calc(${data.length * BAR_HEIGHT}px + ${MARGIN_TOP}px + ${MARGIN_BOTTOM}px + ${
        (data.length + 1) * PADDING
      }px)`;

      return (
        <div style={{ height }}>
          <ResponsiveBar
            layout={'horizontal'}
            enableGridY={false}
            margin={{ top: MARGIN_TOP, right: 50, bottom: MARGIN_BOTTOM, left: 20 }}
            padding={0.5}
            borderRadius={4}
            tooltip={({ data }) => tooltip(data)}
            axisLeft={{
              renderTick: ({ value, y }) => (
                <text y={y - PADDING} fontSize={14} fill={'#1E1E1E'}>
                  {value.replace(/ *\([^)]*\) */g, '') || t('common.attachment')}
                </text>
              ),
            }}
            axisBottom={{ format: (value) => (value === Math.floor(value) ? value : '') }}
            data={data.reverse()}
            keys={keys}
            indexBy={'label'}
            colors={[COLORS[0]]}
            label={''}
            onClick={({ data: { detailsPath } }) =>
              window.open(detailsPath, '_blank', 'noopener, noreferrer')
            }
            layers={['axes', 'bars', ({ bars }) => getHorizontalBarsLayer({ bars })]}
          />
        </div>
      );
    }, [commentList, isFetchingCommentList, t, tooltip]);

    return (
      <ChartWrapper typeLocaliseKey={'chartTypes.comment-top'} infoLocaliseKey={'commentTop.info'}>
        {renderChart}
      </ChartWrapper>
    );
  }
);

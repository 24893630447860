import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useLoaderText = (loading: boolean) => {
  const { t } = useTranslation();

  const [loaderText, setLoaderText] = useState('');

  useEffect(() => {
    if (!loading) {
      return;
    }

    let messageIndex = 1;

    const interval = setInterval(() => {
      setLoaderText(t(`fileUpload.loader-message-${messageIndex}`));
      messageIndex >= 3 ? (messageIndex = 1) : messageIndex++;
    }, 4000);

    return () => {
      clearInterval(interval);
      setLoaderText('');
    };
  }, [loading, setLoaderText, t]);

  return { loaderText };
};

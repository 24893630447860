import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../../../../context';
import { StoryCard, StoryCardQuoteContent } from '../../../../services';
import { useAnalytics } from '../../../hooks';
import { getCssVar } from '../../../utils';
import { AuthorInfo } from '../../AuthorInfo';
import { IconLabel } from '../../IconLabel';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { FeedCardMargin } from '../FeedCardMargin';

import classNames from 'classnames';
import classes from './FeedCardSocialCommon.module.scss';

interface FeedCardSocialCommonProps {
  card: StoryCard;
}

export const FeedCardSocialCommon: FunctionComponent<FeedCardSocialCommonProps> = memo(
  ({ card }) => {
    const { logClickOpenArticle } = useAnalytics();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { id: cardId, storyId, options, content, isPinnedToTopOfNewsFeed } = card;

    const { allowDisplayAuthor } = options;

    const {
      url,
      headline,
      quote,
      gallery,
      quoteSource,
      quotePerson,
      quotePersonImage,
      quotePersonHandle,
      quoteCreated,
    } = content as StoryCardQuoteContent;

    const imageGalleryItems: ImageGalleryItem[] = gallery.map(({ image }) => {
      return {
        image,
      };
    });

    const headerSubTitle = useMemo(
      () =>
        `${quotePersonHandle} via ${quoteSource?.toLowerCase()} • ${format(
          parseISO(quoteCreated ?? ''),
          'dd MMM'
        )}`,
      [quoteCreated, quotePersonHandle, quoteSource]
    );

    const logClickOpen = useCallback(() => {
      logClickOpenArticle({
        channel_id: channelId,
        story_id: storyId,
        item_id: cardId,
        time_stamp: new Date().toISOString(),
      });
    }, [cardId, channelId, logClickOpenArticle, storyId]);

    const cardHeaderClickHandler = useCallback(() => {
      logClickOpen();
      window.open(url, '_blank', 'noopener, noreferrer');
    }, [logClickOpen, url]);

    const isFramed = useMemo(() => allowDisplayAuthor || headline, [allowDisplayAuthor, headline]);

    const authorInfoIcon = useMemo(
      () => (
        <IconLabel
          className={classes['icon']}
          iconId={'arrow-rounded-right-up'}
          iconSize={20}
          color={getCssVar('--card-social-icon-color')}
          singleColor
        />
      ),
      []
    );

    return (
      <FeedCardMargin top left right>
        {!allowDisplayAuthor && headline && <div className={classes['headline']}>{headline}</div>}

        <div className={classNames({ [classes['wrapper']]: isFramed })}>
          <div
            className={classNames(classes['header-wrapper'], {
              [classes['header-wrapper--inner']]: isFramed,
            })}
            onClick={cardHeaderClickHandler}
          >
            <FeedCardMargin bottom>
              <div className={classes['author-info']}>
                {isPinnedToTopOfNewsFeed && (
                  <IconLabel
                    iconId={'pin'}
                    label={'•'}
                    color={getCssVar('--base-link-text-color')}
                    singleColor
                    nonClickable
                  />
                )}
                <AuthorInfo
                  avatarUrl={quotePersonImage?.url}
                  title={quotePerson}
                  subTitle={headerSubTitle}
                  icon={authorInfoIcon}
                />
              </div>
            </FeedCardMargin>
            {quote && <div className={classes['quote']}>{quote}</div>}
          </div>

          <ImageGallery
            items={imageGalleryItems}
            modalPreviewOnClick
            onLogClickOpenArticle={logClickOpen}
          />
        </div>
      </FeedCardMargin>
    );
  }
);

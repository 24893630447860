import { channelApi } from './channelApi';

//! TODO
export interface InAppActiveMessage {
  id: number;
  bgColor: string;
  textColor: string;
  image: {
    url: string;
    width: number;
    height: number;
  } | null;
  title: string;
  intro: string;
  primary: {
    text: string;
    textColor: string;
    bgColor: string;
    action: string;
  };
  created: string;
  delivery: string;
  expire: string;
}

interface InAppMessageResult {
  activeMessage: InAppActiveMessage | null;
}

interface InAppMessageReceivedArgs {
  messageId: number;
}

export const inAppMessageApi = channelApi.injectEndpoints({
  endpoints: (builder) => ({
    getInAppMessage: builder.query<InAppMessageResult, void>({
      query: () => ({
        url: '/webapp/in-app-messages',
      }),
    }),
    InAppMessageReceived: builder.mutation<void, InAppMessageReceivedArgs>({
      query: ({ messageId }) => ({
        url: `/webapp/in-app-messages/${messageId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetInAppMessageQuery, useInAppMessageReceivedMutation } = inAppMessageApi;

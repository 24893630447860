import { Delimiter } from '../../../../../components';
import { DelimiterBlockData } from '../../../../models';
import { BaseBlockTool } from '../baseBlockTool';

type Settings = {
  placeholder?: string;
};

export class DelimiterTool extends BaseBlockTool<DelimiterBlockData, DelimiterBlockData, Settings> {
  static get toolbox() {
    return {
      title: 'Delimiter',
      icon: `
      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <line x1="6" x2="10" y1="12" y2="12" stroke="currentColor" stroke-linecap="round" stroke-width="2"></line>
        <line x1="14" x2="18" y1="12" y2="12" stroke="currentColor" stroke-linecap="round" stroke-width="2"></line>
      </svg>`,
    };
  }

  getJSXTool(): JSX.Element {
    return <Delimiter />;
  }

  save(): DelimiterBlockData {
    return { style: 'stars' };
  }

  getDefaultState(): DelimiterBlockData {
    return { style: 'stars' };
  }
}

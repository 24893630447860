export const durationFormatter = (value: number) => {
  const years = Math.floor(value / 31536000);

  const months = Math.floor((value % 31536000) / 2628000);

  const days = Math.floor(((value % 31536000) % 2628000) / 86400);

  const hours = Math.floor((value % 86400) / 3600)
    .toString()
    .padStart(2, '0');

  const minutes = Math.floor((value % 3600) / 60)
    .toString()
    .padStart(2, '0');

  const seconds = Math.floor(value % 60)
    .toString()
    .padStart(2, '0');

  //500+ days
  if (value >= 43200000) {
    return `${years}y:${months}m:${days}d`;
  }

  //50-500 days
  if (value >= 4320000) {
    return `${months}m:${days}d:${hours}h`;
  }

  //5-50 days
  if (value >= 432000) {
    return `${days}d:${hours}h:${minutes}m`;
  }

  //0-5 days
  return `${hours}h:${minutes}m:${seconds}s`;
};

import { FunctionComponent, memo } from 'react';
import { StoryCardQuoteContent } from '../../../services';
import { FeedCardProps, IconLabel, LineClamp, getCssVar } from '../../../shared';
import { FeedCardPreviewWrapper } from '../FeedCardPreviewWrapper';

import classes from './FeedCardPreviewSocial.module.scss';

export const FeedCardPreviewSocial: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { quote, quoteSource, quotePersonHandle } = card.content as StoryCardQuoteContent;

  return (
    <FeedCardPreviewWrapper card={card}>
      <div className={classes['preview-card-social']}>
        {quoteSource && (
          <div className={classes['preview-card-social__source']}>
            <IconLabel
              iconId={quoteSource.toLowerCase()}
              iconSize={14}
              color={getCssVar('--card-social-icon-color')}
              singleColor
            />
            {quotePersonHandle && (
              <span className={classes['preview-card-social__person']}>{quotePersonHandle}</span>
            )}
          </div>
        )}
        {quote && (
          <LineClamp
            maxLines={5}
            readMore={'...'}
            contentClassName={classes['preview-card-social__quote']}
            stringContent={quote}
          />
        )}
      </div>
    </FeedCardPreviewWrapper>
  );
});

import { FunctionComponent, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from '../Button';
import { Modal } from '../Modal';

import classes from './ConfirmationModal.module.scss';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  subTitle: string | JSX.Element;
  onClose: () => void;
  onAccept: () => void;
  acceptLabel: string;
  cancelLabel?: string;
  hideCancel?: boolean;
  keepOpened?: boolean;
  opacityOverlay?: boolean;
  loading?: boolean;
  danger?: boolean;
  error?: string;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  isOpen,
  title,
  subTitle,
  acceptLabel,
  cancelLabel,
  hideCancel,
  onClose,
  onAccept,
  keepOpened,
  opacityOverlay,
  loading,
  danger,
  error,
}) => {
  const { t } = useTranslation();

  const body = useMemo(() => {
    return (
      <div className={classes['confirmation__body']}>
        {title && <div className={classes['confirmation__body-title']}>{title}</div>}
        {subTitle && <div className={classes['confirmation__body-subTitle']}>{subTitle}</div>}
        <div className={classes['confirmation__body-buttons']}>
          {!hideCancel && (
            <Button
              type={ButtonType.secondary}
              label={cancelLabel ?? t('common.cancel')}
              onClick={onClose}
              disabled={loading}
            />
          )}
          <Button
            type={ButtonType.primary}
            label={acceptLabel}
            onClick={onAccept}
            loading={loading}
            disabled={loading}
            {...(danger && { danger: true })}
          />
        </div>
        {error && <div className={classes['confirmation__body-error']}>{error}</div>}
      </div>
    );
  }, [
    acceptLabel,
    cancelLabel,
    danger,
    error,
    hideCancel,
    loading,
    onAccept,
    onClose,
    subTitle,
    t,
    title,
  ]);

  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <Modal
            isOpen={isOpen}
            body={body}
            onClose={onClose}
            {...(keepOpened && { keepOpened: true })}
            {...(opacityOverlay && { opacityOverlay: true })}
          />,
          document.getElementById('modal-root') as HTMLElement
        )}
    </>
  );
};

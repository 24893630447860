import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonType } from '../../../../shared';
import { Form } from '../../Form';

import classNames from 'classnames';
import formClasses from '../../Form/Form.module.scss';

export const TokenError: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Form>
      <div className={formClasses['form__content-header']}>
        <span
          className={classNames(
            formClasses['form__content-header-title'],
            formClasses['form__content-header-title--error']
          )}
        >
          {t('setPassword.token-error-title')}
        </span>
        <div className={formClasses['form__content-header-subtitle']}>
          {t('setPassword.token-error-sub-title')}
        </div>
      </div>

      <Button
        type={ButtonType.primary}
        label={t('common.continue')}
        onClick={() => navigate('/auth/login')}
        fullWidth
      />
    </Form>
  );
};

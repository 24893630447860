import { FileAcceptType } from '../hooks';

export const getAcceptTypeByFileType = (fileType: string) => {
  switch (fileType) {
    case 'image':
      return FileAcceptType.IMAGE;
    case 'audio':
      return FileAcceptType.AUDIO;
    case 'video':
      return FileAcceptType.VIDEO;
    case 'application/pdf':
      return FileAcceptType.PDF;
    case 'text': //txt
    case 'application/msword': //doc
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': //docx
    case 'application/vnd.ms-excel': //xls
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': //xlsx
    case 'application/vnd.ms-powerpoint': //ppt
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': //pptx
    case 'application/vnd.oasis.opendocument.text': //odt
    case 'application/vnd.oasis.opendocument.spreadsheet': //ods
    case 'application/vnd.oasis.opendocument.presentation': //ods
      return FileAcceptType.FILE;
    default:
      return undefined;
  }
};

import { FunctionComponent } from 'react';
import { getCssVar } from '../../../../shared';

import classes from './Delimiter.module.scss';

export const Delimiter: FunctionComponent = () => {
  return (
    <div
      className={classes['delimiter']}
      style={{
        backgroundColor: getCssVar('--details-block-delimiter-color'),
        mask: `url(${getCssVar('--details-block-delimiter-url')}) no-repeat center`,
        WebkitMask: `url(${getCssVar('--details-block-delimiter-url')}) no-repeat center`,
      }}
    ></div>
  );
};

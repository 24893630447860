import { FunctionComponent, memo, useContext } from 'react';
import { UserContext } from '../../../../context';
import { StoryCardContentGallery } from '../../../../services';
import {
  DeeplinkType,
  EmbedLinkMediaPlaceholder,
  GalleryItem,
  getDeepLinkUrl,
  getStoryCardIntro,
  getStoryCardPreviewUrl,
  getStoryCardSource,
  getStoryCardTitle,
  ImageFile,
  StoryCardBlockData,
} from '../../../../shared';
import { EmbedLink } from '../EmbedLink';

export const StoryCard: FunctionComponent<StoryCardBlockData> = memo(({ storyCard }) => {
  const { channelId } = useContext(UserContext).userInfo.userData;

  if (!storyCard) {
    return null;
  }

  const { storyId, id: cardId, type, content } = storyCard;

  const sourceName = getStoryCardSource(storyCard);

  const title = getStoryCardTitle(storyCard);

  const text = getStoryCardIntro(storyCard);

  const { gallery } = content as StoryCardContentGallery;

  const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

  const imageUrl = getStoryCardPreviewUrl({ type, galleryItem });

  const url = getDeepLinkUrl({ type: DeeplinkType.CARD, channelId, storyId, cardId });

  if ([sourceName, title, text, imageUrl].every((value) => !Boolean(value))) {
    return <EmbedLinkMediaPlaceholder url={url} type={type} />;
  }

  return (
    <EmbedLink
      link={{ url, sourceName, title, text, image: { url: imageUrl } as ImageFile }}
      hideUrl
    />
  );
});

import { FunctionComponent, memo } from 'react';
import { HorizontalStoryFeedCardProps, HorizontalStoryFeedContentCommon } from '../../../../shared';
import { HorizontalStoryFeedAdminCardWrapper } from '../HorizontalStoryFeedAdminCardWrapper';
import { StoryCardArticleContent } from '../../../../services';

export const HorizontalStoryFeedAdminArticle: FunctionComponent<HorizontalStoryFeedCardProps> =
  memo(({ card }) => {
    const { type, content, isPinnedToTopOfNewsFeed } = card;
    const { title, sourceName, abstract } = content as StoryCardArticleContent;

    return (
      <HorizontalStoryFeedAdminCardWrapper card={card}>
        <HorizontalStoryFeedContentCommon
          cardType={type}
          source={sourceName}
          title={title}
          text={abstract}
          pinned={isPinnedToTopOfNewsFeed}
        ></HorizontalStoryFeedContentCommon>
      </HorizontalStoryFeedAdminCardWrapper>
    );
  });

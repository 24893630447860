import { FunctionComponent, memo } from 'react';
import { StatsItem } from '../StatsItem';

import classes from '../../../Analytics.module.scss';

interface TableStatsItem {
  localiseKey: string;
  value: number;
  valuePrev: number;
  valueFormatter?: (value: number) => string;
  hintLocaliseKey?: string;
}

interface TableStatsProps {
  stats: TableStatsItem[];
  loading: boolean;
  columnDisplay?: boolean;
}

export const TableStats: FunctionComponent<TableStatsProps> = memo(
  ({ stats, loading, columnDisplay }) => {
    return (
      <div className={classes['analytics__chart-stats']}>
        {stats.map(({ localiseKey, value, valuePrev, valueFormatter, hintLocaliseKey }) => {
          return (
            <StatsItem
              key={localiseKey}
              localiseKey={localiseKey}
              hintLocaliseKey={hintLocaliseKey}
              value={value}
              valuePrev={valuePrev}
              valueFormatter={valueFormatter}
              isDisabled={loading}
              columnDisplay={columnDisplay}
              isInline
            />
          );
        })}
      </div>
    );
  }
);

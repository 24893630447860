import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../context';
import {
  ConfigurationLink,
  DropdownMenu,
  Popup,
  getCssVar,
  isPushNotificationsAllowed,
  layoutPath,
} from '../../shared';
import { TeaserDetails } from '../SpecialTeaser';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';

interface SettingsMenuProps {
  component?: JSX.Element;
}

export const SettingsMenu: FunctionComponent<SettingsMenuProps> = memo(({ component }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [showTeaserDetails, setShowTeaserDetails] = useState<boolean>(false);

  const { config } = useContext(ConfigContext);

  const { links } = config.elements.settings;

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const promoClickHandler = useCallback(() => {
    closeMenu();
    setShowTeaserDetails(true);
  }, [closeMenu]);

  const renderSettingsLinks = useMemo(
    () =>
      links.map((link) => {
        const { title, promo } = link;
        return (
          <ConfigurationLink
            key={title}
            link={link}
            labelClassName={dropdownMenuClasses['dropdown-menu__item']}
            {...(promo && { customClickHandler: promoClickHandler })}
            clickCallback={closeMenu}
            singleColor
          />
        );
      }),
    [closeMenu, links, promoClickHandler]
  );

  const pushNotificationsClickHandler = useCallback(() => {
    closeMenu();
    navigate(layoutPath('/pushNotifications'));
  }, [closeMenu, navigate]);

  const dropdownMenuContent = useMemo(() => {
    return (
      <>
        {isPushNotificationsAllowed && (
          <span
            className={dropdownMenuClasses['dropdown-menu__item']}
            onClick={pushNotificationsClickHandler}
          >
            {t('settings.push-notifications')}
          </span>
        )}
        {component}
        {renderSettingsLinks}
      </>
    );
  }, [component, pushNotificationsClickHandler, renderSettingsLinks, t]);

  if (!Boolean(renderSettingsLinks.length || isPushNotificationsAllowed || component)) {
    return null;
  }

  return (
    <>
      <Popup
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        iconId={'settings'}
        color={getCssVar('--settings-icon-color')}
        hoverColor={getCssVar('--settings-icon-hover-color')}
        bodyTop={'2rem'}
        bodyRight={'0'}
        body={<DropdownMenu width={'17rem'} content={dropdownMenuContent} />}
      />

      <TeaserDetails isOpen={showTeaserDetails} onClose={() => setShowTeaserDetails(false)} />
    </>
  );
});

import { UserRole } from '../shared';
import { channelApi } from './channelApi';
import { RestrictDuplicateUrlOfCardsInTimeFrame, Story } from './story';

//! TODO

interface Locale {
  id: number;
  code: string;
}

export interface UserData {
  id: number;
  organisationId: number;
  pageTitle: string;
  organisationSubdomain: string;
  channelId: number;
  role: UserRole;
  localeId: number;
  locale: string;
  locales: Locale[];
  channelRestrictDuplicateUrlOfCardsGQL: RestrictDuplicateUrlOfCardsInTimeFrame;
  userPermissions: {
    allowToHideComment: boolean;
    allowToPinStoryToOrganisation: boolean;
    allowToSeeHiddenComment: boolean;
    allowToUseTranslation: boolean;
  };
  twoFactorEnabled: boolean;
}

export interface UserInfo {
  userData: UserData;
  storiesResult: Story[];
  body: {
    channelId: number;
    channelName: string;
    user_id: number;
    email: string;
    name: string;
  };
}

export const userInfoApi = channelApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => '/',
    }),
  }),
});

export const { useGetUserInfoQuery } = userInfoApi;

import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { storyCardApi } from '../../../../services';
import { FileAcceptType, ImageUploadType, useLoaderText, useUpload } from '../../../hooks';
import { FileExternalType, GalleryItem } from '../../../models';
import {
  getAcceptTypeByCardType,
  getAcceptTypeByFileType,
  getStoryCardParsedUrlFile,
  urlToFile,
} from '../../../utils';
import { Button, ButtonType } from '../../Button';
import { CircularLoader } from '../../CircularLoader';
import { InputField } from '../../InputField';
import {
  generateAudioTeaserUrl,
  generatePdfTeaserUrl,
  generateVideoTeaserUrl,
} from './FileUploadTeaser';

import classNames from 'classnames';
import classes from './FileUpload.module.scss';

interface FileUploadProps {
  galleryItems: GalleryItem[];
  setGalleryItems: (galleryItems: GalleryItem[]) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fileAcceptTypes?: FileAcceptType[];
  multiUpload?: boolean;
  uploadLimit?: number;
  files?: FileList;
  fileUrl?: string;
  placeholder?: string;
}

export const FileUpload: FunctionComponent<FileUploadProps> = memo(
  ({
    galleryItems,
    setGalleryItems,
    loading,
    setLoading,
    fileAcceptTypes,
    multiUpload,
    uploadLimit,
    files,
    fileUrl,
    placeholder,
  }) => {
    const { t } = useTranslation();

    const [parseUrl] = storyCardApi.endpoints.storyCardParseUrl.useLazyQuery();

    const { uploadHandler, getUploadType, getTemplateType } = useUpload();

    const { uploadHandler: teaserUploadHandler } = useUpload();

    const { loaderText } = useLoaderText(loading);

    const fileRef = useRef<HTMLInputElement | null>(null);

    const isTeaserUpload = useMemo(
      () => Boolean(galleryItems.length === 1 && !galleryItems.find(({ image }) => Boolean(image))),
      [galleryItems]
    );

    const hideUpload = useMemo(
      () => uploadLimit && galleryItems.length >= uploadLimit && !isTeaserUpload,
      [galleryItems.length, isTeaserUpload, uploadLimit]
    );

    const generateTeaserUrl = useCallback(
      ({ file, acceptType }: { file?: File; acceptType: FileAcceptType }) => {
        if (!file) {
          return;
        }

        switch (acceptType) {
          case FileAcceptType.AUDIO:
            return generateAudioTeaserUrl(file);
          case FileAcceptType.VIDEO:
            return generateVideoTeaserUrl(file);
          case FileAcceptType.PDF:
            return generatePdfTeaserUrl(file);
          default:
            return null;
        }
      },
      []
    );

    const getGalleryItem = useCallback(
      async ({ id, file, acceptType }: { id: number; file: File; acceptType: FileAcceptType }) => {
        const originalFilename = file.name;
        const teaserUrl = await generateTeaserUrl({ file, acceptType });

        const teaser = teaserUrl
          ? {
              image: {
                id: (
                  await teaserUploadHandler({
                    file: await urlToFile(teaserUrl),
                    template: getTemplateType({ acceptType: FileAcceptType.IMAGE }),
                    type: getUploadType({ acceptType: FileAcceptType.IMAGE }),
                    imageUploadType: ImageUploadType.ITEM,
                  })
                ).id,
                url: teaserUrl,
              },
            }
          : null;

        switch (acceptType) {
          case FileAcceptType.IMAGE:
            return {
              image: { id, originalFilename, url: URL.createObjectURL(file) },
              ...(!isTeaserUpload && { title: '' }),
            };
          case FileAcceptType.AUDIO:
            return {
              audio: { id, originalFilename },
              ...teaser,
              title: '',
            };
          case FileAcceptType.VIDEO:
            return {
              video: { id, originalFilename },
              ...teaser,
              title: '',
            };
          case FileAcceptType.PDF:
            return {
              pdf: { id, originalFilename },
              ...teaser,
              title: '',
            };
        }
      },
      [generateTeaserUrl, getTemplateType, getUploadType, isTeaserUpload, teaserUploadHandler]
    );

    const onFileChange = useCallback(
      async (files: FileList | File[] | null) => {
        if (!files?.length || hideUpload) {
          return;
        }

        setLoading(true);

        const uploadedFiles = [];

        const uploadedFilesCount = galleryItems.length + files.length;

        const filesToProcess =
          uploadLimit && uploadedFilesCount > uploadLimit && !isTeaserUpload
            ? Array.from(files).slice(0, uploadLimit - uploadedFilesCount)
            : files;

        for (const file of filesToProcess) {
          const { type } = file;

          const fileType = type.includes('application/') ? type : type.split('/')[0];

          const acceptType = getAcceptTypeByFileType(fileType);

          if (!acceptType || (fileAcceptTypes && !fileAcceptTypes.includes(acceptType))) {
            toast.error(t('fileUpload.file-type-error'));
            continue;
          }

          const { id } = await uploadHandler({
            file,
            template: getTemplateType({ acceptType }),
            type: getUploadType({ acceptType }),
            ...(acceptType === FileAcceptType.IMAGE && { imageUploadType: ImageUploadType.ITEM }),
          });

          uploadedFiles.push({
            ...(await getGalleryItem({ id, file, acceptType })),
          } as GalleryItem);

          setGalleryItems(
            isTeaserUpload
              ? [{ ...galleryItems[0], ...uploadedFiles[0] }]
              : [...galleryItems, ...uploadedFiles]
          );
        }

        setLoading(false);
      },
      [
        fileAcceptTypes,
        galleryItems,
        getGalleryItem,
        getTemplateType,
        getUploadType,
        hideUpload,
        isTeaserUpload,
        setGalleryItems,
        setLoading,
        t,
        uploadHandler,
        uploadLimit,
      ]
    );

    const uploadFileByUrl = useCallback(
      async (url: string) => {
        if (!url || hideUpload) {
          return;
        }

        setLoading(true);

        const parsedData = await parseUrl({ url }).unwrap();

        const acceptType = getAcceptTypeByCardType(parsedData?.type);

        if (!acceptType || (fileAcceptTypes && !fileAcceptTypes.includes(acceptType))) {
          toast.error(t('fileUpload.file-type-error'));
          setLoading(false);
          return;
        }

        const file = getStoryCardParsedUrlFile(parsedData);

        if (!file) {
          setLoading(false);
          return;
        }

        const { id, externalType } = file;

        const galleryItem = (
          externalType === FileExternalType.TRANSLOADIT
            ? await getGalleryItem({ id, file: await urlToFile(url), acceptType })
            : { ...parsedData.gallery[0], title: '' }
        ) as GalleryItem;

        setGalleryItems([...galleryItems, galleryItem]);

        setLoading(false);
      },
      [
        fileAcceptTypes,
        galleryItems,
        getGalleryItem,
        hideUpload,
        parseUrl,
        setGalleryItems,
        setLoading,
        t,
      ]
    );

    useEffect(() => {
      if (files) {
        onFileChange(files);
      }

      if (fileUrl) {
        uploadFileByUrl(fileUrl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      window.addEventListener('dragover', (event) => event.preventDefault(), false);
      window.addEventListener('drop', (event) => event.preventDefault(), false);
    }, []);

    const pasteFileHandler = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        const { clipboardData } = event;
        const { files } = clipboardData;

        if (files.length) {
          onFileChange(files);
          return;
        }

        uploadFileByUrl(clipboardData.getData('Text'));
      },
      [onFileChange, uploadFileByUrl]
    );

    const dropFileHandler = useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        (event.target as HTMLDivElement).classList.remove(classes['file-upload--dropzone']);

        const files = event.dataTransfer.files;

        if (!files.length) {
          return;
        }

        onFileChange(files);
      },
      [onFileChange]
    );

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      (event.target as HTMLDivElement).classList.add(classes['file-upload--dropzone']);
    }, []);

    const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
      (event.target as HTMLDivElement).classList.remove(classes['file-upload--dropzone']);
    }, []);

    const accept = useMemo(
      () =>
        (
          fileAcceptTypes ?? [
            FileAcceptType.IMAGE,
            FileAcceptType.AUDIO,
            FileAcceptType.VIDEO,
            FileAcceptType.PDF,
          ]
        ).join(','),
      [fileAcceptTypes]
    );

    const inputFile = useMemo(() => {
      return (
        <input
          type={'file'}
          ref={fileRef}
          disabled={loading}
          accept={accept}
          className={classes['file-upload__file']}
          onChange={({ target }) => onFileChange(target.files)}
          onClick={({ target }) => ((target as HTMLInputElement).value = '')}
          multiple={multiUpload}
        />
      );
    }, [accept, loading, multiUpload, onFileChange]);

    const uploadButton = useMemo(() => {
      return (
        <Button
          label={t('fileUpload.upload-media')}
          type={ButtonType.secondary}
          onClick={() => fileRef.current?.click()}
          className={classes['file-upload__trigger-button']}
        />
      );
    }, [t]);

    const content = useMemo(() => {
      if (loading) {
        return <CircularLoader text={loaderText} />;
      }

      if (isMobileOnly) {
        return (
          <>
            {inputFile}
            {uploadButton}
          </>
        );
      }

      return (
        <>
          {inputFile}

          <div className={classes['file-upload__drop']}>
            <span className={classes['file-upload__drop-label']}>{t('fileUpload.drop-file')}</span>
          </div>

          <div
            className={classes['file-upload__trigger']}
            onDragOver={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <InputField
              value={''}
              placeholder={placeholder ?? t('fileUpload.paste-url-or-file')}
              inputClassName={classes['file-upload__trigger-input']}
              onPaste={pasteFileHandler}
            />

            <span className={classes['file-upload__trigger-divider']}>{t('common.or')}</span>

            {uploadButton}
          </div>
        </>
      );
    }, [inputFile, loaderText, loading, pasteFileHandler, placeholder, t, uploadButton]);

    if (hideUpload) {
      return null;
    }

    return (
      <div
        className={classNames('file-upload', classes['file-upload'], {
          [classes['file-upload--mobile']]: isMobileOnly,
        })}
        {...(!isMobileOnly && {
          onDrop: dropFileHandler,
          onDragOver: onDragOver,
          onDragLeave: onDragLeave,
        })}
      >
        {content}
      </div>
    );
  }
);

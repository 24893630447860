import { FunctionComponent, useContext } from 'react';
import { Teaser } from './Teaser';
import {
  ConfigSpecialTeaserSetupItem,
  StoryCardStatus,
  useStoryCardsFeedQuery,
} from '../../services';
import { ConfigContext, UserContext } from '../../context';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SpecialTeaser: FunctionComponent = () => {
  const { channelId } = useContext(UserContext).userInfo.userData;
  const { setup } = useContext(ConfigContext).config.elements.specialTeaser;

  const setupOption = setup.find(
    (setupItem) => setupItem.channelId === channelId || !setupItem.channelId
  );

  const { storyId } = { ...setupOption } as ConfigSpecialTeaserSetupItem;

  const { data, isFetching } = useStoryCardsFeedQuery(
    {
      storyId,
      size: 1,
      filter: { status: [StoryCardStatus.PUBLISHED] },
    },
    { skip: !storyId }
  );

  if (isFetching) {
    return <Skeleton width={'100%'} height={'14rem'} />;
  }

  if (!data?.items) {
    return null;
  }

  const topStoryCard = data.items[0];

  return topStoryCard ? <Teaser card={topStoryCard} /> : null;
};

import { useCallback, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { OrganisationContext, UserContext } from '../../context';
import { StorySection } from '../../services';
import { isAdminLayout, layoutPath } from '../utils';
import { useAppSelector } from './useAppSelector';
import { useNewsFeed } from './useNewsFeed';
import { useSearchFeed } from './useSearchFeed';
import { useStoriesFeed } from './useStoriesFeed';
import { useStoryFeed } from './useStoryFeed';
import { useUserFeed } from './useUserFeed';
import { useTagsFeed } from './useTagsFeed';

interface RefreshFeedsProps {
  scrollTop?: boolean;
}

export const useRefreshFeeds = ({ scrollTop }: RefreshFeedsProps) => {
  const { id: userId } = useContext(UserContext).userInfo.userData;

  const { channels } = useAppSelector(({ channels }) => channels);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { pathname } = useLocation();

  const { id: urlId, storyId: urlStoryId, organisationTagId } = useParams();

  const { getNewsFeedItems, getPinnedFeedItems, pinnedStorySection } = useNewsFeed();

  const { getSearchFeedItems } = useSearchFeed();

  const { getUserFeedItems } = useUserFeed({ userId: Number(urlId ?? userId) });

  const { getStoriesFeed } = useStoriesFeed();

  const { getStoryFeedItems } = useStoryFeed({ storyId: Number(urlStoryId) });

  const { getTagsFeedItems } = useTagsFeed();

  const { organisation } = useContext(OrganisationContext);

  const refreshNewsFeed = useCallback(() => {
    if (isAdminLayout() && pinnedStorySection) {
      const pinnedStoryId =
        (pinnedStorySection === StorySection.PINNED_CHANNEL
          ? channels.find(({ id }) => id === channelId)?.pinnedStoryId
          : organisation?.pinnedStoryId) ?? -1;

      return getPinnedFeedItems(pinnedStoryId);
    }

    return getNewsFeedItems();
  }, [
    channelId,
    channels,
    getNewsFeedItems,
    getPinnedFeedItems,
    organisation?.pinnedStoryId,
    pinnedStorySection,
  ]);

  const refreshFeeds = useCallback(() => {
    switch (pathname) {
      case layoutPath('/'): {
        scrollTop && window.scrollTo(0, 0);
        return refreshNewsFeed();
      }
      case layoutPath('/search'): {
        scrollTop && window.scrollTo(0, 0);
        return getSearchFeedItems();
      }
      case layoutPath(`${urlId ? `/profile/${urlId}` : '/profile'}`): {
        scrollTop && window.scrollTo(0, 0);
        return getUserFeedItems();
      }
      case '/admin/storyTab':
        return getStoriesFeed();
      case `/storyTab1/${urlStoryId}/items`:
      case `/storyTab2/${urlStoryId}/items`:
      case `/pinnedStoryTab/${urlStoryId}/items`:
      case `/admin/storyTab/${urlStoryId}/items`:
        return getStoryFeedItems();
      case layoutPath(`/tags/${organisationTagId}`):
        return getTagsFeedItems();
    }
  }, [
    getSearchFeedItems,
    getStoriesFeed,
    getStoryFeedItems,
    getTagsFeedItems,
    getUserFeedItems,
    organisationTagId,
    pathname,
    refreshNewsFeed,
    scrollTop,
    urlId,
    urlStoryId,
  ]);

  return { refreshFeeds };
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterGroup, FilterValue } from '../../components';
import { LastDateValue, useLastDate } from '../useLastDate';
import { useFilter } from './useFilter';

export const DATE_RANGE_GROUP_ID = 'date';

interface DateRangeFilterProps {
  filter: FilterValue[];
  allowReset?: boolean;
}

export const useDateRangeFilter = ({ filter, allowReset = true }: DateRangeFilterProps) => {
  const { t } = useTranslation();

  const { getGroupValue } = useFilter();

  const { getLastDate } = useLastDate();

  const customRangeValue = useMemo(() => {
    const dateRange = getGroupValue({ filter, groupId: DATE_RANGE_GROUP_ID });

    return {
      from: dateRange?.from ?? getLastDate(LastDateValue.LAST_7_DAYS).from,
      to: dateRange?.to ?? getLastDate(LastDateValue.LAST_7_DAYS).to,
    };
  }, [filter, getGroupValue, getLastDate]);

  return {
    group: {
      id: DATE_RANGE_GROUP_ID,
      title: t('common.creation-date'),
      allowReset,
      items: [
        {
          id: 'custom-range',
          label: t('dateRangeFilter.custom-range'),
          value: customRangeValue,
          dateRange: true,
        },
        {
          id: LastDateValue.LAST_24_HRS,
          label: t('dateRangeFilter.LAST_24_HRS'),
          value: LastDateValue.LAST_24_HRS,
        },
        {
          id: LastDateValue.LAST_7_DAYS,
          label: t('dateRangeFilter.LAST_7_DAYS'),
          value: LastDateValue.LAST_7_DAYS,
        },
        {
          id: LastDateValue.LAST_30_DAYS,
          label: t('dateRangeFilter.LAST_30_DAYS'),
          value: LastDateValue.LAST_30_DAYS,
        },
        {
          id: LastDateValue.LAST_3_MONTHS,
          label: t('dateRangeFilter.LAST_3_MONTHS'),
          value: LastDateValue.LAST_3_MONTHS,
        },
        {
          id: LastDateValue.LAST_6_MONTHS,
          label: t('dateRangeFilter.LAST_6_MONTHS'),
          value: LastDateValue.LAST_6_MONTHS,
        },
        {
          id: LastDateValue.LAST_12_MONTHS,
          label: t('dateRangeFilter.LAST_12_MONTHS'),
          value: LastDateValue.LAST_12_MONTHS,
        },
        {
          id: LastDateValue.ALL_TIME,
          label: t('dateRangeFilter.ALL_TIME'),
          value: LastDateValue.ALL_TIME,
        },
      ],
    } as FilterGroup,
  };
};

import { FunctionComponent, memo } from 'react';
import { StoryCardEditorialContent } from '../../../../../services';
import { CardContainer } from '../CardContainer';
import { Content } from '../Content';
import { LatestsStoryCardProps } from '../LatestsStoryCard';

export const Editorial: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  const { type, id, storyId, content, dateToDisplay } = card;
  const { headline, subHeadline, sourceName, textHTML } = content as StoryCardEditorialContent;

  return (
    <CardContainer type={type} storyId={storyId} cardId={id} postedTime={dateToDisplay}>
      <Content source={sourceName} title={headline} subTitle={subHeadline} htmlText={textHTML} />
    </CardContainer>
  );
});

import { ResponsiveFunnel } from '@nivo/funnel';
import { differenceInDays, subDays } from 'date-fns';
import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDemoFunnelQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, StatsItem } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserDemoFunnel: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { t } = useTranslation();

  const { data: userDemoFunnel, isFetching: isFetchingUserDemoFunnel } = useUserDemoFunnelQuery({
    from,
    to,
    platform,
  });

  const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

  const { data: userDemoFunnelPrev, isFetching: isFetchingUserDemoFunnelPrev } =
    useUserDemoFunnelQuery({
      from: subDays(new Date(from), daysDiff).toISOString(),
      to: subDays(new Date(to), daysDiff).toISOString(),
      platform,
    });

  const { items = [] } = { ...userDemoFunnel };

  const { items: itemsPrev = [] } = { ...userDemoFunnelPrev };

  const renderStats = useMemo(() => {
    if (isFetchingUserDemoFunnel || isFetchingUserDemoFunnelPrev) {
      return <Loader stats />;
    }

    if (!userDemoFunnel || !userDemoFunnelPrev) {
      return null;
    }

    return (
      <div className={classes['analytics__chart-stats']}>
        {items.map(({ label, value }) => {
          return (
            <StatsItem
              key={label}
              label={label}
              localiseKey={`userFunnelLabels.${label}`}
              hintLocaliseKey={`userFunnelHints.${label}`}
              value={value}
              valuePrev={itemsPrev.find((dataset) => dataset.label === label)?.value ?? 0}
              isInline
            />
          );
        })}
      </div>
    );
  }, [
    isFetchingUserDemoFunnel,
    isFetchingUserDemoFunnelPrev,
    items,
    itemsPrev,
    userDemoFunnel,
    userDemoFunnelPrev,
  ]);

  const renderChart = useMemo(() => {
    if (isFetchingUserDemoFunnel) {
      return <Loader />;
    }

    if (!userDemoFunnel) {
      return null;
    }

    if (!items.length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const total = items.find(({ label }) => label === 'total')?.value ?? 0;

    const data = items.map(({ label, value }) => {
      const localizedLabel = t(`userFunnelLabels.${label}`);

      return {
        id: localizedLabel,
        label: localizedLabel,
        value: Math.round((100 * value) / total),
      };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsiveFunnel
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          data={data}
          colors={COLORS}
          direction={'horizontal'}
          labelColor={{ from: 'color', modifiers: [['darker', 3]] }}
          beforeSeparatorLength={100}
          afterSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          valueFormat={(value) => `${value}%`}
          theme={{ labels: { text: { fontSize: 14 } } }}
        />
      </div>
    );
  }, [isFetchingUserDemoFunnel, items, t, userDemoFunnel]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-demo-funnel'}
      infoLocaliseKey={'userDemoFunnel.info'}
    >
      {renderStats}
      {renderChart}
    </ChartWrapper>
  );
});

import { ResponsivePie } from '@nivo/pie';
import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserInteractionQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

export const UserInteraction: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { t } = useTranslation();

  const { data: userInteraction, isFetching: isFetchingUserInteraction } = useUserInteractionQuery({
    from,
    to,
    platform,
  });

  const renderChart = useMemo(() => {
    if (isFetchingUserInteraction) {
      return <Loader />;
    }

    if (!userInteraction) {
      return null;
    }

    const { items } = userInteraction;

    if (!items.filter(({ value }) => Boolean(value)).length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const data = items.map(({ label, value }) => {
      const localizedLabel = t(`userInteractionLabels.${label}`);

      return {
        id: localizedLabel,
        label: localizedLabel,
        value,
      };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsivePie
          margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
          data={data}
          colors={COLORS}
          innerRadius={0.35}
          activeOuterRadiusOffset={6}
          arcLinkLabelsTextColor={{ from: 'color' }}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsSkipAngle={1}
          arcLabelsSkipAngle={1}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              translateY: 20,
              itemWidth: 100,
              itemHeight: 60,
              itemTextColor: '#999',
              itemDirection: 'top-to-bottom',
              symbolShape: 'circle',
              toggleSerie: true,
              effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
            },
          ]}
          theme={{ labels: { text: { fontSize: 14 } } }}
        />
      </div>
    );
  }, [isFetchingUserInteraction, t, userInteraction]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-interaction'}
      infoLocaliseKey={'userInteraction.info'}
    >
      {renderChart}
    </ChartWrapper>
  );
});

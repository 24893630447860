import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconLabel, OrderDirection, PaginationInfo, getCssVar } from '../../../../../shared';

import sprite from '../../../../../assets/icons/sprite.svg';

import classNames from 'classnames';
import classes from './TableChart.module.scss';

export interface TableChartHeader {
  label: string;
  rowSpan?: number;
  colSpan?: number;
  orderBy?: string;
}

export interface TableChartSubHeader {
  label: string;
  orderBy?: string;
}

export interface DataRowItem {
  value: string | number;
  url?: string;
}

interface TableChartProps {
  headers: TableChartHeader[];
  subHeaders?: TableChartSubHeader[];
  data: DataRowItem[][];
  pageInfo: PaginationInfo;
  setPage: (page: number) => void;
  sticky?: boolean;
  orderBy?: string;
  setOrderBy?: (orderBy: string) => void;
  orderDirection?: OrderDirection;
  setOrderDirection?: (orderDirection: OrderDirection) => void;
  loading?: boolean;
}

export const TableChart: FunctionComponent<TableChartProps> = memo(
  ({
    headers,
    subHeaders,
    data,
    pageInfo,
    setPage,
    sticky,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    loading,
  }) => {
    const { t } = useTranslation();

    const noData = useMemo(() => !Boolean(data.length), [data.length]);

    const orderByArrow = useCallback(
      (headerOrderBy?: string) => {
        if (!headerOrderBy || headerOrderBy !== orderBy) {
          return null;
        }

        return (
          <svg
            className={classNames(classes['table__th-arrow'], {
              [classes['table__th-arrow--up']]: orderDirection === OrderDirection.DESC,
            })}
          >
            <use href={`${sprite}#down-arrow`} />
          </svg>
        );
      },
      [orderBy, orderDirection]
    );

    const changeOrderBy = useCallback(
      (headerOrderBy: string) => {
        setPage(1);

        if (headerOrderBy === orderBy) {
          setOrderDirection?.(
            orderDirection === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
          );
          return;
        }

        setOrderBy?.(headerOrderBy);
        setOrderDirection?.(OrderDirection.DESC);
      },
      [orderBy, orderDirection, setOrderBy, setOrderDirection, setPage]
    );

    const renderPagination = useMemo(() => {
      const { page, perPage, hasNextPage, hasPreviousPage, totalItems, totalPages } = pageInfo;

      if (!totalItems || totalPages === 1) {
        return null;
      }

      const from = (page - 1) * perPage + 1;

      const to = Math.min(page * perPage, totalItems);

      return (
        <div className={classes['table__pagination']}>
          <span className={classes['table__pagination__label']}>
            {from}-{to} {t('common.of')} {totalItems}
          </span>
          <>
            <IconLabel
              iconSize={20}
              iconId={'arrow-left'}
              disabled={!hasPreviousPage}
              onClick={() => setPage(pageInfo.page - 1)}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
            />
            <IconLabel
              iconSize={20}
              iconId={'arrow-right'}
              disabled={!hasNextPage}
              onClick={() => setPage(pageInfo.page + 1)}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
            />
          </>
        </div>
      );
    }, [pageInfo, setPage, t]);

    return (
      <>
        <div
          className={classNames(classes['table__wrapper'], {
            [classes['table__wrapper--loading']]: loading,
          })}
        >
          <table
            className={classNames(classes['table'], {
              [classes['table--sticky']]: sticky,
            })}
          >
            <thead className={classes['table__thead']}>
              {Boolean(headers.length) && (
                <tr>
                  {headers.map(({ label, rowSpan, colSpan, orderBy }) => {
                    return (
                      <th
                        key={label}
                        className={classNames(classes['table__th'], classes['table__th--header'], {
                          [classes['table__th--order-by']]: orderBy,
                        })}
                        rowSpan={rowSpan}
                        colSpan={colSpan}
                        onClick={() => orderBy && changeOrderBy(orderBy)}
                      >
                        {label}
                        {orderByArrow(orderBy)}
                      </th>
                    );
                  })}
                </tr>
              )}
              {Boolean(subHeaders?.length) && (
                <tr>
                  {subHeaders?.map(({ label, orderBy }) => {
                    return (
                      <th
                        key={label}
                        className={classNames(
                          classes['table__th'],
                          classes['table__th--sub-header'],
                          {
                            [classes['table__th--order-by']]: orderBy,
                          }
                        )}
                        onClick={() => orderBy && changeOrderBy(orderBy)}
                      >
                        {label}
                        {orderByArrow(orderBy)}
                      </th>
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody
              className={classNames({
                [classes['table__tbody--loading']]: loading,
              })}
            >
              {noData && (
                <tr>
                  <td
                    colSpan={headers.length + (subHeaders?.length ?? 0)}
                    className={classes['table__no-records']}
                  >
                    {t('common.no-records')}
                  </td>
                </tr>
              )}
              {data.map((row, rowIndex) => {
                return (
                  <tr key={`row-${rowIndex}`} className={classes['table__tr']}>
                    {row.map(({ value, url }, cellIndex) => {
                      const isLink = Boolean(value && url);

                      return (
                        <td
                          onClick={() =>
                            isLink && window.open(url, '_blank', 'noopener, noreferrer')
                          }
                          title={value.toString()}
                          key={`cell-${rowIndex}-${cellIndex}`}
                          className={classNames(classes['table__td'], {
                            [classes['table__td--link']]: isLink,
                          })}
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {renderPagination}
      </>
    );
  }
);

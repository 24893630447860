import { useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfigContext } from '../../../context';
import {
  LoginContentType,
  TwoFactorAuth,
  TwoFactorAuthSetup,
  TwoFactorAuthSetupProps,
} from '../../../pages';
import { LoginWithEmail } from '../../../pages/Auth/Login/LoginWithEmail';
import { Config, userApi } from '../../../services';
import { Button, ButtonType, Modal } from '../../components';
import { MZ_ACCOUNT_COOKIE_KEY } from '../../constants';
import { CookieActionType, cookieOptions, CUSTOM_CLIENT_CONFIG } from '../../utils';

import classNames from 'classnames';
import classes from './useFreeLoginPopup.module.scss';

export const useFreeLoginPopup = (queriedConfig?: Config) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [triggerLoginByEmail] = userApi.endpoints.userLoginByEmail.useLazyQuery();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies([MZ_ACCOUNT_COOKIE_KEY]);

  const { config: contextConfig } = useContext(ConfigContext);

  const { titleLocaliseKey = '', subTitleLocaliseKey = '' } = {
    ...(queriedConfig ?? contextConfig)?.elements?.freeLogin,
  };

  const { enabled: emailEnabled = false } = {
    ...(queriedConfig ?? contextConfig)?.elements?.login?.email,
  };

  const [isOpen, setIsOpen] = useState(false);

  const [email, setEmail] = useState<string>('');

  const [password, setPassword] = useState<string>('');

  const [loginContentType, setLoginContentType] = useState<LoginContentType | null>(
    LoginContentType.EMAIL
  );

  const [twoFactorAuthSetup, setTwoFactorAuthSetup] = useState<TwoFactorAuthSetupProps>({
    otpAuthSecret: '',
    otpAuthUrlQRCode: '',
    onVerify: () => null,
    onBack: () => null,
  });

  const twoFactorBackHandler = useCallback(() => {
    setLoginContentType(LoginContentType.EMAIL);
  }, []);

  const onSuccessLogin = useCallback(
    ({ accessToken }: { accessToken: string }) => {
      if (CUSTOM_CLIENT_CONFIG?.baseHost) {
        setCookie(
          MZ_ACCOUNT_COOKIE_KEY,
          accessToken,
          cookieOptions({
            action: CookieActionType.SET,
            domain: `.${CUSTOM_CLIENT_CONFIG.baseHost}`,
          })
        );
      }

      setIsOpen(false);

      window.location.reload();
    },
    [setCookie]
  );

  const otpProcessing = useCallback(
    async ({ otp, otpBackup }: { otp?: string; otpBackup?: string }) => {
      const { payload } = await triggerLoginByEmail({ email, password, otp, otpBackup }).unwrap();

      if (payload) {
        const { accessToken } = payload;

        onSuccessLogin({ accessToken });

        return;
      }

      toast.error(t('auth.2fa-otp-invalid'));
    },
    [email, onSuccessLogin, password, t, triggerLoginByEmail]
  );

  const renderContentHeader = useMemo(
    () =>
      Boolean(
        ![LoginContentType.SETUP_2FA, LoginContentType.CODE_2FA].includes(
          loginContentType as LoginContentType
        )
      ),
    [loginContentType]
  );

  const content = useMemo(() => {
    if (!emailEnabled) {
      return (
        <Button
          type={ButtonType.primary}
          label={t('common.login')}
          onClick={() => navigate('/auth/login')}
          fullWidth
        />
      );
    }

    switch (loginContentType) {
      case LoginContentType.EMAIL:
        return (
          <LoginWithEmail
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            onSuccessLogin={onSuccessLogin}
            setLoginContentType={setLoginContentType}
            otpProcessing={otpProcessing}
            setTwoFactorAuthSetup={setTwoFactorAuthSetup}
            twoFactorBackHandler={twoFactorBackHandler}
            isFreeLogin
          />
        );
      case LoginContentType.SETUP_2FA:
        return <TwoFactorAuthSetup {...twoFactorAuthSetup} />;
      case LoginContentType.CODE_2FA:
        return <TwoFactorAuth onValidate={otpProcessing} onBack={twoFactorBackHandler} />;
    }
  }, [
    email,
    emailEnabled,
    loginContentType,
    navigate,
    onSuccessLogin,
    otpProcessing,
    password,
    t,
    twoFactorAuthSetup,
    twoFactorBackHandler,
  ]);

  const freeLoginModal = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return (
      <>
        {isOpen &&
          ReactDOM.createPortal(
            <Modal
              isOpen={isOpen}
              contentStyle={{ width: '25rem' }}
              body={
                <div className={classNames(classes['free-login'], 'free-login')}>
                  {renderContentHeader && (
                    <div className={classes['free-login__header']}>
                      <div className={classes['free-login__header-title']}>
                        {t(titleLocaliseKey)}
                      </div>
                      <div className={classes['free-login__header-subTitle']}>
                        {t(subTitleLocaliseKey)}
                      </div>
                    </div>
                  )}
                  {content}
                </div>
              }
              onClose={() => setIsOpen(false)}
              alignTop
            />,
            document.getElementById('modal-root') as HTMLElement
          )}
      </>
    );
  }, [content, isOpen, renderContentHeader, subTitleLocaliseKey, t, titleLocaliseKey]);

  const freeLoginHandler = useCallback(async () => {
    if (document.querySelectorAll('.free-login').length) {
      return;
    }

    setIsOpen(true);
  }, []);

  return {
    freeLoginModal,
    freeLoginHandler,
  };
};

import { FunctionComponent, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryAutoNotification } from '../../../../../services';
import { Switch, TextAreaField } from '../../../../../shared';

import classes from './AutoNotification.module.scss';

interface AutoNotificationProps {
  autoNotification: StoryAutoNotification;
  setAutoNotification: (autoNotification: StoryAutoNotification) => void;
}

export const AUTO_NOTIFICATION_TITLE_MIN_LIMIT = 4;
export const AUTO_NOTIFICATION_MESSAGE_MIN_LIMIT = 1;
const AUTO_NOTIFICATION_TITLE_MAX_LIMIT = 50;
const AUTO_NOTIFICATION_MESSAGE_MAX_LIMIT = 160;

export const AutoNotification: FunctionComponent<AutoNotificationProps> = memo(
  ({ autoNotification, setAutoNotification }) => {
    const { t } = useTranslation();

    const { enabled, titleDefault, messageDefault, titlePrefix } = autoNotification;

    const [title, setTitle] = useState<string>(titleDefault);

    const [message, setMessage] = useState<string>(messageDefault);

    const [prefix, setPrefix] = useState<string>(titlePrefix);

    const toggleAutoNotification = useCallback(
      (enabled: boolean) => {
        setAutoNotification({ ...autoNotification, enabled });
      },
      [autoNotification, setAutoNotification]
    );

    const onTitleChange = useCallback(
      (value: string) => {
        setTitle(value);
        setAutoNotification({ ...autoNotification, titleDefault: value });
      },
      [autoNotification, setAutoNotification]
    );

    const onMessageChange = useCallback(
      (value: string) => {
        setMessage(value);
        setAutoNotification({ ...autoNotification, messageDefault: value });
      },
      [autoNotification, setAutoNotification]
    );

    const onPrefixChange = useCallback(
      (value: string) => {
        setPrefix(value);
        setAutoNotification({ ...autoNotification, titlePrefix: value });
      },
      [autoNotification, setAutoNotification]
    );

    return (
      <div className={classes['auto-notification']}>
        <Switch
          inputId={'auto-notification'}
          label={t('createStorySettings.auto-notification-switch-label')}
          checked={enabled}
          onChange={toggleAutoNotification}
        />
        <TextAreaField
          inputClassName={classes['auto-notification__input']}
          label={t('createStorySettings.auto-notification-title-label')}
          placeholder={t('createStorySettings.auto-notification-title-placeholder')}
          value={title}
          minLength={AUTO_NOTIFICATION_TITLE_MIN_LIMIT}
          maxLength={AUTO_NOTIFICATION_TITLE_MAX_LIMIT}
          onChange={({ target: { value } }) => onTitleChange(value)}
          disabled={!enabled}
          disableLineBreak
          validateOnInit
        />
        <TextAreaField
          inputClassName={classes['auto-notification__input']}
          label={t('createStorySettings.auto-notification-message-label')}
          placeholder={t('createStorySettings.auto-notification-message-placeholder')}
          value={message}
          minLength={AUTO_NOTIFICATION_MESSAGE_MIN_LIMIT}
          maxLength={AUTO_NOTIFICATION_MESSAGE_MAX_LIMIT}
          onChange={({ target: { value } }) => onMessageChange(value)}
          minRows={2}
          disabled={!enabled}
          disableLineBreak
          validateOnInit
        />
        <TextAreaField
          inputClassName={classes['auto-notification__input']}
          label={t('createStorySettings.auto-notification-title-prefix-label')}
          placeholder={t('createStorySettings.auto-notification-title-prefix-placeholder')}
          value={prefix}
          maxLength={AUTO_NOTIFICATION_TITLE_MAX_LIMIT}
          onChange={({ target: { value } }) => onPrefixChange(value)}
          disabled={!enabled}
          disableLineBreak
        />
      </div>
    );
  }
);

import { FunctionComponent, useEffect, useMemo } from 'react';
import { FeedCard, HorizontalStoryFeed } from '../../components';
import { FeedCardLoader, NewsFeedCommon, useAppSelector, useNewsFeed } from '../../shared';
import { NoContent } from '../Error';

export const NewsFeed: FunctionComponent = () => {
  const { getNewsFeedItems, loadMoreNewsFeedItems } = useNewsFeed();

  const { newsFeed, isFetching } = useAppSelector(({ newsFeed }) => newsFeed);

  useEffect(() => {
    getNewsFeedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCards = useMemo(() => Boolean(newsFeed.length), [newsFeed.length]);

  const loader = useMemo(() => <FeedCardLoader />, []);

  const content = useMemo(() => {
    if (isFetching) {
      return loader;
    }

    if (!hasCards) {
      return <NoContent />;
    }

    return (
      <NewsFeedCommon
        loader={loader}
        loadMore={loadMoreNewsFeedItems}
        HorizontalStoryFeedComponent={HorizontalStoryFeed}
        CardComponent={FeedCard}
      />
    );
  }, [hasCards, isFetching, loadMoreNewsFeedItems, loader]);

  return content;
};

import { graphqlApi } from './graphqlApi';

export enum ChannelAppTabAction {
  NEWS_FEED = 'NEWS_FEED',
  CHATS = 'CHATS',
  PINNED_STORY = 'PINNED_STORY',
  PROFILE = 'PROFILE',
  STORIES = 'STORIES',
  STORIES2 = 'STORIES2',
}

export interface AppTab {
  action: {
    type: ChannelAppTabAction;
    storyId?: number;
  };
  iconUrl: string;
  title: [
    {
      language: string;
      localization: string;
    }
  ];
}

export interface ChannelBySubdomainPayload {
  appTabs: AppTab[];
  allAppTabs: AppTab[];
  dashboardUrl: string | null;
}

interface ChannelBySubdomainResponse {
  data: {
    channelBySubdomain: {
      payload: ChannelBySubdomainPayload;
    };
  };
}

export interface ChannelBySubdomainArgs {
  subdomain: string;
}

const appTabQuery = `
  iconUrl
  action {
    type
    ... on ChannelAppTabPinnedStory {
      storyId
    }
  }
  title {
    language
    localization
  }
`;

export const channelBySubdomainApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getChannelBySubdomain: builder.query<ChannelBySubdomainPayload, ChannelBySubdomainArgs>({
      query: ({ subdomain }) => ({
        url: '/graphql/webapp',
        method: 'POST',
        body: {
          query: `
          {
            channelBySubdomain(subdomain: "${subdomain}") {
              payload {
                appTabs {
                  ${appTabQuery}
                }
                allAppTabs {
                  ${appTabQuery}
                }
                dashboardUrl
              }
            }
          }`,
        },
      }),
      transformResponse: (response: ChannelBySubdomainResponse) =>
        response.data.channelBySubdomain.payload,
    }),
  }),
});

export const { useGetChannelBySubdomainQuery } = channelBySubdomainApi;

import { FunctionComponent, memo } from 'react';
import { ChartType } from '../Analytics';
import { useChartExclude } from '../helpers';
import { InstallUninstall } from './InstallUninstall';
import { LoginLogout } from './LoginLogout';

export interface OtherProps {
  from: string;
  to: string;
}

export const Other: FunctionComponent<OtherProps> = memo((otherProps) => {
  const excludeLoginLogout = useChartExclude(ChartType.LOGIN_LOGOUT);
  const excludeInstallUninstall = useChartExclude(ChartType.INSTALL_UNINSTALL);

  return (
    <>
      {!excludeLoginLogout && <LoginLogout {...otherProps} />}
      {!excludeInstallUninstall && <InstallUninstall {...otherProps} />}
    </>
  );
});

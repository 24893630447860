import { SVGProps } from 'react';
import { EmojiModes } from '../../shared/models';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number | string;
  mode?: EmojiModes;
}

export const Haha = ({ size = '1em', mode = EmojiModes.light, ...props }: Props) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={15}
      cy={15}
      r={14.5}
      fill="#FFD972"
      stroke={mode === EmojiModes.light ? 'white' : '#0A0904'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.958 15a9.042 9.042 0 1018.084 0H5.958z"
      fill="#262C39"
    />
    <mask
      id="prefix__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={5}
      y={15}
      width={20}
      height={10}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.958 15a9.042 9.042 0 1018.084 0H5.958z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix__a)">
      <ellipse cx={15.146} cy={26.958} rx={16.188} ry={9.042} fill="#F25267" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.296 8.33a.583.583 0 00-.512 1.05l2.627 1.28-2.627 1.281a.583.583 0 00.512 1.05l3.67-1.79a.583.583 0 00.327-.54.583.583 0 00-.327-.54l-3.67-1.79zM22.996 8.33a.583.583 0 01.512 1.05l-2.627 1.28 2.627 1.281a.583.583 0 01-.512 1.05l-3.67-1.79a.583.583 0 01-.328-.54.583.583 0 01.328-.54l3.67-1.79z"
      fill="#262C39"
    />
  </svg>
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../utils';
import { Badge } from '../Badge';
import { Check, CheckProps } from '../Check';
import { IconLabel, IconLabelSizes } from '../IconLabel';
import { Popup } from '../Popup';
import { Radio, RadioProps } from '../Radio';

import classes from './MultiStepFilter.module.scss';

export enum MultiStepFilterItemType {
  RADIO,
  CHECK,
}

interface MultiStepFilterItem {
  groupId?: string;
  value?: any;
  title?: string;
  itemType?: MultiStepFilterItemType;
  items?: MultiStepFilterItem[];
}

interface GroupValue {
  title?: string;
  value: any;
}

export interface MultiStepFilterValue {
  groupId: string;
  groupValues: GroupValue[];
}

export interface MultiStepFilterData {
  title: string;
  items: MultiStepFilterItem[];
}

interface MultiStepFilterProps {
  filter: MultiStepFilterValue[];
  data: MultiStepFilterData;
  onChange: (filter: MultiStepFilterValue[]) => void;
  groupIdLocaliseKey: string;
  disabled?: boolean;
}

export const MultiStepFilter: FunctionComponent<MultiStepFilterProps> = memo(
  ({ filter, data, onChange, groupIdLocaliseKey, disabled }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const { title: defaultTitle, items: defaultItems } = data;

    const [history, setHistory] = useState<MultiStepFilterItem[]>([]);

    const hasHistory = useMemo(() => Boolean(history.length), [history.length]);

    const [title, setTitle] = useState<string>(defaultTitle);

    const [items, setItems] = useState<MultiStepFilterItem[]>(defaultItems);

    const onClose = useCallback(() => {
      setTitle(defaultTitle);
      setItems(defaultItems);
      setHistory([]);
    }, [defaultItems, defaultTitle]);

    const itemClickHandler = useCallback(
      (item: MultiStepFilterItem) => {
        const { title = '', items = [] } = { ...item };

        setHistory([...history, item]);
        setTitle(title);
        setItems(items);
      },
      [history]
    );

    const back = useCallback(() => {
      history.pop();

      const { title = defaultTitle, items = defaultItems } = { ...history.at(-1) };

      setTitle(title);
      setItems(items);
    }, [defaultItems, defaultTitle, history]);

    const renderTitle = useMemo(() => {
      return (
        <div
          className={classes['multi-step-filter__list-title']}
          onClick={() => hasHistory && back()}
        >
          <IconLabel
            label={title}
            labelSize={IconLabelSizes.large}
            {...(hasHistory && { iconSize: 18, iconId: 'arrow-left' })}
            {...(!hasHistory && { nonClickable: true })}
            color={'#43445B'}
            singleColor
          />
        </div>
      );
    }, [back, hasHistory, title]);

    const onFilterChange = useCallback(
      ({
        groupId,
        itemType,
        value,
        title,
      }: {
        groupId: string;
        itemType: MultiStepFilterItemType;
        value: any;
        title?: string;
      }) => {
        const filterCopy = [...filter];

        const group = filterCopy.find((item) => item.groupId === groupId);

        const groupValue = { value, title };

        if (!group) {
          onChange([...filterCopy, { groupId, groupValues: [groupValue] }]);
          return;
        }

        const { groupValues } = group;

        switch (itemType) {
          case MultiStepFilterItemType.CHECK: {
            const index = groupValues.findIndex((groupValue) => groupValue.value === value) ?? -1;
            index !== -1 ? groupValues.splice(index, 1) : groupValues.push(groupValue);
            break;
          }
          case MultiStepFilterItemType.RADIO: {
            groupValues[0] = groupValue;
            break;
          }
        }

        if (!groupValues.length) {
          filterCopy.splice(
            filterCopy.findIndex((item) => item.groupId === groupId),
            1
          );
        }

        onChange(filterCopy);
      },
      [filter, onChange]
    );

    const renderItem = useCallback(
      (item: MultiStepFilterItem) => {
        const { groupId = '', value = '', title = '', itemType } = { ...item };
        const { groupValues = [] } = { ...filter.find((value) => value.groupId === groupId) };

        const itemContent = (() => {
          switch (itemType) {
            case MultiStepFilterItemType.CHECK:
            case MultiStepFilterItemType.RADIO: {
              const props: CheckProps | RadioProps = {
                checked: Boolean(groupValues.find((groupValue) => groupValue.value === value)),
                label: title,
                onChange: () => onFilterChange({ groupId, itemType, value, title }),
              };

              if (itemType === MultiStepFilterItemType.CHECK) {
                return <Check {...(props as CheckProps)} />;
              }

              return <Radio {...(props as RadioProps)} />;
            }
            default:
              return (
                <IconLabel
                  className={classes['multi-step-filter__list-item--with-child']}
                  label={title}
                  labelFirst
                  iconId={'arrow-right'}
                  iconSize={18}
                  color={'#43445B'}
                  singleColor
                  onClick={() => itemClickHandler(item)}
                />
              );
          }
        })();

        return (
          <li key={title} className={classes['multi-step-filter__list-item']}>
            {itemContent}
          </li>
        );
      },
      [filter, itemClickHandler, onFilterChange]
    );

    const onFilterGroupRemove = useCallback(
      (groupId: string) => {
        const filterCopy = [...filter];

        filterCopy.splice(
          filterCopy.findIndex((item) => item.groupId === groupId),
          1
        );

        onChange(filterCopy);
      },
      [filter, onChange]
    );

    return (
      <div className={classes['multi-step-filter']}>
        <Popup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconId={'add-plus'}
          iconSize={18}
          label={t('multiStepFilter.label')}
          bodyBottom={'1.5rem'}
          bodyLeft={'0'}
          color={getCssVar('--multi-step-filter-text-color')}
          hoverColor={getCssVar('--multi-step-filter-text-hover-color')}
          onClose={onClose}
          disabled={disabled}
          body={
            <div className={classes['multi-step-filter__list']}>
              {renderTitle}
              {items.map((item: MultiStepFilterItem) => renderItem(item))}
            </div>
          }
        />

        <div className={classes['multi-step-filter__value']}>
          {filter.map(({ groupId, groupValues }) => {
            const groupName = t(`${groupIdLocaliseKey}.${groupId}`);

            const values = groupValues
              .map(({ value, title }) => `"${title || value}"`)
              .join(` ${t('common.or').toUpperCase()} `);

            return (
              <Badge
                key={groupId}
                value={`${groupName} ${t('common.is')} ${values}`}
                onRemove={() => onFilterGroupRemove(groupId)}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

import { FunctionComponent } from 'react';
import { NavbarCommon } from '../../shared';
import { NotificationsAdmin } from '../NotificationsAdmin';
import { SettingsMenu } from '../SettingsMenu';

export const NavbarAdmin: FunctionComponent = () => {
  return (
    <NavbarCommon>
      <SettingsMenu />
      <NotificationsAdmin />
    </NavbarCommon>
  );
};

import {
  Dispatch,
  FunctionComponent,
  RefObject,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../../utils';
import { DropdownMenu } from '../../DropdownMenu';
import { IconLabel } from '../../IconLabel';
import { Popup } from '../../Popup';
import { FullPostFields, LinkPostArticleFields, ShortPostFields } from '../CreateCardModal';
import { FullPostToggleFields } from '../FullPost';
import { LinkPostArticleToggleFields } from '../LinkPost';
import { ShortPostToggleFields } from '../ShortPost';

import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';

export interface PostToggleFieldsItem {
  ref: RefObject<HTMLTextAreaElement>;
  title: string;
  iconId?: string;
  hidden: boolean;
}

interface PostToggleFieldsProps {
  loading: boolean;
  fields: ShortPostToggleFields | FullPostToggleFields | LinkPostArticleToggleFields;
  setFields: Dispatch<
    SetStateAction<ShortPostToggleFields | FullPostToggleFields | LinkPostArticleToggleFields>
  >;
}

export const PostToggleFields: FunctionComponent<PostToggleFieldsProps> = memo(
  ({ loading, fields, setFields }) => {
    const { t } = useTranslation();

    const [lastModified, setLastModified] = useState<RefObject<HTMLTextAreaElement>>();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
      if (lastModified) {
        lastModified.current?.focus();
      }
    }, [fields, lastModified]);

    const toggleFieldHandler = useCallback(
      (key: keyof (ShortPostFields | FullPostFields | LinkPostArticleFields)) => {
        setFields({
          ...fields,
          [key]: { ...fields[key], hidden: false },
        });

        setIsOpen(false);
      },
      [fields, setFields]
    );

    const dropdownMenuContent = useMemo(() => {
      const items = [];

      for (const [key, value] of Object.entries(fields)) {
        const { ref, title, hidden } = value as PostToggleFieldsItem;

        if (!hidden) {
          continue;
        }

        items.push(
          <span key={title}>
            <IconLabel
              iconSize={18}
              label={title}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              color={getCssVar('--create-card-link-color')}
              onClick={() => {
                setLastModified(ref);
                toggleFieldHandler(
                  key as keyof (ShortPostFields | FullPostFields | LinkPostArticleFields)
                );
              }}
              singleColor
            />
          </span>
        );
      }

      return items;
    }, [fields, toggleFieldHandler]);

    return (
      <Popup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        iconId={'headline'}
        iconSize={20}
        label={t('addContent.toggle-fields-title')}
        color={getCssVar('--create-card-link-color')}
        hoverColor={getCssVar('--create-card-link-hover-color')}
        disabled={Boolean(loading || !dropdownMenuContent.length)}
        bodyBottom={'0'}
        bodyRight={'0'}
        body={<DropdownMenu width={'12rem'} content={<>{dropdownMenuContent}</>} />}
      />
    );
  }
);

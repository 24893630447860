import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../../context';
import {
  ApiClientType,
  OrganisationTag,
  Story,
  StoryCard,
  StoryCardArticleParsedUrl,
  StoryCardAudioParsedUrl,
  StoryCardFieldsPostInStoryInput,
  StoryCardImageParsedUrl,
  StoryCardPdfParsedUrl,
  StoryCardPostContentBlockFields,
  StoryCardQuoteParsedUrl,
  StoryCardStatus,
  StoryCardTeaserImageStyle,
  StoryCardType,
  StoryCardVideoParsedUrl,
  storyCardApi,
} from '../../../../services';
import { addCard, editCard, getCard } from '../../../../slices';
import {
  CARD,
  CARD_LOCALE,
  CardProps,
  useAppDispatch,
  useFilter,
  useRefreshFeeds,
  useTags,
} from '../../../hooks';
import { GalleryItem } from '../../../models';
import {
  getChannelDomain,
  getCssVar,
  getWebAppUrl,
  isAdminLayout,
  isCardMediaCompleted,
  isEmptyString,
  optionalArrayItem,
  toggleStoryCardDisabled,
} from '../../../utils';
import { Button, ButtonType } from '../../Button';
import { FilterValue } from '../../Filter';
import { IconLabel, IconLabelHintDirection } from '../../IconLabel';
import { IconLabelWithActive } from '../../IconLabelWithActive';
import { Modal } from '../../Modal';
import { PublishToggle } from '../../PublishToggle';
import { ScheduleInput } from '../../ScheduleInput';
import { Select } from '../../Select';
import { TabItem, Tabs } from '../../Tabs';
import { FullPost } from '../FullPost';
import { LinkPost } from '../LinkPost';
import { ShortPost } from '../ShortPost';
import { ThreadPost } from '../ThreadPost';
import { CreateCardSettings, CreateCardSettingsTabType } from './CreateCardSettings';

import classNames from 'classnames';
import classes from './CreateCardModal.module.scss';

enum PostType {
  SHORT = 'SHORT',
  FULL = 'FULL',
  LINK = 'LINK',
  THREAD = 'THREAD',
}

enum TabType {
  SHORT_TAB,
  FULL_TAB,
  LINK_TAB,
  THREAD_TAB,
}

export interface CardSettings {
  card: FilterValue[];
  media: {
    galleryItems?: GalleryItem[];
    setGalleryItems?: (galleryItems: GalleryItem[]) => void;
    activeGalleryItemIndex?: number;
    teaserStyle?: StoryCardTeaserImageStyle;
    setTeaserStyle?: (teaserStyle: StoryCardTeaserImageStyle) => void;
  };
  activeSettingsTabIndex: number;
  setActiveSettingsTabIndex: (index: number) => void;
  tags?: {
    external: OrganisationTag[];
    setExternal: (items: OrganisationTag[]) => void;
    internal: OrganisationTag[];
    setInternal: (items: OrganisationTag[]) => void;
  };
}

export interface ShortPostFields {
  sourceName: string;
  headline: string;
  subHeadline: string;
  text: string;
  gallery: GalleryItem[];
}

export interface FullPostFields {
  sourceName: string;
  title: string;
  headline: string;
  abstract: string;
  contentAuthor: string;
  gallery: GalleryItem[];
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
  contentBlocks: StoryCardPostContentBlockFields[];
}

export interface ThreadPostFields {
  title: string;
}

export interface LinkPostQuoteFields extends StoryCardQuoteParsedUrl {
  headline: string;
}

export interface LinkPostArticleFields extends StoryCardArticleParsedUrl {
  headline: string;
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
}

export interface LinkPostFields {
  url: string;
  type?: StoryCardType;
  article?: LinkPostArticleFields;
  quote?: LinkPostQuoteFields;
  image?: StoryCardImageParsedUrl;
  audio?: StoryCardAudioParsedUrl;
  video?: StoryCardVideoParsedUrl;
  pdf?: StoryCardPdfParsedUrl;
}

interface CreateCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  postInStories: Story[];
  card?: StoryCard;
  createWithFilledFields?: boolean;
  shortPostFilledFields?: ShortPostFields;
  fullPostFilledFields?: FullPostFields;
  linkPostFilledFields?: LinkPostFields;
  threadPostFilledFields?: ThreadPostFields;
  files?: FileList;
  fileUrl?: string;
}

const FETCH_CARD_ATTEMPTS_LIMIT = 2;
const FETCH_CARD_MS_TIMEOUT = 5000;

export const CreateCardModal: FunctionComponent<CreateCardModalProps> = memo(
  ({
    isOpen,
    onClose,
    postInStories,
    card,
    createWithFilledFields,
    shortPostFilledFields,
    fullPostFilledFields,
    linkPostFilledFields,
    threadPostFilledFields,
    files,
    fileUrl,
  }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { pathname } = useLocation();

    const urlStoryId = Number(useParams().storyId);

    const { channelName } = useContext(UserContext).userInfo.body;

    const channelDomain = useMemo(() => getChannelDomain(), []);

    const { hasTags } = useTags();

    const { refreshFeeds } = useRefreshFeeds({ scrollTop: false });

    const { getGroupValue, getGroupItemValue } = useFilter();

    const [storyCardPostIn] = storyCardApi.endpoints.storyCardPostInStory.useLazyQuery();

    const [storyCardUpdate] = storyCardApi.endpoints.storyCardUpdate.useLazyQuery();

    const [storyCardParseUrlAsNative] =
      storyCardApi.endpoints.storyCardParseUrlAsNative.useLazyQuery();

    const [storyCardParseUrlAsPost] = storyCardApi.endpoints.storyCardParseUrlAsPost.useLazyQuery();

    const fetchCardAttemptsCount = useRef<number>(0);

    const {
      id: cardId = 0,
      status: cardStatus,
      options: cardOptions,
      postingTime: cardPostingTime,
      externalTags: cardExternalTags,
      internalTags: cardInternalTags,
      localeId,
      apiClient,
      assignedUserId,
    } = { ...card };

    const {
      allowComment,
      allowCardReaction,
      allowSharing,
      allowDisplayAuthor,
      shouldUpdatePostedTimeByComments,
    } = { ...cardOptions };

    const storageKey = useMemo(
      () => `Post in selected mix for channel:${channelName}`,
      [channelName]
    );

    const [loading, setLoading] = useState(false);

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

    const postTypeValue = useMemo(() => {
      switch (true) {
        case Boolean(fullPostFilledFields):
          return PostType.FULL;
        case Boolean(linkPostFilledFields):
          return PostType.LINK;
        case Boolean(threadPostFilledFields):
          return PostType.THREAD;
        default:
          return PostType.SHORT;
      }
    }, [fullPostFilledFields, linkPostFilledFields, threadPostFilledFields]);

    const [postType, setPostType] = useState<PostType>(postTypeValue);

    const [editCardStatus, setEditCardStatus] = useState<StoryCardStatus | undefined>(cardStatus);

    const isIntegrationCard = useMemo(
      () => Boolean(!assignedUserId && apiClient?.type === ApiClientType.API_CLIENTS_EXTERNAL),
      [apiClient?.type, assignedUserId]
    );

    const isEditCardPublished = useMemo(
      () => editCardStatus === StoryCardStatus.PUBLISHED,
      [editCardStatus]
    );

    const [postingTime, setPostingTime] = useState<Date | null>(
      cardPostingTime ? new Date(cardPostingTime) : null
    );

    const isFullPost = useMemo(() => postType === PostType.FULL, [postType]);

    const isLinkPost = useMemo(() => postType === PostType.LINK, [postType]);

    const postInSelectedStoryId = useMemo(() => {
      const storyId = [
        `/storyTab1/${urlStoryId}/items`,
        `/storyTab2/${urlStoryId}/items`,
        `/pinnedStoryTab/${urlStoryId}/items`,
        `/admin/storyTab/${urlStoryId}/items`,
      ].includes(pathname)
        ? urlStoryId
        : parseInt(localStorage.getItem(storageKey) ?? '');

      if (postInStories.find(({ id }) => id === storyId)) {
        return storyId;
      }

      return postInStories[0].id;
    }, [pathname, postInStories, storageKey, urlStoryId]);

    const [postInStoryId, setPostInStoryId] = useState<number>(postInSelectedStoryId);

    const postInStory = useMemo(
      () => postInStories.find(({ id }) => id === postInStoryId),
      [postInStories, postInStoryId]
    );

    const postInStoryTeaserStyle = useMemo(
      () =>
        postInStory?.settings.cardStyles.article.teaserStyle ?? StoryCardTeaserImageStyle.STANDARD,
      [postInStory?.settings.cardStyles.article.teaserStyle]
    );

    const editMode = useMemo(
      () =>
        !createWithFilledFields &&
        [
          shortPostFilledFields,
          fullPostFilledFields,
          linkPostFilledFields,
          threadPostFilledFields,
        ].some((item) => Boolean(item)),
      [
        createWithFilledFields,
        fullPostFilledFields,
        linkPostFilledFields,
        shortPostFilledFields,
        threadPostFilledFields,
      ]
    );

    const [externalTags, setExternalTags] = useState<OrganisationTag[]>(
      (!editMode ? postInStory?.externalTags : cardExternalTags) ?? []
    );

    const [internalTags, setInternalTags] = useState<OrganisationTag[]>(
      (!editMode ? postInStory?.internalTags : cardInternalTags) ?? []
    );

    useEffect(() => {
      if (editMode) {
        return;
      }

      setExternalTags(postInStory?.externalTags ?? []);
      setInternalTags(postInStory?.internalTags ?? []);
    }, [editMode, postInStory]);

    const [shortPostFields, setShortPostFields] = useState<ShortPostFields>(
      shortPostFilledFields ?? {
        sourceName: '',
        headline: '',
        subHeadline: '',
        text: '',
        gallery: [],
      }
    );

    const setShortPostGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        setShortPostFields({ ...shortPostFields, gallery });
      },
      [setShortPostFields, shortPostFields]
    );

    const defaultActiveSettingsTabIndex = useMemo(() => {
      switch (true) {
        case isAdminLayout():
          return CreateCardSettingsTabType.CARD;
        case hasTags:
          return CreateCardSettingsTabType.TAGS;
        default:
          return CreateCardSettingsTabType.MEDIA;
      }
    }, [hasTags]);

    const [activeSettingsTabIndex, setActiveSettingsTabIndex] = useState(
      defaultActiveSettingsTabIndex
    );

    const isMediaTabActive = useMemo(
      () => activeSettingsTabIndex === CreateCardSettingsTabType.MEDIA,
      [activeSettingsTabIndex]
    );

    const [activeShortPostGalleryItemIndex, setActiveShortPostGalleryItemIndex] = useState(0);

    const [fullPostFields, setFullPostFields] = useState<FullPostFields>(
      fullPostFilledFields ?? {
        sourceName: '',
        title: '',
        headline: '',
        abstract: '',
        contentAuthor: '',
        gallery: [],
        contentBlocks: [],
        styles: { teaserImageStyle: postInStoryTeaserStyle },
      }
    );

    const setFullPostGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        setFullPostFields({ ...fullPostFields, gallery });
      },
      [fullPostFields]
    );

    const setFullPostTeaserStyle = useCallback(
      (teaserImageStyle: StoryCardTeaserImageStyle) => {
        setFullPostFields({ ...fullPostFields, styles: { teaserImageStyle } });
      },
      [fullPostFields, setFullPostFields]
    );

    const [activeFullPostGalleryItemIndex, setActiveFullPostGalleryItemIndex] = useState(0);

    const [threadPostFields, setThreadPostFields] = useState<ThreadPostFields>(
      threadPostFilledFields ?? { title: '' }
    );

    const [activeLinkPostGalleryItemIndex, setActiveLinkPostGalleryItemIndex] = useState(0);

    const [linkPostFields, setLinkPostFields] = useState<LinkPostFields>(
      linkPostFilledFields ?? { url: '' }
    );

    const [convertBackToLinkPost, setConvertBackToLinkPost] = useState(false);

    const settingsButton = useRef<HTMLDivElement>(null);

    const scrollToSettings = useCallback(() => {
      setTimeout(() => {
        settingsButton?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }, []);

    const { text: shortPostText, gallery: shortPostGallery } = shortPostFields;

    const {
      title: fullPostTitle,
      gallery: fullPostGallery,
      styles: { teaserImageStyle: fullPostTeaserStyle },
      contentBlocks: fullPostContentBlocks,
    } = fullPostFields;

    const { title: threadPostTitle } = threadPostFields;

    const {
      url: linkPostFieldsUrl,
      type: linkPostType,
      article: linkPostArticle,
      quote: linkPostQuote,
    } = linkPostFields;

    const {
      styles: linkPostArticleStyles,
      sourceName: linkPostArticleSourceName,
      title: linkPostArticleTitle,
      gallery: linkPostArticleGallery,
    } = { ...linkPostArticle };

    useEffect(() => {
      if (!editMode) {
        const styles = { teaserImageStyle: postInStoryTeaserStyle };

        setFullPostFields({ ...fullPostFields, styles });

        if (linkPostFields.article) {
          setLinkPostFields({ ...linkPostFields, article: { ...linkPostFields.article, styles } });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postInStoryTeaserStyle]);

    const setLinkPostArticleGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        if (!linkPostFields.article) {
          return;
        }

        setLinkPostFields({ ...linkPostFields, article: { ...linkPostFields.article, gallery } });
      },
      [linkPostFields]
    );

    const setLinkPostArticleTeaserStyle = useCallback(
      (teaserImageStyle: StoryCardTeaserImageStyle) => {
        if (!linkPostFields.article) {
          return;
        }

        setLinkPostFields({
          ...linkPostFields,
          article: { ...linkPostFields.article, styles: { teaserImageStyle } },
        });
      },
      [linkPostFields]
    );

    const {
      quote: linkPostQuoteContent,
      quotePersonImage: linkPostQuoteContentPersonImage,
      gallery: linkPostQuoteGallery,
    } = { ...linkPostQuote };

    const setLinkPostQuoteGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        if (!linkPostFields.quote) {
          return;
        }

        setLinkPostFields({ ...linkPostFields, quote: { ...linkPostFields.quote, gallery } });
      },
      [linkPostFields]
    );

    const [fullPostTextEditorOpen, setFullPostTextEditorOpen] = useState(
      Boolean(!editMode && !fullPostContentBlocks.length)
    );

    const [fullPostTextEditorUsePrefill, setFullPostTextEditorUsePrefill] = useState(
      Boolean(!editMode)
    );

    const [linkPostPrevUrl, setLinkPostPrevUrl] = useState<string>(
      editMode ? linkPostFieldsUrl : ''
    );

    const convertToLinkPost = useCallback(
      (url: string, headline: string) => {
        if (editMode || url === linkPostFieldsUrl) {
          return;
        }

        setLinkPostFields({
          url,
          article: { headline } as LinkPostArticleFields,
          quote: { headline } as LinkPostQuoteFields,
        });

        setPostType(PostType.LINK);
      },
      [editMode, linkPostFieldsUrl]
    );

    const convertToShortPost = useCallback(async () => {
      const toastId = toast.loading(t('addContent.converting-to-short-post'));

      const fields = await storyCardParseUrlAsNative({ url: linkPostFieldsUrl }).unwrap();

      toast.dismiss(toastId);

      if (fields) {
        setConvertBackToLinkPost(true);
        setShortPostFields(fields as ShortPostFields);
        setPostType(PostType.SHORT);
        return;
      }

      toast.error(t('addContent.convert-to-short-post-error'), { delay: 1000 });
    }, [linkPostFieldsUrl, storyCardParseUrlAsNative, t]);

    const convertToFullPost = useCallback(async () => {
      const toastId = toast.loading(t('addContent.converting-to-full-post'));

      const fields = await storyCardParseUrlAsPost({ url: linkPostFieldsUrl }).unwrap();

      toast.dismiss(toastId);

      if (fields) {
        setConvertBackToLinkPost(true);
        setFullPostFields({
          ...fields,
          headline: '',
          styles: { teaserImageStyle: postInStoryTeaserStyle },
        } as FullPostFields);
        setPostType(PostType.FULL);
        return;
      }

      toast.error(t('addContent.convert-to-full-post-error'), { delay: 1000 });
    }, [linkPostFieldsUrl, postInStoryTeaserStyle, storyCardParseUrlAsPost, t]);

    const postInChangeHandler = useCallback(
      (id: number) => {
        localStorage.setItem(storageKey, id.toString());
        setPostInStoryId(id);
      },
      [storageKey]
    );

    const renderLinkPostReset = useMemo(
      () => Boolean(!editMode && isLinkPost && linkPostType),
      [editMode, isLinkPost, linkPostType]
    );

    const renderLinkPostConvert = useMemo(
      () => Boolean(isLinkPost && linkPostType === StoryCardType.ARTICLE),
      [isLinkPost, linkPostType]
    );

    const resetLinkPostHandler = useCallback(() => {
      setLinkPostFields({ url: '' });
      setLinkPostPrevUrl('');
    }, []);

    const convertBackToLinkPostHandler = useCallback(() => {
      setConvertBackToLinkPost(false);
      setPostType(PostType.LINK);
    }, []);

    const renderPostInButtons = useMemo(
      () =>
        Boolean(
          renderLinkPostConvert || renderLinkPostReset || isFullPost || convertBackToLinkPost
        ),
      [convertBackToLinkPost, isFullPost, renderLinkPostConvert, renderLinkPostReset]
    );

    const postIn = useMemo(() => {
      return (
        <div className={classes['create-card__post-in']}>
          <span className={classes['create-card__post-in-label']}>
            {t(`common.${editMode ? 'edit' : 'create'}-post-in`)}
          </span>
          <Select
            className={classNames(classes['create-card__post-in-select'], {
              [classes['create-card__post-in-select--disabled']]: editMode,
            })}
            items={postInStories}
            onChange={(id) => postInChangeHandler(id as number)}
            selectedItemId={postInSelectedStoryId}
            listMaxHeightRem={10.5}
            disabled={editMode}
          />
          {renderPostInButtons && (
            <div className={classes['create-card__post-in-buttons']}>
              {renderLinkPostConvert && (
                <>
                  <IconLabel
                    iconId={'editorial'}
                    iconSize={18}
                    disabled={loading}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={convertToShortPost}
                    {...(!isMobileOnly && {
                      data: t('addContent.link-post-convert-to-short-post'),
                      hintDirection: IconLabelHintDirection.left,
                    })}
                  />
                  <IconLabel
                    iconId={'article'}
                    iconSize={18}
                    disabled={loading}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={convertToFullPost}
                    {...(!isMobileOnly && {
                      data: t('addContent.link-post-convert-to-full-post'),
                      hintDirection: IconLabelHintDirection.left,
                    })}
                  />
                </>
              )}
              {renderLinkPostReset && (
                <IconLabel
                  iconId={'add-link'}
                  iconSize={18}
                  disabled={loading}
                  color={getCssVar('--base-link-text-color')}
                  hoverColor={getCssVar('--base-link-text-hover-color')}
                  onClick={resetLinkPostHandler}
                  {...(!isMobileOnly && {
                    label: !renderLinkPostConvert ? t('addContent.link-post-reset-label') : '',
                    data: renderLinkPostConvert ? t('addContent.link-post-reset-label') : '',
                    hintDirection: IconLabelHintDirection.left,
                  })}
                />
              )}
              {isFullPost && (
                <IconLabel
                  iconId={'edit'}
                  iconSize={18}
                  disabled={loading}
                  color={getCssVar('--base-link-text-color')}
                  hoverColor={getCssVar('--base-link-text-hover-color')}
                  onClick={() => setFullPostTextEditorOpen(true)}
                  {...(!isMobileOnly && { label: t('addContent.full-post-open-text-editor') })}
                />
              )}
              {convertBackToLinkPost && (
                <IconLabel
                  iconId={'redo'}
                  iconSize={18}
                  disabled={loading}
                  color={getCssVar('--base-link-text-color')}
                  hoverColor={getCssVar('--base-link-text-hover-color')}
                  onClick={convertBackToLinkPostHandler}
                  {...(!isMobileOnly && { label: t('addContent.convert-back-to-link-post') })}
                />
              )}
            </div>
          )}
        </div>
      );
    }, [
      convertBackToLinkPost,
      convertBackToLinkPostHandler,
      convertToFullPost,
      convertToShortPost,
      editMode,
      isFullPost,
      loading,
      postInChangeHandler,
      postInSelectedStoryId,
      postInStories,
      renderLinkPostConvert,
      renderLinkPostReset,
      renderPostInButtons,
      resetLinkPostHandler,
      t,
    ]);

    const postInCardType = useMemo(() => {
      const isShortPost = postType === PostType.SHORT;

      switch (true) {
        case postType === PostType.THREAD: {
          return StoryCardType.THREAD;
        }
        case isFullPost: {
          return StoryCardType.POST;
        }
        case Boolean(isShortPost && !shortPostGallery.length): {
          return StoryCardType.EDITORIAL;
        }
        case Boolean(
          isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.audio))
        ): {
          return StoryCardType.AUDIO;
        }
        case Boolean(
          isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.video))
        ): {
          return StoryCardType.VIDEO;
        }
        case Boolean(
          isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.pdf))
        ): {
          return StoryCardType.PDF;
        }
        case Boolean(
          isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.image))
        ): {
          return StoryCardType.IMAGE;
        }
        case Boolean(isLinkPost && linkPostArticle): {
          return StoryCardType.ARTICLE;
        }
        case Boolean(isLinkPost && linkPostQuote): {
          return StoryCardType.QUOTE;
        }
      }
    }, [isFullPost, isLinkPost, linkPostArticle, linkPostQuote, postType, shortPostGallery]);

    const isArticle = useMemo(() => postInCardType === StoryCardType.ARTICLE, [postInCardType]);

    const isQuote = useMemo(() => postInCardType === StoryCardType.QUOTE, [postInCardType]);

    const getCardItems = useMemo(() => {
      if (!editMode) {
        const { settings } = { ...postInStory };

        const {
          allowComments: storyAllowComments,
          allowCardReactions: storyAllowCardReactions,
          allowSharing: storyAllowSharing,
          shouldUpdatePostedTimeByComments: storyShouldUpdatePostedTimeByComments,
          showAllAuthors: storyShowAllAuthorsStory,
        } = {
          ...settings,
        };

        return [
          ...optionalArrayItem(Boolean(storyAllowComments), {
            id: CardProps.allowComments,
            value: CardProps.allowComments,
          }),
          ...optionalArrayItem(Boolean(storyAllowCardReactions), {
            id: CardProps.allowCardReactions,
            value: CardProps.allowCardReactions,
          }),
          ...optionalArrayItem(Boolean(storyAllowSharing), {
            id: CardProps.allowSharing,
            value: CardProps.allowSharing,
          }),
          ...optionalArrayItem(Boolean(storyShouldUpdatePostedTimeByComments), {
            id: CardProps.shouldUpdatePostedTimeByComments,
            value: CardProps.shouldUpdatePostedTimeByComments,
          }),
          ...optionalArrayItem(Boolean(storyShowAllAuthorsStory), {
            id: CardProps.showAllAuthors,
            value: CardProps.showAllAuthors,
          }),
        ];
      }

      return [
        ...optionalArrayItem(Boolean(allowComment), {
          id: CardProps.allowComments,
          value: CardProps.allowComments,
        }),
        ...optionalArrayItem(Boolean(allowCardReaction), {
          id: CardProps.allowCardReactions,
          value: CardProps.allowCardReactions,
        }),
        ...optionalArrayItem(Boolean(allowSharing), {
          id: CardProps.allowSharing,
          value: CardProps.allowSharing,
        }),
        ...optionalArrayItem(Boolean(shouldUpdatePostedTimeByComments), {
          id: CardProps.shouldUpdatePostedTimeByComments,
          value: CardProps.shouldUpdatePostedTimeByComments,
        }),
        ...optionalArrayItem(Boolean(allowDisplayAuthor), {
          id: CardProps.showAllAuthors,
          value: CardProps.showAllAuthors,
        }),
      ];
    }, [
      allowCardReaction,
      allowComment,
      allowDisplayAuthor,
      allowSharing,
      editMode,
      postInStory,
      shouldUpdatePostedTimeByComments,
    ]);

    const getCardLocaleItems = useMemo(() => {
      if (!editMode) {
        return [{ value: postInStory?.localeId }];
      }

      return [{ value: localeId }];
    }, [editMode, localeId, postInStory?.localeId]);

    const { galleryItems, setGalleryItems, activeGalleryItemIndex, teaserStyle, setTeaserStyle } =
      useMemo(() => {
        switch (postType) {
          case PostType.SHORT:
            return {
              galleryItems: shortPostGallery,
              setGalleryItems: setShortPostGalleryItems,
              activeGalleryItemIndex: activeShortPostGalleryItemIndex,
            };
          case PostType.FULL:
            return {
              galleryItems: fullPostGallery,
              setGalleryItems: setFullPostGalleryItems,
              activeGalleryItemIndex: activeFullPostGalleryItemIndex,
              teaserStyle: fullPostTeaserStyle,
              setTeaserStyle: setFullPostTeaserStyle,
            };
          case PostType.LINK:
            if (isArticle) {
              return {
                galleryItems: linkPostArticleGallery ?? [],
                setGalleryItems: setLinkPostArticleGalleryItems,
                activeGalleryItemIndex: activeLinkPostGalleryItemIndex,
                teaserStyle: linkPostArticleStyles?.teaserImageStyle,
                setTeaserStyle: setLinkPostArticleTeaserStyle,
              };
            }
            return {
              galleryItems: linkPostQuoteGallery ?? [],
              setGalleryItems: setLinkPostQuoteGalleryItems,
              activeGalleryItemIndex: activeLinkPostGalleryItemIndex,
            };
          default:
            return {
              galleryItems: [],
              setGalleryItems: () => null,
              activeGalleryItemIndex: 0,
            };
        }
      }, [
        activeFullPostGalleryItemIndex,
        activeLinkPostGalleryItemIndex,
        activeShortPostGalleryItemIndex,
        fullPostGallery,
        fullPostTeaserStyle,
        isArticle,
        linkPostArticleGallery,
        linkPostArticleStyles?.teaserImageStyle,
        linkPostQuoteGallery,
        postType,
        setFullPostGalleryItems,
        setFullPostTeaserStyle,
        setLinkPostArticleGalleryItems,
        setLinkPostArticleTeaserStyle,
        setLinkPostQuoteGalleryItems,
        setShortPostGalleryItems,
        shortPostGallery,
      ]);

    const [settings, setSettings] = useState<CardSettings>({
      card: [
        { groupId: CARD, groupItems: getCardItems },
        { groupId: CARD_LOCALE, groupItems: getCardLocaleItems },
      ],
      media: {
        galleryItems,
        setGalleryItems,
        activeGalleryItemIndex,
        teaserStyle,
        setTeaserStyle,
      },
      activeSettingsTabIndex,
      setActiveSettingsTabIndex,
      ...(hasTags && {
        tags: {
          external: externalTags,
          setExternal: setExternalTags,
          internal: internalTags,
          setInternal: setInternalTags,
        },
      }),
    });

    useEffect(() => {
      setSettings({
        ...settings,
        card: [
          { groupId: CARD, groupItems: getCardItems },
          { groupId: CARD_LOCALE, groupItems: getCardLocaleItems },
        ],
        media: {
          galleryItems,
          setGalleryItems,
          activeGalleryItemIndex,
          teaserStyle,
          setTeaserStyle,
        },
        activeSettingsTabIndex,
        setActiveSettingsTabIndex,
        ...(hasTags && {
          tags: {
            external: externalTags,
            setExternal: setExternalTags,
            internal: internalTags,
            setInternal: setInternalTags,
          },
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeGalleryItemIndex,
      activeSettingsTabIndex,
      galleryItems,
      setGalleryItems,
      setTeaserStyle,
      teaserStyle,
      getCardItems,
      getCardLocaleItems,
      hasTags,
      externalTags,
      internalTags,
    ]);

    const {
      card: cardFilter,
      media: { galleryItems: mediaGalleryItems },
    } = settings;

    const publishButtonLabel = useMemo(() => {
      switch (true) {
        case editMode:
          return t('common.save');
        case Boolean(postingTime):
          return t('common.schedule');
        default:
          return t('common.publish');
      }
    }, [editMode, postingTime, t]);

    const hasMedia = useMemo(() => Boolean(mediaGalleryItems?.length), [mediaGalleryItems?.length]);

    const renderSettingsToggle = useMemo(
      () => Boolean(isAdminLayout() || hasMedia || hasTags),
      [hasMedia, hasTags]
    );

    const galleryMap = useCallback((galleryItems?: GalleryItem[]) => {
      return galleryItems?.map((galleryItem) => {
        const { image, audio, video, pdf, title } = galleryItem;

        const { id, rightholder } = { ...image };

        const imageMapped = id && { image: { id, ...(rightholder && { rightholder }) } };

        switch (true) {
          case Boolean(audio):
            return { audio: { id: audio?.id, useDefaultThumb: false }, ...imageMapped, title };
          case Boolean(video):
            return { video: { id: video?.id, useDefaultThumb: true }, ...imageMapped, title };
          case Boolean(pdf):
            return { pdf: { id: pdf?.id, useDefaultThumb: false }, ...imageMapped, title };
          case Boolean(image): {
            return { ...imageMapped, ...(title && { title }) };
          }
          default:
            return null;
        }
      });
    }, []);

    const postCard = useCallback(
      (status: StoryCardStatus) => {
        return new Promise<void>(async (resolve, reject) => {
          fetchCardAttemptsCount.current = 0;
          toggleStoryCardDisabled({ storyCardId: cardId, disabled: true });
          onClose();

          try {
            const mediaFields = { ...shortPostFields, gallery: galleryMap(shortPostGallery) };

            const { gallery, ...editorialFields } = mediaFields;

            const postFields = { ...fullPostFields, gallery: galleryMap(fullPostGallery) };

            const articleData = { ...linkPostArticle, gallery: galleryMap(linkPostArticleGallery) };

            const { type: articleType, ...articleFields } = articleData;

            const quoteData = { ...linkPostQuote, gallery: galleryMap(linkPostQuoteGallery) };

            const { type: quoteType, quotePersonImage, ...quoteFields } = quoteData;

            const allowComment = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowComments,
              })
            );

            const allowCardReaction = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowCardReactions,
              })
            );

            const allowSharing = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowSharing,
              })
            );

            const shouldUpdatePostedTimeByComments = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.shouldUpdatePostedTimeByComments,
              })
            );

            const allowDisplayAuthor = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.showAllAuthors,
              })
            );

            const localeId = getGroupValue({ filter: cardFilter, groupId: CARD_LOCALE });

            const fields = {
              status,
              postingTime: postingTime?.toISOString(),
              options: {
                allowComment,
                allowCardReaction,
                allowSharing,
                allowDisplayAuthor,
                shouldUpdatePostedTimeByComments,
              },
              localeId,
              organisationTags: {
                externalTagsId: externalTags.map(({ id }) => id),
                ...(internalTags && {
                  internalTagsId: internalTags.map(({ id }) => id),
                }),
              },
              ...(postInCardType === StoryCardType.THREAD && {
                threadFields: threadPostFields,
              }),
              ...(postInCardType === StoryCardType.EDITORIAL && {
                editorialFields,
              }),
              ...(postInCardType === StoryCardType.IMAGE && {
                imageFields: mediaFields,
              }),
              ...(postInCardType === StoryCardType.AUDIO && {
                audioFields: mediaFields,
              }),
              ...(postInCardType === StoryCardType.VIDEO && {
                videoFields: mediaFields,
              }),
              ...(postInCardType === StoryCardType.PDF && {
                pdfFields: mediaFields,
              }),
              ...(isArticle &&
                linkPostArticle && {
                  articleFields: { url: linkPostFieldsUrl, ...articleFields },
                }),
              ...(isQuote &&
                linkPostQuote && {
                  quoteFields: {
                    url: linkPostFieldsUrl,
                    ...quoteFields,
                    ...(linkPostQuoteContentPersonImage?.id && {
                      quotePersonImageId: linkPostQuoteContentPersonImage?.id,
                    }),
                  },
                }),
              ...(postInCardType === StoryCardType.POST && {
                postFields,
              }),
            } as StoryCardFieldsPostInStoryInput;

            const postInStoryArgs = { storyId: postInStoryId, fields };

            const cardUpdateArgs = { ...postInStoryArgs, storyCardId: cardId };

            const { payload, error } = await (editMode
              ? storyCardUpdate(cardUpdateArgs)
              : storyCardPostIn(postInStoryArgs)
            ).unwrap();

            const payloadAction = editMode ? editCard : addCard;

            if (payload) {
              if (isCardMediaCompleted(payload)) {
                toggleStoryCardDisabled({ storyCardId: cardId, disabled: false });
                refreshFeeds();
                dispatch(payloadAction(payload));
                resolve();
                return;
              }

              const { storyId: postedStoryId, id: postedCardId } = payload;

              const postedCardInterval = setInterval(async () => {
                fetchCardAttemptsCount.current = fetchCardAttemptsCount.current + 1;

                const postedCard = await dispatch(
                  getCard({ storyId: postedStoryId, cardId: postedCardId })
                ).unwrap();

                if (!isCardMediaCompleted(postedCard)) {
                  if (fetchCardAttemptsCount.current === FETCH_CARD_ATTEMPTS_LIMIT) {
                    toast.dismiss('postCard');
                    toast(t('addContent.long-processing'), { delay: 1000 });
                  }
                  return;
                }

                if (fetchCardAttemptsCount.current < FETCH_CARD_ATTEMPTS_LIMIT) {
                  toggleStoryCardDisabled({ storyCardId: cardId, disabled: false });
                  refreshFeeds();
                  dispatch(payloadAction(postedCard));
                  resolve();
                  clearInterval(postedCardInterval);
                  return;
                }

                const detailsUrl = `${getWebAppUrl(
                  channelDomain
                )}/details/${postedStoryId}/${postedCardId}`;

                toast.dismiss('postCard');
                toast(
                  <IconLabel
                    label={t('addContent.card-processed')}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={() => window.open(detailsUrl, '_blank', 'noopener, noreferrer')}
                  />,
                  { autoClose: false }
                );

                clearInterval(postedCardInterval);
              }, FETCH_CARD_MS_TIMEOUT);

              return;
            }

            if (error) {
              toast.error(
                error.__typename === 'StoryCardUrlUniquenessConflictError'
                  ? t('addContent.unique-conflict-error')
                  : t('addContent.error-message')
              );

              reject();
            }
          } catch (_) {
            reject();
          }
        });
      },
      [
        cardFilter,
        cardId,
        channelDomain,
        dispatch,
        editMode,
        externalTags,
        fullPostFields,
        fullPostGallery,
        galleryMap,
        getGroupItemValue,
        getGroupValue,
        internalTags,
        isArticle,
        isQuote,
        linkPostArticle,
        linkPostArticleGallery,
        linkPostFieldsUrl,
        linkPostQuote,
        linkPostQuoteContentPersonImage?.id,
        linkPostQuoteGallery,
        onClose,
        postInCardType,
        postInStoryId,
        postingTime,
        refreshFeeds,
        shortPostFields,
        shortPostGallery,
        storyCardPostIn,
        storyCardUpdate,
        t,
        threadPostFields,
      ]
    );

    const postCardValidationError = useMemo(() => {
      switch (postType) {
        case PostType.SHORT:
          return Boolean(!shortPostGallery.length && isEmptyString(shortPostText));
        case PostType.FULL:
          return isEmptyString(fullPostTitle);
        case PostType.THREAD:
          return isEmptyString(threadPostTitle);
        case PostType.LINK:
          return Boolean(
            !linkPostType ||
              (linkPostArticle &&
                (isEmptyString(linkPostArticleSourceName) ||
                  isEmptyString(linkPostArticleTitle))) ||
              (linkPostQuote &&
                isEmptyString(linkPostQuoteContent) &&
                !linkPostQuoteGallery?.length)
          );
        default:
          return false;
      }
    }, [
      fullPostTitle,
      linkPostArticle,
      linkPostArticleSourceName,
      linkPostArticleTitle,
      linkPostQuote,
      linkPostQuoteContent,
      linkPostQuoteGallery,
      linkPostType,
      postType,
      shortPostGallery.length,
      shortPostText,
      threadPostTitle,
    ]);

    const postCardHandler = useCallback(
      async (status: StoryCardStatus) => {
        if (postCardValidationError) {
          return toast.error(t(`addContent.${postType}-post-validation-error`));
        }

        const action = editMode ? 'edit' : 'create';

        await toast.promise(
          postCard(status),
          {
            pending: t(`addContent.${action}-pending`),
            success: t(`addContent.${action}-success`),
            error: t(`addContent.${action}-error`),
          },
          { toastId: 'postCard' }
        );
      },
      [editMode, postCard, postCardValidationError, postType, t]
    );

    const onTabChange = useCallback(
      (index: number) => {
        if (editMode) {
          return;
        }

        switch (index) {
          case TabType.FULL_TAB: {
            setPostType(PostType.FULL);
            break;
          }
          case TabType.LINK_TAB: {
            setPostType(PostType.LINK);
            break;
          }
          case TabType.THREAD_TAB: {
            setPostType(PostType.THREAD);
            break;
          }
          default: {
            setPostType(PostType.SHORT);
            break;
          }
        }
      },
      [editMode]
    );

    const saveButton = useMemo(() => {
      if (editMode) {
        return null;
      }

      if (isMobileOnly) {
        return (
          <IconLabel
            iconId={'save'}
            iconSize={20}
            onClick={() => postCardHandler(StoryCardStatus.UNPUBLISHED)}
            disabled={Boolean(loading || postingTime)}
          />
        );
      }

      return (
        <Button
          type={ButtonType.secondary}
          label={t('common.save-draft')}
          onClick={() => postCardHandler(StoryCardStatus.UNPUBLISHED)}
          disabled={Boolean(loading || postingTime)}
        />
      );
    }, [editMode, loading, postCardHandler, postingTime, t]);

    const createCardButtons = useMemo(() => {
      return (
        <>
          {(!editMode || editCardStatus !== StoryCardStatus.PUBLISHED) && (
            <ScheduleInput
              postingTime={postingTime}
              setPostingTime={setPostingTime}
              title={t('addContent.schedule-post')}
              subTitle={t('addContent.schedule-post-description')}
            />
          )}
          {saveButton}
          {editMode && (
            <PublishToggle
              isPublished={isEditCardPublished}
              onClick={() =>
                setEditCardStatus(
                  isEditCardPublished ? StoryCardStatus.UNPUBLISHED : StoryCardStatus.PUBLISHED
                )
              }
            />
          )}
          <Button
            type={ButtonType.primary}
            label={publishButtonLabel}
            disabled={loading}
            onClick={() => {
              if (!editMode) {
                postCardHandler(
                  postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.PUBLISHED
                );
                return;
              }

              if (isEditCardPublished) {
                postCardHandler(StoryCardStatus.PUBLISHED);
                return;
              }

              postCardHandler(
                postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.UNPUBLISHED
              );
            }}
          />
        </>
      );
    }, [
      editCardStatus,
      editMode,
      isEditCardPublished,
      loading,
      postCardHandler,
      postingTime,
      publishButtonLabel,
      saveButton,
      t,
    ]);

    const hideSettingsCaption = useMemo(
      () => isFullPost || isArticle || isQuote,
      [isArticle, isFullPost, isQuote]
    );

    const tabContent = useCallback((content: JSX.Element) => {
      return <div className={classes['create-card__content']}>{content}</div>;
    }, []);

    const tabItems: TabItem[] = useMemo(
      () => [
        ...optionalArrayItem(!editMode || postType === PostType.SHORT, {
          index: TabType.SHORT_TAB,
          name: t('addContent.short-post'),
          content: tabContent(
            <ShortPost
              loading={loading}
              setLoading={setLoading}
              shortPostFields={shortPostFields}
              setShortPostFields={setShortPostFields}
              setShortPostGalleryItems={setShortPostGalleryItems}
              activeShortPostGalleryItemIndex={activeShortPostGalleryItemIndex}
              setActiveShortPostGalleryItemIndex={setActiveShortPostGalleryItemIndex}
              convertToLinkPost={convertToLinkPost}
              settingsOpen={settingsOpen}
              setSettingsOpen={(isOpen) => {
                setSettingsOpen(isOpen);
                scrollToSettings();
              }}
              activeShortPostSettingsMediaTab={isMediaTabActive}
              setActiveShortPostSettingsTabIndex={setActiveSettingsTabIndex}
              {...(files && { files })}
              {...(fileUrl && { fileUrl })}
            />
          ),
        }),
        ...optionalArrayItem(!editMode || postType === PostType.FULL, {
          index: TabType.FULL_TAB,
          name: t('addContent.full-post'),
          content: tabContent(
            <FullPost
              loading={loading}
              setLoading={setLoading}
              fullPostFields={fullPostFields}
              setFullPostFields={setFullPostFields}
              setFullPostGalleryItems={setFullPostGalleryItems}
              fullPostTextEditorOpen={fullPostTextEditorOpen}
              setFullPostTextEditorOpen={setFullPostTextEditorOpen}
              fullPostTextEditorUsePrefill={fullPostTextEditorUsePrefill}
              setFullPostTextEditorUsePrefill={setFullPostTextEditorUsePrefill}
              activeFullPostGalleryItemIndex={activeFullPostGalleryItemIndex}
              setActiveFullPostGalleryItemIndex={(index) =>
                setActiveFullPostGalleryItemIndex(index)
              }
              settingsOpen={settingsOpen}
              setSettingsOpen={(isOpen) => {
                setSettingsOpen(isOpen);
                scrollToSettings();
              }}
              activeFullPostSettingsMediaTab={isMediaTabActive}
              setActiveFullPostSettingsTabIndex={setActiveSettingsTabIndex}
            />
          ),
        }),
        ...optionalArrayItem(!editMode || postType === PostType.LINK, {
          index: TabType.LINK_TAB,
          name: t('addContent.link-post'),
          content: tabContent(
            <LinkPost
              loading={loading}
              setLoading={setLoading}
              linkPostFields={linkPostFields}
              setLinkPostFields={setLinkPostFields}
              linkPostPrevUrl={linkPostPrevUrl}
              setLinkPostPrevUrl={setLinkPostPrevUrl}
              setLinkPostArticleGalleryItems={setLinkPostArticleGalleryItems}
              setLinkPostQuoteGalleryItems={setLinkPostQuoteGalleryItems}
              activeLinkPostGalleryItemIndex={activeLinkPostGalleryItemIndex}
              setActiveLinkPostGalleryItemIndex={(index) =>
                setActiveLinkPostGalleryItemIndex(index)
              }
              settingsOpen={settingsOpen}
              setSettingsOpen={(isOpen) => {
                setSettingsOpen(isOpen);
                scrollToSettings();
              }}
              activeLinkPostSettingsMediaTab={isMediaTabActive}
              setActiveLinkPostSettingsTabIndex={setActiveSettingsTabIndex}
              postInStoryTeaserStyle={postInStoryTeaserStyle}
            />
          ),
        }),
        ...optionalArrayItem(!editMode || postType === PostType.THREAD, {
          index: TabType.THREAD_TAB,
          name: t('addContent.thread-post'),
          content: tabContent(
            <ThreadPost
              threadPostFields={threadPostFields}
              setThreadPostFields={setThreadPostFields}
              setActiveThreadPostSettingsTabIndex={setActiveSettingsTabIndex}
              setSettingsOpen={(isOpen) => {
                setSettingsOpen(isOpen);
                scrollToSettings();
              }}
            />
          ),
        }),
      ],
      [
        activeFullPostGalleryItemIndex,
        activeLinkPostGalleryItemIndex,
        activeShortPostGalleryItemIndex,
        convertToLinkPost,
        editMode,
        fileUrl,
        files,
        fullPostFields,
        fullPostTextEditorOpen,
        fullPostTextEditorUsePrefill,
        isMediaTabActive,
        linkPostFields,
        linkPostPrevUrl,
        loading,
        postInStoryTeaserStyle,
        postType,
        scrollToSettings,
        setFullPostGalleryItems,
        setLinkPostArticleGalleryItems,
        setLinkPostQuoteGalleryItems,
        setShortPostGalleryItems,
        settingsOpen,
        shortPostFields,
        t,
        tabContent,
        threadPostFields,
      ]
    );

    const body = useMemo(() => {
      return (
        <div className={classes['create-card']}>
          {postIn}
          <Tabs
            items={tabItems}
            selectedTabIndex={TabType[`${postType}_TAB`]}
            setSelectedTabIndex={onTabChange}
            disabled={loading}
          />
          <div className={classes['create-card__buttons']}>
            {renderSettingsToggle && (
              <IconLabelWithActive
                ref={settingsButton}
                iconId={'settings'}
                active={settingsOpen}
                setActive={setSettingsOpen}
                onActive={scrollToSettings}
                className={classes['create-card__buttons-settings']}
                {...(!isMobileOnly && { label: t('common.settings') })}
              />
            )}
            {createCardButtons}
          </div>

          {renderSettingsToggle && settingsOpen && (
            <CreateCardSettings
              settings={settings}
              onChange={setSettings}
              hideCaption={hideSettingsCaption}
              loading={loading}
              showEnableComments={postInCardType !== StoryCardType.THREAD}
              showDisplayUser={!isIntegrationCard}
              onTabChangeCallback={scrollToSettings}
            />
          )}
        </div>
      );
    }, [
      postIn,
      tabItems,
      postType,
      onTabChange,
      loading,
      renderSettingsToggle,
      settingsOpen,
      scrollToSettings,
      t,
      createCardButtons,
      settings,
      hideSettingsCaption,
      postInCardType,
      isIntegrationCard,
    ]);

    return (
      <>
        {isOpen &&
          ReactDOM.createPortal(
            <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />,
            document.getElementById('modal-root') as HTMLElement
          )}
      </>
    );
  }
);

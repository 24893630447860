import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../context';
import { ConfirmationModal, useDeepLink } from '../../shared';

import classes from './TermsModal.module.scss';

export const TermsModal: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isDeepLink } = useDeepLink();

  const { config } = useContext(ConfigContext);

  const { termsConditions } = config.elements;

  const { visible, titleLocaliseKey, subTitleLocaliseKey, links, hideCancel } = termsConditions;

  const storageKey = useMemo(() => 'Accepted T&C', []);

  const [isOpen, setIsOpen] = useState(!Boolean(localStorage.getItem(storageKey)));

  const onAccept = useCallback(() => {
    setIsOpen(false);
    localStorage.setItem(storageKey, new Date().toISOString());
  }, [storageKey]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    navigate('/auth/login');
  }, [navigate]);

  if (!visible || isDeepLink) {
    return null;
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onAccept={onAccept}
      acceptLabel={t('common.accept')}
      title={t(titleLocaliseKey)}
      subTitle={
        <Trans i18nKey={subTitleLocaliseKey}>
          {links.map((link) => (
            <a
              key={link}
              className={classes['link']}
              href={link}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {link}
            </a>
          ))}
        </Trans>
      }
      keepOpened
      opacityOverlay
      {...(hideCancel && { hideCancel })}
    />
  );
};

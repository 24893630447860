import { IS_PRODUCTION, TCHOP_CHANNEL_DOMAIN, TCHOP_ROOT_URL } from '../../env';
import { getChannelNameFromCookie } from './getChannelNameFromCookie';

const isTchopLocation = (): boolean => {
  if (TCHOP_ROOT_URL === '/root') {
    return false;
  }

  return document.location.host.includes(new URL(TCHOP_ROOT_URL).host);
};

export const getChannelDomain = (): string => {
  const channel = getChannelNameFromCookie();

  if (channel) {
    return channel;
  }

  if (!IS_PRODUCTION) {
    return TCHOP_CHANNEL_DOMAIN ?? '';
  }

  if (isTchopLocation()) {
    // https://mbo-cardstyleguide.tchop-staging.com/path => mbo

    const tchopRootDomain = new URL(TCHOP_ROOT_URL).host;
    const subdomain = document.location.host.replace('.' + tchopRootDomain, '');
    const subdomainParts = subdomain.split('-');
    subdomainParts.pop();

    return subdomainParts.join('-');
  }

  return '';
};

import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../../context';
import { StoryCard, StoryCardThreadContent } from '../../../../services';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { FeedCardMargin } from '../FeedCardMargin';

import classes from './FeedCardThreadCommon.module.scss';

interface FeedCardThreadCommonProps {
  card: StoryCard;
}

export const FeedCardThreadCommon: FunctionComponent<FeedCardThreadCommonProps> = memo(
  ({ card }) => {
    const { t } = useTranslation();

    const { content, isPinnedToTopOfNewsFeed } = card;

    const { title } = content as StoryCardThreadContent;

    const { config } = useContext(ConfigContext);

    const { card: cardConfig } = config.elements;

    const { thread } = cardConfig;

    const discussion = useMemo(
      () => t(`${thread.localiseKey ?? 'common.discussion'}`),
      [t, thread.localiseKey]
    );

    return (
      <FeedCardMargin top left right>
        <div className={classes['thread']}>
          <div className={classes['thread__discussion']}>
            {isPinnedToTopOfNewsFeed && (
              <IconLabel
                iconId={'pin'}
                label={'•'}
                color={getCssVar('--card-thread-discussion-color')}
                singleColor
                nonClickable
              />
            )}
            {discussion}
          </div>
          {title && <div className={classes['thread__title']}>{title}</div>}
        </div>
      </FeedCardMargin>
    );
  }
);

import { FunctionComponent, memo } from 'react';
import { FeedCardWrapper } from '../FeedCardWrapper';
import { FeedCardContent, FeedCardProps, FeedCardTeaser } from '../../../shared';

export const FeedCardPost: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  return (
    <FeedCardWrapper card={card}>
      <FeedCardTeaser card={card} ContentComponent={FeedCardContent} />
    </FeedCardWrapper>
  );
});

import { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, StatsItem } from '../../helpers';
import { BarDatum, ResponsiveBar, ResponsiveBarSvgProps } from '@nivo/bar';
import { ContentProps } from '../Content';
import { useContentInteractionQuery } from '../../../../services';
import { differenceInDays, subDays } from 'date-fns';

import classes from '../../Analytics.module.scss';

export const ContentInteraction: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform, organisationTags }) => {
    const { t } = useTranslation();

    const { data: contentInteraction, isFetching: isFetchingContentInteraction } =
      useContentInteractionQuery({
        from,
        to,
        channelId,
        platform,
        organisationTags,
      });

    const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

    const { data: contentInteractionPrev, isFetching: isFetchingContentInteractionPrev } =
      useContentInteractionQuery({
        from: subDays(new Date(from), daysDiff).toISOString(),
        to: subDays(new Date(to), daysDiff).toISOString(),
        channelId,
        platform,
        organisationTags,
      });

    const [contentInteractionLabels, setContentInteractionLabels] = useState<string[]>([]);

    useEffect(() => {
      if (contentInteraction && !contentInteractionLabels.length) {
        setContentInteractionLabels(contentInteraction.items.map(({ label }) => label));
      }
    }, [contentInteraction, contentInteractionLabels.length]);

    const renderStats = useMemo(() => {
      if (isFetchingContentInteraction || isFetchingContentInteractionPrev) {
        return <Loader stats />;
      }

      if (!contentInteraction || !contentInteractionPrev) {
        return null;
      }

      const { items } = contentInteraction;

      const { items: itemsPrev } = contentInteractionPrev;

      return (
        <div className={classes['analytics__chart-stats']}>
          {items.map(({ label, value }, index) => {
            const isDisabled = !contentInteractionLabels.includes(label);
            const isClickable = contentInteractionLabels.length > 1 || isDisabled;
            const isTotal = label === 'total';

            return (
              <StatsItem
                key={label}
                label={label}
                localiseKey={isTotal ? 'common.total' : `cardTypes.${label}`}
                color={COLORS[index]}
                value={value}
                valuePrev={itemsPrev.find((set) => set.label === label)?.value ?? 0}
                valueSuffix={'%'}
                labels={contentInteractionLabels}
                setLabels={setContentInteractionLabels}
                hasTotal
                isTotal={isTotal}
                isDisabled={isDisabled}
                isClickable={isClickable}
              />
            );
          })}
        </div>
      );
    }, [
      contentInteraction,
      contentInteractionLabels,
      contentInteractionPrev,
      isFetchingContentInteraction,
      isFetchingContentInteractionPrev,
    ]);

    const renderTick = useCallback(({ y, label }: { y: number; label: string }) => {
      return (
        <text y={y - 20} fontSize={14} fontWeight={600} fill={'#1E1E1E'}>
          {label}
        </text>
      );
    }, []);

    const tooltip = useCallback(
      ({ label, value, color }: { label: string | number; value: number; color: string }) => {
        if (!contentInteraction) {
          return null;
        }

        const isTotal = label === 'total';
        const isTotalRest = label === 'totalRest';
        const isTotalOrRest = isTotal || isTotalRest;

        const { published = 0, interacted = 0 } = {
          ...contentInteraction.items.find(
            (dataset) => dataset.label === (isTotalOrRest ? 'total' : label)
          )?.payload,
        };

        return (
          <div key={label} className={classes['analytics__chart-tooltip']}>
            {!isTotalOrRest && (
              <div className={classes['analytics__chart-tooltip-item']}>
                <span
                  className={classes['analytics__chart-tooltip-item-color']}
                  style={{ backgroundColor: color }}
                ></span>
                <span className={classes['analytics__chart-tooltip-item-label']}>
                  {t(`cardTypes.${label}`)}
                </span>
                <span className={classes['analytics__chart-tooltip-item-value']}>{value}%</span>
              </div>
            )}
            <div className={classes['analytics__chart-tooltip-item']}>
              <span className={classes['analytics__chart-tooltip-item-label']}>
                {t('common.published')}
              </span>
              <span className={classes['analytics__chart-tooltip-item-value']}>{published}</span>
            </div>
            <div className={classes['analytics__chart-tooltip-item']}>
              <span className={classes['analytics__chart-tooltip-item-label']}>
                {isTotalRest ? t('common.unengaged') : t('common.engaged')}
              </span>
              <span className={classes['analytics__chart-tooltip-item-value']}>
                {isTotalRest ? published - interacted : interacted}
              </span>
            </div>
          </div>
        );
      },
      [contentInteraction, t]
    );

    const renderChart = useMemo(() => {
      if (isFetchingContentInteraction) {
        return <Loader />;
      }

      if (!contentInteraction) {
        return null;
      }

      const { items } = contentInteraction;

      const data = Object.assign({}, ...items.map(({ label, value }) => ({ [label]: value })));

      const total = items.find(({ label }) => label === 'total')?.value ?? 0;

      const renderCardsBar = Boolean(
        total && contentInteractionLabels.find((label) => label !== 'total')
      );

      const renderTotalBar = contentInteractionLabels.includes('total');

      const responsiveBarProps = {
        margin: { top: 16, right: 20, left: 20 },
        layout: 'horizontal',
        axisBottom: null,
        labelSkipWidth: 1,
        innerPadding: 2,
        borderRadius: 4,
        enableLabel: false,
        enableGridY: false,
      } as ResponsiveBarSvgProps<BarDatum>;

      const keys: string[] = [];

      items.forEach(({ label }) => {
        if (contentInteractionLabels.includes(label) && label !== 'total') {
          keys.push(label);
        }
      });

      const colors = keys.map((key) => {
        return COLORS[items.findIndex(({ label }) => label === key)];
      });

      return (
        <div className={classes['analytics__chart-height-wrapper']}>
          {renderCardsBar && (
            <div className={classes['analytics__chart-height--content-interaction']}>
              <ResponsiveBar
                {...responsiveBarProps}
                data={[data]}
                keys={keys}
                colors={colors}
                tooltip={({ id, value, color }) => tooltip({ label: id, value, color })}
                axisLeft={{
                  renderTick: ({ y }) => renderTick({ y, label: t('common.card-type') }),
                }}
              />
            </div>
          )}
          {renderTotalBar && (
            <div className={classes['analytics__chart-height--content-interaction']}>
              <ResponsiveBar
                {...responsiveBarProps}
                data={[{ total, totalRest: 100 - total }]}
                keys={['total', 'totalRest']}
                colors={[COLORS[0], '#ebebeb']}
                tooltip={({ id, value, color }) => tooltip({ label: id, value, color })}
                maxValue={100}
                axisLeft={{
                  renderTick: ({ y }) => renderTick({ y, label: t('common.total') }),
                }}
              />
            </div>
          )}
        </div>
      );
    }, [
      contentInteraction,
      contentInteractionLabels,
      isFetchingContentInteraction,
      renderTick,
      t,
      tooltip,
    ]);

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.content-interaction'}
        infoLocaliseKey={'contentInteraction.info'}
      >
        {renderStats}
        {renderChart}
      </ChartWrapper>
    );
  }
);

import { FunctionComponent, memo } from 'react';

import classes from './ColorPicker.module.scss';

interface ColorPickerProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

export const ColorPicker: FunctionComponent<ColorPickerProps> = memo(
  ({ label, value, onChange }) => {
    return (
      <label className={classes['color-picker']}>
        <input
          className={classes['color-picker__input']}
          type={'color'}
          value={value}
          onChange={({ target }) => onChange(target.value)}
        />
        {label}
      </label>
    );
  }
);
